import User from '@/models/User';

export type KlassAssessment = {
  id: number;
  behaviorAssessment: BehaviorAssessment;
  commentAssessment: CommentAssessment;
  author: User;
  klassId: number;
  createdAt: Date;
};

export type BehaviorAssessment = {
  id: number;
  energy: BehaviorRatingEnum;
  maturity: BehaviorRatingEnum;
  speed: BehaviorRatingEnum;
  createdAt: Date;
  updatedAt: Date;
  klassAssessmentId: number;
};

export type CommentAssessment = {
  id: number;
  comment: string;
  klassAssessmentId: number;
  createdAt: Date;
  updatedAt: Date;
};

export enum BehaviorRatingEnum {
  ONE = 1,
  TWO,
  THREE,
}

export enum KlassAssessmentTypeEnum {
  COMMENT = 'COMMENT',
  BEHAVIOR = 'BEHAVIOR',
}
