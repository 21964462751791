import {
  AuthorFieldsArray,
  FilterFields,
  useBankFilters,
} from '@/data/context/BankFiltersContext';
import useAuth from '@/data/hook/useAuth';
import { isSuperAdmin } from '@/functions/auth';
import { UserTypeEnum } from '@/models/User';
import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import ComponentGuard from '@/components/common/ComponentGuard';
import CheckGroup, {
  CheckGroupContainer,
} from '@/components/common/dataInput/CheckGroup';
import ActionGroupContainer from './ActionGroupContainer';
import InfinityList from '@/components/common/InfinityList';
import useInfiniteService from '@/data/hook/useInfiniteService';
import { toolsQueryKeys, unitsQueryKeys } from '@/data/services/querykeys';
import { useQuery } from '@tanstack/react-query';

export function FilterBank() {
  const { t } = useTranslation('translation', {
    keyPrefix: 'modalBank',
  });

  const { filters, setFilters } = useBankFilters();

  const { user } = useAuth();

  const { register, watch, setValue } = useForm<
    Pick<FilterFields, 'author' | 'tool' | 'unit'>
  >({
    defaultValues: {
      unit: filters.unit,
      author: filters.author,
      tool: filters.tool,
    },
  });

  const {
    results: units,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
  } = useInfiniteService({
    ...unitsQueryKeys.list({
      isActive: true,
    })._ctx.infinity,
    refetchOnMount: false,
    enabled: isSuperAdmin(user?.userType),
  });

  const { data: toolsResponse } = useQuery({
    ...toolsQueryKeys.list(),
    refetchOnMount: false,
  });
  const tools = toolsResponse?.results ?? [];

  useEffect(() => {
    const w = watch(values => {
      setFilters(Object.assign({}, filters, values));
    });
    return () => w.unsubscribe();
  }, [watch, setFilters, filters]);

  const allChecked = AuthorFieldsArray.every(value =>
    filters.author.includes(value),
  );

  const authorCheckOptions = [
    {
      value: '',
      label: t('authorFilter.all'),
      checked: allChecked,
      onClick: () => setValue('author', !allChecked ? AuthorFieldsArray : []),
    },
    {
      value: 'my',
      label: t('authorFilter.my'),
      checked: filters.author.includes('my'),
    },
    {
      value: UserTypeEnum.SUPER_ADMIN,
      label: t('authorFilter.pedagogical'),
    },
    {
      value: UserTypeEnum.TEACHER,
      label: t('authorFilter.teacher'),
    },
  ];

  return (
    <ActionGroupContainer title={t('section.filters')} className="gap-2">
      <CheckGroupContainer
        title={t('filters.author')}
        badgeCount={filters.author.length}
        testId="authorCheckGroup"
        initOpen
      >
        <CheckGroup options={authorCheckOptions} {...register('author')} />
      </CheckGroupContainer>
      <ComponentGuard roles={[UserTypeEnum.SUPER_ADMIN]}>
        <CheckGroupContainer
          className="relative overflow-visible"
          title={t('filters.unit')}
          testId="unitCheckGroup"
          badgeCount={filters.unit.length}
          initOpen
        >
          <InfinityList
            className="relative max-h-[200px] pr-5 gap-4 overflow-x-hidden"
            scroll
            onReachEnd={fetchNextPage}
            hasNextPage={hasNextPage}
            isFetchingNextPage={isFetchingNextPage}
          >
            <CheckGroup
              options={units.map(unit => ({
                value: unit.id,
                label: unit.name,
              }))}
              {...register('unit')}
            />
          </InfinityList>
        </CheckGroupContainer>
      </ComponentGuard>
      <CheckGroupContainer
        title={t('filters.tool')}
        testId="toolCheckGroup"
        badgeCount={filters.tool.length}
        initOpen
      >
        <CheckGroup
          options={tools.map(tool => ({
            value: tool.id,
            label: tool.name,
          }))}
          {...register('tool')}
        />
      </CheckGroupContainer>
    </ActionGroupContainer>
  );
}
