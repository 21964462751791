import {
  createContext,
  Dispatch,
  SetStateAction,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import { useNavigate } from 'react-router-dom';
import requester from '@/axios';
import Book from '@/models/Book';
import CoursePath from '@/models/Course';
import { LessonTab } from '@/models/Lesson';
import ScheduledLesson from '@/models/ScheduledLesson';
import ModalWarning from '@/components/common/modals/ModalWarning';
import Klass from '@/models/Klass';
import { ACTIVE_LESSON_TAB_STORAGE_KEY } from '@/constants';
import { VersioningStatusEnum } from '@/enums/VersioningStatus';
import { updateCoursePath } from '../services/coursePathServices';

export type ModeCourseInfo = Pick<CoursePath, 'slug' | 'status'>;

type AllowMode = (
  course: ModeCourseInfo,
  isFirstVersion?: boolean,
) => Promise<void>;
export type MaterialMode = 'upload' | 'default';

export interface ActiveLessonTab {
  previousActiveLessonIndex: number | null;
  activeLessonIndex: number | null;
  activeTab: LessonTab;
}

export interface CourseEditingProps {
  allowBigChanges: boolean;
  activeLessonTab?: ActiveLessonTab;
  handleOpenTab: (isActive: boolean, index: number) => void;
  handleLessonTab: (lessonTab: Partial<ActiveLessonTab>) => void;
  unpublishedCoursesIds: number[];
  getUnpublishedCourses: (ids: number[]) => void;
  setCourseStatus: (status: VersioningStatusEnum) => void;
  allowVersioningMode: AllowMode;
  allowEditingMode: AllowMode;
  courseToPublish(slug: string): Promise<void>;
  book: null | Book;
  setBook: Dispatch<SetStateAction<null | Book>>;
  materialMode: MaterialMode;
  setMaterialMode: Dispatch<SetStateAction<MaterialMode>>;
  reorderScheduledLessons: ScheduledLesson[];
  updateReorderScheduledLessons(scheduledLessons: ScheduledLesson[]): void;
  handleKlassChange(klass: Klass): boolean;
  preventExitWithChanges: (callbackFunc?: () => void) => void;
}

const CourseEditingContext = createContext<CourseEditingProps>(
  {} as CourseEditingProps,
);

export default CourseEditingContext;

export function CourseEditingProvider({ children }: any) {
  const initialActiveTabState: ActiveLessonTab = {
    previousActiveLessonIndex: null,
    activeLessonIndex: null,
    activeTab: 'details',
  };

  async function courseToPublish(slug: string) {
    const coursePath = await updateCoursePath(slug, {
      status: VersioningStatusEnum.PUBLISHED,
    });
    navigate(`../courses/${coursePath.slug}`, { replace: true });
  }
  const [allowBigChanges, setAllowBigChanges] = useState(true);
  const isCancelled = useRef<boolean>(false);
  const [activeLessonTab, setActiveLessonTab] = useState<ActiveLessonTab>();
  const [unpublishedCoursesIds, setUnpublishedCoursesIds] = useState<number[]>(
    [],
  );
  const [reorderScheduledLessons, setReorderScheduledLessons] = useState<
    ScheduledLesson[]
  >([]);
  const [newSelectedKlass, setNewSelectedKlass] = useState<Klass>();
  const updateReorderScheduledLessons = (
    scheduledLessons: ScheduledLesson[],
  ) => {
    setReorderScheduledLessons(scheduledLessons);
  };
  const [openChangeKlassModal, setOpenChangeKlassModal] = useState(false);
  const callbackFuncOnConfirmModal = useRef<() => void>();
  const navigate = useNavigate();

  const [book, setBook] = useState<null | Book>(null);
  const [materialMode, setMaterialMode] = useState<MaterialMode>('default');

  const preventExitWithChanges = (callbackFunc?: () => void) => {
    if (!reorderScheduledLessons.length) callbackFunc?.();
    else {
      callbackFuncOnConfirmModal.current = callbackFunc;
      setOpenChangeKlassModal(true);
    }
  };

  function handleLessonTab(lessonTab: Partial<ActiveLessonTab>) {
    setActiveLessonTab(lt => {
      return lt
        ? {
            ...lt,
            ...lessonTab,
          }
        : { ...initialActiveTabState, ...lessonTab };
    });
  }
  const getUnpublishedCourses = useCallback((ids: number[]) => {
    setUnpublishedCoursesIds(ids);
  }, []);

  const allowVersioningMode: AllowMode = async function (
    { slug, status },
    isFirstVersion = false,
  ) {
    const isCoursePublished = status === VersioningStatusEnum.PUBLISHED;
    const isCourseBeta = status === VersioningStatusEnum.BETA;
    setActiveLessonTab(initialActiveTabState);
    if (isCoursePublished || isCourseBeta) {
      const { data: newCourse } = await requester().patch<CoursePath>(
        `courses/${slug}/`,
        {
          status: VersioningStatusEnum.VERSIONING,
        },
      );
      isFirstVersion = newCourse.version <= 1.0 && isFirstVersion;
      slug = newCourse.slug || slug;
    }
    return goToVersioningPage(slug, isFirstVersion);
  };

  function goToEditingPage(slug: string) {
    return navigate(`/admin/courses/${slug}/lessons/editing`);
  }

  function goToVersioningPage(slug: string, isFirstVersion: boolean = false) {
    return navigate(
      `/admin/courses/${slug}/lessons/${
        isFirstVersion ? 'draft' : 'versioning'
      }`,
    );
  }

  const allowEditingMode: AllowMode = async ({ status, slug }) => {
    setActiveLessonTab(initialActiveTabState);
    if (status === VersioningStatusEnum.PUBLISHED) {
      const { data: newCourse } = await requester().patch<CoursePath>(
        `courses/${slug}/`,
        {
          status: VersioningStatusEnum.EDITING,
        },
      );
      slug = newCourse.slug;
    }
    return goToEditingPage(slug);
  };

  const handleOpenTab = (isActive: boolean, index: number) => {
    if (isActive) {
      handleLessonTab({
        previousActiveLessonIndex: index,
        activeLessonIndex: null,
      });
    } else {
      handleLessonTab({
        activeLessonIndex: index,
        activeTab:
          activeLessonTab?.previousActiveLessonIndex === index
            ? activeLessonTab?.activeTab
            : 'details',
        previousActiveLessonIndex: null,
      });
    }
  };

  function setCourseStatus(status: VersioningStatusEnum) {
    if (status === VersioningStatusEnum.EDITING) {
      setAllowBigChanges(false);
    } else if (status === VersioningStatusEnum.VERSIONING) {
      setAllowBigChanges(true);
    } else {
      setAllowBigChanges(false);
    }
  }

  function getSessionStorage(): ActiveLessonTab | null {
    const sessionJson = sessionStorage.getItem(ACTIVE_LESSON_TAB_STORAGE_KEY);
    if (!sessionJson) {
      return null;
    }
    const session = JSON.parse(sessionJson) as ActiveLessonTab;
    return session;
  }
  const handleKlassChange = (klass: Klass) => {
    if (reorderScheduledLessons.length) {
      setNewSelectedKlass(klass);
      setOpenChangeKlassModal(true);
      return false;
    }
    return true;
  };

  useEffect(() => {
    let isCancelled = false;
    function saveSessionStorage(name: string, data?: {}) {
      if (data) {
        sessionStorage.setItem(name, JSON.stringify(data));
      }
    }

    !isCancelled &&
      saveSessionStorage(ACTIVE_LESSON_TAB_STORAGE_KEY, activeLessonTab);

    return () => {
      isCancelled = true;
    };
  }, [activeLessonTab]);

  useEffect(() => {
    if (!isCancelled.current) {
      const storage = getSessionStorage();
      storage !== null && setActiveLessonTab(storage);
    }
    return () => {
      isCancelled.current = true;
    };
  }, []);

  useEffect(() => {
    const beforeUnload = (e: BeforeUnloadEvent) => {
      e.returnValue = '';
    };
    if (reorderScheduledLessons.length) {
      window.addEventListener('beforeunload', beforeUnload);
    }

    return () => {
      window.removeEventListener('beforeunload', beforeUnload);
    };
  }, [reorderScheduledLessons]);

  const onCancelChangeKlass = () => setOpenChangeKlassModal(false);

  const onConfirmChangeKlass = () => {
    if (callbackFuncOnConfirmModal) callbackFuncOnConfirmModal.current?.();
    else
      navigate(
        `../courses/${newSelectedKlass?.coursePathSlug}/class/${newSelectedKlass?.id}`,
      );
    setOpenChangeKlassModal(false);
    setReorderScheduledLessons([]);
  };

  return (
    <CourseEditingContext.Provider
      value={{
        materialMode,
        setMaterialMode,
        book,
        setBook,
        allowBigChanges,
        activeLessonTab,
        unpublishedCoursesIds,
        handleOpenTab,
        handleLessonTab,
        getUnpublishedCourses,
        setCourseStatus,
        allowVersioningMode,
        allowEditingMode,
        courseToPublish,
        reorderScheduledLessons,
        updateReorderScheduledLessons,
        handleKlassChange,
        preventExitWithChanges,
      }}
    >
      {children}
      <ModalWarning
        onClickCancel={onCancelChangeKlass}
        onClickConfirm={onConfirmChangeKlass}
        translationString="modalChangeKlass"
        visible={openChangeKlassModal}
      />
    </CourseEditingContext.Provider>
  );
}
