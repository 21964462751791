import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { PencilIcon, TrashIcon } from '@heroicons/react/outline';
import { useMutation } from '@tanstack/react-query';

import useCourseEditing from '@/data/hook/useCourseEditing';
import chapterFormatter from '@/functions/chapterFormatter';
import Book, { bookType } from '@/models/Book';
import CoursePath from '@/models/Course';
import { Lesson } from '@/models/Lesson';
import { bookEndpoints } from '@/utils/setBookType';
import { anOldChapter, stringToStringArray } from '@/utils/stringToStringArray';
import MainButton from '@/components/common/buttons/MainButton';
import { HtmlPreview } from '@/components/common/dataDisplay/HtmlPreview';
import { Chapters } from './EditMaterialPage/Chapters';
import { Tooltip } from '@/components/common/dataDisplay/Tooltip';
import ModalDisable from '@/components/common/modals/ModalDisable';
import alert from '@/utils/UseAlert';
import { deleteBook, updateBook } from '@/data/services/bookServices';
import { ApiError } from '@/models/Errors';
import { isPulished } from '@/functions/handleCourseStatusIcon';
import ConditionalRenderer from '@/components/common/ConditionalRenderer';
import { updateChapter } from '@/data/services/chapterServices';
import Chapter from '@/models/Chapter';
import { updateLesson } from '@/data/services/lessonServices';

interface ViewMaterialProps {
  book: Book;
  course?: CoursePath;
  lesson: Lesson;
  readOnly?: boolean;
  bookType: bookType;
  updateLessons?: () => Promise<void>;
}

export default function ViewMaterial({
  book,
  course,
  lesson,
  bookType,
  updateLessons,
  readOnly,
}: ViewMaterialProps) {
  const navigate = useNavigate();

  const { allowBigChanges, setMaterialMode, setBook, handleLessonTab } =
    useCourseEditing();
  const [chapterIndex, setChapterIndex] = useState(0);
  const endpoint = bookEndpoints[bookType];
  const getBaseUrl = (lessonId: number) =>
    `courses/${course?.slug}/lessons/${lessonId}/${endpoint}`;
  const [visibleModal, setVisibleModal] = useState(false);

  type updateBook = (lessonId: number, bookId: number) => Promise<Book>;

  const editBook: updateBook = async (lessonId, bookId) => {
    const params = {
      bookId,
      lessonId,
      bookType,
    };
    const book = await updateBook(params, {});
    return book;
  };

  const navigateEditBookContent = async () => {
    let currentLesson = lesson;
    if (isPulished(currentLesson.status) && course) {
      const updatedLesson = await updateLesson(
        {
          slugCourseName: course.slug,
          lessonId: lesson.id,
        },
        {},
      );
      currentLesson = updatedLesson;
    }

    const updatedBook = await editBook(currentLesson.id, book?.id);

    for (const chapter of updatedBook.chapters) {
      try {
        if (anOldChapter(chapter)) {
          const content = chapterFormatter(
            chapter.title,
            stringToStringArray(chapter.content),
          );

          if (!chapter.book) throw new Error('book not found');

          await updateChapter({
            params: {
              chapterId: chapter.id,
              bookId: chapter.book,
              bookType,
              lessonId: currentLesson.id,
            },
            body: {
              content,
            },
          });
        }
      } catch (error: any) {
        continue;
      }
    }

    return { lesson: currentLesson, book: updatedBook };
  };

  const handleAfterEdit = (book: Book, lesson: Lesson) => {
    if (chapterIndex > 0) {
      const findChapter = book.chapters.find(
        (_chapter, index) => index === chapterIndex,
      );

      if (findChapter) {
        navigate(
          `/admin/${getBaseUrl(lesson.id)}/${book.id}/chapter/${
            findChapter.id
          }`,
        );
      }
    } else {
      navigate(`/admin/${getBaseUrl(lesson.id)}/${book.id}`);
    }
  };

  const { mutate: onEditBook, isLoading: isLoadingEditBook } = useMutation(
    navigateEditBookContent,
    {
      onError(error: any) {
        const apiError = new ApiError(error);
        alert.error(apiError.getErrorMessage());
      },
      onSettled(data) {
        if (data?.book && data?.lesson) handleAfterEdit(data.book, data.lesson);
      },
    },
  );

  const { t } = useTranslation('translation', {
    keyPrefix: 'editMaterialPage',
  });

  const { isLoading: isLoadingDeleteBook, mutate: onDeleteBook } = useMutation(
    deleteBook,
    {
      onSuccess() {
        handleLessonTab({
          previousActiveLessonIndex: null,
          activeLessonIndex: null,
          activeTab: 'details',
        });
        setMaterialMode('default');
        setBook(null);
        alert.success(t('deleted'));
        updateLessons?.();
      },
      onError(error: any) {
        const apiError = new ApiError(error);
        alert.error(apiError.getErrorMessage());
      },
    },
  );

  const requestDeleteBook = async () => {
    const slugCourseName = course?.slug;
    let currentLesson = lesson;
    if (isPulished(lesson.status) && slugCourseName) {
      const editedLesson = await updateLesson(
        {
          lessonId: currentLesson.id,
          slugCourseName,
        },
        {},
      );
      currentLesson = editedLesson;
    }
    const lessonId = currentLesson.id;
    const bookId =
      bookType === 'STUDENT' ? currentLesson.book : currentLesson.teacherBook;

    if (!bookId) {
      throw new Error('Book invalid');
    }
    onDeleteBook({ bookType, lessonId, bookId });
  };

  const chapter = book.chapters.length
    ? book.chapters[chapterIndex]
    : undefined;

  const content = (chapter?: Chapter) => {
    if (chapter) {
      if (anOldChapter(chapter))
        return chapterFormatter(
          chapter.title,
          stringToStringArray(chapter.content),
        );
      return chapter.content;
    }
  };

  return (
    <div className="flex flex-col gap-5">
      <ModalDisable
        testId="deleteBookModal"
        modalType="delete"
        visible={visibleModal}
        onClickConfirm={requestDeleteBook}
        onClickCancel={() => setVisibleModal(false)}
        translationString="modalRemoveBook"
        isRequesting={isLoadingDeleteBook}
      />
      <div className="flex gap-3.5">
        <div className="w-[70%]">
          <HtmlPreview
            className="select-none"
            html={content(chapter) ?? ''}
            format="book"
          />
        </div>

        <Chapters
          courseSlug={course?.slug ?? ''}
          lessonId={lesson.id}
          book={book}
          chapterIndex={chapterIndex}
          setChapterIndex={setChapterIndex}
          chapters={book.chapters}
        />
      </div>
      <ConditionalRenderer condition={course && !readOnly}>
        <div className="flex justify-between gap-4">
          <MainButton
            loading={isLoadingEditBook}
            text={t('editMaterial')}
            icon={<PencilIcon />}
            onClick={() => onEditBook()}
          />
          <Tooltip
            text={!allowBigChanges ? t('cantModify') : null}
            className="w-fit"
          >
            <MainButton
              text={t('deleteMaterial')}
              icon={<TrashIcon />}
              onClick={() => setVisibleModal(true)}
              color="warning"
              disabled={!allowBigChanges}
            />
          </Tooltip>
        </div>
      </ConditionalRenderer>
    </div>
  );
}
