import { NotificationFilterFields } from '@/components/notifications/card/NotificationTypes';
import { ListAlertsFilters } from '@/data/services/alertServices';
import moment from 'moment';
import { AlertFiltersOptions } from '../components/notifications/page/NotificationsPageContent';

export function adapterCardFiltersToFiltersService(
  fields: NotificationFilterFields = {},
): ListAlertsFilters {
  const filtersService: ListAlertsFilters = {};
  if (fields.status === 'today')
    filtersService.alertAt = moment().format('YYYY-MM-DD');
  if (fields.status === 'yesterday')
    filtersService.alertAt = moment()
      .add({
        days: -1,
      })
      .format('YYYY-MM-DD');
  if (fields.status === 'all') delete filtersService.alertAt;

  if (fields.onlyPendings) filtersService.isRead = false;
  else delete filtersService.isRead;

  filtersService.ordering = fields.sortBy === 'asc' ? 'alert_at' : '-alert_at';

  filtersService.type = fields.types?.length ? fields.types : undefined;
  return filtersService;
}

export function adapterPageFiltersToFiltersService({
  status,
  ...fields
}: AlertFiltersOptions = {}): ListAlertsFilters {
  const filtersService: ListAlertsFilters =
    adapterCardFiltersToFiltersService(fields);
  if (status === 'yesterday')
    filtersService.alertAt = moment().add({ days: -1 }).format('YYYY-MM-DD');

  if (status === 'chooseDate')
    filtersService.alertAt = moment(fields.date).format('YYYY-MM-DD');

  if (status === 'today')
    filtersService.alertAt = moment().format('YYYY-MM-DD');

  if (status === 'all') delete filtersService.alertAt;

  if (fields?.unitId?.length) filtersService.unitId = fields.unitId;
  else delete filtersService.unitId;

  if (fields?.klassId?.length) filtersService.klassId = fields.klassId;
  else delete filtersService.klassId;

  if (fields?.teacherId?.length) filtersService.teacherId = fields.teacherId;
  else delete filtersService.teacherId;

  return filtersService;
}
