import Text from '@/components/common/dataDisplay/Text';
import NotificationsList from '@/components/notifications/card/NotificationsList';
import { AlertTypeEnum } from '@/models/Alert';
import { useEffect, useState } from 'react';
import { FormProvider, useForm, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { adapterPageFiltersToFiltersService } from '../../../adapters/alertsAdapters';
import NotificationMobileFilter from './NotificationMobileFilter';
import NotificationLeftFilter from './NotificationsLeftFilters';
import NotificationTopFilters from './NotificationTopFilters';
import { useMediaQuery } from 'react-responsive';
import ConditionalRenderer from '@/components/common/ConditionalRenderer';
import { Breakpoints } from '@/utils/Breakpoint';

export type PageFilterStatus = 'all' | 'today' | 'yesterday' | 'chooseDate';

export type AlertFiltersOptions = {
  types?: AlertTypeEnum[];
  unitId?: number[];
  teacherId?: number[];
  klassId?: number[];
  status?: PageFilterStatus;
  onlyPendings?: boolean;
  sortBy?: 'asc' | 'desc';
  date?: Date;
};
const NotificationsPageContent = () => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'alerts.page',
  });

  const isDektop = useMediaQuery({ minWidth: Breakpoints.md });

  const methods = useForm<AlertFiltersOptions>({
    defaultValues: {
      status: 'all',
      sortBy: 'desc',
      types: [],
      unitId: [],
      teacherId: [],
      klassId: [],
    },
  });
  return (
    <FormProvider {...methods}>
      <main className="flex gap-3.5">
        <ConditionalRenderer condition={isDektop}>
          <NotificationLeftFilter className="w-[300px]" />
        </ConditionalRenderer>
        <section className="flex-grow flex flex-col gap-4 shadow-default rounded-xl border border-neutral-content p-3.5 max-h-[calc(100vh-140px)]">
          <div className="flex w-full justify-between items-center">
            <Text
              className="text-accent font-500"
              text={t('inbox')}
              size="text-18"
            />
            <ConditionalRenderer condition={!isDektop}>
              <NotificationMobileFilter />
            </ConditionalRenderer>
          </div>
          <NotificationTopFilters />
          <NotificationListContainer />
        </section>
      </main>
    </FormProvider>
  );
};

export default NotificationsPageContent;

const NotificationListContainer = () => {
  const [filter, setFilters] = useState<AlertFiltersOptions>();
  const { watch } = useFormContext<AlertFiltersOptions>();

  useEffect(() => {
    const subscription = watch(data => {
      setFilters(data as AlertFiltersOptions);
    });
    return () => subscription.unsubscribe();
  }, [watch]);

  return (
    <NotificationsList filters={adapterPageFiltersToFiltersService(filter)} />
  );
};
