import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import useAuth from '@/data/hook/useAuth';
import NoAdminScreenForMobile from './NoAdminScreenForMobile';
import Layout from '@/components/template/Layout';
import ListKlasses from '@/components/admin/klass/ListKlasses';
import AdminButton from '@/components/common/AdminButton';
import TableSearchIcon from '@/components/common/TableSearchIcon';
import TableSortIcons from '@/components/common/TableSortIcons';
import { UserTypeEnum } from '@/models/User';
import { KlassHeader } from '@/utils/HeaderTypes';
import { useQuery } from '@tanstack/react-query';
import PaginationComponent from './PaginationComponent';
import TableStatistics from '@/components/common/table/TableStatistics';
import AddKlass from '@/components/admin/klass/AddKlass';
import HeadTitle from '@/components/common/HeadTitle';
import { MANAGEMENT_PAGE_SIZE } from '@/constants';
import {
  ListKlassesFilters,
  orderingKlassDate,
} from '@/data/services/klassServices';
import { isSuperAdmin, isTeacher, isUnitAdmin } from '@/functions/auth';
import useFilterParams from '@/utils/UseFilterParams';
import Dropdown from '@/components/common/filter/Dropdown';
import {
  accessCountQueryKeys,
  klassesQueryKeys,
  teacherStudentsCountQueryKeys,
} from '@/data/services/querykeys';
import useStatisticService from '@/data/hook/useStatistics';

export default function ManageKlasses() {
  const { t } = useTranslation('translation', { keyPrefix: 'manageKlasses' });
  const { actionId } = useParams();
  const [isAddKlass, setIsAddKlass] = useState(actionId === 'add');

  const { user } = useAuth();

  const { filterParams, setFilterParams } =
    useFilterParams<ListKlassesFilters>();

  const useIsTeacher = isTeacher(user?.userType);
  const pageNumber = Number(filterParams.get('pageNumber') ?? '1');
  const ordering =
    (filterParams.get('ordering') as orderingKlassDate) ?? undefined;
  const status = filterParams.getAll('status') ?? undefined;
  const unitId = filterParams.getAll('unitId').map(Number) ?? undefined;
  const name = filterParams.get('name') ?? undefined;
  const pageSize = MANAGEMENT_PAGE_SIZE;
  const teacherId = useIsTeacher ? user?.id : undefined;
  const isMultipleUnits = user && user.unitsIds?.length > 1;

  const [isSearch, setIsSearch] = useState(!!name);

  const params = {
    pageNumber,
    pageSize,
    ordering,
    status,
    unitId,
    name,
    teacherId,
  };

  const {
    data: klasses,
    isInitialLoading: isLoadingKlasses,
    isPreviousData,
  } = useQuery({
    keepPreviousData: true,
    ...klassesQueryKeys.list(params),
  });

  const setOrderingParams = (orderBy: orderingKlassDate) => {
    setFilterParams({ ordering: orderBy, pageNumber: 1 });
  };

  const changePages = (numberOfPage: string) => {
    setFilterParams({ pageNumber: Number(numberOfPage) });
  };

  const adminKlassHeader: KlassHeader = {
    klass: {
      title: t('listKlasses.klass'),
      extraFunction: [
        <TableSearchIcon onClick={() => setIsSearch(!isSearch)} />,
      ],
      size: 30,
    },
    unit: {
      title: t('listKlasses.unit'),
      extraFunction: [
        <Dropdown
          type="unitId"
          filter={unitId?.map(id => id.toString()) ?? []}
        />,
      ],
      size: 10,
    },
    start: {
      title: t('listKlasses.start'),
      extraFunction: [
        <TableSortIcons
          onClickSort={value => {
            const orderBy =
              value === 'ASC' ? 'klass_start_date' : '-klass_start_date';
            setOrderingParams(orderBy);
          }}
        />,
      ],
      size: 10,
    },
    end: {
      title: t('listKlasses.end'),
      extraFunction: [
        <TableSortIcons
          onClickSort={value => {
            const orderBy =
              value === 'ASC' ? 'klass_end_date' : '-klass_end_date';
            setOrderingParams(orderBy);
          }}
        />,
      ],
      size: 10,
    },
    status: {
      title: t('listKlasses.status'),
      extraFunction: [<Dropdown type="klassStatus" filter={status ?? []} />],
      size: 5,
    },
    actions: { title: t('listKlasses.actions'), size: 20 },
  };

  const klassHeader: KlassHeader = {
    klass: {
      title: t('listKlasses.klass'),
      extraFunction: [
        <TableSearchIcon onClick={() => setIsSearch(!isSearch)} />,
      ],
      size: 40,
    },
    start: {
      title: t('listKlasses.start'),
      extraFunction: [
        <TableSortIcons
          onClickSort={value => {
            const orderBy =
              value === 'ASC' ? 'klass_start_date' : '-klass_start_date';
            setOrderingParams(orderBy);
          }}
        />,
      ],
      size: 15,
    },
    end: {
      title: t('listKlasses.end'),
      extraFunction: [
        <TableSortIcons
          onClickSort={value => {
            const orderBy =
              value === 'ASC' ? 'klass_end_date' : '-klass_end_date';
            setOrderingParams(orderBy);
          }}
        />,
      ],
      size: 15,
    },
    status: {
      title: t('listKlasses.status'),
      extraFunction: [<Dropdown type="klassStatus" filter={status ?? []} />],
      size: 12,
    },
    actions: { title: t('listKlasses.actions'), size: 20 },
  };

  const isViewUnits =
    isSuperAdmin(user?.userType) ||
    ((isUnitAdmin(user?.userType) || isTeacher(user?.userType)) &&
      isMultipleUnits);

  const header = isViewUnits ? adminKlassHeader : klassHeader;

  const accessStatisticsFilters = {
    userType: UserTypeEnum.TEACHER,
    unitId: isSuperAdmin(user?.userType) ? undefined : user?.unitsIds,
    ...(user?.userType === UserTypeEnum.TEACHER && {
      teacherId: [user?.id],
    }),
  };

  const teacherStatisticsFilters = {
    unitId: isSuperAdmin(user?.userType) ? undefined : user?.unitsIds,
    ...(user?.userType === UserTypeEnum.TEACHER && {
      teacherId: [user?.id],
    }),
  };

  const { statistic: accessForWeek, isInitialLoading: isLoadingAcessForWeek } =
    useStatisticService({
      keepPreviousData: true,
      ...accessCountQueryKeys.list(accessStatisticsFilters),
    });

  const {
    statistic: teacherStudentStatistics,
    isInitialLoading: isLoadingUnitStatistics,
  } = useStatisticService({
    ...teacherStudentsCountQueryKeys.list(teacherStatisticsFilters),
    keepPreviousData: true,
  });

  const studentPerTeacherCount = parseInt(
    (
      (teacherStudentStatistics?.studentsCount || 0) /
      (teacherStudentStatistics?.teachersCount || 1)
    ).toFixed(2),
  );

  const studentsPerKlassCount = parseInt(
    (
      (teacherStudentStatistics?.studentsCount || 0) /
      (teacherStudentStatistics?.klassesCount || 1)
    ).toFixed(2),
  );

  const statisticsCards = [
    {
      title: t('klassesPerTeacher'),
      info: studentsPerKlassCount ?? 0,
      isLoading: isLoadingUnitStatistics,
    },
    {
      title: t('studentsPerTeacher'),
      info: studentPerTeacherCount ?? 0,
      isLoading: isLoadingUnitStatistics,
    },
    {
      title: t('weeklyAccesses'),
      info: accessForWeek?.sevenDaysCount ?? 0,
      isLoading: isLoadingAcessForWeek,
    },
  ];

  return (
    <React.Fragment>
      <HeadTitle routeInfo={t('klasses')} />
      <div className="md:hidden">
        <NoAdminScreenForMobile />
      </div>

      <div className="hidden md:block w-full">
        <Layout className="gap-2 flex flex-col">
          <TableStatistics
            headingText={t('title')}
            statisticsCards={statisticsCards}
            adminButton={
              <AdminButton
                testId="addKlassButton"
                title={t('add')}
                onClick={() => setIsAddKlass(old => !old)}
                open={isAddKlass}
              />
            }
          />
          <AddKlass show={isAddKlass} setShow={setIsAddKlass} />

          <ListKlasses
            isLoading={isLoadingKlasses || isPreviousData}
            klassList={klasses?.results || []}
            klassHeader={header}
            isSearch={isSearch}
          />

          <PaginationComponent
            isLoading={isLoadingKlasses || isPreviousData}
            amountOf={klasses?.meta.pagination.count || 0}
            amountPages={klasses?.meta.pagination.pages || 1}
            changePages={changePages}
            paramsPage={pageNumber}
            managerType="klasses"
          />
        </Layout>
      </div>
    </React.Fragment>
  );
}
