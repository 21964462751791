import { StudentLessonRating } from '@/models/LessonRating';
import Tag, { TagColor } from '../dataDisplay/Tag';
import Text from '../dataDisplay/Text';
import moment from 'moment';
import ConditionalRenderer from '../ConditionalRenderer';
import Skeleton from '../Skeleton';
import { useTranslation } from 'react-i18next';
import { useQuery } from '@tanstack/react-query';
import { lessonsQueryKeys } from '@/data/services/querykeys';

export default function FeedbackCard({
  rating,
}: {
  rating: StudentLessonRating;
}) {
  const { t } = useTranslation('translation', {
    keyPrefix: 'feedbackPage.header',
  });
  const tagContent = (number: number) => {
    let text = '';
    let color: TagColor = 'success';
    switch (number) {
      case 1: {
        text = t('negative');
        color = 'error';
        break;
      }
      case 2: {
        text = t('neutral');
        color = 'warning';
        break;
      }
      case 3: {
        text = t('positive');
        color = 'success';
        break;
      }
    }
    return { text, color };
  };

  const { data: lesson, isInitialLoading: isLoadingLesson } = useQuery({
    ...lessonsQueryKeys.get(rating.lessonId),
  });
  const lessonRatingTagContent = tagContent(rating.lessonRating);
  const teacherRatingTagContent = tagContent(rating.teacherRating);

  return (
    <li className="border bg-base-100 border-primary/40 p-2.5 flex flex-col gap-1.5 rounded-lg w-full">
      <div className="w-full flex justify-between">
        <div className="flex flex-col gap-1.5 md:flex-row md:items-center md:gap-4">
          <div className="flex items-center gap-2">
            <Text text={t('lessonRating')} format="rubik-500" />
            <Tag className="px-2 py-0.5" {...lessonRatingTagContent} />
          </div>
          <div className="flex items-center gap-2">
            <Text text={t('teacherRating')} format="rubik-500" />
            <Tag className="px-2 py-0.5" {...teacherRatingTagContent} />
          </div>
        </div>
        <Text text={moment(rating?.createdAt).format('DD/MM/YYYY')} />
      </div>

      <div className="flex flex-col gap-1.5 md:flex-row md:justify-between">
        <ConditionalRenderer
          condition={!isLoadingLesson}
          fallback={
            <Skeleton className="w-[50%] h-4 rounded-full bg-primary-content" />
          }
        >
          <Text text={lesson?.name} className="text-primary break-words" />
        </ConditionalRenderer>
        <Text text={rating?.klassName} />
      </div>

      <ConditionalRenderer condition={!!rating?.comment}>
        <Text
          className="break-words"
          format="rubik-500"
          size="text-18"
          text={rating?.comment}
        />
      </ConditionalRenderer>
    </li>
  );
}
