import { Method } from 'axios';

export type RequestEvent = {
  url: string;
  method: Method;
  statusCode: number;
  params?: object;
};

export class RequestObserver {
  private subscribers: Subscriber[] = [];

  subscribe(newSubscriber: Subscriber) {
    this.subscribers.push(newSubscriber);
  }

  unsubscribe(id: string) {
    const filteredSubscribers = this.subscribers.filter(
      ({ id: subscriberId }) => id !== subscriberId,
    );
    this.subscribers = filteredSubscribers;
  }

  notifyAll(event: RequestEvent) {
    this.subscribers.forEach(({ action }) => action(event));
  }
}

type SubscribersFn = (event: RequestEvent) => void | Promise<void>;

type Subscriber = {
  id: string;
  action: SubscribersFn;
};
