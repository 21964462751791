import { useState } from 'react';
import Modal from '../modals/Modal';
import { BugIcon } from '@/components/icons';
import MainButton from '../buttons/MainButton';
import ComponentGuard from '../ComponentGuard';
import { UserTypeEnum } from '@/models/User';
import ConditionalRenderer from '../ConditionalRenderer';
import CoursePath, { CourseCategoryEnum } from '@/models/Course';
import { useTranslation } from 'react-i18next';

type BugReportProps = {
  lessonOrder: number;
  coursePath: CoursePath;
};

export function BugReport({ coursePath, lessonOrder }: BugReportProps) {
  const { t } = useTranslation('translation', {
    keyPrefix: 'courseCategory',
    lng: 'pt-BR',
  });
  const [open, setOpen] = useState<boolean>(false);

  const {
    version,
    course: { abbreviation, category },
  } = coursePath;

  const order = lessonOrder < 10 ? `0${lessonOrder}` : `${lessonOrder}`;

  function setCategory(category: CourseCategoryEnum): string {
    const translatedCategory = category ? t(category) : '';

    return translatedCategory.replace(/\s+/g, '+');
  }

  return (
    <ConditionalRenderer condition={!!lessonOrder && !!coursePath}>
      <ComponentGuard roles={[UserTypeEnum.TEACHER, UserTypeEnum.UNIT_ADMIN]}>
        <div className="pt-4">
          <MainButton
            icon={<BugIcon className="w-6 h-6" />}
            text="Bug report"
            onClick={() => setOpen(prev => !prev)}
          />

          <Modal visible={open} onClose={() => setOpen(prev => !prev)}>
            <div className="flex">
              <iframe
                title="bugReport"
                src={`
                https://docs.google.com/forms/d/e/1FAIpQLSeOF0etMbfgv01fBnv6e_Nb599FMgwDUVfTa6Kmg2XF8h0M_w/viewform?usp=pp_url&entry.1042897503=Portal+Astro&entry.1543264451=${abbreviation}&entry.1785241963=${version}&entry.173482920=${setCategory(
                  category,
                )}&entry.1331854282=${order}
                `}
                width="640"
                height="640"
              />
            </div>
          </Modal>
        </div>
      </ComponentGuard>
    </ConditionalRenderer>
  );
}
