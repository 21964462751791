import React, { ReactElement, ReactNode } from 'react';

interface ConditionalWrapperProps {
  condition: boolean;
  wrapper: ReactElement;
  children: ReactNode;
  fallback?: JSX.Element;
}

const ConditionalWrapper = ({
  condition,
  wrapper,
  children,
  fallback,
}: ConditionalWrapperProps) => {
  if (condition) {
    return React.cloneElement(wrapper, { children });
  } else if (fallback) {
    return React.cloneElement(fallback, { children });
  } else {
    return <React.Fragment>{children}</React.Fragment>;
  }
};

export default ConditionalWrapper;
