import { useState } from 'react';
import { Avatar, AvatarItemsEnum, BaseItem } from '../../models/Avatar';
import { getAvatarInfo } from '../../utils/userAvatarUtils';
import ItemDisplay from './ItemDisplay';
import AvatarColorPicker from './AvatarColorPicker';

export default function ItemInventory({
  item,
  onChange,
  subcategory,
  previewAvatar,
}: {
  item: BaseItem;
  subcategory: AvatarItemsEnum;
  previewAvatar: Avatar;
  onChange: (newValue: BaseItem, color: string) => void;
}) {
  const { avatarCategory, baseItem: baseItemAvatar } = getAvatarInfo(
    previewAvatar,
    subcategory,
  );
  const isSameItem = baseItemAvatar ? baseItemAvatar.id === item?.id : false;
  const [color, setColor] = useState(
    isSameItem ? avatarCategory.color : item?.baseColor,
  );
  return (
    <div className="w-auto h-20 aspect-square overflow-visible">
      <ItemDisplay
        item={item}
        type="inventory"
        color={color}
        subcategory={subcategory}
        onChange={onChange}
        isSameItem={isSameItem}
      />
      <AvatarColorPicker
        item={item}
        color={color}
        onChange={newColor => {
          setColor(newColor);
          onChange(item, newColor);
        }}
      />
    </div>
  );
}
