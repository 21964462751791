import i18next from 'i18next';
import Klass from '../models/Klass';
import ScheduledLesson from '../models/ScheduledLesson';
import moment from 'moment';

export enum FormatTypeEnum {
  HEADER = 'HEADER',
  PILL = 'PILL',
  INITIAL_AND_END_TIME = 'INITIAL_AND_END_TIME',
  DAY_AND_MONTH = 'DAY_AND_MONTH',
}

export const FormatScheduledDate = (
  scheduled?: ScheduledLesson,
  klass?: Klass,
  type?: FormatTypeEnum,
): string => {
  let string: string = '';

  const date = moment(scheduled?.datetime || moment());
  const duration = moment(date).add(klass?.duration || 90, 'minutes');

  if (klass) {
    if (type === FormatTypeEnum.PILL) {
      if (i18next.language === 'pt_BR') {
        string =
          date.format('[Dia] DD MMMM, HH:mm-') + duration.format('HH:mm');
      } else {
        string = date.format('DD MMMM, HH:mm-') + duration.format('HH:mm');
      }
    }
  }

  if (scheduled) {
    if (type === FormatTypeEnum.DAY_AND_MONTH) {
      string += `${date.format('DD/MM')}`;
    }
  }

  return string;
};
