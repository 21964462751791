import React from 'react';
import { Avatar, AvatarItemsEnum, BaseItem } from '../../models/Avatar';
import { getAvatarInfo } from '../../utils/userAvatarUtils';
import { useTranslation } from 'react-i18next';
import { BanIcon, ShoppingBagIcon } from '@heroicons/react/outline';
import Text from '../common/dataDisplay/Text';
import { LoadingIcon } from '../icons';
import ItemInventory from './ItemInventory';
import ConditionalRenderer from '../common/ConditionalRenderer';
import { isStudent } from '../../functions/auth';
import useAuth from '../../data/hook/useAuth';

export const ListItemsInventory = ({
  items,
  onChange,
  previewAvatar,
  subcategory,
  isLoading,
  setType,
}: {
  items: BaseItem[];
  onChange: (newValue: BaseItem | null, color: string) => void;
  previewAvatar: Avatar;
  isLoading: boolean;
  subcategory: AvatarItemsEnum;
  setType: () => void;
}) => {
  const renderConditional = items.length > 0 && !!items;

  return (
    <div
      className={`flex flex-row ${
        renderConditional ? '' : 'justify-center items-center'
      } flex-wrap py-0.5 h-40 gap-1.5 w-full scrollbar-thin scrollbar-thumb-primary/40
    scrollbar-track-primary-content scrollbar-thumb-rounded-full scrollbar-track-rounded-full`}
    >
      <RenderItemInventory
        onChange={onChange}
        previewAvatar={previewAvatar}
        subcategory={subcategory}
        setType={setType}
        isLoading={isLoading}
        items={items}
      />
    </div>
  );
};

const RenderItemInventory = ({
  items,
  onChange,
  previewAvatar,
  subcategory,
  isLoading,
  setType,
}: {
  items: BaseItem[];
  onChange: (newValue: BaseItem | null, color: string) => void;
  previewAvatar: Avatar;
  isLoading: boolean;
  subcategory: AvatarItemsEnum;
  setType: () => void;
}) => {
  const { baseItem } = getAvatarInfo(previewAvatar, subcategory);
  const { t } = useTranslation('translation', {
    keyPrefix: 'inventory',
  });
  const { user } = useAuth();

  if (isLoading) {
    return <LoadingIcon className="w-20 h-20 text-accent/40" />;
  } else if (items.length <= 0 && !!items) {
    return (
      <Text text={t('noItems')} className="text-primary" format="rubik-500" />
    );
  } else if (!isLoading && items) {
    return (
      <React.Fragment>
        <div
          onClick={() => onChange(null, '#fff')}
          className={`bg-accent/40 overflow-visible aspect-square flex flex-col gap-0.5 h-20 items-center justify-center rounded-xl cursor-pointer border-2 ${
            baseItem ? 'border-accent/40' : 'border-accent'
          }  `}
        >
          <BanIcon className="w-10 h-10 text-accent" />
          <Text
            text={t('removeItem')}
            className="text-accent font-500 text-12"
          />
        </div>
        {items.map((item: BaseItem) => {
          return (
            <ItemInventory
              subcategory={subcategory}
              previewAvatar={previewAvatar}
              key={item.id}
              onChange={onChange}
              item={item}
            />
          );
        })}
        <ConditionalRenderer condition={isStudent(user?.userType)}>
          <div
            onClick={setType}
            className={`bg-accent/40 aspect-square flex flex-col gap-0.5 h-20 items-center justify-center rounded-xl cursor-pointer border-2 border-accent/40`}
          >
            <ShoppingBagIcon className="w-10 h-10 text-accent" />
            <Text
              text={t('goToShop')}
              className="text-accent font-500 text-12"
            />
          </div>
        </ConditionalRenderer>
      </React.Fragment>
    );
  } else return <React.Fragment />;
};
