import { useTranslation } from 'react-i18next';
import { CourseCategoryEnum } from '@/models/Course';
import { UserTypeEnum } from '@/models/User';
import ComponentGuard from '@/components/common/ComponentGuard';
import ConditionalRenderer from '@/components/common/ConditionalRenderer';
import Text from '../Text';
import CoursePathItem from '@/components/admin/courses/CoursePathItem';
import InfinityList from '@/components/common/InfinityList';
import { VersioningStatusEnum } from '@/enums/VersioningStatus';
import useInfiniteService from '@/data/hook/useInfiniteService';
import { coursePathsQueryKeys } from '@/data/services/querykeys';

export default function UnpublishedCoursesList({
  category,
}: {
  category?: CourseCategoryEnum;
}) {
  const { t } = useTranslation('translation', {
    keyPrefix: 'coursesView.admin',
  });

  const editingStatusList = [
    VersioningStatusEnum.BETA,
    VersioningStatusEnum.EDITING,
    VersioningStatusEnum.VERSIONING,
  ];

  const {
    results: unpublishedCourses,
    hasNextPage,
    isFetchingNextPage,
    fetchNextPage,
  } = useInfiniteService({
    ...coursePathsQueryKeys.list({ category, status: editingStatusList })._ctx
      .infinity,
  });

  return (
    <ComponentGuard roles={[UserTypeEnum.SUPER_ADMIN]}>
      <ConditionalRenderer condition={!!unpublishedCourses.length}>
        <div className="hidden  lg:flex flex-col gap-5 mt-6">
          <Text
            text={t('unpublishedCourses')}
            format="poppins-600"
            className="text-primary"
            size="text-24"
          />
          <Text
            text={t('betaDraftExplanation')}
            className="text-base-content"
          />
          <InfinityList
            hasNextPage={hasNextPage}
            isFetchingNextPage={isFetchingNextPage}
            onReachEnd={fetchNextPage}
            className="grid grid-cols-2 md:grid-cols-4 gap-3"
          >
            {unpublishedCourses.map(coursePath => (
              <CoursePathItem
                isDraft
                key={coursePath.id}
                {...coursePath}
                isCoursePage={true}
              />
            ))}
          </InfinityList>
        </div>
      </ConditionalRenderer>
    </ComponentGuard>
  );
}
