import {
  Avatar,
  AvatarItemsEnum,
  BaseItem,
  InventoryItems,
} from '../models/Avatar';

export type inventoryCategoriesKeys = keyof typeof inventoryCategories;

export const getAvatarInfo = (avatar: Avatar, category: AvatarItemsEnum) => {
  const avatarCategory: any = avatar[category];
  let baseItem: BaseItem | undefined;

  if (category === AvatarItemsEnum.leftHandAcessory) {
    baseItem = avatarCategory.handAcessory;
  } else if (category === AvatarItemsEnum.rightHandAcessory) {
    baseItem = avatarCategory.handAcessory;
  } else {
    baseItem = avatarCategory[category];
  }

  const inventoryCategory = getBaseItemCategory(category);

  return { avatarCategory, baseItem, inventoryCategory };
};

export const getBaseItemCategory = (category: AvatarItemsEnum) => {
  const handCategories = [
    AvatarItemsEnum.leftHandAcessory,
    AvatarItemsEnum.rightHandAcessory,
  ];

  const inventoryCategory = handCategories.includes(category)
    ? 'handAcessory'
    : category;

  return inventoryCategory as keyof InventoryItems;
};

export const changeToAvatarModel = (
  itemCategory: AvatarItemsEnum,
  avatar: Avatar,
  id: number | null,
  color: string | null,
) => {
  const { inventoryCategory } = getAvatarInfo(avatar, itemCategory);
  return { [itemCategory]: { color: color, [inventoryCategory]: id } };
};

export const handleAvatarPatchRequest = (avatar: Avatar) => {
  let object = {};
  Object.values(AvatarItemsEnum).forEach((category, i) => {
    const { avatarCategory, baseItem } = getAvatarInfo(avatar, category);
    if (baseItem) {
      Object.assign(
        object,
        changeToAvatarModel(
          category,
          avatar,
          baseItem.id,
          avatarCategory.color,
        ),
      );
    } else {
      Object.assign(object, changeToAvatarModel(category, avatar, null, null));
    }
  });
  return object;
};

export const inventoryCategories = {
  base: [AvatarItemsEnum.base, AvatarItemsEnum.face, AvatarItemsEnum.hair],
  top: [AvatarItemsEnum.shirt, AvatarItemsEnum.coat],
  bottom: [AvatarItemsEnum.pants, AvatarItemsEnum.shoe],
  accessories: [
    AvatarItemsEnum.bottomFaceAcessory,
    AvatarItemsEnum.hat,
    AvatarItemsEnum.topFaceAcessory,
    AvatarItemsEnum.neckAcessory,
  ],
  equipment: [
    AvatarItemsEnum.rightHandAcessory,
    AvatarItemsEnum.leftHandAcessory,
  ],
};
