import { ActivityTypeEnum } from './Activity';

export type CodeEditorActivity = {
  id: number;
  activity: number;
  type: ActivityTypeEnum;
  identifier: string;
  order: number;
  title: string;
  subtitle: string;
  content: string;
  language: LanguageCodeEditorEnum;
  initialCode: string | null;
  expectedOutput: string | null;
};

export enum LanguageCodeEditorEnum {
  JAVASCRIPT = 'JAVASCRIPT',
  PYTHON = 'PYTHON',
}

export type CodeEditorRuntime = {
  aliases: string[];
  language: LanguageCodeEditorEnum;
  version: string;
};

export type ExecuteCode = {
  language: string;
  version: string;
  files: File[];
  input?: string;
};

type File = {
  name: string;
  content: string;
  encoding: string;
};
