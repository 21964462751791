import { handleUserFullName } from '@/functions/handleUserFullName';
import { GradeEnum } from '@/models/ScheduledLessonReport';
import User from '@/models/User';
import { Avatar } from '../../dataDisplay/Avatar';
import { Rating } from '../../dataDisplay/Rating';
import Text from '../../dataDisplay/Text';
import { useTranslation } from 'react-i18next';
import { HomeworkActivityProgress } from '@/models/HomeworkActivity';
import { Controller, useForm } from 'react-hook-form';
import { handleHomeworkNotDone } from '@/utils/teacherHomeworkNotification';

interface StudentHomeworkItemProps {
  student: User;
  defineHomeworkGrade(
    reportId: number,
    grade: GradeEnum | null,
    hasDone: boolean,
  ): void;
  homeworkProgress: HomeworkActivityProgress;
}

export const StudentHomeworkItem = ({
  student,
  defineHomeworkGrade,
  homeworkProgress,
}: StudentHomeworkItemProps) => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'modalHomework',
  });
  const grade = homeworkProgress.activityProgress.grade || 0;

  const { control, register, watch } = useForm<{
    grade: number;
    isChecked: boolean;
  }>({
    defaultValues: {
      grade,
      isChecked: handleHomeworkNotDone(homeworkProgress),
    },
  });
  const isChecked = watch('isChecked');
  const activityProgressId = homeworkProgress.activityProgress.id;

  const defineCheckboxGrade = (notDone: boolean) => {
    const changeGrade = notDone ? 0 : grade;
    defineHomeworkGrade(activityProgressId, changeGrade, !notDone);
  };

  return (
    <div
      data-testid={'studentHomeworkItem' + student.id}
      className="flex gap-2 justify-center xs:justify-between items-center flex-wrap"
    >
      <span className="flex gap-2 items-center justify-center">
        <Avatar
          className="h-fit cursor-default shrink-0"
          testId={'studentAvatar' + student.id}
          size="6"
          userId={student.id}
        />
        <Text
          testId={'studentName' + student.id}
          text={handleUserFullName(student)}
          format="rubik-500"
          className="flex text-primary transition ease-in-out duration-150"
        />
      </span>
      <span className="flex gap-6 items-center mx-auto">
        <Controller
          control={control}
          name="grade"
          render={({ field: { value, onChange } }) => (
            <Rating
              length={5}
              isDisabled={isChecked}
              value={isChecked ? 0 : value}
              testId={'studentHomework' + student.id}
              className={`items-center ${isChecked ? 'opacity-40' : ''}`}
              onClickRating={grade => {
                onChange(grade);
                defineHomeworkGrade(activityProgressId, grade, !isChecked);
              }}
            />
          )}
        />

        <span className="flex gap-2 items-center">
          <input
            data-testid={'studentCheckbox' + student.id}
            disabled={!!homeworkProgress.answer}
            type="checkbox"
            className="checkbox checkbox-primary border-2 border-primary rounded-md disabled:opacity-10"
            {...register('isChecked', {
              onChange(event) {
                defineCheckboxGrade(event.target.checked);
              },
            })}
          />
          <Text
            testId={'studentNotDone' + student.id}
            text={t('notDone')}
            className="h-fit text-14"
          />
        </span>
      </span>
    </div>
  );
};
