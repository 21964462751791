import requester from '../../axios';
import Chapter from '../../models/Chapter';
import JsonApiResponse from '@/models/JsonApiResponse';
import { bookEndpoints } from '@/utils/setBookType';
import { t } from 'i18next';
import { GetBookParams, handleBookEndpoint } from './bookServices';
import { bookType } from '@/models/Book';

interface ListChaptersFilters {
  book?: number;
  order?: number;
}

export type ChapterParams = GetBookParams & {
  chapterId: number;
};

export const listChapters = async (filters?: ListChaptersFilters) => {
  const {
    data: { results },
  } = await requester().get<JsonApiResponse<Chapter>>(`chapters/`, {
    params: filters,
  });
  return results;
};

export async function createChapter(params: GetBookParams) {
  const { lessonId, bookType, bookId } = params;
  const body = {
    book: bookId,
    title: t('editMaterialPage.chapterTitle'),
    content: `<h2>${t('editMaterialPage.placeholderEditor')}</h2>`,
  };

  const endpoint = bookEndpoints[bookType];

  const { data: chapter } = await requester().post<Chapter>(
    `lessons/${lessonId}/${endpoint}/${bookId}/chapters/`,
    body,
  );

  return chapter;
}

export type UpdateChapter = {
  params: ChapterParams;
  body: Partial<Omit<Chapter, 'order'>>;
};

export async function updateChapter({ params, body }: UpdateChapter) {
  const { lessonId, bookType, bookId, chapterId } = params;

  const endpoint = bookEndpoints[bookType];

  const { data: chapter } = await requester().patch<Chapter>(
    `lessons/${lessonId}/${endpoint}/${bookId}/chapters/${chapterId}/`,
    body,
  );

  return chapter;
}

export async function deleteChapter(params: ChapterParams) {
  const { lessonId, bookType, bookId, chapterId } = params;

  const endpoint = bookEndpoints[bookType];

  await requester().delete<void>(
    `lessons/${lessonId}/${endpoint}/${bookId}/chapters/${chapterId}/`,
  );
}

export type UpdateChaptersOrder = {
  params: GetBookParams;
  chapters: { id: number }[];
};

export async function updateChaptersOrder({
  params,
  chapters,
}: UpdateChaptersOrder) {
  const { lessonId, bookType, bookId } = params;

  const endpoint = bookEndpoints[bookType];

  await requester().patch(
    `lessons/${lessonId}/${endpoint}/${bookId}/chapters/order/`,
    chapters,
  );
}

export type GetChapter = {
  lessonId: number;
  bookId: number;
  chapterId: number;
  bookType: bookType;
};

export const getChapter = async ({
  lessonId,
  bookId,
  chapterId,
  bookType,
}: GetChapter) => {
  const endpoint = handleBookEndpoint(bookType);

  const { data } = await requester().get<Chapter>(
    `lessons/${lessonId}/${endpoint}/${bookId}/chapters/${chapterId}/`,
  );
  return data;
};
