import React, { useEffect, useState } from 'react';
import { useQuery } from '@tanstack/react-query';
import ConditionalRenderer from './ConditionalRenderer';
import { AxiosError } from 'axios';
import { imagesQueryKeys } from '@/data/services/querykeys';
interface AstroImageValidationProps {
  renderLoading?: React.FC;
  timeout?: number;
}
interface AstroImageProps
  extends React.DetailedHTMLProps<
    React.ImgHTMLAttributes<HTMLImageElement>,
    HTMLImageElement
  > {
  alternativesrc?: string;
  validation?: AstroImageValidationProps;
}

export default function AstroImage({
  validation,
  alternativesrc = '/images/img-placeholder.png',
  alt = 'Imagem na plataforma Astro',
  ...props
}: AstroImageProps) {
  if (!!validation) {
    return (
      <AstroImageWithValidation
        validation={validation}
        alternativesrc={alternativesrc}
        alt={alt}
        {...props}
      />
    );
  }
  return (
    <AstroImageWithoutValidation
      alternativesrc={alternativesrc}
      alt={alt}
      {...props}
    />
  );
}

type AstroImageWithoutValidationProps = AstroImageProps &
  Required<Pick<AstroImageProps, 'alternativesrc' | 'alt'>>;

function AstroImageWithoutValidation({
  id,
  alt,
  alternativesrc,
  src: urlImg,
  ...props
}: AstroImageWithoutValidationProps) {
  const [src, setSrc] = useState<string>(urlImg || alternativesrc);

  return (
    <img
      id={id}
      alt={alt}
      src={src}
      onError={() => setSrc(alternativesrc)}
      {...props}
    />
  );
}

type AstroImageWithValidationProps = AstroImageProps &
  Required<Pick<AstroImageProps, 'alternativesrc' | 'validation' | 'alt'>>;

function AstroImageWithValidation({
  validation,
  src: urlImg,
  ...props
}: AstroImageWithValidationProps) {
  const [src, setSrc] = useState<string>(urlImg || props.alternativesrc);
  const { renderLoading: LoadingComponent, timeout } = validation;
  const {
    isInitialLoading: isLoading,
    isError,
    isSuccess,
  } = useQuery({
    ...imagesQueryKeys.get(urlImg ?? '', timeout),
    enabled: !!urlImg,
    retry: (failureCount, error) => {
      return (
        error instanceof AxiosError &&
        error.code === AxiosError.ERR_NETWORK &&
        failureCount < 3
      );
    },
  });

  useEffect(() => {
    let mounted = true;
    if (isError && mounted) {
      setSrc(props.alternativesrc);
    }
    return () => {
      mounted = false;
    };
  }, [isError, urlImg, props.alternativesrc]);

  useEffect(() => {
    let mounted = true;
    if (isSuccess && mounted) {
      setSrc(urlImg as string);
    }
    return () => {
      mounted = false;
    };
  }, [isSuccess, urlImg]);

  if (isLoading) {
    return (
      <ConditionalRenderer condition={LoadingComponent}>
        {LoadingComponent && <LoadingComponent />}
      </ConditionalRenderer>
    );
  }

  return (
    <img
      {...props}
      alt={props.alt}
      src={src}
      onError={() => setSrc(props.alternativesrc)}
    />
  );
}
