import JsonApiResponse from '@/models/JsonApiResponse';
import requester from '@/axios';
import { ActivityProgressAttempt } from '@/models/ActivityProgress';
import { BaseFilters, ListServiceFunc } from '@/models/Service';

export const createActivityProgressAttempt = async (
  activityProgressId: number,
) => {
  const { data: attempt } = await requester().post<ActivityProgressAttempt>(
    `activity-progress/${activityProgressId}/activity-progress-attempt/`,
    {
      activityProgress: activityProgressId,
    },
  );
  return attempt;
};

export type GetActivityProgressAttemptParams = {
  activityProgressId: number;
  attemptId: number;
};

export async function getActivityProgressAttempt({
  activityProgressId,
  attemptId,
}: GetActivityProgressAttemptParams) {
  const { data } = await requester().get<ActivityProgressAttempt>(
    `activity-progress/${activityProgressId}/activity-progress-attempt/${attemptId}/`,
  );
  return data;
}

export type ListActivityProgressAttempt = BaseFilters;

type ActivityProgressAttemptRouteParams = {
  activityProgressId: number;
};

export const listActivityProgressAttempt: ListServiceFunc<
  ActivityProgressAttempt,
  ListActivityProgressAttempt,
  ActivityProgressAttemptRouteParams
> = async (filters, routeParams) => {
  const { data } = await requester().get<
    JsonApiResponse<ActivityProgressAttempt>
  >(
    `activity-progress/${routeParams?.activityProgressId}/activity-progress-attempt/`,
    {
      params: filters,
    },
  );

  return data;
};
