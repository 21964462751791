import Text from '@/components/common/dataDisplay/Text';
import { useTranslation } from 'react-i18next';
import Skeleton from '@/components/common/Skeleton';
import ConditionalRenderer from '@/components/common/ConditionalRenderer';

interface AboutKlassProps {
  klassLessonCount: number;
  klassMissedCount: number;
  klassProgressAvg: number;
  klassAttendancePercentage: number;
  klassDescription?: string;
  isLoading?: boolean;
}

export default function AboutKlass(props: AboutKlassProps) {
  const {
    klassLessonCount,
    klassMissedCount,
    klassAttendancePercentage,
    klassProgressAvg,
    klassDescription,
    isLoading,
  } = props;

  const { t } = useTranslation('translation', {
    keyPrefix: 'klassProgressPage',
  });

  const loading = <Skeleton className="h-4 w-4 rounded bg-primary-content" />;

  return (
    <div className="w-48 flex text-14 gap-5 pt-3 flex-col">
      <Text
        text={t('aboutKlass')}
        size="text-16"
        className="text-primary"
        format="rubik-500"
      />
      <div className="flex items-center gap-1 text-secondary bg-secondary-content py-1 px-2.5 rounded-full w-fit self-center">
        <Text text={`${t('progressAverage')}:`} />
        <ConditionalRenderer condition={!isLoading} fallback={loading}>
          <Text text={`${klassProgressAvg || 0}%`} />
        </ConditionalRenderer>
      </div>
      <div className="flex w-full flex-wrap items-center gap-2">
        <div className="flex items-center gap-1">
          <Text text={`${t('lessons')}:`} />
          <ConditionalRenderer condition={!isLoading} fallback={loading}>
            <Text text={klassLessonCount || 0} className="text-primary" />
          </ConditionalRenderer>
        </div>
        <div className="flex items-center gap-1">
          <Text text={`${t('absences')}:`} />
          <ConditionalRenderer condition={!isLoading} fallback={loading}>
            <Text text={klassMissedCount || 0} className="text-primary" />
          </ConditionalRenderer>
        </div>
        <div className="flex items-center gap-1">
          <Text text={`${t('averagePP')}:`} />
          <ConditionalRenderer condition={!isLoading} fallback={loading}>
            <Text
              text={`${klassAttendancePercentage || 0}%`}
              className="text-primary"
            />
          </ConditionalRenderer>
        </div>
      </div>
      <ConditionalRenderer condition={klassDescription}>
        <Text text={klassDescription} />
      </ConditionalRenderer>
    </div>
  );
}
