import JsonApiResponse from '@/models/JsonApiResponse';
import {
  QueryKey,
  UseQueryOptions,
  useQuery,
  useQueryClient,
} from '@tanstack/react-query';

type Options<Model, TQueryKey extends QueryKey> = UseQueryOptions<
  JsonApiResponse<Model>,
  Error,
  JsonApiResponse<Model>,
  TQueryKey
>;

export default function useStatisticService<
  Model,
  StatisticCalculatorResult,
  TQueryKey extends QueryKey = QueryKey,
>(
  options: Options<Model, TQueryKey>,
  calculator?: (data: Model) => StatisticCalculatorResult,
) {
  const { data, ...rest } = useQuery(options);

  const statistic = data?.results.length ? data.results[0] : undefined;

  const queryClient = useQueryClient();

  const invalidate = () => queryClient.invalidateQueries(options.queryKey);

  const commom = { ...rest, invalidate };

  return {
    ...commom,
    statistic,
    calculated: statistic ? calculator?.(statistic) : undefined,
  };
}
