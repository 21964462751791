import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import requester from '@/axios';
import { ApiError } from '@/models/Errors';
import RoundedButton from '@/components/common/buttons/RoundedButton';
import Text from '@/components/common/dataDisplay/Text';
import Modal from '@/components/common/modals/Modal';
import alert from '@/utils/UseAlert';
import { VersioningStatusEnum } from '@/enums/VersioningStatus';

interface BetaModalProps {
  onClose(): void;
  slug: string;
  visible: boolean;
}

function BetaModal({ onClose, slug, visible }: BetaModalProps) {
  const { t } = useTranslation('translation', {
    keyPrefix: 'adminPage.lesson',
  });
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  async function courseToBeta() {
    try {
      setIsLoading(true);
      await requester().patch(`courses/${slug}/`, {
        status: VersioningStatusEnum.BETA,
      });
      alert.success(t('modal.beta.success'));
      setIsLoading(false);
      onClose();
      navigate(`/courses/${slug}`);
    } catch (error: any) {
      const apiError = new ApiError(error);
      setIsLoading(false);
      alert.error(apiError.getErrorMessage());
    }
  }
  return (
    <Modal onClose={onClose} visible={visible}>
      <div className="flex flex-col items-center gap-6">
        <Text
          text={t('modal.beta.title')}
          className="text-primary text-center"
          format="rubik-500"
          size="text-20"
        />
        <Text text={t('modal.beta.warning')} className="text-center" />
        <Text text={t('modal.beta.question')} className="text-center" />
        <div className="flex items-center gap-7 w-full">
          <RoundedButton
            className="w-full"
            text={t('modal.beta.cancel')}
            onClick={onClose}
            color="neutral"
          />
          <RoundedButton
            className="w-full"
            testId="betaButton"
            loading={isLoading}
            onClick={courseToBeta}
            text={t('modal.beta.confirm')}
            color="gradient"
          />
        </div>
      </div>
    </Modal>
  );
}

export default BetaModal;
