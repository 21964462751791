import React from 'react';
import User from '../../../models/User';
import Text from '../../common/dataDisplay/Text';
import SelectedAnswer from './SelectedAnswer';
import { AttemptAnswer } from '../../../models/ActivityProgress';
import Skeleton from '../../common/Skeleton';
import { Avatar } from '../../common/dataDisplay/Avatar';

interface StudentAnswerProps {
  student: User;
  attempt?: AttemptAnswer;
  isLoadingAnswer?: boolean;
}

function StudentAnswer({
  student,
  attempt,
  isLoadingAnswer = false,
}: StudentAnswerProps) {
  const { firstName } = student;
  return (
    <div className="flex items-center gap-3">
      <div className="avatar">
        <div className="w-6 rounded-full">
          <Avatar testId="studentAnswer" userId={student.id} />
        </div>
      </div>
      <Text text={firstName} format="rubik-500" className="text-primary" />
      {isLoadingAnswer && (
        <Skeleton className="w-6 h-6 content-none rounded-full" />
      )}
      {attempt && <SelectedAnswer {...attempt} />}
    </div>
  );
}

export default StudentAnswer;
