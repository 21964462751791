import React from 'react';

interface Heading3Props {
  text: string | undefined;
  className?: string;
  id?: string;
}

export default function Heading3(props: Heading3Props) {
  return (
    <React.Fragment>
      <h3
        id={props.id || ''}
        className={`font-poppins font-600 text-28 leading-tight ${props.className}`}
      >
        {props.text}
      </h3>
    </React.Fragment>
  );
}
