import ErrorDispatcher from './ErrorDispatcher';

export class ScheduledLessonNotFoundError implements ErrorDispatcher {
  constructor(
    private readonly scheduledLessonId: number,
    private readonly klassId: number,
    private readonly page: number,
  ) {}

  public getError() {
    return new Error(
      `Scheduled lesson not found - scheduledLesson: ${this.scheduledLessonId} - klass: ${this.klassId} - page: ${this.page}`,
    );
  }
}

export class ScheduledLessonByKlassNotFoundDispatcher
  implements ErrorDispatcher
{
  constructor(private readonly klassId: number) {
    this.klassId = klassId;
  }

  public getError() {
    return new Error(
      `Scheduled lesson not found in this klass: ${this.klassId}`,
    );
  }
}
