import React, { useRef } from 'react';
import Text from './dataDisplay/Text';
import { useTranslation } from 'react-i18next';

export default function MadeByCtrlPlay() {
  const { t } = useTranslation();
  const heartRef = useRef<HTMLSpanElement>(null);

  const handleHover = () => {
    if (heartRef.current) {
      heartRef.current.classList.add('animate-[heart_1s_ease-in-out_infinite]');
    }
  };

  const handleMouseLeave = () => {
    if (heartRef.current) {
      heartRef.current.classList.remove(
        'animate-[heart_1s_ease-in-out_infinite]',
      );
    }
  };

  return (
    <div
      id="madeByCtrlPlay"
      className="flex self-end gap-1 mt-3 text-12 md:text-14 text-secondary/40"
      onMouseEnter={handleHover}
      onMouseLeave={handleMouseLeave}
    >
      <Text text={t('common.madeBy')} />
      <span className="flex">
        <Text format="rubik-500" className="text-primary/70" text="Ctrl+" />
        <Text format="rubik-500" className="text-warning/70" text="Play" />
      </span>
      <Text text={t('common.withLove')} />
      <span ref={heartRef}>
        <Text className="animate-[heart_1s_ease-in-out_2]" text="🧡" />
      </span>
      <Text text={t('common.inBrazil')} />
    </div>
  );
}
