import useTreeArea from '@/data/hook/useTreeArea';
import Area from '@/models/Area';
import useFilterParams from '@/utils/UseFilterParams';
import { TagIcon } from '@heroicons/react/outline';
import { TopicsFiltersParams } from '../../TopicsPageContent';
import Text from '@/components/common/dataDisplay/Text';

type TopicTreeTagProps = {
  area: Area;
};

export default function TopicTreeTag({ area }: TopicTreeTagProps) {
  const { getPath } = useTreeArea();
  const { setFilterParams } = useFilterParams<TopicsFiltersParams>();
  const onClick = () => {
    setFilterParams({
      area: area.id,
      path: getPath(area.id)?.slice(0, -1),
    });
  };
  return (
    <button className="flex gap-2.5 items-center truncate" onClick={onClick}>
      <TagIcon className="min-w-5 min-h-5 h-5 w-5 text-primary" />
      <Text text={area.name} className="truncate" />
    </button>
  );
}
