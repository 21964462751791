import { ChangeEventHandler, useState } from 'react';
import { debounce } from 'lodash';
import 'react-day-picker/dist/style.css';
import { useTranslation } from 'react-i18next';
import { PlusIcon, SearchIcon } from '@heroicons/react/outline';
import { Controller, useForm } from 'react-hook-form';

import OutlineButton from '@/components/common/buttons/OutlineButton';
import InfiniteSearchInput from '@/components/common/dataInput/InfiniteSearchInput';
import ComponentGuard from '@/components/common/ComponentGuard';
import HeadTitle from '@/components/common/HeadTitle';
import PageTitle from '@/components/common/PageTitle';
import MainButton from '@/components/common/buttons/MainButton';
import TextInput from '@/components/common/dataInput/TextInput';
import ProjectCarousel from '@/components/projects/ProjectCarousel';
import ModalCreateProject from '@/components/projects/ModalCreateProject';
import Layout from '@/components/template/Layout';
import useStudentContext from '@/data/hook/student';
import useAuth from '@/data/hook/useAuth';
import { ProjectFilters } from '@/data/services/projectServices';
import { UserTypeEnum } from '@/models/User';
import { toolsQueryKeys } from '@/data/services/querykeys';
import ConditionalRenderer from '@/components/common/ConditionalRenderer';
import { isTeacher } from '@/functions/auth';

export default function ProjectPage() {
  const { t } = useTranslation('translation', { keyPrefix: 'projectPage' });

  type ProjectFilterForm = {
    toolName: string;
    title: string;
  };

  const [title, setTitle] = useState('');

  const [isModalVisible, setIsModalVisible] = useState(false);

  const { watch, register, control } = useForm<ProjectFilterForm>();

  enum ProjectFilterEnum {
    ALL = 'ALL',
    MINE = 'MINE',
    MYKLASS = 'MYKLASS',
    MYCOURSE = 'MYCOURSE',
  }

  const [selectedFilter, setSelectedFilter] = useState<ProjectFilterEnum>(
    ProjectFilterEnum.ALL,
  );

  const { klass, progress, validEnrollment } = useStudentContext();

  const toolName = watch('toolName');
  const { user } = useAuth();
  const coursesAbbreviations =
    progress
      ?.map(({ coursePath: { course } }) => course.abbreviation)
      .join(',') || '';

  const filters: ProjectFilters = {
    courseAbbreviation:
      selectedFilter === ProjectFilterEnum.MYCOURSE
        ? coursesAbbreviations
        : undefined,
    klass:
      selectedFilter === ProjectFilterEnum.MYKLASS
        ? String(klass?.id)
        : undefined,
    user: selectedFilter === ProjectFilterEnum.MINE ? user?.id : undefined,
    toolName: toolName || undefined,
    title: title || undefined,
  };

  interface FilterControls {
    text: string;
    id: ProjectFilterEnum;
    roles: 'ALL' | UserTypeEnum[];
  }
  const filterControls: FilterControls[] = [
    {
      text: t('filters.myProjects'),
      id: ProjectFilterEnum.MINE,
      roles: [UserTypeEnum.STUDENT],
    },
    {
      text: t('filters.myClassProjects'),
      id: ProjectFilterEnum.MYKLASS,
      roles: [UserTypeEnum.STUDENT],
    },
    {
      text: t('filters.myCourseProjects'),
      id: ProjectFilterEnum.MYCOURSE,
      roles: [UserTypeEnum.STUDENT],
    },
  ];
  const handleControls = ({ roles, id }: FilterControls) => {
    if (!validEnrollment && id === ProjectFilterEnum.MYKLASS) return false;

    return roles === 'ALL' || (user && roles.includes(user.userType));
  };

  const closeModal = () => setIsModalVisible(false);
  const onChangeTitle: ChangeEventHandler<HTMLInputElement> = e => {
    setTitle(e.target.value);
  };
  const debouncedChangeTitle = debounce(onChangeTitle, 800);

  return (
    <Layout>
      <HeadTitle routeInfo={t('projects')} />
      <ModalCreateProject
        teacher={klass?.teacherId}
        onClose={closeModal}
        isVisible={isModalVisible}
      />
      <PageTitle headingText={t('projects')} />
      <div className="flex gap-x-3 mt-4">
        <div className="w-full flex flex-col flex-wrap gap-3.5 lg:flex-row lg:justify-between">
          <div className="flex gap-2.5 flex-wrap items-center">
            {filterControls.filter(handleControls).map(({ text, id }) => (
              <OutlineButton
                key={id}
                text={text}
                active={selectedFilter === id}
                onClick={() =>
                  setSelectedFilter(
                    selectedFilter === id ? ProjectFilterEnum.ALL : id,
                  )
                }
                className="shrink-0 text-14 py-1 px-2.5 cursor-pointer"
              />
            ))}
            <Controller
              control={control}
              name="toolName"
              render={({ field: { onChange } }) => (
                <InfiniteSearchInput
                  displayName={tool => tool.name}
                  service={toolsQueryKeys.list}
                  onSelect={({ name }) => onChange(name)}
                  input={{
                    testId: 'toolSelect',
                    placeholder: t('allTools'),
                    className: { input: 'max-w-lg', base: 'font-500' },
                  }}
                  onDeselect={() => onChange('')}
                />
              )}
            />
          </div>

          <div className="flex gap-2.5 items-center">
            <ComponentGuard
              roles={[UserTypeEnum.STUDENT, UserTypeEnum.TEACHER]}
            >
              <ConditionalRenderer
                condition={validEnrollment || isTeacher(user?.userType)}
              >
                <MainButton
                  className="text-xs items-center"
                  text={t('addProject')}
                  onClick={() => setIsModalVisible(true)}
                  icon={<PlusIcon />}
                />
              </ConditionalRenderer>
            </ComponentGuard>
            <TextInput
              register={register('title', {
                onChange: debouncedChangeTitle,
              })}
              placeholder={t('search')}
              icon={<SearchIcon />}
            />
          </div>
        </div>
      </div>
      <div className="flex flex-col max-w-full mt-4">
        <ProjectCarousel
          title={t('highlights')}
          filters={{ ...filters, byUpVotes: 'more' }}
        />
        <ProjectCarousel title={t('recents')} filters={filters} />
      </div>
    </Layout>
  );
}
