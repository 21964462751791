import PageTitle from '@/components/common/PageTitle';
import Layout from '@/components/template/Layout';
import Klass from '@/models/Klass';
import { useParams, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import NoAdminScreenForMobile from './NoAdminScreenForMobile';
import KlassProgressTable from '@/components/admin/klassesTable/KlassProgressTable';
import InfiniteSearchInput from '@/components/common/dataInput/InfiniteSearchInput';
import { translateKlassName } from '@/utils/translateKlassName';
import { getAuthorizedUnits } from '@/utils/getAuthorizedUnits';
import useAuth from '@/data/hook/useAuth';
import { REQUEST_STALE_TIME_IN_MS } from '@/constants';
import { useQuery } from '@tanstack/react-query';
import { klassesQueryKeys } from '@/data/services/querykeys';

export default function KlassProgressView() {
  const { t } = useTranslation('translation', {
    keyPrefix: 'klassProgressPage',
  });
  const { user } = useAuth();

  const { klassId: klassIdParam } = useParams();

  const klassId = Number(klassIdParam);

  const defaultQueryOptionEnable = {
    enabled: !!klassId && !isNaN(klassId),
    staleTime: REQUEST_STALE_TIME_IN_MS,
  };

  const {
    data: klass,
    isFetching: loadingKlass,
    error: klassError,
  } = useQuery({
    ...klassesQueryKeys.get(klassId),
    ...defaultQueryOptionEnable,
    retry: false,
  });

  const navigate = useNavigate();

  const onSelectKlass = ({ id }: Klass) => {
    navigate(`/class/${id}/progress`);
  };
  const error = klassError;

  return (
    <Layout>
      <div className="sm:hidden">
        <NoAdminScreenForMobile />
      </div>
      <div className="hidden sm:flex gap-2.5 flex-col">
        <PageTitle headingText={t('pageTitle')} backButton position="mb-0" />
        <InfiniteSearchInput
          service={klassesQueryKeys.list}
          filters={{ unitId: getAuthorizedUnits(user) }}
          displayName={klass => translateKlassName(klass.name)}
          onSelect={onSelectKlass}
          selectedItem={klass}
          blockDeselect
          input={{ className: { base: 'w-96 font-500' } }}
        />

        <KlassProgressTable
          key={klassId}
          klass={klass}
          error={error}
          isLoading={loadingKlass}
        />
      </div>
    </Layout>
  );
}
