import useAuth from '@/data/hook/useAuth';
import TeamHomeworkView from './TeamHomeworkView';
import StudentHomeworkView from './StudentHomeworkView';
import Text from '../../common/dataDisplay/Text';
import { useTranslation } from 'react-i18next';
import HeadTitle from '../../common/HeadTitle';
import useStudentContext from '@/data/hook/student';
import { useQuery } from '@tanstack/react-query';
import { useParams } from 'react-router-dom';
import { makeLessonHeadTitle } from '@/utils/lessonHeadTitle';
import ConditionalRenderer from '../../common/ConditionalRenderer';
import { isStudent } from '@/functions/auth';
import useLessonContext from '@/data/hook/lesson';
import { coursePathsQueryKeys } from '@/data/services/querykeys';

function HomeworkView() {
  const { t } = useTranslation('translation', {
    keyPrefix: 'lesson.activity',
  });

  const { user } = useAuth();
  const { slugCourseName } = useParams();
  const { lesson, scheduledLesson } = useLessonContext();
  const { currentProgress } = useStudentContext();
  const { data: course } = useQuery({
    enabled: !!slugCourseName && !currentProgress,
    ...coursePathsQueryKeys.get(slugCourseName ?? ''),
  });
  const coursePath = course ?? currentProgress?.coursePath;

  const title = `${t('homework')} - ${makeLessonHeadTitle({
    lessonOrder: lesson?.order || 0,
    coursePath,
    lessonType: scheduledLesson?.type,
  })}`;
  return (
    <div className="flex flex-col gap-5 items-center justify-center">
      <HeadTitle routeInfo={title} />
      <Text
        text={t('homework')}
        format="poppins-600"
        size="text-20"
        className="text-primary text-center"
      />

      <ConditionalRenderer condition={isStudent(user?.userType)}>
        <StudentHomeworkView />
      </ConditionalRenderer>

      <ConditionalRenderer condition={!isStudent(user?.userType)}>
        <TeamHomeworkView />
      </ConditionalRenderer>
    </div>
  );
}

export default HomeworkView;
