import MainButton from '@/components/common/buttons/MainButton';
import InfiniteSearchInput from '@/components/common/dataInput/InfiniteSearchInput';
import { createArea, listAreaParents } from '@/data/services/areaServices';
import { areasQueryKeys } from '@/data/services/querykeys';
import Area from '@/models/Area';
import { extractParentsId } from '@/utils/area';
import useFilterParams from '@/utils/UseFilterParams';
import { PlusIcon } from '@heroicons/react/outline';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';
import { TopicsFiltersParams } from './TopicsPageContent';
import useManualQuery from '@/data/hook/useManualQuery';

export default function TopicsTopBar() {
  const { t } = useTranslation('translation', {
    keyPrefix: 'topics.topbar',
  });
  const { setFilterParams } = useFilterParams<TopicsFiltersParams>();
  const queryClient = useQueryClient();
  const { add } = useManualQuery(areasQueryKeys.list()._ctx.infinity);
  const addArea = async () => {
    return await createArea({
      name: t('newArea'),
    });
  };

  const { mutate: onAddArea, isLoading } = useMutation(addArea, {
    onSuccess: async newArea => {
      await queryClient.invalidateQueries(
        areasQueryKeys.list({
          isRoot: true,
        }),
      );
      add(newArea);
      setFilterParams({
        path: [newArea.id],
        isRenaming: true,
      });
    },
  });

  const onSelectArea = async (area: Area) => {
    const parents = await listAreaParents(area.id);
    const parentsIds = extractParentsId(parents.results.at(0));
    if (area.isLeaf)
      setFilterParams({
        path: parentsIds.slice(0, -1),
        area: area.id,
      });
    else
      setFilterParams({
        path: parentsIds,
      });
  };

  return (
    <div className="relative flex justify-between items-center w-full">
      <MainButton
        text={t('new')}
        color="secondary"
        loading={isLoading}
        icon={<PlusIcon className="w-4 h-4" />}
        onClick={() => onAddArea()}
      />
      <InfiniteSearchInput
        onSelect={onSelectArea}
        service={areasQueryKeys.list}
        displayName={(area: Area) => area.name}
        className={{ base: 'w-96' }}
        input={{
          placeholder: t('searchPlaceholder'),
        }}
      />
    </div>
  );
}
