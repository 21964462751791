import { ReactElement, ReactNode } from 'react';
import {
  CheckCircleIcon,
  ExclamationCircleIcon,
  InformationCircleIcon,
  XCircleIcon,
} from '@heroicons/react/solid';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import { useQuery } from '@tanstack/react-query';

import {
  HistoryEnrollment,
  HistoryLessonReport,
} from '@/models/StudentHistory';
import { EnrollmentStatusEnum } from '@/models/Enrollment';
import Text from '../common/dataDisplay/Text';
import { LoadingIcon } from '../icons';
import {
  formatLessonNamePrefix,
  formatOldNames,
} from '@/functions/lessonsName';
import { ScheduledLessonTypeEnum } from '@/models/ScheduledLesson';
import { PresenceEnum } from '@/models/ScheduledLessonReport';
import TooltipHandler from '../common/TooltipHandler';
import { studentHistoriesQueryKeys } from '@/data/services/querykeys';

type HistoricTimelineProps = {
  studentId: number;
  courseId?: number;
};

export function HistoricTimeline({
  studentId,
  courseId,
}: HistoricTimelineProps) {
  const { t } = useTranslation('translation', {
    keyPrefix: 'studentHistoric',
  });

  const { data: history, isInitialLoading: loading } = useQuery({
    enabled: !!courseId && !!studentId,
    ...studentHistoriesQueryKeys.get(studentId, {
      courseId,
    }),
  });

  const hasHistory =
    !!history?.enrollments.length || !!history?.lessonsReport.length;

  const getDate = (
    obj: HistoryEnrollment | HistoryLessonReport,
  ): Date | null => {
    if ('createdAt' in obj) {
      return obj.createdAt;
    } else if ('scheduledLessonDate' in obj) {
      return obj.scheduledLessonDate;
    }
    return null;
  };

  if (loading) {
    return (
      <div className="w-full h-full flex items-center justify-center">
        <LoadingIcon className="text-primary w-20 h-20" />
      </div>
    );
  }

  if (!!history && hasHistory) {
    const fullHistory: (HistoryEnrollment | HistoryLessonReport)[] = [
      ...history.enrollments,
      ...history.lessonsReport,
    ];

    const sortedHistory = fullHistory.sort((a, b) => {
      const dateA = getDate(a);
      const dateB = getDate(b);
      if (dateA && dateB) {
        return moment(dateA).diff(moment(dateB));
      }
      return 0;
    });

    return (
      <ul className="timeline timeline-vertical -translate-x-[25%] md:translate-x-0 w-[200%] md:w-[80%] self-center">
        {sortedHistory.map((item, i) => {
          if ('enrollmentId' in item)
            return (
              <EnrollmentTimelineItem
                key={`${item.enrollmentId + item.status}`}
                enrollment={item}
                index={i}
                length={fullHistory.length}
              />
            );
          else if ('id' in item)
            return (
              <LessonTimelineItem
                key={item?.id}
                lessonReport={item}
                index={i}
                length={fullHistory.length}
              />
            );

          return null;
        })}
      </ul>
    );
  }
  return <Text text={t('noData')} />;
}

const LessonTimelineItem = ({
  lessonReport,
  index,
  length,
}: {
  lessonReport: HistoryLessonReport;
  index: number;
  length: number;
}) => {
  const { t } = useTranslation();
  const getBorder = () => {
    if (lessonReport.scheduledLessonType !== ScheduledLessonTypeEnum.NORMAL) {
      return 'border-warning';
    } else if (lessonReport.presence !== PresenceEnum.ATTENDED) {
      return 'border-error';
    } else return '';
  };

  const tooltipText = t(
    `presenceStatus.` + (lessonReport.presence || PresenceEnum.NONE),
  );

  const lessonName = `${formatLessonNamePrefix({
    lessonOrder: lessonReport.lessonOrder,
    scheduledLessonType: lessonReport.scheduledLessonType,
  })} - ${formatOldNames(lessonReport.lessonName)}`;

  return (
    <BaseTimelineItem
      index={index}
      length={length}
      tooltipText={tooltipText}
      borderColor={getBorder()}
      icon={
        lessonReport.presence === PresenceEnum.ATTENDED ? (
          <CheckCircleIcon className="w-5 h-5 text-success" />
        ) : (
          <XCircleIcon
            className={`${
              lessonReport.presence === PresenceEnum.MISSED
                ? 'text-error'
                : 'text-neutral/50'
            } w-5 h-5`}
          />
        )
      }
    >
      <Text format="rubik-500" className="text-14" text={lessonName} />
      <Text
        className="text-12 text-base-300 flex self-end"
        text={moment(lessonReport.scheduledLessonDate).format('DD/MM/YYYY')}
      />
    </BaseTimelineItem>
  );
};

const EnrollmentTimelineItem = ({
  enrollment,
  index,
  length,
}: {
  enrollment: HistoryEnrollment;
  index: number;
  length: number;
}) => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'studentHistoric',
  });

  const getText = (status: EnrollmentStatusEnum) => {
    switch (status) {
      case EnrollmentStatusEnum.CLASS_TRANSFERRED:
      case EnrollmentStatusEnum.COURSE_TRANSFERRED:
        return t('enrollTransfer');
      case EnrollmentStatusEnum.CANCELED:
        return t('enrollCanceled');
      default:
        return t('enrolled');
    }
  };
  return (
    <BaseTimelineItem
      index={index}
      length={length}
      borderColor={
        enrollment.status !== EnrollmentStatusEnum.ACTIVE
          ? 'border-warning'
          : ''
      }
      icon={
        enrollment.status === EnrollmentStatusEnum.ACTIVE ? (
          <InformationCircleIcon className="w-5 h-5 text-info" />
        ) : (
          <ExclamationCircleIcon className="w-5 h-5 text-warning" />
        )
      }
    >
      <Text
        format="rubik-500"
        className="text-14"
        text={getText(enrollment.status) + ' ' + enrollment.klassName}
      />
      <Text
        className="text-12 text-base-300 flex self-end"
        text={moment(enrollment.createdAt).format('DD/MM/YYYY')}
      />
    </BaseTimelineItem>
  );
};

const BaseTimelineItem = ({
  index,
  length,
  icon,
  children,
  borderColor,
  tooltipText,
}: {
  index: number;
  length: number;
  icon: ReactElement;
  children: ReactNode;
  borderColor: string;
  tooltipText?: string;
}) => {
  const isEven = index % 2 === 0;
  const isLast = index === length - 1;
  return (
    <li>
      {index !== 0 ? <hr /> : null}
      <div
        className={`flex w-full md:w-fit flex-col items-end text-end ${
          isEven ? 'timeline-end md:timeline-start' : 'timeline-end'
        } timeline-box ${borderColor}`}
      >
        {children}
      </div>

      <div className="timeline-middle">
        <TooltipHandler
          renderTooltip={!!tooltipText}
          tooltipMessage={tooltipText ?? ''}
        >
          {icon}
        </TooltipHandler>
      </div>
      {isLast ? null : <hr />}
    </li>
  );
};
