import { useState } from 'react';
import User, { UserStatusEnum } from '@/models/User';
import { InformationCircleIcon } from '@heroicons/react/outline';
import { CheckCircleIcon, ChevronUpIcon } from '@heroicons/react/solid';
import { useTranslation } from 'react-i18next';
import EditTeam from './EditTeam';
import IconButton from '../../common/buttons/IconButton';
import Row from '../../common/table/Row';
import BodyCell from '../../common/table/BodyCell';
import { TeamHeader } from '@/utils/HeaderTypes';
import Text from '../../common/dataDisplay/Text';
import { Tooltip } from '../../common/dataDisplay/Tooltip';
import LoadingView from '@/pages/courses/LoadingView';
import ConditionalRenderer from '../../common/ConditionalRenderer';
import Observable from '@/utils/observers/ObserverPattern';
import { AnimatePresence, motion } from 'framer-motion';
import { editFormAnimation } from '@/utils/animations/formAnimations';
import TextWithCopy from '../../common/dataDisplay/TextWithCopy';
import UnitDisplay from '../../common/table/UnitDisplay';
import { handleUserFullName } from '@/functions/handleUserFullName';

interface ListTeamProps {
  team: User[];
  titles: TeamHeader;
}

interface RenderRowProps {
  member: User;
  titles: TeamHeader;
  onUpdate?: () => void;
  observer?: Observable<number>;
}

export default function DataTeamTable(props: ListTeamProps) {
  const { team, titles } = props;

  const observer = new Observable<number>();

  return (
    <tbody className="bg-transparent flex flex-col gap-y-2.5">
      {team?.map((member, i) => {
        return (
          <RenderRow
            key={i}
            member={member}
            titles={titles}
            observer={observer}
          />
        );
      })}
    </tbody>
  );
}

function RenderRow({ member, titles, onUpdate, observer }: RenderRowProps) {
  const [viewInformations, setViewInformations] = useState(false);

  member.id &&
    observer?.subscribe({
      id: member.id,
      action: () => {
        setViewInformations(false);
      },
    });

  const { t: tCommon } = useTranslation('translation', {
    keyPrefix: 'common.userType',
  });

  const { t } = useTranslation('translation', {
    keyPrefix: 'manageTeam',
  });

  const toogleViewInformations = () => {
    if (!viewInformations) observer?.notifyAll(member.id);
    setViewInformations(old => !old);
  };

  return (
    <Row key={member.id} testId="teamRow">
      <BodyCell className="justify-between p-0">
        <div
          style={{ width: `${titles.name.size}%` }}
          className="flex items-center"
          onClick={toogleViewInformations}
        >
          <IconButton
            testId="teamInfoButton"
            className="transition ease-in-out duration-150"
            icon={
              <ChevronUpIconWithAnimation
                animate={{
                  rotate: !viewInformations ? 180 : 0,
                }}
                className="w-3 h-3"
              />
            }
          />
          <Text
            text={handleUserFullName(member)}
            className="cursor-pointer pl-5"
            format="rubik-400"
          />
        </div>

        <div
          style={{ width: `${titles?.email.size}%` }}
          className="flex items-center"
        >
          <TextWithCopy
            className={{ text: 'break-all' }}
            format="rubik-400"
            label={member.email || ''}
          />
        </div>

        <div
          style={{ width: `${titles?.phoneNumber.size}%` }}
          className="flex items-center"
        >
          <Text format="rubik-400" text={member.profile.phoneNumber} />
        </div>

        <UnitDisplay unitsIds={member.unitsIds} width={titles.unit?.size} />

        <div
          style={{ width: `${titles?.role?.size}%` }}
          className="flex items-center"
        >
          <Text format="rubik-400" text={tCommon(`${member.userType}`)} />
        </div>

        <div
          style={{ width: `${titles?.status?.size}%` }}
          className="flex items-center"
        >
          {member.status === UserStatusEnum.INACTIVE ? (
            <CheckCircleIcon className="w-5 h-5 text-neutral/50" />
          ) : (
            <CheckCircleIcon className="w-5 h-5 text-primary" />
          )}
        </div>

        <div
          style={{ width: `${titles?.actions?.size}%` }}
          className="flex items-center"
        >
          <Tooltip text={t('dataUserTable.infoTooltip')}>
            <IconButton
              onClick={toogleViewInformations}
              icon={<InformationCircleIcon className="text-primary w-6 h-6" />}
            />
          </Tooltip>
        </div>
      </BodyCell>
      <BodyCell className="overflow-hidden">
        <AnimatePresence>
          {viewInformations && (
            <motion.div
              className="w-full overflow-hidden"
              initial={editFormAnimation.initial}
              animate={editFormAnimation.animate}
              exit={editFormAnimation.exit}
            >
              <UserDetails user={member} onUpdate={onUpdate} />
            </motion.div>
          )}
        </AnimatePresence>
      </BodyCell>
    </Row>
  );
}

const UserDetails = ({
  user,
  onUpdate,
}: {
  user: User;
  onUpdate?: () => void;
}) => {
  return (
    <ConditionalRenderer
      condition={user}
      fallback={
        <LoadingView className="flex w-full h-64 justify-center items-center" />
      }
    >
      <div className="flex w-full">
        <EditTeam onUpdate={onUpdate} user={user} />
      </div>
    </ConditionalRenderer>
  );
};

const ChevronUpIconWithAnimation = motion(ChevronUpIcon);
