import { slideHorizontal } from '@/utils/animations/commom';
import { MotionProps, motion } from 'framer-motion';

type StepIndicatorProps = MotionProps & {
  className?: string;
};

function StepIndicator({ className, ...motionProps }: StepIndicatorProps) {
  const slide = slideHorizontal();
  return (
    <motion.div
      {...motionProps}
      transition={{
        type: 'spring',
        stiffness: 400,
        damping: 40,
      }}
      {...slide}
      className={`w-2 h-2 bg-accent rounded-full bottom-3 ${className ?? ''}`}
    />
  );
}

export default StepIndicator;
