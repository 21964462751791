import React from 'react';
import Text from './Text';

type ButtonColor = 'primary' | 'secondary' | 'neutral' | 'warning' | 'custom';
type ButtonSize = 'regular' | 'medium' | 'large';

interface MainButtonProps {
  id?: string;
  color?: ButtonColor;
  text: string;
  className?: string;
  icon?: JSX.Element;
  size?: ButtonSize;
  isExternalLink?: boolean;
  'data-testid'?: string;
}

const buttonColorClasses: Record<ButtonColor, string> = {
  primary:
    'bg-primary text-base-100 disabled:bg-primary/40 focus-visible:ring-primary/40',
  secondary:
    'bg-secondary/40 text-base-100 disabled:bg-secondary-content focus-visible:ring-secondary/40',
  neutral:
    'bg-neutral-content text-base-content disabled:text-neutral-content focus-visible:ring-neutral/50',
  warning:
    'bg-error-content text-error disabled:text-error-content focus-visible:ring-error-content',
  custom: 'disabled:opacity-25 focus-visible:ring-primary-content',
};

const buttonSizeClasses: Record<ButtonSize, string> = {
  regular: 'text-14',
  medium: 'text-16',
  large: 'text-18',
};

export default function MainButtonDisplay(props: MainButtonProps) {
  const { color, text, className, icon, size, ...rest } = props;

  return (
    <div
      {...rest}
      className={`leading-none max-w-fit h-fit flex rounded-md enabled:active:scale-95 items-center font-rubik duration-150 transition cursor-default focus-visible:ring focus:outline-none gap-2.5 ${
        buttonColorClasses[color || 'primary']
      } ${buttonSizeClasses[size || 'regular']} ${
        color === 'custom' ? '' : 'px-2.5 py-1.5'
      } ${className}`}
    >
      <div className="duration-150 transition p-0">
        {icon ? icon : <React.Fragment />}
      </div>
      <Text text={text} />
    </div>
  );
}
