export default function getKlassViewBaseUrl(params: {
  klassId?: number;
  slugCourseName: string;
  lessonId: number;
}) {
  const klassPath =
    params.klassId && !isNaN(params.klassId) ? `/class/${params.klassId}` : '';

  const baseUrl =
    `/courses/${params.slugCourseName}/lesson/${params.lessonId}` + klassPath;
  return baseUrl;
}
