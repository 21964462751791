import moment from 'moment';
import { UsersIcon } from '@heroicons/react/outline';
import { Link } from 'react-router-dom';
import Klass from '@/models/Klass';
import Text from '@/components/common/dataDisplay/Text';
import { useTranslation } from 'react-i18next';
import { useQuery } from '@tanstack/react-query';
import StudentListTooltip from '@/components/common/dataDisplay/StudentListTooltip';
import { Lesson } from '@/models/Lesson';
import Skeleton from '@/components/common/Skeleton';
import { nextScheduledLessonsQueryKeys } from '@/data/services/querykeys';
import { ROUTES } from '@/utils/routes';

export default function WeekdayKlass({ klass }: { klass: Klass }) {
  const { data: nextLesson, isLoading: isLoadingLessons } = useQuery({
    enabled: !!klass.id,
    ...nextScheduledLessonsQueryKeys.get(klass.id),
  });

  const { t } = useTranslation();

  const isLastLessons =
    moment().diff(moment(klass.klassEndDate), 'weeks') >= -2;

  const klassLink = ROUTES.ADMIN.CLASSES.INFO({
    name: klass.name.replace('#', ''),
  });

  return (
    <div data-testid={`weekdayKlass${klass.id}`} className="relative w-full">
      <Link
        to={klassLink}
        className={`${
          isLastLessons
            ? 'bg-warning-content border-warning/40'
            : 'bg-secondary-content border-secondary/40'
        } border relative rounded-lg cursor-pointer p-2 flex flex-col xl:p-3 gap-1.5 text-14 xl:gap-2.5 z-0 w-full text-base-content`}
      >
        <div className="flex gap-y-0.5 flex-wrap xl:flex-nowrap lg:space-between">
          <div className="flex flex-col gap-1 w-full text-primary">
            <Text format="rubik-700" text={klass.name} />
            <StudentListTooltip klassId={klass.id} placement="top">
              <div className="flex gap-1">
                <Text format="rubik-700" text={klass.enrollsCount} />
                <UsersIcon className="w-3 h-3" strokeWidth={3} />
              </div>
            </StudentListTooltip>
          </div>
        </div>
        <div
          className={`flex gap-1 flex-wrap ${
            isLastLessons
              ? 'bg-warning rounded-full text-center justify-center py-1 text-base-100'
              : ''
          }`}
        >
          <Text format="rubik-500" text={t('teacherCalendar.endDate')} />
          <Text text={moment(klass.klassEndDate).format('DD/MM')} />
        </div>
        <RenderLessonContent
          nextLesson={nextLesson?.lesson}
          isLoadingLesson={isLoadingLessons}
        />
      </Link>
    </div>
  );
}

const RenderLessonContent = ({
  nextLesson,
  isLoadingLesson,
}: {
  isLoadingLesson?: boolean;
  nextLesson?: Lesson;
}) => {
  const { t } = useTranslation();

  if (isLoadingLesson) {
    return <Skeleton className="w-full rounded-full h-3.5 bg-primary/40" />;
  }

  if (!!nextLesson) {
    return (
      <Text
        format="rubik-500"
        className="text-primary underline truncate"
        text={`${t('teacherCalendar.lesson')} ${
          nextLesson.order || 'Extra'
        } - ${nextLesson.name}`}
      />
    );
  }
  return <Text className="text-14" text={t('teacherCalendar.noMoreLessons')} />;
};
