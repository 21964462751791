import { AxiosResponse } from 'axios';
import requester from '../../axios';
import { MGActivity, MGActivityTypeEnum } from '../../models/MGActivity';

type CreateMgActivityBody = Pick<
  MGActivity,
  'isActive' | 'activityType' | 'name'
>;

export const createScheduledLessonMgActivity = async (
  scheduledLessonId: number,
) => {
  const { data: mgActivity } = await requester().post<
    any,
    AxiosResponse<MGActivity>,
    CreateMgActivityBody
  >(`scheduled-lessons/${scheduledLessonId}/manual-grade-activities/`, {
    activityType: MGActivityTypeEnum.HOMEWORK,
    isActive: true,
    name: 'Atividade de Casa',
  });
  return mgActivity;
};

export async function getMgActivity(
  id: number,
  scheduledLessonId?: number,
): Promise<MGActivity> {
  const { data } = await requester().get<MGActivity>(
    `scheduled-lessons/${scheduledLessonId}/manual-grade-activities/${id}/`,
  );
  return data;
}
