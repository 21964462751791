import { HeaderType } from '@/utils/HeaderTypes';
import HeaderCell from './HeaderCell';
import Row from './Row';
import Text from '../../common/dataDisplay/Text';
import React from 'react';
import ComponentGuard from '../ComponentGuard';
import ConditionalRenderer from '../ConditionalRenderer';

interface HeaderProps {
  headers: HeaderType[];
  className?: string;
}

export default function Header(props: HeaderProps) {
  const { className, headers, ...rest } = props;

  return (
    <thead
      {...rest}
      className={`bg-primary text-base-100 flex w-full rounded-xl py-3 px-6 ${
        className ?? ''
      }`}
    >
      <Row type="header" className="text-center bg-transparent justify-between">
        {headers.map((item: HeaderType, index: number) => {
          return (
            <ComponentGuard roles={item.roles ?? []} key={index}>
              <ConditionalRenderer
                condition={item.blockRender ? !item.blockCondition : true}
              >
                <HeaderCell
                  key={index}
                  size={item.size || 20}
                  className="p-0 gap-x-2"
                >
                  <Text
                    text={item.title}
                    format="poppins-600"
                    className={index === 0 ? 'pl-8' : ''}
                  />
                  {item.extraFunction &&
                    item.extraFunction.map((extrafunction, index) => {
                      return (
                        <React.Fragment key={index}>
                          {extrafunction}
                        </React.Fragment>
                      );
                    })}
                </HeaderCell>
              </ConditionalRenderer>
            </ComponentGuard>
          );
        })}
      </Row>
    </thead>
  );
}
