import moment from 'moment';

export default function datesDistincts(datesArr: string[]): string[] {
  const dates: string[] = [];

  datesArr.forEach(date => {
    const dateMoment = moment(date).format('YYYY-MM-DD');
    if (!dates.includes(dateMoment)) {
      dates.push(dateMoment);
    }
  });

  return dates;
}
