import { isArray, isEmpty, pickBy } from 'lodash';

const cleanupObj = <T extends object>(obj: T) => {
  function cleanup(e: unknown) {
    if (isArray(e)) {
      return !isEmpty(e);
    }
    return Boolean(e) || e === false;
  }
  return pickBy(obj, cleanup);
};

export default cleanupObj;
