import { useEffect, useRef, useState } from 'react';

const DEFAULT_OPTIONS = {
  attributes: true,
  childList: true,
  subtree: true,
};

export type CustomMutationCallback<HTMLRef> = (
  e: MutationRecord[],
  o: MutationObserver,
  ref: HTMLRef | null,
) => void;

function useMutationObserver<HTMLRef extends HTMLElement>(
  cb: CustomMutationCallback<HTMLRef>,
  options: MutationObserverInit = DEFAULT_OPTIONS,
) {
  const target = useRef<HTMLRef>(null);

  const [observer, setObserver] = useState<MutationObserver>();

  useEffect(() => {
    const obs = new MutationObserver((e, o) => cb(e, o, target.current));
    setObserver(obs);
  }, [cb, setObserver]);

  useEffect(() => {
    if (!observer) return;
    if (target.current) {
      observer.observe(target.current, options);
    }
    return () => {
      if (observer) {
        observer.disconnect();
      }
    };
  }, [observer, target, options]);

  return { ref: target, observer };
}

export default useMutationObserver;
