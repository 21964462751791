import ConditionalRenderer from '@/components/common/ConditionalRenderer';
import TooltipHandler from '@/components/common/TooltipHandler';
import IconButton from '@/components/common/buttons/IconButton';
import RoundedButton from '@/components/common/buttons/RoundedButton';
import Text from '@/components/common/dataDisplay/Text';
import useCourseEditing from '@/data/hook/useCourseEditing';
import { createBook } from '@/data/services/bookServices';
import { updateLesson } from '@/data/services/lessonServices';
import CoursePath from '@/models/Course';
import { ApiError } from '@/models/Errors';
import { Lesson } from '@/models/Lesson';
import alert from '@/utils/UseAlert';
import {
  InboxInIcon,
  PaperClipIcon,
  TrashIcon,
} from '@heroicons/react/outline';
import { AnimatePresence, motion } from 'framer-motion';
import { isEmpty } from 'lodash';
import { Dispatch, SetStateAction, useState } from 'react';
import { ValidateResult, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { isPulished } from '@/functions/handleCourseStatusIcon';
import { bookType } from '@/models/Book';
import { Mode } from './MaterialMode';

interface AddMaterialType {
  course: CoursePath;
  lesson: Lesson;
  updateLessons?(hasToLoading?: boolean): void;
  bookType: bookType;
  setMaterialMode: Dispatch<SetStateAction<Mode>>;
}

export default function AddMaterial({
  bookType,
  setMaterialMode,
  ...props
}: AddMaterialType) {
  const { course, lesson, updateLessons } = props;

  const { slugCourseName = '' } = useParams();

  const { allowBigChanges } = useCourseEditing();
  const { t } = useTranslation('translation', {
    keyPrefix: 'adminPage.lesson',
  });
  const { t: editRulesT } = useTranslation('translation', {
    keyPrefix: 'adminPage.editCourseRules',
  });

  const [isLoading, setIsLoading] = useState(false);

  type Form = {
    rawZip: FileList;
  };
  const {
    reset,
    register,
    watch,
    resetField,
    handleSubmit,
    formState: { errors },
  } = useForm<Form>();

  const rawZip = watch('rawZip');
  const fileList = Array.from(rawZip ?? []);
  const shouldDisableSubmit =
    !allowBigChanges || !isEmpty(errors) || !fileList.length;

  function clearFields() {
    reset();
  }

  const onRemoveFile = () => {
    resetField('rawZip');
  };

  const validateFileExtension = (fileList: FileList): ValidateResult => {
    const file = fileList.item(0);
    if (file) {
      const isZipType = ![
        'application/zip',
        'application/x-zip-compressed',
      ].includes(file.type);

      return isZipType ? t('errorNotValidExtension') : undefined;
    }
  };

  const onSubmit = async ({ rawZip }: Form) => {
    setIsLoading(true);
    try {
      const lessonName = lesson.name;
      let lessonIdAux = lesson.id;
      const zip = rawZip.item(0);

      if (!zip) {
        throw new Error('File was not found');
      }

      if (isPulished(lesson.status)) {
        const newLesson = await updateLesson(
          { lessonId: lesson.id, slugCourseName },
          {},
        );
        lessonIdAux = newLesson.id;
      }

      const params = {
        bookType,
        courseSlug: course.slug,
        lessonId: lessonIdAux,
      };
      const body = {
        filename: lessonName,
        rawZip: zip,
      };

      await createBook(params, body);
      alert.warning(t('addMaterial.waitComplete'));
      updateLessons?.();

      clearFields();
    } catch (err: any) {
      const apiError = new ApiError(err);

      alert.error(apiError.getErrorMessage());
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <form className="p-4 astro-upload" onSubmit={handleSubmit(onSubmit)}>
      <div className="flex flex-col gap-4 w-1/2">
        <label
          htmlFor="rawZip"
          className="flex cursor-pointer flex-col gap-3 items-center justify-center text-base-content bg-secondary-content border border-dashed border-secondary/40 rounded-md p-5"
        >
          <InboxInIcon className="w-20 h-20" />
          <Text text={t('addMaterial.selectMaterial')} />
        </label>
        <ConditionalRenderer condition={errors.rawZip}>
          <Text className="text-error" text={errors.rawZip?.message} />
        </ConditionalRenderer>
        <AnimatePresence>
          {fileList.length && (
            <motion.ul
              initial={{ height: 0, opacity: 0, x: -200 }}
              animate={{ height: 'auto', opacity: 1, x: 0 }}
              exit={{ height: 0, opacity: 0, x: -200 }}
              className="flex flex-col gap-2 w-full"
            >
              {fileList.map(({ name }) => (
                <li
                  className="text-error flex justify-between items-center"
                  key={name}
                >
                  <div className="flex items-center gap-2">
                    <PaperClipIcon className="w-5 h-5" />
                    <Text text={name} as="span" />
                  </div>
                  <IconButton
                    icon={<TrashIcon className="w-5 h-5" />}
                    onClick={onRemoveFile}
                  />
                </li>
              ))}
            </motion.ul>
          )}
        </AnimatePresence>
      </div>
      <input
        type="file"
        id="rawZip"
        accept=".zip"
        className="sr-only"
        {...register('rawZip', {
          maxLength: 1,
          validate: validateFileExtension,
          required: t('addMaterial.selectMaterial'),
        })}
      />

      <div className="flex gap-4">
        <TooltipHandler
          renderTooltip={!allowBigChanges}
          tooltipMessage={editRulesT('cantModify')}
        >
          <RoundedButton
            testId="uploadMaterial"
            disabled={shouldDisableSubmit}
            loading={isLoading}
            text={t('addMaterial.addButton')}
            className="mt-4"
          />
        </TooltipHandler>

        <RoundedButton
          disabled={!allowBigChanges}
          text={t('addMaterial.back')}
          className="mt-4"
          onClick={() => setMaterialMode('default')}
        />
      </div>
    </form>
  );
}
