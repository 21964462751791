import Enrollment, { EnrollmentStatusEnum } from '@/models/Enrollment';
import User from '@/models/User';

export function getKlassEnrollments(
  enrollments: Enrollment[] = [],
  status: EnrollmentStatusEnum[] = [EnrollmentStatusEnum.ACTIVE],
): Enrollment[] {
  return enrollments.filter(enrollment => status.includes(enrollment.status));
}

export default function getKlassStudents(
  enrollments: Enrollment[] = [],
  status: EnrollmentStatusEnum[] = [EnrollmentStatusEnum.ACTIVE],
): User[] {
  return getKlassEnrollments(enrollments, status).map(
    enrollment => enrollment.student,
  );
}

export const enrollmentsArray = [
  EnrollmentStatusEnum.CANCELED,
  EnrollmentStatusEnum.ACTIVE,
  EnrollmentStatusEnum.CLASS_TRANSFERRED,
  EnrollmentStatusEnum.COURSE_TRANSFERRED,
  EnrollmentStatusEnum.AWAITING_PAYMENT,
  EnrollmentStatusEnum.CONCLUDED,
];

export const inactiveEnrollmentStatus = [
  EnrollmentStatusEnum.CANCELED,
  EnrollmentStatusEnum.CLASS_TRANSFERRED,
  EnrollmentStatusEnum.COURSE_TRANSFERRED,
  EnrollmentStatusEnum.CONCLUDED,
];

export const activeEnrollmentStatus = [
  EnrollmentStatusEnum.ACTIVE,
  EnrollmentStatusEnum.AWAITING_PAYMENT,
];
