import { ApiError } from '@/models/Errors';

export const getErrorMessage = (err: any) => {
  let error = '';
  if (err) {
    const apiError = new ApiError(err as any);
    error = apiError.getErrorMessage();
  }
  return error;
};
