import MainButton from '../../common/buttons/MainButton';
import { useTranslation } from 'react-i18next';
import { PencilIcon, InformationCircleIcon } from '@heroicons/react/outline';
import Tag from '../../common/dataDisplay/Tag';
import TeamForm from './TeamForm';
import { useState } from 'react';
import User from '@/models/User';

interface EditUserProps {
  user: User;
  onUpdate?: (user?: User) => void;
}

export default function EditTeam({ user }: EditUserProps) {
  const { t } = useTranslation('translation', {
    keyPrefix: 'manageTeam',
  });

  const [editMode, setEditMode] = useState(false);
  return (
    <div className="flex flex-col w-full font-rubik pt-5 pb-5 ml-8">
      <div className="flex w-auto items-center gap-8 mb-3 ">
        <Tag
          text={t('addUser.info')}
          icon={<InformationCircleIcon className="w-6 h-6" />}
        />
        <MainButton
          disabled={editMode}
          onClick={() => setEditMode(true)}
          text={t('addUser.edit')}
          icon={<PencilIcon />}
          color="custom"
          className="text-primary"
          dataTestId="editButton"
        />
      </div>
      <TeamForm
        user={user}
        disabled={!editMode}
        className="w-[60%] mb-5"
        onCancel={() => setEditMode(false)}
      />
    </div>
  );
}
