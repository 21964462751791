import { ItemDND } from '../../../../../../models/DNDOrdenation';
import { DragIcon } from '../../../../../icons';
import DNDItemContainer from '../../DNDItem/DNDItemContainer';

interface DNDOrdenationItemProps {
  dndOrdenationItem: ItemDND;
}

export default function DNDOrdenationItem({
  dndOrdenationItem,
}: DNDOrdenationItemProps) {
  const { text, image, altText } = dndOrdenationItem;

  return (
    <DNDItemContainer
      text={text}
      image={image}
      altText={altText}
    >
      <div>
        <DragIcon data-testid="dragIcon" />
      </div>
    </DNDItemContainer>
  );
}
