import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useMutation } from '@tanstack/react-query';
import { motion } from 'framer-motion';
import { isEqual } from 'lodash';

import Klass from '@/models/Klass';
import {
  BehaviorAssessment,
  BehaviorRatingEnum,
  CommentAssessment,
  KlassAssessment,
} from '@/models/KlassAssessment';
import { SheetTab } from '@/models/SheetTab';
import {
  KlassAssessmentBody,
  createKlassAssessment,
  updateKlassAssessment,
} from '@/data/services/klassAssessmentServices';
import { ApiError } from '@/models/Errors';
import alert from '@/utils/UseAlert';
import { buildChangedObject } from '@/utils/buildChangedObject';
import { fadeIn } from '@/utils/animations/commom';
import BehaviorInput from '@/components/common/assessment/BehaviorInput';
import ConditionalRenderer from '@/components/common/ConditionalRenderer';
import SaveCancelGroup from '@/components/common/buttons/SaveCancelGroup';
import CommentAssessmentForm from '../KlassCommentAssessmentForm/CommentAssessmentForm';

type BehaviorAssessmentFormProps = {
  klass?: Klass;
  changeTab?(changed: SheetTab): void;
  updateAssessments?(): Promise<void>;
  klassAssessment?: KlassAssessment;
  disabled?: boolean;
  tab: SheetTab;
};

export default function BehaviorAssessmentForm({
  klass,
  changeTab,
  updateAssessments,
  klassAssessment: defaultValues,
  disabled,
  tab,
}: BehaviorAssessmentFormProps) {
  const createMode = !defaultValues;

  const { t } = useTranslation('translation', {
    keyPrefix: 'behaviorAssessmentForm',
  });

  const { handleSubmit, reset, watch, control } = useForm<KlassAssessment>({
    defaultValues,
  });

  const [hasChanges, setHasChanges] = useState(false);

  const onCancel = () => {
    setHasChanges(false);
    reset();
    changeTab?.('view');
  };

  const onSuccessAction = async () => {
    if (updateAssessments) await updateAssessments();
    changeTab?.('view');
    setHasChanges(false);
  };

  const { mutate: create, isLoading: loadingCreate } = useMutation(
    createKlassAssessment,
    {
      async onSuccess() {
        alert.success(t('successCreatedMessage'));
        await onSuccessAction();
      },
      onError(error: any) {
        const apiError = new ApiError(error);
        alert.error(apiError.getErrorMessage());
      },
    },
  );

  const onSubmitCreate: SubmitHandler<KlassAssessment> = async ({
    behaviorAssessment,
    commentAssessment,
  }: KlassAssessment) => {
    if (!klass) throw new Error('class not found');

    const assessment: KlassAssessmentBody = {
      behaviorAssessment,
      commentAssessment: commentAssessment?.comment
        ? commentAssessment
        : undefined,
      klassId: klass.id,
    };

    create(assessment);
  };

  const { mutate: update, isLoading: loadingUpdate } = useMutation(
    updateKlassAssessment,
    {
      async onSuccess() {
        alert.success(t('successUpdatedMessage'));
        await onSuccessAction();
      },
      onError(error: any) {
        const apiError = new ApiError(error);
        alert.error(apiError.getErrorMessage());
      },
    },
  );

  const onSubmitUpdate: SubmitHandler<KlassAssessment> = async data => {
    if (!defaultValues?.id) throw new Error('assessment not found');

    if (defaultValues) {
      const assessmentId = defaultValues.id;

      const assessmentChanges = buildChangedObject<KlassAssessment>(
        defaultValues,
        data,
      );

      const hasCommentAssessment =
        !!defaultValues.commentAssessment || !!data.commentAssessment?.comment;

      const commentChanges = hasCommentAssessment
        ? buildChangedObject<CommentAssessment>(
            defaultValues.commentAssessment,
            data.commentAssessment,
          )
        : {};

      const commentAssessment = Object.keys(commentChanges).length
        ? commentChanges.comment
          ? commentChanges
          : {}
        : undefined;

      const behaviorChanges = buildChangedObject<BehaviorAssessment>(
        defaultValues.behaviorAssessment,
        data.behaviorAssessment,
      );

      const behaviorAssessment = Object.keys(behaviorChanges).length
        ? behaviorChanges
        : undefined;

      const body = {
        ...assessmentChanges,
        commentAssessment,
        behaviorAssessment,
      };
      update({ assessmentId, body });
    }
  };

  const onSubmit = createMode ? onSubmitCreate : onSubmitUpdate;

  const motionClassName = 'flex flex-col w-full gap-3';

  useEffect(() => {
    const subscribe = watch(value =>
      setHasChanges(!isEqual(value, defaultValues)),
    );
    return () => {
      subscribe.unsubscribe();
    };
  }, [defaultValues, watch]);

  return (
    <motion.form
      className={motionClassName}
      {...fadeIn}
      onSubmit={handleSubmit(onSubmit)}
    >
      <BehaviorInput
        control={control}
        label={t('labels.energy')}
        disabled={disabled}
        previewValue={defaultValues?.behaviorAssessment?.energy}
        name="behaviorAssessment.energy"
        testId="energyInput"
        tab={tab}
        enumModel={BehaviorRatingEnum}
        translatePrefix="klassEnergyRating"
      />

      <BehaviorInput
        control={control}
        label={t('labels.speed')}
        disabled={disabled}
        previewValue={defaultValues?.behaviorAssessment?.speed}
        name="behaviorAssessment.speed"
        testId="speedInput"
        tab={tab}
        enumModel={BehaviorRatingEnum}
        translatePrefix="klassSpeedRating"
      />

      <BehaviorInput
        control={control}
        label={t('labels.maturity')}
        disabled={disabled}
        previewValue={defaultValues?.behaviorAssessment?.maturity}
        name="behaviorAssessment.maturity"
        testId="maturityInput"
        tab={tab}
        enumModel={BehaviorRatingEnum}
        translatePrefix="klassMaturityRating"
      />

      <ConditionalRenderer condition={!disabled}>
        <CommentAssessmentForm
          klass={klass}
          assessmentControl={control}
          klassAssessment={defaultValues}
        />
        <SaveCancelGroup
          className="self-start"
          loading={loadingCreate || loadingUpdate}
          save={{
            disabled: !hasChanges,
            testId: 'saveButton',
          }}
          cancel={{
            onClick: onCancel,
            testId: 'cancelButton',
          }}
        />
      </ConditionalRenderer>
    </motion.form>
  );
}
