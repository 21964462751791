import ConditionalWrapper from '@/components/common/ConditionalWrapper';
import LessonIconContainer from '@/components/common/LessonIconContainer';
import AvatarName from '@/components/common/dataDisplay/AvatarName';
import useAuth from '@/data/hook/useAuth';
import { updateLessonInBank } from '@/data/services/lessonServices';
import { isSuperAdmin } from '@/functions/auth';
import { Lesson, LessonMeta } from '@/models/Lesson';
import { modalColors } from '@/utils/modalBankUtils';
import {
  BookOpenIcon,
  EyeIcon,
  EyeOffIcon,
  SaveIcon,
  StarIcon,
} from '@heroicons/react/outline';
import { useMutation, useQuery } from '@tanstack/react-query';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import Card from '../BankCards';
import { ExpansibleLessonCard } from '../Expansible/ExpansibleLessonCard';
import { CreateCardProps } from './CreateCardProps';
import {
  lessonsQueryKeys,
  scheduledLessonsQueryKeys,
  usersQueryKeys,
} from '@/data/services/querykeys';
import { useBankFilters } from '@/data/context/BankFiltersContext';
import { useItems } from '../ListBankCards.hooks';
import useInfiniteService from '@/data/hook/useInfiniteService';

export function CreateLessonCard({
  item: lesson,
  checkable,
  disabledAnimation,
  onUse,
}: CreateCardProps<Lesson, LessonMeta>) {
  const { user } = useAuth();
  const { slugCourseName = '', klassId } = useParams();
  const { t } = useTranslation('translation', {
    keyPrefix: 'modalBank',
  });

  const isSuper = isSuperAdmin(user?.userType);

  const [inBank, setInBank] = useState(lesson.inBank);
  const { data: author } = useQuery({
    ...usersQueryKeys.get(lesson.author),
    refetchOnMount: false,
    enabled: isSuper,
  });
  const { filters } = useBankFilters();

  const { invalidate: lessonQueryInvalidate } = useItems('lessons', filters, {
    enabled: false,
  });

  const { data: lessons } = useQuery({
    ...lessonsQueryKeys.list({ slug: slugCourseName }),
    enabled: !!slugCourseName && !klassId,
  });
  const { results: scheduledLessons } = useInfiniteService({
    ...scheduledLessonsQueryKeys.list({
      klassId: Number(klassId),
      ordering: 'datetime',
    })._ctx.infinity,
    enabled: !isNaN(Number(klassId)),
  });

  const lessonsUsage: Lesson[] =
    lessons?.results || scheduledLessons.map(s => s.lesson);
  const lessonInCourse = lessonsUsage?.some(
    lessonFromCourse => lessonFromCourse.id === lesson.id,
  );

  const updateInBank = async (inBank: boolean) => {
    const bankUpdate = await updateLessonInBank({
      lessonId: lesson.id,
      bank: { inBank },
    });
    await lessonQueryInvalidate();
    return bankUpdate;
  };

  const { mutate: onCheck, isLoading } = useMutation(updateInBank, {
    onSuccess: data => setInBank(data.inBank),
  });

  let tags: string[] = [lesson.originCourse];

  tags = lessonInCourse ? tags.concat(t('inUseLesson')) : tags;

  tags = tags.concat(lesson.tools.map(tool => tool.name));

  const mountDescIcons = (lesson: Lesson) => {
    const icons = [];
    if (lesson.challenge) {
      icons.push(
        <LessonIconContainer
          key="challengeIcon"
          type="challenge"
          className="w-9 h-9"
          size="w-6 h-6"
        />,
      );
    }
    if (lesson.activities.length) {
      icons.push(
        <LessonIconContainer
          key="activityIcon"
          type="activity"
          className="w-9 h-9"
          size="w-6 h-6"
        />,
      );
    }
    if (lesson.teacherBook) {
      icons.push(
        <LessonIconContainer
          key="teacherBookIcon"
          type="teacherBook"
          className="w-9 h-9"
          size="w-6 h-6"
        />,
      );
    }
    if (lesson.book) {
      icons.push(
        <LessonIconContainer
          key="studentBookIcon"
          type="studentBook"
          className="w-9 h-9"
          size="w-6 h-6"
        />,
      );
    }

    const iconsProp = icons.map(icon => ({ icon }));
    return iconsProp;
  };

  let metaCards = [
    <Card.Meta
      key={'lessonRating' + lesson.id}
      icon={StarIcon}
      label={lesson.teacherRating?.average || 0}
    />,
    <Card.Meta
      key={'activityUse' + lesson.id}
      icon={SaveIcon}
      label={lesson.coursesCount}
    />,
  ];

  const publicCard = (
    <Card.Meta
      key={'public' + lesson.id}
      icon={lesson.isPublicInBank ? EyeIcon : EyeOffIcon}
      className="ml-2"
      tooltip={lesson.isPublicInBank ? t('public') : t('private')}
    />
  );

  metaCards = isSuper ? [...metaCards, publicCard] : metaCards;

  return (
    <Card.Animation disabled={disabledAnimation}>
      <ConditionalWrapper
        condition={!!checkable}
        wrapper={
          <Card.Checkable
            checked={inBank}
            onCheck={onCheck}
            isLoading={isLoading}
          />
        }
      >
        <Card
          color={modalColors.lessons}
          testId={'lessonCard' + lesson.id}
          inUse={lessonInCourse}
          expansibleContent={
            <ExpansibleLessonCard
              used={lessonInCourse}
              item={lesson}
              onUse={!lessonInCourse ? onUse : undefined}
            />
          }
        >
          <Card.LeftContent>
            <Card.Header
              icon={BookOpenIcon}
              title={lesson.name}
              color={modalColors.lessons.color}
              meta={metaCards}
            />
            <Card.DescContainer>
              <Card.Tags tags={tags} color={modalColors.lessons} />
              {author && <AvatarName user={author} className="gap-2" />}
            </Card.DescContainer>
          </Card.LeftContent>
          <Card.IconsList icons={mountDescIcons(lesson)} />
        </Card>
      </ConditionalWrapper>
    </Card.Animation>
  );
}
