import { Fragment } from 'react';
import { Control, Controller, useController } from 'react-hook-form';
import PasswordInput from './PasswordInput';
import { useTranslation } from 'react-i18next';
import { BaseInputProps } from './BaseInput';

export default function PasswordAndConfirmInput({
  control,
  ...baseProps
}: {
  control: Control<any>;
} & BaseInputProps) {
  const { t } = useTranslation('translation', {
    keyPrefix: 'manageTeam.addUser',
  });

  const {
    fieldState: { error: errorPassword },
  } = useController({ control, name: 'password' });
  const {
    fieldState: { error: errorConfirm },
  } = useController({ control, name: 'confirmPassword' });

  return (
    <Fragment>
      <Controller
        name="password"
        control={control}
        rules={{
          required: t('errorMessagePassword'),
          validate: value => {
            const confirmPassword = control._getWatch('confirmPassword');

            const password = value;
            if (password !== confirmPassword) {
              return t('errorMessagePasswordMatch');
            }
            return true;
          },
        }}
        render={({ field: { value, onChange } }) => (
          <PasswordInput
            label={t('password')}
            value={value ?? ''}
            onChange={e => {
              const value = e.target.value;

              control.setError('confirmPassword', {
                message: undefined,
              });
              onChange(value);
            }}
            testId={'password'}
            errorLabelText={errorPassword?.message}
            {...baseProps}
          />
        )}
      />
      <Controller
        name="confirmPassword"
        control={control}
        rules={{
          validate: value => {
            const password = control._getWatch('password');

            const confirmPassword = value;
            if (password !== confirmPassword) {
              return t('errorMessagePasswordMatch');
            }
            return true;
          },
        }}
        render={({ field: { value, onChange } }) => (
          <PasswordInput
            label={t('passwordConfirm')}
            testId={'passwordConfirm'}
            value={value ?? ''}
            onChange={e => {
              const value = e.target.value;

              control.setError('password', {
                message: undefined,
              });
              onChange(value);
            }}
            errorLabelText={errorConfirm?.message}
            {...baseProps}
          />
        )}
      />
    </Fragment>
  );
}
