import { Helmet } from 'react-helmet-async';

interface HeadTitleProps {
  routeInfo?: string;
}

export default function HeadTitle({ routeInfo }: HeadTitleProps) {
  const pinnedTitle = `Astro Portal - Ctrl+Play`;
  return (
    <Helmet>
      <title>{routeInfo ? `${routeInfo} - ${pinnedTitle}` : pinnedTitle}</title>
    </Helmet>
  );
}
