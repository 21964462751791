import IconButton from '@/components/common/buttons/IconButton';
import ConditionalRenderer from '@/components/common/ConditionalRenderer';
import TextAreaInput from '@/components/common/dataInput/TextAreaInput';
import ModalDisable from '@/components/common/modals/ModalDisable';
import { tagsQueryKeys } from '@/data/services/querykeys';
import { deleteTag, updateTag } from '@/data/services/tagServices';
import Tag from '@/models/Tag';
import { PencilIcon, SaveIcon, TrashIcon } from '@heroicons/react/outline';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

type TagProps = {
  tag: Tag;
};

export default function TagComponent({ tag }: TagProps) {
  const { t } = useTranslation('translation', {
    keyPrefix: 'topics.tags',
  });

  const [isEditing, setIsEditing] = useState(false);
  const [isVisibleModal, setIsVisibleModal] = useState(false);

  const queryClient = useQueryClient();

  const { mutate: onDelete, isLoading } = useMutation(deleteTag, {
    onSuccess: () => {
      queryClient.invalidateQueries(
        tagsQueryKeys.list({
          areaId: [tag.areaId],
        }),
      );
    },
  });

  const { register, handleSubmit } = useForm<Tag>({
    defaultValues: tag,
  });

  const update = async (updatedTag: Tag) => {
    await updateTag(tag.id, updatedTag);
  };

  const { mutate: onUpdate, isLoading: isUpdating } = useMutation(update, {
    onSuccess: () => {
      queryClient.invalidateQueries(
        tagsQueryKeys.list({
          areaId: [tag.areaId],
        }),
      );
    },
    onSettled: () => setIsEditing(false),
  });

  const onConfirmDelete = () => {
    onDelete(tag.id);
    setIsVisibleModal(false);
  };

  const onSubmit = (e: Tag) => onUpdate(e);

  return (
    <form className="flex flex-col gap-2.5" onSubmit={handleSubmit(onSubmit)}>
      <div className="flex gap-2.5 items-center">
        <div className="py-0.5 px-4 w-[160px] bg-secondary-content  border border-secondary rounded-lg">
          <p className="text-secondary font-500 text-center">{`${t(
            `difficulty.values.${tag.difficulty}`,
          )} ${t(`level.values.${tag.level}`)}`}</p>
        </div>
        <ConditionalRenderer
          condition={isEditing}
          fallback={
            <IconButton
              icon={<PencilIcon className="w-5 h-5 text-primary" />}
              onClick={() => setIsEditing(true)}
              key="edit"
              type="button"
            />
          }
        >
          <IconButton
            icon={<SaveIcon className="w-5 h-5 text-secondary" />}
            type="submit"
            key="save"
            disabled={isUpdating}
            loadingSize="w-5 h-5 text-primary"
            loading={isUpdating}
          />
        </ConditionalRenderer>
        <IconButton
          icon={<TrashIcon className="w-5 h-5 text-error " />}
          loading={isLoading}
          loadingSize="w-5 h-5 text-error"
          onClick={() => setIsVisibleModal(true)}
        />
      </div>
      <TextAreaInput
        register={register('description')}
        disabled={!isEditing}
        className={{ base: 'flex-grow', input: 'disabled:text-base-content ' }}
        fontWeight="font-400"
      />
      <ModalDisable
        visible={isVisibleModal}
        translationString={'modalDisableDifficulty'}
        modalType="delete"
        onClickConfirm={onConfirmDelete}
        onClickCancel={() => setIsVisibleModal(false)}
      />
    </form>
  );
}
