import { CSSProperties, ReactNode } from 'react';

interface TableCellProps {
  onClick?: () => void;
  style?: CSSProperties;
  children?: ReactNode;
  className?: string;
  id?: string;
  testId?: string;
}

export default function TableCell({
  id,
  testId,
  className,
  onClick,
  style,
  children,
}: TableCellProps) {
  return (
    <td
      id={id}
      data-testid={testId}
      className={`items-center flex ${className}`}
      onClick={onClick}
      style={style}
    >
      {children}
    </td>
  );
}
