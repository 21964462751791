import { AnimatePresence, motion } from 'framer-motion';
import { ReactNode, useState } from 'react';
import TabsTriggerListContainer from './TabsTriggerListContainer';

const TabsCollapsibleList: React.FC<{
  children: (isColap: boolean) => ReactNode;
  collapsed?: boolean;
  maxWidth?: number;
}> = ({ children, collapsed = true, maxWidth }) => {
  const [isHover, setHover] = useState(false);

  const isCollapsed = collapsed && !isHover;

  return (
    <TabsTriggerListContainer>
      <motion.nav
        animate={{
          width: isCollapsed ? '50px' : maxWidth || 'auto',
        }}
        transition={{
          duration: 0.15,
          ease: 'linear',
        }}
        onHoverStart={() => setHover(true)}
        onHoverEnd={() => setHover(false)}
      >
        <AnimatePresence>{children(isCollapsed)}</AnimatePresence>
      </motion.nav>
    </TabsTriggerListContainer>
  );
};

export default TabsCollapsibleList;
