import { AxiosInstance } from 'axios';
import jwtDecode from 'jwt-decode';

import { JwtPayloadResponse } from '@/models/JwtPayloadResponse';
import { UserTypeEnum } from '@/models/User';
import { getTokenResponse, setTokenResponse } from './tokenSettings';
import TokenResponse from '@/models/TokenResponse';

export function isTokenExpired(token: string): boolean {
  try {
    const decoded = jwtDecode<JwtPayloadResponse>(token);
    return new Date(decoded.exp! * 1000) < new Date(Date.now());
  } catch (err) {
    return true;
  }
}

export function isRefreshTokenExpired(): boolean {
  const tokenResponse = getTokenResponse();
  const refresh = tokenResponse?.refresh;
  if (refresh) return isTokenExpired(refresh);

  return true;
}

export async function refreshAccessTokenWithRefreshToken(
  client: AxiosInstance,
) {
  const tokenResponse = getTokenResponse();

  const refresh = tokenResponse?.refresh;

  try {
    const { data: tokenResponse } = await client.post<TokenResponse>(
      '/auth/token/refresh/',
      {
        refresh,
      },
    );

    setTokenResponse({ tokenResponse, remember: false });

    return tokenResponse.access;
  } catch (error) {
    console.error('Error refreshing token');
    redirectToLogin();
    return Promise.reject(error);
  }
}

export function redirectToLogin(): void {
  if (!window.location.href.endsWith('login')) {
    window.location.replace('/login');
  }
}

export function isSuperAdmin(role?: UserTypeEnum) {
  return /SUPER_ADMIN/gi.test(role || '');
}

export function isAdmin(role?: UserTypeEnum) {
  return /ADMIN/gi.test(role || '');
}

export function isTeacher(role?: UserTypeEnum) {
  return /TEACHER/gi.test(role || '');
}

export function isStudent(role?: UserTypeEnum) {
  return /STUDENT/gi.test(role || '');
}

export function isStaff(role?: UserTypeEnum) {
  return (
    role &&
    [
      UserTypeEnum.SUPER_ADMIN,
      UserTypeEnum.UNIT_ADMIN,
      UserTypeEnum.TEACHER,
    ].includes(role)
  );
}

export function isUnitAdmin(role?: UserTypeEnum) {
  return /UNIT_ADMIN/gi.test(role || '');
}
