import { useTranslation } from 'react-i18next';
import { twMerge } from 'tailwind-merge';

import {
  EditVacationNotice,
  StudentVacationNoticeProps,
} from './StudentVacationNotice';
import ConditionalRenderer from '@/components/common/ConditionalRenderer';
import ButtonInfiniteList from '@/components/common/ButtonInfiniteList';
import { VacationNotice } from '@/models/VacationNotice';
import { handleUserFullName } from '@/functions/handleUserFullName';
import { scrollBarYClassName } from '@/utils/scrollBarClassName';
import VacationNoticeView from './VacationNoticeView';

export type VacationNoticesProps = StudentVacationNoticeProps & {
  vacationNotices: VacationNotice[];
  loading?: boolean;
  fetchNextPage(): unknown | Promise<unknown>;
  hasNextPage?: boolean;
  isFetchingNextPage?: boolean;
  edit({ vacationNotice, tab }: EditVacationNotice): void;
  remove: (areaId: number) => void;
  error?: string;
};

export default function VacationNotices({
  loading,
  fetchNextPage,
  hasNextPage,
  isFetchingNextPage,
  edit,
  remove,
  error,
  student,
  vacationNotices,
}: VacationNoticesProps) {
  const [t] = useTranslation('translation', {
    keyPrefix: 'common',
  });

  const ulId = 'ulVacationNotices' + student.id;

  return (
    <ButtonInfiniteList
      testId="vacationNotices"
      isLoading={loading}
      error={error}
      hasNextPage={hasNextPage}
      isFetchingNextPage={isFetchingNextPage}
      fetchNextPage={fetchNextPage}
      listClassName="flex"
    >
      <ConditionalRenderer condition={!loading && vacationNotices.length}>
        <ul
          id={ulId}
          className={twMerge(
            'flex flex-col gap-4 grow max-h-[22rem] pr-5 w-full',
            scrollBarYClassName,
          )}
        >
          {vacationNotices.map(vacationNotice => (
            <VacationNoticeView
              key={vacationNotice.id}
              vacationNotice={vacationNotice}
              edit={edit}
              remove={remove}
            />
          ))}
        </ul>
      </ConditionalRenderer>

      <ConditionalRenderer
        condition={!loading && !error && !vacationNotices.length}
      >
        {t('noInfo', { fullName: handleUserFullName(student) })}
      </ConditionalRenderer>
    </ButtonInfiniteList>
  );
}
