import requester from '@/axios';
import { Avatar, Inventory, InventoryItems, ShopItem } from '@/models/Avatar';
import JsonApiResponse from '@/models/JsonApiResponse';
import { RewardEnum } from '@/models/Rewards';
import { BaseFilters } from '@/models/Service';

const BASE_URL = 'avatar';

export type AvatarFilters = BaseFilters & {
  klass?: number;
  userId?: number;
};

export const listAvatars = async (params: AvatarFilters = {}) => {
  const { data } = await requester().get<JsonApiResponse<Avatar>>(
    `${BASE_URL}/`,
    { params },
  );
  return data;
};

export const getUserAvatarByUserId = async (userId: number) => {
  const { data } = await requester().get<Avatar>(`${BASE_URL}/${userId}/`);
  return data;
};

//TODO: trocar a rota pra devolver um único registro
export const getInventoryByAvatarId = async (avatarId: number) => {
  const { data } = await requester().get<JsonApiResponse<Inventory>>(
    `avatar/${avatarId}/inventory/`,
  );
  return data.results[0];
};

export const updateUserAvatar = async (
  userId: number,
  changes: Partial<Avatar>,
) => {
  const { data: userAvatar } = await requester().patch(
    `avatar/${userId}/`,
    changes,
  );
  return userAvatar;
};

export type ListShopItemsFilters = BaseFilters & {
  itemType?: keyof InventoryItems;
};

export const listShopItems = async (params: ListShopItemsFilters = {}) => {
  if (params.itemType) {
    const upperCasedCategory =
      params.itemType[0].toUpperCase() + params.itemType.substring(1);
    params.itemType = upperCasedCategory as keyof InventoryItems;
  }
  const { data } = await requester().get<JsonApiResponse<ShopItem>>(`shop/`, {
    params,
  });
  return data;
};

export const postShopPurchase = async (
  itemShopId: number,
  paymentType: RewardEnum,
) => {
  const { data: shopPurchase } = await requester().post(`shop/purchase/`, {
    itemShopId,
    paymentType,
  });
  return shopPurchase;
};
