import { twMerge } from 'tailwind-merge';
import { MouseEventHandler, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { CheckCircleIcon } from '@heroicons/react/solid';
import { DocumentDuplicateIcon } from '@heroicons/react/outline';

import Text, { TextProps } from './Text';
import { Tooltip } from './Tooltip';
import IconButton from '@/components/common/buttons/IconButton';
import alert from '@/utils/UseAlert';
import ConditionalRenderer from '../ConditionalRenderer';
import { ClassNameComponent } from '@/utils/ClassNameComponent';
import ConditionalWrapper from '../ConditionalWrapper';

type TextWithCopyProps = Omit<TextProps, 'text' | 'className'> & {
  copyText?: string;
  copiedText?: string;
  target?: string;
  label: string;
  clickText?: boolean;
  className?: Pick<ClassNameComponent, 'base' | 'text' | 'span'>;
  truncate?: boolean;
};

export default function TextWithCopy({
  className,
  copyText,
  copiedText,
  clickText,
  label,
  target = label,
  truncate,
  ...textProps
}: TextWithCopyProps) {
  const [isCopied, setIsCopied] = useState(false);
  const [timerId, setTimerId] = useState<NodeJS.Timeout | null>(null);

  const { t: tCommon } = useTranslation('translation', {
    keyPrefix: 'common',
  });

  const startTimeout = () => {
    const timer = setTimeout(() => {
      setIsCopied(false);
      setTimerId(null);
    }, 2000);
    setTimerId(timer);
  };

  const handleCopy = (text: string) => {
    if (isCopied && timerId) {
      clearTimeout(timerId);
      startTimeout();
      return;
    }
    navigator.clipboard.writeText(text);
    alert.success(tCommon('copied'));
    setIsCopied(true);
    startTimeout();
  };

  const onClickButton: MouseEventHandler<HTMLButtonElement> = e => {
    e.stopPropagation();
    handleCopy(target);
  };

  return (
    <ConditionalRenderer condition={!!label}>
      <div className={twMerge('flex items-center gap-1', className?.base)}>
        <ConditionalWrapper
          condition={!!truncate}
          wrapper={
            <span className={twMerge('overflow-hidden', className?.span)} />
          }
        >
          <Text
            text={label}
            {...textProps}
            className={className?.text}
            onClick={() => clickText && handleCopy(target)}
          />
        </ConditionalWrapper>
        <Tooltip
          text={
            !isCopied
              ? copyText || tCommon('copy')
              : copiedText || tCommon('copied')
          }
          placement="right"
        >
          <IconButton
            icon={
              isCopied ? (
                <CheckCircleIcon
                  data-testid="checkIcon"
                  className="w-4 h-4 text-success"
                />
              ) : (
                <DocumentDuplicateIcon
                  data-testid="documentIcon"
                  className="w-4 h-4  text-primary"
                />
              )
            }
            onClick={onClickButton}
            className="opacity-75 hover:opacity-100 border-none transition ease-in-out duration-150"
          />
        </Tooltip>
      </div>
    </ConditionalRenderer>
  );
}
