import Activity, { ActivityTypeEnum } from './Activity';
import { QuizQuestion } from './ActivityProgress';
import { ColumnDND } from './DNDOrdenation';
import MultipleChoiceAnswer from './MultipleChoiceAnswer';
import { CodeEditorFields } from '@/components/activities/elements/edit/CodeEditorElementEdit/CodeEditorForm';

export interface ChallengeActivity extends Activity {
  questions: TextActivityElement[];
}

export type changeStatus = 'new' | 'edit' | 'changed';

export default interface ActivityElement {
  id: number;
  identifier: string;
  questionId?: number;
  type: ActivityTypeEnum;
  activityProgressUrl?: string;
  activity?: number;
  progressId?: number;
  order: number;
  changeStatus?: changeStatus;
  quizQuestions?: MultipleChoiceAnswer[];
}

export enum VideoPlayerEnum {
  YOUTUBE = 'YOUTUBE',
  VIMEO = 'VIMEO',
}

export interface ErrorList {
  identifier: string;
  errors: string[];
}

export interface TextActivityElement extends ActivityElement {
  title: string;
  subtitle: string;
  content: string;
}

export interface CodeActivityElement extends ActivityElement {
  title: string;
  instructions: string;
  map: object;
  isValidated?: boolean;
  tip?: string;
  maxBlocks?: number;
}

export interface VideoActivityElement extends ActivityElement {
  videoPlayer: VideoPlayerEnum;
  url: string;
  title: string;
  subtitle: string;
  width?: number;
  height?: number;
}

export interface TrueOrFalseActivityElement extends ActivityElement {
  question: string;
  isTrue: boolean;
  reason: string;
  content: string;
}

export enum QuizAnswersType {
  IMAGE = 'IMAGE',
  TEXT = 'TEXT',
}

export interface QuizActivityElement extends ActivityElement {
  question: string;
  quizAnswers: MultipleChoiceAnswer[] | QuizQuestion[];
  reason: string;
  hasStepper: boolean;
  content: string;
  answerType: QuizAnswersType;
}

export type CodeActivityGoal = {
  coins: number;
  checkpoints: number;
};

export interface CodeActivityEditElement extends CodeActivityElement {
  teacherCode: object;
  goal: CodeActivityGoal;
}

export interface QuizActivityEditElement extends QuizActivityElement {
  quizAnswers: MultipleChoiceAnswer[];
}

export interface DNDOrdenationActivity extends ActivityElement {
  title: string;
  subtitle: string;
  columns: ColumnDND[];
}

export interface DNDPositionalActivity extends ActivityElement {
  title: string;
  subtitle: string;
  questionColumn: any;
  answerColumn: any;
}

export type CodeEditorActivityElement = ActivityElement & CodeEditorFields;
