import { useTranslation } from 'react-i18next';
import { DNDOrdenationActivity } from '../../../../../../models/ActivityElement';
import ActivityHeading from '../../ActivityHeading';
import DNDOrdenationColumn from './DNDOrdenationColumn';

interface DNDOrdenationColumnListProps {
  dndOrdenationActivity: DNDOrdenationActivity;
}

export default function DNDOrdenationColumnList({
  dndOrdenationActivity,
}: DNDOrdenationColumnListProps) {
  const { title, subtitle, columns } = dndOrdenationActivity;

  const { t } = useTranslation('translation', {
    keyPrefix: 'lesson.activity.dragAndDropOrdenation',
  });

  return (
    <div className="flex flex-col items-center gap-8">
      <ActivityHeading
        heading={title}
        content={subtitle}
        subheading={t('instruction')}
      />

      <div
        className={`grid w-full gap-10 max-w-[80%] ${
          columns.length > 1 ? 'grid-cols-1 lg:grid-cols-2' : 'lg:max-w-[50%]'
        }`}
      >
        {columns.map(dndOrdenationColumn => {
          return (
            <DNDOrdenationColumn
              key={dndOrdenationColumn.id}
              dndOrdenationColumn={dndOrdenationColumn}
            />
          );
        })}
      </div>
    </div>
  );
}
