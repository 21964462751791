import React, { Fragment } from 'react';
import Text from '../dataDisplay/Text';
import Skeleton from '../Skeleton';
import ConditionalRenderer from '../ConditionalRenderer';
import { useTranslation } from 'react-i18next';

type TagColor = 'warning' | 'error' | 'success';

export default function FeedbackTag({
  text,
  average,
  count,
  isLoading,
  showCount,
}: {
  text: string;
  average?: number;
  count?: number;
  showCount?: boolean;
  isLoading: boolean;
}) {
  const { t } = useTranslation('translation', {
    keyPrefix: 'feedbackPage.tag',
  });
  const tagColorClasses: Record<TagColor, string> = {
    error: 'bg-error-content border-error',
    warning: 'bg-warning-content border-warning',
    success: 'bg-success-content border-success',
  };
  const transformedRating = average ? (average * 5) / 3 : 0;

  const getTagColor = (number: number): TagColor => {
    if (average && number < 3) {
      return 'error';
    } else if (average && number > 3 && number < 3.5) {
      return 'warning';
    } else return 'success';
  };
  const textColor = `text-${getTagColor(transformedRating)}`;
  if (isLoading) {
    return (
      <Skeleton className="bg-primary-content border border-primary/40 rounded-full h-6 w-56" />
    );
  }
  if (count === 0) {
    return <Fragment />;
  }
  return (
    <div
      className={
        'border rounded-lg xl:rounded-full gap-x-2 gap-y-1.5 p-1 lg:px-3 xl:px-2 lg:py-0.5 flex flex-wrap flex-row justify-between md:items-center w-fit ' +
        tagColorClasses[getTagColor(transformedRating)]
      }
    >
      <div className="flex w-full lg:w-fit justify-between items-center md:justify-normal md:gap-x-2">
        <Text format="rubik-500" text={text + ': '} />
        <div className="flex flex-row shrink-0 w-fit items-center self-center xl:items-end xl:self-end">
          <Text
            format="rubik-500"
            className={`${textColor} lg:text-18`}
            text={transformedRating.toFixed(2)}
          />
          <Text className="text-14" text={'/5 '} />
        </div>
      </div>
      <ConditionalRenderer condition={showCount}>
        <Text
          text={`| ${count} ${
            count === 1 ? t('feedbackSingular') : t('feedbackPlural')
          }`}
        />
      </ConditionalRenderer>
    </div>
  );
}
