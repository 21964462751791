import Text from '@/components/common/dataDisplay/Text';

export function ResumeField({
  label,
  icon: Icon,
  value,
}: {
  label: string;
  icon?: React.ReactElement;
  value: string | string[] | React.ReactElement[];
}) {
  return (
    <div className="flex flex-col gap-4 items-start">
      <div className="flex items-center gap-2 justify-center">
        <Text size="text-14" format="rubik-400" text={label} />
        {Icon}
      </div>
      <div className="ml-2 flex flex-col items-start gap-2 text-left">
        {Array.isArray(value) ? (
          value.map(text =>
            typeof text === 'string' ? (
              <Text key={text} size="text-16" format="rubik-500" text={text} />
            ) : (
              text
            ),
          )
        ) : (
          <Text size="text-16" format="rubik-500" text={value} />
        )}
      </div>
    </div>
  );
}
