import IconButton from '@/components/common/buttons/IconButton';
import ConditionalRenderer from '@/components/common/ConditionalRenderer';
import CheckGroup, {
  CheckGroupContainer,
  CheckOption,
} from '@/components/common/dataInput/CheckGroup';
import SearchCheckGroup, {
  BaseModel,
  InfiniteSearchInputProps,
} from '@/components/common/dataInput/SearchCheckbox';
import NotificationTag from '@/components/notifications/card/NotificationTag';
import useAuth from '@/data/hook/useAuth';
import useToggle from '@/data/hook/useToggle';
import {
  klassesQueryKeys,
  unitsQueryKeys,
  usersQueryKeys,
} from '@/data/services/querykeys';
import { isSuperAdmin } from '@/functions/auth';
import { handleUserFullName } from '@/functions/handleUserFullName';
import { AlertTypeEnum } from '@/models/Alert';
import { BaseFilters } from '@/models/Service';
import { UserStatusEnum, UserTypeEnum } from '@/models/User';
import { getAuthorizedUnits } from '@/utils/getAuthorizedUnits';
import { SearchIcon, XIcon } from '@heroicons/react/outline';
import { forwardRef, useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { AlertFiltersOptions } from './NotificationsPageContent';

const NotificationFiltersForm = () => {
  const { t } = useTranslation('translation', { keyPrefix: 'alerts.filters' });
  const { user } = useAuth();
  const { register, watch, setValue } = useFormContext<AlertFiltersOptions>();

  const handleClean = (key: keyof AlertFiltersOptions) => {
    setValue(key, []);
  };

  const [unitId, klassId, types, teacherId] = watch([
    'unitId',
    'klassId',
    'types',
    'teacherId',
  ]);

  const handleTypeChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    key: keyof AlertFiltersOptions,
    arr?: number[],
  ) => {
    const { checked } = e.target;
    const value = Number(e.target.value);
    const newTypes = checked
      ? [...(arr || []), value]
      : arr?.filter(type => Number(type) !== value);

    setValue(key, newTypes);
  };

  const alertTypeOptions: CheckOption[] = Object.values(AlertTypeEnum).map(
    type => ({
      id: 'checkbox' + type,
      value: type,
      label: <NotificationTag type={type} />,
    }),
  );

  return (
    <section className="flex flex-col gap-4">
      <CheckGroupContainer
        title={t('type')}
        badgeCount={types?.length}
        initOpen
      >
        <CheckGroup options={alertTypeOptions} {...register('types')} />
      </CheckGroupContainer>
      <ConditionalRenderer
        condition={
          (user?.unitsIds.length || 0) > 1 || isSuperAdmin(user?.userType)
        }
      >
        <CreateFilter
          title={t('unit')}
          badgeCount={unitId?.length}
          selecteds={unitId}
          onClean={() => handleClean('unitId')}
          service={unitsQueryKeys.list}
          filters={{
            idIn: getAuthorizedUnits(user)?.toString(),
            isActive: true,
          }}
          showSearch={isSuperAdmin(user?.userType)}
          displayName={unit => unit.name}
          {...register('unitId', {
            onChange: e => handleTypeChange(e, 'unitId', unitId),
          })}
        />
      </ConditionalRenderer>

      <CreateFilter
        title={t('teacher')}
        badgeCount={teacherId?.length}
        onClean={() => handleClean('teacherId')}
        service={usersQueryKeys.list}
        showSearch
        selecteds={teacherId}
        filters={{
          userType: [UserTypeEnum.TEACHER],
          unit: getAuthorizedUnits(user),
          status: [UserStatusEnum.REGISTRED],
        }}
        displayName={handleUserFullName}
        {...register('teacherId', {
          onChange: e => handleTypeChange(e, 'teacherId', teacherId),
        })}
      />

      <CreateFilter
        title={t('klass')}
        badgeCount={klassId?.length}
        onClean={() => handleClean('klassId')}
        service={klassesQueryKeys.list}
        showSearch
        selecteds={klassId}
        filters={{
          unitId: getAuthorizedUnits(user),
        }}
        displayName={klass => klass.name}
        {...(register('klassId'),
        {
          onChange: e => handleTypeChange(e, 'klassId', klassId),
        })}
      />
    </section>
  );
};

type FilterActionsProps = {
  onClickSearch?: () => void;
  onClickClean?: () => void;
  showClean?: boolean;
  showSearch?: boolean;
};
const FilterActions = ({
  showClean,
  showSearch,
  onClickClean,
  onClickSearch,
}: FilterActionsProps) => {
  return (
    <div className="flex gap-2 items-center">
      <ConditionalRenderer condition={showSearch}>
        <IconButton
          icon={<SearchIcon className="w-4 text-base-content" />}
          onClick={onClickSearch}
        />
      </ConditionalRenderer>
      <ConditionalRenderer condition={showClean}>
        <IconButton
          icon={
            <XIcon className="w-4 text-base-content" onClick={onClickClean} />
          }
        />
      </ConditionalRenderer>
    </div>
  );
};

type CreateFilterProps<
  T extends BaseFilters = BaseFilters,
  K extends BaseModel = BaseModel,
> = {
  badgeCount?: number;
  onClean?: () => void;
} & InfiniteSearchInputProps<T, K>;

const CreateFilter = forwardRef<HTMLInputElement, CreateFilterProps>(
  ({ badgeCount = 0, onClean, title, ...inputProps }, ref) => {
    const { isOpen, toggle, open } = useToggle();
    const { isOpen: search, toggle: toogleSearch } = useToggle();

    useEffect(() => {
      if (search) {
        open();
      }
    }, [search, open]);

    return (
      <CheckGroupContainer
        title={title || ''}
        badgeCount={badgeCount}
        isOpen={isOpen}
        onToggle={toggle}
        actions={
          <FilterActions
            showClean={badgeCount > 0}
            showSearch={inputProps.showSearch}
            onClickSearch={toogleSearch}
            onClickClean={onClean}
          />
        }
      >
        <SearchCheckGroup
          {...inputProps}
          showSearch={inputProps.showSearch && search}
          ref={ref}
        />
      </CheckGroupContainer>
    );
  },
) as <Filter extends BaseFilters, Model extends BaseModel>(
  props: CreateFilterProps<Filter, Model> & {
    ref?: React.Ref<HTMLInputElement>;
  },
) => JSX.Element;

export default NotificationFiltersForm;
