import { useAvatar } from '@/data/context/AvatarContext';
import useAuth from '@/data/hook/useAuth';
import AvatarCharacter from './AvatarCharacter';
import { useQuery } from '@tanstack/react-query';
import { avatarsQueryKeys } from '@/data/services/querykeys';

type UserAvatarProps = {
  userId: number;
  className?: string;
};

export default function UserAvatar({
  userId,
  className = '',
}: UserAvatarProps) {
  const { user, isLoading } = useAuth();
  const { userAvatar, isLoadingUserAvatar } = useAvatar();
  const isUser = userId === user?.id;
  const { data: visitAvatar, isInitialLoading: isLoadingVisitAvatar } =
    useQuery({
      ...avatarsQueryKeys.get(userId),
      enabled: !!userId && !isUser && user !== undefined,
      refetchOnMount: false,
    });

  const avatar = isUser ? userAvatar : visitAvatar;
  return (
    <AvatarCharacter
      className={className}
      userAvatar={avatar}
      isLoading={isLoading || isLoadingUserAvatar || isLoadingVisitAvatar}
    />
  );
}
