import { DNDItem } from '../../../../../../models/DNDItem';
import DNDItemContainer from '../../DNDItem/DNDItemContainer';
import React from 'react';

interface DNDPositionalItemProps {
  dndPositionalItem: DNDItem;
}

export default function DNDPositionalItem({
  dndPositionalItem,
}: DNDPositionalItemProps) {
  const { text, image, altText, order } = dndPositionalItem;

  return (
    <DNDItemContainer
      text={text}
      image={image}
      altText={altText}
      children={<React.Fragment />}
      order={order}
    />
  );
}
