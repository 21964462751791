import { PropsWithChildren } from 'react';
import { useTabsPanelContext } from './Tabs';
import { panelAnimation } from '@/utils/animations/tabsAnimation';
import { motion } from 'framer-motion';
import { twMerge } from 'tailwind-merge';

type TriggerContainerProps = PropsWithChildren<{
  id: string;
}>;

const TabsTriggerContainer = ({ children, id }: TriggerContainerProps) => {
  const { orientation, color, active } = useTabsPanelContext();

  const isActive = id === active;

  const styleIsActive = isActive
    ? color + ' text-primary'
    : 'text-secondary hover:bg-base-100/30';

  return (
    <motion.div
      className={twMerge(
        `relative px-2 flex gap-4 items-center justify-between text-16`,
        styleIsActive,
        orientation === 'horizontal'
          ? 'rounded-t-md'
          : 'rounded-l-md overflow-hidden',
      )}
      {...panelAnimation}
    >
      {children}
    </motion.div>
  );
};

export default TabsTriggerContainer;
