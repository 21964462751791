import { requesterS3 } from '../axios';
import { IMAGE_TIMEOUT } from '../constants';

export default function isAceptableType(
  acceptTypes: string,
  fileType?: string,
): boolean {
  if (fileType) {
    const acceptTypesNames = acceptTypes.replaceAll('.', '');
    const fileTypeName = fileType.split('/')[1];
    const isAcceptableType = acceptTypesNames.includes(fileTypeName);
    return isAcceptableType;
  } else return false;
}

export const bannerConfig = {
  name: 'banner_img',
  multiple: false,
  accept: '.png, .jpg, .jpeg, .webpack',
};

export async function verifyImageExists(
  imgUrl: string,
  timeout = IMAGE_TIMEOUT,
) {
  return await requesterS3().head(imgUrl, {
    timeout,
  });
}

export const validateUpload = (file: File) =>
  !isAceptableType(bannerConfig.accept, file.type) &&
  !!URL.createObjectURL(file);

export function imagePreview(image: string | File) {
  if (typeof image === 'string') return image;
  return URL.createObjectURL(image);
}
