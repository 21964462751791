import { AxiosResponse } from 'axios';
import requester from '@/axios';
import { VideoActivityProgress } from '@/models/ActivityProgress';

export interface GetVideoActivityProgressParams {
  videActivityId: number;
  videoActivityProgressId: number;
}

export const getVideoActivityProgress = async ({
  videActivityId,
  videoActivityProgressId,
}: GetVideoActivityProgressParams) => {
  const { data: videActivityProgress } =
    await requester().get<VideoActivityProgress>(
      `video-activity/${videActivityId}/video-activity-progress/${videoActivityProgressId}/`,
    );
  return videActivityProgress;
};

type UpdateVideoActivityProgress = Pick<VideoActivityProgress, 'isDone'>;

export const updateVideoActivityProgress = async (
  { videActivityId, videoActivityProgressId }: GetVideoActivityProgressParams,
  body: UpdateVideoActivityProgress,
) => {
  type Response = AxiosResponse<VideoActivityProgress>;
  const { data: videActivityProgress } = await requester().patch<
    VideoActivityProgress,
    Response
  >(
    `video-activity/${videActivityId}/video-activity-progress/${videoActivityProgressId}/`,
    body,
  );
  return videActivityProgress;
};
