import { ChallengeEditing } from '@/components/admin/lessons/ChallengeEditing';
import ListActivities from '@/components/admin/lessons/ListActivities';
import TeacherLessonDetail from '@/components/admin/lessons/TeacherLessonDetail';
import MaterialContainer from '@/components/admin/lessons/material/MaterialContainer';
import { Pane } from '@/components/common/dataDisplay/Tabs/Tabs';
import ScheduledLesson from '@/models/ScheduledLesson';
import {
  BookOpenIcon,
  ClipboardListIcon,
  InformationCircleIcon,
  LightningBoltIcon,
} from '@heroicons/react/outline';
import { useTranslation } from 'react-i18next';

const useMountExtraLessonPanes = (
  scheduledLesson: ScheduledLesson,
  options: {
    slugCourseName: string;
    klassId: number;
    readOnly: boolean;
    updateScheduledLessons(): Promise<void>;
    canEditScheduledLesson?: boolean;
  },
) => {
  const { lesson } = scheduledLesson;
  const { t } = useTranslation('translation', { keyPrefix: 'klass.lessons' });
  const { slugCourseName, updateScheduledLessons, klassId, readOnly } = options;
  const panes: Pane[] = [
    {
      key: 'details',
      text: t('details'),
      icon: InformationCircleIcon,
      content: (
        <TeacherLessonDetail
          canEditScheudledLesson={options.canEditScheduledLesson}
          klassId={klassId}
          slugCourseName={slugCourseName}
          readOnly={readOnly}
          scheduledLesson={scheduledLesson}
          updateScheduledLessons={updateScheduledLessons}
        />
      ),
    },
  ];
  if (scheduledLesson.lesson.book)
    panes.push({
      key: 's-material',
      text: t('studentBook'),
      icon: BookOpenIcon,
      content: (
        <MaterialContainer
          lesson={scheduledLesson.lesson}
          bookType="STUDENT"
          readOnly
        />
      ),
    });
  if (scheduledLesson.lesson.teacherBook)
    panes.push({
      key: 't-material',
      text: t('teacherBook'),
      icon: BookOpenIcon,
      content: (
        <MaterialContainer lesson={lesson} bookType="TEACHER" readOnly />
      ),
    });
  if (lesson.activities.length || !readOnly)
    panes.push({
      key: 'activities',
      text: t('activity'),
      icon: ClipboardListIcon,
      content: (
        <ListActivities
          updateLessons={updateScheduledLessons}
          lesson={lesson}
          type={scheduledLesson.type}
          klassId={klassId}
          readOnly={readOnly}
        />
      ),
    });
  if (lesson.challenge || !readOnly)
    panes.push({
      key: 'challenge',
      text: t('challenge'),
      icon: LightningBoltIcon,
      content: (
        <ChallengeEditing
          onUpdate={updateScheduledLessons}
          lesson={lesson}
          type={scheduledLesson.type}
          readOnly={readOnly}
        />
      ),
    });

  return panes;
};

export default useMountExtraLessonPanes;
