import React from 'react';
import { Link } from 'react-router-dom';
import useAuth from '../../data/hook/useAuth';
import { UserTypeEnum } from '../../models/User';
import { SolidGradientCheckCircleIcon, DashedCircleIcon } from '../icons';
import Text from '../common/dataDisplay/Text';
import ConditionalRenderer from '../common/ConditionalRenderer';

interface LessonItemsListProps {
  title: string;
  url: string;
  onClick?: () => void;
  completed?: boolean;
  children?: JSX.Element;
  disable?: boolean;
  activityType?: string;
  role: (UserTypeEnum | 'ALL')[];
  testId?: string;
}

export default function LessonItemsList(props: LessonItemsListProps) {
  const { title, url, onClick, completed, disable } = props;
  const { user } = useAuth();

  if (
    !props.role.includes('ALL') &&
    !props.role.includes(user?.userType || 'ALL')
  ) {
    return <React.Fragment />;
  }

  return (
    <li className="lesson-item-list" onClick={onClick}>
      <div className="flex flex-wrap items-center justify-between">
        <div className="flex items-center hover:opacity-80 cursor-pointer font-rubik gap-4">
          <ConditionalRenderer
            condition={user?.userType === UserTypeEnum.STUDENT}
          >
            {completed ? (
              <SolidGradientCheckCircleIcon className="w-5 h-5" />
            ) : (
              <DashedCircleIcon className="w-5 h-5 text-primary" />
            )}
          </ConditionalRenderer>
          <Link
            data-testid={props.testId}
            to={url}
            className={disable ? 'pointer-events-none' : 'pointer-events-auto'}
          >
            <Text
              text={title}
              className="text-primary align-bottom text-18"
              format="rubik-500"
            />
          </Link>
        </div>
        <div className="flex items-center flex-col w-full lg:w-min lg:flex-row">
          {props.children}
        </div>
      </div>
    </li>
  );
}
