import requester from '@/axios';
import { CodeEditorFields } from '@/components/activities/elements/edit/CodeEditorElementEdit/CodeEditorForm';
import { CodeEditorActivityElement } from '@/models/ActivityElement';
import { CodeEditorActivity } from '@/models/CodeEditorActivity';
import JsonApiResponse from '@/models/JsonApiResponse';

const BASE_URL = 'code-editor-activity';

export const listCodeEditorActivities = async () => {
  const { data } = await requester().get<JsonApiResponse<CodeEditorActivity>>(
    `${BASE_URL}/`,
  );

  return data;
};

export const getCodeEditorActivity = async (questionId: number) => {
  const { data } = await requester().get<CodeEditorActivity>(
    `${BASE_URL}/${questionId}/`,
  );

  return data;
};

export const createCodeEditorActivity = async (
  body: Partial<CodeEditorActivityElement>,
) => {
  const { data } = await requester().post<CodeEditorActivity>(
    `${BASE_URL}/`,
    body,
  );

  return data;
};

export type UpdateCodeEditorActivity = {
  codeActivityId: number;
  changes: Partial<CodeEditorFields>;
};

export const updateCodeEditorActivity = async (
  codeActivityId: number,
  changes: Partial<CodeEditorFields>,
) => {
  const { data } = await requester().patch<CodeEditorActivity>(
    `${BASE_URL}/${codeActivityId}/`,
    changes,
  );

  return data;
};

export const deleteCodeEditorActivity = async (questionId: number) => {
  await requester().delete<CodeEditorActivity>(`${BASE_URL}/${questionId}/`);
};
