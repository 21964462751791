import {
  Dispatch,
  SetStateAction,
  useState,
  useRef,
  useEffect,
  Fragment,
} from 'react';
import {
  UseMutateFunction,
  useQuery,
  useMutation,
} from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';
import { isEqual } from 'lodash';
import { useNavigate } from 'react-router-dom';

import ConditionalRenderer from '@/components/common/ConditionalRenderer';
import SaveCancelGroup from '@/components/common/buttons/SaveCancelGroup';
import TextInput from '@/components/common/dataInput/TextInput';
import MyCkeditor from '@/components/editor/MyCkeditor';
import { LoadingIcon } from '@/components/icons';
import { REQUEST_AUTOSAVE_CHAPTER_TIME_IN_MS } from '@/constants';
import { UpdateChapter, updateChapter } from '@/data/services/chapterServices';
import chapterFormatter from '@/functions/chapterFormatter';
import Book from '@/models/Book';
import Chapter from '@/models/Chapter';
import { ImageFolderEnum } from '@/models/CkEditor';
import { stringToStringArray } from '@/utils/stringToStringArray';
import Text from '@/components/common/dataDisplay/Text';
import { getErrorMessage } from '@/utils/getErrorMessage';
import { chaptersQueryKeys } from '@/data/services/querykeys';

type ChapterContentProps = {
  lessonId: number;
  book: Book;
  chapterId: number;
  requesting?: boolean;
  setUploadigImage: Dispatch<SetStateAction<boolean>>;
  onUpdate: UseMutateFunction<Chapter, any, UpdateChapter, unknown>;
  backUrl?: string;
  invalidate: () => Promise<void>;
};

export default function ChapterContent({
  lessonId,
  book,
  chapterId,
  requesting,
  setUploadigImage,
  onUpdate,
  backUrl,
  invalidate,
}: ChapterContentProps) {
  const { t } = useTranslation('translation', {
    keyPrefix: 'editMaterialPage',
  });

  const navigate = useNavigate();

  const hasLessonId = !!lessonId;

  const hasBookId = !!book?.id;

  const {
    data: chapter,
    isInitialLoading: loadingChapter,
    error: chapterError,
  } = useQuery({
    enabled: hasLessonId && hasBookId && chapterId > 0,
    ...chaptersQueryKeys.get({
      lessonId: Number(lessonId),
      bookId: book?.id ?? 0,
      chapterId,
      bookType: book?.bookType ?? 'STUDENT',
    }),
  });

  const [title, setTitle] = useState<string>('');
  const [content, setContent] = useState<string>('');

  const titleRef = useRef<string>('');
  const contentRef = useRef<string>('');

  const titleHasBeenChanged = !isEqual(title, titleRef.current);
  const contentHasBeenChanged = !isEqual(content, contentRef.current);

  const hasBeenChanges = titleHasBeenChanged || contentHasBeenChanged;

  const { mutate: autoSave, isLoading: autoSavingChapter } = useMutation(
    updateChapter,
    {
      async onSuccess(data) {
        titleRef.current = data.title;
        contentRef.current = data.content;

        await invalidate();
      },
    },
  );

  const oldBook = chapter?.content[0] === '[';

  const reset = () => {
    setTitle('');
    setContent('');
  };

  useEffect(() => {
    if (chapter) {
      if (oldBook) {
        const content = chapterFormatter(
          chapter.title,
          stringToStringArray(chapter.content),
        );
        setContent(content);

        contentRef.current = content;
      } else {
        setContent(chapter.content);
        contentRef.current = chapter.content;
      }
      setTitle(chapter.title);

      titleRef.current = chapter.title;
    }

    return () => reset();
  }, [chapter, oldBook]);

  const chapterErrorDetail = getErrorMessage(chapterError);

  const className = `flex flex-col gap-4 w-[70%] ${
    requesting ? 'disabled' : ''
  } ${loadingChapter ? 'justify-center items-center' : ''}`;

  useEffect(() => {
    const timer = setTimeout(() => {
      if (hasBeenChanges && chapter) {
        const params = {
          chapterId: chapter?.id,
          bookId: book.id,
          bookType: book.bookType,
          lessonId: lessonId,
        };

        const body = {
          title,
          content,
        };

        autoSave({
          params,
          body,
        });
      }
    }, REQUEST_AUTOSAVE_CHAPTER_TIME_IN_MS);

    return () => clearTimeout(timer);
  }, [
    autoSave,
    book.bookType,
    book.id,
    chapter,
    content,
    hasBeenChanges,
    lessonId,
    title,
  ]);

  const onChangeTitle = (title: string) => setTitle(title);

  const onChangeContent = (content: string) => setContent(content);

  if (loadingChapter) {
    return (
      <div className={className}>
        <LoadingIcon className="w-40 text-secondary-content" />
      </div>
    );
  }

  if (chapterErrorDetail) {
    return (
      <div className={className}>
        <Text
          text={chapterErrorDetail}
          format="rubik-500"
          className="text-primary text-24"
        />
      </div>
    );
  }

  if (book && chapter) {
    const chapterId = chapter.id;
    const bookId = book.id;

    return (
      <div className={className}>
        <TextInput
          label={t('chapterTitle')}
          fontSize="text-14"
          value={title}
          onChange={e => onChangeTitle(e.target.value)}
        />

        <ConditionalRenderer condition={oldBook}>
          <Text
            format="rubik-500"
            text={t('chapterFormattingFailureMessage')}
            data-testid="editor"
            className="text-14 text-error bg-error-content rounded-lg sm:rounded-full sm:py-2 p-3 sm:px-7 text-center"
          />
        </ConditionalRenderer>
        <div className="flex flex-col gap-2">
          <div className="flex gap-2 items-center">
            <Text
              text={t('content')}
              data-testid="editor"
              className="text-14"
            />
            <ConditionalRenderer condition={autoSavingChapter}>
              <Text
                text={t('autosave')}
                className="text-14 text-primary animate-bounce"
              />
            </ConditionalRenderer>
          </div>

          <MyCkeditor
            imageUploadBody={{ chapterId, bookId }}
            onUploadStart={() => setUploadigImage(true)}
            onUploadEnd={() => setUploadigImage(false)}
            content={content}
            handleContentChange={e => onChangeContent(e)}
            folder={ImageFolderEnum.BOOK}
            format="book"
          />
        </div>

        <div className="fixed bottom-5 left-15">
          <SaveCancelGroup
            loading={requesting}
            save={{
              testId: 'saveButton',
              onClick: () =>
                onUpdate({
                  params: {
                    chapterId: chapter?.id,
                    bookId: book.id,
                    bookType: book.bookType,
                    lessonId: lessonId,
                  },
                  body: {
                    content,
                    title,
                  },
                }),
            }}
            cancel={{
              onClick: () => navigate(backUrl ?? ''),
              testId: 'cancelButton',
            }}
          />
        </div>
      </div>
    );
  }

  return <Fragment />;
}
