import { twMerge } from 'tailwind-merge';

export default function ContainerActivityEdit({
  children,
  className,
  ...props
}: {
  children: React.ReactNode;
} & React.HTMLAttributes<HTMLDivElement>) {
  return (
    <div
      className={twMerge('flex flex-col gap-5 overflow-hidden', className)}
      {...props}
    >
      {children}
    </div>
  );
}
