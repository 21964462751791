import { useEffect, useState } from 'react';
import Activity from '@/models/Activity';
import { DNDPositionalActivity } from '@/models/ActivityElement';
import DNDActivityEdit from './DNDActivityEdit';
import MainButton from '@/components/common/buttons/MainButton';
import { SaveIcon } from '@heroicons/react/outline';
import { useTranslation } from 'react-i18next';
import DNDPositionalColumnEdit from './DNDPositionalColumnEdit/DNDPositionalColumnEdit';
import { DNDColumn } from '@/models/DNDColumn';
import { DNDItem } from '@/models/DNDItem';
import {
  patchDndPositionalActivity,
  postDndPositionalActivity,
} from '@/data/services/activityElement/dndPositionalActivityServices';
import alert from '@/utils/UseAlert';
import {
  deleteDndPositionalItem,
  patchExistentDndPositionalItem,
  postDndPositionalItem,
} from '@/data/services/dndItemServices';
import { ApiError } from '@/models/Errors';
import {
  getDndPositionalColumn,
  patchDndPositionalColumn,
  patchDndPositionalColumnOrder,
} from '@/data/services/dndColumnServices';
import ContainerActivityEdit from '../commom/ContainerActivityEdit';

export type DNDAttributesHandler<T> = (params: {
  question: T;
  answer: T;
}) => void;
interface HandleRemoveItemParams {
  questionItem: DNDItem;
  answerItem: DNDItem;
  index: number;
}
export type HandleRemoveItem = (params: HandleRemoveItemParams) => void;

interface DNDElementEditProps extends Omit<DNDPositionalActivity, 'activity'> {
  activity: Activity;
  onEdit: (props: any) => void;
  onSave: (props: DNDPositionalActivity) => void;
}

export default function DNDPositionalElementEdit(props: DNDElementEditProps) {
  const { activity, onEdit, onSave, ...rest } = props;

  const { t } = useTranslation('translation', {
    keyPrefix: 'activity.manageActivity.dndPositionalElementEdit',
  });

  const [activityFieldsErrors, setActivityFieldsErrors] = useState<string[]>(
    [],
  );

  const [isLoading, setIsLoading] = useState(false);

  const [buttonDisabled, setButtonDisabled] = useState(
    typeof rest.changeStatus === 'undefined' || rest.changeStatus === 'edit',
  );

  const defaultQuestionColumn = new DNDColumn();

  const defaultAnswerColumn = new DNDColumn();

  function onChangeActivityFields(
    value: string,
    keyField: 'title' | 'subtitle',
  ) {
    onEdit({ ...rest, [keyField]: value, activity });
  }

  function checkFields() {
    const questionColumnCheck = rest.questionColumn || defaultQuestionColumn;
    const answerColumnCheck = rest.answerColumn || defaultAnswerColumn;

    const noItems = questionColumnCheck.items.length === 0;

    const errors = [];
    let hasError = false;

    if (!rest.title) {
      setActivityFieldsErrors(['noTitle']);
      hasError = true;
    } else {
      setActivityFieldsErrors([]);
      hasError = false;
    }

    if (noItems) {
      errors.push('noItems');
      hasError = true;
    }

    const questionItemsCheckingErrors = questionColumnCheck.items.map(
      (item: DNDItem) => {
        const errors = [];

        if (item.image && !item.altText) {
          errors.push('noAltText');
        }

        if (!item.image && item.altText) {
          errors.push('noImage');
        }

        if (!item.text && !item.image && !item.altText) {
          errors.push('noContent');
        }

        if (!!item.text && item.text.length > 150) {
          errors.push('maxLength');
        }

        if (!!item.altText && item.altText.length > 150) {
          errors.push('maxLengthAltText');
        }

        item.errors = errors;

        if (item.errors.length > 0) {
          hasError = true;
        }

        return item;
      },
    );

    const answerItemsCheckingErrors = answerColumnCheck.items.map(
      (item: DNDItem) => {
        const errors = [];

        if (item.image && !item.altText) {
          errors.push('noAltText');
        }

        if (!item.image && item.altText) {
          errors.push('noImage');
        }

        if (!item.text && !item.image && !item.altText) {
          errors.push('noContent');
        }

        if (!!item.text && item.text.length > 150) {
          errors.push('maxLength');
        }

        if (!!item.altText && item.altText.length > 150) {
          errors.push('maxLengthAltText');
        }

        item.errors = errors;

        if (item.errors.length > 0) {
          hasError = true;
        }

        return item;
      },
    );

    questionColumnCheck.items = questionItemsCheckingErrors;

    answerColumnCheck.items = answerItemsCheckingErrors;

    questionColumnCheck.errors = errors;

    const hasOneItem =
      questionColumnCheck.items.length === 1 ||
      answerColumnCheck.items.length === 1;

    if (hasOneItem) {
      hasError = true;
      alert.warning(t('dndErrors.onlyOneItem'));
    }

    onEdit({
      ...rest,
      questionColumn: questionColumnCheck,
      answerColumn: answerColumnCheck,
      activity,
    });

    return hasError;
  }

  async function handleSaveChangedActivity() {
    setIsLoading(true);
    try {
      const dndActicityPatch = await patchDndPositionalActivity(rest);

      await patchDndPositionalColumn(rest.questionColumn);

      await patchDndPositionalColumn(rest.answerColumn);

      if (rest.questionColumn.id) {
        const patchQuestionItems = await Promise.all(
          rest.questionColumn.items.map(async (questionItem: DNDItem) => {
            return await patchExistentDndPositionalItem(
              rest.questionColumn.id,
              questionItem,
            );
          }),
        );

        rest.questionColumn.items = patchQuestionItems;
      }

      if (rest.answerColumn.id) {
        const patchAnswerItems = await Promise.all(
          rest.answerColumn.items.map(async (answerItem: DNDItem) => {
            return await patchExistentDndPositionalItem(
              rest.answerColumn.id,
              answerItem,
            );
          }),
        );

        rest.answerColumn.items = patchAnswerItems;
      }

      onSave({
        ...rest,
        ...dndActicityPatch,
        questionColumn: rest.questionColumn,
        answerColumn: rest.answerColumn,
        changeStatus: 'edit',
      });
      alert.success(t('saveSuccess'));
    } catch (error: any) {
      const apiError = new ApiError(error);
      alert.error(apiError.getErrorMessage());
    } finally {
      setIsLoading(false);
    }
  }

  async function handleSaveNewActivity() {
    setIsLoading(true);
    try {
      const dndPositionalActivity = await postDndPositionalActivity(
        activity,
        rest,
      );

      const patchDndPositionalQuestionColumn = await patchDndPositionalColumn(
        rest.questionColumn,
        dndPositionalActivity.questionColumn.id,
      );
      const patchDndPositionalAnswerColumn = await patchDndPositionalColumn(
        rest.answerColumn,
        dndPositionalActivity.answerColumn.id,
      );

      dndPositionalActivity.questionColumn = patchDndPositionalQuestionColumn;
      dndPositionalActivity.answerColumn = patchDndPositionalAnswerColumn;

      const dndQuestionColumn = dndPositionalActivity.questionColumn;
      const dndAnswerColumn = dndPositionalActivity.answerColumn;

      const questionItems = await Promise.all(
        rest.questionColumn.items.map((questionItem: DNDItem, index: number) =>
          postDndPositionalItem(dndQuestionColumn.id, questionItem, index + 1),
        ),
      );

      const answerItems = await Promise.all(
        rest.answerColumn.items.map((questionItem: DNDItem, index: number) =>
          postDndPositionalItem(dndAnswerColumn.id, questionItem, index + 1),
        ),
      );

      dndQuestionColumn.items = questionItems;
      dndAnswerColumn.items = answerItems;

      onSave({
        ...rest,
        ...dndPositionalActivity,
        questionColumn: dndQuestionColumn,
        answerColumn: dndAnswerColumn,
        changeStatus: 'edit',
      });

      alert.success(t('saveSuccess'));
    } catch (error: any) {
      const apiError = new ApiError(error);
      alert.error(apiError.getErrorMessage());
    } finally {
      setIsLoading(false);
    }
  }

  async function handleSave() {
    if (checkFields()) return;

    switch (rest.changeStatus) {
      case 'new': {
        handleSaveNewActivity();
        break;
      }
      case 'changed': {
        handleSaveChangedActivity();
        break;
      }
    }
  }

  const handleUpdateColumn: DNDAttributesHandler<DNDColumn> = ({
    question: questionColumn,
    answer: answerColumn,
  }) => {
    const changeStatus =
      rest.changeStatus !== 'new' ? 'changed' : rest.changeStatus;

    onEdit({
      ...rest,
      questionColumn,
      answerColumn,
      activity,
      changeStatus,
    });
  };

  const handleAddItem: DNDAttributesHandler<DNDColumn> = async ({
    question,
    answer,
  }) => {
    if (rest.changeStatus !== 'new') {
      setIsLoading(true);
      try {
        if (question.id) {
          const newQuestionItem = await postDndPositionalItem(
            question.id,
            new DNDItem(undefined, t('dndColumnEdit.question')),
            question.items.length + 1,
          );

          question.items.push(newQuestionItem);
        }

        if (answer.id) {
          const newAnswerItem = await postDndPositionalItem(
            answer.id,
            new DNDItem(undefined, t('dndColumnEdit.answer')),
            answer.items.length + 1,
          );

          answer.items.push(newAnswerItem);
        }

        alert.success(t('saveAddItemSuccess'));
      } catch (error: any) {
        const apiError = new ApiError(error);
        alert.error(apiError.getErrorMessage());
      } finally {
        setIsLoading(false);
      }
    } else {
      question.items.push(new DNDItem());
      answer.items.push(new DNDItem());
    }

    handleUpdateColumn({
      question,
      answer,
    });
  };

  const handleRemoveItem: HandleRemoveItem = async ({
    questionItem,
    answerItem,
    index,
  }) => {
    if (rest.changeStatus !== 'new') {
      setIsLoading(true);

      try {
        if (questionItem.id && answerItem.id) {
          await deleteDndPositionalItem(
            questionItem.dndPositionalColumn,
            questionItem.id,
          );

          await deleteDndPositionalItem(
            answerItem.dndPositionalColumn,
            answerItem.id,
          );

          rest.questionColumn.items.splice(index, 1);
          rest.answerColumn.items.splice(index, 1);
        }

        alert.success(t('saveDeleteSuccess'));
      } catch (error: any) {
        const apiError = new ApiError(error);
        alert.error(apiError.getErrorMessage());
      } finally {
        setIsLoading(false);
      }
    } else {
      rest.questionColumn.items.splice(index, 1);
      rest.answerColumn.items.splice(index, 1);
    }

    handleUpdateColumn({
      question: rest.questionColumn,
      answer: rest.answerColumn,
    });
  };

  const handleOrdenationItems: DNDAttributesHandler<DNDItem[]> = async ({
    question,
    answer,
  }) => {
    if (rest.changeStatus !== 'new') {
      setIsLoading(true);

      try {
        const updatedQuestionListOrder = question.map(({ id }) => ({
          id,
        }));
        const updatedAnswerListOrder = answer.map(({ id }) => ({ id }));

        if (rest.questionColumn.id && updatedQuestionListOrder) {
          await patchDndPositionalColumnOrder(
            rest.questionColumn.id,
            updatedQuestionListOrder,
          );

          const updatedQuestionColumnItems = await getDndPositionalColumn(
            rest.questionColumn.id,
          );
          rest.questionColumn.items = updatedQuestionColumnItems.items;
        }

        if (rest.answerColumn.id && updatedAnswerListOrder) {
          await patchDndPositionalColumnOrder(
            rest.answerColumn.id,
            updatedAnswerListOrder,
          );

          const updatedAnswerColumnItems = await getDndPositionalColumn(
            rest.answerColumn.id,
          );
          rest.answerColumn.items = updatedAnswerColumnItems.items;
        }

        alert.success(t('saveOrderSuccess'));
      } catch (error: any) {
        const apiError = new ApiError(error);
        alert.error(apiError.getErrorMessage());
      } finally {
        setIsLoading(false);
      }
    } else {
      rest.questionColumn.items = question;
      rest.answerColumn.items = answer;
    }

    handleUpdateColumn({
      question: rest.questionColumn,
      answer: rest.answerColumn,
    });
  };

  useEffect(() => {
    let mounted = true;

    if (mounted) {
      setButtonDisabled(
        typeof rest.changeStatus === 'undefined' ||
          rest.changeStatus === 'edit',
      );
    }
  }, [rest.changeStatus]);

  return (
    <ContainerActivityEdit data-testid="dndPositionalElementEdit">
      <DNDActivityEdit
        title={rest.title}
        subtitle={rest.subtitle}
        activityFieldsErrors={activityFieldsErrors}
        onChangeActivityFields={onChangeActivityFields}
        isLoading={isLoading}
      />
      <DNDPositionalColumnEdit
        questionColumn={rest.questionColumn || defaultQuestionColumn}
        answerColumn={rest.answerColumn || defaultAnswerColumn}
        handleUpdateColumn={handleUpdateColumn}
        handleRemoveItem={handleRemoveItem}
        handleAddItem={handleAddItem}
        handleOrdenationItems={handleOrdenationItems}
        isLoading={isLoading}
      />
      <hr className="h-px my-4 border-primary/40" />
      <MainButton
        dataTestId="dndSaveButton"
        icon={<SaveIcon />}
        text={t('save')}
        onClick={handleSave}
        loading={isLoading}
        disabled={buttonDisabled}
      />
    </ContainerActivityEdit>
  );
}
