import { isEqual, differenceWith, toPairs } from 'lodash';

export function buildChangedObject<T extends {}>(
  original: T,
  modified: Partial<T>,
): Partial<T> {
  type KeyValue = [keyof Partial<T>, any];
  const changedFieldsArray = differenceWith(
    toPairs(modified),
    toPairs(original),
    isEqual,
  ) as KeyValue[];
  const body = Object.fromEntries(changedFieldsArray) as Partial<T>;

  return body;
}
