import { twMerge } from 'tailwind-merge';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { Fragment, SetStateAction, useState } from 'react';
import { useTranslation } from 'react-i18next';
import CollapsableCard from '../CollapsableCard';
import {
  ClipboardListIcon,
  DotsVerticalIcon,
  EyeIcon,
  RefreshIcon,
} from '@heroicons/react/outline';

import ConditionalRenderer from '@/components/common/ConditionalRenderer';
import Text from '@/components/common/dataDisplay/Text';
import SortToggle from '@/components/common/dataInput/SortToggle';
import IconButton from '@/components/common/buttons/IconButton';
import Popper from '@/components/common/dataDisplay/Popper';
import MainButton from '@/components/common/buttons/MainButton';
import { readAllNotification } from '@/data/services/notificationServices';
import alert from '@/utils/UseAlert';
import { ApiError } from '@/models/Errors';
import { NotificationTypeEnum } from '@/models/Notification';
import ComponentGuard from '@/components/common/ComponentGuard';
import { UserTypeEnum } from '@/models/User';
import AbsenceNotificationGroups, {
  AbsenceNotificationGroupBy,
  AbsenceNotificationScrollHandler,
} from './AbsenceNotificationGroup/AbsenceNotificationGroups';
import ToggleButtonGroup, {
  ToggleButton,
} from '@/components/common/dataInput/ToggleGroup';
import useAuth from '@/data/hook/useAuth';
import {
  absenceNotificationsQueryKeys,
  scheduledLessonReportsQueryKeys,
} from '@/data/services/querykeys';
import useInfiniteService from '@/data/hook/useInfiniteService';
import {
  useGroupFilters,
  useGroupItems,
} from './AbsenceNotificationGroup/AbsenceNotificationGroups.hooks';
import { ClassNameComponent } from '@/utils/ClassNameComponent';

type AbsenceReportsProps = {
  className?: ClassNameComponent;
};

export default function AbsenceReports({ className }: AbsenceReportsProps) {
  const { t } = useTranslation('translation', {
    keyPrefix: 'teacherDashboard.absenceReports',
  });
  const [orderByRecents, setOrderByRecents] = useState(true);
  const [activeGroup, setActiveGroup] =
    useState<AbsenceNotificationGroupBy>('all');

  const { filters } = useGroupFilters({
    filters: {
      ordering: orderByRecents ? '-created_at' : 'created_at',
    },
  });

  const { queryFn, queryKey: absencesQueryKeys } =
    absenceNotificationsQueryKeys.list(filters)._ctx.infinity;

  const {
    isLoading: isLoadingGroups,
    items,
    scrollHandler: groupScrollHandler,
  } = useGroupItems({
    groupBy: activeGroup,
  });

  const {
    results: absenceNotifications,
    meta,
    isInitialLoading: isLoading,
    isFetchingNextPage,
    fetchNextPage,
    hasNextPage = false,
  } = useInfiniteService({
    queryFn,
    queryKey: absencesQueryKeys,
  });

  const ungroupScrollHandler: AbsenceNotificationScrollHandler = {
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
  };

  const scrollHandler: AbsenceNotificationScrollHandler =
    groupScrollHandler ?? ungroupScrollHandler;

  const onClickGroup = (group: AbsenceNotificationGroupBy) => {
    setActiveGroup(group);
  };

  const total = meta?.pagination ? meta?.pagination.count : 0;

  const shouldRenderList = total > 0;

  const groupByButton: ToggleButton<AbsenceNotificationGroupBy>[] = [
    {
      value: 'all',
      text: t('groups.all'),
    },
    {
      value: 'teacher',
      text: t('groups.teacher'),
    },
    {
      value: 'klass',
      text: t('groups.klass'),
    },
  ];

  const { user } = useAuth();

  const exclusiveAdminGroup: AbsenceNotificationGroupBy[] = ['teacher'];

  const allowedGroups = groupByButton.filter(({ value }) => {
    if (user?.userType === UserTypeEnum.UNIT_ADMIN) {
      return true;
    }
    return !exclusiveAdminGroup.includes(value);
  });

  const navClassname = shouldRenderList
    ? 'flex justify-between items-center flex-wrap gap-3 px-5 py-2.5'
    : '';

  return (
    <CollapsableCard
      isCollapsable={!!absenceNotifications.length}
      headerIcon={<ClipboardListIcon className="w-6 stroke-2" />}
      title={t('absenceReports')}
      color="accent"
      isLoading={isLoading}
      badgeCount={total}
      header={<CardHeader hasNotification={shouldRenderList} />}
      hasNextPage={scrollHandler.hasNextPage}
      onReachEnd={scrollHandler.fetchNextPage}
      isFetchingNextPage={scrollHandler.isFetchingNextPage}
      nav={
        <ConditionalRenderer condition={shouldRenderList}>
          <CardNav
            activeGroup={activeGroup}
            allowedGroups={allowedGroups}
            onClickGroup={onClickGroup}
            orderByRecents={orderByRecents}
            setOrderByRecents={setOrderByRecents}
          />
        </ConditionalRenderer>
      }
      className={{ ...className, nav: twMerge(navClassname, className?.nav) }}
    >
      <ConditionalRenderer
        fallback={
          <div className="flex justify-center items-center h-full flex-col gap-y-2 text-accent font-500">
            <Text className="text-center" text={t('noAbsenceReports')} />
            <Text text="\ (•◡•) /" />
          </div>
        }
        condition={shouldRenderList}
      >
        <AbsenceNotificationGroups
          isLoading={isLoadingGroups}
          groups={items}
          ungroupedNotifications={absenceNotifications}
          absenceNotificationFilters={filters}
          groupBy={activeGroup}
        />
      </ConditionalRenderer>
    </CollapsableCard>
  );
}

type CardHeaderProps = {
  hasNotification?: boolean;
};

const CardHeader = ({ hasNotification = false }: CardHeaderProps) => {
  const queryClient = useQueryClient();
  const { t } = useTranslation('translation', {
    keyPrefix: 'teacherDashboard.absenceReports',
  });

  const refetchNotifications = async () => {
    const absenceNotificationsQueryKey =
      absenceNotificationsQueryKeys.list._def;

    const scheduledLessonReportQueryKey =
      scheduledLessonReportsQueryKeys.list._def;

    return await Promise.all([
      queryClient.invalidateQueries(absenceNotificationsQueryKey),
      queryClient.invalidateQueries(scheduledLessonReportQueryKey),
    ]);
  };

  const { mutate: readAll, isLoading: isUpdating } = useMutation(
    readAllNotification,
    {
      async onSuccess() {
        alert.success(t('allReadSuccessfuly'));
        return await refetchNotifications();
      },
      onError(error: any) {
        const apiError = new ApiError(error);
        alert.error(apiError.getErrorMessage());
      },
    },
  );

  return (
    <div className="flex items-center justify-between gap-1">
      <IconButton
        onClick={refetchNotifications}
        icon={<RefreshIcon className="w-6" />}
      />
      <ComponentGuard roles={[UserTypeEnum.UNIT_ADMIN]}>
        <ConditionalRenderer condition={hasNotification}>
          <Popper
            referenceContent={
              <IconButton
                testId="headerOptionsTrigger"
                icon={<DotsVerticalIcon className="h-6" />}
              />
            }
            popperClassName="transform -translate-x-52"
            popperContent={
              <div
                className="bg-base-100 p-3 rounded-md min-w-max cursor-pointer"
                onClick={() =>
                  readAll({ type: [NotificationTypeEnum.ABSENCE] })
                }
              >
                <MainButton
                  icon={<EyeIcon className="stroke-3" />}
                  loading={isUpdating}
                  text={t('markAllAsRead')}
                  className="text-accent items-center"
                  color="custom"
                />
              </div>
            }
            hover
          />
        </ConditionalRenderer>
      </ComponentGuard>
    </div>
  );
};

type CardNavProps = {
  allowedGroups: ToggleButton<AbsenceNotificationGroupBy>[];
  activeGroup: AbsenceNotificationGroupBy;
  onClickGroup: (group: AbsenceNotificationGroupBy) => void;
  orderByRecents: boolean;
  setOrderByRecents: (value: SetStateAction<boolean>) => void;
};

function CardNav({
  allowedGroups,
  activeGroup,
  onClickGroup,
  orderByRecents,
  setOrderByRecents,
}: CardNavProps) {
  const { t } = useTranslation('translation', {
    keyPrefix: 'teacherDashboard.absenceReports',
  });
  return (
    <Fragment>
      <ToggleButtonGroup
        size="small"
        exclusive
        buttons={allowedGroups}
        value={activeGroup}
        onChange={onClickGroup}
      />

      <div className="flex gap-1">
        <SortToggle
          id="absencesSort"
          text={{ ascending: t('older'), descending: t('newer') }}
          isDescending={orderByRecents}
          onToggleSort={checked => setOrderByRecents(checked)}
          hideLabel
        />
      </div>
    </Fragment>
  );
}
