import IconButton from '@/components/common/buttons/IconButton';
import useManualQuery from '@/data/hook/useManualQuery';
import useTreeArea from '@/data/hook/useTreeArea';
import { deleteArea } from '@/data/services/areaServices';
import { areasQueryKeys } from '@/data/services/querykeys';
import Area from '@/models/Area';
import useFilterParams from '@/utils/UseFilterParams';
import { TrashIcon } from '@heroicons/react/outline';
import { useMutation } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';
import { TopicsFiltersParams } from '../../TopicsPageContent';
import { Fragment } from 'react/jsx-runtime';
import ModalDisable from '@/components/common/modals/ModalDisable';
import { useState } from 'react';

export type DeleteAreaProps = {
  area: Area;
  backToParent?: boolean;
  disabled?: boolean;
};

export default function DeleteArea({
  area,
  disabled,
  backToParent,
}: DeleteAreaProps) {
  const { t } = useTranslation('translation', {
    keyPrefix: 'topics.tooltip',
  });

  const [isVisibleModal, setIsVisibleModal] = useState(false);

  const { pathParams, invalidateParent, tree } = useTreeArea();
  const { remove } = useManualQuery(areasQueryKeys.list()._ctx.infinity);
  const { setFilterParams } = useFilterParams<TopicsFiltersParams>();

  const { mutate, isLoading } = useMutation(deleteArea, {
    onSuccess: async () => {
      await invalidateParent({ parentId: area.parentAreaId });
      tree.current.postOrder(area.id).forEach(area => {
        remove(area.id);
      });
      backToParent &&
        setFilterParams({
          area: null,
          path: pathParams.slice(0, -1),
        });
    },
  });

  const onConfirmDelete = () => {
    setIsVisibleModal(false);
    mutate(area.id);
  };

  return (
    <Fragment>
      <IconButton
        icon={<TrashIcon className="w-5 h-5 text-error " />}
        loading={isLoading}
        loadingSize="h-5 w-5 text-error"
        onClick={() => setIsVisibleModal(true)}
        disabled={disabled}
        tooltip={t('delete')}
      />
      <ModalDisable
        visible={isVisibleModal}
        translationString={'modalDisableTopic'}
        modalType="delete"
        onClickConfirm={onConfirmDelete}
        onClickCancel={() => setIsVisibleModal(false)}
      />
    </Fragment>
  );
}
