import { Link } from 'react-router-dom';
import { twMerge } from 'tailwind-merge';
import { handleUserFullName } from '@/functions/handleUserFullName';
import User from '@/models/User';
import ConditionalWrapper from '../ConditionalWrapper';
import { Avatar } from './Avatar';
import Text from './Text';

export default function AvatarName({
  user,
  className,
  onClick,
  href,
  textClassName,
}: {
  user: User;
  className?: string;
  textClassName?: string;
  onClick?: () => void;
  href?: string;
}) {
  return (
    <div
      onClick={onClick}
      className={`flex items-center ${
        onClick ? 'cursor-pointer' : ''
      } ${twMerge('gap-4 text-base-content', className)}`}
    >
      <Avatar userId={user.id} url={href} />
      <ConditionalWrapper
        condition={!!href}
        wrapper={
          <Link
            data-testid="link"
            to={href as string}
            className="text-inherit"
          />
        }
      >
        <Text className={textClassName} text={handleUserFullName(user)} />
      </ConditionalWrapper>
    </div>
  );
}
