import { LessonFormType } from '@/components/forms/LessonForm';
import useListService from '@/data/hook/useListService';
import { tagsQueryKeys } from '@/data/services/querykeys';
import Area from '@/models/Area';
import Tag from '@/models/Tag';
import { mountTopicTagName, mountTagName } from '@/utils/area';
import { ArrowRightIcon } from '@heroicons/react/outline';
import { ChangeEvent } from 'react';
import { Control, useFieldArray, useWatch } from 'react-hook-form';
import useStack from '@/data/hook/useStack';
import Text from '@/components/common/dataDisplay/Text';
import Checkbox from '../../Checkbox';

type ListItemProps = {
  area: Area;
  stack: ReturnType<typeof useStack<Area>>;
  control?: Control<LessonFormType>;
};

export const ListItemFactory = ({ area, stack, control }: ListItemProps) => {
  if (area.isLeaf)
    return <LeafItem area={area} stack={stack} control={control} />;
  return <AreaItem area={area} stack={stack} />;
};

type AreaItemProps = {
  area: Area;
  stack: ReturnType<typeof useStack<Area>>;
};

const AreaItem = ({ area, stack }: AreaItemProps) => {
  return (
    <li
      key={area.id}
      onClick={() => stack.add(area)}
      className="flex justify-between cursor-pointer"
    >
      <Text text={area.name} />
      <ArrowRightIcon className="h-4 w-4 text-primary" />
    </li>
  );
};

type LeafItemProps = {
  area: Area;
  stack: ReturnType<typeof useStack<Area>>;
  control?: Control<LessonFormType>;
};

const LeafItem = ({ area, control, stack }: LeafItemProps) => {
  const { results: tags } = useListService(
    tagsQueryKeys.list({
      areaId: [area.id],
    }),
  );

  return (
    <ul className="flex flex-col gap-2.5">
      {tags.map(tag => (
        <TagItem
          key={tag.id}
          area={area}
          tag={tag}
          control={control}
          stack={stack}
        />
      ))}
    </ul>
  );
};

type TagItemProps = {
  tag: Tag;
  area: Area;
  control?: Control<LessonFormType>;
  stack: ReturnType<typeof useStack<Area>>;
};
const TagItem = ({ area, tag, control, stack }: TagItemProps) => {
  const { append, remove } = useFieldArray({
    control,
    name: 'tags',
  });
  const tagsFromForm = useWatch({ control, name: 'tags' });
  const contain = tagsFromForm?.some(field => field.id === tag.id);

  const onChange = (e: ChangeEvent<HTMLInputElement>) => {
    const checked = e.target.checked;
    if (checked) {
      append({
        ...tag,
        name: mountTopicTagName(tag, [...stack.value, area]),
      });
    }

    if (!checked) {
      remove(tagsFromForm?.findIndex(field => field.id === tag.id));
    }
  };

  return (
    <li key={tag.id} className="flex justify-between cursor-pointer">
      <label htmlFor={'area' + tag.id} className="cursor-pointer">
        {mountTagName(tag, area)}
      </label>
      <Checkbox
        id={'area' + tag.id}
        value={tag.id}
        checked={contain}
        onChange={onChange}
      />
    </li>
  );
};
