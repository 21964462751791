import { ActivityTypeSelect } from '../components/admin/activities/EditActivity';
import { ActivityTypeEnum } from '../models/Activity';
import {
  TextActivityIcon,
  TrueOrFalseIcon,
  VideoActivityIcon,
  QuizActivityIcon,
  HandDragging,
} from '../components/icons';

export const activityTypeOptions: ActivityTypeSelect[] = [
  {
    type: ActivityTypeEnum.TEXT,
    icon: TextActivityIcon,
  },
  {
    type: ActivityTypeEnum.TRUE_OR_FALSE,
    icon: TrueOrFalseIcon,
  },
  {
    type: ActivityTypeEnum.VIDEO,
    icon: VideoActivityIcon,
  },
  {
    type: ActivityTypeEnum.QUIZ,
    icon: QuizActivityIcon,
  },
  {
    type: ActivityTypeEnum.DND_ORDENATION,
    icon: HandDragging,
  },
  {
    type: ActivityTypeEnum.DND_POSITIONAL,
    icon: HandDragging,
  },
];
