import { twMerge } from 'tailwind-merge';
import { useTranslation } from 'react-i18next';

import Text from '../common/dataDisplay/Text';
import { calculateLevel } from '@/utils/xp/levelHandler';
import ConditionalRenderer from '../common/ConditionalRenderer';
import Rewards from '@/models/Rewards';

type StudentLevelProgressProps = {
  rewardsAccount?: Rewards;
  className?: string;
};

export default function StudentLevelProgress({
  rewardsAccount,
  className,
}: StudentLevelProgressProps) {
  const xp = rewardsAccount?.xp ?? 0;
  const levelCounter = calculateLevel(xp);

  const [t] = useTranslation('translation', {
    keyPrefix: 'profile',
  });

  return (
    <ConditionalRenderer condition={!!rewardsAccount}>
      <div
        className={twMerge(
          'flex flex-col gap-4 w-full items-center text-primary text-center',
          className,
        )}
      >
        <Text
          format="rubik-500"
          text={t('level.levelName', { level: levelCounter.level })}
        />
        <progress
          className="progress progress-primary bg-primary-content"
          value={levelCounter.percentage.toFixed(2)}
          max="1"
        />
        <Text
          format="rubik-500"
          text={t('level.levelProgress', {
            currentXp: xp,
            targetXp: levelCounter.totalLevel,
          })}
        />
      </div>
    </ConditionalRenderer>
  );
}
