import IconButton from '@/components/common/buttons/IconButton';
import ConditionalRenderer from '@/components/common/ConditionalRenderer';
import Text from '@/components/common/dataDisplay/Text';
import { LoadingIcon } from '@/components/icons';
import useInfiniteService from '@/data/hook/useInfiniteService';
import useTreeArea from '@/data/hook/useTreeArea';
import { areasQueryKeys } from '@/data/services/querykeys';
import Area from '@/models/Area';
import useFilterParams from '@/utils/UseFilterParams';
import { FolderIcon, FolderOpenIcon } from '@heroicons/react/outline';
import { MouseEventHandler, useEffect, useState } from 'react';
import { twMerge } from 'tailwind-merge';
import { TopicsFiltersParams } from '../../TopicsPageContent';
import TopicsTreeList from '../TopicsTreeList';

type TopicTreeAreaProps = {
  area: Area;
};
export default function TopicTreeArea({ area }: TopicTreeAreaProps) {
  const { pathParams } = useTreeArea();
  const [isOpen, setIsOpen] = useState(pathParams.includes(area.id));

  const { results: areas, ...infinityProps } = useInfiniteService({
    ...areasQueryKeys.list({
      parentAreaId: [area.id],
    })._ctx.infinity,
    enabled: isOpen,
  });

  useEffect(() => {
    setIsOpen(open => {
      if (!open && pathParams.includes(area.id)) return true;
      return open;
    });
  }, [pathParams, area.id]);

  return (
    <li>
      <AreaButton
        area={area}
        onClick={() => setIsOpen(prev => !prev)}
        isOpen={isOpen}
        isLoading={infinityProps.isInitialLoading}
      />
      <ConditionalRenderer condition={isOpen}>
        <TopicsTreeList items={areas} {...infinityProps} />
      </ConditionalRenderer>
    </li>
  );
}

type GetIconArgs = {
  isLoading?: boolean;
  isOpen?: boolean;
};

function getIcon({ isLoading, isOpen }: GetIconArgs) {
  if (isLoading) return LoadingIcon;
  return isOpen ? FolderOpenIcon : FolderIcon;
}

type GetCustomStyleArgs = {
  area: Area;
  activePath: number[];
};
const getCustomStyle = ({ area, activePath }: GetCustomStyleArgs) => {
  if (activePath.at(-1) === area.id) return 'font-500 text-primary';
  return activePath.includes(area.id) ? 'font-500' : 'font-400';
};

type AreaButtonProps = {
  area: Area;
  isOpen?: boolean;
  isLoading?: boolean;
  onClick?: MouseEventHandler<HTMLButtonElement>;
} & React.ComponentProps<'div'>;
function AreaButton({
  area,
  isOpen,
  onClick,
  isLoading,
  ...props
}: AreaButtonProps) {
  const { getPath, pathParams } = useTreeArea();
  const { setFilterParams } = useFilterParams<TopicsFiltersParams>();

  const onClickSetPath: MouseEventHandler<HTMLButtonElement> = () => {
    const path = getPath(area.id);
    setFilterParams({
      path,
    });
  };
  const Icon = getIcon({ isOpen, isLoading });
  const style = getCustomStyle({ area, activePath: pathParams });

  return (
    <div {...props} className="flex gap-2.5 items-center">
      <IconButton
        icon={<Icon className="w-5 h-5 text-primary" />}
        onClick={onClick}
      />
      <span
        className="flex-grow cursor-pointer truncate"
        onClick={onClickSetPath}
      >
        <Text text={area.name} className={twMerge('truncate', style)} />
      </span>
    </div>
  );
}
