import ConditionalRenderer from '../../ConditionalRenderer';
import Text from '../Text';
import { useTabsPanelContext } from './Tabs';

type PanelHeaderProps = {
  title?: string;
  subtitle?: string;
};
const TabsHeader = ({ title, subtitle }: PanelHeaderProps) => {
  const { triggerListSize, orientation } = useTabsPanelContext();
  const isVertical = orientation === 'vertical';
  return (
    <ConditionalRenderer condition={title}>
      <header
        className="relative px-5 py-2.5"
        style={{
          left: isVertical ? triggerListSize?.width : 0,
        }}
      >
        <Text
          text={title}
          format="rubik-500"
          size="text-16"
          className="text-secondary"
        />
        <ConditionalRenderer condition={subtitle}>
          <Text size="text-14 my-2.5" text={subtitle} />
        </ConditionalRenderer>
      </header>
    </ConditionalRenderer>
  );
};

export default TabsHeader;
