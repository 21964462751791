import { ScheduledLessonFilters } from '../services/scheduledLessonsServices';
import { REQUEST_STALE_TIME_IN_MS } from '@/constants';
import { getErrorMessage } from '@/utils/getErrorMessage';
import useInfiniteService from './useInfiniteService';
import { scheduledLessonsQueryKeys } from '../services/querykeys';

type UseScheduledLessonsProps = ScheduledLessonFilters;

export default function useScheduledLessons({
  klassId,
}: UseScheduledLessonsProps) {
  const { queryKey, queryFn } = scheduledLessonsQueryKeys.list({ klassId })._ctx
    .infinity;

  const {
    results,
    isInitialLoading,
    isRefetching,
    isFetchingNextPage,
    isFetching,
    hasNextPage,
    fetchNextPage,
    error,
    invalidate,
  } = useInfiniteService({
    queryKey,
    queryFn,
    keepPreviousData: true,
    enabled: !!klassId && !isNaN(klassId),
    staleTime: REQUEST_STALE_TIME_IN_MS,
  });

  const scheduledLessonsError = getErrorMessage(error);

  const hookReturn = {
    results,
    loading: isInitialLoading,
    refetching: isRefetching,
    isFetchingNextPage,
    hasNextPage,
    fetchNextPage,
    queryKey,
    error: scheduledLessonsError,
    fetching: isFetching,
    invalidate,
  };

  return hookReturn;
}
