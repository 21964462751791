import { useTranslation } from 'react-i18next';
import { AcademicCapIcon } from '@heroicons/react/outline';

import RoundedButton from '@/components/common/buttons/RoundedButton';
import ViewAvatar from '@/components/store/ViewAvatar';
import Text from '@/components/common/dataDisplay/Text';
import { IconWithText } from '@/components/common/dataDisplay/IconWithText';

type CourseViewProps = {
  userId: number;
  courseName: string;
};

export function StudentCourseDisplay({ userId, courseName }: CourseViewProps) {
  const { t } = useTranslation('translation', {
    keyPrefix: 'common',
  });

  return (
    <div className="flex flex-row min-w-fit justify-between gap-4">
      <ViewAvatar
        userId={userId}
        className="w-[47.5%] h-auto aspect-[4/2.5] sm:w-[40%] md:w-44 lg:w-52"
      />

      <div
        className="flex flex-col justify-between items-center gap-2 sm:gap-3.5 w-[47.5%] md:w-40
    lg:w-52 shrink-0 uppercase"
      >
        <IconWithText
          icon={
            <AcademicCapIcon
              className="shrink-0 w-5 md:w-6 h-auto text-base-100 bg-accent rounded-full p-0.5"
              strokeWidth={1.3}
            />
          }
          text={t('course')}
          className={{ text: 'sm:text-18 md:text-20', base: 'text-accent' }}
        />

        <Text
          testId="courseName"
          text={courseName}
          format="rubik-700"
          size="text-16 sm:text-24"
          className="text-center flex flex-row items-center leading-none tracking-normal italic break-word text-accent"
        />

        <RoundedButton
          testId="accessClassroomButton"
          text={t('lessons')}
          className="w-full"
          href="/courses"
        />
      </div>
    </div>
  );
}
