import moment from 'moment';
import { useTranslation } from 'react-i18next';
import Modal from '@/components/common/modals/Modal';
import RoundedButton from '@/components/common/buttons/RoundedButton';
import PillComponent from '@/components/common/dataDisplay/PillComponent';
import LinkText from '@/components/common/LinkText';
import { ModalState } from './ModalHandler';
import alert from '@/utils/UseAlert';
import ConditionalRenderer from '@/components/common/ConditionalRenderer';
import Skeleton from '@/components/common/Skeleton';
import { useMutation, useQuery } from '@tanstack/react-query';
import { ApiError } from '@/models/Errors';
import { updateRescheduledLesson } from '@/data/services/scheduledLessonsServices';
import { RescheduleTypeEnum } from '@/models/ScheduledLesson';
import Text from '@/components/common/dataDisplay/Text';
import { getErrorMessage } from '@/utils/getErrorMessage';
import { reschedulePreviewQueryKeys } from '@/data/services/querykeys';

interface AnticipatePostponeKlassProps {
  lesson: string;
  duration?: number;
  scheduledId: number;
  onClickCancel: () => void;
  updateScheduledLessons?: (a: any) => Promise<void>;
  state: ModalState;
  onBack?(): void;
}

export default function AnticipatePostponeKlass({
  scheduledId,
  state,
  updateScheduledLessons,
  onClickCancel,
  lesson,
  duration,
  onBack,
}: AnticipatePostponeKlassProps) {
  const { t } = useTranslation('translation', {
    keyPrefix: 'calendar.admin.manageKlass.anticipatePostpone',
  });
  const rescheduleType =
    state === ModalState.ANTICIPATE
      ? RescheduleTypeEnum.ANTICIPATE
      : RescheduleTypeEnum.POSTPONE;

  const {
    data: preview,
    isLoading: isLoadingPreview,
    error,
    isError,
  } = useQuery({
    retry: false,
    ...reschedulePreviewQueryKeys.get(scheduledId, { rescheduleType }),
  });

  const errorMessage = getErrorMessage(error);

  async function onFinishChanges() {
    await updateRescheduledLesson(
      scheduledId,
      state === ModalState.ANTICIPATE
        ? RescheduleTypeEnum.ANTICIPATE
        : RescheduleTypeEnum.POSTPONE,
    );
  }

  const { mutate: onFinish, isLoading: isUpdating } = useMutation(
    onFinishChanges,
    {
      async onSuccess() {
        await updateScheduledLessons?.('');
        alert.success(t('success'));
      },
      onError(error: any) {
        const api = new ApiError(error);
        alert.error(api.getErrorMessage());
      },
      onSettled() {
        onClickCancel();
      },
    },
  );

  return (
    <Modal onBack={onBack} visible={true} onClose={onClickCancel}>
      <div className="w-full flex flex-col gap-y-6">
        <Text
          format="rubik-500"
          text={t(`${state.toLowerCase()}.title`)}
          className="text-primary"
        />
        <Text text={t('warningResults')} className="text-base-content" />
        <div className="border border-primary rounded-xl flex flex-col gap-3.5 px-3.5 py-3.5">
          <LinkText text={lesson} />
          <div className="flex justify-center items-center gap-x-3.5">
            <ConditionalRenderer
              condition={!isLoadingPreview}
              fallback={
                <div className="flex gap-4">
                  <Skeleton className="flex w-20 h-5 rounded-full bg-primary-content" />
                  <Skeleton className="flex w-32 h-5 rounded-md bg-primary-content" />
                </div>
              }
            >
              <ConditionalRenderer
                condition={!errorMessage}
                fallback={<Text className="text-error" text={errorMessage} />}
              >
                <PillComponent>
                  {preview && moment(preview?.datetime, false).format('DD/MM')}
                </PillComponent>
                <Text
                  text={`${moment(preview?.datetime).format('HH:mm')}-${moment(
                    preview?.datetime,
                  )
                    .add(duration ?? 90, 'minutes')
                    .format('HH:mm')}`}
                  className="text-base-content"
                />
              </ConditionalRenderer>
            </ConditionalRenderer>
          </div>
        </div>
        <Text
          text={t(`${state.toLowerCase()}.warningMessage`)}
          className="text-base-content"
        />

        <div className="flex w-full justify-around gap-6">
          <RoundedButton
            text={t('cancel')}
            color="neutral"
            className="w-full"
            onClick={onClickCancel}
          />
          <RoundedButton
            text={t('confirm')}
            className="w-full"
            disabled={isError}
            onClick={() => onFinish()}
            loading={isUpdating}
          />
        </div>
      </div>
    </Modal>
  );
}
