import { SaveIcon } from '@heroicons/react/outline';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import requester from '@/axios';
import useCourseEditing from '@/data/hook/useCourseEditing';
import Activity from '@/models/Activity';
import { TrueOrFalseActivityElement } from '@/models/ActivityElement';
import { ImageFolderEnum } from '@/models/CkEditor';
import MainButton from '@/components/common/buttons/MainButton';
import SelectInput from '@/components/common/dataInput/SelectInput';
import TextInput from '@/components/common/dataInput/TextInput';
import TooltipHandler from '@/components/common/TooltipHandler';
import MyCkeditor from '@/components/editor/MyCkeditor';
import alert from '@/utils/UseAlert';
import ContainerActivityEdit from './commom/ContainerActivityEdit';

export interface TrueOrFalseElementEditProps
  extends Omit<TrueOrFalseActivityElement, 'activity'> {
  activity: Activity;
  onEdit: (props: any) => void;
  onSave: (props: any) => void;
}

export default function TrueOrFalseElementEdit(
  props: TrueOrFalseElementEditProps,
) {
  const {
    activity,
    identifier,
    isTrue,
    onEdit,
    onSave,
    question,
    reason,
    type,
    id,
    changeStatus,
    order,
    content,
  } = props;
  const { allowBigChanges } = useCourseEditing();

  const { t } = useTranslation('translation', {
    keyPrefix: 'activity.manageActivity.activityTrueFalse',
  });
  const { t: editRulesT } = useTranslation('translation', {
    keyPrefix: 'adminPage.editCourseRules',
  });

  const [errors, setErrors] = useState<any[]>([]);
  const [isButtonActivated, setIsButtonActivated] = useState<boolean>(false);
  const [isRequesting, setIsRequesting] = useState<boolean>(false);

  const handleQuestionChange = (event: React.FormEvent<HTMLInputElement>) => {
    onEdit({ ...props, question: event.currentTarget.value });
  };

  const handleContentChange = (editorValue: string | undefined) => {
    onEdit({ ...props, content: editorValue || '' });
    setIsButtonActivated(true);
  };

  const handleAnswerChange = (value: string) => {
    if (value === 'true') {
      onEdit({ ...props, isTrue: true });
    } else if (value === 'false') {
      onEdit({ ...props, isTrue: false });
    }
  };

  const handleJustificativeChange = (editorValue: string | undefined) => {
    onEdit({ ...props, reason: editorValue || '' });
    setIsButtonActivated(true);
  };

  useEffect(() => {
    if (changeStatus === 'new') {
      const keysTrueOrFalse = ['question', 'isTrue', 'reason'];
      const hasKey = keysTrueOrFalse.find(key => Object.hasOwn(props, key));

      if (hasKey) return setIsButtonActivated(true);

      return setIsButtonActivated(false);
    }

    if (changeStatus === 'changed') return setIsButtonActivated(true);
    setIsButtonActivated(false);
  }, [props, changeStatus]);

  function checkTrueFalse(): string[] | null {
    const errors: string[] = [];

    if (question === '' || question === undefined) {
      errors.push('question');
    }

    if (reason === '' || reason === undefined) {
      errors.push('justificative');
    }

    if (isTrue === undefined) {
      errors.push('isTrue');
    }

    if (errors.length > 0) {
      return errors;
    } else {
      return null;
    }
  }

  const handleClickSave = async () => {
    const ErrorID = checkTrueFalse();
    if (ErrorID !== null) {
      setErrors(ErrorID);
    } else {
      setErrors([]);

      if (changeStatus === 'new') {
        try {
          setIsRequesting(true);
          const { data } = await requester().post(`true-or-false-activity/`, {
            activity: activity.id,
            content,
            question,
            isTrue,
            reason,
            type,
            identifier,
            order,
          });
          onSave({ ...props, id: data.id });

          alert.success(t('saveSuccess'));
        } catch (err) {
          alert.error(t('saveError'));
        } finally {
          setIsRequesting(false);
        }
      } else if (changeStatus === 'changed') {
        try {
          setIsRequesting(true);
          await requester().patch(`true-or-false-activity/${id}/`, {
            question,
            isTrue,
            reason,
            content,
            type,
            identifier,
          });
          onSave({ ...props });

          alert.success(t('saveSuccess'));
        } catch (err) {
          alert.error(t('saveError'));
        } finally {
          setIsRequesting(false);
        }
      }
    }
  };

  return (
    <ContainerActivityEdit id="TrueOrFalseElementEdit">
      <div>
        <label className="font-rubik text-base-content">{t('title')}</label>
        <br />
        {errors && errors.some(errorID => errorID === 'question') ? (
          <label className="font-rubik text-error error-label">
            {t('titleError')}
          </label>
        ) : (
          ''
        )}

        <TextInput
          key={identifier + 'question'}
          className={{ base: 'rounded-lg border-primary font-600  font-rubik' }}
          testId="trueOrFalseTitle"
          fontWeight="font-400"
          value={question ?? ''}
          onChange={handleQuestionChange}
          name="question"
        />
      </div>
      <div>
        <label className="font-rubik text-base-content">{t('content')}</label>

        <MyCkeditor
          key={identifier + 'content'}
          content={content || ''}
          folder={ImageFolderEnum.ACTIVITY}
          handleContentChange={handleContentChange}
          testId="ckEditorContent"
        />
      </div>
      <div className="astro-select flex flex-col">
        <label className="font-rubik text-base-content">
          {t('trueOrFalse')}
        </label>
        {errors && errors.some(errorID => errorID === 'isTrue') ? (
          <label className="font-rubik text-error error-label-select">
            {t('trueOrFalseError')}
          </label>
        ) : (
          ''
        )}
        <div className="w-fit">
          <TooltipHandler
            renderTooltip={!allowBigChanges}
            tooltipMessage={editRulesT('cantModify')}
          >
            <SelectInput
              value={`${isTrue}`}
              disabled={!allowBigChanges}
              testId="trueOrFalseAnswer"
              onChange={event => {
                handleAnswerChange(event.target.value);
              }}
              className={{ base: 'w-40 astro-select' }}
            >
              <option value="true">{t('true')}</option>
              <option value="false">{t('false')}</option>
            </SelectInput>
          </TooltipHandler>
        </div>
      </div>
      <div>
        <label className="font-rubik text-base-content">
          {t('justificative')}
        </label>
        <br />
        {errors && errors.some(errorID => errorID === 'justificative') ? (
          <label className="font-rubik text-error error-label">
            {t('justificativeError')}
          </label>
        ) : (
          ''
        )}

        <MyCkeditor
          key={identifier + 'reason'}
          testId="ckEditorReason"
          content={reason}
          folder={ImageFolderEnum.ACTIVITY}
          handleContentChange={handleJustificativeChange}
        />
      </div>

      <div className="flex">
        <MainButton
          dataTestId="trueOrFalseActivitySave"
          loading={isRequesting}
          disabled={!isButtonActivated}
          icon={<SaveIcon />}
          text={t('save')}
          onClick={handleClickSave}
        />
      </div>
    </ContainerActivityEdit>
  );
}
