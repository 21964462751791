import ConditionalWrapper from '@/components/common/ConditionalWrapper';
import AvatarName from '@/components/common/dataDisplay/AvatarName';
import useAuth from '@/data/hook/useAuth';
import { updateActivityInBank } from '@/data/services/activityServices';
import { isSuperAdmin } from '@/functions/auth';
import Activity, { ActivityMeta } from '@/models/Activity';
import { Lesson } from '@/models/Lesson';
import { activityTypeOptions } from '@/utils/activityTypeOptions';
import { modalColors } from '@/utils/modalBankUtils';
import {
  ClipboardIcon,
  EyeIcon,
  EyeOffIcon,
  SaveIcon,
} from '@heroicons/react/outline';
import { useMutation, useQuery } from '@tanstack/react-query';
import { compact } from 'lodash';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import Card from '../BankCards';
import ExpansibleActivityCard from '../Expansible/ExpansibleActivityCard';
import { CreateCardProps } from './CreateCardProps';
import {
  lessonsQueryKeys,
  scheduledLessonsQueryKeys,
  usersQueryKeys,
} from '@/data/services/querykeys';
import { useItems } from '../ListBankCards.hooks';
import { useBankFilters } from '@/data/context/BankFiltersContext';
import useInfiniteService from '@/data/hook/useInfiniteService';

export function CreateActivityCard({
  item: activity,
  checkable,
  disabledAnimation,
  onUse,
  loading,
}: CreateCardProps<Activity, ActivityMeta>) {
  const { t } = useTranslation('translation', {
    keyPrefix: 'modalBank',
  });
  const { t: tActivityTypes } = useTranslation('translation', {
    keyPrefix: 'activity.manageActivity.activityTypes',
  });
  const { user } = useAuth();

  const isSuper = isSuperAdmin(user?.userType);
  const { klassId, slugCourseName = '' } = useParams();

  const { filters } = useBankFilters();

  const { invalidate: activityQueryInvalidate } = useItems(
    'activities',
    filters,
    {
      enabled: false,
    },
  );

  const { results: scheduledLessons } = useInfiniteService({
    ...scheduledLessonsQueryKeys.list({
      klassId: Number(klassId),
      ordering: 'datetime',
    })._ctx.infinity,
    enabled: !isNaN(Number(klassId)),
  });

  const { results: lessons } = useInfiniteService({
    ...lessonsQueryKeys.list({ slug: slugCourseName })._ctx.infinity,
    enabled: !!slugCourseName && !klassId,
  });

  const lessonsUsage: Lesson[] = lessons.length
    ? lessons
    : scheduledLessons.map(s => s.lesson);

  const activityInUse = lessonsUsage.some(lesson =>
    lesson.activities.some(
      scheduledActivityId => scheduledActivityId === activity.id,
    ),
  );

  const [inBank, setInBank] = useState(activity.inBank);

  const { data: author } = useQuery({
    ...usersQueryKeys.get(activity.author),
    refetchOnMount: false,
    enabled: isSuperAdmin(user?.userType),
  });

  const updateInBank = async (inBank: boolean) => {
    const bankUpdate = await updateActivityInBank({
      activityId: activity.id,
      bank: { inBank },
    });
    await activityQueryInvalidate();
    return bankUpdate;
  };

  const { mutate: onCheck, isLoading } = useMutation(updateInBank, {
    onSuccess: data => {
      setInBank(data.inBank);
    },
  });

  let tags: string[] = [activity.originCourse];
  tags = activityInUse ? tags.concat(t('inUseActivity')) : tags;

  tags = tags.concat(activity.tools.map(tool => tool.name));

  const mountDescIcons = (activity: Activity) => {
    const activityTypes = activity.questions.map(question => {
      const activityIcon = activityTypeOptions.find(
        item => item.type === question.type,
      );
      return activityIcon;
    });

    const activityTypesNotNullable = compact(activityTypes);

    const iconsElement = activityTypesNotNullable.map(
      ({ type, icon: Icon }, index) => ({
        icon: Icon && (
          <Card.IconContainer key={'activityIcon' + index}>
            <Icon className="w-5 text-primary" />
          </Card.IconContainer>
        ),
        tooltip: tActivityTypes(type),
      }),
    );
    return iconsElement;
  };

  let metaCards = [
    <Card.Meta
      key={'activityUse' + activity.id}
      icon={SaveIcon}
      label={activity.lessonsCount}
    />,
  ];

  const publicCard = (
    <Card.Meta
      key={'public' + activity.id}
      icon={activity.isPublicInBank ? EyeIcon : EyeOffIcon}
      className="ml-2"
      tooltip={activity.isPublicInBank ? t('public') : t('private')}
    />
  );

  metaCards = isSuper ? [...metaCards, publicCard] : metaCards;

  return (
    <Card.Animation disabled={disabledAnimation}>
      <ConditionalWrapper
        condition={!!checkable}
        wrapper={
          <Card.Checkable
            checked={inBank}
            onCheck={onCheck}
            isLoading={isLoading}
          />
        }
      >
        <Card
          color={modalColors.activities}
          expansibleContent={
            <ExpansibleActivityCard
              item={activity}
              onUse={onUse}
              used={activityInUse}
              loading={loading}
            />
          }
        >
          <Card.LeftContent>
            <Card.Header
              icon={ClipboardIcon}
              title={activity.name}
              color={modalColors.activities.color}
              meta={metaCards}
            />
            <Card.DescContainer>
              <Card.Tags tags={tags} color={modalColors.activities} />
              {author && <AvatarName user={author} className="gap-2" />}
            </Card.DescContainer>
          </Card.LeftContent>
          <Card.IconsList icons={mountDescIcons(activity)} />
        </Card>
      </ConditionalWrapper>
    </Card.Animation>
  );
}
