import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Text from './dataDisplay/Text';

interface ButtonProps {
  url?: string;
  color?: string;
  hoverColor?: string;
  titleClassName?: string;
}

export default function BackButton(props: ButtonProps) {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const { url, color, hoverColor, titleClassName } = props;
  return (
    <button
      data-testid="backButton"
      id="back-button"
      className={`text-center h-10 w-auto  
      items-center justify-end flex ${color ?? 'text-primary'} hover:${
        hoverColor ?? 'text-primary'
      }`}
      onClick={() => {
        url ? navigate(url, { replace: true }) : navigate(-1);
      }}
    >
      <Text
        format="rubik-500"
        text={t('manageStudent.back')}
        className={titleClassName}
      />
    </button>
  );
}
