import IconButton from '@/components/common/buttons/IconButton';
import MainButton from '@/components/common/buttons/MainButton';
import ConditionalRenderer from '@/components/common/ConditionalRenderer';
import ConditionalWrapper from '@/components/common/ConditionalWrapper';
import Text from '@/components/common/dataDisplay/Text';
import TextWithCopy from '@/components/common/dataDisplay/TextWithCopy';
import { Tooltip } from '@/components/common/dataDisplay/Tooltip';
import InfinityList from '@/components/common/InfinityList';
import Popup from '@/components/common/Popup';
import { LoadingIcon, WhatsAppIcon } from '@/components/icons';
import useInfiniteService from '@/data/hook/useInfiniteService';
import { usersQueryKeys } from '@/data/services/querykeys';
import { handleUserFullName } from '@/functions/handleUserFullName';
import { phoneMask } from '@/functions/phoneHandler';
import { Alert } from '@/models/Alert';
import { Contact } from '@/models/User';
import { PhoneIcon } from '@heroicons/react/outline';
import { useTranslation } from 'react-i18next';
import { twMerge } from 'tailwind-merge';
import { HandleSendMessageArgs } from './factory/DelayNotificationCard';
import { useAlertMessage } from './factory/NotificationCard.hooks';

type ContactsProps = {
  contacts: Contact[];
  notification: Alert;
  onSendMessage: (args: HandleSendMessageArgs) => void;
};
export default function Contacts({
  contacts,
  notification,
  onSendMessage,
}: ContactsProps) {
  return (
    <ul className="flex flex-col gap-3.5 p-2.5 rounded-md bg-base-100 shadow-default ">
      {contacts.map(contact => (
        <ContactItem
          key={contact.id}
          notification={notification}
          contact={contact}
          onSendMessage={onSendMessage}
        />
      ))}
    </ul>
  );
}

type ContactProps = {
  contact: Contact;
  notification: Alert;
  onSendMessage: (args: HandleSendMessageArgs) => void;
};
const ContactItem = ({
  contact,
  notification,
  onSendMessage,
}: ContactProps) => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'contacts',
  });
  const { t: tRelationships } = useTranslation('translation', {
    keyPrefix: 'relationships',
  });
  const { message, isInitialLoading } = useAlertMessage(notification);

  if (isInitialLoading) {
    return <LoadingIcon className="h-5 w-5" />;
  }

  return (
    <div className="flex gap-1.5">
      <div className="text-left">
        <div className="flex gap-1 items-center">
          <Text
            text={handleUserFullName(contact)}
            size="text-16"
            className="font-500"
          />
          <Text
            text={`(${
              contact.relation ? tRelationships(contact.relation) : t('student')
            })`}
            size="text-12"
            className="font-500 text-base-300"
          />
        </div>
        <div className="flex gap-0.5">
          <TextWithCopy label={phoneMask(contact.phoneNumber)} size="text-12" />
          <Tooltip text={t('sendMessage')}>
            <IconButton
              icon={<WhatsAppIcon className="h-5 w-5 text-success" />}
              onClick={() => {
                onSendMessage({
                  phoneNumber: contact.phoneNumber,
                  text: message,
                });
              }}
            />
          </Tooltip>
        </div>
      </div>
    </div>
  );
};

type ContactsButtonProps = {
  onSendMessage: (args: HandleSendMessageArgs) => void;
  notification: Alert;
};

export const ContactsButton = ({
  notification,
  onSendMessage,
}: ContactsButtonProps) => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'alerts.buttons',
  });
  const { t: tContacts } = useTranslation('translation', {
    keyPrefix: 'contacts',
  });
  const { results: contacts, isInitialLoading } = useInfiniteService(
    usersQueryKeys.listContacts(notification.actor.id),
  );

  return (
    <ConditionalWrapper
      condition={!!contacts.length}
      fallback={<Tooltip text={tContacts('noContacts')} />}
      wrapper={
        <Popup
          hover
          reference={
            <MainButton
              className={twMerge(
                'rounded-full bg-success !text-base-100 px-2 py-1 gap-1.5 items-center disabled:pointer-events-none',
              )}
              color="custom"
              icon={<PhoneIcon className="h-4 w-4 text-base-100" />}
              size="regular"
              loading={isInitialLoading}
              text={t('contact')}
              disabled={!contacts.length}
            />
          }
        />
      }
    >
      <ConditionalRenderer condition={!contacts.length}>
        <MainButton
          className={twMerge(
            'rounded-full bg-success !text-base-100 px-2 py-1 gap-1.5 items-center disabled:pointer-events-none',
          )}
          color="custom"
          icon={<PhoneIcon className="h-4 w-4 text-base-100" />}
          size="regular"
          text={t('contact')}
          loading={isInitialLoading}
          disabled={!contacts.length}
        />
      </ConditionalRenderer>
      <ConditionalRenderer condition={contacts.length}>
        <InfinityList className="max-h-[30rem] gap-4">
          <Contacts
            contacts={contacts}
            notification={notification}
            onSendMessage={onSendMessage}
          />
        </InfinityList>
      </ConditionalRenderer>
    </ConditionalWrapper>
  );
};
