import { useTranslation } from 'react-i18next';
import { DownloadIcon, PencilIcon } from '@heroicons/react/outline';

import MainButton from '@/components/common/buttons/MainButton';
import ConditionalRenderer from '@/components/common/ConditionalRenderer';

type EditButtonProps = {
  render?: boolean;
  editing?: boolean;
  onEditing(editing: boolean): void;
  disabled?: boolean;
};

export function EditButton({
  editing,
  render,
  disabled,
  onEditing,
}: EditButtonProps) {
  const { t } = useTranslation('translation', {
    keyPrefix: 'calendar.admin.manageKlass',
  });

  const Icon = editing ? DownloadIcon : PencilIcon;

  return (
    <ConditionalRenderer condition={render}>
      <MainButton
        color="custom"
        className="font-500 text-[0.875rem] lg:text-[1rem] text-primary justify-end gap-0.5 py-0"
        icon={<Icon className="h-4 w-4" />}
        text={editing ? t('finish') : t('edit')}
        disabled={disabled}
        onClick={() => onEditing(!editing)}
      />
    </ConditionalRenderer>
  );
}
