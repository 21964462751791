import { isArray } from 'lodash';
import { ChangeEvent, useMemo, useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { CourseCategoryEnum, CourseTypeEnum } from '@/models/Course';
import { DaysOfWeekTypeEnum } from '@/models/Klass';
import { ListKlassesFilters } from '@/data/services/klassServices';
import { TeacherSummaryCount } from '../TeacherSummaryCount';
import {
  ClassScheduleFiltersProps,
  ClassScheduleFiltersType,
} from '@/models/ClassScheduleFilters';
import PopupFilter, {
  PopupFilterItem,
} from '@/components/common/popupFilter/PopupFilter';

type TeachingWeekFiltersProps = Omit<
  ClassScheduleFiltersProps<ListKlassesFilters>,
  'type'
> & {
  updateFilter?: (filter: ListKlassesFilters) => void;
};

const createFilterItem = (
  id: string | number,
  itemValue: any,
  label: string,
  checked: boolean,
  type: string,
): PopupFilterItem => ({
  id,
  value: itemValue,
  checked,
  label,
  type,
});

export default function TeachingWeekFilters({
  updateFilters,
  updateFilter,
  filters = {},
}: TeachingWeekFiltersProps) {
  const { t: tFilters } = useTranslation('translation', {
    keyPrefix: 'filters',
  });

  const teachingweekfilters: ClassScheduleFiltersType[] = [
    'courseCategory',
    'courseType',
    'dayOfWeek',
  ];

  const checkedOptions: PopupFilterItem[] = useMemo(
    () =>
      Object.entries(filters).flatMap(([key, value]) => {
        if (isArray(value)) {
          return value.map(item =>
            createFilterItem(
              item,
              item,
              typeof item === 'string' ? tFilters(item) : '',
              true,
              key,
            ),
          );
        }
        const label = tFilters(key);
        const itemValue = typeof value === 'boolean' ? String(value) : value;
        return [createFilterItem(key, itemValue, label, !!value, key)];
      }),
    [filters, tFilters],
  );

  const updateOtherFilters = useCallback(
    (id: string, checked: boolean) => {
      updateFilter?.({ [id]: checked });
    },
    [updateFilter],
  );

  const onChange = useCallback(
    (
      event: Pick<ChangeEvent<HTMLInputElement>, 'target'>,
      type: ClassScheduleFiltersType,
    ) => {
      const { value, checked, id } = event.target;

      if (type === 'otherFilters') updateOtherFilters(id, checked);
      else
        updateFilters({
          type,
          event: { value, checked },
        });
    },
    [updateOtherFilters, updateFilters],
  );

  return (
    <section className="flex flex-wrap gap-5 order-last items-center xl:self-end">
      <ul className="flex flex-wrap lg:flex-nowrap h-fit gap-5 text-base-content text-14">
        {teachingweekfilters.map(filter => (
          <TeachingWeekFilter
            key={filter}
            type={filter}
            checkedOptions={checkedOptions}
            onChange={onChange}
          />
        ))}
        <TeachingWeekFilter
          type="otherFilters"
          checkedOptions={checkedOptions}
          onChange={onChange}
        />
      </ul>
      <TeacherSummaryCount
        teacherId={filters?.teacherId}
        unitId={filters?.unitId}
      />
    </section>
  );
}

function buildEnumOptions<T extends Record<string, string | number>>(
  enumType: T,
  checkedOptions: PopupFilterItem[],
  tFilters: (key: string) => string,
): PopupFilterItem[] {
  return Object.keys(enumType).map(value => ({
    id: value,
    value,
    label: tFilters(value),
    checked: checkedOptions.some(checked => checked.value === value),
    type: String(enumType),
  }));
}

type TeachingWeekFilterProps = {
  type: ClassScheduleFiltersType;
  checkedOptions: PopupFilterItem[];
  onChange: (
    event: Pick<ChangeEvent<HTMLInputElement>, 'target'>,
    type: ClassScheduleFiltersType,
  ) => void;
};

function TeachingWeekFilter({
  type,
  checkedOptions,
  onChange,
}: TeachingWeekFilterProps) {
  const { t } = useTranslation('translation', {
    keyPrefix: 'klassesFilters',
  });

  const { t: tFilters } = useTranslation('translation', {
    keyPrefix: 'filters',
  });

  const options = useMemo(() => {
    switch (type) {
      case 'courseCategory':
        return buildEnumOptions(CourseCategoryEnum, checkedOptions, tFilters);
      case 'dayOfWeek':
        return buildEnumOptions(
          DaysOfWeekTypeEnum,
          checkedOptions,
          tFilters,
        ).filter(day => day.value !== DaysOfWeekTypeEnum.SUNDAY);
      case 'courseType':
        return buildEnumOptions(CourseTypeEnum, checkedOptions, tFilters);
      case 'otherFilters': {
        const otherFiltersOptions = [
          'startSoon',
          'endSoon',
          'hasActiveLessons',
        ].map(value => ({
          id: value,
          value,
          label: tFilters(value),
          type: 'otherFilters',
          checked: checkedOptions.some(
            option => option.id === value && option.checked,
          ),
        }));
        return otherFiltersOptions;
      }
      default:
        return [];
    }
  }, [checkedOptions, tFilters, type]);

  return (
    <li>
      <PopupFilter
        type={type}
        options={options}
        onChange={onChange}
        label={t(String(type))}
      />
    </li>
  );
}
