import { useState } from 'react';
import { motion } from 'framer-motion';
import { useTranslation } from 'react-i18next';

import ActivityViewContainer from '@/components/activities/elements/preview/ActivityViewContainer';
import ElementPreviewFactory from '@/components/activities/elements/preview/ElementPreviewFactory';
import Controls from '@/components/common/Controls';
import Activity from '@/models/Activity';
import Steps, { StepStatus, Step } from '../common/Steps/Steps';
import { useQuery } from '@tanstack/react-query';
import { correctAnswersQueryKeys } from '@/data/services/querykeys';

type ActivityPreviewProps = {
  activity: Activity;
};

const ActivityPreview = ({ activity }: ActivityPreviewProps) => {
  const { t } = useTranslation('translation', { keyPrefix: 'lesson.activity' });
  const [activeStep, setActiveStep] = useState(0);

  const { data: answers } = useQuery({
    ...correctAnswersQueryKeys.get(activity.id),
    enabled: !!activity,
  });

  const questionsWithAnswers = activity.questions.map(question => {
    return {
      ...question,
      answer: answers?.questions?.find(q => q.order === question.order),
    };
  });

  function handleNavigation(direction: 1 | -1 = 1) {
    let nextStep = activeStep ? activeStep + direction : 1;
    setActiveStep(nextStep);
    window.scroll({ top: 0, behavior: 'smooth' });
  }

  const width = `${100 * activity.questions.length}%`;

  const stepStatus = (index: number): StepStatus => {
    if (index < activeStep) return 'done';
    if (index === activeStep) return 'active';
    return 'todo';
  };

  const steps = activity.questions.map<Step>((question, index) => {
    return {
      id: question.order,
      status: stepStatus(index),
      onClick: target => setActiveStep(target),
    };
  });

  return (
    <div className="relative overflow-hidden h-[70vh] w-full">
      <ActivityViewContainer
        stepper={<Steps activeStep={activeStep} steps={steps} />}
        previewer={
          <div>
            <motion.div
              className="relative flex"
              style={{ width }}
              animate={{
                left: `-${activeStep * 100}%`,
              }}
              transition={{
                type: 'spring',
                stiffness: 400,
                damping: 40,
              }}
            >
              {questionsWithAnswers.map(question => (
                <div
                  key={question.identifier}
                  className="w-full max-h-[45vh] mb-4 overflow-y-auto scrollbar-thin scrollbar-thumb-primary/40 scrollbar-track-primary-content scrollbar-thumb-rounded-full scrollbar-track-rounded-full"
                >
                  <ElementPreviewFactory
                    activityMode="answerkey"
                    isAdmin
                    hiddenStudents
                    activityId={activity.id}
                    {...{
                      ...question,
                      isDone: true,
                      progressId: 0,
                      questionId: question.id,
                      isCurrentQuestion: false,
                    }}
                  />
                </div>
              ))}
            </motion.div>
          </div>
        }
      />
      <Controls
        isOnLast={activeStep === steps.length - 1}
        hidePrev={activeStep === 0}
        hideNext={activeStep === steps.length - 1}
        nextText={t('activityNext')}
        prevText={t('activityPrev')}
        buttonColor="text-primary"
        className="absolute bottom-0 w-full bg-base-100 pt-4"
        goNext={() => handleNavigation()}
        goBack={() => handleNavigation(-1)}
        isNextRequest={false}
        isPrevRequest={false}
      />
    </div>
  );
};

export default ActivityPreview;
