import OutlineButton from '@/components/common/buttons/OutlineButton';
import ToggleButtonGroup from '@/components/common/dataInput/ToggleGroup';
import Popup from '@/components/common/Popup';
import { enUS, ptBR } from 'date-fns/locale';
import { DayPicker, useInput } from 'react-day-picker';
import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { AlertFiltersOptions } from './NotificationsPageContent';

export default function NotificationStatusFilter() {
  const {
    t,
    i18n: { language },
  } = useTranslation('translation', { keyPrefix: 'alerts.filters' });
  const { setValue, control } = useFormContext<AlertFiltersOptions>();
  const statusOptions = [
    { text: t('status.all'), value: 'all' },
    { text: t('status.today'), value: 'today' },
    { text: t('status.yesterday'), value: 'yesterday' },
  ];
  const localeLanguage = {
    locale: language === 'pt_BR' ? ptBR : enUS,
  };
  const { inputProps, dayPickerProps, setSelected } = useInput({
    fromYear: 1960,
    toYear: new Date().getFullYear(),
    locale: localeLanguage.locale,
    format: 'dd/MM/yyyy',
  });

  const onChangeStatus = () => {
    setSelected(undefined);
  };

  const onChangeDate = (e?: Date) => {
    if (e) {
      setValue('status', 'chooseDate');
      setValue('date', e);
      return;
    }

    setSelected(e);
    setValue('status', 'all');
  };
  return (
    <div className="flex flex-wrap gap-2">
      <Controller
        name="status"
        control={control}
        render={({ field }) => (
          <ToggleButtonGroup
            exclusive
            size="medium"
            buttons={statusOptions}
            value={field.value}
            onChange={e => {
              field.onChange(e);
              onChangeStatus();
            }}
          />
        )}
      />
      <Popup
        hover
        className={{ popup: 'z-10' }}
        reference={
          <OutlineButton
            className={'px-3 py-1.5'}
            
            text={inputProps.value?.toString() || t('status.chooseDate')}
            active={!!inputProps.value}
          />
        }
      >
        <DayPicker
          {...dayPickerProps}
          mode="single"
          onSelect={onChangeDate}
          captionLayout="dropdown-buttons"
          className="bg-base-content rounded-lg p-4 shadow-default shadow-neutral-content"
          classNames={{
            head: 'text-primary',
            day: 'text-base-content rounded rounded-full hover:bg-secondary-content hover:text-secondary focus:bg-base-100 w-full aspect-square',
            caption: 'capitalize flex flex-row justify-between',
            day_selected:
              'bg-secondary-content text-secondary focus:bg-secondary-content',
            day_today: 'bg-primary-content text-primary',
          }}
        />
      </Popup>
    </div>
  );
}
