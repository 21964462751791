import { useEffect, useState } from 'react';
import { ActivityMode } from '@/models/ActivityProgress';
import {
  ColumnDND,
  ColumnProgress,
  ItemProgress,
} from '@/models/DNDOrdenation';
import Text from '../../../../../common/dataDisplay/Text';
import DNDOrdenationItemProgress from './DNDOrdenationItemProgress';
import { DragDropContext, DropResult, Droppable } from '@hello-pangea/dnd';
import alert from '@/utils/UseAlert';
import { updateDndOrdenationColumnProgress } from '@/data/services/activityElement/dndOrdenationActivityProgressServices';
import { useTranslation } from 'react-i18next';
import ConditionalRenderer from '../../../../../common/ConditionalRenderer';

interface DNDOrdenationColumnProgressProps {
  dndOrdenationColumnProgress: ColumnProgress;
  activityMode: ActivityMode;
  setHasChange(hasChange: boolean): Promise<void> | void;
  answerColumn?: ColumnDND;
  columnProgress?: ColumnProgress;
}

export default function DNDOrdenationColumnProgress({
  dndOrdenationColumnProgress,
  activityMode,
  setHasChange,
  answerColumn,
  columnProgress,
}: DNDOrdenationColumnProgressProps) {
  const [itemsState, setItemsState] = useState<ItemProgress[]>(
    dndOrdenationColumnProgress.itemsProgress,
  );

  const [isReordenationItems, setIsReordenationItems] = useState(
    activityMode !== 'activity',
  );

  const { t } = useTranslation('translation', {
    keyPrefix: 'lesson.activity.dragAndDropOrdenation',
  });

  async function handleOnDragEnd(result: DropResult) {
    setHasChange(true);
    if (!result.destination) return;

    if (itemsState) {
      const itemsSource = [...itemsState];
      const items = itemsState;
      const [reorderedItem] = items.splice(result.source.index, 1);

      items.splice(result.destination.index, 0, reorderedItem);

      try {
        setIsReordenationItems(true);
        await updateDndOrdenationColumnProgress(
          dndOrdenationColumnProgress.id,
          items,
        );
        setItemsState(items);
      } catch (error) {
        setItemsState(itemsSource);
        alert.error(t('errorReordenation'));
      } finally {
        setIsReordenationItems(false);
      }
    }
  }

  useEffect(() => {
    setItemsState(dndOrdenationColumnProgress.itemsProgress);
  }, [dndOrdenationColumnProgress.itemsProgress]);

  return (
    <div className="flex flex-col gap-3 items-center w-full">
      <ConditionalRenderer
        condition={!!dndOrdenationColumnProgress.column.name}
      >
        <Text
          format="rubik-500"
          className="text-accent"
          text={dndOrdenationColumnProgress.column.name}
        />
      </ConditionalRenderer>

      <DragDropContext onDragEnd={handleOnDragEnd}>
        <Droppable
          droppableId="dndOrdenationItemProgressList"
          isDropDisabled={isReordenationItems}
        >
          {provided => (
            <div
              className="flex flex-col w-full gap-3"
              {...provided.droppableProps}
              ref={provided.innerRef}
            >
              {itemsState.map((dndOrdenationItemProgress, index) => {
                if (columnProgress) {
                  const findDndItemProgress = columnProgress.itemsProgress.find(
                    itemProgress =>
                      itemProgress.id === dndOrdenationItemProgress.id,
                  );

                  if (findDndItemProgress) {
                    const findAnswer = answerColumn?.items.find(
                      ({ id }) => id === findDndItemProgress.item.id,
                    );

                    return (
                      <DNDOrdenationItemProgress
                        index={index}
                        key={dndOrdenationItemProgress.id}
                        dndOrdenationItemProgress={findDndItemProgress}
                        activityMode={activityMode}
                        isReordenationItems={isReordenationItems}
                        answerOrder={findAnswer?.order}
                      />
                    );
                  }
                }

                return (
                  <DNDOrdenationItemProgress
                    index={index}
                    key={dndOrdenationItemProgress.id}
                    dndOrdenationItemProgress={dndOrdenationItemProgress}
                    activityMode={activityMode}
                    isReordenationItems={isReordenationItems}
                  />
                );
              })}

              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>
    </div>
  );
}
