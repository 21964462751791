import { CtrlPlayFullLogoColorIcon } from '../icons';
import Text from '../common/dataDisplay/Text';
import { CheckIcon } from '@heroicons/react/outline';
import CirclesBackground from './CirclesBackground';
import { Link, useParams } from 'react-router-dom';
import requester from '../../axios';
import TrialEnrollment from '../../models/TrialEnrollment';
import { useQuery } from '@tanstack/react-query';
import moment from 'moment';
import React from 'react';
import LoadingView from '../../pages/courses/LoadingView';
import Layout from '../template/Layout';

export default function TrialLessonSuccessView() {
  const { trialLessonEnrollmentId, trialLessonId } = useParams();

  const {
    data: trialLessonEnrollment,
    isLoading: isLoadingTrialLessonEnrollment,
  } = useQuery(
    ['trialLessonEnrollment', trialLessonEnrollmentId],
    () =>
      requester()
        .get<TrialEnrollment>(
          `/trial-lesson/enrollment/${trialLessonEnrollmentId}/`,
        )
        .then(res => res.data),
    {
      refetchOnWindowFocus: false,
    },
  );

  const {
    data: trialLessonscheduledDay,
    isLoading: isLoadingTrialLessonScheduledDay,
  } = useQuery(
    ['trialLesson', trialLessonId],
    () =>
      requester()
        .get(`trial-lesson/scheduled-day/${trialLessonId}/`)
        .then(({ data }) => {
          return data;
        }),
    {
      refetchOnWindowFocus: false,
    },
  );

  const DateInformation = () => {
    const dateTimeMoment = moment(new Date(trialLessonscheduledDay!.dateTime));
    const dateTimeFormat = dateTimeMoment.format('dddd, D [de] MMMM,');
    const hourStart = dateTimeMoment.format(`H[h] [às]`);
    const hoursEnd = dateTimeMoment.add(1, 'hour').format('H[h]');

    return (
      <Text
        className="z-10 text-20"
        text={`${dateTimeFormat} ${hourStart} ${hoursEnd}`}
      />
    );
  };

  return (
    <Layout>
      <div className="flex flex-col gap-8 items-center justify-center h-screen self-center text-16">
        {isLoadingTrialLessonEnrollment || isLoadingTrialLessonScheduledDay ? (
          <LoadingView classNameContent="fixed top-0 left-2/4 -translate-x-2/4 z-40 bg-base-100/60 w-full" />
        ) : (
          <React.Fragment>
            <CtrlPlayFullLogoColorIcon className="w-44" />
            <CirclesBackground
              primary="#FFF4BF"
              secondary="#FD8F8F"
              secondaryLight="#FFEFEF"
              accent="#4AA9FF"
              className="absolute m-auto flex self-center align-middle max-w-[712px] rotate-90 xs:rotate-0"
            />
            <Text
              text="Aula Experimental agendada com sucesso"
              format="poppins-600"
              className="text-primary text-center text-32 z-10 max-w-[430px]"
            />
            <div className="flex flex-col xxs:flex-row relative items-center w-full xxs:w-fit">
              <CheckIcon
                className="w-36 h-36 xs:w-44 xs:h-44 p-5 self-start text-base-100 bg-accent/40 rounded-full xxs:-mr-10 xs:mr-0 -mb-10 lg:mb-0 lg:-mr-5 z-10 bg-gradient-to-tr from-primary to-secondary shrink-0"
                strokeWidth={4}
              />
              <img
                src="/images/MeninoCtrlPlay.png"
                alt=""
                className="z-10 self-end"
              />
              <Text
                text={`Código do Pedido - #${trialLessonEnrollment?.cod}`}
                format="rubik-500"
                className="bg-primary-content absolute bottom-0 z-20 right-0 text-primary rounded-full flex px-6 py-2.5 leading-none"
              />
            </div>
            <DateInformation />
            <Text
              text="A aula acontecerá aqui pela plataforma no horário marcado,
              você também recebeu um e-mail com as instruções."
              className="max-w-[554px] text-center text-20"
            />
            <Link to="/">
              <button
                className="w-64 px-6 py-2 rounded-md leading-none text-base-100 bg-gradient-to-r
                from-secondary to-primary enabled:hover:from-secondary/40 enabled:hover:to-primary/40
                disabled:from-secondary-content disabled:to-primary-content focus-visible:ring-secondary/40"
              >
                Ir para a tela inicial
              </button>
            </Link>
          </React.Fragment>
        )}
      </div>
    </Layout>
  );
}
