import React from 'react';
import { StudentAgeEnum } from '../../models/User';
import getCourseStudentAge from '../../utils/getCourseStudentAge';
import Text from '../common/dataDisplay/Text';
import {
  BadgeCheckIcon,
  ClockIcon,
  PuzzleIcon,
} from '@heroicons/react/outline';
import { useTranslation } from 'react-i18next';
import studentAgeDict from '../../utils/studentAgeDict';

interface TrialCourseCardProps {
  studentAge: StudentAgeEnum;
}

export default function TrialCourseCard({ studentAge }: TrialCourseCardProps) {
  const { t } = useTranslation('translation', {
    keyPrefix: 'common.studentAge',
  });
  const course = getCourseStudentAge(studentAge);

  return (
    <div className="flex md:flex-row flex-col gap-2 items-center justify-center">
      <img
        className="w-full max-w-[244px] h-36 rounded-2xl"
        src="/images/course-default-image.png"
        alt={`ctrl+${course}`}
      />
      <div className="flex flex-col gap-3">
        <div>
          <Text
            className="inline shrink-0 min-w-fit"
            size="text-14"
            text="Indicado para alunos de "
          />
          <Text
            format="rubik-500"
            className="inline shrink-0 min-w-fit"
            size="text-14"
            text={`${t(studentAgeDict[studentAge])} anos`}
          />
        </div>
        <div className="flex flex-col gap-3.5 text-14 w-full">
          <div className="flex gap-3">
            <PuzzleIcon className="text-accent w-4 h-4" />
            <Text text="criação do primeiro jogo" />
          </div>
          <div className="flex gap-3">
            <BadgeCheckIcon className="text-accent w-4 h-4" />
            <Text text="certificado de participação" />
          </div>
          <div className="flex gap-3">
            <ClockIcon className="text-accent w-4 h-4" />
            <Text text="uma aula de 60 minutos de duração" />
          </div>
        </div>
      </div>
    </div>
  );
}
