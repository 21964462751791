interface Observer<T, Payload = undefined> {
  action: (payload?: Payload) => void;
  id: T;
}

class Observable<T, Payload = undefined> {
  public observers: Observer<T, Payload>[];
  constructor() {
    this.observers = [];
  }

  subscribe(e: Observer<T, Payload>) {
    this.observers.push(e);
  }

  unsubscribe(id: T) {
    this.observers = this.observers.filter(subscriber => subscriber.id !== id);
  }

  notifyAll(origin?: T, payload?: Payload) {
    this.observers
      .filter(observer => observer.id !== origin)
      .forEach(observer => observer.action(payload));
  }
}

export default Observable;
