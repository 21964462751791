import ConditionalRenderer from '@/components/common/ConditionalRenderer';
import { LessonBarColor } from './LessonBar';
import { motion } from 'framer-motion';

type LessonProgressBarProps = {
  progress?: number;
  color?: LessonBarColor;
  hideProgress?: boolean;
};

export default function LessonProgressBar({
  progress,
  color,
  hideProgress,
}: LessonProgressBarProps) {
  const renderCondition = (progress === 0 || !!progress) && !hideProgress;

  type ProgressStyle = { bgColor: string; progressColor: string };

  const setProgressPathColor = (color?: LessonBarColor): ProgressStyle => {
    const progressColors: { [key in LessonBarColor]: ProgressStyle } = {
      primary: {
        bgColor: 'bg-primary/40',
        progressColor: 'bg-gradient-to-r from-secondary/80 to-primary/80',
      },
      secondary: {
        bgColor: 'bg-secondary/40',
        progressColor: 'bg-gradient-to-r from-secondary/80 to-primary/80',
      },
      accent: {
        bgColor: 'bg-accent/40',
        progressColor: 'bg-gradient-to-r fromsecondary/80 to-primary/80',
      },
    };

    return progressColors[color ?? 'primary'];
  };

  const { bgColor, progressColor } = setProgressPathColor(color);

  return (
    <ConditionalRenderer condition={renderCondition}>
      <div
        data-testid="lessonProgressBar"
        className={`w-full rounded-b-xl rounded-t-none h-2.5 ${
          bgColor ?? 'bg-primary/40'
        }`}
      >
        <motion.div
          className={`bg-accent h-full rounded-bl-full ${
            progress === 1 ? 'rounded-br-full' : 'rounded-r-full'
          } ${progressColor ?? 'bg-primary'}`}
          animate={{
            width: `${(progress ?? 0) * 100}%`,
          }}
        />
      </div>
    </ConditionalRenderer>
  );
}
