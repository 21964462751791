import { useTranslation } from 'react-i18next';
import { Dispatch, SetStateAction, useState } from 'react';
import {
  DragDropContext,
  Draggable,
  Droppable,
  DropResult,
} from '@hello-pangea/dnd';
import { PlusIcon, XIcon } from '@heroicons/react/outline';
import { useNavigate } from 'react-router-dom';
import { UseMutateFunction } from '@tanstack/react-query';

import Chapter from '@/models/Chapter';
import Text from '@/components/common/dataDisplay/Text';
import { CircleIcon, DragIcon } from '@/components/icons';
import { Tooltip } from '@/components/common/dataDisplay/Tooltip';
import MainButton from '@/components/common/buttons/MainButton';
import IconButton from '@/components/common/buttons/IconButton';
import ModalWarning from '@/components/common/modals/ModalWarning';
import alert from '@/utils/UseAlert';
import useCourseEditing from '@/data/hook/useCourseEditing';
import ConditionalRenderer from '@/components/common/ConditionalRenderer';
import Book from '@/models/Book';
import { GetBookParams } from '@/data/services/bookServices';
import {
  ChapterParams,
  UpdateChaptersOrder,
} from '@/data/services/chapterServices';

type ChaptersProps = {
  courseSlug: string;
  lessonId: number;
  book: Book;
  chapters: Chapter[];
  editMode?: boolean | true;
  onCreate?: UseMutateFunction<Chapter, any, GetBookParams, unknown>;
  onUpdateOrder?: UseMutateFunction<void, any, UpdateChaptersOrder, unknown>;
  onDelete?: UseMutateFunction<void, any, ChapterParams, unknown>;
  updatingBook?: boolean;
  chapterIndex: number;
  setChapterIndex: (value: SetStateAction<number>) => void;
};

export const Chapters = ({
  courseSlug,
  lessonId,
  book,
  chapters,
  editMode,
  onCreate,
  onUpdateOrder,
  onDelete,
  updatingBook,
  chapterIndex,
  setChapterIndex,
}: ChaptersProps) => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'editMaterialPage',
  });

  const { allowBigChanges } = useCourseEditing();
  const [chapter, setChapter] = useState<Chapter>(chapters[chapterIndex]);
  const [isOpenModal, setIsOpenModal] = useState<boolean>(false);

  const handleDragEnd = (res: DropResult, activeChapterId: number) => {
    if (!res.destination || res.destination.index === res.source.index) return;
    const tempData = chapters;

    const [sourceData] = tempData.splice(res.source.index, 1);
    tempData.splice(res.destination.index, 0, sourceData);

    if (onUpdateOrder) {
      if (res.draggableId === activeChapterId.toString()) {
        setChapterIndex(res.destination.index);
      } else if (res.destination.index === chapterIndex) {
        if (res.destination.index === tempData.length - 1) {
          setChapterIndex(res.destination.index - 1);
        } else if (res.destination.index === 0) {
          setChapterIndex(res.destination.index + 1);
        }
      } else {
        const findNewActiveChapterIndex = tempData.findIndex(
          ({ id }) => id === activeChapterId,
        );
        setChapterIndex(findNewActiveChapterIndex);
      }
      onUpdateOrder({
        params: {
          bookId: book.id,
          lessonId,
          bookType: book.bookType,
        },
        chapters: tempData,
      });
    }
  };

  return (
    <div className="flex flex-col gap-5 sticky top-3.5 self-start mt-3.5 w-[30%]">
      <ModalWarning
        isLoading={updatingBook}
        visible={isOpenModal}
        translationString="modalDeleteChapter"
        objectTarget={chapter?.title}
        onClickConfirm={() => {
          onDelete?.({
            chapterId: chapter.id,
            bookId: book.id,
            bookType: book.bookType,
            lessonId,
          });
          setIsOpenModal(false);
        }}
        onClickCancel={() => setIsOpenModal(false)}
      />

      <Text
        text={t('chapters')}
        size="text-18"
        className="text-base-content"
        format="rubik-500"
      />

      <DragDropContext
        onDragEnd={res => handleDragEnd(res, chapters[chapterIndex]?.id)}
      >
        <Droppable droppableId="chapters">
          {provided => (
            <div {...provided.droppableProps} ref={provided.innerRef}>
              <div ref={provided.innerRef} {...provided.droppableProps}>
                <div className="flex flex-col gap-2.5">
                  {chapters.map((chapter, index) => (
                    <DraggableChapter
                      key={chapter.id}
                      chapter={chapter}
                      index={index}
                      isActive={chapterIndex === index}
                      setChapter={setChapter}
                      setIsOpenModal={setIsOpenModal}
                      setChapterIndex={setChapterIndex}
                      updatingBook={updatingBook}
                      chapters={chapters}
                      editMode={editMode}
                      lessonId={lessonId}
                      courseSlug={courseSlug}
                    />
                  ))}
                </div>
              </div>
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>

      <ConditionalRenderer condition={editMode}>
        <Tooltip
          text={!allowBigChanges && editMode ? t('cantModify') : null}
          className="w-fit"
        >
          <MainButton
            text={t('newChapterButton')}
            icon={<PlusIcon />}
            loading={updatingBook}
            disabled={!allowBigChanges || updatingBook}
            onClick={() =>
              onCreate?.({ bookId: book.id, bookType: book.bookType, lessonId })
            }
          />
        </Tooltip>
      </ConditionalRenderer>
    </div>
  );
};

type DraggableChapterProps = Pick<
  ChaptersProps,
  'editMode' | 'chapters' | 'lessonId' | 'courseSlug'
> & {
  index: number;
  chapter: Chapter;
  isActive: boolean;
  setChapter: Dispatch<SetStateAction<Chapter>>;
  setIsOpenModal: Dispatch<SetStateAction<boolean>>;
  setChapterIndex(index: number): void;
  updatingBook?: boolean;
};

function DraggableChapter({
  index,
  chapter,
  isActive,
  setIsOpenModal,
  setChapterIndex,
  updatingBook,
  setChapter,
  editMode,
  chapters,
  lessonId,
  courseSlug,
}: DraggableChapterProps) {
  const { t } = useTranslation('translation', {
    keyPrefix: 'editMaterialPage',
  });

  const navigate = useNavigate();

  const { allowBigChanges } = useCourseEditing();

  const checkIfCanDeleteChapter = (
    chapter: Chapter,
    chapters: null | Chapter[],
  ) => {
    if (chapters && chapters.length > 1) {
      setChapter(chapter);
      setIsOpenModal(true);
    } else {
      alert.warning(t('oneChapterMessage'));
    }
  };

  const icon = editMode ? (
    <DragIcon className="cursor-pointer" />
  ) : (
    <CircleIcon className="w-4 h-4 shrink-0 text-primary" />
  );

  const chapterStatus = (isActive: boolean, isEditMode?: boolean) => {
    if (isActive) {
      return 'font-700 text-primary bg-primary-content rounded-md';
    } else {
      if (isEditMode) {
        return 'font-400 text-base-content hover:text-primary/75 cursor-pointer';
      } else {
        return 'font-700 text-primary cursor-pointer';
      }
    }
  };

  const onNavigate = () => {
    setChapterIndex(index);

    if (editMode) {
      navigate(
        `/admin/courses/${courseSlug}/lessons/${lessonId}/books/${chapter.book}/chapter/${chapter.id}`,
      );
    }
  };

  return (
    <Draggable
      draggableId={String(chapter.id)}
      index={index}
      isDragDisabled={!editMode || !allowBigChanges || updatingBook}
      key={chapter.id}
    >
      {provided => (
        <div
          onClick={onNavigate}
          className="flex items-center gap-1.5 justify-between"
          data-testid="chapterItem"
          {...provided.draggableProps}
          ref={provided.innerRef}
        >
          <div
            className="gap-2.5 flex items-center"
            {...provided.dragHandleProps}
          >
            <Tooltip
              text={!allowBigChanges && editMode ? t('cantModify') : null}
            >
              {icon}
            </Tooltip>
          </div>

          <div
            className={`flex items-center gap-1.5 justify-between w-full px-2 py-1.5 overflow-x-hidden ${chapterStatus(
              isActive,
              editMode,
            )}`}
          >
            <Text
              text={chapter.title}
              size="text-14"
              className="text-left truncate"
            />

            <ConditionalRenderer condition={editMode}>
              <Tooltip
                placement="left"
                text={!allowBigChanges && editMode ? t('cantModify') : null}
                className="w-fit"
              >
                <IconButton
                  icon={<XIcon className="text-neutral/50" />}
                  className="w-4"
                  disabled={!allowBigChanges || updatingBook}
                  onClick={() => checkIfCanDeleteChapter(chapter, chapters)}
                />
              </Tooltip>
            </ConditionalRenderer>
          </div>
        </div>
      )}
    </Draggable>
  );
}
