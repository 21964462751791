import { twMerge } from 'tailwind-merge';

interface SkeletonProps {
  testId?: string;
  className?: string;
}

export default function Skeleton(props: SkeletonProps) {
  const { testId, className } = props;
  return (
    <div
      data-testid={testId}
      className={twMerge(
        'animate-pulse bg-neutral-content w-[100%] h-[100%]',
        className,
      )}
    />
  );
}
