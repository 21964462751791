import React from 'react';
import { Link } from 'react-router-dom';
import Text from '../common/dataDisplay/Text';
import Skeleton from '../common/Skeleton';
import ConditionalWrapper from '../common/ConditionalWrapper';
import AstroImage from '../common/AstroImage';

interface CourseCardProps {
  name: string;
  isActive: boolean;
  bannerImage: string;
  version?: number;
  href?: string;
  onClick?: (e?: React.MouseEvent<HTMLAnchorElement>) => void;
  children?: React.ReactNode;
}

function CourseCard({ name, href, bannerImage, ...props }: CourseCardProps) {
  const { isActive, version, children: controls, onClick } = props;

  return (
    <div>
      <ConditionalWrapper
        condition={!!href}
        wrapper={<Link to={href!} data-testid="courseCard" onClick={onClick} />}
      >
        <div
          className={`flex ${
            isActive ? '' : 'opacity-50 grayscale-[75%] cursor-default'
          } rounded-lg h-fit aspect-[4/2.5] bg-center bg-no-repeat bg-cover justify-end items-end relative w-auto`}
        >
          <AstroImage
            data-testid="courseCardImage"
            src={bannerImage}
            className="relative rounded-lg w-full h-full"
            alt="course-banner"
            alternativesrc="/images/course-default-image.png"
            validation={{
              renderLoading: () => (
                <Skeleton className="relative rounded-lg w-full h-full bg-neutral-content" />
              ),
            }}
          />
          <div className="absolute top-0 w-full flex gap-2 rounded-t-lg justify-between items-center flex-wrap px-[5%] py-2 text-accent hover:text-accent transition duration-100 bg-accent-content/80">
            <Text
              size="text-[85%]"
              text={name}
              className="h-fit"
              format="rubik-500"
            />
            {version && (
              <Text
                format="rubik-500"
                text={`v${version.toFixed(1)}`}
                size="text-[80%]"
                className="h-fit"
              />
            )}
          </div>
        </div>
      </ConditionalWrapper>
      <div className="bg-base-content relative">{controls}</div>
    </div>
  );
}

export default CourseCard;
