import React from 'react';
import FaqCollapse from './FaqCollapse';
import Text from '../common/dataDisplay/Text';
import { OptionsFrequentQuestions } from '../../utils/optionsFrequentQuestions';

interface FrequentQuestionsProps {
  options: OptionsFrequentQuestions[];
  title: string;
  className?: string;
  subtitle?: string;
}

export default function FrequentQuestions({
  options,
  subtitle,
  title,
  className,
}: FrequentQuestionsProps) {
  return (
    <div className={`flex flex-col sm:flex-col gap-4 ${className || ''}`}>
      <div className="flex flex-col gap-2">
        <Text
          format="poppins-600"
          className="text-primary text-20"
          text={title}
        />
        {!!subtitle && <Text text={subtitle} />}
      </div>
      <div className="flex flex-col gap-3 shrink-0">
        {options.map((option, index) => (
          <FaqCollapse
            key={index}
            header={option.header}
            content={option.content}
          />
        ))}
      </div>
    </div>
  );
}
