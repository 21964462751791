import React from 'react';
import { UseFormRegisterReturn } from 'react-hook-form';

interface RadioButtonProps extends React.InputHTMLAttributes<HTMLInputElement> {
  text?: string;
  register?: UseFormRegisterReturn;
  testId?: string;
}

export default function RadioButton({
  text,
  className,
  register,
  testId,
  ...props
}: RadioButtonProps) {
  return (
    <label className="flex items-center gap-2 text-16 text-base-content">
      {text && <span className="">{text}</span>}
      <input
        {...props}
        data-testid={testId}
        type="radio"
        className={`radio radio-primary ${className}`}
        {...register}
      />
    </label>
  );
}
