import Area from '@/models/Area';
import LeafArea from './area/LeafArea';
import ParentArea from './area/ParentArea';

export default function WorkspaceItemsFactory({
  area,
  active,
}: {
  area: Area;
  active: boolean;
}) {
  if (area.isLeaf) return <LeafArea area={area} active={active} />;
  else return <ParentArea area={area} />;
}
