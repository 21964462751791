import requester from '@/axios';
import Area, { AreaWithParent } from '@/models/Area';
import JsonApiResponse from '@/models/JsonApiResponse';
import { BaseFilters } from '@/models/Service';

type ParentArea = number | null;

export type ListAreaFilter = {
  parentAreaId?: ParentArea[];
  isLeaf?: boolean;
  isRoot?: boolean;
  ordering?: string;
} & BaseFilters;

export const listAreas = async (params: ListAreaFilter = {}) => {
  const { data } = await requester().get<JsonApiResponse<Area>>('areas/', {
    params,
  });

  return data;
};

export const getArea = async (id: number) => {
  const { data } = await requester().get<Area>(`areas/${id}/`);
  return data;
};

export const createArea = async (area: Omit<Area, 'id'>) => {
  const { data } = await requester().post<Area>('areas/', area);

  return data;
};

export const deleteArea = async (id: number) => {
  const { data } = await requester().delete<JsonApiResponse<Area>>(
    `areas/${id}/`,
  );

  return data;
};

export const updateArea = async (
  areaId: number,
  area: Partial<Area> & {
    parentAreaId?: number | null;
  },
) => {
  const { data } = await requester().patch<Area>(`areas/${areaId}/`, area);

  return data;
};

export const listAreaParents = async (areaId: number) => {
  const { data } = await requester().get<JsonApiResponse<AreaWithParent>>(
    `areas/${areaId}/parents/`,
  );
  return data;
};
