import { Fragment } from 'react';
import { useTranslation } from 'react-i18next';

import {
  ActivityProgressAttempt,
  ActivityMode,
} from '@/models/ActivityProgress';
import Statistic from './Statistic';
import ConditionalRenderer from '@/components/common/ConditionalRenderer';
import Text from '@/components/common/dataDisplay/Text';

type ResultProps = {
  answersRevealed: boolean;
  activityMode: ActivityMode;
  activityAttempt?: ActivityProgressAttempt;
  hasDoneOnce?: boolean;
};

export default function Result({ activityAttempt, hasDoneOnce }: ResultProps) {
  const { t } = useTranslation('translation', {
    keyPrefix: 'lesson.activity.result',
  });

  if (activityAttempt) {
    const result = Math.round(activityAttempt.grade * 100);
    return (
      <div className="flex flex-col justify-center text-center">
        <div className="flex flex-col gap-4">
          <Text
            text={t('resultText')}
            format="poppins-600"
            className="text-primary text-28"
          />
          <ConditionalRenderer condition={hasDoneOnce}>
            <Text
              text={t('congratulationsText')}
              className="text-base-content"
            />
          </ConditionalRenderer>
        </div>

        <div className="grid grid-cols-2 sm:grid-cols-3 justify-center gap-6 pt-10">
          <Statistic
            testId="questions"
            text={t('questions')}
            value={activityAttempt.evaluativeQuestions}
            className="bg-primary-content text-primary 
            w-full aspect-square h-auto 2xl:w-[80%]"
          />
          <Statistic
            testId="correct"
            text={t('correct')}
            value={Math.floor(activityAttempt.correctQuestions)}
            className="bg-success-content text-success 
            w-full aspect-square h-auto 2xl:w-[80%]"
          />
          <div className="col-span-2 sm:col-span-1">
            <Statistic
              testId="percentage"
              text={t('percentage')}
              value={`${result}%`}
              className="bg-gradient-to-r from-primary to-secondary
            text-base-100 w-[50%] sm:w-full aspect-square h-auto 2xl:w-[80%]"
            />
          </div>
        </div>
      </div>
    );
  }

  return <Fragment />;
}
