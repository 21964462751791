import AstroImage from '../common/AstroImage';
import ConditionalWrapper from '../common/ConditionalWrapper';
import { LessonProgressIconCheck } from '../common/ProgressIcons';
import Skeleton from '../common/Skeleton';
import { Tooltip } from '../common/dataDisplay/Tooltip';

interface LessonBannerProps {
  lessonBanner?: string;
  hasOverlay: boolean;
  width: `w-${number | '3/4'}`;
}

export function LessonBanner({
  lessonBanner,
  hasOverlay,
  ...props
}: LessonBannerProps) {
  const { width } = props;

  const loading = {
    renderLoading: () => (
      <Skeleton className="relative rounded-lg w-12 bg-primary-content" />
    ),
    timeout: 5000,
  };

  return (
    <div className={`relative rounded-full ${width} max-w-[48px]`}>
      <AstroImage
        src={lessonBanner}
        alternativesrc="/images/logo-ctrl-play.png"
        role="img"
        validation={lessonBanner ? loading : undefined}
      />
      <div
        className={`content-none w-full h-full absolute left-0 top-0 ${
          hasOverlay ? 'bg-base-100/70' : ''
        }`}
      />
    </div>
  );
}

interface LessonIconProps {
  className?: string;
  progress?: number;
  lessonBanner?: string;
  showProgress?: boolean;
}

function LessonIcon({
  className,
  lessonBanner,
  progress,
  showProgress,
}: LessonIconProps) {
  const renderTooltipCondition = !!progress || progress === 0;
  const lessonProgress = `${Math.trunc((progress || 0) * 100)}%`;
  return (
    <ConditionalWrapper
      condition={renderTooltipCondition}
      wrapper={<Tooltip text={lessonProgress} placement="right" />}
    >
      <LessonProgressIconCheck
        showProgress={showProgress}
        className={className}
        progress={progress || 0}
      >
        <LessonBanner
          width="w-3/4"
          hasOverlay={Number(progress) >= 1}
          lessonBanner={lessonBanner}
        />
      </LessonProgressIconCheck>
    </ConditionalWrapper>
  );
}

export default LessonIcon;
