import useCourseEditing from '@/data/hook/useCourseEditing';
import {
  ChallengeInputs,
  createChallengeActivity,
  createChallengeQuestion,
  deleteChallenge,
  updateChallenge,
} from '@/data/services/activityElement/challengeServices';
import { updateTextActivity } from '@/data/services/activityElement/textActivityServices';
import { updateLesson } from '@/data/services/lessonServices';
import { isPulished } from '@/functions/handleCourseStatusIcon';
import {
  ChallengeActivity,
  TextActivityElement,
} from '@/models/ActivityElement';
import { ImageFolderEnum } from '@/models/CkEditor';
import { Lesson } from '@/models/Lesson';
import alert from '@/utils/UseAlert';
import {
  PencilIcon,
  PlusIcon,
  SaveIcon,
  TrashIcon,
  XIcon,
} from '@heroicons/react/outline';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import { useState } from 'react';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import ConditionalRenderer from '@/components/common/ConditionalRenderer';
import TooltipHandler from '@/components/common/TooltipHandler';
import MainButton from '@/components/common/buttons/MainButton';
import { HtmlPreview } from '@/components/common/dataDisplay/HtmlPreview';
import Text from '@/components/common/dataDisplay/Text';
import ModalDisable from '@/components/common/modals/ModalDisable';
import MyCkeditor from '@/components/editor/MyCkeditor';
import { ScheduledLessonTypeEnum } from '@/models/ScheduledLesson';

import { VersioningStatusEnum } from '@/enums/VersioningStatus';
import { challengesQueryKeys } from '@/data/services/querykeys';

export function ChallengeEditing({
  lesson,
  type,
  readOnly,
  onUpdate,
}: {
  lesson: Lesson;
  readOnly?: boolean;
  type?: ScheduledLessonTypeEnum;
  onUpdate?: () => void;
}) {
  const { t: tChallenge } = useTranslation('translation', {
    keyPrefix: 'activity.manageActivity.challenge',
  });
  const { t: editRulesT } = useTranslation('translation', {
    keyPrefix: 'adminPage.editCourseRules',
  });
  const { slugCourseName = '' } = useParams();
  const { allowBigChanges } = useCourseEditing();

  const [hasChanges, setHasChanges] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [isRequesting, setIsRequesting] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const [isVisible, setIsVisible] = useState(false);

  const isExtraLesson = type !== ScheduledLessonTypeEnum.NORMAL && !!type;
  const { queryFn, queryKey: challengeQueryKey } = challengesQueryKeys.get(
    lesson.id,
  );

  const { data: challenge, isInitialLoading: isLoading } = useQuery({
    queryFn,
    queryKey: challengeQueryKey,
  });

  const { handleSubmit, reset, control } = useForm<ChallengeInputs>({
    defaultValues: {
      content: challenge?.questions.length
        ? challenge.questions[0].content
        : '',
    },
  });
  const queryClient = useQueryClient();

  const handleUpdateLessonStatus = async (lesson: Lesson) => {
    if (isPulished(lesson.status)) {
      const newLesson = await updateLesson(
        { lessonId: lesson.id, slugCourseName },
        { status: isExtraLesson ? VersioningStatusEnum.VERSIONING : undefined },
      );
      onUpdate?.();
      return newLesson;
    } else return lesson;
  };

  const handleCreateChallenge = async (lessonId: number, content: string) => {
    const { id: challengeId } = await createChallengeActivity(lessonId);
    await createChallengeQuestion(challengeId, { content });
    queryClient.invalidateQueries(challengeQueryKey);
  };

  const handleUpdateChallenge = async (
    challenge: ChallengeActivity,
    lessonId: number,
    content: string,
  ) => {
    let challengeTextAux =
      challenge?.questions[0] || ({} as TextActivityElement);

    if (!challenge.questions.length) {
      const newQuestions = await createChallengeQuestion(challenge.id, {
        content,
      });
      challengeTextAux = newQuestions;
    }

    if (challenge.status === VersioningStatusEnum.PUBLISHED) {
      const updatedChallenge = await updateChallenge({
        lessonId,
        challengeId: challenge.id,
      });
      challengeTextAux = updatedChallenge.questions[0];
    }
    await updateTextActivity(challengeTextAux.id, { content });
    queryClient.invalidateQueries(challengeQueryKey);
  };

  const handleDeleteChallenge = async () => {
    setIsDeleting(true);
    try {
      const updatedLesson = await handleUpdateLessonStatus(lesson);

      if (challenge?.id) {
        let challengeAux = challenge;
        if (challenge.status === VersioningStatusEnum.PUBLISHED) {
          challengeAux = await updateChallenge({
            lessonId: updatedLesson.id,
            challengeId: challenge.id,
          });
        }
        await deleteChallenge({
          lessonId: updatedLesson.id,
          challengeId: challengeAux.id,
        });
        setIsVisible(false);
        setIsEdit(false);
        alert.success(tChallenge('deleteSuccess'));
      }
      reset({
        content: '',
      });
      queryClient.removeQueries(challengeQueryKey);
    } catch (error) {
      alert.error(tChallenge('deleteFail'));
    } finally {
      setIsDeleting(false);
    }
  };

  const onSubmit: SubmitHandler<ChallengeInputs> = async ({ content }) => {
    try {
      setIsRequesting(true);

      if (challenge?.id && isNaN(challenge.id)) {
        return;
      }

      const updatedLesson = await handleUpdateLessonStatus(lesson);

      if (challenge?.id) {
        await handleUpdateChallenge(challenge, updatedLesson.id, content);
      } else {
        await handleCreateChallenge(updatedLesson.id, content);
      }

      setHasChanges(false);
      setIsEdit(false);
      reset({ content });
      alert.success(tChallenge('saveSuccess'));
      queryClient.invalidateQueries(challengeQueryKey);
    } catch (error) {
      alert.error(tChallenge('saveError'));
    } finally {
      setIsRequesting(false);
    }
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)} className="flex gap-y-2.5 flex-col">
      <ConditionalRenderer condition={challenge?.id}>
        <Text
          text={tChallenge('content')}
          format="rubik-500"
          className="text-primary"
        />
      </ConditionalRenderer>
      {!isEdit ? (
        <HtmlPreview
          className={isLoading || isRequesting ? 'animate-pulse' : ''}
          format="book"
          testId="challengeEditor"
          html={
            challenge?.questions.length ? challenge.questions[0].content : ''
          }
        />
      ) : (
        <Controller
          control={control}
          name="content"
          render={({ field: { onChange } }) => (
            <MyCkeditor
              content={
                challenge?.questions.length
                  ? challenge.questions[0].content
                  : ''
              }
              format="book"
              testId="challengeEditor"
              disabled={!isEdit}
              folder={ImageFolderEnum.CHALLENGE}
              handleContentChange={value => {
                onChange(value);
                setHasChanges(true);
              }}
            />
          )}
        />
      )}
      <ModalDisable
        visible={isVisible}
        selectedObjectName={lesson.name}
        translationString="modalDisableChallenge"
        onClickCancel={() => {
          setIsVisible(false);
        }}
        onClickConfirm={handleDeleteChallenge}
        isRequesting={isDeleting}
      />
      <ConditionalRenderer condition={!readOnly}>
        <div className="flex justify-between items-center w-full">
          <TooltipHandler
            renderTooltip={!challenge?.id && !allowBigChanges}
            tooltipMessage={editRulesT('cantModify')}
            position="tooltip-right"
            className="w-[85px]"
          >
            <MainButton
              icon={challenge?.id ? <PencilIcon /> : <PlusIcon />}
              dataTestId="editChallenge"
              text={tChallenge(challenge?.id ? 'edit' : 'add')}
              onClick={() => {
                setIsEdit(true);
              }}
              type="button"
              loading={isRequesting || isDeleting || isLoading}
              disabled={(!challenge?.id && !allowBigChanges) || isEdit}
            />
          </TooltipHandler>
          <div
            className={`flex gap-3 ${
              challenge?.id || isEdit ? '' : 'invisible pointer-events-none'
            }`}
          >
            <ConditionalRenderer condition={challenge?.id}>
              <TooltipHandler
                renderTooltip={!allowBigChanges}
                tooltipMessage={editRulesT('cantModify')}
                position="tooltip-right"
                className="w-[85px]"
              >
                <MainButton
                  icon={<TrashIcon />}
                  dataTestId="deleteChallenge"
                  text={tChallenge('delete')}
                  onClick={() => {
                    setIsVisible(true);
                  }}
                  type="button"
                  color="warning"
                  loading={isDeleting}
                  disabled={!allowBigChanges || !isEdit || !challenge}
                />
              </TooltipHandler>
            </ConditionalRenderer>
            <MainButton
              text={tChallenge('cancel')}
              disabled={!isEdit}
              icon={<XIcon />}
              type="reset"
              color="neutral"
              onClick={() => {
                reset({ content: challenge?.questions[0].content || '' });
                setHasChanges(false);
                setIsEdit(false);
              }}
            />
            <MainButton
              icon={<SaveIcon />}
              dataTestId="saveChallenge"
              text={tChallenge('save')}
              type="submit"
              loading={isRequesting || isDeleting || isLoading}
              disabled={!hasChanges || !isEdit}
            />
          </div>
        </div>
      </ConditionalRenderer>
    </form>
  );
}
