import requester from '@/axios';
import { TrueOrFalseActivityElement } from '@/models/ActivityElement';

export const getTrueOrFalseActivity = async (questionId: number) => {
  const { data: trueOrFalseActivity } =
    await requester().get<TrueOrFalseActivityElement>(
      `true-or-false-activity/${questionId}/`,
    );
  return trueOrFalseActivity;
};

export const deleteTrueOrFalseActivity = async (questionId: number) => {
  await requester().delete<TrueOrFalseActivityElement>(
    `true-or-false-activity/${questionId}/`,
  );
};
