import { activityTypeOptions } from '../../../utils/activityTypeOptions';
import Text from '../../common/dataDisplay/Text';
import Modal from '../../common/modals/Modal';
import { ActivityTypeSelect } from './EditActivity';
import { useTranslation } from 'react-i18next';

export default function ModalNewQuestion({
  onClick,
  onClose,
  visible,
}: {
  onClick: (option: ActivityTypeSelect) => void;
  onClose: () => void;
  visible: boolean;
}) {
  const { t } = useTranslation('translation', {
    keyPrefix: 'activity.manageActivity.activityTypes',
  });
  return (
    <Modal visible={visible} onClose={onClose} testId="modalNewQuestion">
      <Text
        format="poppins-600"
        className="text-primary text-24"
        text="Selecione um tipo de questão"
      />
      <ul className="menu p-2 bg-base-100 rounded-box">
        {activityTypeOptions.map(({ type, icon: Icon }, index: number) => {
          return (
            <li key={index} id={type}>
              <button
                className="flex items-center gap-4 pl-5 w-full"
                onClick={() => {
                  onClick({ type, icon: Icon });
                  onClose();
                }}
              >
                <Icon className="w-6" /> {t(type)}
              </button>
            </li>
          );
        })}
      </ul>
    </Modal>
  );
}
