export const urlValidate = (url?: string) =>
  url
    ? `https://www.` +
      url
        ?.replace('www.', '')
        .replace('http://', '')
        .replace('https://', '')
        .replace('https:/', '')
        .replace('htps:/', '')
        .replace('htp:/', '')
        .replace('http:/', '')
    : '';
