import { useTranslation } from 'react-i18next';
import { ApiError } from '@/models/Errors';
import RoundedButton from '@/components/common/buttons/RoundedButton';
import Text from '@/components/common/dataDisplay/Text';
import Modal from '@/components/common/modals/Modal';
import alert from '@/utils/UseAlert';
import { useMutation } from '@tanstack/react-query';
import useCourseEditing from '@/data/hook/useCourseEditing';

interface PublishEditModalProps {
  onClose(): void;
  slug?: string;
  visible: boolean;
  onConfirm?: () => void;
  isLoading?: boolean;
}

function PublishEditModal({
  onClose,
  slug,
  visible,
  onConfirm,
  isLoading,
}: PublishEditModalProps) {
  const { t } = useTranslation('translation', {
    keyPrefix: 'adminPage.lesson',
  });
  const { courseToPublish } = useCourseEditing();
  const { mutate: publishCourse, isLoading: isRequesting } = useMutation(
    courseToPublish,
    {
      onSuccess() {
        alert.success(t('modal.publishEdit.success'));
        onClose();
      },
      onError(error: any) {
        const apiError = new ApiError(error);
        alert.error(apiError.getErrorMessage());
      },
    },
  );

  return (
    <Modal onClose={onClose} visible={visible}>
      <div className="flex flex-col items-center gap-6">
        <Text
          text={t('modal.publishEdit.title')}
          className="text-primary text-center"
          format="rubik-500"
          size="text-20"
        />
        <Text text={t('modal.publishEdit.warning')} className="text-center" />
        <Text text={t('modal.publishEdit.question')} className="text-center" />
        <div className="flex items-center gap-7 w-full">
          <RoundedButton
            className="w-full"
            text={t('modal.publishEdit.cancel')}
            onClick={onClose}
            color="neutral"
          />
          <RoundedButton
            className="w-full"
            testId="confirmEditButton"
            loading={isLoading || isRequesting}
            onClick={onConfirm || (() => publishCourse(slug ?? ''))}
            text={t('modal.publishEdit.confirm')}
            color="gradient"
          />
        </div>
      </div>
    </Modal>
  );
}

export default PublishEditModal;
