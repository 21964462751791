import { useEffect, useRef, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { SaveIcon } from '@heroicons/react/outline';
import { isEqual } from 'lodash';
import { useMutation } from '@tanstack/react-query';

import {
  CodeEditorActivity,
  LanguageCodeEditorEnum,
} from '@/models/CodeEditorActivity';
import { ApiError } from '@/models/Errors';
import TextInput from '@/components/common/dataInput/TextInput';
import MainButton from '@/components/common/buttons/MainButton';
import EnumInput from '@/components/common/dataInput/EnumInput';
import MyCkeditor from '@/components/editor/MyCkeditor';
import ConditionalRenderer from '@/components/common/ConditionalRenderer';
import { ImageFolderEnum } from '@/models/CkEditor';
import {
  createCodeEditorActivity,
  UpdateCodeEditorActivity,
  updateCodeEditorActivity,
} from '@/data/services/activityElement/codeEditorActivityServices';
import alert from '@/utils/UseAlert';
import { buildChangedObject } from '@/utils/buildChangedObject';
import { getErrorMessage } from '@/utils/getErrorMessage';
import Activity from '@/models/Activity';
import { CodeEditorActivityElement } from '@/models/ActivityElement';
import TriggerSizeCodeEditor from '@/components/codeEditor/TriggerSizeCodeEditor';

export type CodeEditorFields = Pick<
  CodeEditorActivity,
  | 'title'
  | 'subtitle'
  | 'content'
  | 'initialCode'
  | 'expectedOutput'
  | 'language'
>;

export type CodeEditorFieldsKeys = keyof CodeEditorFields;

type onEditFn = (question: Partial<CodeEditorActivityElement>) => void;

type CodeEditorFormProps = {
  error?: ApiError<CodeEditorFieldsKeys>;
  question: CodeEditorActivityElement;
  activity: Activity;
  onEdit: onEditFn;
  onSave: (question: CodeEditorActivity) => void;
};

export default function CodeEditorForm(props: CodeEditorFormProps) {
  const [t] = useTranslation('translation', {
    keyPrefix: 'common',
  });

  const { t: tCodeEditorLanguage } = useTranslation('translation', {
    keyPrefix: 'codeEditorLanguage',
  });

  const { t: tCodeEditorForm } = useTranslation('translation', {
    keyPrefix: 'codeEditorForm',
  });

  const { question, onEdit, onSave, activity } = props;

  const [hasChanges, setHasChanges] = useState(false);

  const {
    title,
    content,
    expectedOutput,
    initialCode,
    language,
    changeStatus,
    type,
    identifier,
    order,
    subtitle,
    ...codeEditorQuestion
  } = question;

  const defaultValues = useRef<CodeEditorFields>({
    title,
    content,
    expectedOutput,
    initialCode,
    language,
    subtitle,
  });

  const {
    watch,
    register,
    handleSubmit,
    control,
    formState: { errors },
  } = useForm<CodeEditorFields>({
    defaultValues: defaultValues.current,
    shouldFocusError: true,
  });

  useEffect(() => {
    const subscribe = watch(value =>
      setHasChanges(!isEqual(value, defaultValues.current)),
    );
    return () => {
      setHasChanges(false);
      subscribe.unsubscribe();
    };
  }, [defaultValues, watch]);

  useEffect(() => {
    const w = watch(e =>
      onEdit({ ...e, changeStatus, id: codeEditorQuestion.id, identifier }),
    );
    return () => w.unsubscribe();
  }, [changeStatus, codeEditorQuestion, identifier, onEdit, watch]);

  const { mutate: create, isLoading: loadingCreate } = useMutation(
    createCodeEditorActivity,
    {
      async onSuccess(data) {
        onSave(data);
        setHasChanges(false);
        alert.success(t('saved'));
        defaultValues.current = data;
      },
      onError(error) {
        alert.error(getErrorMessage(error));
      },
    },
  );

  const { mutate: update, isLoading: loadingUpdate } = useMutation(
    (params: UpdateCodeEditorActivity) =>
      updateCodeEditorActivity(params.codeActivityId, params.changes),
    {
      onSuccess: data => {
        onSave(data);
        setHasChanges(false);
        alert.success(t('saved'));
        defaultValues.current = data;
      },
      onError: error => {
        alert.error(getErrorMessage(error));
      },
    },
  );

  const onSubmit = async (changes: Partial<CodeEditorFields>) => {
    const changedFields = buildChangedObject(defaultValues.current, changes);

    if (Object.values(changedFields).length) {
      if (changeStatus === 'new')
        create({
          ...changedFields,
          activity: activity.id,
          type,
          identifier,
          order,
        });
      else update({ codeActivityId: question.id, changes: changedFields });
    }
  };

  const loading = loadingCreate || loadingUpdate;

  const blockSave = Object.values(errors).length > 0;

  return (
    <form onSubmit={handleSubmit(onSubmit)} className="flex flex-col gap-6">
      <TextInput
        testId="titleInput"
        register={register('title', {
          required: t('form.required', {
            field: t('form.title.title').toLowerCase(),
          }),
        })}
        label={t('form.title.title')}
        errorLabelText={errors.title?.message}
      />

      <Controller
        rules={{
          required: t('form.required', {
            field: t('form.content.title').toLowerCase(),
          }),
        }}
        name="content"
        control={control}
        render={({ field: { onChange, value }, fieldState: { error } }) => (
          <div className="flex flex-col">
            <label
              htmlFor="content"
              className="label leading-none p-0 w-full text-[0.875rem]"
            >
              {t('form.content.title')}
            </label>
            <ConditionalRenderer condition={error?.message}>
              <label className="font-rubik text-error error-label text-[0.875rem]">
                {error?.message}
              </label>
            </ConditionalRenderer>
            <MyCkeditor
              testId="contentEditor"
              content={value}
              folder={ImageFolderEnum.ACTIVITY}
              handleContentChange={onChange}
            />
          </div>
        )}
      />

      <Controller
        rules={{
          required: t('form.required', {
            field: t('form.language.title').toLowerCase(),
          }),
        }}
        name="language"
        control={control}
        render={({ field: { onChange, value }, fieldState: { error } }) => (
          <EnumInput
            testId="languageInput"
            label={t('form.language.title')}
            selected={value}
            onSelect={onChange}
            enumModel={LanguageCodeEditorEnum}
            displayName={option => tCodeEditorLanguage(option)}
            errorLabelText={error?.message}
            type="string"
          />
        )}
      />

      <Controller
        name="initialCode"
        control={control}
        render={({ field: { onChange, value } }) => (
          <div className="flex flex-col">
            <label
              htmlFor="initialCode"
              className="label leading-none p-0 w-full text-[0.875rem]"
            >
              {tCodeEditorForm('initialCode')}
            </label>
            <TriggerSizeCodeEditor
              testId="initialCodeEditor"
              value={value ?? ''}
              onChangeCode={onChange}
              autocomplete
              language={watch('language') ?? LanguageCodeEditorEnum.JAVASCRIPT}
            />
          </div>
        )}
      />

      <MainButton
        dataTestId="saveButton"
        type="submit"
        icon={<SaveIcon />}
        text={t('save')}
        disabled={!hasChanges || blockSave}
        loading={loading}
      />
    </form>
  );
}
