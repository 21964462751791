import { Libraries } from '@react-google-maps/api';
import { UserStateEnum } from '../models/UserProfile';

export const placesLibraries: Libraries = ['places'];

interface CityStateObject {
  city: string;
  state: string | undefined;
}

export function extractCityAndState(input: string): CityStateObject {
  if (!input) {
    return {} as CityStateObject;
  }
  const parts = input.split(',');

  if (parts.length < 2) {
    return { city: input, state: undefined };
  }

  const city = parts[0].trim();
  const stateAndCountry = parts.slice(1).join(',').trim();

  let foundState = '';

  for (const stateAbbr in UserStateEnum) {
    const stateName = UserStateEnum[stateAbbr as keyof typeof UserStateEnum];
    if (
      stateAndCountry.includes(stateName) ||
      stateAndCountry.includes(stateAbbr)
    ) {
      foundState = stateAbbr;
      break;
    }
  }

  if (!foundState) {
    return { city: input, state: undefined };
  }

  return { city, state: foundState };
}

export const returnLocationString = (city?: string, state?: string) => {
  if (city && state) {
    return `${city}, ${state}`;
  }
  return city || '';
};
