import React, { ReactElement } from 'react';
import PageTitle from '../PageTitle';
import ComponentGuard from '../ComponentGuard';
import { UserTypeEnum } from '@/models/User';
import StatisticsCard from '../StatisticsCard';

interface StatisticsCardsProps {
  title: string;
  info: string | number;
  isLoading: boolean;
  roles?: UserTypeEnum[];
}

export default function TableStatistics({
  headingText,
  statisticsCards,
  adminButton,
  roles = [UserTypeEnum.SUPER_ADMIN],
}: {
  headingText: string;
  statisticsCards: StatisticsCardsProps[];
  adminButton: ReactElement;
  roles?: UserTypeEnum[];
}) {
  return (
    <React.Fragment>
      <div className="flex justify-between">
        <div className="flex-col w-full">
          <PageTitle
            headingText={headingText}
            position="mb-2"
            backButton={true}
          />
          <div className="flex flex-wrap xl:flex-nowrap gap-3 items-end">
            {statisticsCards.map((card, i) => {
              return (
                <StatisticsCard
                  key={i}
                  title={card.title}
                  info={card.info}
                  isLoading={card.isLoading}
                  size="small"
                />
              );
            })}
            <ComponentGuard roles={roles}>
              <span className="flex items-end h-full w-full justify-end">
                {adminButton}
              </span>
            </ComponentGuard>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}
