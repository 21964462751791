import PublishEditModal from '@/components/admin/courses/PublishEditModal';
import CollapsibleLesson from '@/components/admin/lessons/CollapsibleLesson';
import LessonReplacer from '@/components/admin/lessons/LessonReplacer';
import LessonReplacerItem from '@/components/admin/lessons/LessonReplacerItem';
import ConditionalRenderer from '@/components/common/ConditionalRenderer';
import CourseTitle from '@/components/common/CourseTitle';
import ErrorComponent from '@/components/common/ErrorComponent';
import HeadTitle from '@/components/common/HeadTitle';
import InfinityList from '@/components/common/InfinityList';
import PageTitle from '@/components/common/PageTitle';
import MainButton from '@/components/common/buttons/MainButton';
import Tabs, {
  serializerTabsContents,
} from '@/components/common/dataDisplay/Tabs/Tabs';
import { TagProps } from '@/components/common/dataDisplay/Tag';
import Text from '@/components/common/dataDisplay/Text';
import ModalDisable from '@/components/common/modals/ModalDisable';
import ModalEmptyActivity from '@/components/common/modals/ModalEmptyActivity';
import Layout from '@/components/template/Layout';
import useAuth from '@/data/hook/useAuth';
import useCourseEditing from '@/data/hook/useCourseEditing';
import useScheduledLessons from '@/data/hook/useScheduledLessons';
import { deleteActivity } from '@/data/services/activityServices';
import {
  cancelExtraLessons,
  getEmptyActivities,
  publishExtraLessons,
  updateExtraLessonOrders,
} from '@/data/services/lessonServices';
import { activateOrDeactivateScheduledLesson } from '@/data/services/scheduledLessonsServices';
import { VersioningStatusEnum } from '@/enums/VersioningStatus';
import { formatLessonName } from '@/functions/lessonsName';
import { EmptyActivity, EmptyActivityWithLesson } from '@/models/Activity';
import { ApiError } from '@/models/Errors';
import Klass from '@/models/Klass';
import ScheduledLesson, {
  ScheduledLessonTypeEnum,
} from '@/models/ScheduledLesson';
import alert from '@/utils/UseAlert';
import { translateKlassName } from '@/utils/translateKlassName';
import { DropResult } from '@hello-pangea/dnd';
import { EyeIcon, XIcon } from '@heroicons/react/outline';
import { UploadIcon } from '@heroicons/react/solid';
import { useMutation, useQuery } from '@tanstack/react-query';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import CancelChangesModal from '../../admin/lessons/CancelChangesModal';
import LoadingView from '../../courses/LoadingView';
import ErrorPage from '../../notFound/ErrorPage';
import useMountExtraLessonPanes from './KlassLessons.hooks';
import { LessonTab } from '@/models/Lesson';
import {
  coursePathsQueryKeys,
  klassesQueryKeys,
} from '@/data/services/querykeys';
import { isSuperAdmin, isUnitAdmin } from '@/functions/auth';

type UpdateScheduledLessons = (
  updatedScheudledLesson?: ScheduledLesson,
) => Promise<void>;

export default function KlassLessons() {
  const { klassId = '' } = useParams();
  const navigate = useNavigate();
  const { t: tErrors } = useTranslation('translation', { keyPrefix: 'erros' });
  const { t } = useTranslation('translation', { keyPrefix: 'klass.lessons' });
  type ModalTypes = 'none' | 'cancel' | 'publish' | 'reorder' | 'activity';
  const [openedModal, setOpenedModal] = useState<ModalTypes>('none');
  const { reorderScheduledLessons, updateReorderScheduledLessons } =
    useCourseEditing();

  const hasKlassId = Boolean(Number(klassId));

  const { data: klass } = useQuery({
    ...klassesQueryKeys.get(Number(klassId)),
    enabled: !!hasKlassId,
  });

  const {
    results,
    error,
    fetchNextPage,
    isFetchingNextPage,
    loading,
    hasNextPage,
    invalidate,
  } = useScheduledLessons({ klassId: +klassId });

  const updateScheduledLessons: UpdateScheduledLessons =
    async updatedScheduledLesson => {
      await invalidate();

      if (reorderScheduledLessons.length && updatedScheduledLesson) {
        const scheduledLessonIndex = reorderScheduledLessons.findIndex(
          ({ id }) => id === updatedScheduledLesson.id,
        );
        const updatedReorderScheduledLessons = [...reorderScheduledLessons];
        updatedReorderScheduledLessons.splice(
          scheduledLessonIndex,
          1,
          updatedScheduledLesson,
        );
        updateReorderScheduledLessons(updatedReorderScheduledLessons);
      }
    };

  const scheduledLessons = reorderScheduledLessons.length
    ? reorderScheduledLessons
    : results;

  const { data: coursePath, isInitialLoading: isLoadingCoursePath } = useQuery({
    ...coursePathsQueryKeys.get(klass?.coursePathSlug ?? ''),
    enabled: !!klass?.coursePathSlug,
  });

  const pastScheduledLesssons =
    scheduledLessons?.filter(({ hasDone }) => hasDone) ?? [];

  const currentScheduledLessons =
    scheduledLessons?.filter(({ hasDone }) => !hasDone) ?? [];

  const versioningLessons =
    scheduledLessons?.filter(
      ({ lesson: { status }, isActive, type }) =>
        status === VersioningStatusEnum.VERSIONING ||
        (!isActive && type !== ScheduledLessonTypeEnum.NORMAL),
    ) ?? [];

  const versioningIds = versioningLessons.map<{ id: number }>(({ id }) => ({
    id,
  }));

  const extraLessonsCount =
    scheduledLessons?.reduce(
      (prev, { type }) =>
        type === ScheduledLessonTypeEnum.EXTRA ? ++prev : prev,
      0,
    ) ?? 0;

  const disbaleNormalLessonsCount =
    scheduledLessons?.reduce(
      (prev, { type, isActive }) =>
        type === ScheduledLessonTypeEnum.NORMAL && !isActive ? ++prev : prev,
      0,
    ) ?? 0;

  type isSameOrderType = (
    list1: ScheduledLesson[],
    list2: ScheduledLesson[],
  ) => boolean;

  const isSameOrder: isSameOrderType = (list1, list2) =>
    JSON.stringify(list1) === JSON.stringify(list2);

  const hasUpdateOrder = !!reorderScheduledLessons.length;
  const hasVersioningLessons = versioningIds.length > 0;

  const disableActions = (!hasUpdateOrder && !hasVersioningLessons) || !klass;

  const klassName = translateKlassName(klass?.name);

  const [emptyActivities, setEmptyActivities] = useState<
    EmptyActivityWithLesson[]
  >([]);

  const [isLoadingDeleteActivities, setIsLoadingDeleteActivities] =
    useState<boolean>(false);

  const closeModal = () => setOpenedModal('none');

  const klassCourseUrl = `/courses/${klass?.coursePathSlug}/class/${klass?.id}`;

  const onCancelChanges = async () => {
    if (!klass) {
      throw new Error('Class not found');
    }

    if (hasVersioningLessons) {
      await cancelExtraLessons(klass.id, versioningIds);
    }

    updateReorderScheduledLessons([]);
  };

  const { mutate: cancelChange, isLoading: isCanceling } = useMutation(
    onCancelChanges,
    {
      async onSuccess() {
        alert.success(t('modal.cancel.success'));
        await invalidate();
        navigate(klassCourseUrl);
      },
      onError(error: any) {
        const apiError = new ApiError(error);
        alert.error(apiError.getErrorMessage());
      },
    },
  );

  const onPublishChanges = async () => {
    if (!klass) {
      throw new Error('Class not found');
    }

    if (hasVersioningLessons) {
      await publishExtraLessons(klass.id, versioningIds);
    }

    if (reorderScheduledLessons.length) {
      await saveLessonOrder({
        klass,
        list: reorderScheduledLessons,
      });
    }
  };

  interface SaveLessonOrderParams {
    klass: Klass;
    list: ScheduledLesson[];
  }

  async function saveLessonOrder({ klass, list }: SaveLessonOrderParams) {
    const updatedLessons = list.map(({ id }) => ({ id }));
    await updateExtraLessonOrders(klass.id, updatedLessons);
  }

  const { mutate: saveChanges, isLoading: isUpdating } = useMutation(
    onPublishChanges,
    {
      async onSuccess() {
        alert.success(t('success'));
        await invalidate();
        updateReorderScheduledLessons([]);
        navigate(klassCourseUrl);
        return;
      },
      onError(error) {
        const apiError = new ApiError(error as any);
        alert.error(apiError.getErrorMessage());
      },
    },
  );

  const handleDragEnd = (res: DropResult) => {
    if (!res.destination || !currentScheduledLessons || !klass) return;
    const currentList = [...currentScheduledLessons];
    const [sourceData] = currentList.splice(res.source.index, 1);
    currentList.splice(res.destination.index, 0, sourceData);
    const list = pastScheduledLesssons.concat(currentList);
    if (!isSameOrder(list, currentScheduledLessons)) {
      updateReorderScheduledLessons(list);
    }
  };

  const checkEmptyActivities = async (
    versioningScheduledLessons?: ScheduledLesson[],
  ) => {
    const emptyActivities = await getEmptyActivities({
      klassId: Number(klassId),
    });
    const hasEmptyActivities = !!emptyActivities.length;
    if (hasEmptyActivities) {
      const emptyActivitiesAux: EmptyActivityWithLesson[] = [];
      versioningScheduledLessons?.forEach(versioningScheduledLesson => {
        const filterEmptyActivity = emptyActivities.filter(
          (lessonToCheck: EmptyActivity) =>
            lessonToCheck.lesson.includes(versioningScheduledLesson.lesson.id),
        );
        if (filterEmptyActivity && filterEmptyActivity.length) {
          filterEmptyActivity.map(activity =>
            emptyActivitiesAux.push({
              ...activity,
              lessonInfo: {
                ...versioningScheduledLesson.lesson,
                type: versioningScheduledLesson.type,
                order: versioningScheduledLesson.order,
              },
            }),
          );
        }
      });

      setEmptyActivities(emptyActivitiesAux);
    }
    setOpenedModal(hasEmptyActivities ? 'activity' : 'publish');
  };

  const deleteActivities = async (emptyActivities: EmptyActivity[]) => {
    setIsLoadingDeleteActivities(true);

    const [request] = await Promise.allSettled(
      emptyActivities.map(emptyActivity =>
        deleteActivity({
          activityId: emptyActivity.id,
          lessonId: emptyActivity.lesson[0],
        }),
      ),
    );

    if (request.status === 'rejected') {
      alert.error(tErrors('serverError'));
    } else {
      alert.success(t('alert.saved'));
      setIsLoadingDeleteActivities(false);
      setOpenedModal('none');
    }
  };

  if (!klassId || isNaN(Number(klassId))) {
    return <ErrorPage />;
  }

  return (
    <Layout>
      <HeadTitle routeInfo={`${t('title')} - ${klassName}`} />
      <PageTitle headingText={t('title')} />
      <ConditionalRenderer condition={emptyActivities.length}>
        <ModalEmptyActivity
          isVisible={openedModal === 'activity'}
          emptyActivities={emptyActivities}
          onClickConfirm={async () => {
            await deleteActivities(emptyActivities);
            saveChanges();
          }}
          onClickCancel={closeModal}
          isRequesting={isLoadingDeleteActivities || isUpdating}
        />
      </ConditionalRenderer>
      <CancelChangesModal
        closeModal={closeModal}
        isLoading={isCanceling}
        itemName={klassName}
        onConfirm={cancelChange}
        visible={openedModal === 'cancel'}
      />
      <PublishEditModal
        onClose={closeModal}
        onConfirm={saveChanges}
        visible={openedModal === 'publish'}
        isLoading={isUpdating}
      />
      <div className="flex flex-col gap-6 mb-3.5">
        <div className="flex justify-between">
          <Text
            format="rubik-500"
            size="font-14"
            className="text-primary"
            text={klassName}
          />
          <div className="flex items-center gap-2.5">
            <MainButton
              disabled={disableActions}
              dataTestId="cancelButton"
              text={t('cancel')}
              color="neutral"
              onClick={() => setOpenedModal('cancel')}
              icon={<XIcon />}
            />
            <MainButton
              disabled={disableActions}
              onClick={() => checkEmptyActivities(versioningLessons)}
              icon={<UploadIcon />}
              text={t('finish')}
              dataTestId="publishButton"
              color="secondary"
            />
            <MainButton
              text={t('view')}
              onClick={() => navigate(klassCourseUrl)}
              icon={<EyeIcon />}
            />
          </div>
        </div>

        <CourseTitle
          title={coursePath?.course.name}
          type={coursePath?.course.type}
          isLoading={isLoadingCoursePath}
        />
      </div>

      <InfinityList
        hasNextPage={hasNextPage}
        isFetchingNextPage={isFetchingNextPage}
        onReachEnd={fetchNextPage}
        className="gap-4"
      >
        {coursePath && (
          <HandleView error={error} isLoading={loading}>
            <LessonReplacer isDropDisabled>
              {pastScheduledLesssons.map((scheduled, i) => (
                <EditLesson
                  hasMoreExtraLessons={
                    disbaleNormalLessonsCount < extraLessonsCount
                  }
                  scheduledLesson={scheduled}
                  index={i}
                  key={scheduled.id}
                  klass={klass}
                  slugCourseName={coursePath.slug}
                  updateScheduledLessons={updateScheduledLessons}
                />
              ))}
            </LessonReplacer>
            <LessonReplacer onDragEnd={handleDragEnd}>
              {currentScheduledLessons.map((scheduled, i) => (
                <EditLesson
                  hasMoreExtraLessons={
                    disbaleNormalLessonsCount < extraLessonsCount
                  }
                  scheduledLesson={scheduled}
                  index={i}
                  key={scheduled.id}
                  klass={klass}
                  slugCourseName={coursePath.slug}
                  updateScheduledLessons={updateScheduledLessons}
                />
              ))}
            </LessonReplacer>
          </HandleView>
        )}
      </InfinityList>
    </Layout>
  );
}

type EditLessonProps = {
  hasMoreExtraLessons?: boolean;
  scheduledLesson: ScheduledLesson;
  index: number;
  klass?: Klass;
  slugCourseName: string;
  updateScheduledLessons: UpdateScheduledLessons;
};

function EditLesson(props: EditLessonProps) {
  const {
    klass,
    hasMoreExtraLessons,
    scheduledLesson,
    slugCourseName,
    updateScheduledLessons,
    index,
  } = props;
  const klassId = klass?.id ?? 0;
  const { activeLessonTab, handleLessonTab, handleOpenTab } =
    useCourseEditing();
  const { activeLessonIndex, activeTab } = activeLessonTab || {
    activeLessonIndex: null,
    activeTab: 'details',
  };
  const isScheduledLessonActive = scheduledLesson.isActive;
  const isActive = activeLessonIndex === index;
  const isNormalLesson =
    scheduledLesson.type === ScheduledLessonTypeEnum.NORMAL;

  const hasDoneLesson = scheduledLesson.hasDone;
  const canEnable =
    !hasDoneLesson && !isScheduledLessonActive && isNormalLesson;
  const renderActionElement =
    !hasDoneLesson && hasMoreExtraLessons && isNormalLesson;
  const { t } = useTranslation('translation', { keyPrefix: 'klass.lessons' });

  const { user } = useAuth();

  const isAddedFromBank = scheduledLesson.lesson.inBank && !isNormalLesson;

  function isUserFromKlassUnit() {
    const isFromSameUnit = user?.unitsIds.includes(klass?.unitId ?? 0) ?? false;
    return isFromSameUnit;
  }

  const isReadOnlyMode = () => {
    const userType = user?.userType;
    if (isSuperAdmin(userType)) {
      return false;
    }
    if (isUnitAdmin(userType)) {
      const isFromSameUnit = isUserFromKlassUnit();

      return !isFromSameUnit || isAddedFromBank || isNormalLesson;
    }
    return scheduledLesson.lesson.author !== user?.id || isNormalLesson;
  };
  const readOnly = isReadOnlyMode();

  function getScheduledLessonPermisision() {
    const userType = user?.userType;
    if (isSuperAdmin(userType)) {
      return true;
    }
    if (isUnitAdmin(userType)) {
      const isFromSameUnit = isUserFromKlassUnit();

      return !isNormalLesson && isFromSameUnit;
    }
    return scheduledLesson.lesson.author === user?.id && !isNormalLesson;
  }

  const canEditScheduledLesson = getScheduledLessonPermisision();

  const onChangeTab = (tab: string) =>
    handleLessonTab({
      activeTab: tab as LessonTab,
    });

  const panes = useMountExtraLessonPanes(scheduledLesson, {
    slugCourseName,
    klassId,
    readOnly,
    updateScheduledLessons,
    canEditScheduledLesson,
  });

  function handleDisableLesson() {
    if (hasDoneLesson) {
      return 'PAST';
    } else if (isScheduledLessonActive || !isNormalLesson) {
      return 'ENABLE';
    } else {
      return 'DEACTIVE';
    }
  }

  const tags: TagProps[] = isAddedFromBank
    ? [
        { text: t('inBank'), color: 'info' },
        { text: t('readOnly'), color: 'warning' },
      ]
    : [];

  return (
    <LessonReplacerItem
      key={scheduledLesson.id}
      index={index}
      draggableId={scheduledLesson.id.toString()}
      dragableHandler={{
        disabled: hasDoneLesson || !scheduledLesson.isActive,
        message: t('cantOrder'),
      }}
    >
      <div
        className="w-full"
        onClick={() => !isNormalLesson && handleOpenTab(isActive, index)}
      >
        <CollapsibleLesson
          disable={handleDisableLesson()}
          scheduledLesson={scheduledLesson}
          actionElement={
            <ActionElement
              scheduledLesson={scheduledLesson}
              updateScheduledLessons={updateScheduledLessons}
            />
          }
          renderActionElement={renderActionElement || canEnable}
          lesson={scheduledLesson.lesson}
          open={!isNormalLesson && isActive}
          tags={tags}
        >
          {!isNormalLesson && (
            <Tabs
              active={activeTab}
              contents={serializerTabsContents(panes)}
              onChange={onChangeTab}
            >
              <Tabs.Container>
                <Tabs.List>
                  {panes.map(pane => (
                    <Tabs.Trigger id={pane.key} key={pane.key}>
                      <pane.icon className="w-6 h-6 mr-2" />
                      {pane.text}
                    </Tabs.Trigger>
                  ))}
                </Tabs.List>
                <Tabs.ShowContent />
              </Tabs.Container>
            </Tabs>
          )}
        </CollapsibleLesson>
      </div>
    </LessonReplacerItem>
  );
}

type ActionElementProps = {
  scheduledLesson: ScheduledLesson;
  updateScheduledLessons: UpdateScheduledLessons;
};

function ActionElement({
  scheduledLesson,
  updateScheduledLessons,
}: ActionElementProps) {
  const deactivateLesson = async ({
    isActive,
    scheduledLesson: { klass: klassId, id: scheduledLessonId },
  }: {
    isActive: boolean;
    scheduledLesson: ScheduledLesson;
  }) => {
    return await activateOrDeactivateScheduledLesson({
      scheduledLessonId,
      klassId,
      isActive,
    });
  };

  const { mutate, isLoading: isDeactivating } = useMutation<
    ScheduledLesson,
    any,
    any
  >(deactivateLesson, {
    async onSuccess(scheduledLesson) {
      setIsActive(scheduledLesson.isActive);
      await updateScheduledLessons(scheduledLesson);
    },
    onError(error) {
      setIsActive(true);
      const apiError = new ApiError(error as any);
      alert.error(apiError.getErrorMessage());
    },
    onSettled() {
      setOpenModal(false);
    },
  });
  const [isActive, setIsActive] = useState(scheduledLesson.isActive);
  const [openModal, setOpenModal] = useState(false);
  const onChange: React.ChangeEventHandler<HTMLInputElement> = async e => {
    if (isActive) {
      return setOpenModal(true);
    }
    const isChecked = e.target.checked;
    setIsActive(isChecked);
    mutate({ isActive: isChecked, scheduledLesson });
  };
  const closeModal = () => {
    setOpenModal(false);
  };

  const onConfirmModal = async () => {
    mutate({ isActive: false, scheduledLesson });
  };
  return (
    <>
      <input
        disabled={isDeactivating}
        onChange={onChange}
        checked={isActive}
        type="checkbox"
        data-testid="disableToggle"
        className="toggle toggle-primary"
      />
      <ModalDisable
        visible={openModal}
        isRequesting={isDeactivating}
        selectedObjectName={formatLessonName(
          scheduledLesson.lesson,
          scheduledLesson,
        )}
        translationString="modalDisableScheduledLesson"
        onClickCancel={closeModal}
        onClickConfirm={onConfirmModal}
      />
    </>
  );
}

interface HandleViewProps {
  isLoading: boolean;
  error?: any;
  children: React.ReactNode;
}

function HandleView({ isLoading, error, children }: HandleViewProps) {
  const { t } = useTranslation('translation', { keyPrefix: 'klass.lessons' });
  if (isLoading) {
    return <LoadingView />;
  } else if (!!error) {
    return (
      <ErrorComponent
        statusCode={500}
        errorTextTitle={t('errorTextTitle')}
        errorTextSubTitle={t('errorTextSubTitle')}
      />
    );
  } else {
    return <React.Fragment>{children}</React.Fragment>;
  }
}
