import requester from '@/axios';
import {
  ChallengeActivity,
  TextActivityElement,
} from '@/models/ActivityElement';
import JsonApiResponse from '@/models/JsonApiResponse';

export interface UpdateChallengeProps {
  challengeId: number;
  lessonId: number;
}

//TODO: alterar para a rota retornar apenas 1 objeto
export const getChallengeActivity = async (lessonId: number) => {
  const { data } = await requester().get<JsonApiResponse<ChallengeActivity>>(
    `lessons/${lessonId}/challenge/`,
  );
  return data.results[0];
};

export const createChallengeActivity = async (lessonId: number) => {
  const { data: challenge } = await requester().post<ChallengeActivity>(
    `lessons/${lessonId}/challenge/`,
  );
  return challenge;
};

export interface ChallengeInputs {
  content: string;
}

export const createChallengeQuestion = async (
  challengeId: number,
  { content }: ChallengeInputs,
) => {
  const { data } = await requester().post<TextActivityElement>(
    'text-activity/',
    {
      activity: challengeId,
      title: 'Desafio',
      subtitle: 'Desafio',
      type: 'TEXT',
      identifier: `chu`,
      order: 1,
      content,
    },
  );
  return data;
};

export const updateChallenge = async ({
  lessonId,
  challengeId,
}: UpdateChallengeProps) => {
  const { data } = await requester().patch<ChallengeActivity>(
    `lessons/${lessonId}/challenge/${challengeId}/`,
  );
  return data;
};

export const deleteChallenge = async ({
  lessonId,
  challengeId,
}: UpdateChallengeProps) => {
  await requester().delete<ChallengeActivity>(
    `lessons/${lessonId}/challenge/${challengeId}/`,
  );
};
