import { ActivityTypeEnum } from '@/models/Activity';
import { Step } from '@/components/common/Steps/Steps';

export function handleStepIcon(type: ActivityTypeEnum) {
  const noAnswerTypes = [ActivityTypeEnum.TEXT, ActivityTypeEnum.VIDEO];
  if (!noAnswerTypes.includes(type)) {
    throw new Error('This activity has answer');
  }
  switch (type) {
    case ActivityTypeEnum.TEXT:
      return '📄';
    case ActivityTypeEnum.VIDEO:
      return '🎞️';
    default:
      return '';
  }
}

export const createStepByAttempt = (
  content: any,
  onClick?: (index: number) => void | Promise<void>,
): Step => {
  const common: Partial<Step> = { onClick };

  if ('answers' in content) {
    const { isCorrect } = content.answers;

    switch (content.type) {
      case ActivityTypeEnum.DND_ORDENATION:
      case ActivityTypeEnum.DND_POSITIONAL:
        const { gradePercent } = content.answers;

        const status = gradePercent === 1 ? 'success' : 'error';
        return {
          id: content?.order,
          status,
          caption: String(Math.trunc((gradePercent ?? 0) * 100)) + '%',
          ...common,
        };

      default:
        return {
          id: content?.order,
          status: isCorrect ? 'success' : 'error',
          ...common,
        };
    }
  }

  return {
    id: content?.order,
    status: 'info',
    content: handleStepIcon(content.type),
    ...common,
  };
};
