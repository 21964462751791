import { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { isEqual } from 'lodash';
import { useMutation } from '@tanstack/react-query';
import { motion } from 'framer-motion';
import moment from 'moment';

import { VacationNotice, VacationNoticeTab } from '@/models/VacationNotice';
import DateInput from '@/components/common/dataInput/DateInput';
import TextAreaInput from '@/components/common/dataInput/TextAreaInput';
import SaveCancelGroup from '@/components/common/buttons/SaveCancelGroup';
import {
  createVacationNotice,
  updateVacationNotice,
  VacationNoticeParams,
} from '@/data/services/vacationNoticeServices';
import alert from '@/utils/UseAlert';
import { getErrorMessage } from '@/utils/getErrorMessage';
import { buildChangedObject } from '@/utils/buildChangedObject';
import { fadeIn } from '@/utils/animations/commom';

export const mutateCreate = ({
  body,
}: Omit<VacationNoticeParams, 'vacationNoticeId'>) =>
  createVacationNotice(body);

export type VacationNoticeFormProps = {
  userId: number;
  vacationNotice?: VacationNotice;
  changeTab?(tab: VacationNoticeTab): void;
  updateData?(area: VacationNotice): void;
  invalidateData?(): Promise<void>;
};

export default function VacationNoticeForm({
  userId,
  vacationNotice: defaultValues,
  changeTab,
  updateData,
  invalidateData,
}: VacationNoticeFormProps) {
  const [t] = useTranslation('translation', {
    keyPrefix: 'common',
  });

  const [hasChanges, setHasChanges] = useState(false);

  type VacationNoticeFields = Omit<VacationNotice, 'id'>;

  const {
    handleSubmit,
    reset,
    watch,
    control,
    formState: { errors },
  } = useForm<VacationNoticeFields>({
    defaultValues,
  });

  const onCancel = () => {
    setHasChanges(false);
    reset();
    changeTab?.('view');
  };

  const onSuccess = async () => {
    changeTab?.('view');
    setHasChanges(false);
  };

  type UpdateDataKey = keyof Pick<
    VacationNoticeFields,
    'startDate' | 'endDate'
  >;

  const updateDate = (date: string, key: UpdateDataKey) => {
    const momentDate = moment(date);

    if (key === 'endDate') {
      momentDate.set({
        hour: 23,
        minutes: 59,
        seconds: 59,
      });
    }

    return momentDate.format('YYYY-MM-DDTHH:mm:ssZ');
  };

  const mutateUpdate = ({ vacationNoticeId, body }: VacationNoticeParams) =>
    updateVacationNotice(vacationNoticeId, body);

  const { mutate: update, isLoading: loadingUpdate } = useMutation(
    mutateUpdate,
    {
      onSuccess: async data => {
        alert.success(t('saved'));
        updateData?.(data);
        await onSuccess();
      },
      onError: error => {
        alert.error(getErrorMessage(error));
      },
    },
  );

  const { mutate: create, isLoading: loadingCreate } = useMutation(
    mutateCreate,
    {
      onSuccess: async () => {
        alert.success(t('saved'));
        await invalidateData?.();
        await onSuccess();
      },
      onError: error => {
        alert.error(getErrorMessage(error));
      },
    },
  );

  const onCreate = (body: VacationNoticeFields) => {
    const endDate = body.endDate
      ? body.endDate
      : updateDate(body.startDate, 'endDate');

    create({ body: { ...body, userId, endDate } });
  };

  const onUpdate = (changes: Omit<VacationNoticeFields, 'userId'>) => {
    if (defaultValues) {
      const vacationNoticeId = defaultValues.id;

      const changedFields = buildChangedObject<VacationNoticeFields>(
        defaultValues,
        changes,
      );

      if (Object.keys(changedFields).length)
        update({ vacationNoticeId, body: changedFields });
    }
  };

  const onSubmit = defaultValues ? onUpdate : onCreate;

  useEffect(() => {
    const subscribe = watch(value =>
      setHasChanges(!isEqual(value, defaultValues)),
    );
    return () => {
      subscribe.unsubscribe();
    };
  }, [defaultValues, watch]);

  const loading = loadingCreate || loadingUpdate;

  return (
    <motion.form
      {...fadeIn}
      className="flex flex-col gap-2.5 text-neutral text-[0.875rem]"
      onSubmit={handleSubmit(onSubmit)}
    >
      <section className="flex gap-2.5">
        <Controller
          control={control}
          name="startDate"
          rules={{
            required: t('form.required', {
              field: t('form.startDate.title').toLocaleLowerCase(),
            }),
          }}
          render={({ field: { onChange, value } }) => (
            <DateInput
              errorLabelText={errors.startDate?.message}
              label={t('form.startDate.title')}
              testId="startDate"
              value={value}
              className={{
                base: 'w-full gap-1',
                input: 'font-500',
                reference: 'w-full max-w-[50%] flex',
              }}
              onDateChange={date => onChange(updateDate(date, 'startDate'))}
            />
          )}
        />

        <Controller
          control={control}
          name="endDate"
          render={({ field: { onChange, value } }) => (
            <DateInput
              isOptional
              errorLabelText={errors.endDate?.message}
              label={t('form.endDate.title')}
              testId="endDate"
              value={value}
              className={{
                base: 'w-full gap-1',
                input: 'font-500',
                reference: 'w-full max-w-[50%] flex',
              }}
              onDateChange={date => onChange(updateDate(date, 'endDate'))}
            />
          )}
        />
      </section>
      <Controller
        control={control}
        name="reason"
        render={({ field: { onChange, value } }) => (
          <TextAreaInput
            isOptional
            testId="reason"
            onChange={onChange}
            value={value ?? ''}
            label={t('form.reason.title')}
            placeholder={t('form.reason.placeholder')}
            className={{
              base: 'gap-1',
              input: 'font-500',
              label: 'leading-none',
            }}
          />
        )}
      />
      <SaveCancelGroup
        className="self-end"
        loading={loading}
        save={{
          disabled: !hasChanges,
          testId: 'save',
        }}
        cancel={{
          onClick: onCancel,
          testId: 'cancel',
        }}
      />
    </motion.form>
  );
}
