import updatesRepository, {
  ConditionWhen,
  UpdatesRepository,
} from '@/functions/updatesRepository';
import { Updates } from '@/models/Updates';
import moment from 'moment';
import { useEffect } from 'react';

export type UseDeleteUpdates = {
  dueDate?: Date;
  keywords?: string[];
};

const useDeleteUpdates = (
  { dueDate, keywords }: UseDeleteUpdates,
  repository: UpdatesRepository = updatesRepository,
) => {
  useEffect(() => {
    const when: ConditionWhen<keyof Updates> = (_keyword, value) => {
      if (!value) return false;
      if (keywords?.includes(_keyword)) return true;
      if (dueDate) return moment(value.readAt).isBefore(dueDate);
      return false;
    };
    repository.remove({ when });
  }, [dueDate, keywords, repository]);
};

export default useDeleteUpdates;
