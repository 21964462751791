import { BaseFilters } from '@/models/Service';
import requester from '@/axios';
import JsonApiResponse from '@/models/JsonApiResponse';
import {
  TeacherLessonRating,
  StudentLessonRating,
  AverageStudentLessonRating,
} from '@/models/LessonRating';
import { CourseTypeEnum } from '@/models/Course';

export interface ListLessonRatingParams extends BaseFilters {
  courseId?: number[];
  lessonId?: number;
  klassId?: number;
  unitId?: number[];
  courseType?: CourseTypeEnum[];
  userId?: number;
  lessonRating?: number;
  rating?: number[];
  teacherRating?: number;
  ordering?: 'updated_at' | '-updated_at';
}

export const listTeacherLessonRating = async (
  params: ListLessonRatingParams = {},
) => {
  const { data } = await requester().get<JsonApiResponse<TeacherLessonRating>>(
    `teacher-lesson-rating/`,
    {
      params: {
        ...params,
        courseId: params.courseId?.toString(),
        unitId: params.unitId?.toString(),
      },
    },
  );
  return data;
};

export const createTeacherLessonRating = async (
  body: Omit<TeacherLessonRating, 'userId' | 'createdAt' | 'id'>,
) => {
  const { data: lessonRating } = await requester().post<TeacherLessonRating>(
    `teacher-lesson-rating/`,
    body,
  );
  return lessonRating;
};

export const updateTeacherLessonRating = async (
  lessonId: number,
  body: Partial<
    Omit<TeacherLessonRating, 'userId' | 'createdAt' | 'id' | 'lessonId'>
  >,
) => {
  const { data: lessonRating } = await requester().patch<TeacherLessonRating>(
    `teacher-lesson-rating/${lessonId}/`,
    body,
  );
  return lessonRating;
};

export const listStudentLessonRating = async (
  params: ListLessonRatingParams = {},
) => {
  const { data } = await requester().get<JsonApiResponse<StudentLessonRating>>(
    `student-lesson-rating/`,
    {
      params: {
        ...params,
        courseId: params.courseId?.toString(),
        unitId: params.unitId?.toString(),
      },
    },
  );
  return data;
};

export const createStudentLessonRating = async (
  body: Omit<StudentLessonRating, 'userId' | 'createdAt' | 'id' | 'klassName'>,
) => {
  const { data: lessonRating } = await requester().post<StudentLessonRating>(
    `student-lesson-rating/`,
    body,
  );
  return lessonRating;
};

export const updateStudentLessonRating = async (
  lessonId: number,
  body: Partial<
    Omit<StudentLessonRating, 'userId' | 'createdAt' | 'id' | 'lessonId'>
  >,
) => {
  const { data: lessonRating } = await requester().patch<StudentLessonRating>(
    `student-lesson-rating/${lessonId}/`,
    body,
  );
  return lessonRating;
};

export const getAverageStudentLessonRating = async (
  params: ListLessonRatingParams = {},
) => {
  const { data } = await requester().get<AverageStudentLessonRating>(
    `student-lesson-rating/average/`,
    {
      params: {
        ...params,
        courseId: params.courseId?.toString(),
        unitId: params.unitId?.toString(),
      },
    },
  );
  return data;
};
