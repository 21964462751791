import React, { ReactNode } from 'react';

type ControlViewProps<T extends string | number> = {
  view: T;
  renders: Record<T, ReactNode>;
};
function ControlView<T extends string | number>({
  view,
  renders,
}: ControlViewProps<T>) {
  return <React.Fragment>{renders[view]}</React.Fragment>;
}

export default ControlView;
