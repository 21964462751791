import { useState } from 'react';

const useStack = <T>() => {
  const [stack, setStack] = useState<T[]>([]);

  const add = (item: T) => {
    setStack([...stack, item]);
  };

  const remove = (itemId: T) => {
    setStack(stack.filter(id => id !== itemId));
  };

  const pop = () => {
    setStack(stack => {
      stack.pop();
      return [...stack];
    });
  };

  const getTop = () => {
    return stack.at(-1);
  };

  return { value: stack, add, remove, pop, getTop, setStack };
};

export default useStack;
