import { DNDItem } from './DNDItem';

export enum FlowColumnEnum {
  VERTICAL = 'VERTICAL',
  HORIZONTAL = 'HORIZONTAL',
}

export class DNDColumn {
  id?: number;
  name: string;
  flow: FlowColumnEnum;
  dragAndDropPositionalActivity?: number;
  items: DNDItem[];
  order: number;
  editType?: 'new' | 'edit' | 'remove';
  errors?: string[];

  constructor(
    name: string = '',
    flow: FlowColumnEnum = FlowColumnEnum.VERTICAL,
    items: DNDItem[] = [new DNDItem()],
    order: number = 1,
  ) {
    this.name = name;
    this.flow = flow;
    this.items = items;
    this.order = order;
    this.editType = 'new';
  }
}