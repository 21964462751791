import { AlertTypeEnum } from '@/models/Alert';
import { FilterIcon } from '@heroicons/react/solid';
import { forwardRef, InputHTMLAttributes } from 'react';
import { Control, useWatch } from 'react-hook-form';
import Popup from '../../common/Popup';
import MainButton from '../../common/buttons/MainButton';
import CheckGroup, { CheckOption } from '../../common/dataInput/CheckGroup';
import NotificationTag, { getAlertTagsStyles } from './NotificationTag';
import { useTranslation } from 'react-i18next';
import { NotificationFilterFields } from './NotificationTypes';

type NotificationTypeFilterProps = InputHTMLAttributes<HTMLInputElement> & {
  control?: Control<NotificationFilterFields>;
};

const NotificationTypeFilter = forwardRef<
  HTMLInputElement,
  NotificationTypeFilterProps
>(({ control, ...props }, ref) => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'alerts.filters',
  });
  const { t: tBase } = useTranslation('translation');
  const options: CheckOption[] = Object.values(AlertTypeEnum).map(type => ({
    value: type,
    label: <NotificationTag type={type} />,
  }));
  const { types } = useWatch({
    control,
    exact: true,
  });
  return (
    <Popup
      hover
      reference={
        <div className="flex gap-2 items-center">
          <MainButton
            color="custom"
            text={t('type').toLowerCase()}
            icon={<FilterIcon className="w-4 h-4" />}
            className="flex-row-reverse gap-1"
          />
          <ul className="flex gap-1.5">
            {types?.map(type => {
              const { icon: Icon, className } = getAlertTagsStyles(tBase)[type];
              return (
                <Icon className={`${className.text} w-4 h-4`} key={type} />
              );
            })}
          </ul>
        </div>
      }
    >
      <div className="p-2.5 bg-base-100 rounded-md shadow-default">
        <CheckGroup options={options} {...props} ref={ref} />
      </div>
    </Popup>
  );
});

export default NotificationTypeFilter;
