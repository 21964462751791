import React, { Fragment } from 'react';
import Text from '@/components/common/dataDisplay/Text';
import Skeleton from '@/components/common/Skeleton';
import { TextActivityElement } from '@/models/ActivityElement';
import { useTranslation } from 'react-i18next';
import ErrorComponent from '@/components/common/ErrorComponent';
import { HtmlPreview } from '@/components/common/dataDisplay/HtmlPreview';
import ConditionalRenderer from '@/components/common/ConditionalRenderer';

interface ChallengeContainerProps {
  challenge?: TextActivityElement;
  children: React.ReactNode;
  isLoading: boolean;
  isError: boolean;
}

function ChallengeContainer(props: ChallengeContainerProps) {
  return (
    <div className="rounded-xl bg-base-100 shadow-default   flex flex-col w-full pb-8">
      <ChallengePreview {...props} />
    </div>
  );
}

function ChallengePreview(props: ChallengeContainerProps) {
  const { isError, challenge, isLoading, children } = props;
  const { t } = useTranslation('translation', {
    keyPrefix: 'klassView.challengeView',
  });

  return (
    <Fragment>
      <div className="text-center pt-14">
        <Text
          text={t('title')}
          className="text-primary font-600 mb-5"
          format="poppins-600"
          id="text-title"
          size="text-28"
        />
      </div>
      <ConditionalRenderer condition={isLoading}>
        <Skeleton className="rounded-md w-[90%] h-10 m-auto bg-primary/40" />
      </ConditionalRenderer>

      <ConditionalRenderer condition={isError}>
        <ErrorComponent
          statusCode={404}
          errorTextSubTitle={t('errorTextSubTitle')}
          errorTextTitle={t('errorTextTitle')}
        />
      </ConditionalRenderer>

      <ConditionalRenderer condition={challenge}>
        <div className="px-10 flex flex-col gap-8">
          <HtmlPreview format="book" html={challenge?.content} />
          {children}
        </div>
      </ConditionalRenderer>
    </Fragment>
  );
}

export default ChallengeContainer;
