import React from 'react';
import IconButton from '../../../common/buttons/IconButton';
import { XIcon } from '@heroicons/react/outline';
import ConditionalRenderer from '@/components/common/ConditionalRenderer';

interface ActivityViewContainerProps {
  stepper: React.ReactNode;
  close?(): void;
  previewer: React.ReactNode;
}

function ActivityViewContainer({
  close,
  previewer,
  stepper,
}: ActivityViewContainerProps) {
  return (
    <div className="flex flex-col gap-8">
      <div className="relative flex justify-between">
        {stepper}
        <ConditionalRenderer condition={!!close}>
          <IconButton
            className="absolute -right-2.5 -top-2.5"
            testId="closeButton"
            id="close-button"
            onClick={close}
            icon={<XIcon className="text-secondary w-7" />}
          />
        </ConditionalRenderer>
      </div>
      {previewer}
    </div>
  );
}

export default ActivityViewContainer;
