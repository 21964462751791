import { DragDropContext, DropResult, Droppable } from '@hello-pangea/dnd';
import { DNDItem } from '@/models/DNDItem';
import { DNDColumn } from '@/models/DNDColumn';
import DNDPositionalDraggableItemEdit from './DNDPositionalDraggableItemEdit';
import TooltipHandler from '../../../../../common/TooltipHandler';
import { useTranslation } from 'react-i18next';
import useCourseEditing from '../../../../../../data/hook/useCourseEditing';
import MainButton from '../../../../../common/buttons/MainButton';
import { PlusIcon } from '@heroicons/react/outline';
import {
  DNDAttributesHandler,
  HandleRemoveItem,
} from '../DNDPositionalElementEdit';

export type ColumnType = 'question' | 'answer';

interface OnChangesFieldsItemParams {
  columnType: ColumnType;
  keyItem: string;
  value: string | File;
  item: DNDItem;
  index: number;
}

export type OnChangesFieldsItem = (params: OnChangesFieldsItemParams) => void;

interface DNDPositionalItemListEditProps {
  questionColumn: DNDColumn;
  answerColumn: DNDColumn;
  handleUpdateColumn: DNDAttributesHandler<DNDColumn>;
  handleRemoveItem: HandleRemoveItem;
  handleAddItem: DNDAttributesHandler<DNDColumn>;
  handleOrdenationItems: DNDAttributesHandler<DNDItem[]>;
  isLoading: boolean;
}

export function DNDPositionalItemListEdit(
  props: DNDPositionalItemListEditProps,
) {
  const {
    questionColumn,
    answerColumn,
    handleUpdateColumn,
    handleRemoveItem,
    handleAddItem,
    handleOrdenationItems,
    isLoading,
  } = props;

  const { allowBigChanges } = useCourseEditing();

  const questionColumnItems = questionColumn.items;

  const answerColumnItems = answerColumn.items;

  const { t } = useTranslation('translation', {
    keyPrefix: 'activity.manageActivity.dndPositionalElementEdit.dndColumnEdit',
  });

  const { t: tVersioningRules } = useTranslation('translation', {
    keyPrefix: 'adminPage.editCourseRules',
  });

  function handleOnDragEnd(result: DropResult) {
    if (!result.destination) return;

    const questionItemsAux = questionColumnItems;

    const answerItemsAux = answerColumn.items;

    const [reorderedQuestionItem] = questionItemsAux.splice(
      result.source.index,
      1,
    );

    const [reorderedAnswerItem] = answerItemsAux.splice(result.source.index, 1);

    questionItemsAux.splice(result.destination.index, 0, reorderedQuestionItem);

    answerItemsAux.splice(result.destination.index, 0, reorderedAnswerItem);

    handleOrdenationItems({
      question: questionItemsAux,
      answer: answerItemsAux,
    });
  }

  const onChangesFieldsItem: OnChangesFieldsItem = ({
    columnType,
    keyItem,
    value,
    item,
    index,
  }) => {
    const newItem = { ...item, [keyItem]: value };

    switch (columnType) {
      case 'question': {
        questionColumn.items.splice(index, 1, newItem);
        break;
      }
      case 'answer': {
        answerColumn.items.splice(index, 1, newItem);
        break;
      }
    }
    handleUpdateColumn({
      question: questionColumn,
      answer: answerColumn,
    });
  };

  const isBlockedDeleteItem = questionColumnItems.length <= 2;

  return (
    <div className={`flex flex-col gap-3 ${isLoading ? 'disabled' : ''}`}>
      <DragDropContext onDragEnd={handleOnDragEnd}>
        <Droppable droppableId="questions">
          {provided => (
            <div
              className="flex flex-col gap-5"
              {...provided.droppableProps}
              ref={provided.innerRef}
            >
              {questionColumnItems.map((item, index) => (
                <DNDPositionalDraggableItemEdit
                  draggableId={String(item.id || item.identifier)}
                  key={item.id ?? item.identifier}
                  question={item}
                  answer={answerColumnItems[index]}
                  onChangesFieldsItem={onChangesFieldsItem}
                  index={index}
                  isBlockedDeleteItem={isBlockedDeleteItem}
                  handleRemoveItem={handleRemoveItem}
                />
              ))}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>

      <div className="flex w-fit">
        <TooltipHandler
          tooltipMessage={tVersioningRules('cantModify')}
          renderTooltip={!allowBigChanges}
          className="w-fit"
        >
          <MainButton
            dataTestId="dndNewItemButton"
            icon={<PlusIcon />}
            color="secondary"
            onClick={() =>
              handleAddItem({ question: questionColumn, answer: answerColumn })
            }
            text={t('newItem')}
            disabled={!allowBigChanges || isLoading}
            loading={isLoading}
          />
        </TooltipHandler>
      </div>
    </div>
  );
}
