import {
  CheckIcon,
  ExclamationCircleIcon,
  XIcon,
} from '@heroicons/react/outline';
import React, { PropsWithChildren } from 'react';
import { statusTypes } from '../../../models/StatusTag';

type DivProps = React.DetailedHTMLProps<
  React.HTMLAttributes<HTMLDivElement>,
  HTMLDivElement
>;

interface StatusTagProps extends DivProps {
  status: statusTypes;
  costumIcon?: Partial<Record<statusTypes, JSX.Element>>;
}

interface TagContent {
  className: string;
  icon: JSX.Element;
}

const iconSize = 'w-4 h-4';

const tagContent: Record<statusTypes, TagContent> = {
  DANGER: {
    className: 'bg-error-content text-error',
    icon: <ExclamationCircleIcon className={iconSize} />,
  },
  OK: {
    className: 'bg-success text-base-100',
    icon: <CheckIcon className={iconSize} />,
  },
  WARNING: {
    className: 'bg-warning-content text-warning',
    icon: <XIcon className={iconSize} />,
  },
};

function StatusTag({
  status,
  children,
  costumIcon,
  className,
  ...props
}: PropsWithChildren<StatusTagProps>) {
  const { className: defaultClassName, icon } = tagContent[status];
  return (
    <div
      {...props}
      className={`px-2 py-1.5 rounded-md flex items-center gap-1 ${defaultClassName} ${className}`}
    >
      {costumIcon?.[status] ?? icon}
      {children}
    </div>
  );
}

export default StatusTag;
