import { EnrollmentStatusEnum } from '@/models/Enrollment';
import {
  lessonsQueryKeys,
  scheduledLessonReportsQueryKeys,
  scheduledLessonsQueryKeys,
} from '../services/querykeys';
import { REQUEST_STALE_TIME_IN_MS } from '@/constants';
import useInfiniteService from './useInfiniteService';
import { enrollmentValidation } from '@/utils/enrollmentValidation';
import useListService from './useListService';

type useStudentLessonsProps = {
  slugCourseName: string;
  enrollmentStatus: EnrollmentStatusEnum;
  klassId?: number;
};

export function useStudentLessons({
  slugCourseName,
  enrollmentStatus,
  klassId,
}: useStudentLessonsProps) {
  const validEnrollment = enrollmentValidation(enrollmentStatus);

  const {
    results: lessons,
    error: lessonsError,
    isInitialLoading: loadingLessons,
    hasNextPage: hasNextLessonsPage,
    isFetchingNextPage: fetchingNextLessonsPage,
    fetchNextPage: fetchNextLessonsPage,
  } = useInfiniteService({
    ...lessonsQueryKeys.nestedList(slugCourseName)._ctx.infinity,
    staleTime: REQUEST_STALE_TIME_IN_MS,
    enabled: !validEnrollment,
  });

  const {
    results: scheduledLessons,
    hasNextPage: hasNextScheduledLessonsPage,
    isFetchingNextPage: fetchingNextScheduledLessonsPage,
    isInitialLoading: loadingScheduledLessons,
    error: scheduledLessonsError,
    fetchNextPage: fetchNextScheduledLessonPage,
  } = useInfiniteService({
    ...scheduledLessonsQueryKeys.list({ klassId, isActive: true })._ctx
      .infinity,
    staleTime: REQUEST_STALE_TIME_IN_MS,
  });

  const { results: reports } = useListService({
    ...scheduledLessonReportsQueryKeys.list({
      pageSize: 50,
      klass: klassId,
      isActive: true,
    }),
    staleTime: REQUEST_STALE_TIME_IN_MS,
    enabled: !!klassId,
  });

  const hasNextPage = hasNextLessonsPage || hasNextScheduledLessonsPage;
  const isFetchingNextPage =
    fetchingNextLessonsPage || fetchingNextScheduledLessonsPage;
  const loading = loadingLessons || loadingScheduledLessons;
  const error = lessonsError || scheduledLessonsError;
  const fetchNextPage = fetchNextLessonsPage || fetchNextScheduledLessonPage;

  return {
    scheduledLessons,
    lessons,
    validEnrollment,
    hasNextLessonsPage,
    isFetchingNextPage,
    loading,
    error,
    fetchNextPage,
    hasNextPage,
    reports,
  };
}
