import requester from '@/axios';
import JsonApiResponse from '@/models/JsonApiResponse';
import ScheduledLessonReport, {
  ScheduledLessonReportEnum,
} from '@/models/ScheduledLessonReport';

type StudentReportPayload = Partial<Omit<ScheduledLessonReport, 'id'>>;

export const createStudentReport = async (create: StudentReportPayload) => {
  const { data } = await requester().post<ScheduledLessonReport>(
    'scheduled-lesson-reports/',
    create,
  );
  return data;
};

export type UpdateReport = {
  id: number;
  changes: StudentReportPayload;
};

export const updateStudentReport = async ({ id, changes }: UpdateReport) => {
  const { data } = await requester().patch<ScheduledLessonReport>(
    `scheduled-lesson-reports/${id}/`,
    changes,
  );
  return data;
};

export type ScheduledLessonReportFiltersParams = {
  student?: number;
  scheduledLesson?: number;
  isActive?: boolean;
  pageSize?: number;
  pageNumber?: number;
  klass?: number;
  dateRangeBefore?: string;
  dateRangeAfter?: string;
  ordering?: 'datetime' | '-datetime';
  hasDone?: boolean;
  status?: ScheduledLessonReportEnum[];
};

export async function listScheduledLessonReports(
  params?: ScheduledLessonReportFiltersParams,
) {
  const { data } = await requester().get<
    JsonApiResponse<ScheduledLessonReport>
  >(`scheduled-lesson-reports/`, {
    params,
  });

  return data;
}
