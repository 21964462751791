import { DNDPositionalActivity } from '../../../../../../models/ActivityElement';
import ActivityHeading from '../../ActivityHeading';
import { useTranslation } from 'react-i18next';
import DNDPositionalColumn from './DNDPositionalColumn';

interface DNDPositionalColumnsProps {
  dndPositionalActivity: DNDPositionalActivity;
}

export default function DNDPositionalColumns({
  dndPositionalActivity,
}: DNDPositionalColumnsProps) {
  const { questionColumn, answerColumn, title, subtitle } =
    dndPositionalActivity;
  const { t } = useTranslation('translation', {
    keyPrefix: 'lesson.activity.dragAndDropPositional',
  });

  return (
    <div className="flex flex-col gap-8 items-center">
      <ActivityHeading
        heading={title}
        subheading={t('instruction')}
        content={subtitle}
      />
      <div className="grid grid-cols-2 gap-10 w-full md:max-w-[80%]">
        <DNDPositionalColumn dndPositionalColumn={questionColumn} />
        <DNDPositionalColumn dndPositionalColumn={answerColumn} />
      </div>
    </div>
  );
}
