import InfiniteSearchInput from '@/components/common/dataInput/InfiniteSearchInput';
import { createLesson } from '@/data/services/lessonServices';
import {
  createExtraScheduledLesson,
  ScheduledLessonFilters,
  updateScheduledLesson,
} from '@/data/services/scheduledLessonsServices';
import { formatLessonName } from '@/functions/lessonsName';
import { ApiError } from '@/models/Errors';
import { Lesson } from '@/models/Lesson';
import alert from '@/utils/UseAlert';
import { useMutation } from '@tanstack/react-query';
import { Control, useController, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import RoundedButton from '../../common/buttons/RoundedButton';
import Text from '../../common/dataDisplay/Text';
import Modal from '../../common/modals/Modal';
import { ScheduledLessonTypeEnum } from '@/models/ScheduledLesson';
import { scheduledLessonsQueryKeys } from '@/data/services/querykeys';
import { Fragment, PropsWithChildren, useEffect } from 'react';
import useInfiniteService from '@/data/hook/useInfiniteService';
import useListService from '@/data/hook/useListService';
import ConditionalRenderer from '@/components/common/ConditionalRenderer';
import { LoadingIcon } from '@/components/icons';
import { REQUEST_STALE_TIME_IN_MS } from '@/constants';

interface AddExtraLessonModalProps {
  onCancel(): void;
  isVisible?: boolean;
  klassId: number;
  lesson?: Lesson;
  onError?(error: ApiError, extraScheduledLesson: number): void;
  updateScheduledLessons?(): Promise<void>;
}

interface SelectScheduledLesson {
  scheduledLessonId: string;
}

export default function AddExtraLessonModal({
  onCancel,
  isVisible,
  klassId,
  lesson,
  onError,
  updateScheduledLessons,
}: AddExtraLessonModalProps) {
  const { t } = useTranslation('translation', {
    keyPrefix: 'coursesView.admin.addExtraLesson',
  });

  const { control, handleSubmit, watch, resetField } =
    useForm<SelectScheduledLesson>();

  const onAssignLessonInScheduledLesson = async ({
    extraScheduledLessonId,
    lessonId,
  }: {
    extraScheduledLessonId: number;
    lessonId: number;
  }) => {
    await updateScheduledLesson(extraScheduledLessonId, {
      lessonId,
    });
  };

  const { mutateAsync: assignLesson } = useMutation(
    onAssignLessonInScheduledLesson,
    {
      onError(error: any, variables) {
        const apiError = new ApiError(error);
        alert.error(apiError.getErrorMessage());
        onError?.(apiError, variables.extraScheduledLessonId);
      },
    },
  );

  const handleCreateExtraLesson = async ({
    scheduledLessonId: id,
  }: SelectScheduledLesson) => {
    const scheduledLessonId = +id;
    const { id: extraScheduledLessonId } = await createExtraScheduledLesson(
      {
        klassId,
        scheduledLessonId,
      },
      {
        type: !!lesson
          ? ScheduledLessonTypeEnum.EXTRA
          : ScheduledLessonTypeEnum.FREE,
      },
    );
    if (!lesson)
      await createLesson({
        scheduledLessonId: extraScheduledLessonId,
        name: t('newLesson'),
        motivation: {
          message: '',
        },
      });
    else
      await assignLesson({
        extraScheduledLessonId,
        lessonId: lesson.id,
      });
  };

  const { isLoading, mutate } = useMutation(handleCreateExtraLesson, {
    async onSuccess() {
      resetField('scheduledLessonId');
      await updateScheduledLessons?.();
    },
    onError(error: any) {
      const apiError = new ApiError(error);
      alert.error(apiError.getErrorMessage());
    },
  });

  const onSubmit = (data: SelectScheduledLesson) => mutate(data);

  return (
    <Modal visible={!!isVisible} onClose={onCancel}>
      <form
        data-testid="selectLessonForm"
        className="flex flex-col gap-6"
        onSubmit={handleSubmit(onSubmit)}
      >
        <ModalContent
          control={control}
          klassId={klassId}
          enabled={!!isVisible && !isNaN(klassId)}
        />

        <div className="flex gap-6">
          <RoundedButton
            type="button"
            onClick={onCancel}
            text={t('cancel')}
            color="neutral"
            className="w-full"
          />
          <RoundedButton
            loading={isLoading}
            testId="submitExtraLesson"
            text={t('add')}
            color="gradient"
            disabled={!watch('scheduledLessonId')}
            className="w-full"
          />
        </div>
      </form>
    </Modal>
  );
}

type ModalContentProps = {
  klassId: number;
  control: Control<SelectScheduledLesson>;
  enabled: boolean;
};

function ModalContent({ control, klassId, enabled }: ModalContentProps) {
  const { t } = useTranslation('translation', {
    keyPrefix: 'coursesView.admin.addExtraLesson',
  });
  const {
    field: { onChange },
  } = useController({ control, name: 'scheduledLessonId' });

  const scheduledLessonFilters: ScheduledLessonFilters = {
    isActive: true,
    hasDone: false,
    klassId,
  };

  const { results, isSuccess, isInitialLoading } = useInfiniteService({
    ...scheduledLessonsQueryKeys.list(scheduledLessonFilters)._ctx.infinity,
    enabled: false,
  });

  const shouldGetLastLesson = isSuccess && results.length === 0;

  const {
    results: hasDoneScheduledLessons,
    isInitialLoading: isLoadingLastLesson,
  } = useListService({
    ...scheduledLessonsQueryKeys.list({
      hasDone: true,
      ordering: '-datetime',
      isActive: true,
      klassId,
      pageSize: 1,
    }),
    enabled: shouldGetLastLesson,
  });

  const lastScheduledLesson = hasDoneScheduledLessons.at(0);

  const title = shouldGetLastLesson
    ? 'Todas as aulas da turma estão concluídas'
    : t('title');

  useEffect(() => {
    if (lastScheduledLesson) {
      onChange(lastScheduledLesson.id);
    }

    return () => onChange(undefined);
  }, [lastScheduledLesson, onChange]);

  const onDeselect = () => {
    onChange(undefined);
  };

  return (
    <Fragment>
      <Text
        text={title}
        className="text-primary"
        format="rubik-500"
        size="text-20"
      />

      <ConditionalRenderer
        condition={!shouldGetLastLesson}
        fallback={
          <ConditionalLoadingReder condition={!isLoadingLastLesson}>
            <div className="flex flex-col gap-1.5">
              <Text text={'Deseja adicionar uma aula estra após a aula'} />
              {lastScheduledLesson ? (
                <Text
                  text={formatLessonName(
                    lastScheduledLesson.lesson,
                    lastScheduledLesson,
                  )}
                />
              ) : null}
            </div>
          </ConditionalLoadingReder>
        }
      >
        <ConditionalLoadingReder condition={!isInitialLoading}>
          <Text text={t('selectLessonExplain')} />
          <InfiniteSearchInput
            onDeselect={onDeselect}
            service={scheduledLessonsQueryKeys.list}
            onSelect={({ id }) => onChange(id)}
            displayName={scheduledLesson =>
              formatLessonName(scheduledLesson.lesson, scheduledLesson)
            }
            input={{
              testId: 'scheduledLessonSelect',
              placeholder: t('selectLesson'),
            }}
            filters={scheduledLessonFilters}
            options={{ enabled, staleTime: REQUEST_STALE_TIME_IN_MS }}
            className={{ base: 'w-full flex self-center' }}
          />
        </ConditionalLoadingReder>
      </ConditionalRenderer>
    </Fragment>
  );
}

type ConditionalLoadingRederProps = {
  condition: boolean;
};

function ConditionalLoadingReder({
  condition,
  children,
}: PropsWithChildren<ConditionalLoadingRederProps>) {
  return (
    <ConditionalRenderer
      condition={condition}
      fallback={<LoadingIcon className="w-14 mx-auto text-primary" />}
    >
      {children}
    </ConditionalRenderer>
  );
}
