import { DNDColumn } from '../../../../../../models/DNDColumn';
import ConditionalRenderer from '../../../../../common/ConditionalRenderer';
import Text from '../../../../../common/dataDisplay/Text';
import DNDPositionalItem from './DNDPositionalItem';

interface DNDPositionalColumnProps {
  dndPositionalColumn: DNDColumn;
}

export default function DNDPositionalColumn({
  dndPositionalColumn,
}: DNDPositionalColumnProps) {
  return (
    <div className="flex flex-col gap-3 items-center w-full">
      <ConditionalRenderer condition={!!dndPositionalColumn.name}>
        <Text
          format="rubik-500"
          className="text-accent"
          text={dndPositionalColumn.name}
        />
      </ConditionalRenderer>
      <div className="flex flex-col justify-around w-full gap-3">
        {dndPositionalColumn.items.map(questionItem => {
          return (
            <DNDPositionalItem
              key={questionItem.id}
              dndPositionalItem={questionItem}
            />
          );
        })}
      </div>
    </div>
  );
}
