import { useState } from 'react';
import { useQueryClient } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';
import Text from '@/components/common/dataDisplay/Text';
import { Coin, Gem } from '@/components/icons';
import RoundedButton from '@/components/common/buttons/RoundedButton';
import Modal from '@/components/common/modals/Modal';
import Tag from '@/components/common/dataDisplay/Tag';
import AvatarItem from './AvatarItem';
import { AvatarItemsEnum, ShopItem } from '@/models/Avatar';
import { RewardEnum } from '@/models/Rewards';
import { postShopPurchase } from '@/data/services/avatarServices';
import alert from '@/utils/UseAlert';
import useStudentContext from '@/data/hook/student';
import ConditionalRenderer from '@/components/common/ConditionalRenderer';
import AvatarColorPicker from './AvatarColorPicker';
import {
  avatarInventoriesQueryKeys,
  rewardsQueryKeys,
} from '@/data/services/querykeys';
import ConditionalWrapper from '@/components/common/ConditionalWrapper';
import { Tooltip } from '@/components/common/dataDisplay/Tooltip';
import { EventTrack } from '@/models/EventTrack';
import { eventTrack } from '@/functions/eventTrack';

export default function PurchaseModal({
  onEquip,
  onClose,
  visible,
  category,
  item,
}: {
  onClose: () => void;
  onEquip: (shouldEquip: boolean, color: string) => void;
  visible: boolean;
  category: AvatarItemsEnum;
  item: ShopItem;
}) {
  const queryClient = useQueryClient();
  const { rewardsData } = useStudentContext();
  const { t } = useTranslation('translation', {
    keyPrefix: 'inventory',
  });
  const [equipItem, setEquipItem] = useState(false);
  const [isPostingPurchase, setIsPostingPurchase] = useState(false);
  const [purchaseType, setPurchaseType] = useState<RewardEnum>(
    item.coinsPrice ? RewardEnum.COIN : RewardEnum.GEM,
  );
  const isCoinType = purchaseType === RewardEnum.COIN;
  const [color, setColor] = useState(item.item.baseColor);

  const hasEnoughCash = (purchaseType: RewardEnum) => {
    if (rewardsData) {
      if (purchaseType === RewardEnum.COIN && item.coinsPrice) {
        return rewardsData.coins >= item.coinsPrice;
      } else if (purchaseType === RewardEnum.GEM && item.gemsPrice) {
        return rewardsData.gems >= item.gemsPrice;
      }
    } else return false;
  };

  const handleSetType = (type: RewardEnum) => {
    if (type === RewardEnum.COIN && hasEnoughCash(type)) {
      setPurchaseType(type);
    } else if (type === RewardEnum.GEM && hasEnoughCash(type)) {
      setPurchaseType(type);
    } else return null;
  };

  const rewardsQueryKey = rewardsQueryKeys._def;
  const avatarInventoryQueryKey = avatarInventoriesQueryKeys.get._def;

  const handlePurchase = async (purchaseType: RewardEnum) => {
    try {
      setIsPostingPurchase(true);
      await postShopPurchase(item.id, purchaseType);
      onEquip(equipItem, color);
      await queryClient.invalidateQueries(rewardsQueryKey);
      await queryClient.invalidateQueries(avatarInventoryQueryKey);
      alert.success(t('purchaseSuccess'));

      const event: EventTrack = {
        category: `${item.item.name} - ShopItemId${item.id}`,
        action: 'Shop Purchase',
      };

      eventTrack(event);

      onClose();
    } catch (error) {
      alert.error(t('purchaseError'));
    } finally {
      setIsPostingPurchase(false);
    }
  };

  const hasEnoughCoin = hasEnoughCash(RewardEnum.COIN);
  const hasEnoughGem = hasEnoughCash(RewardEnum.GEM);

  return (
    <Modal visible={visible} onClose={onClose}>
      <div className="gap-6 flex flex-col items-center">
        <Text
          format="poppins-600"
          className="text-primary text-20"
          text={t('confirmPurchase')}
        />
        <div className="flex items-center gap-2">
          <div className="bg-warning-content h-20 aspect-square overflow-visible rounded-2xl border-2 border-warning relative">
            <AvatarItem
              relative
              color={color}
              category={category}
              img={item.item.image}
            />
            <AvatarColorPicker
              onChange={setColor}
              color={color}
              item={item.item}
            />
          </div>
          <div className="flex flex-col gap-2">
            <div className="flex text-16 gap-1">
              <ConditionalRenderer condition={!!item.coinsPrice}>
                <ConditionalWrapper
                  wrapper={
                    <Tooltip className="w-32 z-50" text={t('notEnoughCash')} />
                  }
                  condition={!hasEnoughCoin}
                >
                  <Tag
                    testId="purchaseCoin"
                    onClick={() => handleSetType(RewardEnum.COIN)}
                    color="custom"
                    icon={<Coin className="w-5 h-5" />}
                    text={`${item.coinsPrice}`}
                    className={`flex px-3 py-0.5 ${
                      hasEnoughCoin ? 'cursor-pointer' : 'opacity-50'
                    } rounded-full font-500 flex-row-reverse gap-1 hover:ring transition ease-in-out duration-300 ring-yellow-100 border border-yellow-400 ${
                      isCoinType
                        ? 'bg-yellow-400 text-base-100'
                        : 'bg-base-100 text-yellow-500'
                    }`}
                  />
                </ConditionalWrapper>
              </ConditionalRenderer>
              <ConditionalRenderer condition={!!item.gemsPrice}>
                <ConditionalWrapper
                  wrapper={
                    <Tooltip className="w-32 z-50" text={t('notEnoughCash')} />
                  }
                  condition={!hasEnoughGem}
                >
                  <Tag
                    testId="purchaseGem"
                    color="custom"
                    onClick={() => handleSetType(RewardEnum.GEM)}
                    icon={<Gem className="w-5 h-5" />}
                    text={`${item.gemsPrice}`}
                    className={`flex px-3 py-0.5 ${
                      hasEnoughGem ? 'cursor-pointer' : 'opacity-50'
                    } rounded-full font-500 flex-row-reverse gap-1 hover:ring transition ease-in-out duration-300 ring-success-content border border-success ${
                      isCoinType
                        ? 'bg-base-100 text-success'
                        : 'bg-success text-base-100'
                    }`}
                  />
                </ConditionalWrapper>
              </ConditionalRenderer>
            </div>
            <div className="flex gap-1 items-center">
              <input
                data-testid="equipItem"
                onChange={() => setEquipItem(!equipItem)}
                type="checkbox"
                className="checkbox checkbox-primary checkbox-sm border-primary rounded-md disabled:opacity-10"
              />
              <Text text={t('equipItem')} />
            </div>
          </div>
        </div>
        <div className="flex w-full gap-2 space-between">
          <RoundedButton
            onClick={onClose}
            className="w-full"
            color="neutral"
            text={t('cancel')}
          />
          <RoundedButton
            testId="confirmPurchase"
            className="w-full"
            onClick={() => handlePurchase(purchaseType)}
            loading={isPostingPurchase}
            text={t('confirm')}
          />
        </div>
      </div>
    </Modal>
  );
}
