import { useState } from 'react';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { useQuery } from '@tanstack/react-query';
import { twMerge } from 'tailwind-merge';

import ComponentGuard from '@/components/common/ComponentGuard';
import ConditionalRenderer from '@/components/common/ConditionalRenderer';
import Skeleton from '@/components/common/Skeleton';
import Tag from '@/components/common/dataDisplay/Tag';
import { Tooltip } from '@/components/common/dataDisplay/Tooltip';
import { REQUEST_STALE_TIME_IN_MS } from '@/constants';
import useAuth from '@/data/hook/useAuth';
import { klassesQueryKeys, usersQueryKeys } from '@/data/services/querykeys';
import { formatLessonName } from '@/functions/lessonsName';
import { KlassTypeEnum } from '@/models/Klass';
import ScheduledLesson from '@/models/ScheduledLesson';
import { UserTypeEnum } from '@/models/User';
import ModalHandler, {
  ModalState,
} from '@/components/admin/calendar/ModalHandler';
import { ROUTES } from '@/utils/routes';
import { PERMISSIONS } from '@/utils/permissions';
import { AlterDatePill } from '@/components/admin/calendar/ManageScheduledLesson/AlterDatePill';
import { AttendancePill } from '@/components/admin/calendar/ManageScheduledLesson/AttendancePill';
import { ProgressPill } from '@/components/admin/calendar/ManageScheduledLesson/ProgressPill';
import { AnticipatePostponePill } from '@/components/admin/calendar/ManageScheduledLesson/AnticipatePostponePill';
import { TeacherPill } from '@/components/admin/calendar/ManageScheduledLesson/TeacherPill';
import { isTeacher } from '@/functions/auth';
import StudentsPopup from '../../../staff/StudentsPopup';
import { AlterLinkButton } from './AlterLinkButton';
import { ReportButton } from './ReportButton';
import { StatusIcon } from './StatusIcon';
import { EditButton } from './EditButton';
import SimpleTextIcon from '@/components/common/SimpleTextIcon';
import useManageScheduledLesson from '@/data/hook/useManageScheduledLesson';
import KlassGroupButton from './KlassGroupButton';

export type ManageScheduledLessonProps = {
  scheduledLesson: ScheduledLesson;
  updateLessons?: () => Promise<void>;
  state?: 'view' | 'manager';
};

export type StudentProgress = {
  student: string;
  progress: number;
  activitiesNotDone: number;
  chaptersUnread: number;
  presence: null | boolean;
};

export default function ManageScheduledLesson(
  props: ManageScheduledLessonProps,
) {
  const {
    progress,
    lesson,
    klass: klassId,
    hasDone,
    datetime,
    presences,
    absences,
    url,
    isActive,
    teacher: teacherId,
  } = props.scheduledLesson;
  const { state = 'manager', updateLessons, scheduledLesson } = props;

  const { user } = useAuth();

  const { data: teacher, isInitialLoading: loadingTeacher } = useQuery({
    ...usersQueryKeys.get(teacherId),
    enabled: !!teacherId,
    staleTime: REQUEST_STALE_TIME_IN_MS,
  });

  const { data: klass, isInitialLoading: loadingKlass } = useQuery({
    ...klassesQueryKeys.get(klassId),
    enabled: !!klassId,
    staleTime: REQUEST_STALE_TIME_IN_MS,
  });

  const [editing, setEditing] = useState(false);

  const [modalState, setModalState] = useState<ModalState>(ModalState.CLOSED);

  const { t } = useTranslation('translation', {
    keyPrefix: 'calendar.admin.manageKlass',
  });

  const { t: tCommon } = useTranslation('translation', {
    keyPrefix: 'common',
  });

  const {
    isStartingSoon,
    isPublished,
    editPermission,
    anticipatePermission,
    bankNotPublished,
  } = useManageScheduledLesson({
    scheduledLesson,
    klass,
    user,
    state,
  });

  const stateColor = hasDone
    ? 'border-success bg-success-content'
    : 'border-secondary bg-secondary-content';

  const disabledColor = isActive ? '' : 'opacity-20 pointer-events-none';

  const lessonName = formatLessonName(lesson, props.scheduledLesson);

  const onChangeModal = (state: ModalState) => setModalState(state);

  const loading = loadingKlass;

  if (loading)
    return (
      <Skeleton
        testId="loading"
        className="w-full rounded-lg min-h-20 bg-primary-content"
      />
    );

  if (klass)
    return (
      <li
        data-testid="manageScheduledLesson"
        className={`flex items-center border rounded-lg p-3.5 gap-1 w-full
        ${stateColor} ${disabledColor}`}
      >
        <StatusIcon hasDone={hasDone} render={state === 'manager'} />

        <div className="flex flex-col gap-1.5 md:gap-0.5 w-full overflow-hidden">
          <div
            className={twMerge(
              'flex gap-x-2.5 gap-y-1 items-start justify-between flex-col overflow-hidden w-full h-full',
              state === 'manager' ? 'xl:flex-row xl:items-center' : '',
            )}
          >
            <div className="flex gap-1 overflow-hidden w-full">
              <SimpleTextIcon
                testId="lessonLink"
                title={lessonName}
                href={ROUTES.COURSES.LESSON.INFO(
                  klass.coursePathSlug,
                  lesson.id,
                  klass.id,
                )}
                className={{
                  text: 'capitalize text-14 xl:text-16 truncate',
                  section: 'max-w-full overflow-hidden',
                  tooltip: 'overflow-hidden',
                }}
                tooltip
              />
            </div>

            <div
              className={twMerge(
                'gap-x-2.5 gap-y-0.5 flex items-start md:items-center flex-col md:flex-row w-full',
                state === 'manager' ? 'xl:justify-end' : '',
              )}
            >
              <div className="flex gap-x-2.5 gap-y-1 overflow-hidden justify-start w-full md:w-fit items-center">
                <SimpleTextIcon
                  testId="klassLink"
                  title={klass.name}
                  href={ROUTES.ADMIN.CLASSES.INFO({
                    name: klass.name.replace('#', ''),
                  })}
                  className={{
                    text: 'capitalize text-14 xl:text-16 truncate',
                    section: 'w-full overflow-hidden',
                    tooltip: 'overflow-hidden',
                  }}
                  tooltip
                />

                {/* TODO: release rendering when network can add class group link */}
                <ConditionalRenderer condition={false}>
                  <KlassGroupButton klassId={klassId} />
                </ConditionalRenderer>

                <ConditionalRenderer
                  condition={klass.klassType === KlassTypeEnum.CONECTA}
                >
                  <Tag
                    color="custom"
                    text="conecta"
                    className="bg-info font-500 font-rubik py-0.5 px-1 text-base-100 text-[0.875rem]"
                  />
                </ConditionalRenderer>
              </div>
              <div className="flex gap-x-1.5 gap-y-1 items-center justify-start shrink-0">
                <StudentsPopup
                  klass={klass}
                  scheduledLesson={scheduledLesson}
                />
                <AlterLinkButton
                  url={url ?? klass.classRoomUrl}
                  editing={editing}
                  render={!hasDone}
                  onChangeModal={onChangeModal}
                />
                <EditButton
                  render={editPermission && !hasDone && state === 'manager'}
                  editing={editing}
                  onEditing={setEditing}
                />
              </div>
            </div>
          </div>

          <div className="flex justify-between items-center flex-wrap gap-x-2.5 gap-y-1">
            <AlterDatePill
              dateTime={moment(datetime).toDate()}
              klassDuration={klass?.duration}
              editing={editing}
              setModalState={() => setModalState(ModalState.KLASS_DATE)}
              editPermission={editPermission && !isTeacher(user?.userType)}
            />
            <ConditionalRenderer condition={hasDone}>
              <AttendancePill
                presences={presences}
                absences={absences}
                studentsCount={klass.enrollsCount}
              />

              <ConditionalRenderer condition={state === 'manager'}>
                <ProgressPill progress={progress} />
              </ConditionalRenderer>
            </ConditionalRenderer>

            <ConditionalRenderer condition={editing && !hasDone}>
              <div className="flex gap-2">
                <ComponentGuard
                  roles={[UserTypeEnum.SUPER_ADMIN, UserTypeEnum.UNIT_ADMIN]}
                >
                  <Tooltip
                    text={
                      !anticipatePermission ? t('doNotAnticipateTooltip') : ''
                    }
                  >
                    <AnticipatePostponePill
                      state={ModalState.ANTICIPATE}
                      setModalState={() => setModalState(ModalState.ANTICIPATE)}
                      isDisable={!anticipatePermission}
                    />
                  </Tooltip>
                </ComponentGuard>

                <ComponentGuard
                  roles={[UserTypeEnum.SUPER_ADMIN, UserTypeEnum.UNIT_ADMIN]}
                >
                  <AnticipatePostponePill
                    state={ModalState.POSTPONE}
                    setModalState={() => setModalState(ModalState.POSTPONE)}
                    isDisable={false}
                  />
                </ComponentGuard>
              </div>
            </ConditionalRenderer>

            <ConditionalRenderer condition={isStartingSoon}>
              <Tag
                color="warning"
                className="py-0.25 px-1 text-[0.875rem] gap-1 lowercase"
              >
                {t('classStartsSoon')}
              </Tag>
            </ConditionalRenderer>

            <ReportButton
              render={isPublished}
              klass={klass}
              scheduledLesson={props.scheduledLesson}
              disabled={
                !PERMISSIONS.SCHEDULED_LESSON_REPORT.ACCESS({
                  klass,
                  user,
                })
              }
            />

            <ConditionalRenderer condition={!isPublished || bankNotPublished}>
              <Tag
                color="warning"
                text={tCommon('lessonUnpublished')}
                className="text-12 md:text-14"
              />
            </ConditionalRenderer>

            <ConditionalRenderer condition={state === 'manager'}>
              <ComponentGuard
                roles={[UserTypeEnum.SUPER_ADMIN, UserTypeEnum.UNIT_ADMIN]}
              >
                <TeacherPill
                  loading={loadingTeacher}
                  teacher={teacher}
                  setModalState={() => setModalState(ModalState.TEACHER)}
                  editing={editing}
                  editPermission={editPermission}
                />
              </ComponentGuard>
            </ConditionalRenderer>
          </div>
        </div>

        <ComponentGuard
          roles={[
            UserTypeEnum.SUPER_ADMIN,
            UserTypeEnum.UNIT_ADMIN,
            UserTypeEnum.TEACHER,
          ]}
        >
          <ModalHandler
            updateLessons={updateLessons}
            scheduledLesson={props.scheduledLesson}
            klassInfo={klass}
            modalState={modalState}
            teacherInfo={teacher}
            closeModal={() => setModalState(ModalState.CLOSED)}
            redirectToModal={setModalState}
          />
        </ComponentGuard>
      </li>
    );

  return null;
}
