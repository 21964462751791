import HeadTitle from '@/components/common/HeadTitle';
import PageTitle from '@/components/common/PageTitle';
import Layout from '@/components/template/Layout';
import { Fragment } from 'react/jsx-runtime';

import TopicsPageContent from '@/components/topics/TopicsPageContent';
import { useTranslation } from 'react-i18next';
import NoAdminScreenForMobile from './NoAdminScreenForMobile';

export default function TopicsPage() {
  const { t } = useTranslation('translation', {
    keyPrefix: 'topics',
  });
  return (
    <Fragment>
      <HeadTitle routeInfo={t('pageTitle')} />

      <Layout>
        <div className="md:hidden">
          <NoAdminScreenForMobile />
        </div>
        <div className="hidden md:block w-full">
          <PageTitle headingText={t('pageTitle')} backButton position="mb-3" />
          <TopicsPageContent />
        </div>
      </Layout>
    </Fragment>
  );
}
