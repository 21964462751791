import requester from '@/axios';
import JsonApiResponse from '@/models/JsonApiResponse';
import { PedagogicalSheet } from '@/models/PedagogicalSheet';
import { BaseFilters, ListServiceFunc } from '@/models/Service';

export type ListPedagogicalSheet = BaseFilters & {
  studentId?: number;
};

export const listPedagogicalSheets: ListServiceFunc<
  PedagogicalSheet,
  ListPedagogicalSheet
> = async (params = {}) => {
  const { data } = await requester().get<JsonApiResponse<PedagogicalSheet>>(
    'pedagogical-sheets/',
    {
      params,
    },
  );

  return data;
};
