import { InformationCircleIcon } from '@heroicons/react/outline';
import { useTranslation } from 'react-i18next';
import PillComponent from '@/components/common/dataDisplay/PillComponent';
import Text from '@/components/common/dataDisplay/Text';
import Popup from '@/components/common/Popup';

type AttendancePillProps = {
  presences: number;
  absences: number;
  studentsCount: number;
};

export function AttendancePill({
  presences,
  absences,
  studentsCount,
}: AttendancePillProps) {
  const { t } = useTranslation('translation', {
    keyPrefix: 'calendar.admin.manageKlass',
  });

  const attendance = [
    {
      title: t('manageAttendance.attendances'),
      total: presences,
    },
    {
      title: t('manageAttendance.absences'),
      total: absences,
    },
  ];

  return (
    <PillComponent className="gap-2">
      <div className="flex content-center items-center align-middle gap-1 h-5">
        <Text text={t('attendance')} format="rubik-500" />
        <Text
          text={presences + ' / ' + studentsCount}
          format="rubik-400"
          className="text-base-content self-center"
        />
      </div>
      <Popup
        hover
        className={{ popup: 'z-10' }}
        reference={
          <InformationCircleIcon className="w-4 h-4 text-primary cursor-pointer" />
        }
      >
        <div className="flex flex-col gap-1 bg-base-100 rounded-xl p-2 border border-neutral-content shadow-default">
          {attendance.map(({ title, total }) => (
            <div className="flex gap-3" key={title}>
              <Text text={title} format="rubik-500" className="text-primary" />
              <Text text={total.toString()} format="rubik-400" />
            </div>
          ))}
        </div>
      </Popup>
    </PillComponent>
  );
}
