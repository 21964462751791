import React, { SVGProps } from 'react';
import Text from '../common/dataDisplay/Text';

type Sizes = 'text-12' | 'text-18';

interface ClassNameSize {
  text: `text-${12 | 18}`;
  icon: `w-${3 | 5}`;
}

const contentSize: Record<Sizes, ClassNameSize> = {
  'text-12': {
    icon: 'w-3',
    text: 'text-12',
  },
  'text-18': {
    icon: 'w-5',
    text: 'text-18',
  },
};

interface InteractionsContainerProps {
  count: number;
  icon: (props: SVGProps<SVGElement>) => JSX.Element;
  size?: Sizes;
  upvoted?: boolean;
}

export default function InteractionsContainer({
  count,
  icon: Icon,
  size = 'text-12',
  upvoted = false,
}: InteractionsContainerProps) {
  return (
    <div className={`flex items-center ${upvoted ? 'text-error' : ''}`}>
      <Text text={count} size={contentSize[size].text} />
      <Icon className={contentSize[size].icon} />
    </div>
  );
}
