import React from 'react';
import Text from '../../components/common/dataDisplay/Text';
import PageTitle from '../../components/common/PageTitle';
import { useTranslation } from 'react-i18next';

export default function NoAdminScreenForMobile() {
  const { t } = useTranslation();
  return (
    <React.Fragment>
      <PageTitle headingText="Admin" />
      <div className="flex flex-col h-screen text-center pt-16 px-8 sm:px-12">
        <Text
          format="poppins-600"
          size="text-24 sm:text-28"
          text={t('adminPage.noAdminScreen')}
          className="text-primary"
        />
        <span className="pt-14">
          <Text
            format="poppins-600"
            size="text-24 sm:text-28"
            text={t('adminPage.useComputer')}
            className="text-primary"
          />
        </span>
      </div>
    </React.Fragment>
  );
}
