import useToggle from '@/data/hook/useToggle';
import { accordion } from '@/utils/animations/commom';
import { ChevronDownIcon } from '@heroicons/react/outline';
import { AnimatePresence, motion } from 'framer-motion';
import {
  HTMLAttributes,
  InputHTMLAttributes,
  ReactNode,
  forwardRef,
} from 'react';
import Badge from '../dataDisplay/Badge';
import ConditionalWrapper from '../ConditionalWrapper';
import Checkbox from './Checkbox';

export type CheckOption = {
  value: string | number;
  label: ReactNode | string;
} & InputHTMLAttributes<HTMLInputElement>;

type CheckGroupProps = {
  options: CheckOption[];
} & InputHTMLAttributes<HTMLInputElement>;

const CheckGroup = forwardRef<HTMLInputElement, CheckGroupProps>(
  (props, ref) => {
    const { options, ...input } = props;
    return (
      <ul className="pr-1">
        {options.map(option => {
          const checkboxId = 'checkbox' + option.label;
          return (
            <li key={option.value} className="mt-1.5">
              <Checkbox
                {...input}
                {...option}
                id={option.id || checkboxId}
                ref={ref}
                className={{ input: input.className }}
              />
            </li>
          );
        })}
      </ul>
    );
  },
);

type CheckGroupContainerProps = {
  title: string;
  testId?: string;
  badgeCount?: number;
  initOpen?: boolean;
  isOpen?: boolean;
  onToggle?: () => void;
  actions?: ReactNode;
} & HTMLAttributes<HTMLDivElement>;

const CheckGroupContainer = ({
  children,
  title,
  badgeCount,
  testId,
  actions,
  initOpen,
  isOpen: externalIsOpen,
  onToggle: externalOnToggle,
}: CheckGroupContainerProps) => {
  const { isOpen: internalIsOpen, toggle: internalOnToggle } =
    useToggle(initOpen);

  const isOpen = externalIsOpen ?? internalIsOpen;
  const onToggle = externalOnToggle ?? internalOnToggle;
  return (
    <div className="relative w-full flex flex-col gap-1">
      <div className="flex justify-between w-full items-center">
        <button
          onClick={onToggle}
          data-testid={testId}
          className="flex gap-1.5 justify-start items-center"
        >
          <ConditionalWrapper
            condition={!!badgeCount}
            wrapper={
              <Badge
                position="-top-1 -right-2"
                color={'info'}
                count={badgeCount}
              />
            }
          >
            <span className="text-base-content">{title}</span>
          </ConditionalWrapper>
          <ChevronDownIconMotion
            className="w-5 h-5 shrink-0 text-base-content"
            animate={{ rotate: isOpen ? 180 : 0 }}
          />
        </button>
        {actions}
      </div>
      <div className="relative overflow-hidden w-full">
        <AnimatePresence>
          {isOpen && <motion.div {...accordion}>{children}</motion.div>}
        </AnimatePresence>
      </div>
    </div>
  );
};

const ChevronDownIconMotion = motion(ChevronDownIcon);

export { CheckGroupContainer };
export default CheckGroup;
