import requester from '@/axios';
import Activity, { ActivityTypeEnum } from '@/models/Activity';
import { DNDOrdenationActivity } from '@/models/ActivityElement';
import { ColumnDND, ItemDND } from '@/models/DNDOrdenation';

interface CreateDNDOrdenationActivity
  extends Omit<DNDOrdenationActivity, 'id' | 'columns'> {
  identifier: string;
  order: number;
  type: ActivityTypeEnum;
}

export async function createDndOrdenationActivity(
  activity: Activity,
  dndOrdenationActivy: CreateDNDOrdenationActivity,
): Promise<DNDOrdenationActivity> {
  const postDndActivity = {
    activity: activity.id,
    type: dndOrdenationActivy.type,
    identifier: dndOrdenationActivy.identifier,
    order: dndOrdenationActivy.order,
    title: dndOrdenationActivy.title,
    subtitle: dndOrdenationActivy.subtitle,
  };

  const { data } = await requester().post<DNDOrdenationActivity>(
    'dnd-ordenation-activity/',
    postDndActivity,
  );

  return data;
}

export async function createDndOrdenationColumn(
  dndOrdenationColumn: ColumnDND,
  dndOrdenationActivityId: number,
): Promise<ColumnDND> {
  const { data } = await requester().post<ColumnDND>('dnd-ordenation-column/', {
    name: dndOrdenationColumn.name,
    flow: dndOrdenationColumn.flow,
    dndOrdenationActivity: dndOrdenationActivityId,
    order: dndOrdenationColumn.order,
  });

  let items: ItemDND[] = [];
  if (data.id) {
    const newItems = await Promise.all(
      dndOrdenationColumn.items.map(async (item, index) =>
        createDndOrdenationItem(item, index + 1, data.id!),
      ),
    );
    items = newItems;
  }

  return {
    ...data,
    items,
  };
}

export async function createDndOrdenationItem(
  dndOrdenationItem: ItemDND,
  order: number,
  dndOrdenationColumnId: number,
): Promise<ItemDND> {
  const formData = new FormData();

  Object.entries(dndOrdenationItem).forEach(([key, value]) => {
    if (value) {
      formData.set(key, value);
    }
  });

  formData.set('dndOrdenationColumn', String(dndOrdenationColumnId));
  formData.set('order', String(order));

  const { data } = await requester().post<ItemDND>(
    `dnd-ordenation-column/${dndOrdenationColumnId}/dnd-ordenation-item/`,
    formData,
    {
      headers: {
        'Content-Type': 'multipart/form-data',
        accept: 'application/json',
      },
    },
  );

  return data;
}

export async function updateDndOrdenationActivity(
  dndOrdenationActivy: Pick<DNDOrdenationActivity, 'title' | 'subtitle' | 'id'>,
): Promise<DNDOrdenationActivity> {
  const patchDndActivity = {
    title: dndOrdenationActivy.title,
    subtitle: dndOrdenationActivy.subtitle,
  };

  const { data } = await requester().patch<DNDOrdenationActivity>(
    `dnd-ordenation-activity/${dndOrdenationActivy.id}/`,
    patchDndActivity,
  );

  return data;
}

export async function updateDndOrdenationColumn(
  dndOrdenationColumn: ColumnDND,
): Promise<ColumnDND> {
  const updateDndOrdenationColumn = {
    name: dndOrdenationColumn.name,
    flow: dndOrdenationColumn.flow,
    dndOrdenationActivity: dndOrdenationColumn.dndOrdenationActivity,
  };

  const { data } = await requester().patch<ColumnDND>(
    `dnd-ordenation-column/${dndOrdenationColumn.id}/`,
    updateDndOrdenationColumn,
  );

  let items: ItemDND[] = [];
  if (data.id) {
    const newItems = await Promise.all(
      dndOrdenationColumn.items.map(async item =>
        updateDndOrdenationItem(item, data.id!),
      ),
    );
    items = newItems;
  }

  return {
    ...data,
    items,
  };
}

export async function updateDndOrdenationItem(
  dndOrdenationItem: Partial<Omit<ItemDND, 'errors' | 'order'>>,
  dndOrdenationColumnId: number,
): Promise<ItemDND> {
  const formData = new FormData();

  Object.entries(dndOrdenationItem).forEach(([key, value]) => {
    if (key === 'image' && !(value instanceof File)) {
      return;
    }

    if (key === 'text' && !value) {
      formData.set(key, '');
    }

    if (key === 'order') return;

    if (value) {
      formData.set(key, value instanceof File ? value : value.toString());
    }
  });

  const { data } = await requester().patch<ItemDND>(
    `dnd-ordenation-column/${dndOrdenationColumnId}/dnd-ordenation-item/${dndOrdenationItem.id}/`,
    formData,
    {
      headers: {
        'Content-Type': 'multipart/form-data',
        accept: 'application/json',
      },
    },
  );

  return data;
}

export async function updateColumnItemsOrder(
  orderList: { id: number | undefined }[],
  dndOrdenationColumnId: number,
) {
  await requester().patch(
    `dnd-ordenation-column/${dndOrdenationColumnId}/dnd-ordenation-item/order/`,
    orderList,
  );
}

export async function getDndOrdenationColumn(
  dndOrdenationColumnId: number,
): Promise<ColumnDND> {
  const { data } = await requester().get(
    `dnd-ordenation-column/${dndOrdenationColumnId}/`,
  );

  return data;
}

export async function deleteDndOrdenationItem(
  dndOrdenationItemId: number,
  dndOrdenationColumnId: number,
) {
  await requester().delete(
    `dnd-ordenation-column/${dndOrdenationColumnId}/dnd-ordenation-item/${dndOrdenationItemId}/`,
  );
}

export async function deleteDndOrdenationColumn(dndOrdenationColumnId: number) {
  await requester().delete<ColumnDND>(
    `dnd-ordenation-column/${dndOrdenationColumnId}/`,
  );
}

export async function getDndOrdenationActivity(
  dndOrdenationActivityId: number,
): Promise<DNDOrdenationActivity> {
  const { data } = await requester().get<DNDOrdenationActivity>(
    `dnd-ordenation-activity/${dndOrdenationActivityId}/`,
  );

  return data;
}

export const deleteDndOrdenationActivity = async (questionId: number) => {
  await requester().delete<DNDOrdenationActivity>(
    `dnd-ordenation-activity/${questionId}/`,
  );
};
