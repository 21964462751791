import { ElementPreviewFactoryProps } from '../ElementPreviewFactory';
import TrueOrFalseButton from './TrueOrFalseButton';
import { TrueOrFalseCorrectAnswer } from '@/models/ActivityProgress';

function TrueOrFalseFeedback({ answer }: ElementPreviewFactoryProps) {
  const { isTrue } = answer as TrueOrFalseCorrectAnswer;

  return (
    <TrueOrFalseButton
      variant={isTrue}
      selectedValue={isTrue}
      disabled={true}
    />
  );
}

export default TrueOrFalseFeedback;
