import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import AddTeam from '@/components/admin/team/AddTeam';
import ListTeam from '@/components/admin/team/ListTeam';
import AdminButton from '@/components/common/AdminButton';
import TableSearchIcon from '@/components/common/TableSearchIcon';
import Layout from '@/components/template/Layout';
import { UserTypeEnum } from '@/models/User';
import NoAdminScreenForMobile from './NoAdminScreenForMobile';
import { TeamHeader } from '@/utils/HeaderTypes';
import TableStatistics from '@/components/common/table/TableStatistics';
import { useQuery } from '@tanstack/react-query';
import PaginationComponent from './PaginationComponent';
import { MANAGEMENT_PAGE_SIZE } from '@/constants';
import HeadTitle from '@/components/common/HeadTitle';
import Dropdown from '@/components/common/filter/Dropdown';
import useFilterParams from '@/utils/UseFilterParams';
import { ListUsersFilters } from '@/data/services/userServices';
import useAuth from '@/data/hook/useAuth';
import { isSuperAdmin } from '@/functions/auth';
import useStatisticService from '@/data/hook/useStatistics';
import { getAuthorizedUnits } from '@/utils/getAuthorizedUnits';
import {
  accessCountQueryKeys,
  usersCountQueryKeys,
  usersQueryKeys,
} from '@/data/services/querykeys';

export default function ManageTeam() {
  const { t } = useTranslation('translation', { keyPrefix: 'manageTeam' });

  const { user: authUser } = useAuth();

  const [isSearch, setIsSearch] = useState(false);

  const { isAdd, username: teacherUsername } = useParams();

  const [isAddTeam, setIsAddTeam] = useState(isAdd === 'add');

  const { filterParams, setFilterParams } = useFilterParams<ListUsersFilters>(
    {},
  );

  const isUnitAdmin = authUser?.userType === UserTypeEnum.UNIT_ADMIN;
  const isMultipleUnits = authUser && authUser.unitsIds?.length > 1;

  const userTypeStrings: string[] =
    filterParams.getAll('userType') ?? undefined;
  const userTypeEnums: UserTypeEnum[] = userTypeStrings.map(
    (type: string) => type.trim() as UserTypeEnum,
  );

  const unitIdParams = filterParams.getAll('unit').map(Number);

  const unit = unitIdParams.length
    ? unitIdParams
    : getAuthorizedUnits(authUser);

  const pageNumber = Number(filterParams.get('pageNumber') ?? '1');

  const search = filterParams.get('search') ?? undefined;

  const pageSize = MANAGEMENT_PAGE_SIZE;

  const titles: TeamHeader = {
    name: {
      title: t('listUsers.name'),
      extraFunction: [
        <TableSearchIcon onClick={() => setIsSearch(!isSearch)} />,
      ],
      size: 20,
    },
    email: { title: t('listUsers.email'), size: 20 },
    phoneNumber: { title: t('listUsers.phoneNumber'), size: 13 },
    unit: {
      title: t('listUsers.unit'),
      size: 12,
      extraFunction: [
        <Dropdown
          filter={unit?.map(String).filter(value => !!value) ?? []}
          type="unit"
        />,
      ],
      roles: [UserTypeEnum.SUPER_ADMIN, UserTypeEnum.UNIT_ADMIN],
      blockRender: true,
      blockCondition: isUnitAdmin && !isMultipleUnits,
    },
    role: {
      title: t('listUsers.role'),
      size: 10,
      extraFunction: [
        <Dropdown filter={userTypeStrings ?? []} type="userType" />,
      ],
    },
    status: { title: t('listUsers.status'), size: 6 },
    actions: { title: t('listUsers.actions'), size: 6 },
  };

  const exUserType = isUnitAdmin
    ? [UserTypeEnum.STUDENT, UserTypeEnum.PARENT, UserTypeEnum.SUPER_ADMIN]
    : [UserTypeEnum.STUDENT, UserTypeEnum.PARENT];

  const params = {
    pageNumber,
    userType: userTypeEnums,
    unit,
    exUserType,
    search,
    pageSize,
  };

  const {
    data: teamPage,
    isLoading: isLoadingTeam,
    isPreviousData,
  } = useQuery({
    refetchOnWindowFocus: false,
    keepPreviousData: true,
    ...usersQueryKeys.list(params),
  });

  const statisticsFilters = {
    exUserType,
    unitId: isSuperAdmin(authUser?.userType) ? undefined : authUser?.unitsIds,
  };

  const {
    statistic: userStatistics,
    isInitialLoading: isLoadingUserStatistics,
  } = useStatisticService({
    keepPreviousData: true,
    ...accessCountQueryKeys.list(statisticsFilters),
  });
  const { statistic: totalUsers, isInitialLoading: isLoadingTotalUsers } =
    useStatisticService({
      keepPreviousData: true,
      ...usersCountQueryKeys.list(statisticsFilters),
    });

  const statisticsCards = [
    {
      title: t('quantity'),
      info: totalUsers?.usersCount || 0,
      isLoading: isLoadingTotalUsers,
    },
    {
      title: t('accesses'),
      info: userStatistics?.thirtyDaysCount || 0,
      isLoading: isLoadingUserStatistics,
    },
    {
      title: t('weeklyAccesses'),
      info: userStatistics?.sevenDaysCount || 0,
      isLoading: isLoadingUserStatistics,
    },
  ];

  const changePages = (numberOfPage: string) => {
    setFilterParams({ pageNumber: Number(numberOfPage) });
  };

  return (
    <React.Fragment>
      <HeadTitle routeInfo={t('team')} />
      <div className="md:hidden">
        <NoAdminScreenForMobile />
      </div>

      <div className="hidden md:block w-full">
        <Layout className="gap-2 flex flex-col">
          <TableStatistics
            headingText={t('title')}
            statisticsCards={statisticsCards}
            roles={[UserTypeEnum.UNIT_ADMIN, UserTypeEnum.SUPER_ADMIN]}
            adminButton={
              <AdminButton
                title={t('add')}
                onClick={() => setIsAddTeam(!isAddTeam)}
                open={isAddTeam}
              />
            }
          />
          <AddTeam show={isAddTeam} setShow={setIsAddTeam} />

          <ListTeam
            isLoading={isLoadingTeam || isPreviousData}
            isSearch={isSearch}
            titles={titles}
            team={teamPage?.results || []}
            searchTeacher={teacherUsername || ''}
          />

          <PaginationComponent
            amountPages={teamPage?.meta.pagination.pages || 1}
            changePages={changePages}
            paramsPage={pageNumber}
            amountOf={teamPage?.meta.pagination.count || 0}
            managerType="team"
          />
        </Layout>
      </div>
    </React.Fragment>
  );
}
