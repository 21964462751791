import requester from '@/axios';
import Activity from '@/models/Activity';
import { DNDPositionalActivity } from '@/models/ActivityElement';

export async function postDndPositionalActivity(
  activity: Activity,
  dndPositionalActivity: DNDPositionalActivity,
): Promise<DNDPositionalActivity> {
  const postDndActivity = {
    activity: activity.id,
    type: dndPositionalActivity.type,
    identifier: dndPositionalActivity.identifier,
    order: dndPositionalActivity.order,
    title: dndPositionalActivity.title,
    subtitle: dndPositionalActivity.subtitle,
  };

  const { data } = await requester().post<DNDPositionalActivity>(
    'dnd-positional-activity/',
    postDndActivity,
  );

  return data;
}

export async function patchDndPositionalActivity(
  dndPositionalActivity: DNDPositionalActivity,
): Promise<DNDPositionalActivity> {
  const { data } = await requester().patch<DNDPositionalActivity>(
    `dnd-positional-activity/${dndPositionalActivity.id}/`,
    {
      title: dndPositionalActivity.title,
      subtitle: dndPositionalActivity.subtitle,
    },
  );

  return data;
}

export async function getDndPositionalActivity(
  dndPositionalActivityId: number,
): Promise<DNDPositionalActivity> {
  const { data } = await requester().get<DNDPositionalActivity>(
    `/dnd-positional-activity/${dndPositionalActivityId}/`,
  );

  return data;
}

export const deleteDndPositionalActivity = async (questionId: number) => {
  await requester().delete<DNDPositionalActivity>(
    `dnd-positional-activity/${questionId}/`,
  );
};
