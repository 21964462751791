import { HexColorPicker } from 'react-colorful';
import { Control, Controller } from 'react-hook-form';
import Popper from '../../dataDisplay/Popper';
import TextInput from '../TextInput';
import { useTranslation } from 'react-i18next';
import { twMerge } from 'tailwind-merge';
import { validateHexadecimalCode } from '@/validators/regex';
import { BaseInputProps } from '../BaseInput';
import { hexadecimalMask } from '../../../admin/settings/MaskInput';

type ColorInputProps = BaseInputProps & {
  name: string;
  control: Control<any>;
  className?: string;
};

export default function ColorInput(props: ColorInputProps) {
  const { name, control, className, errorLabelText } = props;

  const { t } = useTranslation('translation', {
    keyPrefix: 'colorInput',
  });

  return (
    <Controller
      control={control}
      name={name}
      rules={{
        pattern: {
          value: validateHexadecimalCode,
          message: t('warnings.validateHexadecimalCode'),
        },
      }}
      render={({ field: { onChange, value } }) => (
        <Popper
          referenceClassName={twMerge('flex w-full', className)}
          referenceContent={
            <div className="flex w-full relative">
              <span
                className="absolute w-3 h-3 rounded-full left-6 top-1"
                style={{ backgroundColor: value }}
              />
              <TextInput
                mask={hexadecimalMask}
                value={value ?? ''}
                testId="colorInput"
                label={t('color')}
                fontWeight="font-400"
                className={{ base: 'w-full' }}
                onChange={e => onChange(e.target.value)}
                errorLabelText={errorLabelText}
                maxLenght={8}
              />
            </div>
          }
          popperContent={
            <HexColorPicker
              color={value ?? ''}
              data-testid="tagColorPicker"
              onChange={color => {
                if (color === '#NaNNaNNaN') {
                  onChange('');
                  return;
                }
                onChange(color);
              }}
            />
          }
        />
      )}
    />
  );
}
