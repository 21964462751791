import { ReactElement } from 'react';
import { ChevronDownIcon, ChevronUpIcon } from '@heroicons/react/outline';
import useToggle from '@/data/hook/useToggle';
import ConditionalRenderer from '../ConditionalRenderer';

type LongCollapseProps = {
  testId: string;
  header: ReactElement;
  children: ReactElement;
  open?: boolean;
};

export default function LongCollapse({
  testId,
  header,
  children,
  open,
}: LongCollapseProps) {
  const { isOpen = open, toggle } = useToggle();

  return (
    <div className="flex flex-col w-full bg-base-100 shadow-default rounded-lg gap-3.5 xl:gap-4 px-3.5 xl:px-5 py-3.5">
      <div
        onClick={toggle}
        data-testid={testId}
        className="flex cursor-pointer flex-col gap-0.5 lg:justify-between lg:flex-row lg:items-center"
      >
        <div className="flex flex-row items-center w-full text-primary">
          <ConditionalRenderer
            condition={isOpen}
            fallback={<ChevronDownIcon className="w-4 h-4" />}
          >
            <ChevronUpIcon className="w-4 h-4" />
          </ConditionalRenderer>

          <div className="ml-2.5 w-full">{header}</div>
        </div>
      </div>
      {isOpen && children}
    </div>
  );
}
