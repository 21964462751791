import { useTranslation } from 'react-i18next';

import ConditionalRenderer from '@/components/common/ConditionalRenderer';
import { handleUserFullName } from '@/functions/handleUserFullName';
import { StudentAssessment } from '@/models/StudentAssessment';
import { EditAssessment, PedagogicalSheetProps } from '../PedagogicalSheet';
import Assessment from './Assessment';
import { SheetTab } from '@/models/SheetTab';
import ButtonInfiniteList from '@/components/common/ButtonInfiniteList';

type AssessmentsProps = PedagogicalSheetProps & {
  assessments: StudentAssessment[];
  isLoading?: boolean;
  fetchNextPage(): unknown | Promise<unknown>;
  hasNextPage?: boolean;
  isFetchingNextPage?: boolean;
  editAssessment({ assessment, tab }: EditAssessment): void;
  error?: string;
  tab: SheetTab;
};

export default function Assessments({
  assessments,
  isLoading,
  hasNextPage,
  fetchNextPage,
  isFetchingNextPage,
  student,
  editAssessment,
  error,
  tab,
}: AssessmentsProps) {
  const { t } = useTranslation('translation', {
    keyPrefix: 'pedagogicalSheet.assessments',
  });

  const scrollYClassName =
    'scrollbar-thin scrollbar-thumb-primary/40 scrollbar-track-primary-content scrollbar-thumb-rounded-full scrollbar-track-rounded-full overflow-y-auto';

  const ulClassName = `flex flex-col gap-4 h-full max-h-[22rem] pr-5 w-full ${scrollYClassName}`;

  const ulId = 'ulAssessments' + student.id;

  return (
    <ButtonInfiniteList
      testId="assessments"
      isLoading={isLoading}
      error={error}
      hasNextPage={hasNextPage}
      isFetchingNextPage={isFetchingNextPage}
      fetchNextPage={fetchNextPage}
      listClassName="flex"
    >
      <ConditionalRenderer condition={!isLoading && assessments.length}>
        <ul id={ulId} className={ulClassName}>
          {assessments.map(assessment => (
            <Assessment
              key={assessment.id}
              assessment={assessment}
              editAssessment={editAssessment}
              student={student}
              tab={tab}
            />
          ))}
        </ul>
      </ConditionalRenderer>

      <ConditionalRenderer
        condition={!isLoading && !error && !assessments.length}
      >
        {`${t('noInfo')}  ${handleUserFullName(student)}`}
      </ConditionalRenderer>
    </ButtonInfiniteList>
  );
}
