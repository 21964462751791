import { twMerge } from 'tailwind-merge';
import { uniqueId } from 'lodash';
import { createElement, CSSProperties, ReactNode } from 'react';

import { HtmlPreviewErrorDispatcher } from '@/errors/htmlPreview';
import { ErrorCapturer } from '@/adapters/ErrorCapturer';

export type DisplayFormat = 'book' | 'default' | 'none';

interface HtmlPreviewProps {
  testId?: string;
  html?: string;
  format?: DisplayFormat;
  className?: string;
}

export const formatClasses: Record<DisplayFormat, string> = {
  book: 'bookHtmlDisplay',
  default: 'defaultHtmlDisplay',
  none: '',
};

export const HtmlPreview = ({
  html: content = '',
  format = 'default',
  className,
  testId,
}: HtmlPreviewProps) => {
  const replaceHtml = (rawHtml: string): string => {
    try {
      const linkPattern = /<a href="https?:\/\//gi;
      const linkReplacement = '<a target="_blank" href="https://';

      const youtubePatterns = [
        /<oembed\s+url="https:\/\/www\.youtube\.com\/watch\?v=([\w-]{11})&amp;ab_channel=[\w%]*"><\/oembed>/gi,
        /<oembed\s+url="https:\/\/youtu\.be\/([\w-]{11})\?t=[\w-]*"><\/oembed>/gi,
        /<oembed\s+url="https:\/\/www\.youtube\.com\/watch\?v=([\w-]{11})&amp;t=[\w-]*"><\/oembed>/gi,
        /<oembed\s+url="https:\/\/www\.youtube\.com\/watch\?v=([\w-]{11})"><\/oembed>/gi,
        /<oembed\s+url="https:\/\/youtu\.be\/([\w-]{11})"><\/oembed>/gi,
        /<oembed\s+url="(?:https?:\/\/)?(?:www\.)?youtube\.com\/watch\?v=([\w-]+)&?(?:[^"]+)?"><\/oembed>/gi,
        /<oembed\s+url="(?:https?:\/\/)?(?:www\.)?youtube\.com\/watch\?v=([\w-]+)&?(?:list=[\w-]+&?)?(?:[^"]+)?"><\/oembed>/gi,
      ];

      const replaceOembedWithIframe = (_match: string, videoId: string) => {
        return `<iframe class="videoHtmlDisplay" src="https://www.youtube.com/embed/${videoId}" frameborder="0" allowfullscreen></iframe>`;
      };

      const colorPatterns = [
        /color:\s*rgb\(0,\s*0,\s*0\)/gi,
        /color:\s*hsl\(0,\s*0%,\s*0%\)/gi,
        /color:#000000/gi,
      ];
      const colorReplacement = 'color: var(--base-content)';

      let replacedHtml = rawHtml.replace(linkPattern, linkReplacement);

      youtubePatterns.forEach(pattern => {
        replacedHtml = replacedHtml.replace(pattern, replaceOembedWithIframe);
      });

      colorPatterns.forEach(pattern => {
        replacedHtml = replacedHtml.replace(pattern, colorReplacement);
      });

      return replacedHtml;
    } catch (error: any) {
      const htmlPreviewError = new HtmlPreviewErrorDispatcher(error);

      const errorCapturer = new ErrorCapturer(htmlPreviewError);
      errorCapturer.dispatchError();

      return '';
    }
  };

  const toCamelCaseProperty = (property: string): string =>
    property.replace(/-([a-z])/g, (_, char) => char.toUpperCase());

  const renderNode = (node: ChildNode): ReactNode => {
    if (node.nodeType === Node.TEXT_NODE) {
      return node.textContent ? (
        <span key={uniqueId()}>{node.textContent}</span>
      ) : null;
    }

    if (node.nodeType === Node.ELEMENT_NODE) {
      const element = node as HTMLElement;

      const props = Array.from(element.attributes).reduce((acc, attr) => {
        if (attr.name === 'class') acc['className'] = attr.value;
        else if (attr.name === 'style') acc['style'] = parseStyle(attr.value);
        else if (attr.name === 'allowfullscreen')
          acc['allowFullScreen'] = attr.value;
        else if (attr.name === 'frameborder') acc['frameBorder'] = attr.value;
        else acc[attr.name] = attr.value;

        return acc;
      }, {} as Record<string, any>);

      if (element.tagName.toLowerCase() === 'a') {
        props.className = twMerge(
          'text-primary hover:opacity-75 transition-all duration-300 ease-in-out',
          props.className,
        );
        props.target = '_blank';
      }

      const children = Array.from(element.childNodes).map(renderNode);

      return createElement(
        element.tagName.toLowerCase() || 'span',
        { key: uniqueId(), ...props },
        ...children,
      );
    }

    return null;
  };

  const parseStyle = (style: string): CSSProperties => {
    const splitStyle = style.split(';');

    const filterStyle = splitStyle.filter(Boolean);

    const convertedStyleProps = filterStyle.reduce((cssProperties, rule) => {
      const [property, value] = rule.split(':').map(item => item.trim());

      if (property && value) {
        const camelCaseProperty = toCamelCaseProperty(property);
        (cssProperties as Record<string, string>)[camelCaseProperty] = value;
      }

      return cssProperties;
    }, {} as CSSProperties);

    return convertedStyleProps;
  };

  if (content) {
    const parser = new DOMParser();
    const dom = parser.parseFromString(replaceHtml(content), 'text/html');

    const reactNodes = Array.from(dom.body.childNodes).map(renderNode);

    return (
      <div
        data-testid={testId}
        className={twMerge(
          'font-rubik text-wrap break-words htmlDisplay ck-content',
          formatClasses[format],
          className,
        )}
      >
        {reactNodes}
      </div>
    );
  }

  return null;
};
