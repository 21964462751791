import { InformationCircleIcon, PencilIcon } from '@heroicons/react/outline';
import { useTranslation } from 'react-i18next';
import { useState } from 'react';
import CourseForm from './CourseForm';
import { Course } from '../../../models/Course';
import Tag from '../../common/dataDisplay/Tag';
import ComponentGuard from '../../common/ComponentGuard';
import { UserTypeEnum } from '../../../models/User';
import MainButton from '../../common/buttons/MainButton';

interface EditCourseProps {
  course: Partial<Course>;
  updateCourseList(): void;
}

export default function EditCourse(props: EditCourseProps) {
  const { course, updateCourseList } = props;

  const { t } = useTranslation('translation', {
    keyPrefix: 'manageCourse.editCourse',
  });

  const [isEditMode, setIsEditMode] = useState<boolean>(false);

  return (
    <div className="flex flex-col w-full gap-2.5 py-2.5 px-9">
      <div className="flex items-center gap-8">
        <Tag
          text={t('info')}
          icon={<InformationCircleIcon className="w-6 h-6" />}
        />
        <ComponentGuard roles={[UserTypeEnum.SUPER_ADMIN]}>
          <MainButton
            dataTestId="editButton"
            onClick={() => setIsEditMode(!isEditMode)}
            className="text-primary"
            icon={<PencilIcon />}
            text={t('editButton')}
            color="custom"
          />
        </ComponentGuard>
      </div>

      <CourseForm
        course={course}
        updateCourseList={updateCourseList}
        isEditMode={isEditMode}
        setIsEditMode={setIsEditMode}
      />
    </div>
  );
}
