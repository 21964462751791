import { useState } from 'react';

import { ClassScheduleFilter } from '@/models/ClassScheduleFilters';

export function useClassScheduleFilters<Filters>(initial?: Filters) {
  const [filters, setFilters] = useState(initial);

  const [events, setEvents] = useState<ClassScheduleFilter[]>([]);

  function updateFilter<Filters>(filter: Filters) {
    setFilters(prev => {
      return prev ? { ...prev, ...filter } : prev;
    });
  }

  function updateFilters({ type: typeUpdated, event }: ClassScheduleFilter) {
    const { value, checked } = event;

    setEvents(prev => {
      const updated = checked
        ? [...prev, { type: typeUpdated, event }]
        : prev.filter(element => element.event.value !== value);

      const eventsByType = updated.filter(({ type }) => type === typeUpdated);

      updateFilter({
        [typeUpdated]: eventsByType.length
          ? eventsByType.map(({ event }) => event.value)
          : undefined,
      });

      return updated;
    });
  }

  function clearFilter() {
    setEvents([]);
    setFilters(initial);
  }

  return {
    filters,
    events,
    updateFilters,
    updateFilter,
    clearFilter,
  };
}
