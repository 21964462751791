import requester from '@/axios';
import { Notification, NotificationTypeEnum } from '@/models/Notification';

type UpdateNotificationBody = {
  hasRead: boolean;
};

const BASE_URL = '/analytics/notifications';

export const updateNotification = async (
  id: number,
  body: UpdateNotificationBody,
) => {
  const { data: notification } = await requester().patch<Notification>(
    `${BASE_URL}/${id}/`,
    body,
  );
  return notification;
};

type ReadAllPayload = {
  type?: NotificationTypeEnum[];
};

export const readAllNotification = async (body: ReadAllPayload = {}) => {
  await requester().post(`${BASE_URL}/read-all/`, body);
};
