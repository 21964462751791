import React from 'react';

interface Subheading2Props {
  text: string | undefined;
  className?: string;
  id?: string;
}

export default function Subheading2(props: Subheading2Props) {
  return (
    <React.Fragment>
      <p
        id={props.id}
        className={`font-text font-links text-subheading_2 leading-tight text-18 font-500 ${props.className}`}
      >
        {props.text}
      </p>
    </React.Fragment>
  );
}
