import IconButton from '../common/buttons/IconButton';
import { MinusIcon, PlusIcon } from '@heroicons/react/outline';
import { useTranslation } from 'react-i18next';
import { twMerge } from 'tailwind-merge';

interface RewardInputProps {
  coins: number;
  maxCoins: number;
  lessCoins: () => void;
  moreCoins: () => void;
  className?: string;
  testId?: string;
}

export default function RewardInput({
  coins,
  maxCoins,
  className,
  lessCoins,
  moreCoins,
  testId,
}: RewardInputProps) {
  const { t } = useTranslation('translation', {
    keyPrefix: 'rewardInput',
  });

  return (
    <div
      className={twMerge('flex items-center justify-center gap-2.5', className)}
    >
      <div className="flex items-center gap-1">
        <IconButton
          disabled={coins === 1 || coins === 0}
          icon={<MinusIcon className="w-4" />}
          className="text-primary"
          onClick={lessCoins}
          type="button"
        />

        <span
          data-testid={testId}
          className={`select-none flex ${
            coins === 0 ? 'w-14 text-neutral/50' : 'w-8 text-deafult'
          } justify-center items-center border border-primary rounded-md transform ease-in-out duration-500`}
        >
          {coins === 0 ? t('placeholder') : coins}
        </span>

        <IconButton
          disabled={coins === maxCoins || coins === 0}
          icon={<PlusIcon className="w-4" />}
          className="text-primary"
          onClick={moreCoins}
          type="button"
        />
      </div>
    </div>
  );
}
