import requester from '@/axios';
import { HomeworkActivityProgress } from '@/models/HomeworkActivity';
import JsonApiResponse from '@/models/JsonApiResponse';
import { BaseFilters } from '@/models/Service';

export type HomeworkProgressFilters = BaseFilters & {
  user?: number;
  studentId?: number;
  homeworkId?: number;
};

export const listHomeworkProgresses = async (
  homeworkActivityId: number,
  params: HomeworkProgressFilters = {},
) => {
  const { data } = await requester().get<
    JsonApiResponse<HomeworkActivityProgress>
  >(`homework-activity/${homeworkActivityId}/homework-progress/`, { params });

  return data;
};

interface UpdateHomeworkProgressParams {
  homeworkId: number;
  homeworkProgressId: number;
  changes: Partial<HomeworkActivityProgress>;
}
export const updateHomeworkProgress = async ({
  changes,
  homeworkId,
  homeworkProgressId,
}: UpdateHomeworkProgressParams) => {
  const { data } = await requester().patch<HomeworkActivityProgress>(
    `homework-activity/${homeworkId}/homework-progress/${homeworkProgressId}/`,
    changes,
  );
  return data;
};

export const getHomeworkActivityProgress = async (id: number) => {
  const { data: homeworkActivity } = await requester().get<
    JsonApiResponse<HomeworkActivityProgress>
  >(`homework-activity/${id}/homework-progress/`);
  return homeworkActivity.results[0];
};

export type GetHomeworkProgress = {
  homeworkId?: number | null;
  homeworkProgressId?: number;
};

export const getHomeworkProgress = async ({
  homeworkId,
  homeworkProgressId,
}: GetHomeworkProgress) => {
  const { data } = await requester().get<HomeworkActivityProgress>(
    `homework-activity/${homeworkId}/homework-progress/${homeworkProgressId}/`,
  );
  return data;
};
