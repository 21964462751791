import TopicsWorkspace from './workspace/TopicsWorkspace';
import TopicsTopBar from './TopicsTopBar';
import TopicsTreeRoot from './tree/TopicsTreeRoot';

export type TopicsFiltersParams = {
  path?: number[];
  area?: number;
  isRenaming?: boolean;
};

export default function TopicsPageContent() {
  return (
    <div className="flex flex-col w-full gap-4">
      <TopicsTopBar />
      <div className="relative flex gap-4 w-full ">
        <TopicsTreeRoot />
        <TopicsWorkspace />
      </div>
    </div>
  );
}
