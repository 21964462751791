const changeLog = theme => {
  const appID = process.env.REACT_APP_CANNY_CHANGELOG_KEY;

  const changeLog = 'canny-changelog-iframe';
  const changeLogIframe = document.getElementById(changeLog);

  if (changeLogIframe) changeLogIframe.remove();

  window.Canny('initChangelog', {
    appID,
    position: 'bottom',
    align: 'left',
    theme,
  });
};

const render = (basePath, theme) => {
  const boardToken = process.env.REACT_APP_CANNY;

  if (typeof window.Canny === 'function') {
    window.Canny('render', {
      basePath,
      theme,
      boardToken,
    });

    changeLog(theme);
  }
};

const createCannyScript = (basePath, theme, id) => {
  const script = document.createElement('script');
  script.id = id;
  script.type = 'text/javascript';
  script.async = true;
  script.src = 'https://canny.io/sdk.js';
  script.onload = () => render(basePath, theme);

  document.head.appendChild(script);
};

export const initCanny = (basePath, theme) => {
  const scriptId = 'canny-jssdk';
  const cannyScript = document.getElementById(scriptId);

  if (cannyScript) render(basePath, theme);
  else createCannyScript(basePath, theme, scriptId);
};
