import { DragDropContext, DropResult, Droppable } from '@hello-pangea/dnd';
import { ColumnDND, ItemDND } from '@/models/DNDOrdenation';
import DNDOrdenationColumnNameEdit from './DNDOrdenationColumnNameEdit';
import DNDOrdenationItemEdit from './DNDOrdenationItemEdit';
import TooltipHandler from '../../../../../common/TooltipHandler';
import useCourseEditing from '../../../../../../data/hook/useCourseEditing';
import { useTranslation } from 'react-i18next';
import MainButton from '../../../../../common/buttons/MainButton';
import { PlusIcon, TrashIcon } from '@heroicons/react/outline';

interface DNDOrdenationItemListEditProps {
  column: ColumnDND;
  indexColumn: number;
  handleUpdateColumns: ({
    column,
    indexColumn,
  }: {
    column: ColumnDND;
    indexColumn: number;
  }) => void;
  handleAddItem: (indexColumn: number) => void;
  handleDeleteColumn: (indexColumn: number) => void;
  handleDeleteItem: ({
    indexColumn,
    indexItem,
  }: {
    indexColumn: number;
    indexItem: number;
  }) => void;
  ordenationItems: ({
    items,
    indexColumn,
  }: {
    items: ItemDND[];
    indexColumn: number;
  }) => void;
  isBlockedDeleteColumn: boolean;
}

export default function DNDOrdenationItemListEdit(
  props: DNDOrdenationItemListEditProps,
) {
  const {
    column,
    indexColumn,
    handleUpdateColumns,
    handleAddItem,
    handleDeleteColumn,
    handleDeleteItem,
    ordenationItems,
    isBlockedDeleteColumn,
  } = props;

  const { allowBigChanges } = useCourseEditing();

  const { t: tVersioningRules } = useTranslation('translation', {
    keyPrefix: 'adminPage.editCourseRules',
  });

  const { t } = useTranslation('translation', {
    keyPrefix: 'activity.manageActivity.activityDNDOrdenation',
  });

  const isBlockedAction = isBlockedDeleteColumn || !allowBigChanges;

  function handleOnDragEnd(result: DropResult) {
    if (!result.destination) return;

    const items = column.items;
    const [reorderedItem] = items.splice(result.source.index, 1);

    items.splice(result.destination.index, 0, reorderedItem);

    column.items = items;

    ordenationItems({ items, indexColumn });
  }

  function onChangeColumnName({
    value,
    keyColumn,
  }: {
    value: string;
    keyColumn: string;
  }) {
    const newColumn = { ...column, [keyColumn]: value };

    handleUpdateColumns({ column: newColumn, indexColumn });
  }

  function onChangeFieldsItem({
    item,
    keyItem,
    value,
    index,
  }: {
    item: ItemDND;
    keyItem: string;
    value: string | File;
    index: number;
  }) {
    const newItem = { ...item, [keyItem]: value };
    column.items.splice(index, 1, newItem);

    handleUpdateColumns({ column: column, indexColumn });
  }

  return (
    <div className="flex flex-col gap-4">
      <DNDOrdenationColumnNameEdit
        indexColumn={indexColumn}
        column={column}
        onChangeColumnName={onChangeColumnName}
      />
      <DragDropContext onDragEnd={handleOnDragEnd}>
        <Droppable droppableId={'items' + indexColumn}>
          {provided => (
            <div
              className="flex flex-col justify-around w-full gap-3"
              {...provided.droppableProps}
              ref={provided.innerRef}
            >
              {column.items.map((item, index) => {
                return (
                  <DNDOrdenationItemEdit
                    item={item}
                    key={item?.id ?? item?.identifier}
                    draggableId={String(item.id || item.identifier)}
                    index={index}
                    indexColumn={indexColumn}
                    onChangeFieldsItem={onChangeFieldsItem}
                    handleDeleteItem={handleDeleteItem}
                    isBlockedDeleteItem={column.items.length <= 3}
                  />
                );
              })}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>

      <div className="flex gap-4">
        <div className="w-fit">
          <TooltipHandler
            tooltipMessage={tVersioningRules('cantModify')}
            renderTooltip={!allowBigChanges}
          >
            <MainButton
              dataTestId={`addItemButton${indexColumn}`}
              icon={<PlusIcon />}
              color="secondary"
              text={t('newItem')}
              disabled={!allowBigChanges}
              onClick={() => handleAddItem(indexColumn)}
            />
          </TooltipHandler>
        </div>
        <div className="w-fit">
          <TooltipHandler
            tooltipMessage={
              allowBigChanges
                ? t('textTooltipRemoveColumn')
                : tVersioningRules('cantModify')
            }
            renderTooltip={isBlockedAction}
          >
            <MainButton
              dataTestId={'removeColumnButton' + indexColumn}
              disabled={isBlockedAction}
              icon={<TrashIcon />}
              color="warning"
              text={t('removeColumn')}
              onClick={() => handleDeleteColumn(indexColumn)}
            />
          </TooltipHandler>
        </div>
      </div>
      <hr className="h-px my-4 border-primary/40" />
    </div>
  );
}
