import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useQueryClient } from '@tanstack/react-query';

import Text from '../common/dataDisplay/Text';
import TeacherComment from './TeacherComment';
import CommentBox from './CommentBox';
import Skeleton from '../common/Skeleton';
import InfinityList from '../common/InfinityList';
import { createLessonComment } from '@/data/services/lessonCommentServices';
import alert from '@/utils/UseAlert';
import { lessonCommentsQueryKeys } from '@/data/services/querykeys';
import useInfiniteService from '@/data/hook/useInfiniteService';
import { getErrorMessage } from '@/utils/getErrorMessage';
import { UserTypeEnum } from '@/models/User';
import ComponentGuard from '../common/ComponentGuard';
import ConditionalRenderer from '../common/ConditionalRenderer';

type LessonCommentSectionProps = {
  lessonId: number;
  enabled?: boolean;
};

export default function LessonCommentSection({
  lessonId,
  enabled,
}: LessonCommentSectionProps) {
  const {
    results: comments,
    hasNextPage,
    isInitialLoading: loading,
    isFetchingNextPage,
    error,
    fetchNextPage,
    isFetching,
  } = useInfiniteService({
    enabled: !!lessonId && enabled,
    ...lessonCommentsQueryKeys.list(Number(lessonId))._ctx.infinity,
  });

  const [isUpdating, setIsUpdating] = useState(false);
  const { t } = useTranslation();
  const queryClient = useQueryClient();

  const handleSubmit = async (content: string) => {
    setIsUpdating(true);
    try {
      await createLessonComment(content, lessonId);
      alert.success(t('teacherComments.successAdd'), { duration: 2000 });
      queryClient.invalidateQueries(
        lessonCommentsQueryKeys.list(lessonId)._ctx.infinity.queryKey,
      );
    } catch (error) {
      alert.error(t('teacherComments.errorAdd'), { duration: 2000 });
    } finally {
      setIsUpdating(false);
    }
  };

  return (
    <ComponentGuard
      roles={[
        UserTypeEnum.SUPER_ADMIN,
        UserTypeEnum.TEACHER,
        UserTypeEnum.UNIT_ADMIN,
      ]}
    >
      <ConditionalRenderer condition={loading}>
        <Skeleton className="bg-accent/40 w-44 rounded-full h-7" />
      </ConditionalRenderer>

      <ConditionalRenderer condition={error}>
        <Text
          format="rubik-500"
          className="text-18 text-accent"
          text={getErrorMessage(error)}
        />
      </ConditionalRenderer>

      <ConditionalRenderer condition={!loading && !error}>
        <div className="flex lg:max-w-[600px] xl:max-w-[720px] mt-6 flex-col gap-4">
          <Text
            format="rubik-500"
            className="text-18 text-accent"
            text={`${comments.length} ${
              comments.length === 1
                ? t('teacherComments.comment')
                : t('teacherComments.comments')
            }`}
          />

          <hr className="h-px w-full border-primary" />
          <CommentBox
            testId="Section"
            isSubmitting={isUpdating}
            onSubmit={handleSubmit}
          />
          <InfinityList
            hasNextPage={hasNextPage}
            onReachEnd={fetchNextPage}
            isFetchingNextPage={isFetchingNextPage}
            className="flex flex-col w-full gap-4"
          >
            {comments
              .sort(
                (a, b) => (b.upVotes?.length ?? 0) - (a.upVotes?.length ?? 0),
              )
              .map(comment => (
                <TeacherComment
                  isUpdatingList={isFetching}
                  key={comment.id}
                  lessonId={lessonId}
                  comment={comment}
                />
              ))}
          </InfinityList>
        </div>
      </ConditionalRenderer>
    </ComponentGuard>
  );
}
