import {
  ColorProperties,
  bankTypes,
  modalColors,
} from '@/utils/modalBankUtils';
import {
  BookOpenIcon,
  ClipboardIcon,
  CollectionIcon,
  PencilAltIcon,
} from '@heroicons/react/outline';
import { LayoutGroup, MotionProps, motion } from 'framer-motion';
import { CSSProperties } from 'react';
import Text from '../../dataDisplay/Text';
import ActionGroupContainer from './ActionGroupContainer';
import { useTranslation } from 'react-i18next';
import { Tooltip } from '../../dataDisplay/Tooltip';
import ConditionalWrapper from '../../ConditionalWrapper';
import ConditionalRenderer from '../../ConditionalRenderer';

type SelectTypeProps = {
  activeContext: bankTypes;
  onSelected: (context: bankTypes) => void;
};

export type Button = {
  icon: React.ElementType<{ className: string }>;
  color?: ColorProperties;
  disabled?: boolean;
};

type Buttons = Record<bankTypes, Button>;

const buttons: Buttons = {
  all: {
    color: modalColors.all,
    icon: CollectionIcon,
  },
  lessons: {
    color: modalColors.lessons,
    icon: BookOpenIcon,
  },
  activities: {
    color: modalColors.activities,
    icon: ClipboardIcon,
  },
  questions: {
    color: modalColors.questions,
    icon: PencilAltIcon,
    disabled: true,
  },
};

export function SelectType({ activeContext, onSelected }: SelectTypeProps) {
  const { t } = useTranslation('translation', {
    keyPrefix: 'modalBank',
  });
  return (
    <ActionGroupContainer title={t('section.types')}>
      <LayoutGroup>
        {Object.entries(buttons).map(([type, button]) => (
          <ConditionalRenderer condition={!button.disabled} key={type}>
            <SelectButton
              key={type}
              {...button}
              text={t(`selectTypeButton.${type}`)}
              active={type === activeContext}
              onClick={() => onSelected(type as bankTypes)}
            />
          </ConditionalRenderer>
        ))}
      </LayoutGroup>
    </ActionGroupContainer>
  );
}

type SelectButtonProps = Button & {
  active?: boolean;
  text: string;
  onClick?: () => void;
};

function SelectButton({
  text,
  icon: Icon,
  onClick,
  color,
  disabled,
  active,
}: SelectButtonProps) {
  const { t } = useTranslation('translation', {
    keyPrefix: 'modalBank',
  });
  return (
    <button onClick={onClick} className="relative rounded-lg w-full">
      <ConditionalWrapper
        condition={!!disabled}
        wrapper={<Tooltip text={t('disabledButton')} placement="top" />}
      >
        {active && (
          <BorderSelected
            color={color?.border}
            layoutId="border"
            transition={{
              type: 'spring',
              stiffness: 900,
              damping: 40,
            }}
          />
        )}
        <div className="flex gap-2 items-center px-2 py-1.5">
          <div className="flex items-center justify-center">
            <Icon className={`w-6 ${color?.color}`} strokeWidth={1} />
          </div>
          <Text text={text} className="truncate text-16" />
        </div>
      </ConditionalWrapper>
    </button>
  );
}

type BorderSelectedProps = MotionProps & {
  color?: CSSProperties['border'];
};

export const BorderSelected = ({
  color,
  ...motionProps
}: BorderSelectedProps) => (
  <motion.div
    {...motionProps}
    className={`absolute inset-0 border-2 w-full h-full rounded-lg ${color} border-opacity-50`}
  />
);
