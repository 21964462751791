import React from 'react';
import AvatarItem from './AvatarItem';
import BaseDefault from './defaultItems/baseDefault.svg';
import FaceDefault from './defaultItems/faceDefault.svg';
import ShirtDefault from './defaultItems/shirtDefault.svg';
import PantsDefault from './defaultItems/pantsDefault.svg';
import { Avatar, AvatarItemsEnum, AvatarColor } from '@/models/Avatar';
import { getAvatarInfo } from '@/utils/userAvatarUtils';
import { LoadingIcon } from '../icons';

interface UserAvatarProps extends React.HTMLAttributes<HTMLDivElement> {
  userAvatar?: Avatar;
  isLoading?: boolean;
}

export default function AvatarCharacter({
  className,
  isLoading,
  userAvatar,
}: UserAvatarProps) {
  if (isLoading) {
    return (
      <div className="flex items-center justify-center rounded-2xl w-full h-full relative">
        <LoadingIcon className="text-accent/40 w-[20%] min-h-16" />
      </div>
    );
  }

  if (userAvatar && userAvatar.id)
    return (
      <div className={`relative ${className || ''}`}>
        {Object.values(AvatarItemsEnum).map((category, i) => {
          const avatarCategory = userAvatar[category];
          return (
            <RenderAvatarItem
              avatarCategory={avatarCategory}
              avatar={userAvatar}
              category={category}
              key={i}
            />
          );
        })}
      </div>
    );

  return <React.Fragment />;
}

const RenderAvatarItem = ({
  avatarCategory,
  category,
  avatar,
}: {
  avatarCategory: AvatarColor;
  avatar: Avatar;
  category: AvatarItemsEnum;
}) => {
  const { baseItem } = getAvatarInfo(avatar, category);

  const defaultItems = {
    [AvatarItemsEnum.base]: BaseDefault,
    [AvatarItemsEnum.face]: FaceDefault,
    [AvatarItemsEnum.pants]: PantsDefault,
    [AvatarItemsEnum.shirt]: ShirtDefault,
  };

  if (Object.keys(defaultItems).includes(category)) {
    if (baseItem) {
      return (
        <AvatarItem
          category={category}
          img={baseItem.image}
          color={avatarCategory.color || baseItem.baseColor}
        />
      );
    } else
      return (
        <AvatarItem
          category={category}
          img={defaultItems[category as keyof typeof defaultItems]}
          color={avatarCategory.color || 'white'}
        />
      );
  } else if (baseItem) {
    return (
      <AvatarItem
        category={category}
        img={baseItem.image}
        color={avatarCategory.color || baseItem.baseColor}
      />
    );
  } else return <React.Fragment />;
};
