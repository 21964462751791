import en from '@/translations/en';
import pt_BR from '@/translations/pt_BR';
import EditorJS, {
  EditorConfig,
  I18nDictionary,
  OutputData,
} from '@editorjs/editorjs';
import { debounce, isEqual } from 'lodash';
import moment from 'moment';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

type useEditorProps = {
  onChange?: (value: OutputData) => void;
} & Omit<EditorConfig, 'onChange'>;

const useEditorTranslate = (): I18nDictionary => {
  const { i18n } = useTranslation('translation');

  const lng = i18n.language;

  const translations: Record<string, I18nDictionary> = {
    ptBr: pt_BR.editorJs,
    en: en.editorJs,
  };

  return translations[lng] || translations['ptBr'];
};

export const DEFAULT_EDITORJS_DATA = {
  blocks: [],
  time: moment().toDate().getTime(),
  version: '2.30.2',
};

export const useEditorJS = ({
  onChange,
  readOnly,
  onReady,
  data = DEFAULT_EDITORJS_DATA,
  ...editorProps
}: useEditorProps) => {
  const translation = useEditorTranslate();
  const intervalValue = useRef<OutputData | undefined>(data);
  const instance = useRef<EditorJS | null>(null);
  const [element, setElement] = useState<HTMLElement | null>(null);

  const destroy = useCallback(async () => {
    if (instance.current) {
      await instance.current.isReady;
      try {
        instance.current.destroy();
      } catch (err) {}
      instance.current = null;
    }
  }, []);

  const init = useCallback(
    (holder: HTMLElement) => {
      instance.current = new EditorJS({
        ...editorProps,
        holder,
        data,
        readOnly,
        i18n: { messages: translation },
        onReady: async () => {
          await instance.current?.isReady;
          instance.current?.toolbar.open();
          onReady?.();
        },

        onChange: async (_api, _event) => {
          const value =
            (await instance?.current?.save()) || DEFAULT_EDITORJS_DATA;
          intervalValue.current = value;
          onChange?.(value);
        },
      });
    },
    [data, editorProps, onChange, onReady, readOnly, translation],
  );

  useEffect(() => {
    if (instance.current === null && element) init(element);
  }, [element, init]);

  useEffect(() => {
    const changeValue = async () => {
      const hasChange = !isEqual(intervalValue.current, data);
      if (instance.current?.render && hasChange && data)
        instance.current.render(data);
    };
    changeValue();
  }, [data]);

  useEffect(() => {
    const toogleReadOnly = async () => {
      if (instance.current) {
        await instance.current.readOnly.toggle(readOnly);
        !readOnly && instance.current?.focus();
      }
    };
    toogleReadOnly();
  }, [readOnly]);

  useEffect(() => {
    const rerenderOnResize = debounce(async () => {
      if (!element) return;
      await instance.current?.isReady;
      destroy().then(() => init(element));
    }, 500);
    window.addEventListener('resize', rerenderOnResize);
    return () => {
      window.removeEventListener('resize', rerenderOnResize);
    };
  }, [destroy, element, init]);

  useEffect(() => {
    return () => {
      destroy();
    };
  }, [destroy]);

  return { element, setElement };
};
