import User from '@/models/User';
import UserProfile from '@/models/UserProfile';
import { useTranslation } from 'react-i18next';
import Tag from '@/components/common/dataDisplay/Tag';
import { InformationCircleIcon, PencilIcon } from '@heroicons/react/outline';
import { UserTypeEnum } from '@/models/User';
import ComponentGuard from '@/components/common/ComponentGuard';
import MainButton from '@/components/common/buttons/MainButton';
import StudentForm from './StudentForm';
import Rewards from '@/models/Rewards';
import { useState } from 'react';

interface EditUserProps {
  user: User;
  profile?: UserProfile;
  rewards?: Rewards;
  isLoadingRewards?: boolean;
}

export default function EditStudent({
  profile,
  user,
  rewards,
  isLoadingRewards,
}: EditUserProps) {
  const { t } = useTranslation('translation', {
    keyPrefix: 'manageStudent.addUser',
  });

  const [editMode, setEditMode] = useState(false);

  return (
    <div className="flex flex-col w-full pt-5 pb-5 gap-3 px-8">
      <div className="flex w-auto items-center gap-8">
        <Tag
          text={t('info')}
          icon={<InformationCircleIcon className="w-6 h-6" />}
        />
        <ComponentGuard roles={[UserTypeEnum.SUPER_ADMIN]}>
          <MainButton
            id="editButton"
            dataTestId="editButton"
            disabled={editMode}
            onClick={() => setEditMode(true)}
            className="text-primary"
            color="custom"
            icon={<PencilIcon />}
            text={t('edit')}
          />
        </ComponentGuard>
      </div>
      <StudentForm
        user={user}
        profile={profile}
        disabled={!editMode}
        onCancel={() => setEditMode(false)}
        className="w-[60%]"
        rewards={rewards}
        isLoadingRewards={isLoadingRewards}
      />
    </div>
  );
}
