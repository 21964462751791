import { isEqual } from 'lodash';

type Callback = (value: boolean) => void;

export const hanldeFormChange = <T>(
  initialValue: T,
  fields: Partial<T>,
  cb: Callback,
) => {
  const hasChanges = !isEqual(initialValue ?? {}, fields);
  cb(hasChanges);
};
