import useStudentContext from '@/data/hook/student';
import { GuestPage } from '@/pages/guest/GuestPage';
import Text from '@/components/common/dataDisplay/Text';
import useAuth from '@/data/hook/useAuth';
import { CourseProgressView } from '@/components/dashboard/student/CourseProgressView/CourseProgressView';
import { KlassMembers } from '@/components/dashboard/student/KlassMembers';
import AstroCountdown from '@/components/common/AstroCountdown';
import LoadingView from '@/pages/courses/LoadingView';
import { getErrorMessage } from '@/utils/getErrorMessage';
import { SelectProgress } from '@/components/courses/student/SelectProgress';
import { Wellcome } from '@/components/common/Wellcome';
import ConditionalRenderer from '@/components/common/ConditionalRenderer';

export default function StudentDashboardView() {
  const { user } = useAuth();

  const {
    loadingProgress,
    progressError,
    currentProgress,
    progress,

    loadingKlass,
    klass,

    loadingNextLesson,
    nextLesson,
  } = useStudentContext();

  if (user) {
    if (loadingProgress) return <LoadingView />;

    if (progressError)
      return (
        <Text
          text={getErrorMessage(progressError)}
          format="rubik-500"
          className="text-primary"
        />
      );

    if (progress.length && currentProgress)
      return (
        <main className="flex flex-col gap-4">
          <Wellcome userType={user.userType} firstName={user.firstName} />

          <SelectProgress userId={user.id} selected={currentProgress} />

          <CourseProgressView user={user} progress={currentProgress} />

          <section className="flex gap-4 flex-wrap">
            <ConditionalRenderer condition={klass}>
              <KlassMembers loading={loadingKlass} klass={klass} />
            </ConditionalRenderer>
            <AstroCountdown
              klass={klass}
              nextLesson={nextLesson}
              loading={loadingNextLesson}
            />
          </section>
        </main>
      );

    return <GuestPage user={user} />;
  }

  return null;
}
