import Text from './Text';
import Snackbar from '@mui/material/Snackbar';
import SnackbarAlert from '@mui/material/Alert';
import {
  CheckIcon,
  InformationCircleIcon,
  ShieldExclamationIcon,
  XIcon,
} from '@heroicons/react/outline';

export enum TypeAlertEnum {
  SUCCESS = 'success',
  WARNING = 'warning',
  ERROR = 'error',
  INFO = 'info',
}

export enum PositionEnum {
  TOP = 'top',
  BOTTOM = 'bottom',
  LEFT = 'left',
  RIGHT = 'right',
  CENTER = 'center',
}

type VerticalPositionType = 'top' | 'bottom';
type HorizontalPositionType = 'left' | 'center' | 'right';

export interface AlertPosition {
  vertical: VerticalPositionType;
  horizontal: HorizontalPositionType;
}

interface AlertProps {
  testId?: string;
  message: string;
  className?: string;
  type: TypeAlertEnum;
  icon?: React.ReactNode | JSX.Element;
  position?: AlertPosition;
}

export const Alert = ({
  message,
  testId,
  type,
  position,
  className,
  icon,
}: AlertProps) => {
  const positionChoice = position || {
    vertical: PositionEnum.TOP,
    horizontal: PositionEnum.CENTER,
  };

  type MessageType =
    | TypeAlertEnum.SUCCESS
    | TypeAlertEnum.ERROR
    | TypeAlertEnum.INFO
    | TypeAlertEnum.WARNING;

  const colorClasses: Record<MessageType, string> = {
    success: 'text-success',
    warning: 'text-warning',
    error: 'text-error',
    info: 'text-primary',
  };

  const iconClasses: Record<MessageType, React.ReactNode | JSX.Element> = {
    success: (
      <CheckIcon
        className={`h-6 ${colorClasses[type] || TypeAlertEnum.INFO}`}
      />
    ),
    warning: (
      <ShieldExclamationIcon
        className={`h-6 ${colorClasses[type] || TypeAlertEnum.INFO}`}
      />
    ),
    error: (
      <XIcon className={`h-6 ${colorClasses[type] || TypeAlertEnum.INFO}`} />
    ),
    info: (
      <InformationCircleIcon
        className={`h-6 ${colorClasses[type] || TypeAlertEnum.INFO}`}
      />
    ),
  };

  return (
    <Snackbar
      data-testid={testId}
      anchorOrigin={positionChoice}
      open
      className={colorClasses[type] || TypeAlertEnum.INFO}
    >
      <SnackbarAlert
        icon={icon || iconClasses[type] || TypeAlertEnum.INFO}
        severity={type}
        className={`flex items-center ${className || 'rounded-full'}`}
      >
        <Text
          text={message}
          format="rubik-500"
          size="text-16"
          className={colorClasses[type] || TypeAlertEnum.INFO}
        />
      </SnackbarAlert>
    </Snackbar>
  );
};
