import { PencilIcon } from '@heroicons/react/outline';
import React from 'react';
import { useTranslation } from 'react-i18next';
import MainButton from '../../components/common/buttons/MainButton';
import ComponentGuard from '../../components/common/ComponentGuard';
import { UserTypeEnum } from '../../models/User';
import PageTitle from '../../components/common/PageTitle';
import HeadTitle from '../../components/common/HeadTitle';
import CoursesTab from '../../components/common/dataDisplay/course/coursesTab/CoursesTab';

export default function AdminCoursesView() {
  const { t } = useTranslation('translation', {
    keyPrefix: 'coursesView.admin',
  });

  return (
    <React.Fragment>
      <HeadTitle routeInfo={t('courses')} />
      <PageTitle headingText={t('courses')} position="mb-4 md:mb-2" />
      <ComponentGuard roles={[UserTypeEnum.SUPER_ADMIN]}>
        <div className="md:flex justify-end hidden">
          <MainButton
            href="/admin/courses/add"
            icon={<PencilIcon className="text-base-100" />}
            text={t('newCourse')}
          />
        </div>
      </ComponentGuard>
      <CoursesTab />
    </React.Fragment>
  );
}
