import { useTranslation } from 'react-i18next';

import ComponentGuard from '@/components/common/ComponentGuard';
import ConditionalRenderer from '@/components/common/ConditionalRenderer';
import InfiniteSearchInput from '@/components/common/dataInput/InfiniteSearchInput';
import { ListKlassesFilters } from '@/data/services/klassServices';
import { usersQueryKeys, unitsQueryKeys } from '@/data/services/querykeys';
import { handleUserFullName } from '@/functions/handleUserFullName';
import User, { UserStatusEnum, UserTypeEnum } from '@/models/User';
import { getAuthorizedUnits } from '@/utils/getAuthorizedUnits';
import TeachingWeekFilters from './TeachingWeekFilters';
import { canAccessMultipleUnits } from '@/utils/canAccessMultipleUnits';
import { ClassScheduleFiltersProps } from '@/models/ClassScheduleFilters';
import { isUnitAdmin, isTeacher } from '@/functions/auth';
import { ClassNameComponent } from '@/utils/ClassNameComponent';

type TeachingWeekHeaderProps = Omit<
  ClassScheduleFiltersProps<ListKlassesFilters>,
  'type'
> & {
  className?: ClassNameComponent;
  updateFilter: (filter: ListKlassesFilters) => void;
  user: User;
};

export default function TeachingWeekHeader({
  user,
  events,
  updateFilters,
  updateFilter,
  filters,
}: TeachingWeekHeaderProps) {
  const { t } = useTranslation('translation', {
    keyPrefix: 'common',
  });

  const unitAdminView = isUnitAdmin(user.userType);

  const teacherView = isTeacher(user.userType);

  return (
    <nav
      className={`flex w-full justify-start text-base-content flex-col lg:flex-row ${
        teacherView ? 'gap-0' : 'gap-5'
      }`}
    >
      <div className="flex gap-3.5 flex-col sm:flex-row">
        <ComponentGuard
          roles={[UserTypeEnum.SUPER_ADMIN, UserTypeEnum.UNIT_ADMIN]}
        >
          <InfiniteSearchInput
            input={{
              placeholder: t('input.teacher.placeholder'),
              className: {
                base: `w-full lg:max-w-96 ${
                  unitAdminView ? 'sm:max-w-96' : ''
                }`,
              },
            }}
            service={usersQueryKeys.list}
            filters={{
              userType: [UserTypeEnum.TEACHER],
              status: [UserStatusEnum.REGISTRED],
              unit: getAuthorizedUnits(user),
            }}
            displayName={user => handleUserFullName(user)}
            onSelect={({ id: teacherId }) => updateFilter({ teacherId })}
            onDeselect={() => updateFilter({ teacherId: undefined })}
          />
        </ComponentGuard>

        <ConditionalRenderer condition={canAccessMultipleUnits(user)}>
          <InfiniteSearchInput
            input={{
              placeholder: t('input.unit.placeholder'),
              className: {
                base: 'w-full lg:max-w-96',
              },
            }}
            service={unitsQueryKeys.list}
            filters={{
              idIn: getAuthorizedUnits(user)?.toString(),
              isActive: true,
            }}
            displayName={unit => unit.name}
            onSelect={({ id: unitId }) => updateFilter({ unitId: [unitId] })}
            onDeselect={() => updateFilter({ unitId: undefined })}
          />
        </ConditionalRenderer>
      </div>

      <TeachingWeekFilters
        events={events}
        updateFilters={updateFilters}
        updateFilter={updateFilter}
        filters={filters}
      />
    </nav>
  );
}
