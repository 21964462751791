export function register() {
  if ('serviceWorker' in navigator) {
    window.addEventListener('load', () => {
      const swUrl = `/service-worker.js`;

      navigator.serviceWorker.register(swUrl).catch(error => {
        console.error('Erro ao registrar o Service Worker:', error);
      });
    });
  }
}
