/// <reference types="youtube" />
import { Fragment, useRef } from 'react';
import useStudentContext from '@/data/hook/student';
import useAuth from '@/data/hook/useAuth';
import { UserTypeEnum } from '@/models/User';
import YoutubePlayer from './YoutubePlayer';
import { useQuery, useMutation } from '@tanstack/react-query';
import { useParams } from 'react-router-dom';
import HeadTitle from './HeadTitle';
import { makeLessonHeadTitle } from '@/utils/lessonHeadTitle';
import { useTranslation } from 'react-i18next';
import useLessonContext from '@/data/hook/lesson';
import { updateLessonProgress } from '@/data/services/lessonProgressServices';
import { coursePathsQueryKeys } from '@/data/services/querykeys';

export default function InstructionsVideo() {
  const {
    lesson,
    lessonProgress,
    updateLessonProgress: updateLessonProgressContext,
    scheduledLesson,
  } = useLessonContext();

  const { hasWatchedInstruction, id: progressId } = lessonProgress || {};

  const { instructionsUrl, order } = lesson || {};

  const { t } = useTranslation('translation', {
    keyPrefix: 'klassView.instruction',
  });
  const isRequestingRef = useRef(false);
  const { user } = useAuth();
  const isStudent = user?.userType === UserTypeEnum.STUDENT;
  const { currentProgress } = useStudentContext();
  const { slugCourseName } = useParams();

  const { data: course } = useQuery({
    enabled: !!slugCourseName && !currentProgress,
    ...coursePathsQueryKeys.get(slugCourseName ?? ''),
  });
  const coursePath = course ?? currentProgress?.coursePath;

  const title = `${t('accessAndInstalation')} - ${makeLessonHeadTitle({
    lessonOrder: order || 0,
    coursePath,
    lessonType: scheduledLesson?.type,
  })}`;

  const onVideoProgress = async (currentProgress: number) => {
    if (currentProgress >= 0.8 && progressId) {
      const lessonProgress = {
        hasWatchedInstruction: true,
      };
      const lessonProgressUpdated = await updateLessonProgress({
        lessonProgressId: progressId,
        lessonProgress,
      });
      return lessonProgressUpdated;
    } else return;
  };

  const { mutate: videoProgress } = useMutation(onVideoProgress, {
    onSuccess: updateLessonProgressContext,
  });

  if (!instructionsUrl) {
    return <Fragment />;
  }

  return (
    <div className="container-astro">
      <HeadTitle routeInfo={title} />
      <div className="video-responsive">
        <YoutubePlayer
          videoUrl={instructionsUrl}
          onVideoProgress={videoProgress}
          shouldWatchProgress={
            !isRequestingRef.current && !hasWatchedInstruction && isStudent
          }
        />
      </div>
    </div>
  );
}
