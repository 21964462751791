import { useTranslation } from 'react-i18next';
import { useQuery } from '@tanstack/react-query';
import { useMediaQuery } from 'react-responsive';
import { twMerge } from 'tailwind-merge';

import ConditionalWrapper from '@/components/common/ConditionalWrapper';
import { Tooltip } from '@/components/common/dataDisplay/Tooltip';
import { REQUEST_STALE_TIME_IN_MS } from '@/constants';
import { klassesQueryKeys } from '@/data/services/querykeys';
import MainButton from '@/components/common/buttons/MainButton';
import { Breakpoints } from '@/utils/Breakpoint';
import { WhatsAppIcon } from '@/components/icons';
import Skeleton from '@/components/common/Skeleton';

type KlassGroupButtonProps = {
  klassId: number;
};

export default function KlassGroupButton({ klassId }: KlassGroupButtonProps) {
  const { t } = useTranslation('translation', {
    keyPrefix: 'klassGroupButton',
  });

  const isDesktop = useMediaQuery({ minWidth: Breakpoints.xl });

  const { data, isInitialLoading: loading } = useQuery({
    ...klassesQueryKeys.group(klassId),
    staleTime: REQUEST_STALE_TIME_IN_MS,
  });

  if (loading)
    return (
      <Skeleton
        testId="loadingKlassGroup"
        className={twMerge(
          'h-6 rounded-full bg-primary-content',
          isDesktop ? 'px-2 py-1 w-36 text-base-100' : 'p-1 w-6',
        )}
      />
    );

  if (data) {
    const { klassGroup } = data;

    const handleGroupLink = (klassGroup: string | null) =>
      window.open(klassGroup ?? '', '_blank')?.focus();

    return (
      <ConditionalWrapper
        condition={!klassGroup}
        wrapper={<Tooltip text={t('tooltip')} />}
      >
        <MainButton
          dataTestId="klassGroupButton"
          className={twMerge(
            'gap-1.5 items-center disabled:pointer-events-none text-[0.875rem] h-6 rounded-full bg-success lowercase',
            isDesktop ? 'px-2 py-1 min-w-36 text-base-100' : 'p-1',
          )}
          color="custom"
          icon={<WhatsAppIcon className="h-4 w-4 text-base-100" />}
          size="regular"
          text={isDesktop ? t('textButton') : ''}
          disabled={!klassGroup}
          onClick={() => handleGroupLink(klassGroup)}
        />
      </ConditionalWrapper>
    );
  }

  return null;
}
