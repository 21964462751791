import Text from '../../common/dataDisplay/Text';
import { stringAnswer } from '../../../utils/activities';
import { AttemptAnswer } from '../../../models/ActivityProgress';

interface SelectedAnswerProps extends AttemptAnswer {}

function SelectedAnswer({
  answer,
  isCorrect,
  gradePercent,
}: SelectedAnswerProps) {
  if (typeof gradePercent !== 'undefined') {
    const grade = Math.trunc(gradePercent * 100) + '%';

    return (
      <div
        className={`flex justify-center items-center w-16 h-6 shrink-0 rounded-full ${
          gradePercent === 1 ? 'bg-success' : 'bg-error'
        }`}
      >
        <Text text={grade} className="text-base-200 p-0 " />
      </div>
    );
  }

  return (
    <div
      className={`flex justify-center items-center w-6 h-6 shrink-0 rounded-full ${
        isCorrect ? 'bg-success' : 'bg-error'
      }`}
    >
      <Text text={stringAnswer(answer)} className="text-base-200 p-0" />
    </div>
  );
}

export default SelectedAnswer;
