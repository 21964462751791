import { HTMLAttributes } from 'react';
import Text from '../../dataDisplay/Text';
import { twMerge } from 'tailwind-merge';

const ActionGroupContainer = ({
  children,
  title,
  className,
}: HTMLAttributes<HTMLDivElement>) => (
  <aside className="flex flex-col items-start border-b-2 border-base-200 mb-4 w-full last-of-type:border-b-0 last-of-type:mb-0">
    <Text text={title} size="text-16" format="rubik-500" className="mb-2" />
    <nav className={twMerge('flex flex-col my-4 w-full', className)}>
      {children}
    </nav>
  </aside>
);

export default ActionGroupContainer;
