import {
  MutableRefObject,
  useCallback,
  useEffect,
  useState,
} from 'react';

export default function useVisibleElement(
  element: MutableRefObject<Element | null> | null,
  options?: IntersectionObserverInit,
) {
  const [intersecting, setIntersecting] = useState(false);

  const onIntersect = useCallback(
    ([entry]: IntersectionObserverEntry[]) =>
      setIntersecting(entry.isIntersecting),
    [],
  );

  useEffect(() => {
    const observer = new IntersectionObserver(
      entry => onIntersect(entry),
      options,
    );

    if (element && element.current) {
      observer.observe(element.current);
    }

    return () => observer.disconnect();
  }, [onIntersect, element, options]);

  return intersecting;
}
