import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import Text from '../common/dataDisplay/Text';
import useCourseEditing from '../../data/hook/useCourseEditing';
import ConditionalRenderer from '../common/ConditionalRenderer';

interface SidebarItemProps {
  mobile?: boolean;
  text: string;
  icon: JSX.Element;
  url: string;
  isMenuOpen: boolean;
  testId?: string;
  onClick?: () => void;
  renderCondition?: boolean;
  setRef?: (ref: HTMLAnchorElement) => void;
}

export default function SidebarItem({
  renderCondition,
  ...props
}: SidebarItemProps) {
  const { reorderScheduledLessons, preventExitWithChanges } =
    useCourseEditing();

  const navigate = useNavigate();
  const { text, icon, url, testId, onClick, isMenuOpen } = props;
  const path = window.location.pathname;
  const separator = path.split('/');
  const rootUrl = '/' + separator[1];
  const active = url === '/' ? rootUrl === url : rootUrl.includes(url);
  const isRenderConditionFalse = !renderCondition;

  if (typeof renderCondition !== 'undefined' && isRenderConditionFalse) {
    return <React.Fragment />;
  }

  const className = `flex w-full transition-all items-center min-w-fit duration-500 gap-3.5 ${
    !isMenuOpen ? 'p-1.5' : 'px-6 py-3 lg:px-5 lg:py-2.5'
  } rounded-full ${
    active
      ? 'bg-base-100 text-primary'
      : 'bg-transparent hover:bg-base-100/20 text-base-100'
  }`;

  const onClickHandler = () => {
    preventExitWithChanges(() => {
      onClick?.();
      navigate(url);
    });
  };

  return (
    <Link
      data-testid={testId}
      to={reorderScheduledLessons.length ? '' : url}
      onClick={onClickHandler}
      className={className}
      ref={props.setRef}
    >
      <div className="w-5 h-5">{icon}</div>
      <ConditionalRenderer condition={isMenuOpen}>
        <Text
          format="poppins-600"
          text={text}
          className={`pt-0.5 ${active ? 'text-primary' : 'text-base-100'}`}
        />
      </ConditionalRenderer>
    </Link>
  );
}
