import BackButton from './BackButton';
import { ProgressSpace } from './ProgressIcons';
import Skeleton from './Skeleton';
import ConditionalRenderer from './ConditionalRenderer';
import Text from './dataDisplay/Text';
type CoursePathCardProps = {
  title?: string;
  hasBackButton?: boolean;
  backUrl?: string;
  type?: string;
  isLoading?: boolean;
};

export default function CourseTitle(props: CoursePathCardProps) {
  const { title, type, hasBackButton, isLoading, backUrl } = props;

  if (isLoading)
    return (
      <Skeleton className="h-[61px] w-full bg-secondary-content rounded-lg" />
    );

  return (
    <div className="flex flex-col bg-secondary-content rounded-lg items-stretch shadow-default shadow-secondary-content mb-1">
      <div className="flex flex-row items-center pl-3.5 pr-8 py-2 ">
        <ProgressSpace className="mr-7" progress={100} type={type} />
        <Text
          format="rubik-500"
          id="course-card-title"
          text={title}
          className="text-secondary mr-auto text-18"
        />

        <ConditionalRenderer condition={hasBackButton}>
          <BackButton
            color="text-secondary"
            hoverColor="text-primary"
            url={backUrl}
          />
        </ConditionalRenderer>
      </div>
    </div>
  );
}
