import ConditionalRenderer from '@/components/common/ConditionalRenderer';
import ConditionalWrapper from '@/components/common/ConditionalWrapper';
import Tag, { TagColor } from '@/components/common/dataDisplay/Tag';
import { Tooltip } from '@/components/common/dataDisplay/Tooltip';
import ScheduledLesson from '@/models/ScheduledLesson';
import { PresenceEnum } from '@/models/ScheduledLessonReport';
import {
  FormatScheduledDate,
  FormatTypeEnum,
} from '@/utils/FormatScheduledDate';
import { CalendarIcon, CheckIcon, XIcon } from '@heroicons/react/outline';
import moment from 'moment';
import { useTranslation } from 'react-i18next';

type LessonBarDateProps = {
  scheduledLesson?: ScheduledLesson;
  presence?: PresenceEnum | null;
};

export default function LessonBarDate({
  scheduledLesson,
  presence,
}: LessonBarDateProps) {
  const className =
    'right-3.5 -top-2.5 gap-1 py-[3px] px-2 rounded-md absolute lg:top-0 lg:right-0 lg:relative lg:rounded-full lg:py-0.5';
  const { t } = useTranslation('translation');

  const tootipPresence: Record<PresenceEnum, string> = {
    ATTENDED: t('klassProgressPage.attended'),
    MISSED: t('klassProgressPage.missed'),
    NONE: t('klassProgressPage.lessonScheduled'),
    RESCHEDULED: t('klassProgressPage.transferred'),
  };

  const tooltipText = tootipPresence[presence || PresenceEnum.NONE];

  const color = setColor({
    presence,
    dateTime: scheduledLesson?.datetime,
  });

  const renderCondition = !!scheduledLesson && scheduledLesson.isActive;

  return (
    <ConditionalRenderer condition={renderCondition}>
      <ConditionalWrapper
        condition={!!presence || presence === null}
        wrapper={<Tooltip text={tooltipText} testId="tooltipDate" />}
      >
        <Tag
          testId="dateTag"
          text={FormatScheduledDate(
            scheduledLesson,
            undefined,
            FormatTypeEnum.DAY_AND_MONTH,
          )}
          className={className}
          color={color}
          icon={<Icon presence={presence} />}
        />
      </ConditionalWrapper>
    </ConditionalRenderer>
  );
}

const Icon = ({ presence }: { presence?: PresenceEnum | null }) => {
  if (typeof presence === 'undefined') return null;

  switch (presence) {
    case PresenceEnum.ATTENDED:
      return <CheckIcon data-testid="checkIcon" className="w-4" />;
    case PresenceEnum.MISSED:
      return <XIcon data-testid="xIcon" className="w-4" />;
    default:
      return <CalendarIcon data-testid="calendarIcon" className="w-4" />;
  }
};

type SetColorProps = {
  presence?: PresenceEnum | null;
  dateTime?: string;
};

const setColor = ({ presence, dateTime }: SetColorProps) => {
  if (presence || presence === null) {
    const status: { [key in PresenceEnum]: TagColor } = {
      ATTENDED: 'confirm',
      MISSED: 'warning',
      RESCHEDULED: 'info',
      NONE: 'accent',
    };

    return status[presence ?? PresenceEnum.NONE];
  }

  if (dateTime) {
    const date = moment(dateTime);
    if (date.isAfter(moment())) {
      return 'accent';
    }

    return 'confirm';
  }
};
