import { isAdmin, isSuperAdmin } from '@/functions/auth';
import Klass from '@/models/Klass';
import ScheduledLesson from '@/models/ScheduledLesson';
import User from '@/models/User';
import moment from 'moment';

type Permissions = {
  user?: Pick<User, 'unitsIds' | 'userType'>;
  klass?: Pick<Klass, 'unitId' | 'enrollsCount'>;
  scheduledLesson?: Pick<ScheduledLesson, 'hasDone' | 'datetime'>;
};

export const PERMISSIONS = {
  SCHEDULED_LESSON: {
    EDIT: ({ user, klass }: Permissions) => {
      if (!user || !klass) return false;

      const { unitsIds, userType } = user;
      const { unitId } = klass;

      const superAdminLogged = isSuperAdmin(userType);

      const belongsKlassUnit = unitsIds.includes(unitId);

      return superAdminLogged || belongsKlassUnit;
    },
    ANTECIPATE: ({ scheduledLesson }: Permissions) => {
      if (!scheduledLesson) return false;

      const { hasDone, datetime } = scheduledLesson;

      return !hasDone || moment(datetime).isAfter(moment());
    },
  },
  SCHEDULED_LESSON_REPORT: {
    ACCESS: ({ user, klass }: Permissions) => {
      if (!user || !klass) return false;

      const { userType } = user;
      const { enrollsCount } = klass;

      const adminLogged = isAdmin(userType);

      return enrollsCount !== 0 || adminLogged;
    },
  },
};
