import { LessonFormType } from '@/components/forms/LessonForm';
import { listAreaParents } from '@/data/services/areaServices';
import { areasQueryKeys } from '@/data/services/querykeys';
import Area from '@/models/Area';
import { SimpleTag } from '@/models/Tag';
import { extractParents } from '@/utils/area';
import { Control, useFieldArray, useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import InfiniteSearchInput from '../../InfiniteSearchInput';
import TagInput from '../TagInput';
import ListTopics from './ListTopics';
import { useInitTags } from './TopicsInput.hook';
import useStack from '@/data/hook/useStack';

type TopicsInputProps = {
  tagsIds?: number[];
  control?: Control<LessonFormType>;
};

export default function TopicsInput({
  tagsIds = [],
  control,
}: TopicsInputProps) {
  const { t } = useTranslation('translation', {
    keyPrefix: 'lessonForm',
  });
  const { remove } = useFieldArray({
    control,
    name: 'tags',
  });

  const tags = useWatch({ control, name: 'tags' });

  const { isLoading } = useInitTags(tagsIds, control);

  const onDelete = (tag: SimpleTag) => {
    const index = tags?.findIndex(field => field.id === tag.id);
    remove(index);
  };

  return (
    <TagInput
      label={t('topics')}
      tags={tags}
      isLoading={isLoading}
      onDelete={onDelete}
      className={{
        tag: '!text-12 border-[1px] border-primary bg-primary-content text-primary font-400',
      }}
      addTagComponent={<ManageTopics control={control} />}
    />
  );
}

const ManageTopics = ({ control }: { control?: Control<LessonFormType> }) => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'topics.input',
  });
  const stack = useStack<Area>();

  const currentArea = stack.getTop();

  const onSelectArea = async (area: Area) => {
    const parents = await listAreaParents(area.id);
    const parentsExtract = extractParents(parents.results[0]);
    if (area.isLeaf) {
      stack.setStack(parentsExtract.slice(0, -1));
      return;
    }
    stack.setStack(parentsExtract);
  };

  return (
    <div className={mainClassNameList}>
      <InfiniteSearchInput
        onSelect={onSelectArea}
        service={areasQueryKeys.list}
        displayName={(area: Area) => area.name}
        input={{
          placeholder: t('searchForTopics'),
          className: {
            base: 'w-full',
          },
        }}
      />
      <ListTopics area={currentArea} stack={stack} control={control} />
    </div>
  );
};

const mainClassNameList =
  'flex flex-col items-center relative w-full z-30 p-4 bg-base-100 rounded-md shadow-default border border-primary-content h-auto max-h-64 gap-2.5 overflow-visible z-30 divide-y divide-primary-content';
