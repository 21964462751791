import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import useAuth from '../../data/hook/useAuth';
import LoadingView from '../../pages/courses/LoadingView';

export default function ForceAuth(props: any) {
  const { user, isLoading } = useAuth();
  const hasUser = !!user;

  const navigate = useNavigate();

  useEffect(() => {
    let mounted = true;
    const controller = new AbortController();
    if (mounted && !isLoading && !hasUser) {
      navigate('/login');
    }

    return () => {
      mounted = false;
      controller.abort();
    };
  }, [isLoading, hasUser, navigate]);

  if (!isLoading && hasUser) {
    return props.children;
  } else if (isLoading) {
    return <LoadingView />;
  } else {
    return null;
  }
}
