import { Fragment, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import useStudentContext from '@/data/hook/student';
import { GuestPage } from '@/pages/guest/GuestPage';
import {
  AstroBreadcrumb,
  breadcrumbArray,
} from '@/components/common/AstroBreadcrumb';
import { ICourse } from '@/models/Course';
import PageTitle from '@/components/common/PageTitle';
import Text from '@/components/common/dataDisplay/Text';
import CourseProgressList from '@/components/courses/CourseProgressList';
import LoadingView from '../LoadingView';
import KlassProgress from '@/components/courses/student/KlassProgress';
import ConditionalRenderer from '@/components/common/ConditionalRenderer';
import HeadTitle from '@/components/common/HeadTitle';
import useAuth from '@/data/hook/useAuth';
import { SelectProgress } from '@/components/courses/student/SelectProgress';
import { getErrorMessage } from '@/utils/getErrorMessage';
import { enrollmentValidation } from '@/utils/enrollmentValidation';

export default function StudentCoursesView() {
  const { t } = useTranslation('translation', {
    keyPrefix: 'coursesView',
  });
  const {
    nextSteps,

    loadingProgress,
    progressError,
    progress,
    currentProgress,
  } = useStudentContext();

  const breadcrumbCourse: breadcrumbArray[] = [
    { url: '/', name: 'Home', routeType: 'home' },
    { url: '/courses', name: t('myCourses'), routeType: 'courses' },
    {
      name: currentProgress?.coursePath.course.name || '',
      url: '/courses',
      routeType: 'courses',
    },
  ];

  const [completedCourses, setCompletedCourses] = useState<ICourse[]>([]);

  const { user } = useAuth();

  useEffect(() => {
    let mounted = true;
    if (mounted && !completedCourses) {
      const completedProgresses: ICourse[] = [];
      progress?.forEach(({ progress, coursePath }) => {
        if (progress === 1) {
          completedProgresses.push(coursePath.course);
        }
      });
      setCompletedCourses(completedProgresses);
    }

    return () => {
      mounted = false;
    };
  }, [completedCourses, progress]);

  if (user) {
    if (loadingProgress) return <LoadingView />;
    if (progressError)
      return (
        <Fragment>
          <HeadTitle routeInfo={t('myCourses')} />
          <Text
            text={getErrorMessage(progressError)}
            format="rubik-500"
            className="text-primary"
          />
        </Fragment>
      );
    if (progress.length && currentProgress) {
      const courseAbbreviation = currentProgress.coursePath.course.abbreviation;
      const title = `${courseAbbreviation} - ${t('myCourses')}`;

      const validEnrollment = enrollmentValidation(
        currentProgress.enrollmentStatus,
      );

      const currentSameCourses = [
        currentProgress.coursePath.course,
        ...nextSteps.filter(
          data =>
            data.courseType === currentProgress.coursePath.course.courseType,
        ),
      ];

      const currentNextSteps = nextSteps.filter(
        data =>
          data.courseType !== currentProgress.coursePath.course.courseType,
      );

      return (
        <main className="flex flex-col gap-6">
          <HeadTitle routeInfo={title} />

          <AstroBreadcrumb breadcrumbList={breadcrumbCourse} />
          <section className="flex flex-col justify-between">
            <PageTitle
              headingText={t('myCourses')}
              subheadingText={t('myCoursesInstructions')}
              position="mb-3.5"
            />
            <SelectProgress userId={user.id} selected={currentProgress} />
          </section>

          <CourseProgressList
            currentProgress={currentProgress}
            courses={currentSameCourses}
          />

          <KlassProgress progress={currentProgress} />

          <ConditionalRenderer
            condition={currentNextSteps.length && validEnrollment}
          >
            <section className="flex flex-col gap-6">
              <div className="flex flex-col gap-2.5">
                <Text
                  text={t('nextSteps')}
                  className="text-28 text-primary"
                  format="poppins-600"
                />
                <Text text={t('continueText')} />
              </div>

              <CourseProgressList
                courses={currentNextSteps}
                testId="nextSteps"
              />
            </section>
          </ConditionalRenderer>
        </main>
      );
    }
    return <GuestPage user={user} />;
  }

  return null;
}
