import IconButton from '@/components/common/buttons/IconButton';
import { MoveAreaIcon } from '@/components/icons';
import Area from '@/models/Area';
import { PencilIcon } from '@heroicons/react/outline';
import { Dispatch, SetStateAction } from 'react';
import { useTranslation } from 'react-i18next';
import AddArea from './AddArea';
import DeleteArea from './DeleteArea';

type setStateFn = Dispatch<SetStateAction<boolean>>;

export type ActionsProps = {
  area: Area;
  setIsRenaming: setStateFn;
  setIsMoving: setStateFn;
};
const Actions = ({ area, setIsMoving, setIsRenaming }: ActionsProps) => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'topics.tooltip',
  });
  return (
    <div className="flex gap-2.5 items-center">
      <AddArea area={area} />
      <IconButton
        icon={<PencilIcon className="w-5 h-5 text-primary" />}
        onClick={() => setIsRenaming(prev => !prev)}
        tooltip={t('renaming')}
      />
      <IconButton
        icon={<MoveAreaIcon className="w-5 h-5 text-primary" />}
        onClick={() => setIsMoving(prev => !prev)}
        loadingSize="w-5 h-5 text-primary"
        tooltip={t('move')}
      />
      <DeleteArea area={area} backToParent />
    </div>
  );
};

export default Actions;
