import normalize from './normalize';

type Attendance = {
  presences: number;
  totalReports: number;
};

export default function attendancePercentage({
  presences,
  totalReports,
}: Attendance): number {
  const attendance = (presences / totalReports) * 100;
  return normalize(attendance);
}
