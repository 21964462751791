import { PlusIcon } from '@heroicons/react/outline';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import useCourseEditing from '../../../../../data/hook/useCourseEditing';
import MultipleChoiceAnswer from '../../../../../models/MultipleChoiceAnswer';
import MainButton from '../../../../common/buttons/MainButton';
import TooltipHandler from '../../../../common/TooltipHandler';
import AnswerListItem from './AnswerListItem';
import { QuizAnswersType } from '../../../../../models/ActivityElement';
import { AnswersInputError } from './QuizElementEdit';

interface AnswerListProps {
  onChange: (quizQuestions: MultipleChoiceAnswer[]) => void;
  quizQuestions: MultipleChoiceAnswer[];
  isRequesting: boolean;
  answerType: QuizAnswersType;
  answersErrors: AnswersInputError[];
}

export default function AnswerList({
  onChange,
  quizQuestions,
  isRequesting,
  answerType,
  answersErrors,
}: AnswerListProps) {
  const { t } = useTranslation('translation', {
    keyPrefix: 'activity.manageActivity.activityQuiz',
  });
  const { t: editRulesT } = useTranslation('translation', {
    keyPrefix: 'adminPage.editCourseRules',
  });
  const { allowBigChanges } = useCourseEditing();
  const answers = quizQuestions.map(item =>
    MultipleChoiceAnswer.createUsingObject(item),
  );
  const handleAddClick = () => {
    onChange([...answers, new MultipleChoiceAnswer('', false, false, 'new')]);
  };

  const handleRemoveClick = useCallback(
    (answers: MultipleChoiceAnswer[], answer: MultipleChoiceAnswer) => {
      const answersModified = [...answers];
      const questionIndex = answersModified.findIndex(
        question => question.answerVerifier === answer.answerVerifier,
      );
      answer.remove();
      if (answer.id === undefined) {
        answersModified.splice(questionIndex, 1);
      } else {
        answersModified.splice(questionIndex, 1, answer);
      }
      onChange(answersModified);
    },
    [onChange],
  );

  const handleAnswerChange = (
    answers: MultipleChoiceAnswer[],
    quizAnswer: MultipleChoiceAnswer,
  ) => {
    if (quizAnswer.editType !== undefined) {
      const answersModifier = [...answers];
      const answerIndex = answersModifier.findIndex(
        answer => answer.answerVerifier === quizAnswer.answerVerifier,
      );
      answersModifier.splice(answerIndex, 1);
      answersModifier.splice(answerIndex, 0, quizAnswer);

      onChange(answersModifier);
    }
  };

  return (
    <div className="flex flex-col gap-5">
      <div>
        {answers
          ?.filter(({ editType }) => editType !== 'remove')
          ?.map((item, index, items) => (
            <div key={item.answerVerifier}>
              <AnswerListItem
                answerType={answerType}
                isRequesting={isRequesting}
                id={index}
                answer={MultipleChoiceAnswer.createUsingObject(item)}
                onChange={answer => handleAnswerChange(answers, answer)}
                onRemoveClick={answer => handleRemoveClick(answers, answer)}
                errorMessage={
                  answersErrors.find(
                    ({ answerVerifier }) =>
                      answerVerifier === item.answerVerifier,
                  )?.errorMessage
                }
                allowRemove={items.length > 2}
              />
            </div>
          ))}
      </div>
      <TooltipHandler
        renderTooltip={!allowBigChanges}
        tooltipMessage={editRulesT('cantModify')}
      >
        <MainButton
          id="quiz-add-new-answer"
          disabled={!allowBigChanges}
          icon={<PlusIcon />}
          color="secondary"
          onClick={handleAddClick}
          text={t('newAnswer')}
        />
      </TooltipHandler>
    </div>
  );
}
