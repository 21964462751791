import { useTranslation } from 'react-i18next';

import Text from './dataDisplay/Text';
import User from '@/models/User';

type WellcomeProps = Pick<User, 'firstName' | 'userType'>;

export function Wellcome({ firstName, userType }: WellcomeProps) {
  const { t } = useTranslation('translation', {
    keyPrefix: 'wellcome',
  });

  return (
    <header className="flex flex-col gap-4">
      <Text
        text={t('hello', { firstName })}
        className="text-primary w-fit"
        format="poppins-600"
        size="text-28"
      />

      <Text
        text={t(userType.toLowerCase())}
        format="rubik-400"
        size="text-16"
        className="break-words"
      />
    </header>
  );
}
