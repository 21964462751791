import { SwitchHorizontalIcon } from '@heroicons/react/outline';
import { useTranslation } from 'react-i18next';
import { AnimatePresence, motion } from 'framer-motion';

import MainButton from '@/components/common/buttons/MainButton';
import { Avatar } from '@/components/common/dataDisplay/Avatar';
import PillComponent from '@/components/common/dataDisplay/PillComponent';
import Skeleton from '@/components/common/Skeleton';
import User from '@/models/User';
import { handleUserFullName } from '@/functions/handleUserFullName';
import Text from '@/components/common/dataDisplay/Text';
import { ROUTES } from '@/utils/routes';
import ConditionalRenderer from '@/components/common/ConditionalRenderer';
import { accordionX } from '@/utils/animations/commom';

type TeacherPillProps = {
  teacher?: User;
  setModalState: () => void;
  editing: boolean;
  loading: boolean;
  editPermission?: boolean;
};

export function TeacherPill({
  teacher,
  setModalState,
  editing,
  loading,
  editPermission,
}: TeacherPillProps) {
  const { t } = useTranslation('translation', {
    keyPrefix: 'calendar.admin.manageKlass',
  });

  if (loading)
    return (
      <Skeleton className="flex w-44 h-8 rounded-full bg-primary-content" />
    );

  if (teacher)
    return (
      <PillComponent testId="teacherPill" className="flex flex-wrap">
        <div className="flex gap-1 items-center overflow-hidden">
          <Avatar userId={teacher.id} testId="teacherAvatar" size="5" />
          <Text
            testId="teacherLink"
            className="shrink-0 text-[12px] md:text-[14px]"
            text={`${t('teacher')} ${teacher.firstName}`}
            href={ROUTES.ADMIN.TEACHERS.INFO({
              search: handleUserFullName(teacher),
            })}
          />
        </div>

        <ConditionalRenderer condition={editPermission}>
          <AnimatePresence>
            <motion.div {...accordionX(editing)} className="overflow-hidden">
              <MainButton
                icon={<SwitchHorizontalIcon />}
                color="custom"
                className="text-primary gap-1 ml-2 text-[12px] md:text-[14px]"
                onClick={setModalState}
                text={t('alter')}
              />
            </motion.div>
          </AnimatePresence>
        </ConditionalRenderer>
      </PillComponent>
    );

  return null;
}
