import {
  SolidGradientCheckCircleIcon,
  DashedCircleIcon,
  CircleIcon,
} from '../icons';
import ChapterProgress, { ChapterStatusEnum } from '@/models/ChapterProgress';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { bookType } from '@/models/Book';
import LessonCard, { LessonCardProps } from '../lessons/LessonCard/LessonCard';
import Chapter from '@/models/Chapter';
import { Fragment, useEffect, useRef } from 'react';
import LessonCardItem from '../lessons/LessonCard/LessonCardItem';
import ConditionalRenderer from './ConditionalRenderer';
import { useParams } from 'react-router-dom';
import { UserTypeEnum } from '@/models/User';
import ComponentGuard from './ComponentGuard';
import ConditionalWrapper from './ConditionalWrapper';
import { updateChapterProgress } from '@/data/services/chapterProgressServices';
import { REQUEST_STALE_TIME_IN_MS } from '@/constants';
import {
  bookProgressesQueryKeys,
  booksQueryKeys,
} from '@/data/services/querykeys';

type MaterialCardProps = LessonCardProps & {
  bookId?: number | null;
  bookProgressId?: number;
};

export default function MaterialCard(props: MaterialCardProps) {
  const { bookId, notification, bookProgressId, type, active } = props;

  const bookType: bookType = type === 'studentBook' ? 'STUDENT' : 'TEACHER';

  const { data: book, isInitialLoading: loadingChapters } = useQuery({
    enabled: !bookProgressId && !!bookId,
    staleTime: REQUEST_STALE_TIME_IN_MS,
    ...booksQueryKeys.get(bookId ?? 0),
  });

  const chapters = !bookProgressId ? book?.chapters : [];

  const { queryFn: bookProgressFn, queryKey: bookProgressQueryKey } =
    bookProgressesQueryKeys.get(bookProgressId ?? 0);

  const queryClient = useQueryClient();

  const { data: bookProgress, isInitialLoading: loadingProgress } = useQuery({
    enabled: !!bookProgressId,
    keepPreviousData: true,
    staleTime: REQUEST_STALE_TIME_IN_MS,
    queryFn: bookProgressFn,
    queryKey: bookProgressQueryKey,
  });

  const chaptersProgresses = bookProgress?.chaptersProgress;

  const updateProgress = async () =>
    await queryClient.invalidateQueries(bookProgressQueryKey);

  const loading = loadingChapters || loadingProgress;

  const guardWrapper = (
    <ComponentGuard
      roles={[
        UserTypeEnum.SUPER_ADMIN,
        UserTypeEnum.UNIT_ADMIN,
        UserTypeEnum.TEACHER,
      ]}
    />
  );

  return (
    <ConditionalWrapper
      condition={type === 'teacherBook'}
      wrapper={guardWrapper}
    >
      <ConditionalRenderer condition={bookId}>
        <LessonCard
          testId="materialCard"
          notification={notification}
          type={type}
          active={active}
          loading={loading}
        >
          <Chapters
            chapterProgress={chaptersProgresses}
            chapters={chapters}
            bookType={bookType}
            active={active}
            updateProgress={updateProgress}
          />
        </LessonCard>
      </ConditionalRenderer>
    </ConditionalWrapper>
  );
}

type ChaptersProps = Partial<MaterialCardProps> & {
  chapters?: Chapter[];
  chapterProgress?: ChapterProgress[];
  bookType: bookType;
  updateProgress(): Promise<void>;
};

function Chapters({
  chapterProgress,
  chapters,
  bookType,
  active,
  updateProgress,
}: ChaptersProps) {
  const { chapterOrder = '' } = useParams();

  const studentBook = bookType === 'STUDENT';

  const chapterLink = (order?: number) =>
    `${!studentBook ? 't/' : ''}book/chapter/${order}`;

  const ProgressIcon = ({ status }: { status?: ChapterStatusEnum }) => {
    if (status === ChapterStatusEnum.READ)
      return <SolidGradientCheckCircleIcon className="w-4 h-4 shrink-0" />;

    return <DashedCircleIcon className="w-4 h-4 shrink-0 text-secondary" />;
  };

  const chapter = chapterProgress?.find(
    progress => progress.chapterOrder === Number(chapterOrder),
  );

  const timerRef = useRef(0);

  useEffect(() => {
    timerRef.current = chapter?.timer ?? 0;
  }, [chapter?.timer]);

  useEffect(() => {
    const interval = setInterval(() => {
      timerRef.current = timerRef.current + 1;
    }, 1000);
    return () => clearInterval(interval);
  }, [timerRef]);

  const { mutate: update } = useMutation(updateChapterProgress, {
    async onSuccess() {
      await updateProgress();
    },
  });

  const onClick = () => {
    if (chapter) {
      const timer = timerRef.current;

      const progress = {
        timer,
      };

      update({
        chapterId: chapter.chapterId,
        chapterProgressId: chapter.id,
        chapterProgress: progress,
      });
    }

    window.scroll({
      top: 0,
      behavior: 'smooth',
    });
  };

  return (
    <ul className="flex flex-col gap-1 mt-4">
      {chapterProgress && (
        <Fragment>
          {chapterProgress.map((chapterProgress, index) => {
            const selected =
              active &&
              chapterOrder === chapterProgress.chapterOrder.toString();
            return (
              <LessonCardItem
                testId={`chapter${index}`}
                key={chapterProgress.id}
                index={index}
                icon={<ProgressIcon status={chapterProgress.status} />}
                content={chapterProgress.chapterTitle}
                link={chapterLink(chapterProgress.chapterOrder)}
                onClick={onClick}
                selected={selected}
              />
            );
          })}
        </Fragment>
      )}

      {chapters && (
        <Fragment>
          {chapters.map((chapter, index) => {
            const selected =
              active && chapterOrder === chapter.order?.toString();
            return (
              <LessonCardItem
                key={chapter.id}
                index={index}
                icon={<CircleIcon className="w-4 h-4 shrink-0 text-primary" />}
                content={chapter.title}
                link={chapterLink(chapter.order)}
                onClick={onClick}
                selected={selected}
              />
            );
          })}
        </Fragment>
      )}
    </ul>
  );
}
