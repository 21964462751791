import requester from '@/axios';
import {
  CodeActivityEditElement,
  CodeActivityElement,
} from '@/models/ActivityElement';

export const getCodeActivity = async (questionId: number) => {
  const { data: code } = await requester().get<CodeActivityElement>(
    `code-activity/${questionId}/`,
  );
  return code;
};

export const deleteCodeActivity = async (questionId: number) => {
  await requester().delete<CodeActivityElement>(`code-activity/${questionId}/`);
};

export const createCodeActivity = async (
  code: Omit<CodeActivityEditElement, 'id'>,
) => {
  const { data: newCode } = await requester().post<CodeActivityEditElement>(
    `code-activity/`,
    code,
  );
  return newCode;
};

export const updateCodeActivity = async (
  id: number,
  code: Partial<CodeActivityEditElement>,
) => {
  const { data: updatedCode } =
    await requester().patch<CodeActivityEditElement>(
      `code-activity/${id}/`,
      code,
    );
  return updatedCode;
};
