import RoundedButton from '@/components/common/buttons/RoundedButton';
import Text from '@/components/common/dataDisplay/Text';
import SelectInput from '@/components/common/dataInput/SelectInput';
import TextAreaInput from '@/components/common/dataInput/TextAreaInput';
import Modal, { ModalProps } from '@/components/common/modals/Modal';
import { tagsQueryKeys } from '@/data/services/querykeys';
import { createTag } from '@/data/services/tagServices';
import Area from '@/models/Area';
import Tag, { TagDifficultyEnum } from '@/models/Tag';
import { getErrorMessage } from '@/utils/getErrorMessage';
import alert from '@/utils/UseAlert';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

type ModalNewTagProps = {
  area: Area;
} & Omit<ModalProps, 'children'>;

type TagFormFields = Omit<Tag, 'id' | 'areaId'>;

export default function ModalNewTag({ area, ...modalProps }: ModalNewTagProps) {
  return (
    <Modal {...modalProps}>
      <div>
        <Text text={area.name} as="h2" className="text-primary mb-4 text-18" />
        {modalProps.visible && (
          <ModalNewTagForm
            area={area}
            onCancel={() => modalProps.onClose?.()}
          />
        )}
      </div>
    </Modal>
  );
}

type ModalNewTagFormProps = {
  area: Area;
  onCancel: () => void;
};

function ModalNewTagForm({ area, onCancel }: ModalNewTagFormProps) {
  const { t } = useTranslation('translation', {
    keyPrefix: 'topics.tags',
  });

  const queryClient = useQueryClient();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<TagFormFields>();

  const onAddTag = async (tag: TagFormFields) => {
    return await createTag({
      ...tag,
      areaId: area.id,
    });
  };

  const { mutate: addTag, isLoading } = useMutation(onAddTag, {
    onSuccess: async () => {
      await queryClient.invalidateQueries(
        tagsQueryKeys.list({
          areaId: [area.id],
        }),
      );
      onCancel();
    },
    onError: e => {
      const errorMessage = getErrorMessage(e);
      alert.error(errorMessage);
    },
  });

  const onSubmit = (e: TagFormFields) => {
    addTag(e);
  };

  return (
    <form
      className="relative flex flex-col gap-6 items-start"
      onSubmit={handleSubmit(onSubmit)}
    >
      <TextAreaInput
        label={t('description.label')}
        register={register('description', {
          required: t('description.required'),
        })}
        className={{ base: 'w-full' }}
        errorLabelText={errors.description?.message}
        errorLabelPosition="bottom"
      />
      <SelectInput
        label={t('difficulty.label')}
        className={{ base: 'w-full' }}
        register={register('difficulty')}
      >
        {Object.values(TagDifficultyEnum).map(value => {
          return (
            <option key={value} value={String(value)}>
              {t(`difficulty.values.${value}`)}
            </option>
          );
        })}
      </SelectInput>

      <div className="w-full">
        <label htmlFor="level" className="label">
          <Text text={t('level.label')} />
        </label>
        <input
          {...register('level')}
          type="range"
          min={1}
          max={5}
          step={1}
          defaultValue={1}
          className="range range-sm range-primary"
        />
        <div className="flex w-full justify-between px-2 text-xs">
          <span>1</span>
          <span>2</span>
          <span>3</span>
          <span>4</span>
          <span>5</span>
        </div>
      </div>

      <div className="flex w-full justify-around gap-6">
        <RoundedButton
          text={t('cancel')}
          color="neutral"
          className="w-full"
          onClick={onCancel}
        />

        <RoundedButton
          text={t('add')}
          className="w-full"
          type="submit"
          loading={isLoading}
        />
      </div>
    </form>
  );
}
