import Cookies from 'js-cookie';

import { TOKEN_RESPONSE_KEY } from '@/constants';
import TokenResponse from '@/models/TokenResponse';

type SetTokenResponse = {
  tokenResponse: TokenResponse;
  remember?: boolean;
};

export function setTokenResponse({
  tokenResponse,
  remember,
}: SetTokenResponse) {
  const tokens = JSON.stringify(tokenResponse);

  Cookies.set(TOKEN_RESPONSE_KEY, tokens, {
    expires: remember ? 1 : undefined,
  });
}

export function getTokenResponse() {
  const tokenResponse = Cookies.get(TOKEN_RESPONSE_KEY);

  if (tokenResponse) return JSON.parse(tokenResponse) as TokenResponse;
}

export const removeTokenResponse = () => {
  Cookies.remove(TOKEN_RESPONSE_KEY);
};

export const removeCookiesTokenResponse = () =>
  Cookies.remove(TOKEN_RESPONSE_KEY);
