import React from 'react';
import AvatarItem from './AvatarItem';
import { AvatarItemsEnum, BaseItem } from '../../models/Avatar';

export default function ItemDisplay({
  item,
  color,
  subcategory,
  isSameItem,
  onChange,
  type,
}: {
  item: BaseItem;
  color: string;
  subcategory: AvatarItemsEnum;
  isSameItem: boolean;
  onChange: (item: BaseItem, color: string) => void;
  type: 'inventory' | 'shop';
}) {
  const isInventory = type === 'inventory';
  const getClassName = (isActive: boolean) => {
    if (isInventory) {
      if (isActive) {
        return 'border-accent';
      } else return 'border-accent/40';
    } else if (isActive) {
      return 'border-warning';
    } else return 'border-warning-content';
  };
  return (
    <div
      data-testid={`${item.name}AvatarItem`}
      onClick={() => onChange(item, color)}
      className={`${
        isInventory ? 'bg-accent/40' : 'bg-warning-content'
      } aspect-square flex h-20 items-center justify-center rounded-xl cursor-pointer border-2 relative ${getClassName(
        isSameItem,
      )}`}
    >
      <AvatarItem
        relative
        color={color}
        category={subcategory}
        img={item.image}
      />
    </div>
  );
}
