import { createContext, useEffect, useState, PropsWithChildren } from 'react';

import ScheduledLesson, {
  ScheduledLessonTypeEnum,
} from '@/models/ScheduledLesson';
import LessonProgress from '@/models/LessonProgress';
import { Lesson } from '@/models/Lesson';
import { isStudent } from '@/functions/auth';
import useAuth from '../hook/useAuth';
import useStudentContext from '../hook/student';
import { shouldShowScheduledLesson } from '@/utils/shouldShowScheduledLesson';
import {
  lessonsProgressesQueryKeys,
  lessonsQueryKeys,
  scheduledLessonsQueryKeys,
} from '../services/querykeys';
import useInfiniteService from '../hook/useInfiniteService';
import { REQUEST_STALE_TIME_IN_MS } from '@/constants';
import useListService from '../hook/useListService';
import { enrollmentValidation } from '@/utils/enrollmentValidation';
interface LessonsPaginate {
  prev?: Pick<ScheduledLesson, 'lesson' | 'type'>;
  next?: Pick<ScheduledLesson, 'lesson' | 'type'>;
}

export interface LessonContextProps {
  scheduledLesson?: ScheduledLesson;
  lessonProgress?: LessonProgress;
  lessonsError?: unknown;
  updateLessonProgress(): Promise<void>;
  pagination: LessonsPaginate;
  loading?: boolean;
  setParams: (params: LessonContextParams) => void;

  lesson?: Lesson;
  loadingLesson?: boolean;
}

const LessonContext = createContext<LessonContextProps>(
  {} as LessonContextProps,
);

interface LessonContextParams {
  slugCourseName?: string;
  lessonId?: string;
  klassId?: string;
}

export function LessonProvider({ children }: PropsWithChildren) {
  const [{ slugCourseName = '', lessonId, klassId }, setParams] =
    useState<LessonContextParams>({});

  const { user } = useAuth();

  const { nextLesson, updateProgress, currentProgress, klass } =
    useStudentContext();

  const validEnrollment = enrollmentValidation(
    currentProgress?.enrollmentStatus,
  );

  const studentView = !!user && isStudent(user?.userType);

  const hasKlassId = !!klassId && !isNaN(Number(klassId));

  const hasLessonId = !!lessonId && !isNaN(Number(lessonId));

  const enabledLessons = hasLessonId && !hasKlassId;

  const {
    results: lessons,
    error: lessonsError,
    isInitialLoading: loadingLessons,
    hasNextPage: hasNextLessonsPage,
    fetchNextPage: fetchNextLessonsPage,
  } = useInfiniteService({
    ...lessonsQueryKeys.nestedList(slugCourseName ?? '')._ctx.infinity,
    enabled: enabledLessons,
  });

  const enabledLessonProgress =
    studentView && hasLessonId && !!currentProgress?.id;

  const {
    results: lessonProgress,
    isInitialLoading: loadingLessonProgress,
    invalidate: invalidateLessonProgress,
  } = useListService({
    ...lessonsProgressesQueryKeys.list({
      lesson: Number(lessonId),
      courseProgressId: [currentProgress?.id ?? 0],
    }),
    staleTime: REQUEST_STALE_TIME_IN_MS,
    enabled: enabledLessonProgress,
  });

  const currentLessonProgress = lessonProgress.at(0);

  const enabledScheduledLessons = hasKlassId && (!studentView || !!klass);

  const {
    results: scheduledLessons,
    isInitialLoading: loadingScheduledLessons,
    hasNextPage: hasNextScheduledLessonsPage,
    fetchNextPage: fetchScheduledLessonsNextPage,
  } = useInfiniteService({
    ...scheduledLessonsQueryKeys.list({ klassId: Number(klassId) })._ctx
      .infinity,
    enabled: enabledScheduledLessons,
  });

  const currentLessons = scheduledLessons.length
    ? scheduledLessons.map(({ lesson, type }) => ({ lesson, type }))
    : lessons.map(lesson => ({
        lesson,
        type: ScheduledLessonTypeEnum.NORMAL,
      }));

  const lessonIndex = currentLessons?.findIndex(
    ({ lesson }) => lesson.id === Number(lessonId),
  );

  const getPagination = (): LessonsPaginate => {
    if (lessonIndex === undefined || !currentLessons) return {};

    const prev =
      lessonIndex <= 0 ? undefined : currentLessons.at(lessonIndex - 1);
    const next = currentLessons.at(lessonIndex + 1);

    const nextScheduledLesson = scheduledLessons.at(lessonIndex + 1);

    const blockLesson =
      studentView &&
      !shouldShowScheduledLesson(
        nextScheduledLesson,
        currentLessonProgress,
        nextLesson,
      );

    const shouldBlockNextLesson = validEnrollment ? blockLesson : false;

    return {
      prev,
      next: shouldBlockNextLesson ? undefined : next,
    };
  };

  const updateLessonProgress = async () => {
    if (!currentLessonProgress) throw new Error('lesson progress not found');

    await invalidateLessonProgress();

    await updateProgress();
  };

  const scheduledLesson =
    lessonIndex !== undefined ? scheduledLessons?.[lessonIndex] : undefined;

  const lesson = lessonIndex !== undefined ? lessons?.[lessonIndex] : undefined;

  const loading =
    loadingLessons || loadingLessonProgress || loadingScheduledLessons;

  useEffect(() => {
    if (hasNextScheduledLessonsPage) fetchScheduledLessonsNextPage();
  }, [fetchScheduledLessonsNextPage, hasNextScheduledLessonsPage]);

  useEffect(() => {
    if (hasNextLessonsPage) fetchNextLessonsPage();
  }, [fetchNextLessonsPage, hasNextLessonsPage]);

  const values: LessonContextProps = {
    scheduledLesson: scheduledLesson,
    lessonProgress: currentLessonProgress,
    pagination: getPagination(),
    lesson: scheduledLesson?.lesson || lesson || currentLessonProgress?.lesson,
    lessonsError,
    loading,
    updateLessonProgress,
    setParams,
  };

  return (
    <LessonContext.Provider value={values}>{children}</LessonContext.Provider>
  );
}

export default LessonContext;
