import { ChevronDownIcon } from '@heroicons/react/outline';

import SearchInput, { SearchOption } from './SearchInput';
import { BaseInputProps } from './BaseInput';

type EnumInputType = 'numeric' | 'string';

type EnumInputProps<T> = BaseInputProps & {
  enumModel: any;
  selected?: T;
  displayName: (option: T) => string;
  onSelect(item: T): void;
  onDeselect?(): void;
  disabled?: boolean;
  type: EnumInputType;
};

export default function EnumInput<T>({
  enumModel,
  selected,
  displayName,
  onSelect,
  onDeselect,
  label,
  errorLabelText,
  testId,
  disabled,
  type,
  labelPosition,
  className,
  ...rest
}: EnumInputProps<T>) {
  function getEnumValues() {
    const values = Object.values(enumModel) as (keyof typeof enumModel)[];
    if (type === 'numeric') {
      return values.filter(value => !isNaN(Number(value)));
    }

    return values;
  }

  const values = getEnumValues();

  const options = values.map<SearchOption<typeof enumModel>>(value => ({
    value,
    key: String(value),
  }));

  return (
    <SearchInput
      input={{
        label,
        errorLabelText,
        testId,
        disabled,
        labelPosition,
        className,
        ...rest,
      }}
      options={options}
      onSelect={onSelect}
      displayName={displayName}
      value={selected ? displayName(selected) : ''}
      hasItemSelected={!!selected}
      inputIcon={<ChevronDownIcon className="w-4 text-primary" />}
      onDeselect={onDeselect}
    />
  );
}
