import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import RoundedButton from '../../common/buttons/RoundedButton';
import {
  useMutation,
  useQueries,
  useQuery,
  useQueryClient,
} from '@tanstack/react-query';
import React, { useEffect, useState } from 'react';
import useAuth from '@/data/hook/useAuth';
import { isStudent } from '@/functions/auth';
import HomeworkContent from './HomeworkContent';
import StudentActivitiesList from '../../lessons/activities/team/StudentActivitiesList';
import getKlassStudents from '@/functions/enrollment';
import User, { UserTypeEnum } from '@/models/User';
import SubmissionContainer from '../../lessons/activities/team/SubmissionContainer';
import {
  AssignmentType,
  HomeworkActivityProgress,
} from '@/models/HomeworkActivity';
import { statusTypes } from '@/models/StatusTag';
import StatusTag from '../../common/dataDisplay/StatusTag';
import Text from '../../common/dataDisplay/Text';
import { Rating } from '../../common/dataDisplay/Rating';
import { RatingTypes } from '../../common/dataDisplay/Rating';
import { HtmlPreview } from '../../common/dataDisplay/HtmlPreview';
import { Controller, useForm } from 'react-hook-form';
import MainButton from '../../common/buttons/MainButton';
import MyCkeditor from '../../editor/MyCkeditor';
import { ImageFolderEnum } from '@/models/CkEditor';
import { PencilIcon, PlusIcon } from '@heroicons/react/outline';
import { updateMgActivityProgress } from '@/data/services/mgActivityProgressServices';
import { MGActivityProgress } from '@/models/MGActivity';
import {
  handleHomeworkNotDone,
  handleTeacherHomeworkRevision,
} from '@/utils/teacherHomeworkNotification';
import ConditionalRenderer from '../../common/ConditionalRenderer';
import { isEmpty } from 'lodash';
import ModalWarning from '../../common/modals/ModalWarning';
import alert from '@/utils/UseAlert';
import { ApiError } from '@/models/Errors';
import ComponentGuard from '../../common/ComponentGuard';
import Skeleton from '../../common/Skeleton';
import AccordionContainer from '../../common/cards/AccordionContainer';
import SaveCancelGroup from '../../common/buttons/SaveCancelGroup';
import useLessonContext from '@/data/hook/lesson';
import { REQUEST_STALE_TIME_IN_MS } from '@/constants';
import {
  enrollmentsQueryKeys,
  homeworkActivitiesQueryKeys,
  homeworkProgressQueryKeys,
} from '@/data/services/querykeys';
import useListService from '@/data/hook/useListService';

export default function TeamHomeworkView() {
  const { scheduledLesson } = useLessonContext();

  const { user } = useAuth();
  const navigate = useNavigate();
  const { klassId } = useParams();

  const currentUserIsStudent = isStudent(user?.userType);
  const homeworkActivityId = scheduledLesson?.homeworkActivity;

  const hashomeworkActivityId = !isNaN(Number(homeworkActivityId));

  const {
    results: homeworksActivities,
    isInitialLoading: isLoadingHomeworkActivity,
    isError: isErrorHomeworkActivity,
  } = useListService({
    ...homeworkActivitiesQueryKeys.list({
      activity: homeworkActivityId ?? 0,
    })._ctx.infinity,
    enabled: !!hashomeworkActivityId && !currentUserIsStudent,
    staleTime: REQUEST_STALE_TIME_IN_MS,
  });

  const homeworkActivity = homeworksActivities?.at(0);

  const {
    data: enrollments,
    isInitialLoading: isKlassLoading,
    isError: isKlassError,
  } = useQuery({
    refetchOnWindowFocus: false,
    enabled: !!klassId && !isNaN(+klassId),
    ...enrollmentsQueryKeys.list({ klassId: Number(klassId) }),
  });

  const students = getKlassStudents(enrollments?.results);
  const studentsQueries = useQueries({
    queries: students.map(student => ({
      enabled: !!students.length && !!homeworkActivity?.id,
      ...homeworkProgressQueryKeys.list(homeworkActivity?.id ?? 0, {
        studentId: student.id,
      }),
    })),
  });

  const content = homeworkActivity?.content;

  const { t } = useTranslation('translation', {
    keyPrefix: 'klassView',
  });

  if (isLoadingHomeworkActivity)
    return <Skeleton className="rounded-md w-full h-20 bg-primary-content" />;

  if (isErrorHomeworkActivity)
    return (
      <div className="flex flex-col gap-4">
        <Text text={t('internalError')} />
        <RoundedButton
          text={t('goBack')}
          className="w-40 flex self-end"
          onClick={() => navigate(-1)}
        />
      </div>
    );

  if (homeworkActivity)
    return (
      <div className="flex flex-col w-full gap-2">
        <HomeworkContent
          content={content}
          teacherId={scheduledLesson?.teacher}
        />

        <StudentActivitiesList
          students={students}
          isKlassError={isKlassError}
          isKlassLoading={isKlassLoading}
        >
          {studentsQueries.map(
            (
              { isError, data: homeworkProgress, isInitialLoading: isLoading },
              index,
            ) => (
              <Submission
                key={index}
                isError={isError}
                isLoading={isLoading}
                student={students[index]}
                homeworkProgress={homeworkProgress?.results.at(0)}
              />
            ),
          )}
        </StudentActivitiesList>
      </div>
    );

  return (
    <div className="flex gap-4">
      <Text text={`${t('noHomework')} (°ロ°)☝`} />
      <RoundedButton
        text={t('goBack')}
        className="w-40 flex self-end"
        onClick={() => navigate(-1)}
      />
    </div>
  );
}

interface SubmissionProps {
  student: User;
  isLoading: boolean;
  isError: boolean;
  homeworkProgress?: HomeworkActivityProgress;
}

const Submission = ({
  isLoading,
  isError,
  student,
  homeworkProgress,
}: SubmissionProps) => {
  const { firstName, lastName } = student;
  const { t } = useTranslation('translation', {
    keyPrefix: 'klassView.homework',
  });
  const activityProgress = homeworkProgress?.activityProgress;
  const grade = activityProgress?.grade ?? null;
  const answer = homeworkProgress?.answer ?? null;
  const status = homeworkProgress?.status ?? null;
  const assignmentType = homeworkProgress?.homework.assignmentType;

  const hasSubmittion =
    homeworkProgress?.homework.assignmentType !== AssignmentType.NOTHING;
  const feedbackStatus = handleTeacherHomeworkRevision(status, assignmentType);
  const tagContent = handleTagContent(feedbackStatus);

  const studentLastName = lastName ? lastName.charAt(0) : '';

  if (isError) {
    return <React.Fragment />;
  }
  return (
    <SubmissionContainer
      collapseContent={
        <SubmissionFeedback
          hasSubmittion={hasSubmittion}
          activityProgress={activityProgress}
          answer={answer}
          homeworkProgress={homeworkProgress}
        />
      }
      studentName={`${firstName} ${studentLastName}`}
      isLoading={isLoading}
    >
      <div className="flex items-center gap-5">
        <StatusTag
          data-testid="statusTag"
          className="min-w-[96px]"
          status={tagContent.status}
        >
          <Text size="text-14" text={t(tagContent.content)} />
        </StatusTag>

        <Rating
          length={5}
          isDisabled
          testId="homeworkGrade"
          type={RatingTypes.STAR}
          value={grade || 0}
        />
      </div>
    </SubmissionContainer>
  );
};

const handleTagContent = (
  status: 'revised' | 'pending' | 'notDone',
): { status: statusTypes; content: string } => {
  switch (status) {
    case 'revised':
      return {
        content: 'revised',
        status: 'OK',
      };
    case 'pending':
      return {
        content: 'pending',
        status: 'DANGER',
      };
    default:
      return {
        content: 'notDone',
        status: 'WARNING',
      };
  }
};

interface SubmissionFeedbackProps {
  answer: string | null;
  activityProgress?: MGActivityProgress;
  hasSubmittion?: boolean;
  homeworkProgress?: HomeworkActivityProgress;
}

const SubmissionFeedback = ({
  answer,
  activityProgress,
  hasSubmittion,
  homeworkProgress,
}: SubmissionFeedbackProps) => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'klassView.homework.feedback',
  });

  interface HomeworkFeedback {
    grade: number;
    comment: string;
    notDone: boolean;
  }
  const queryClient = useQueryClient();

  const defaultValues: HomeworkFeedback = {
    comment: activityProgress?.comment || '',
    grade: activityProgress?.grade ?? 0,
    notDone: !!homeworkProgress && handleHomeworkNotDone(homeworkProgress),
  };

  const { handleSubmit, watch, reset, control } = useForm<HomeworkFeedback>({
    defaultValues,
  });

  const grade = watch('grade');
  const notDone = watch('notDone');
  const comment = watch('comment');

  const hasChanges =
    defaultValues.comment !== comment ||
    defaultValues.grade !== grade ||
    defaultValues.notDone !== notDone;

  const hasComment = !!activityProgress && !!defaultValues.comment;

  const [openComment, setOpenComment] = useState(!hasComment);

  const [isOpenModal, setIsOpenModal] = useState<boolean>(false);

  const commentButtonText = hasComment ? t('editComment') : t('addComment');

  const updateCurrentProgress = async ({
    notDone,
    comment,
    grade,
  }: HomeworkFeedback) => {
    if (activityProgress) {
      await updateMgActivityProgress(activityProgress.id, {
        comment,
        grade: grade ?? 0,
        hasDone: !notDone,
      });
    }
  };

  async function onSubmit({ notDone, comment, grade }: HomeworkFeedback) {
    if (isEmpty(comment) && !isOpenModal) {
      setIsOpenModal(true);
      return;
    }
    mutate({ notDone, comment, grade });
  }

  const { isLoading: isUpdating, mutate } = useMutation(updateCurrentProgress, {
    onSuccess() {
      alert.success(t('saved'));
      queryClient.invalidateQueries(homeworkProgressQueryKeys.list._def);
      setIsOpenModal(false);
      setOpenComment(false);
    },
    onError(error: any) {
      const api = new ApiError(error);
      alert.error(api.getErrorMessage());
    },
  });

  const resetFields = () => {
    reset(defaultValues);
  };

  useEffect(() => {
    if (grade) reset({ notDone: false, grade, comment });
    else if (notDone) reset({ notDone, grade: 0, comment });
  }, [grade, notDone, comment, reset]);

  return (
    <div className="cursor-default flex flex-col gap-5 pb-5 pt-1">
      <ComponentGuard roles={[UserTypeEnum.TEACHER]}>
        <ModalWarning
          visible={isOpenModal}
          translationString="modalNoComment"
          onClickConfirm={handleSubmit(onSubmit)}
          onClickCancel={() => setIsOpenModal(false)}
          isLoading={isUpdating}
        />
      </ComponentGuard>
      <ConditionalRenderer condition={hasSubmittion}>
        <div className="flex flex-col gap-2.5">
          <Text text={t('submittion') + ':'} format="rubik-500" />
          <HtmlPreview format="default" html={answer ?? ''} />
        </div>
      </ConditionalRenderer>

      <form
        data-testid="feedbackForm"
        className="flex flex-col gap-2.5"
        onSubmit={handleSubmit(onSubmit)}
      >
        <div className="flex gap-2.5 items-center">
          <Controller
            name="grade"
            control={control}
            render={({ field: { value, onChange } }) => (
              <div className="flex gap-2.5 items-center">
                <Text text={t('grade')} />
                <Rating length={5} value={value} onClickRating={onChange} />
              </div>
            )}
          />

          <span className="flex gap-2 items-center">
            <Controller
              name="notDone"
              control={control}
              render={({ field: { value, onChange } }) => (
                <input
                  checked={value}
                  type="checkbox"
                  disabled={!!answer || !!grade}
                  className="checkbox checkbox-primary checkbox-sm border-primary rounded-md disabled:opacity-10"
                  onChange={onChange}
                />
              )}
            />

            <Text text={t('notDone')} className="h-fit text-14" />
          </span>
        </div>

        <div className="flex flex-col gap-2">
          <MainButton
            type="button"
            onClick={() => setOpenComment(prev => !prev)}
            text={commentButtonText}
            icon={hasComment ? <PencilIcon /> : <PlusIcon />}
          />

          <AccordionContainer
            animate={!openComment && hasComment ? 'open' : 'closed'}
          >
            <HtmlPreview html={defaultValues.comment} />
          </AccordionContainer>

          <AccordionContainer
            className="w-full overflow-hidden flex flex-col gap-4 cursor-text"
            animate={openComment ? 'open' : 'closed'}
          >
            <Controller
              name="comment"
              control={control}
              render={({ field: { value, onChange } }) => (
                <MyCkeditor
                  testId="comment"
                  disabled={isUpdating}
                  content={value}
                  folder={ImageFolderEnum.HOMEWORK}
                  handleContentChange={onChange}
                />
              )}
            />
          </AccordionContainer>

          <SaveCancelGroup
            loading={isUpdating}
            save={{
              disabled: isUpdating || !hasChanges,
              testId: 'saveButton',
            }}
            cancel={{
              onClick: resetFields,
              testId: 'cancelButton',
              disabled: isUpdating || !hasChanges,
            }}
          />
        </div>
      </form>
    </div>
  );
};
