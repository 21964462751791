import Text from '../common/dataDisplay/Text';
import { CourseTypeEnum } from '@/models/Course';
import { SimplifiedCourseProgress } from '@/models/StudentCourseProgress';
import TooltipHandler from '../common/TooltipHandler';
import Skeleton from '../common/Skeleton';
import { twMerge } from 'tailwind-merge';
import { RenderHistoryStatusIcon } from './HistoricPanel';

export default function HistoricCourseTag({
  progress,
  courseType,
}: {
  progress: SimplifiedCourseProgress[];
  courseType: CourseTypeEnum;
}) {
  const getTitleAndClassName = () => {
    switch (courseType) {
      case CourseTypeEnum.CTRL_KIDS:
        return { text: 'KIDS', className: 'bg-warning' };
      case CourseTypeEnum.CTRL_TEENS:
        return { text: 'TEENS', className: 'bg-primary' };
      case CourseTypeEnum.CTRL_YOUNG:
        return { text: 'YOUNG', className: 'bg-secondary' };
      default:
        return { text: '', className: '' };
    }
  };

  const { text, className } = getTitleAndClassName();

  if (!progress.length) {
    return null;
  }

  return (
    <div
      className={`flex gap-2 items-center text-base-100 w-fit rounded-full px-2.5 py-0.5 ${className}`}
    >
      <Text text={text} format="rubik-500" className="uppercase text-12" />
      <div className="flex gap-1 items-center">
        {progress
          .sort(
            (a, b) =>
              a.coursePath.course.knowledgeOrder -
              b.coursePath.course.knowledgeOrder,
          )
          .map(progress => (
            <TooltipHandler
              key={progress.id}
              renderTooltip
              tooltipMessage={progress.coursePath.course.abbreviation}
            >
              <RenderHistoryStatusIcon
                iconClassName="text-base-100 w-4 h-4"
                status={progress.enrollmentStatus}
              />
            </TooltipHandler>
          ))}
      </div>
    </div>
  );
}

export const HistoricTagGroup = ({
  progress,
  isLoading,
  className,
}: {
  progress?: SimplifiedCourseProgress[];
  isLoading?: boolean;
  className?: string;
}) => {
  const courseTypeValues = Object.values(CourseTypeEnum).filter(
    value => value !== CourseTypeEnum.CTRL_QUICK,
  );
  if (isLoading) {
    return <Skeleton className="rounded-full bg-primary-content h-3.5 w-20" />;
  }
  if (!!progress?.length) {
    return (
      <div className={twMerge('flex flex-row gap-2 w-fit', className)}>
        {courseTypeValues.map(type => (
          <HistoricCourseTag
            key={type}
            progress={progress.filter(
              item => item.coursePath.course.courseType === type,
            )}
            courseType={type}
          />
        ))}
      </div>
    );
  }

  return null;
};
