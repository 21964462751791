import { AxiosResponse } from 'axios';
import requester from '@/axios';
import {
  DndOrdenationActivityProgress,
  ItemProgress,
} from '@/models/DNDOrdenation';

export interface DndOrdenationActivityParams {
  questionId: number;
  dndOrdenationProgressId: number;
}

export async function getDndOrdenationActivityProgress({
  dndOrdenationProgressId,
  questionId,
}: DndOrdenationActivityParams): Promise<DndOrdenationActivityProgress> {
  const { data } = await requester().get<DndOrdenationActivityProgress>(
    `/dnd-ordenation-activity/${questionId}/dnd-ordenation-activity-progress/${dndOrdenationProgressId}/`,
  );

  return data;
}

export async function updateDndOrdenationColumnProgress(
  dndOrdenationColumnProgressId: number,
  itemProgressList: ItemProgress[],
) {
  await requester().patch(
    `/dnd-ordenation-column-progress/${dndOrdenationColumnProgressId}/dnd-ordenation-item-progress/order/`,
    itemProgressList,
  );
}

type UpdateDndActivityProgress = Pick<DndOrdenationActivityProgress, 'isDone'>;

export async function updateDndOrdenationActivityProgress(
  { dndOrdenationProgressId, questionId }: DndOrdenationActivityParams,
  body: UpdateDndActivityProgress,
) {
  type Response = AxiosResponse<DndOrdenationActivityProgress>;
  const { data: dndActivityProgress } = await requester().patch<
    UpdateDndActivityProgress,
    Response
  >(
    `dnd-ordenation-activity/${questionId}/dnd-ordenation-activity-progress/${dndOrdenationProgressId}/`,
    body,
  );
  return dndActivityProgress;
}
