import requester from '@/axios';
import JsonApiResponse from '@/models/JsonApiResponse';
import UserProfile from '@/models/UserProfile';
import { UserTypeEnum } from '@/models/User';

//TODO: trocar rota para ser userprofiles/:username
export const getUserProfile = async (username: string) => {
  const { data } = await requester().get<JsonApiResponse<UserProfile>>(
    `userprofiles/?username=${username}`,
  );
  return data.results[0];
};

export async function updateUserProfileBanner(
  userId: number,
  bannerUrl?: string | File | null,
) {
  const formData = new FormData();

  if (bannerUrl) formData.set('bannerUrl', bannerUrl);

  const { data: changedProfile } = await requester().patch<UserProfile>(
    `userprofiles/${userId}/`,
    formData,
    {
      headers: {
        'Content-Type': 'multipart/form-data',
        accept: 'application/json',
      },
    },
  );

  return changedProfile;
}

export async function updateUserProfile(
  userProfileId: number,
  changes: Partial<Omit<UserProfile, 'user' | 'id'>>,
) {
  const { data: changedProfile } = await requester().patch<UserProfile>(
    `userprofiles/${userProfileId}/`,
    changes,
  );

  return changedProfile;
}

export interface ListUserProfileFilters {
  pageNumber?: number;
  pageSize?: number;
  exUserType?: UserTypeEnum[];
  unit?: number[];
  userType?: string[];
  search?: string;
  userId?: number;
}

export async function listUserProfiles({
  pageSize,
  pageNumber,
  ...params
}: ListUserProfileFilters) {
  const userType = params.userType?.length
    ? String(params.userType)
    : undefined;

  const exUserType = params.exUserType?.length
    ? String(params.exUserType)
    : undefined;

  const { data } = await requester().get<JsonApiResponse<UserProfile>>(
    'userprofiles/',
    {
      params: {
        ...params,
        userType,
        exUserType,
        'page[size]': pageSize,
        'page[number]': pageNumber,
      },
    },
  );
  return data;
}
