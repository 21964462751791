import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { SheetTab } from '@/models/SheetTab';
import ConditionalRenderer from '../ConditionalRenderer';
import AvatarName from '../dataDisplay/AvatarName';
import User from '@/models/User';
import Text from '../dataDisplay/Text';
import Skeleton from '../Skeleton';
import { useQuery } from '@tanstack/react-query';
import { klassesQueryKeys } from '@/data/services/querykeys';

type AssessmentHeaderProps = {
  type: SheetTab;
  createdAt: Date;
  author: User;
  klassId?: number;
};

export default function AssessmentHeader({
  type,
  createdAt,
  author,
  klassId,
}: AssessmentHeaderProps) {
  const { t } = useTranslation('translation', {
    keyPrefix: 'assessmentHeader',
  });

  const text = type === 'comment' ? t('comment') : t('assessment');
  const date = moment(createdAt).format('DD/MM/YYYY, HH:mm');

  const { data: klass, isInitialLoading: isLoading } = useQuery({
    ...klassesQueryKeys.get(klassId ?? 0),
    enabled: !!klassId,
    refetchOnMount: false,
  });

  return (
    <div className="flex flex-wrap gap-x-4 gap-y-2 items-center">
      <div className="flex gap-4 items-center">
        <Text text={text} format="rubik-500" className="text-primary" />
        <Text text={date} />
        <AvatarName user={author} className="gap-2" />
      </div>
      <ConditionalRenderer
        condition={!isLoading}
        fallback={
          <Skeleton className="h-5 flex bg-primary/40 rounded-md w-full max-w-[40%]" />
        }
      >
        <Text text={klass?.name} format="rubik-500" className="text-primary" />
      </ConditionalRenderer>
    </div>
  );
}
