import ConditionalRenderer from '../../../../../common/ConditionalRenderer';
import { DragIcon } from '../../../../../icons';
import Text from '../../../../../common/dataDisplay/Text';
import DNDItemContainer from '../../DNDItem/DNDItemContainer';
import DNDItemProgress from '@/models/DNDItemProgress';
import { ActivityMode } from '@/models/ActivityProgress';
import { Draggable } from '@hello-pangea/dnd';

interface DNDPositionalItemProps {
  dndPositionalItemProgress: DNDItemProgress;
  index: number;
  activityMode: ActivityMode;
  answerOrder?: number;
  isReordenationItems: boolean;
}

export default function DNDPositionalItemProgress({
  dndPositionalItemProgress,
  index,
  activityMode,
  isReordenationItems,
  answerOrder,
}: DNDPositionalItemProps) {
  const { item } = dndPositionalItemProgress;
  const { text, image, altText } = item;

  const shouldShowAnswer = activityMode === 'answerkey' && !!answerOrder;

  const isCorrect = index + 1 === answerOrder;

  return (
    <Draggable
      key={dndPositionalItemProgress.id}
      isDragDisabled={isReordenationItems}
      draggableId={String(dndPositionalItemProgress.id)}
      index={index}
    >
      {provided => (
        <DNDItemContainer
          provided={provided}
          text={text}
          image={image}
          altText={altText}
        >
          <ConditionalRenderer condition={!shouldShowAnswer}>
            <div
              {...provided.dragHandleProps}
              className={
                activityMode !== 'activity'
                  ? 'cursor-not-allowed'
                  : 'cursor-pointer'
              }
            >
              <DragIcon data-testid="dragIcon" />
            </div>
          </ConditionalRenderer>

          <ConditionalRenderer condition={shouldShowAnswer}>
            <span
              className={`h-6 w-6 md:h-8 md:w-8 rounded-full ${
                isCorrect ? 'bg-success' : 'bg-error'
              } text-base-100 text-14 md:text-16 shrink-0 flex items-center justify-center self-start`}
            >
              <Text text={answerOrder + 'º'} format="rubik-500" />
            </span>
          </ConditionalRenderer>
        </DNDItemContainer>
      )}
    </Draggable>
  );
}
