import { useTranslation } from 'react-i18next';

import AdminCalendarView from './AdminCalendarView';
import Layout from '@/components/template/Layout';
import HeadTitle from '@/components/common/HeadTitle';

export default function CalendarPage() {
  const { t } = useTranslation('translation', {
    keyPrefix: 'calendar.admin',
  });

  return (
    <Layout>
      <HeadTitle routeInfo={t('calendar')} />
      <AdminCalendarView />
    </Layout>
  );
}
