import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import useCourseEditing from '../../../data/hook/useCourseEditing';
import CoursePath from '../../../models/Course';
import { ApiError } from '../../../models/Errors';
import RoundedButton from '../../common/buttons/RoundedButton';
import Text from '../../common/dataDisplay/Text';
import Modal from '../../common/modals/Modal';
import alert from '../../../utils/UseAlert';

interface EnableEditModalProps {
  onClose(): void;
  course: CoursePath;
  visible: boolean;
}

function EnableEditModal(props: EnableEditModalProps) {
  const { onClose, course, visible } = props;
  const { t } = useTranslation('translation', {
    keyPrefix: 'adminPage.lesson',
  });
  const { allowVersioningMode } = useCourseEditing();
  const [isLoading, setIsLoading] = useState(false);
  async function courseToPublish() {
    try {
      setIsLoading(true);
      await allowVersioningMode(course, course.version <= 1.0);
    } catch (error: any) {
      const { getErrorMessage } = new ApiError(error);
      alert.error(getErrorMessage());
    } finally {
      setIsLoading(false);
    }
  }
  return (
    <Modal onClose={onClose} visible={visible}>
      <div className="flex flex-col items-center gap-6">
        <Text
          text={t('modal.enableEdit.title')}
          className="text-primary text-center"
          format="rubik-500"
          size="text-20"
        />
        <Text text={t('modal.enableEdit.warning')} className="text-center" />
        <Text text={t('modal.enableEdit.question')} className="text-center" />
        <div className="flex items-center gap-7 w-full">
          <RoundedButton
            className="w-full"
            text={t('modal.enableEdit.cancel')}
            onClick={onClose}
            color="neutral"
          />
          <RoundedButton
            className="w-full"
            testId="confirmEnableButton"
            loading={isLoading}
            onClick={courseToPublish}
            text={t('modal.enableEdit.confirm')}
            color="gradient"
          />
        </div>
      </div>
    </Modal>
  );
}

export default EnableEditModal;
