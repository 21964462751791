import requester from '@/axios';
import { CourseTypeEnum } from '@/models/Course';
import { EnrollmentStatusEnum } from '@/models/Enrollment';
import { KlassStatusEnum } from '@/models/Klass';
import {
  PresenceEnum,
  ScheduledLessonReportEnum,
} from '@/models/ScheduledLessonReport';
import { BaseFilters } from '@/models/Service';
import { StudentHistory } from '@/models/StudentHistory';

export interface ListStudentHistoryFilters extends BaseFilters {
  courseId?: number;
  coursePathSlug?: string;
  courseType?: CourseTypeEnum[];
  enrollmentStatus?: EnrollmentStatusEnum[];
  klassId?: number;
  klassStatus?: KlassStatusEnum[];
  presence?: PresenceEnum[];
  ordering?: string;
  reportStatus?: ScheduledLessonReportEnum[];
}

export const getStudentHistory = async (
  studentId: number,
  params?: ListStudentHistoryFilters,
) => {
  const { data } = await requester().get<StudentHistory>(
    `students/${studentId}/history/`,
    {
      params,
    },
  );
  return data;
};
