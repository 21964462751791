import { Fragment, useState } from 'react';
import {
  CalendarIcon,
  ChevronLeftIcon,
  ChevronRightIcon,
} from '@heroicons/react/outline';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { formatLessonName } from '@/functions/lessonsName';
import Klass from '@/models/Klass';
import ScheduledLesson from '@/models/ScheduledLesson';
import {
  FormatScheduledDate,
  FormatTypeEnum,
} from '@/utils/FormatScheduledDate';
import IconButton from '@/components/common/buttons/IconButton';
import PillComponent from '@/components/common/dataDisplay/PillComponent';
import Text from '@/components/common/dataDisplay/Text';
import InfiniteSearchInput from '@/components/common/dataInput/InfiniteSearchInput';
import useListService from '@/data/hook/useListService';
import { scheduledLessonsQueryKeys } from '@/data/services/querykeys';

interface ReportPaginationProps {
  klass: Klass;
  loading?: boolean;
  scheduledLesson?: ScheduledLesson;
}

export const ReportPagination = ({
  klass,
  loading,
  scheduledLesson,
}: ReportPaginationProps) => {
  const { id: klassId } = klass;

  const { t } = useTranslation('translation', {
    keyPrefix: 'manageScheduledLessonReport',
  });

  const navigate = useNavigate();

  function reportNavigate(klassId: number, scheduledLessonId: number) {
    return '/class/' + klassId + '/lesson-report/' + scheduledLessonId;
  }

  const [selected, setSelected] = useState<ScheduledLesson | undefined>(
    scheduledLesson,
  );

  function onSetPage(scheduledLesson: ScheduledLesson) {
    setSelected(scheduledLesson);
    navigate(reportNavigate(klass.id, scheduledLesson.id));
  }

  const { results: nextLessons, isInitialLoading: loadingNextLesson } =
    useListService({
      ...scheduledLessonsQueryKeys.list({
        klassId,
        pageSize: 1,
        isActive: true,
        orderGt: scheduledLesson?.order,
      }),
      enabled: !!scheduledLesson?.order,
    });

  const nextLesson = nextLessons.at(0);

  const { results: previousLessons, isInitialLoading: loadingPreviousLesson } =
    useListService({
      ...scheduledLessonsQueryKeys.list({
        klassId,
        pageSize: 1,
        isActive: true,
        orderLt: scheduledLesson?.order,
        ordering: '-datetime',
      }),
      enabled: !!scheduledLesson?.order,
    });

  const previousLesson = previousLessons.at(0);

  const disablePrevious = !previousLesson;

  const disableNext = !nextLesson;

  const onClickPagination = async (value: 1 | -1) => {
    if (scheduledLesson) {
      if (value === 1 && nextLesson) {
        navigate(reportNavigate(klassId, nextLesson.id));
      } else if (value === -1 && previousLesson) {
        navigate(reportNavigate(klassId, previousLesson.id));
      }
    }
  };

  return (
    <div
      className="flex w-full text-primary justify-between"
      data-testid="pagination"
    >
      <IconButton
        onClick={() => onClickPagination(-1)}
        loading={loading}
        loadingSize="h-8"
        testId="previousButton"
        disabled={disablePrevious}
        icon={
          <ChevronLeftIcon
            className={`h-8 ${
              disablePrevious && !loadingPreviousLesson ? 'invisible' : ''
            } ${loadingPreviousLesson ? 'disabled' : ''}`}
          />
        }
      />

      <div className="flex w-full gap-4 flex-wrap justify-center items-center">
        <PillComponent
          testId="pill"
          className="bg-primary-content border-none px-3.5 py-1 gap-x-4 h-8"
          children={
            <Fragment>
              <CalendarIcon className="h-4 sm:h-6" />

              <Text
                testId="pillText"
                text={FormatScheduledDate(
                  scheduledLesson,
                  klass,
                  FormatTypeEnum.PILL,
                )}
                format="rubik-500"
                className="capitalize text-12 sm:text-16"
              />
            </Fragment>
          }
        />
        <div className="relative flex gap-4 items-center md:min-w-[500px] shrink-0">
          <InfiniteSearchInput
            onDeselect={() => setSelected(undefined)}
            service={scheduledLessonsQueryKeys.list}
            displayName={scheduledLesson =>
              formatLessonName(scheduledLesson.lesson, scheduledLesson)
            }
            filters={{
              klassId,
              isActive: true,
            }}
            onSelect={scheduledLesson => onSetPage(scheduledLesson)}
            selectedItem={selected}
            input={{ className: { base: 'w-full font-500' } }}
          />
          <Text
            testId="scheduledLessonName"
            text={t('gotoLesson')}
            format="rubik-500"
            className="text-center shrink-0 w-fit whitespace-nowrap sm:text-16 text-14"
            isLink
            href={`/courses/${klass.coursePathSlug}/lesson/${scheduledLesson?.lesson.id}/class/${klass.id}`}
          />
        </div>
      </div>

      <IconButton
        onClick={() => onClickPagination(1)}
        loading={loading}
        loadingSize="h-8"
        testId="nextButton"
        disabled={disableNext}
        icon={
          <ChevronRightIcon
            className={`h-8 ${
              disableNext && !loadingNextLesson ? 'invisible' : ''
            } ${loadingNextLesson ? 'disabled' : ''}`}
          />
        }
      />
    </div>
  );
};
