import { CourseTypeEnum } from '../models/Course';
import { StudentAgeEnum } from '../models/User';

export default function getCourseStudentAge(studentAge: StudentAgeEnum) {
  if (studentAge === StudentAgeEnum.SIX_OR_LESS) {
    return CourseTypeEnum.CTRL_KIDS;
  } else if (studentAge === StudentAgeEnum.SEVEN_TO_NINE) {
    return CourseTypeEnum.CTRL_TEENS;
  } else return CourseTypeEnum.CTRL_YOUNG;
}
