import { motion } from 'framer-motion';
import { useTabsPanelContext } from './Tabs';
import { twMerge } from 'tailwind-merge';

type TabsTriggerProps = {
  id: string;
  children: React.ReactNode;
  className?: string;
};
const TabsTriggerButton = ({
  id: key,
  children,
  className,
}: TabsTriggerProps) => {
  const { onChange } = useTabsPanelContext();
  return (
    <motion.button
      className={twMerge(
        'relative flex gap-5 px-4 items-center justify-center py-2.5 h-full w-full whitespace-nowrap',
        className,
      )}
      onClick={() => onChange(key)}
      role="tab"
      data-testid={`${key}-tab`}
    >
      {children}
    </motion.button>
  );
};

export default TabsTriggerButton;
