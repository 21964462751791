import { ReactNode, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { BriefcaseIcon, PlusIcon } from '@heroicons/react/outline';
import { motion } from 'framer-motion';

import Tag from '@/components/common/dataDisplay/Tag';
import User, { UserStatusEnum, UserTypeEnum } from '@/models/User';
import useInfiniteService from '@/data/hook/useInfiniteService';
import { vacationNoticeQueryKeys } from '@/data/services/querykeys';
import { REQUEST_STALE_TIME_IN_MS } from '@/constants';
import ToggleButtonGroup, {
  ToggleButton,
} from '@/components/common/dataInput/ToggleGroup';
import ConditionalRenderer from '@/components/common/ConditionalRenderer';
import { fadeIn } from '@/utils/animations/commom';
import ComponentGuard from '@/components/common/ComponentGuard';
import {
  VacationNotice as Vacation,
  VacationNoticeTab,
} from '@/models/VacationNotice';
import VacationNotices from './VacationNotices';
import { getErrorMessage } from '@/utils/getErrorMessage';
import VacationNoticeForm from './VacationNoticeForm';
import useManualQuery from '@/data/hook/useManualQuery';

export type ManualUpdate = 'add' | 'update';

export type EditVacationNotice = {
  tab: VacationNoticeTab;
  vacationNotice: Vacation;
};

export type StudentVacationNoticeProps = {
  student: User;
};

export default function StudentVacationNotice({
  student,
}: StudentVacationNoticeProps) {
  const [t] = useTranslation('translation', {
    keyPrefix: 'common',
  });

  const { t: tComponent } = useTranslation('translation', {
    keyPrefix: 'vacationNotice',
  });

  const [tab, setTab] = useState<VacationNoticeTab>('view');

  const [vacationNotice, setVacationNotice] = useState<Vacation>();

  const block = student.status !== UserStatusEnum.ACTIVE_ENROLLMENT;

  const filters = {
    userId: student.id,
    pageSize: 5,
  };

  const {
    results: vacationNotices,
    isInitialLoading: loading,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
    error,
    invalidate: invalidateData,
  } = useInfiniteService({
    ...vacationNoticeQueryKeys.list(filters)._ctx.infinity,
    keepPreviousData: true,
    enabled: !!student.id,
    staleTime: REQUEST_STALE_TIME_IN_MS,
  });

  const { update, remove } = useManualQuery(
    vacationNoticeQueryKeys.list(filters)._ctx.infinity,
  );

  function changeTab(changed: VacationNoticeTab) {
    if (changed === tab) setTab('view');
    else setTab(changed);
  }

  const editVacationNotice = ({ vacationNotice, tab }: EditVacationNotice) => {
    setVacationNotice(vacationNotice);
    changeTab(tab);
  };

  type Tabs = {
    [key in VacationNoticeTab]: ReactNode;
  };

  const tabs: Tabs = {
    view: (
      <VacationNotices
        student={student}
        edit={editVacationNotice}
        remove={remove}
        vacationNotices={vacationNotices}
        fetchNextPage={fetchNextPage}
        loading={loading}
        error={getErrorMessage(error)}
        hasNextPage={hasNextPage}
        isFetchingNextPage={isFetchingNextPage}
      />
    ),
    form: (
      <VacationNoticeForm
        vacationNotice={vacationNotice}
        userId={student.id}
        changeTab={changeTab}
        updateData={update}
        invalidateData={invalidateData}
      />
    ),
  };

  const buttons: ToggleButton<VacationNoticeTab>[] = [
    {
      testId: 'view',
      text: t('warnings'),
      value: 'view',
      disabled: loading,
    },
    {
      testId: 'form',
      text: t('toAdd'),
      value: 'form',
      disabled: loading,
      icon: <PlusIcon className="w-3.5" />,
    },
  ];

  return (
    <motion.section
      className="px-9 py-2.5 flex flex-col gap-2.5 w-full"
      {...fadeIn}
    >
      <Tag
        text={tComponent('title')}
        icon={<BriefcaseIcon className="w-6 h-6" />}
      />
      <ConditionalRenderer condition={!block}>
        <ComponentGuard roles={[UserTypeEnum.UNIT_ADMIN]}>
          <ToggleButtonGroup
            className="lowercase"
            buttons={buttons}
            value={tab}
            onChange={tab => {
              setVacationNotice(undefined);
              changeTab(tab);
            }}
            exclusive
          />
        </ComponentGuard>
      </ConditionalRenderer>
      {tabs[tab]}
    </motion.section>
  );
}
