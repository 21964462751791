import { AnimatePresence, Variants, motion } from 'framer-motion';
import { defaultAnimation } from '@/utils/animations/defaultAnimation';
import { twMerge } from 'tailwind-merge';
import { ReactNode } from 'react';

interface AccordionContainerProps {
  isOpen?: boolean;
  className?: string;
  animate?: 'open' | 'closed';
  variants?: Variants;
  initial?: string;
  children?: ReactNode;
}

export default function AccordionContainer(props: AccordionContainerProps) {
  const { className, animate, variants, initial, children } = props;
  return (
    <AnimatePresence>
      <motion.div
        className={twMerge('w-full overflow-hidden', className)}
        animate={animate ?? 'open'}
        variants={variants ?? defaultAnimation}
        initial={initial ?? 'closed'}
        exit={initial ?? 'closed'}
        data-testid="accordionAnimation"
      >
        {children}
      </motion.div>
    </AnimatePresence>
  );
}
