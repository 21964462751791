import {
  CheckCircleIcon,
  XCircleIcon,
  MinusCircleIcon,
  ExclamationCircleIcon,
} from '@heroicons/react/solid';
import { UserStatusEnum } from '../../../models/User';

export const IconByStatus = ({
  status,
}: {
  status: UserStatusEnum;
}): React.ReactElement => {
  switch (status) {
    case UserStatusEnum.ACTIVE_ENROLLMENT:
      return <CheckCircleIcon className="w-5 h-5 text-primary" />;
    case UserStatusEnum.INACTIVE:
      return <XCircleIcon className="w-5 h-5 text-neutral/50" />;
    case UserStatusEnum.REGISTRED:
      return <MinusCircleIcon className="w-5 h-5 text-secondary" />;
    default:
      return (
        <ExclamationCircleIcon className="w-5 h-5 text-secondary-content" />
      );
  }
};
