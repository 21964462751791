import { ReactNode, useState } from 'react';
import { usePopper } from 'react-popper';
import ConditionalRenderer from '../ConditionalRenderer';
import { twMerge } from 'tailwind-merge';
import { motion } from 'framer-motion';
import useToggle from '@/data/hook/useToggle';

type Placement =
  | 'auto'
  | 'auto-start'
  | 'auto-end'
  | 'top'
  | 'top-start'
  | 'top-end'
  | 'bottom'
  | 'bottom-start'
  | 'bottom-end'
  | 'right'
  | 'right-start'
  | 'right-end'
  | 'left'
  | 'left-start'
  | 'left-end';

interface PopperProps {
  referenceContent: ReactNode;
  popperContent: ReactNode;
  disabled?: boolean;
  popperOffset?: [x: number, y: number];
  popperClassName?: string;
  referenceClassName?: string;
  hover?: boolean;
  placement?: Placement;
  className?: string;
}

export default function Popper(props: PopperProps) {
  const {
    referenceContent,
    popperContent,
    disabled = false,
    popperOffset,
    popperClassName,
    referenceClassName,
    hover,
    placement = 'auto',
  } = props;

  const { isOpen, close, open, toggle } = useToggle();
  const isHoverEnabled = hover && !disabled;
  const onMouseEnter = isHoverEnabled ? open : undefined;
  const onMouseLeave = isHoverEnabled ? close : undefined;

  const [reference, setReference] = useState<HTMLDivElement | null>(null);

  const [popper, setPopper] = useState<HTMLDivElement | null>(null);

  const { styles, attributes } = usePopper(reference, popper, {
    placement,
    modifiers: [
      {
        name: 'offset',
        options: {
          offset: popperOffset ?? [0, 0],
        },
      },
    ],
  });

  return (
    <div className="relative flex">
      <div
        className={twMerge(
          'flex items-center cursor-pointer',
          referenceClassName,
          'relative',
        )}
        ref={ref => setReference(ref)}
        onClick={toggle}
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
      >
        {referenceContent}
      </div>

      <ConditionalRenderer condition={isOpen && !disabled}>
        <div
          ref={ref => setPopper(ref)}
          style={styles.popper}
          {...attributes.popper}
          className={twMerge('flex w-full', popperClassName) + ' m-0 z-50'}
        >
          <motion.div
            initial={{ opacity: 0, scale: 0.95 }}
            animate={{ opacity: 1, scale: 1 }}
            transition={{ duration: 0.1 }}
          >
            <div onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave}>
              {popperContent}
            </div>
            <ConditionalRenderer condition={!hover}>
              <div
                onClick={close}
                className="w-screen h-screen absolute -top-40 -left-80 -z-10"
              />
            </ConditionalRenderer>
          </motion.div>
        </div>
      </ConditionalRenderer>
    </div>
  );
}
