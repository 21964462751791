import { PropsWithChildren } from 'react';
import { twMerge } from 'tailwind-merge';
import { useTabsPanelContext } from './Tabs';
import TabsContent from './TabsContent';

type PanelSectionProps = PropsWithChildren<{
  className?: string;
}>;

const TabsSection = ({ className, children }: PanelSectionProps) => {
  const { active, contents } = useTabsPanelContext();

  const activeIsFirst = Array.from(contents.keys())[0] === active;

  const firstBeforeStyle = activeIsFirst
    ? "before:content-[''] before:w-3 before:h-3 before:absolute before:bg-base-100 before:inset-0"
    : '';

  return (
    <section
      className={twMerge(
        'relative w-full rounded-md bg-base-100',
        className,
        firstBeforeStyle,
      )}
    >
      {children}
    </section>
  );
};

export function TabsShowContent() {
  return (
    <TabsSection>
      <TabsContent />
    </TabsSection>
  );
}

export default TabsSection;
