import { EnrollmentAndReportStatus } from '@/models/ScheduledLessonReport';
import { useTranslation } from 'react-i18next';
import useAuth from './useAuth';
import { isAdmin } from '@/functions/auth';

export function useEnrollmentStatusTranslation(
  status: EnrollmentAndReportStatus,
) {
  const [t] = useTranslation('translation', {
    keyPrefix: 'enrollmentStatus',
  });
  const { user } = useAuth();
  return t(status, { context: isAdmin(user?.userType) ? '' : 'teacher' });
}
