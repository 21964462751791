import React, {
  Dispatch,
  SetStateAction,
  createContext,
  useCallback,
  useState,
} from 'react';
import { useParams } from 'react-router-dom';
import { useQueryClient } from '@tanstack/react-query';
import { Step } from '@/components/common/Steps/Steps';
import ActivityProgress, {
  ActivityMode,
  ActivityProgressAttempt,
  IQuestionProgress,
  QuestionProgress,
} from '@/models/ActivityProgress';
import { createStepByAttempt } from '@/utils/activitiesSteps';
import { activityProgressesQueryKeys } from '../services/querykeys';
import { createQuestionProgress } from '@/utils/activities';
import { ActivityTypeEnum } from '@/models/Activity';

type CreateSteps = {
  questionsProgress?: QuestionProgress[];
  attemptProgress?: ActivityProgressAttempt;
};

type CreateStep = {
  progress: QuestionProgress;
  index: number;
};

export type ActivityStatus = 'starting' | 'inProgress';

type ActivityViewContextProps = {
  activeStep: number;
  setActiveStep: Dispatch<SetStateAction<number>>;
  blockNavigate: boolean;
  blockStepNavigate(blocked: boolean): void;
  mode: ActivityMode;
  setMode: Dispatch<SetStateAction<ActivityMode>>;
  activityAttempt?: ActivityProgressAttempt;
  setActivityAttempt: Dispatch<
    SetStateAction<ActivityProgressAttempt | undefined>
  >;

  invalidate: (type: ActivityTypeEnum, questionProgress: any) => Promise<void>;
  createSteps: ({ attemptProgress, questionsProgress }: CreateSteps) => Step[];
  status: ActivityStatus;
  setStatus: React.Dispatch<React.SetStateAction<ActivityStatus>>;
  resetState: (mode: ActivityMode) => void;
};

export const ActivityViewContext = createContext<ActivityViewContextProps>(
  {} as ActivityViewContextProps,
);

interface ActivityViewProviderProps {
  children: React.ReactNode;
}

export default function ActivityViewProvider({
  children,
}: ActivityViewProviderProps) {
  const { activityId } = useParams();

  const queryClient = useQueryClient();

  const [activeStep, setActiveStep] = useState<number>(0);

  const [status, setStatus] = useState<ActivityStatus>('starting');

  const [activityAttempt, setActivityAttempt] =
    useState<ActivityProgressAttempt>();

  const [blockNavigate, setBlockNavigate] = useState(false);

  const [mode, setMode] = useState<ActivityMode>('activity');

  const activityProgressId = Number(activityId);

  //TODO: criar um tipo base para os question progress
  const invalidate = async (type: ActivityTypeEnum, questionProgress: any) => {
    const queryKey =
      activityProgressesQueryKeys.get(activityProgressId).queryKey;
    const activityProgress =
      queryClient.getQueryData<ActivityProgress>(queryKey);
    if (!activityProgress || !questionProgress) {
      return;
    }
    function updateProgress(
      currentProgress: QuestionProgress | IQuestionProgress,
    ) {
      const isSameType = currentProgress.type === type;
      const isSameProgress = currentProgress.progressId === questionProgress.id;
      if (isSameType && isSameProgress) {
        return createQuestionProgress({ type, questionProgress });
      }
      return currentProgress;
    }

    const updatedQuestionProgress =
      activityProgress.questionsProgress?.map(updateProgress) ?? [];

    activityProgress.questionsProgress = updatedQuestionProgress;
    queryClient.setQueryData(queryKey, activityProgress);
  };

  const blockStepNavigate = (shouldBlock: boolean) =>
    setBlockNavigate(shouldBlock);

  const onClickStep = useCallback(
    (stepIndex: number) => {
      if (mode === 'result') {
        setMode('review');
      }
      setActiveStep(stepIndex);
    },
    [mode],
  );

  const createStep = useCallback(
    ({ progress, index }: CreateStep) => {
      const { isDone: done, answer, order, progressId } = progress;

      const step: Step = {
        id: order ?? progressId,
        status: 'todo',
        onClick: () => onClickStep(index),
      };

      if (done) {
        step.status = 'done';
      } else if (!answer && activeStep > index) {
        step.status = 'skipped';
      } else if (index === activeStep && !done) {
        step.status = 'active';
      }

      return step;
    },
    [activeStep, onClickStep],
  );

  const createSteps = useCallback(
    ({ attemptProgress, questionsProgress }: CreateSteps) => {
      if (attemptProgress && attemptProgress.content) {
        const steps = attemptProgress.content.map(step =>
          createStepByAttempt(step, onClickStep),
        );

        return steps;
      }

      if (questionsProgress) {
        const steps = questionsProgress.map<Step>((progress, index) =>
          createStep({ progress, index }),
        );
        return steps;
      }

      return [];
    },
    [createStep, onClickStep],
  );

  const resetState = (mode: ActivityMode) => {
    setMode(mode);
    setStatus('starting');
    setActiveStep(0);
  };

  return (
    <ActivityViewContext.Provider
      value={{
        activeStep,
        blockNavigate,
        setActiveStep,
        blockStepNavigate,
        mode,
        setMode,
        activityAttempt,
        setActivityAttempt,
        createSteps,
        invalidate,
        status,
        setStatus,
        resetState,
      }}
    >
      {children}
    </ActivityViewContext.Provider>
  );
}
