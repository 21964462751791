import { SaveIcon } from '@heroicons/react/outline';
import { isEqual } from 'lodash';
import { useEffect, useState } from 'react';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import useToggle from '@/data/hook/useToggle';
import {
  createTeacherLessonRating,
  updateTeacherLessonRating,
} from '@/data/services/lessonRatingServices';
import { TeacherLessonRating } from '@/models/LessonRating';
import alert from '@/utils/UseAlert';
import { buildChangedObject } from '@/utils/buildChangedObject';
import ConditionalRenderer from '@/components/common/ConditionalRenderer';
import MainButton from '@/components/common/buttons/MainButton';
import {
  ColorTypes,
  Rating,
  RatingTypes,
} from '@/components/common/dataDisplay/Rating';
import Text from '@/components/common/dataDisplay/Text';
import TextAreaInput from '@/components/common/dataInput/TextAreaInput';
import { LoadingIcon } from '@/components/icons';
import ComponentGuard from '@/components/common/ComponentGuard';
import { UserTypeEnum } from '@/models/User';
import LessonCard from '../LessonCard/LessonCard';
import useInfiniteService from '@/data/hook/useInfiniteService';
import { teacherLessonRatingssQuerykeys } from '@/data/services/querykeys';

type TeacherFeedbackCardProps = {
  lessonId: number;
  teacherId?: number;
};

export const TeacherFeedbackCard = ({
  lessonId,
  teacherId,
}: TeacherFeedbackCardProps) => {
  interface RatingInputs {
    rating: number;
    comment: string;
  }
  const { t } = useTranslation('translation', {
    keyPrefix: 'klassView.feedback',
  });

  const {
    results: teacherLessonRating,
    isInitialLoading,
    isFetching: isLoading,
    invalidate,
  } = useInfiniteService({
    ...teacherLessonRatingssQuerykeys.list({ lessonId, userId: teacherId }),
    enabled: !!lessonId && !!teacherId,
    refetchOnMount: false,
  });

  const lessonRating = teacherLessonRating?.[0];

  const [isRequesting, setIsRequesting] = useState(false);
  const { isOpen, open, close } = useToggle(false);
  const [hasChanges, setHasChanges] = useState(false);
  const { handleSubmit, register, control, watch } = useForm<RatingInputs>({
    defaultValues: lessonRating,
  });

  useEffect(() => {
    const changes = watch(value => {
      setHasChanges(!isEqual(value, lessonRating));
    });

    return () => changes.unsubscribe();
  }, [watch, lessonRating]);

  const onSubmit: SubmitHandler<RatingInputs> = async ({ rating, comment }) => {
    const body: Omit<TeacherLessonRating, 'userId' | 'createdAt' | 'id'> = {
      lessonId,
      rating,
      comment,
    };

    try {
      setIsRequesting(true);
      open();
      if (!lessonRating?.id) {
        if (!rating) {
          alert.warning(t('alertWarning'));
          return;
        } else {
          await createTeacherLessonRating(body);
        }
      } else {
        const changedFields = buildChangedObject<TeacherLessonRating>(
          lessonRating,
          body,
        );
        await updateTeacherLessonRating(lessonRating?.id, changedFields);
      }
      await invalidate();
      setHasChanges(false);
      alert.success(t('saveSuccess'));
      close();
    } catch (error) {
      alert.error(t('saveError'));
    } finally {
      setIsRequesting(false);
    }
  };

  return (
    <ComponentGuard roles={[UserTypeEnum.TEACHER]}>
      <ConditionalRenderer condition={lessonId}>
        <LessonCard
          notification={lessonRating ? 0 : 1}
          active={isOpen}
          type="teacherFeedback"
          testId="teacherFeedbackCard"
        >
          <ConditionalRenderer
            condition={!isInitialLoading}
            fallback={
              <div className="flex justify-center items-center w-full h-16">
                <LoadingIcon className="w-8 h-8 text-secondary/40" />
              </div>
            }
          >
            <form
              onSubmit={handleSubmit(onSubmit)}
              className="flex gap-y-3 p-3 flex-col"
            >
              <Text
                text={t('feedbackInformation')}
                className="text-neutral/50 text-12"
              />
              <Controller
                name="rating"
                control={control}
                render={({ field: { onChange } }) => (
                  <div className="flex flex-col">
                    <Text text={t('content')} />
                    <Rating
                      testId="teacherRating"
                      type={RatingTypes.STAR}
                      length={5}
                      onClickRating={onChange}
                      value={lessonRating?.rating || 0}
                      color={ColorTypes.YELLOW}
                    />
                  </div>
                )}
              />
              <TextAreaInput
                testId="teacherRatingComment"
                label={t('comment')}
                defaultValue={lessonRating?.comment || ''}
                register={register('comment')}
              />
              <MainButton
                dataTestId="teacherRatingSave"
                loading={isRequesting || isLoading}
                icon={<SaveIcon />}
                disabled={!hasChanges}
                text={t('save')}
                type="submit"
              />
            </form>
          </ConditionalRenderer>
        </LessonCard>
      </ConditionalRenderer>
    </ComponentGuard>
  );
};
