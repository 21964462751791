import useMutationObserver, {
  CustomMutationCallback,
} from '@/data/hook/useMutationObverser';
import { PropsWithChildren, useCallback } from 'react';
import { useTabsPanelContext } from './Tabs';

const TabsTriggerListContainer = ({ children }: PropsWithChildren) => {
  const { setTriggerListSize } = useTabsPanelContext();

  const setListDimensions: CustomMutationCallback<HTMLDivElement> = useCallback(
    (_e, _o, ref) => {
      setTriggerListSize(
        ref
          ? {
              width: ref.getBoundingClientRect().width,
              height: ref.clientHeight,
            }
          : undefined,
      );
    },
    [setTriggerListSize],
  );
  const { ref } = useMutationObserver<HTMLDivElement>(setListDimensions, {
    attributes: true,
    subtree: true,
  });

  return (
    <div ref={ref} className="relative h-fit">
      {children}
    </div>
  );
};

export default TabsTriggerListContainer;
