import React, { useEffect } from 'react';
import usePlacesAutocomplete from 'use-places-autocomplete';
import TextInput from './TextInput';
import { useJsApiLoader } from '@react-google-maps/api';
import { placesLibraries } from '../../../utils/placesLocationUtils';
import { Control, Controller } from 'react-hook-form';
import ConditionalRenderer from '../ConditionalRenderer';
import { BaseInputProps } from './BaseInput';
import { twMerge } from 'tailwind-merge';

type PlacesAutoCompleteInputProps = BaseInputProps & {
  label?: string;
  labelPosition?: 'top' | 'left';
  testId?: string;
  defaultValue?: string;
  disabled?: boolean;
  placeholder?: string;
  control: Control<any>;
  name: string;
};

export default function PlacesAutoCompleteInput({
  testId,
  label,
  labelPosition,
  placeholder,
  disabled,
  control,
  name,
  className,
}: PlacesAutoCompleteInputProps) {
  const {
    setValue,
    suggestions: { status, data },
    clearSuggestions,
    init,
  } = usePlacesAutocomplete({
    initOnMount: false,
    requestOptions: {
      types: ['(cities)'],
      componentRestrictions: {
        country: 'br',
      },
    },
  });

  const API_KEY = process.env.REACT_APP_API_KEY_GOOGLE || '';
  const { isLoaded } = useJsApiLoader({
    googleMapsApiKey: API_KEY,
    libraries: placesLibraries,
  });

  useEffect(() => {
    if (isLoaded) {
      init();
    }
  }, [init, isLoaded]);

  const handleValue = (value: string) => {
    setValue(value, false);
    clearSuggestions();
  };

  return (
    <div className={twMerge('w-full relative', className?.base)}>
      <Controller
        name={name}
        control={control}
        render={({ field: { onChange, value } }) => (
          <React.Fragment>
            <TextInput
              testId={testId}
              autoComplete="off"
              label={label}
              labelPosition={labelPosition}
              placeholder={placeholder}
              value={value}
              onChange={e => {
                const value = e.target.value;
                onChange(value);
                setValue(value);
              }}
              disabled={disabled}
              className={{
                ...className,
                base: `${className?.base} ${
                  data.length > 0 && 'rounded-b-none ring-primary-content ring'
                }`,
              }}
            />
            <ConditionalRenderer condition={status === 'OK'}>
              <ul className="absolute list-none bg-base-100 z-30 w-full ring-primary-content ring-[3px] rounded-t-none rounded">
                {data.map(value => {
                  return (
                    <li
                      className="w-full cursor-pointer p-1 hover:bg-neutral-content"
                      onClick={() => {
                        const { description } = value;
                        onChange(description);
                        handleValue(description);
                      }}
                      key={value.place_id}
                    >
                      {value.description}
                    </li>
                  );
                })}
              </ul>
            </ConditionalRenderer>
          </React.Fragment>
        )}
      />
    </div>
  );
}
