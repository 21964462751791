import { useEffect, useRef, useState } from 'react';
import { isEqual } from 'lodash';
import { useMutation } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';
import { SaveIcon } from '@heroicons/react/outline';
import { Controller, useForm } from 'react-hook-form';

import { TextActivityElement } from '@/models/ActivityElement';
import MainButton from '@/components/common/buttons/MainButton';
import Activity from '@/models/Activity';
import MyCkeditor from '@/components/editor/MyCkeditor';
import { ImageFolderEnum } from '@/models/CkEditor';
import alert from '@/utils/UseAlert';
import ContainerActivityEdit from './commom/ContainerActivityEdit';
import TextInput from '@/components/common/dataInput/TextInput';
import { buildChangedObject } from '@/utils/buildChangedObject';
import ConditionalRenderer from '@/components/common/ConditionalRenderer';
import {
  createTextActivity,
  UpdateTextActivity,
  updateTextActivity,
} from '@/data/services/activityElement/textActivityServices';
import { getErrorMessage } from '@/utils/getErrorMessage';

export type TextElementEditProps = Omit<TextActivityElement, 'activity'> & {
  activity: Activity;
  onEdit: (props: any) => void;
  onSave: (props: any) => void;
};

export default function TextElementEdit(props: TextElementEditProps) {
  const { t } = useTranslation('translation', {
    keyPrefix: 'activity.manageActivity.activityText',
  });
  const [tCommon] = useTranslation('translation', {
    keyPrefix: 'common',
  });

  const {
    onEdit,
    changeStatus,
    activity,
    type,
    identifier,
    order,
    onSave,
    title,
    subtitle,
    content,
  } = props;

  const [hasChanges, setHasChanges] = useState(false);

  type TextActivityFields = {
    title: string;
    subtitle: string;
    content: string;
  };

  const defaultValues = useRef<TextActivityFields>({
    title,
    subtitle,
    content,
  });

  const {
    watch,
    handleSubmit,
    control,
    formState: { errors },
    register,
  } = useForm<TextActivityFields>({ defaultValues: defaultValues.current });

  const { mutate: create, isLoading: loadingCreate } = useMutation(
    createTextActivity,
    {
      onSuccess(data) {
        onSave({ ...props, id: data.id });
        setHasChanges(false);
        alert.success(tCommon('saved'));
      },
      onError(error) {
        alert.error(getErrorMessage(error));
      },
    },
  );

  const { mutate: update, isLoading: loadingUpdate } = useMutation(
    (params: UpdateTextActivity) =>
      updateTextActivity(params.idTextActivity, params.changes),
    {
      onSuccess: () => {
        onSave(props);
        setHasChanges(false);
        alert.success(tCommon('saved'));
      },
      onError: error => {
        alert.error(getErrorMessage(error));
      },
    },
  );

  const onSubmit = async (changes: Partial<TextActivityFields>) => {
    const changedFields = buildChangedObject(defaultValues.current, changes);

    if (Object.values(changedFields).length) {
      if (changeStatus === 'new')
        create({
          ...changedFields,
          activity: activity.id,
          type,
          identifier,
          order,
        });
      else update({ idTextActivity: props.id, changes: changedFields });
    }
  };

  const loading = loadingCreate || loadingUpdate;

  useEffect(() => {
    const subscribe = watch(value =>
      setHasChanges(!isEqual(value, defaultValues.current)),
    );
    return () => {
      setHasChanges(false);
      subscribe.unsubscribe();
    };
  }, [defaultValues, watch]);

  return (
    <ContainerActivityEdit className="flex flex-col gap-5" id="TextElementEdit">
      <form className="flex flex-col gap-2" onSubmit={handleSubmit(onSubmit)}>
        <TextInput
          testId="textActivityTitle"
          {...register('title', {
            onChange({ target: { value: title } }) {
              onEdit({ ...props, title });
            },
            required: t('titleError'),
          })}
          label={t('title')}
          errorLabelText={errors.title?.message}
          className={{ base: 'text-16' }}
          color={errors.subtitle?.message ? 'warning' : 'primary'}
        />

        <TextInput
          testId="textActivitySubtitle"
          {...register('subtitle', {
            onChange({ target: { value: subtitle } }) {
              onEdit({ ...props, subtitle });
            },
            required: t('subtitleError'),
          })}
          label={t('subtitle')}
          errorLabelText={errors.subtitle?.message}
          className={{ base: 'text-16' }}
          color={errors.subtitle?.message ? 'warning' : 'primary'}
        />

        <Controller
          rules={{ required: t('contentError') }}
          name="content"
          control={control}
          render={({ field: { value, onChange }, fieldState: { error } }) => (
            <section className="flex flex-col">
              <label className="font-rubik text-base-content leading-none">
                {t('content')}
              </label>
              <ConditionalRenderer condition={error?.message}>
                <label className="font-rubik text-error error-label text-14">
                  {t('contentError')}
                </label>
              </ConditionalRenderer>
              <MyCkeditor
                testId="textActivityContent"
                content={value}
                folder={ImageFolderEnum.HOMEWORK}
                handleContentChange={content => {
                  onChange(content);
                  onEdit({ ...props, content });
                }}
              />
            </section>
          )}
        />

        <MainButton
          dataTestId="textActivitySave"
          type="submit"
          loading={loading}
          disabled={!hasChanges || loading}
          icon={<SaveIcon />}
          id="text-activity-save"
          text={t('save')}
        />
      </form>
    </ContainerActivityEdit>
  );
}
