import { useTranslation } from 'react-i18next';
import { PencilIcon, TrashIcon } from '@heroicons/react/outline';
import moment from 'moment';
import { useMutation } from '@tanstack/react-query';

import { VacationNotice } from '@/models/VacationNotice';
import Text from '@/components/common/dataDisplay/Text';
import IconButton from '@/components/common/buttons/IconButton';
import { Tooltip } from '@/components/common/dataDisplay/Tooltip';
import useToggle from '@/data/hook/useToggle';
import { VacationNoticesProps } from './VacationNotices';
import ConditionalRenderer from '@/components/common/ConditionalRenderer';
import ModalWarning from '@/components/common/modals/ModalWarning';
import {
  removeVacationNotice,
  VacationNoticeParams,
} from '@/data/services/vacationNoticeServices';
import alert from '@/utils/UseAlert';
import { getErrorMessage } from '@/utils/getErrorMessage';
import ComponentGuard from '@/components/common/ComponentGuard';
import { UserTypeEnum } from '@/models/User';

export const mutateRemove = (params: Omit<VacationNoticeParams, 'body'>) =>
  removeVacationNotice(params.vacationNoticeId);

type VacationNoticeViewProps = Pick<VacationNoticesProps, 'edit' | 'remove'> & {
  vacationNotice: VacationNotice;
};

export default function VacationNoticeView({
  vacationNotice,
  edit,
  remove,
}: VacationNoticeViewProps) {
  const [t] = useTranslation('translation', {
    keyPrefix: 'common',
  });

  const { startDate, endDate, reason, id: vacationNoticeId } = vacationNotice;

  const startAndEndDate = () => {
    const startDateFormated = moment(startDate).format('DD/MM/YYYY');
    const endDateFormated = moment(endDate).format('DD/MM/YYYY');

    const onlyOneDay = startDateFormated === endDateFormated;

    const addEndDate = onlyOneDay ? '' : `-${endDateFormated}`;

    const formated = `${startDateFormated}${addEndDate}`;

    return formated;
  };

  const dateRange = startAndEndDate();

  const { isOpen, toggle } = useToggle();

  const { mutate: removeVacation, isLoading } = useMutation(mutateRemove, {
    onSuccess: async (_data, variables) => {
      alert.success(t('saved'));
      remove(variables.vacationNoticeId);
    },
    onError: error => {
      alert.error(getErrorMessage(error));
    },
  });

  return (
    <li className="flex flex-col gap-1.5">
      <ModalWarning
        visible={isOpen}
        translationString="modalRemoveVacationNotice"
        onClickConfirm={() => removeVacation({ vacationNoticeId })}
        onClickCancel={toggle}
        isLoading={isLoading}
      />
      <section className="flex w-full justify-between">
        <Text
          className="text-primary text-[0.875rem]"
          format="rubik-500"
          text={dateRange}
        />
        <ComponentGuard roles={[UserTypeEnum.UNIT_ADMIN]}>
          <ul className="flex gap-1.5">
            <li>
              <Tooltip text={t('edit')} className="lowercase">
                <IconButton
                  testId={`edit${vacationNoticeId}`}
                  icon={<PencilIcon className="w-4 text-primary" />}
                  onClick={() => edit({ tab: 'form', vacationNotice })}
                />
              </Tooltip>
            </li>
            <li>
              <Tooltip
                text={t('remove')}
                className="lowercase"
                placement="bottom"
              >
                <IconButton
                  testId={`remove${vacationNoticeId}`}
                  icon={<TrashIcon className="w-4 text-error" />}
                  onClick={toggle}
                />
              </Tooltip>
            </li>
          </ul>
        </ComponentGuard>
      </section>
      <ConditionalRenderer condition={reason}>
        <Text
          text={reason}
          className="text-neutral text-[0.875rem]"
          format="rubik-400"
        />
      </ConditionalRenderer>
      <hr className="h-px border-primary/40" />
    </li>
  );
}
