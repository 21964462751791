import moment from 'moment';
import { ScheduledLessonFilters } from '@/data/services/scheduledLessonsServices';
import { ScheduledLessonFilterEnum } from '@/enums/ScheduledLessonFilter';
import { DateRange } from 'react-day-picker';

type Options = {
  initialFilters?: ScheduledLessonFilters;
  pinnedFilters?: ScheduledLessonFilters;
};

export const handleScheduledLessonFilters = (
  filter: ScheduledLessonFilterEnum,
  { initialFilters, pinnedFilters }: Options = {},
): ScheduledLessonFilters => {
  const currentDate = moment().format('YYYY-MM-DD');

  switch (filter) {
    case ScheduledLessonFilterEnum.COMPLETED_SCHEDULED_LESSONS:
      return {
        ...pinnedFilters,
        hasDone: true,
      };
    case ScheduledLessonFilterEnum.PENDING_SCHEDULED_LESSONS:
      return {
        ...pinnedFilters,
        isPendingReport: true,
      };
    case ScheduledLessonFilterEnum.ONLY_SCHEDULED_LESSONS:
      return {
        ...pinnedFilters,
        dateRangeAfter: currentDate,
      };
    default:
      return initialFilters ?? {};
  }
};

export const createDateRange = ({ from, to }: DateRange) => {
  if (!to) {
    return {
      dateRangeBefore: undefined,
      dateRangeAfter: undefined,
    };
  }
  return {
    dateRangeBefore: moment(to).format('YYYY-MM-DD'),
    dateRangeAfter: moment(from).format('YYYY-MM-DD'),
  };
};
