import { Item, Token } from '@cna-br/astro-code';
import Text from '../../dataDisplay/Text';
import { colorByTokenType } from './ModalGameEditor';
import { useGameTokens } from './ModalGameEditor.hooks';

type FocusItemProps = {
  item: Item<Token>;
  selectedLayer: 'ground' | 'interactive';
};

export default function FocusItem({ item, selectedLayer }: FocusItemProps) {
  const { describes, names } = useGameTokens();

  return (
    <div className="relative w-full">
      <div
        className={`rounded-xl p-5 max-w-xs flex flex-col gap-5 mb-3 mx-auto ${colorByTokenType[selectedLayer].backgroundColor}`}
      >
        <Text
          text={names[item.token]}
          className={`text-center text-primary`}
          format="poppins-600"
          size="text-14 lg:text-16"
        />
        <img
          src={item.sprite.toString()}
          alt={names[item.token]}
          className="mx-auto w-12 lg:w-14"
        />
        <Text text={describes[item.token]} size="text-14 lg:text-16" />
      </div>
    </div>
  );
}
