import React from 'react';
import { debounce } from 'lodash';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import Klass from '@/models/Klass';
import DataKlassTable from './DataKlassTable';
import SearchAndFilterButtons from '@/components/common/SearchAndFilterButtons';
import Header from '@/components/common/table/Header';
import { KlassHeader } from '@/utils/HeaderTypes';
import Text from '@/components/common/dataDisplay/Text';
import ConditionalRenderer from '@/components/common/ConditionalRenderer';
import { LoadingIcon } from '@/components/icons';
import useFilterParams from '@/utils/UseFilterParams';
import { ListKlassesFilters } from '@/data/services/klassServices';
import { QuickFilterProps as QuickFilterItem } from '@/components/common/filter/QuickFilter';
import QuickFilters from '@/components/common/filter/QuickFilters';

interface ListKlassesProps {
  klassHeader: KlassHeader;
  klassList: Klass[];
  isSearch?: boolean;
  isLoading?: boolean;
}

export default function ListKlasses({
  klassHeader,
  klassList,
  isSearch,
  isLoading,
}: ListKlassesProps) {
  const { t } = useTranslation('translation', {
    keyPrefix: 'manageKlasses.dataKlassTable',
  });

  const navigate = useNavigate();

  const { filterParams, setFilterParams, deleteFilterParams } =
    useFilterParams<ListKlassesFilters>();

  const name = filterParams.get('name') ?? '';

  const hasSearch = !!name || isSearch;

  const hasParams = [...filterParams.keys()].length !== 0;

  const requestSearch = (value: string) => {
    if (!value) {
      deleteFilterParams('name');
    } else {
      setFilterParams({ name: value });
    }

    setFilterParams({ pageNumber: 1 });
  };

  function clearSearch() {
    deleteFilterParams('name');
    navigate('/admin/classes');
  }

  function clearFilters() {
    deleteFilterParams(['name', 'unitId', 'status']);
    navigate('/admin/classes');
  }

  function clearSorts() {
    deleteFilterParams('ordering');
    setFilterParams({ pageNumber: 1 });
  }

  function clearFiltersAndSorts() {
    clearFilters();
    clearSorts();
  }

  const quickFilters: QuickFilterItem[] = [
    {
      type: 'unitId',
      filters: filterParams.getAll('unitId'),
    },
    {
      type: 'klassStatus',
      filters: filterParams.getAll('status'),
    },
  ];

  return (
    <React.Fragment>
      <div className="flex gap-4 justify-between items-center">
        <SearchAndFilterButtons
          searchInitial={name}
          onChange={debounce(e => requestSearch(e.target.value), 500)}
          isSearch={hasSearch}
          clearFiltersButton={clearFilters}
          clearOrderButton={clearSorts}
          clearAllButton={clearFiltersAndSorts}
          clearSearch={clearSearch}
        />
        <QuickFilters quickFilters={quickFilters} />
      </div>
      <div>
        <table className="w-full flex flex-col gap-y-2.5">
          <Header headers={Object.values(klassHeader)} />
          <ConditionalRenderer condition={!isLoading}>
            <DataKlassTable titles={klassHeader} klassList={klassList} />
          </ConditionalRenderer>
        </table>

        <ConditionalRenderer condition={!klassList.length && !isLoading}>
          <Text
            text={hasParams ? t('searchNotFound') : t('noKlasses')}
            className="flex justify-center mt-5"
          />
        </ConditionalRenderer>
        <ConditionalRenderer condition={isLoading}>
          <div className="flex w-full h-[518px] justify-center items-center p-3.5">
            <LoadingIcon className="text-primary/40 w-10 h-10" />
          </div>
        </ConditionalRenderer>
      </div>
    </React.Fragment>
  );
}
