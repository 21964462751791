export class TreeNode<T extends { id: number }> {
  data: T;
  children: Map<number, TreeNode<T>>;

  constructor(data: T) {
    this.data = data;
    this.children = new Map<number, TreeNode<T>>();
  }
}

export class Tree<T extends { id: number }> {
  root: TreeNode<T> | null;
  nodes: { [key: string]: TreeNode<T> };

  constructor(root?: T) {
    if (!root) {
      this.root = null;
      this.nodes = {};
      return;
    }
    this.root = new TreeNode(root);
    this.nodes = {
      [root.id]: this.root,
    };
  }

  delete(elementId: number) {
    delete this.nodes[elementId];
    for (const nodeId in this.nodes) {
      const node = this.nodes[nodeId];
      if (node.children.has(elementId)) {
        node.children.delete(elementId);
      }
    }
  }

  update(element: T) {
    const node = this.nodes[element.id];
    if (!node) return;
    node.data = element;
  }

  insert(element: T, parentElementId?: number | null): void {
    if (this.nodes[element.id]) return;
    const newNode = new TreeNode(element);

    this.nodes[element.id] = newNode;

    if (parentElementId === null || parentElementId === undefined)
      this.root = newNode;
    else {
      const parentNode = this.nodes[parentElementId];
      if (parentNode) {
        parentNode.children.set(newNode.data.id, newNode);
      } else {
        throw new Error(`The parent node'${parentElementId}' not exists.`);
      }
    }
  }

  private postOrderTraversal(node: TreeNode<T> | null, result: T[]): void {
    if (node) {
      for (const child of node.children.values()) {
        this.postOrderTraversal(child, result);
      }
      result.push(node.data);
    }
  }

  postOrder(startNodeId?: number): T[] {
    const result: T[] = [];
    const startNode = startNodeId ? this.nodes[startNodeId] : this.root;
    this.postOrderTraversal(startNode, result);
    return result;
  }

  private findPathHelper(
    node: TreeNode<T> | null,
    targetId: number,
    path: T[],
  ): boolean {
    if (!node) return false;

    path.push(node.data);

    if (node.data.id === targetId) return true;

    for (const child of node.children.values()) {
      if (this.findPathHelper(child, targetId, path)) return true;
    }

    path.pop();
    return false;
  }

  findPath(targetId: number): T[] | null {
    const path: T[] = [];
    if (this.findPathHelper(this.root, targetId, path)) {
      return path;
    } else {
      return null;
    }
  }
}
