import { PaperAirplaneIcon } from '@heroicons/react/solid';
import { useState } from 'react';
import { ImageFolderEnum } from '../../models/CkEditor';
import MainButton from '../common/buttons/MainButton';
import MyCkeditor from '../editor/MyCkeditor';
import { useTranslation } from 'react-i18next';

export default function CommentBox({
  onSubmit,
  isSubmitting,
  testId,
}: {
  onSubmit: (content: string) => void;
  isSubmitting?: boolean;
  testId?: string;
}) {
  const [content, setContent] = useState('');
  const { t } = useTranslation();

  function handleSubmit() {
    onSubmit(content);
    setContent('');
  }
  return (
    <div
      data-testid={`commentBox${testId}`}
      className="flex flex-col gap-2 max-w-[720px]"
    >
      <MyCkeditor
        content={content}
        folder={ImageFolderEnum.BIO}
        handleContentChange={setContent}
      />
      <MainButton
        dataTestId={`submitButton${testId}`}
        loading={isSubmitting}
        disabled={!content}
        onClick={handleSubmit}
        icon={<PaperAirplaneIcon className="rotate-45 pr-0.5" />}
        text={t('teacherComments.submit')}
      />
    </div>
  );
}
