import {
  GetNextPageParamFunction,
  GetPreviousPageParamFunction,
} from '@tanstack/react-query';
import JsonApiResponse from '../models/JsonApiResponse';

export const getNextPageParam: GetNextPageParamFunction<
  JsonApiResponse<unknown>
> = pageParam => {
  if (pageParam && pageParam.meta) {
    const nextPage = pageParam.meta.pagination.page + 1;
    return nextPage <= pageParam.meta.pagination.pages ? nextPage : undefined;
  }
};

export const getPreviousPageParam: GetPreviousPageParamFunction<
  JsonApiResponse<unknown>
> = pageParam => {
  if (pageParam && pageParam.meta) {
    const previous = pageParam.meta.pagination.page - 1;
    return previous >= 1 ? previous : undefined;
  }
};
