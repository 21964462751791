import { FilterFields } from '@/data/context/BankFiltersContext';
import Activity, { ActivityMeta } from '@/models/Activity';
import { Lesson, LessonMeta } from '@/models/Lesson';
import { bankTypes } from '@/utils/modalBankUtils';
import { UseInfiniteQueryResult } from '@tanstack/react-query';
import { compact, flattenDeep, zip } from 'lodash';
import { Item } from './CardsFactory/CardsFactory';
import { useBuildFilters } from './FilterBank.hooks';
import useAuth from '@/data/hook/useAuth';
import { isSuperAdmin } from '@/functions/auth';
import useInfiniteService from '@/data/hook/useInfiniteService';
import {
  activitiesMetaQueryKeys,
  activitiesQueryKeys,
  lessonsMetaQueryKeys,
  lessonsQueryKeys,
} from '@/data/services/querykeys';

type UseInfinityProperties =
  | 'isInitialLoading'
  | 'isFetching'
  | 'isFetchingNextPage'
  | 'hasNextPage';

type UseItemsResult<Item> = Pick<
  UseInfiniteQueryResult<Item>,
  UseInfinityProperties
> & {
  results: Item[];
  invalidate: () => Promise<void>;
  fetchNextPage?: () => void;
};

type InputModels = Lesson | LessonMeta | Activity | ActivityMeta | Item;

type MergeResultsFn = (...results: InputModels[][]) => InputModels[];

const alternateResults: MergeResultsFn = (...results: InputModels[][]) => {
  return compact(flattenDeep(zip(...results)));
};

const joinById: MergeResultsFn = (...results: InputModels[][]) => {
  const map = new Map<number, InputModels>();
  results.forEach(result => {
    result.forEach(item => {
      map.set(item.id, {
        ...map.get(item.id),
        ...item,
      });
    });
  });
  return Array.from(map.values());
};

const mergeQueries = (
  queries: UseItemsResult<InputModels>[],
  mergeResultsFn: MergeResultsFn,
) => {
  const results = queries.map(query => query.results);
  const isInitialLoading = queries.some(query => query.isInitialLoading);
  const isFetching = queries.some(query => query.isFetching);
  const isFetchingNextPage = queries.some(query => query.isFetchingNextPage);
  const hasNextPage = queries.some(query => query.hasNextPage);
  const fetchNextPage = () => {
    if (isFetching) return;
    queries.forEach(query => query.fetchNextPage?.());
  };
  const invalidate = async () => {
    await Promise.all(queries.map(query => query.invalidate()));
  };
  return {
    results: mergeResultsFn(...results),
    isInitialLoading,
    isFetching,
    isFetchingNextPage,
    hasNextPage,
    fetchNextPage,
    invalidate,
  } as UseItemsResult<Item>;
};

export const useItems = (
  mode: bankTypes,
  filters: FilterFields,
  options?: {
    enabled?: boolean;
  },
): UseItemsResult<Item> => {
  const serviceFilters = useBuildFilters(filters);
  function enableQuery(queryMode: bankTypes) {
    return queryMode === mode || mode === 'all';
  }

  const lessonFilters = getItemFilters('lessons');
  const activitiesFilters = getItemFilters('activities');
  const defaultQueryOptions = {
    keepPreviousData: true,
    ...options,
  };
  const lessonQueryOptions = {
    ...defaultQueryOptions,
    enabled: enableQuery('lessons'),
  };
  const activitiesOptions = {
    ...defaultQueryOptions,
    enabled: enableQuery('activities'),
  };

  function getItemFilters(queryMode: Exclude<bankTypes, 'all' | 'questions'>) {
    const ordering = {
      activities: 'scheduledlesson_count',
      lessons: 'klass_count',
    };
    return {
      ...serviceFilters,
      ordering: ordering[queryMode],
    };
  }

  const lessonsQuery = useInfiniteService({
    ...lessonsQueryKeys.list(lessonFilters)._ctx.infinity,
    ...lessonQueryOptions,
  });

  const metadataLessonsQuery = useInfiniteService({
    ...lessonsMetaQueryKeys.list(lessonFilters)._ctx.infinity,
    ...lessonQueryOptions,
  });

  const activitiesQuery = useInfiniteService({
    ...activitiesQueryKeys.list(activitiesFilters)._ctx.infinity,
    ...activitiesOptions,
  });

  const metadataActivitiesQuery = useInfiniteService({
    ...activitiesMetaQueryKeys.list(activitiesFilters)._ctx.infinity,
    ...activitiesOptions,
  });

  const lessonsAndMetadata = mergeQueries(
    [lessonsQuery, metadataLessonsQuery],
    joinById,
  );

  const activitiesAndMetadata = mergeQueries(
    [activitiesQuery, metadataActivitiesQuery],
    joinById,
  );

  const allItems = mergeQueries(
    [lessonsAndMetadata, activitiesAndMetadata],
    alternateResults,
  );

  const items: Record<Exclude<bankTypes, 'questions'>, UseItemsResult<Item>> = {
    all: allItems,
    lessons: lessonsAndMetadata,
    activities: activitiesAndMetadata,
  };

  return items[mode === 'questions' ? 'all' : mode];
};

export const useShouldShowItem = (item: Item) => {
  const { user } = useAuth();

  const shouldRender = () => {
    if (!item.isPublicInBank)
      return item.author === user?.id || isSuperAdmin(user?.userType);
    return true;
  };

  return shouldRender();
};
