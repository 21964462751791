import React, { useState } from 'react';
import Klass from '@/models/Klass';
import ScheduledLesson from '@/models/ScheduledLesson';
import AnticipatePostponeKlass from './AnticipatePostponeKlass';
import UpdateDateKlass from './UpdateDateKlass';
import UpdateKlassLink from './UpdateKlassLink';
import UpdateTeacher from './UpdateTeacher';
import User from '@/models/User';
import { formatLessonName } from '@/functions/lessonsName';

export enum ModalState {
  CLOSED = 'CLOSED',
  KLASS_LINK = 'KLASS_LINK',
  TEACHER = 'TEACHER',
  POSTPONE = 'POSTPONE',
  ANTICIPATE = 'ANTICIPATE',
  KLASS_DATE = 'KLASS_DATE',
}

interface ModalHandlerProps {
  modalState: ModalState;
  scheduledLesson: ScheduledLesson;
  klassInfo: Klass;
  teacherInfo?: User;
  updateLessons?: (a: any) => Promise<void>;
  closeModal: () => void;
  redirectToModal?(modal: ModalState): void;
}

export default function ModalHandler(props: ModalHandlerProps) {
  const {
    klassInfo,
    modalState,
    scheduledLesson,
    teacherInfo,
    closeModal,
    updateLessons,
    redirectToModal,
  } = props;
  type ModalHistoricItem = {
    from: ModalState;
    to: ModalState;
  };
  const [modalHistoric, setModalHistoric] = useState<ModalHistoricItem[]>([]);

  const redirect = (to: ModalState) => {
    setModalHistoric(current => [...current, { from: modalState, to }]);
    redirectToModal?.(to);
  };

  const onBack = () => {
    const lastHistoric = modalHistoric.at(-1);
    if (lastHistoric) {
      setModalHistoric(current =>
        current.filter((_, i) => i !== current.length - 1),
      );
      redirectToModal?.(lastHistoric.from);
    }
  };

  const onClose = () => {
    closeModal();
    setModalHistoric([]);
  };

  const hasHistoric = modalHistoric.length > 0;

  const back = hasHistoric ? onBack : undefined;

  switch (modalState) {
    case ModalState.KLASS_LINK:
      return (
        <UpdateKlassLink
          updateScheduledLessons={updateLessons}
          klassLink={scheduledLesson.url || klassInfo.classRoomUrl}
          klassId={klassInfo.id}
          scheduledLesson={scheduledLesson.id}
          onClickCancel={onClose}
        />
      );
    case ModalState.TEACHER:
      return (
        <UpdateTeacher
          onClickCancel={onClose}
          updateScheduledLessons={updateLessons}
          teacher={teacherInfo}
          lesson={formatLessonName(scheduledLesson.lesson, scheduledLesson)}
          scheduledId={scheduledLesson.id}
          date={scheduledLesson.datetime}
        />
      );
    case ModalState.POSTPONE:
    case ModalState.ANTICIPATE:
      return (
        <AnticipatePostponeKlass
          onBack={back}
          state={modalState}
          updateScheduledLessons={updateLessons}
          onClickCancel={onClose}
          duration={klassInfo.duration}
          lesson={formatLessonName(scheduledLesson.lesson, scheduledLesson)}
          scheduledId={scheduledLesson.id}
        />
      );
    case ModalState.KLASS_DATE:
      return (
        <UpdateDateKlass
          scheduledLesson={scheduledLesson}
          onClickCancel={onClose}
          unitId={klassInfo.unitId}
          updateScheduled={updateLessons}
          redirectToModal={redirect}
          duration={klassInfo.duration}
        />
      );
    default:
      return <React.Fragment />;
  }
}
