import requester from '@/axios';
import ScheduledLesson, {
  LessonTimeframeEnum,
  RescheduleTypeEnum,
  ScheduledLessonTypeEnum,
} from '@/models/ScheduledLesson';
import JsonApiResponse from '@/models/JsonApiResponse';
import { ListServiceFunc, BaseFilters } from '@/models/Service';
import { VersioningStatusEnum } from '@/enums/VersioningStatus';

export interface ScheduledLessonFilters extends BaseFilters {
  isActive?: boolean;
  hasDone?: boolean;
  isPendingReport?: boolean;
  date?: string;
  teacher?: number;
  lesson?: number;
  ordering?: ScheduledLessonOrdering;
  klassId?: number;
  dateRangeAfter?: string;
  dateRangeBefore?: string;
  name?: string;
  reportIsNull?: boolean;
  order?: number;
  unit?: number[];
  status?: VersioningStatusEnum[];
  orderGt?: number;
  orderLt?: number;
  lessonTimeframe?: LessonTimeframeEnum[];
}

export type ScheduledLessonOrdering = 'datetime' | '-datetime';

export const listScheduledLessons: ListServiceFunc<
  ScheduledLesson,
  ScheduledLessonFilters
> = async params => {
  const { data } = await requester().get<JsonApiResponse<ScheduledLesson>>(
    `scheduled-lessons/`,
    {
      params,
    },
  );
  return data;
};

export type UpdateScheduledLessonBody = Partial<
  Omit<ScheduledLesson, 'lesson'>
> & {
  lessonId?: number;
};

export const updateScheduledLesson = async (
  scheduledLessonId: number,
  changes: UpdateScheduledLessonBody,
) => {
  const { data: scheduledLesson } = await requester().patch<ScheduledLesson>(
    `scheduled-lessons/${scheduledLessonId}/`,
    changes,
  );

  return scheduledLesson;
};

export const getScheduledLesson = async (scheduledLessonId: number) => {
  const { data } = await requester().get<ScheduledLesson>(
    `scheduled-lessons/${scheduledLessonId}/`,
  );

  return data;
};

export type CreateExtraLessonParam = {
  klassId: number;
  scheduledLessonId: number;
};

export type CreateExtraScheduledLessonBody = Pick<ScheduledLesson, 'type'>;

export const createExtraScheduledLesson = async (
  { klassId, scheduledLessonId }: CreateExtraLessonParam,
  body: CreateExtraScheduledLessonBody = {
    type: ScheduledLessonTypeEnum.FREE,
  },
) => {
  const { data: scheduledLesson } = await requester().post<ScheduledLesson>(
    `classes/${klassId}/scheduled-lessons/${scheduledLessonId}/extra-lesson/`,
    body,
  );
  return scheduledLesson;
};

interface DeleteScheduledLessonParams {
  klassId: number;
  scheduledLessonId: number;
}

export const deleteScheduledLesson = async ({
  klassId,
  scheduledLessonId,
}: DeleteScheduledLessonParams) => {
  await requester().delete(
    `classes/${klassId}/scheduled-lessons/${scheduledLessonId}/`,
  );
};

export const activateOrDeactivateScheduledLesson = async ({
  scheduledLessonId,
  klassId,
  isActive,
}: {
  scheduledLessonId: number;
  klassId: number;
  isActive: boolean;
}) => {
  const { data } = await requester().patch<ScheduledLesson>(
    `classes/${klassId}/scheduled-lessons/${scheduledLessonId}/activate-or-deactivate/`,
    {
      isActive,
    },
  );
  return data;
};

export async function getNextLesson(klassId: number) {
  const { data } = await requester().get<ScheduledLesson>(
    `classes/${klassId}/next-scheduled-lesson/`,
  );

  return data;
}

export const updateRescheduledLesson = async (
  scheduledId: number,
  rescheduleType: RescheduleTypeEnum,
) => {
  await requester().patch(
    `scheduled-lessons/${scheduledId}/reschedule-lessons/`,
    {
      rescheduleType,
    },
  );
};

export interface AnticipatePostponeKlassPreview {
  datetime: string;
}

export type ReschedulePreviewOptions = {
  rescheduleType: RescheduleTypeEnum;
};

export const getRescheduledLessonsPreview = async (
  scheduledId: number,
  options: ReschedulePreviewOptions,
) => {
  const { data } = await requester().get<AnticipatePostponeKlassPreview>(
    `scheduled-lessons/${scheduledId}/reschedule-lesson-preview/`,
    {
      params: options,
    },
  );
  return data;
};
