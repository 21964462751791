import { PropsWithChildren, ReactNode } from 'react';
import { twMerge } from 'tailwind-merge';
import { DotsVerticalIcon, EyeOffIcon } from '@heroicons/react/outline';
import { useTranslation } from 'react-i18next';

import { ClassNameComponent } from '@/utils/ClassNameComponent';
import Text from './dataDisplay/Text';
import Popup from './Popup';
import SimpleTextIcon, { SimpleTextIconProps } from './SimpleTextIcon';

import ConditionalRenderer from './ConditionalRenderer';
import {
  HiddenComponentsSettings,
  HiddenComponentType,
} from '@/models/HiddenSectionSettings';
import useHiddenComponents from '@/data/hook/useHiddenComponent';

export type HideContainerProps = Partial<PropsWithChildren> &
  Partial<Omit<SimpleTextIconProps, 'title' | 'className'>> & {
    title?: string;
    type: HiddenComponentType;
    render?: boolean;
    className?: ClassNameComponent;
    updateRender?: (updates: HiddenComponentsSettings) => void;
    header?: ReactNode;
    setRef?: (ref: HTMLElement) => void;
  };

export default function HideContainer({
  className,
  render = true,
  children,
  ...rest
}: HideContainerProps) {
  return (
    <ConditionalRenderer condition={render}>
      <section
        ref={rest.setRef}
        data-testid={rest.type}
        className={twMerge(
          'rounded-xl p-2 border-neutral-content shadow-default',
          className?.section,
          'transition-all ease-in-out duration-300 border bg-base-100',
        )}
      >
        <SectionHeader
          {...rest}
          className={className?.header}
          render={render}
        />
        {children}
      </section>
    </ConditionalRenderer>
  );
}

type SectionHeaderProps = Pick<
  HideContainerProps,
  'title' | 'updateRender' | 'type'
> &
  Partial<
    Pick<HideContainerProps, 'icon' | 'render' | 'count' | 'header' | 'tooltip'>
  > & {
    className?: string;
  };

function SectionHeader({
  title,
  icon,
  className,
  render,
  updateRender,
  type,
  header,
  ...rest
}: SectionHeaderProps) {
  const { components } = useHiddenComponents();

  const component = components[type];

  return (
    <header
      className={twMerge(
        'py-2 px-4 flex justify-between items-center text-accent w-full relative',
        className,
      )}
    >
      <SimpleTextIcon
        {...rest}
        icon={icon ?? component.icon}
        title={title ?? component.title}
      />

      {header}

      <SectionOptions render={render} updateRender={updateRender} type={type} />
    </header>
  );
}

type SectionOptionsProps = Pick<HideContainerProps, 'updateRender' | 'type'> &
  Partial<Pick<HideContainerProps, 'render'>>;

function SectionOptions({ render, updateRender, type }: SectionOptionsProps) {
  const { t } = useTranslation('translation', {
    keyPrefix: 'hideComponent',
  });

  type SectionOption = {
    id: string;
    icon: ReactNode;
    title: string;
    onClick: () => void;
  };

  const hiddenOption: SectionOption = {
    id: 'hidden',
    icon: <EyeOffIcon className="w-4" />,
    title: t('options.hiddenSection'),
    onClick: () => updateRender?.({ [type]: !render }),
  };

  let options: SectionOption[] = [];

  options = updateRender ? options.concat(hiddenOption) : options;

  return (
    <ConditionalRenderer condition={options.length}>
      <Popup
        className={{ reference: 'absolute right-0', popup: 'z-10' }}
        reference={
          <DotsVerticalIcon data-testid="hideSectionPopup" className="h-6" />
        }
        hover
      >
        <ul className="bg-base-100 rounded-xl p-2.5 border border-neutral-content text-base-content w-52">
          {options.map(option => (
            <li
              key={option.id}
              data-testid={option.id}
              onClick={option.onClick}
              className="flex gap-3.5 items-center cursor-pointer hover:text-accent transition ease-in-out duration-300 select-none bg-base-100"
            >
              {option.icon}
              <Text
                text={option.title}
                format="rubik-400"
                className="text-16"
              />
            </li>
          ))}
        </ul>
      </Popup>
    </ConditionalRenderer>
  );
}
