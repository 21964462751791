import ErrorDispatcher from '@/errors/ErrorDispatcher';
import { captureException } from '@sentry/react';

export class ErrorCapturer {
  private dispatcher: ErrorDispatcher;

  constructor(dispatcher: ErrorDispatcher) {
    this.dispatcher = dispatcher;
  }

  dispatchError() {
    captureException(this.dispatcher.getError());
  }
}
