import React from 'react';
import { BaseInputProps } from './BaseInput';
import TextInput from './TextInput';
import useToggle from '@/data/hook/useToggle';
import Text from '../dataDisplay/Text';
import { LoadingIcon } from '../../icons';
import { useTranslation } from 'react-i18next';
import { SearchIcon, XIcon } from '@heroicons/react/outline';
import { AnimatePresence, motion } from 'framer-motion';
import Skeleton from '../Skeleton';
import { twMerge } from 'tailwind-merge';
import { Tooltip } from '../dataDisplay/Tooltip';
import ConditionalWrapper from '../ConditionalWrapper';
import { buildMotionLIProps } from '@/utils/animations/lessonCardAnimation';
import InfinityList from '../InfinityList';

type keyType = string | number;

export type CustomInputProps = Omit<
  BaseInputProps,
  'onChange' | 'value' | 'register'
> & {
  ref?: React.Ref<HTMLInputElement>;
};
export interface SearchOption<T> {
  key: keyType;
  value: T;
  isDisabled?: boolean;
  tooltipMessage?: string;
}

export type SearchInputProps<T> = BaseInputProps & {
  options: SearchOption<T>[];
  input?: CustomInputProps;
  onSearch?: React.ChangeEventHandler<HTMLInputElement>;
  onSelect(option: T): void;
  displayName: (option: T) => string;
  onDeselect?(): void;
  value: string;
  isSearching?: boolean;
  isLoading?: boolean;
  hasItemSelected?: boolean;
  hasNextPage?: boolean;
  isFetchingNextPage?: boolean;
  onReachEnd?(): unknown | Promise<unknown>;
  inputIcon?: JSX.Element;
};

function SearchInput<T>({
  displayName,
  input,
  onSearch,
  onSelect,
  options,
  value,
  isLoading,
  isSearching,
  onDeselect,
  hasItemSelected,
  hasNextPage,
  isFetchingNextPage,
  onReachEnd,
  inputIcon,
}: SearchInputProps<T>) {
  const { close, open, toggle, isOpen } = useToggle();

  const selectOption = (option: T) => {
    close();
    onSelect(option);
  };

  const icon =
    hasItemSelected && onDeselect ? (
      <XIcon
        data-testid="deselectIcon"
        className="text-error cursor-pointer"
        onClick={onDeselect}
      />
    ) : (
      <span onClick={toggle} className="cursor-pointer">
        {inputIcon ?? <SearchIcon />}
      </span>
    );

  const onBlur: React.FocusEventHandler<HTMLInputElement> = e => {
    const relatedTarget = e.relatedTarget;
    if (!relatedTarget?.getAttribute('data-ignore-blur')) {
      close();
    }
  };
  if (isLoading) {
    return (
      <Skeleton
        testId="loadingInput"
        className={twMerge(
          'w-full h-8 rounded-md bg-primary-content',
          input?.className?.base ?? 'max-w-sm',
        )}
      />
    );
  }

  return (
    <div className={twMerge('flex flex-col relative', input?.className?.base)}>
      <TextInput
        {...input}
        onBlur={onBlur}
        icon={input?.disabled ? undefined : icon}
        onFocus={open}
        onChange={e => {
          open();
          onSearch?.(e);
        }}
        value={value}
      />

      <div className="flex flex-col relative w-full z-30">
        <AnimatePresence>
          {isOpen && (
            <motion.div
              className="absolute w-full overflow-hidden"
              initial={{
                height: 0,
                opacity: 0.75,
                x: -20,
                zIndex: 200,
              }}
              animate={{
                height: 'auto',
                opacity: 1,
                x: 0,
                y: 0,
              }}
              exit={{
                height: 0,
              }}
            >
              <Options
                displayName={displayName}
                onSelect={selectOption}
                options={options}
                isSearching={isSearching}
                hasNextPage={hasNextPage}
                isFetchingNextPage={isFetchingNextPage}
                onReachEnd={onReachEnd}
              />
            </motion.div>
          )}
        </AnimatePresence>
      </div>
    </div>
  );
}

interface OptionsProps<T> {
  options: SearchOption<T>[];
  onSelect(option: T): void;
  displayName(option: T): string;
  isSearching?: boolean;
  hasNextPage?: boolean;
  isFetchingNextPage?: boolean;
  onReachEnd?(): unknown | Promise<unknown>;
}

const Options = <T,>({
  displayName,
  onSelect,
  options,
  isSearching,
  hasNextPage,
  isFetchingNextPage,
  onReachEnd,
}: OptionsProps<T>) => {
  const mainClassNameList = 'flex flex-col relative w-full z-30';
  const { t } = useTranslation('translation', {
    keyPrefix: 'klassInput',
  });

  if (isSearching) {
    return (
      <div className={mainClassNameList} data-testid="loadingOptions">
        <div
          className="flex flex-col w-full bg-base-100 shadow-default 
          rounded-t-none rounded-md gap-2 p-10 h-auto max-h-44 text-primary-content"
        >
          <LoadingIcon />
        </div>
      </div>
    );
  }

  if (options.length) {
    return (
      <InfinityList
        className={`${mainClassNameList} py-4 overflow-x-hidden max-h-[10rem] bg-base-100 rounded-t-none rounded-md`}
        hasNextPage={hasNextPage}
        isFetchingNextPage={isFetchingNextPage}
        onReachEnd={onReachEnd}
        scroll
      >
        {options.map(({ key, value, isDisabled, tooltipMessage }) => {
          const motionProps = buildMotionLIProps(1);
          const disableClassName = isDisabled
            ? 'disabled pointer-events-none text-neutral hover:text-neutral/50 bg-neutral/20'
            : 'cursor-pointer hover:text-primary hover:bg-primary/10';
          return (
            <ConditionalWrapper
              condition={Boolean(isDisabled)}
              wrapper={
                <Tooltip
                  placement="auto"
                  className="text-primary"
                  classNameContainer="w-full"
                  color="lightBlue"
                  text={tooltipMessage}
                />
              }
              key={key}
            >
              <motion.li
                whileHover={{ scale: isDisabled ? undefined : 1.03 }}
                whileTap={{ scale: 0.95 }}
                style={{ scale: 1 }}
                role="option"
                aria-selected
                className={`p-0 mx-4 ${isDisabled ? 'w-full' : ''}`}
                {...motionProps}
              >
                <button
                  type="button"
                  data-testid={`option-${key}`}
                  className={`text-left w-full p-2 rounded-md font-500
                    transition ease-in-out duration-150 text-14 ${disableClassName}`}
                  data-ignore-blur="true"
                  onClick={() => onSelect(value)}
                >
                  <Text text={displayName(value)} />
                </button>
              </motion.li>
            </ConditionalWrapper>
          );
        })}
      </InfinityList>
    );
  }

  return (
    <div className={mainClassNameList} data-testid="noResults">
      <div
        className="flex flex-col w-full bg-base-100 shadow-default 
        rounded-t-none rounded-md gap-2 p-4 text-primary text-center"
      >
        <Text
          text={`${t('warnings.noResults')} ༼ つ ◕_◕ ༽つ`}
          className="pt-2"
        />
      </div>
    </div>
  );
};

export default SearchInput;
