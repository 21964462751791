import { Link } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';

import { REQUEST_STALE_TIME_IN_MS } from '@/constants';
import { nextScheduledLessonsQueryKeys } from '@/data/services/querykeys';
import { formatLessonName } from '@/functions/lessonsName';
import { ROUTES } from '@/utils/routes';
import Text from '../common/dataDisplay/Text';
import Skeleton from '../common/Skeleton';
import { Tooltip } from '../common/dataDisplay/Tooltip';
import { getErrorMessage } from '@/utils/getErrorMessage';

export type NextLessonKlassProps = {
  courseSlug: string;
  klassId?: number;
};

export default function NextLessonKlass({
  courseSlug,
  klassId,
}: NextLessonKlassProps) {
  const { t } = useTranslation('translation', {
    keyPrefix: 'nextLessonKlass',
  });

  const {
    data: nextLesson,
    isInitialLoading: loading,
    error,
  } = useQuery({
    ...nextScheduledLessonsQueryKeys.get(klassId ?? 0),
    enabled: !!klassId,
    retry: false,
    staleTime: REQUEST_STALE_TIME_IN_MS,
  });

  if (loading)
    return (
      <Skeleton
        testId="loading"
        className="h-3.5 rounded-xl bg-primary-content"
      />
    );

  if (error)
    return (
      <Text
        format="rubik-400"
        text={getErrorMessage(error)}
        className="select-none text-base-content text-14 hyphens-auto truncate"
      />
    );

  if (nextLesson) {
    const lessonName = formatLessonName(nextLesson.lesson, nextLesson);

    const to = ROUTES.COURSES.LESSON.INFO(
      courseSlug,
      nextLesson.lesson.id,
      klassId,
    );

    return (
      <Link
        to={to}
        data-testid="nextKlass"
        className="flex gap-4 items-center w-full overflow-x-hidden"
      >
        <Tooltip classNameContainer="overflow-x-hidden flex" text={lessonName}>
          <Text format="rubik-500" text={lessonName} className="truncate" />
        </Tooltip>
      </Link>
    );
  }

  return (
    <Text
      format="rubik-400"
      text={t('warning')}
      className="select-none text-base-content text-14 hyphens-auto"
    />
  );
}
