import {
  Fragment,
  PropsWithChildren,
  ReactNode,
  useEffect,
  useState,
} from 'react';
import { AnimatePresence, motion } from 'framer-motion';
import { usePopper } from 'react-popper';
import { twMerge } from 'tailwind-merge';

import { ClassNameComponent } from '@/utils/ClassNameComponent';
import useToggle from '@/data/hook/useToggle';
import ConditionalRenderer from './ConditionalRenderer';
import { Placement } from '@/models/Placement';
import { dropdown } from '@/utils/animations/commom';

type RefElement = HTMLDivElement | null;

type PopupProps = PropsWithChildren & {
  testId?: string;
  placement?: Placement;
  reference: ReactNode;
  className?: ClassNameComponent;
  hover?: boolean;
  disabled?: boolean;
  offset?: [number, number];
  opened?: boolean;
  onToggle?(): void;
  onOpen?(): void;
  onClose?(): void;
};

export default function Popup({
  testId,
  placement = 'auto',
  reference,
  children: popup,
  className,
  hover,
  disabled,
  offset,
  opened,
  onToggle,
  onOpen,
  onClose,
}: PopupProps) {
  const { isOpen, close, open, toggle } = useToggle();

  const onMouseEnter = hover && !disabled ? onOpen ?? open : undefined;
  const onMouseLeave = hover && !disabled ? onClose ?? close : undefined;

  const [referenceElement, setReferenceElement] = useState<RefElement>(null);
  const [popperElement, setPopperElement] = useState<RefElement>(null);

  const { styles, attributes, update } = usePopper(
    referenceElement,
    popperElement,
    {
      placement,
      modifiers: [
        {
          name: 'offset',
          options: {
            offset,
          },
        },
        {
          name: 'preventOverflow',
          options: {
            padding: 20,
          },
        },
      ],
    },
  );

  const condition = opened || isOpen;

  useEffect(() => {
    if (condition && update) update();
  }, [condition, update]);

  return (
    <Fragment>
      <div
        data-testid={testId ?? 'popup'}
        style={styles.reference}
        onClick={onToggle ?? toggle}
        ref={setReferenceElement}
        className={className?.reference}
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
      >
        {reference}
      </div>

      <ConditionalRenderer condition={condition && !hover}>
        <div
          onClick={onClose ?? close}
          className="fixed inset-0 z-40"
          onMouseEnter={onMouseLeave}
        />
      </ConditionalRenderer>

      <div
        ref={setPopperElement}
        style={styles.popper}
        {...attributes.popper}
        className={twMerge('z-50', className?.popup)}
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
      >
        <AnimatePresence>
          {condition && <motion.div {...dropdown}>{popup}</motion.div>}
        </AnimatePresence>
      </div>
    </Fragment>
  );
}
