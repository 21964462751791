import { ReactNode } from 'react';

interface HeaderCellProps
  extends React.ThHTMLAttributes<HTMLTableHeaderCellElement> {
  children: ReactNode;
  onClick?: React.MouseEventHandler<HTMLTableHeaderCellElement>;
  className?: string;
  size?: number;
}

export default function Row(props: HeaderCellProps) {
  const { children, onClick, className, size, ...rest } = props;

  return (
    <th
      {...rest}
      onClick={onClick}
      className={`flex items-center ${className || ''}`}
      style={{ width: `${size || 25}%` }}
    >
      {children}
    </th>
  );
}
