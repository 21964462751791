import ElementEditFactory from '@/components/activities/elements/edit/ElementEditFactory';
import BackButton from '@/components/common/BackButton';
import ConditionalRenderer from '@/components/common/ConditionalRenderer';
import useCourseEditing from '@/data/hook/useCourseEditing';
import { formatLessonName } from '@/functions/lessonsName';
import Activity, { ActivityTypeEnum } from '@/models/Activity';
import ActivityElement from '@/models/ActivityElement';
import ActivityElementFactory, {
  IdentifierValidatorDecorator,
} from '@/models/ActivityElementFactory';
import CoursePath from '@/models/Course';
import { Lesson } from '@/models/Lesson';
import { activityTypeOptions } from '@/utils/activityTypeOptions';
import deleteActivityByType from '@/utils/deleteActivityByType';
import {
  InformationCircleIcon,
  PlusIcon,
  SaveIcon,
} from '@heroicons/react/outline';
import { useQueryClient } from '@tanstack/react-query';
import { ElementType, Fragment, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import MainButton from '@/components/common/buttons/MainButton';
import Tabs, {
  Pane,
  serializerTabsContents,
} from '@/components/common/dataDisplay/Tabs/Tabs';
import Text from '@/components/common/dataDisplay/Text';
import ModalDisable from '@/components/common/modals/ModalDisable';
import { LessonBanner } from '@/components/lessons/LessonIcon';
import ActivityDetailsEdit from './ActivityDetailsEdit';
import ModalNewQuestion from './ModalNewQuestion';
import { activitiesQueryKeys } from '@/data/services/querykeys';

export interface ActivityTypeSelect {
  type: ActivityTypeEnum;
  onClick?: any;
  icon: ElementType;
}

interface EditActivitiesProps {
  lesson: Lesson;
  activity: Activity;
  backUrl?: string;
  course?: CoursePath;
  klassId?: number;
}

export default function EditActivity({
  activity,
  lesson,
  backUrl,
}: EditActivitiesProps) {
  const queryClient = useQueryClient();

  const { queryKey: activityKey } = activitiesQueryKeys.get(activity.id);

  const { t } = useTranslation('translation', {
    keyPrefix: 'activity.manageActivity',
  });

  const { t: tActivityTypes } = useTranslation('translation', {
    keyPrefix: 'activity.manageActivity.activityTypes',
  });
  const { t: editRulesT } = useTranslation('translation', {
    keyPrefix: 'adminPage.editCourseRules',
  });

  const deleteQuestion = useRef<ActivityElement>();

  const { allowBigChanges } = useCourseEditing();
  const [activityName, setActivityName] = useState<string>(activity.name);
  const [isVisibleModal, setIsVisibleModal] = useState<'add' | 'delete'>();
  const [questions, setQuestions] = useState<ActivityElement[]>(
    activity.questions,
  );
  const [activeTab, setActiveTab] = useState<string>('details');

  const [collapseTabs, setCollapseTabs] = useState<boolean>(false);

  const onChangeActivityName = (activityNameChanged: string) => {
    setActivityName(activityNameChanged);
  };

  const onDeleteModal = (question: ActivityElement) => {
    setIsVisibleModal('delete');
    deleteQuestion.current = question;
  };

  const onConfirmDelete = () => {
    deleteQuestion.current && removeQuestion(deleteQuestion.current);
    setIsVisibleModal(undefined);
  };

  const addQuestion = (activityType: ActivityTypeSelect) => {
    const activityFactory = new IdentifierValidatorDecorator(
      new ActivityElementFactory(),
      questions,
    );
    const newQuestion = activityFactory.create(activityType.type);
    newQuestion.order = questions.length + 1;
    questions.push(newQuestion);
    setQuestions(questions);
    setActiveTab(newQuestion.identifier);
  };

  const removeQuestion = async (question: ActivityElement) => {
    let index = questions.findIndex(q => q.identifier === question.identifier);
    const questionsFiltered = questions.filter(
      item => item.identifier !== question.identifier,
    );
    if (question.changeStatus !== 'new') {
      await deleteActivityByType(question);
      queryClient.removeQueries(activityKey);
    }

    if (index === questionsFiltered.length) {
      index--;
    }
    if (question.identifier === activeTab) {
      setActiveTab(questionsFiltered.at(index)?.identifier || 'details');
    }

    setQuestions(questionsFiltered);
  };

  const updateQuestion = (question: Partial<ActivityElement>) => {
    const questionsUpdated = questions.map(item =>
      item.identifier === question.identifier ? { ...item, ...question } : item,
    );
    return setQuestions(questionsUpdated);
  };

  const changeQuestion = (question: Partial<ActivityElement>) => {
    setCollapseTabs(true);
    updateQuestion({
      ...question,
      changeStatus: question.changeStatus === 'new' ? 'new' : 'changed',
    });
  };

  const saveQuestion = (question: ActivityElement) => {
    updateQuestion({ ...question, changeStatus: 'edit' });
  };

  const onEdit = (question: Partial<ActivityElement>) =>
    changeQuestion(question);

  const onSave = (question: ActivityElement) => {
    saveQuestion(question);
    queryClient.invalidateQueries(activityKey);
  };

  const questionHasPendingChanges = (identifier: string) => {
    return questions.some(
      question =>
        question.identifier === identifier &&
        ['changed', 'new'].includes(question.changeStatus as string),
    );
  };

  const questionsToPanel = (): Pane[] => {
    const panes = questions.map(element => {
      const activityIcon = activityTypeOptions.find(
        item => item.type === element.type,
      );

      const pendingChanges = questionHasPendingChanges(element.identifier);

      return {
        text: tActivityTypes(`${activityIcon?.type}`),
        icon: activityIcon?.icon || InformationCircleIcon,
        secondaryIcon: pendingChanges && (
          <SaveIcon className="w-4 text-warning" />
        ),
        content: (
          <ElementEditFactory
            key={element.identifier}
            onEdit={onEdit}
            activity={activity}
            question={element}
            onSave={onSave}
          />
        ),
        key: element.identifier,
        onClose: allowBigChanges ? () => onDeleteModal(element) : undefined,
      };
    });
    return panes;
  };

  const detailsPanes: Pane = {
    text: t('detailsLabel'),
    icon: InformationCircleIcon,
    content: (
      <div className="w-full">
        <ActivityDetailsEdit
          lesson={lesson}
          activity={activity}
          onChange={onChangeActivityName}
        />
      </div>
    ),
    key: 'details',
  };

  const panes = [detailsPanes, ...questionsToPanel()];

  useEffect(() => {
    const t = setTimeout(() => setCollapseTabs(true), 1500);
    return () => clearTimeout(t);
  }, []);

  return (
    <div className="relative flex flex-col bg-secondary-content rounded-lg items-stretch shadow-default shadow-secondary-content p-6 pl-4">
      <Header backUrl={backUrl} lesson={lesson} />

      <Tabs
        active={activeTab}
        contents={serializerTabsContents(panes)}
        onChange={setActiveTab}
        orientation="vertical"
      >
        <Tabs.Header
          title={activityName}
          subtitle={!allowBigChanges ? editRulesT('activityWarning') : ''}
        />
        <Tabs.Container>
          <Tabs.CollapsibleList
            collapsed={collapseTabs}
            maxWidth={!collapseTabs ? 205 : 0}
          >
            {collapse => (
              <Fragment>
                {panes.map(pane => (
                  <Tabs.TriggerContainer key={pane.key} id={pane.key}>
                    <Tabs.TriggerButton
                      id={pane.key}
                      className={collapse ? 'px-0' : 'justify-start'}
                    >
                      <pane.icon
                        className={`w-6 h-6 shrink-0 ${
                          collapse && questionHasPendingChanges(pane.key)
                            ? 'text-warning'
                            : ''
                        }`}
                      />
                      {!collapse && <Text text={pane.text} />}
                    </Tabs.TriggerButton>
                    {!collapse && (
                      <Tabs.IconsList
                        statusIcon={pane.secondaryIcon}
                        onClose={pane.onClose}
                      />
                    )}
                  </Tabs.TriggerContainer>
                ))}
                <MainButton
                  onClick={() => setIsVisibleModal('add')}
                  text={collapse ? '' : t('newQuestion')}
                  className="mx-auto my-2.5 whitespace-nowrap"
                  icon={<PlusIcon />}
                  dataTestId="addNewQuestion"
                  disabled={!allowBigChanges}
                />
              </Fragment>
            )}
          </Tabs.CollapsibleList>
          <Tabs.ShowContent />
        </Tabs.Container>
      </Tabs>
      <ModalDisable
        visible={isVisibleModal === 'delete'}
        selectedObjectName={tActivityTypes(deleteQuestion.current?.type || '')}
        translationString={'modalDisableActivity'}
        modalType="delete"
        onClickConfirm={onConfirmDelete}
        onClickCancel={() => setIsVisibleModal(undefined)}
      />
      <ModalNewQuestion
        visible={isVisibleModal === 'add'}
        onClose={() => setIsVisibleModal(undefined)}
        onClick={addQuestion}
      />
    </div>
  );
}

interface HeaderProps {
  lesson?: Lesson;
  backUrl?: string;
}

function Header({ lesson, backUrl }: HeaderProps) {
  return (
    <div className="relative flex flex-row gap-7 items-center pb-2.5 pl-2">
      <div className="avatar">
        <LessonBanner
          width="w-12"
          lessonBanner={lesson?.bannerImg}
          hasOverlay={false}
        />
      </div>
      <ConditionalRenderer condition={lesson}>
        {lesson && (
          <Text
            format="rubik-500"
            className="mr-auto text-secondary text-18"
            text={formatLessonName(lesson)}
          />
        )}
      </ConditionalRenderer>
      <BackButton
        url={backUrl}
        color="text-secondary"
        hoverColor="text-primary"
      />
    </div>
  );
}
