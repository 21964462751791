import { useQuery, useQueryClient } from '@tanstack/react-query';
import { ListUnitsFilters } from '@/data/services/unitServices';
import { AnimatePresence, motion } from 'framer-motion';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import ListUnits from '@/components/admin/units/ListUnits';
import UnitForm from '@/components/admin/units/UnitForm';
import AdminButton from '@/components/common/AdminButton';
import HeadTitle from '@/components/common/HeadTitle';
import TableSearchIcon from '@/components/common/TableSearchIcon';
import TableStatistics from '@/components/common/table/TableStatistics';
import Layout from '@/components/template/Layout';
import { MANAGEMENT_PAGE_SIZE } from '@/constants';
import { updateUnitsStatistics } from '@/data/services/statisticsServices';
import { UnitHeader } from '@/utils/HeaderTypes';
import useFilterParams from '@/utils/UseFilterParams';
import { accordion } from '@/utils/animations/commom';
import NoAdminScreenForMobile from './NoAdminScreenForMobile';
import PaginationComponent from './PaginationComponent';
import useStatisticService from '@/data/hook/useStatistics';
import {
  unitsQueryKeys,
  unitsStatisticsQueryKeys,
} from '@/data/services/querykeys';

export default function ManageUnit() {
  const navigate = useNavigate();
  const { isAdd } = useParams();
  const isAddRoute = isAdd === 'add';
  const [isAddUnit, setisAddUnit] = useState(isAddRoute);

  const toggleIsAddUnit = () => {
    if (isAddRoute) {
      navigate('..');
    }
    setisAddUnit(prev => !prev);
  };
  const [isSearch, setIsSearch] = useState(false);

  const { t } = useTranslation('translation', {
    keyPrefix: 'adminPage.manageUnit',
  });
  const { t: translateListUnit } = useTranslation('translation', {
    keyPrefix: 'adminPage.listUnits',
  });

  const queryClient = useQueryClient();

  const { filterParams, setFilterParams } = useFilterParams<ListUnitsFilters>(
    {},
  );

  const search = filterParams.get('search') ?? undefined;
  const pageSize = MANAGEMENT_PAGE_SIZE;
  const pageNumber = Number(filterParams.get('pageNumber') ?? '1');

  const params = {
    search,
    pageNumber,
    pageSize,
  };

  const { data, isInitialLoading, isPreviousData } = useQuery({
    keepPreviousData: true,
    ...unitsQueryKeys.list(params),
  });

  const { statistic, isInitialLoading: isLoadingUnitCount } =
    useStatisticService(unitsStatisticsQueryKeys.list()._ctx.totalSum);

  const units = data?.results ?? [];

  const updateStatistics = async () => {
    await updateUnitsStatistics();
    const queryKey = unitsStatisticsQueryKeys.list._def;
    return queryClient.invalidateQueries(queryKey);
  };

  const titles: UnitHeader = {
    unit: {
      title: translateListUnit('units'),
      extraFunction: [
        <TableSearchIcon onClick={() => setIsSearch(!isSearch)} />,
      ],
      size: 16,
    },
    teachers: {
      title: translateListUnit('teachers'),
      size: 8,
    },
    students: {
      title: translateListUnit('students'),
      size: 8,
    },
    classes: {
      title: translateListUnit('classes'),
      size: 6,
    },
    churn: {
      title: translateListUnit('churn'),
      size: 6,
    },
    presence: {
      title: translateListUnit('presence'),
      size: 8,
    },
    status: {
      title: translateListUnit('status'),
      size: 6,
    },
    actions: {
      title: translateListUnit('actions'),
      size: 6,
    },
  };

  const statisticsCards = [
    {
      title: t('totalUnit'),
      info: statistic?.totalUnitsCount ?? 0,
      isLoading: isLoadingUnitCount,
    },
  ];

  const changePages = (numberOfPage: string) => {
    setFilterParams({ pageNumber: Number(numberOfPage) });
  };

  return (
    <React.Fragment>
      <HeadTitle routeInfo={t('units')} />
      <div className="md:hidden">
        <NoAdminScreenForMobile />
      </div>

      <div className="hidden md:block w-full">
        <Layout className="gap-2 flex flex-col">
          <TableStatistics
            headingText={t('title')}
            statisticsCards={statisticsCards}
            adminButton={
              <AdminButton
                title={t('addUnit')}
                onClick={toggleIsAddUnit}
                open={isAddUnit}
              />
            }
          />

          <AnimatePresence>
            {isAddUnit && (
              <motion.div
                className="w-full flex overflow-hidden justify-end"
                {...accordion}
              >
                <UnitForm
                  onCancel={toggleIsAddUnit}
                  formMode="create"
                  className="py-2"
                />
              </motion.div>
            )}
          </AnimatePresence>

          <ListUnits
            updateUnitsStatistics={updateStatistics}
            units={units}
            titles={titles}
            isLoading={isInitialLoading}
            isSearch={isSearch}
          />
          <PaginationComponent
            isLoading={isPreviousData}
            amountPages={data?.meta?.pagination.pages || 1}
            changePages={changePages}
            paramsPage={pageNumber}
            amountOf={data?.meta?.pagination.count || 0}
            managerType="units"
          />
        </Layout>
      </div>
    </React.Fragment>
  );
}
