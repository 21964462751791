type MaskFunction = (value: string) => string;

export const emailMask: MaskFunction = (e: string) => {
  const noFirstCaracthers = ['+', '.', '@', '_', '-'];
  let email = e;
  if (noFirstCaracthers.includes(email[0])) {
    email = '';
  }

  return email
    .replace(/[!#¨$%^&*)\][{};:<>,?|\\/(=]+/g, '')
    .trim()
    .toLowerCase()
    .normalize('NFD')
    .replace(/[\u0300-\u036f]/g, '');
};

export const cnpjMask: MaskFunction = (value: string) => {
  const pattern = /^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/;
  return value.replace(pattern, '$1.$2.$3/$4-$5');
};

export const usernameMask: MaskFunction = (e: string) => {
  return e
    .replace(/[!@#¨$%^&*)\][{};:<>,?|\\/(+=]+/g, '')
    .trim()
    .toLowerCase()
    .normalize('NFD')
    .replace(/[\u0300-\u036f]/g, '');
};

export const generalMask: MaskFunction = (e: any) => {
  return e.replace(/[0-9!@#¨$%&*)\][{};:<>,?|\\/(+=_-]+/g, '');
};

export const upperCaseMask: MaskFunction = (e: any) => {
  return e.toUpperCase();
};
export const numberMask: MaskFunction = (e: any) => {
  return e.replace(/\D/g, '');
};

export const zipcodeMask: MaskFunction = (e: string) => {
  return e.replace(/\D/g, '').replace(/^(\d{5})(\d{3})+?$/, '$1-$2');
};

export const applyMultipleMasks =
  (...masks: MaskFunction[]) =>
  (value: string) => {
    let formatted: string = value;
    masks.forEach(mask => {
      formatted = mask(formatted);
    });
    return formatted;
  };

export const hexadecimalMask: MaskFunction = (e: string) => {
  const pattern = /[^#0-9a-fA-F]/g;

  if (!e.startsWith('#')) {
    e = '#' + e;
  }

  return e.replace(pattern, '');
};
