export let styles = {
  h1: 'text-primary font-rubik text-24 font-500 mb-3',
  h2: 'font-rubik text-20 font-500 text-primary mt-8 mb-2.5',
  h3: 'font-rubik text-18 font-500 text-primary mt-7 mb-2',
  h4: 'font-rubik text-16 font-500 text-primary mt-6 mb-1.5',
  p: 'indent-4 md:indent-8 text-16 font-400 font-rubik text-base-content w-full text-justify py-3',
  hr: 'content-none border border-neutral-800 opacity-40 my-5',
  imageP: 'text-14 font-400 font-rubik text-center text-base-content mt-1 mb-6',
  img: 'mx-auto mt-6',
  ol: 'pl-8 md:pl-12 list-decimal font-rubik text-16 space-y-2 text-justify',
  ul: 'pl-8 md:pl-12 font-rubik text-16 space-y-2 text-justify',
};

function replaceEspecialCaracters(text: string) {
  const [, rest] = text.split('<');
  if (rest && rest.includes('href=')) {
    return text;
  }
  return text.replace(/</, '&lt;').replace(/>/, '&gt;');
}

export default function chapterFormatter(title: string, content: string[]) {
  let html = '';
  let lastTag = '';

  for (let i = 0; i < content.length; i++) {
    let [tag, text] = content[i].split(/:(.*)/s) as string[];
    let styleKey = tag as keyof typeof styles;
    if (!tag && !text) {
      html += '';
    } else if (tag.includes('-')) {
      let list = makeUl(i, content);
      html += list.list;
      i = list.newIndex;
    } else if (tag === 'img') {
      html += `<${tag} class="${
        styles[styleKey]
      }" src=${text.trim()}></${tag}>`;
    } else if (text.includes('_________________')) {
      html += `<div class="${styles['hr']}"></div>`;
    } else {
      const styleTag = tag === 'p' && lastTag === 'img' ? 'imageP' : styleKey;
      html += `<${tag} class="${styles[styleTag]}">${replaceEspecialCaracters(
        text,
      )}</${tag}>`;
    }
    lastTag = tag;
  }
  html = `<h1 class="${styles['h1']}">${title}</h1>`.concat(html);
  return html;
}

function makeUl(index: number, content: any) {
  let listType: 'ul' | 'ol' = 'ul';
  let listContent = '';
  let newIndex = index;
  const ulStyle = `style="list-style: url(/images/star.png);"`;

  for (let i = index; i < content.length; i++) {
    newIndex = i;
    let splitContent = content[i].split(/:(.*)/s);
    let splitUl = splitContent[0].split('-');

    if (splitUl[0] === 'li') {
      listType = splitUl[1];
      listContent += `<li>${replaceEspecialCaracters(splitContent[1])}</li>`;
    } else {
      newIndex = i - 1;
      break;
    }
  }
  return {
    newIndex,
    list: `<${listType} class="${styles[listType || 'ul']}" ${
      listType === 'ul' ? ulStyle : ''
    }>${listContent}</${listType}>`,
  };
}
