import ConditionalRenderer from '@/components/common/ConditionalRenderer';
import { DashedCircleIcon } from '@/components/icons';
import { CheckCircleIcon } from '@heroicons/react/solid';

type StatusIconProps = {
  render?: boolean;
  hasDone: boolean;
};

export function StatusIcon({ render, hasDone }: StatusIconProps) {
  const className = 'hidden md:block h-14 w-14';

  return (
    <ConditionalRenderer condition={render}>
      <ConditionalRenderer
        condition={!hasDone}
        fallback={
          <span className={className}>
            <CheckCircleIcon
              data-testid="checkIcon"
              className={`${className} text-success`}
              strokeWidth={4}
            />
          </span>
        }
      >
        <span className={className}>
          <DashedCircleIcon
            strokeWidth={1}
            strokeDasharray="2 2"
            data-testid="dashedIcon"
            className={`${className} text-secondary/40 `}
          />
        </span>
      </ConditionalRenderer>
    </ConditionalRenderer>
  );
}
