import { ChevronDownIcon, ChevronUpIcon } from '@heroicons/react/outline';
import { useTranslation } from 'react-i18next';
import IconButton from './buttons/IconButton';
import { Tooltip } from './dataDisplay/Tooltip';

interface TableSortIconsProps {
  onClickSort(value: string): void;
}

export default function TableSortIcons(props: TableSortIconsProps) {
  const { t } = useTranslation('translation', {
    keyPrefix: 'tableHeader',
  });

  return (
    <div className="flex flex-col self-center font-400">
      <Tooltip text={t('increasing')}>
        <IconButton
          testId="buttonASC"
          onClick={() => props.onClickSort('ASC')}
          icon={
            <ChevronUpIcon
              className="text-base-100 w-4 flex h-fit"
              viewBox="0 0 24 20"
            />
          }
        />
      </Tooltip>
      <Tooltip text={t('decreasing')}>
        <IconButton
          testId="buttonDEC"
          onClick={() => props.onClickSort('DEC')}
          icon={
            <ChevronDownIcon
              className="text-base-100 w-4 h-fit"
              viewBox="0 0 24 20"
            />
          }
        />
      </Tooltip>
    </div>
  );
}
