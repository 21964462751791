import React from 'react';
import { LoadingIcon } from '../../icons';
import Text from '../dataDisplay/Text';

interface ActionDetailCardProps {
  testId?: string;
  title: string;
  icon: (...params: any[]) => JSX.Element;
  details: string;
  onClick?: React.MouseEventHandler<HTMLButtonElement>;
  isLoading?: boolean;
}

function ActionDetailCard({
  details,
  icon: Icon,
  title,
  onClick,
  isLoading,
  testId,
}: ActionDetailCardProps) {
  return (
    <button
      data-testId={testId || 'actionDetailButton'}
      disabled={isLoading}
      onClick={onClick}
      className="p-1.5 duration-150 disabled:grayscale-[30%] transition cursor-pointer enabled:active:scale-95 flex flex-col gap-1.5 bg-primary-content w-full rounded-md max-w-[244px]"
    >
      <div className="flex gap-1.5 items-center text-primary">
        {isLoading ? (
          <LoadingIcon className="w-4 h-4" />
        ) : (
          <Icon className="w-4 h-4" />
        )}
        <Text size="text-14" text={title} />
      </div>
      <div className="py-1 text-left w-full px-1.5 text-base-content  bg-base-100 rounded-md">
        <Text size="text-12" text={details} />
      </div>
    </button>
  );
}

export default ActionDetailCard;
