import { DraggableProvided } from '@hello-pangea/dnd';
import ConditionalRenderer from '../../../../common/ConditionalRenderer';
import AstroImage from '../../../../common/AstroImage';
import Skeleton from '../../../../common/Skeleton';
import Text from '../../../../common/dataDisplay/Text';
import { useState } from 'react';

interface DNDItemContainerProps {
  provided?: DraggableProvided;
  children: React.ReactNode;
  text?: string;
  image?: string | File;
  altText?: string;
  order?: number;
}

export default function DNDItemContainer({
  provided,
  children,
  text,
  image,
  altText,
  order,
}: DNDItemContainerProps) {
  const hasImage = !!image;
  const hasText = !!text;

  const haveBoth = hasImage && hasText;

  const ImageLoading = () => (
    <Skeleton className="rounded-lg flex w-[100%] h-20 sm:h-32 bg-neutral-content" />
  );

  const [showText, setShowText] = useState<boolean>(false);

  const hasShowTextCard = haveBoth && text.length >= 50 && showText;

  return (
    <div
      onMouseEnter={() => setShowText(true)}
      onMouseLeave={() => setShowText(false)}
      ref={provided?.innerRef}
      {...provided?.draggableProps}
      className="odd:bg-accent-content even:bg-success-content border odd:border-accent/40 
      even:border-success odd:text-accent even:text-success shadow-default shadow-primary-content
      flex rounded-xl p-2 items-center h-44 justify-center"
    >
      <ConditionalRenderer condition={!!order}>
        <Text
          text={order + '.'}
          format="rubik-500"
          className="self-start text-12 sm:text-14 md:text-16"
        />
      </ConditionalRenderer>

      {children}

      <div className="flex flex-col gap-2 justify-center relative w-[95%]">
        <ConditionalRenderer condition={hasImage}>
          <AstroImage
            className={`object-contain flex w-fit self-center h-20 sm:h-32
            ${hasShowTextCard ? 'opacity-25' : ''} `}
            src={image as string}
            alt={altText}
            validation={{
              renderLoading: ImageLoading,
            }}
          />
        </ConditionalRenderer>

        <ConditionalRenderer condition={hasText && !haveBoth}>
          <Text
            text={text}
            className={`text-base-content text-center leading-relaxed self-center
               ${
                 hasShowTextCard ? 'opacity-25' : ''
               } text-[2.5vw] xs:text-[2vw] sm:text-14 lg:text-16`}
          />
        </ConditionalRenderer>

        <ConditionalRenderer condition={!!haveBoth}>
          <Text
            text={text}
            className={`text-base-content text-justify leading-relaxed truncate ${
              hasShowTextCard ? 'opacity-25' : ''
            } text-[2.5vw] xs:text-[2vw] sm:text-14 lg:text-16`}
          />
        </ConditionalRenderer>

        <ConditionalRenderer condition={hasShowTextCard}>
          <div className="absolute text-center items-center flex pr-2.5 justify-center">
            <Text
              text={text}
              className={`text-base-content text-center leading-relaxed self-center text-[2.5vw] xs:text-[2vw] sm:text-14 lg:text-16
              `}
            />
          </div>
        </ConditionalRenderer>
      </div>
    </div>
  );
}
