import TabsTriggerButton from './TabsTriggerButton';
import TabsTriggerContainer from './TabsTriggerContainer';

type TabsTriggerProps = {
  id: string;
  children: React.ReactNode;
};
const TabsTrigger = ({ id, children }: TabsTriggerProps) => {
  return (
    <TabsTriggerContainer id={id}>
      <TabsTriggerButton id={id}>{children}</TabsTriggerButton>
    </TabsTriggerContainer>
  );
};

export default TabsTrigger;
