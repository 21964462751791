import ComponentGuard from '../ComponentGuard';
import { UserTypeEnum } from '@/models/User';
import RowListTextDisplay from './RowListTextDisplay';

type KlassesDisplayProps = {
  klasses?: string[];
  width?: number;
};

export default function KlassesDisplay({
  klasses = [],
  width,
}: KlassesDisplayProps) {
  return (
    <ComponentGuard roles={[UserTypeEnum.TEACHER]}>
      <RowListTextDisplay
        items={klasses.map(klassName => ({
          status: 'success',
          text: klassName,
        }))}
        itemsKeys={klasses}
        hasCopyButton
        width={width}
      />
    </ComponentGuard>
  );
}
