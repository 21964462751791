import moment from 'moment';
import { useTranslation } from 'react-i18next';
import User, { UserTypeEnum } from '@/models/User';
import Modal from '../../common/modals/Modal';
import RoundedButton from '../../common/buttons/RoundedButton';
import PillComponent from '../../common/dataDisplay/PillComponent';
import alert from '@/utils/UseAlert';
import { useMutation } from '@tanstack/react-query';
import { updateScheduledLesson } from '@/data/services/scheduledLessonsServices';
import { ApiError } from '@/models/Errors';
import Text from '../../common/dataDisplay/Text';
import { handleUserFullName } from '@/functions/handleUserFullName';
import { Controller, useForm } from 'react-hook-form';
import InfiniteSearchInput from '@/components/common/dataInput/InfiniteSearchInput';
import useAuth from '@/data/hook/useAuth';
import { getAuthorizedUnits } from '@/utils/getAuthorizedUnits';
import { usersQueryKeys } from '@/data/services/querykeys';

interface UpdateTeacherProps {
  onClickCancel: () => void;
  teacher?: User;
  lesson: string;
  date: string;
  scheduledId: number;
  updateScheduledLessons?: (a: any) => Promise<void>;
}

export default function UpdateTeacher(props: UpdateTeacherProps) {
  const {
    teacher,
    onClickCancel,
    lesson,
    date,
    scheduledId,
    updateScheduledLessons,
  } = props;

  const { user: authUser } = useAuth();

  const { t } = useTranslation('translation', {
    keyPrefix: 'calendar.admin.manageKlass.updateTeacher',
  });

  interface SelectTeacher {
    teacherId: number;
  }

  const { control, handleSubmit, watch, resetField } = useForm<SelectTeacher>({
    defaultValues: {
      teacherId: 0,
    },
  });

  async function onFinishChanges({ teacherId }: { teacherId: number }) {
    await updateScheduledLesson(scheduledId, {
      teacher: teacherId,
    });
  }

  const { mutate, isLoading: isUpdating } = useMutation(onFinishChanges, {
    async onSuccess() {
      await updateScheduledLessons?.('');
      resetField('teacherId');
      alert.success(t('success'));
    },
    onError(error: any) {
      const api = new ApiError(error);
      alert.error(api.getErrorMessage());
    },
    onSettled() {
      onClickCancel();
    },
  });

  const onSubmit = (data: SelectTeacher) => {
    mutate(data);
  };

  return (
    <Modal visible={true} onClose={onClickCancel}>
      <form
        onSubmit={handleSubmit(onSubmit)}
        className="flex flex-col gap-6 w-full"
      >
        <Text
          format="rubik-500"
          text={t('updateTeacherTitle')}
          className="text-primary text-18"
        />

        <div className="flex gap-x-3.5 self-center items-center">
          <PillComponent>{moment(date).format('DD/MM')}</PillComponent>
          <Text text={lesson} className="text-base-content" />
        </div>

        <Text
          format="rubik-500"
          text={handleUserFullName(teacher)}
          className="text-base-content text-18"
        />
        <Controller
          control={control}
          name="teacherId"
          render={({ field: { onChange } }) => (
            <InfiniteSearchInput
              service={usersQueryKeys.list}
              onSelect={({ id }) => onChange(id)}
              displayName={user => handleUserFullName(user)}
              input={{
                testId: 'scheduledLessonSelect',
                placeholder: t('placeholder'),
              }}
              filters={{
                userType: [UserTypeEnum.TEACHER],
                unit: getAuthorizedUnits(authUser),
              }}
              className={{ base: 'w-full flex self-center' }}
            />
          )}
        />

        <div className="flex w-full justify-around gap-6">
          <RoundedButton
            text={t('cancel')}
            color="neutral"
            className="w-full"
            onClick={onClickCancel}
          />
          <RoundedButton
            text={t('confirm')}
            className="w-full"
            disabled={watch('teacherId') === 0}
            loading={isUpdating}
          />
        </div>
      </form>
    </Modal>
  );
}
