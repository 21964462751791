import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import InfinityList from '@/components/common/InfinityList';
import Skeleton from '@/components/common/Skeleton';
import LegalInformationCourseCollapse from '@/components/courses/LegalInformationCourseCollapse';
import ScheduledLesson from '@/models/ScheduledLesson';
import LoadingView from '@/pages/courses/LoadingView';
import { getErrorMessage } from '@/utils/getErrorMessage';
import Text from '@/components/common/dataDisplay/Text';
import { KlassProgressProps } from '@/components/courses/student/KlassProgress';
import { REQUEST_STALE_TIME_IN_MS } from '@/constants';
import { lessonsProgressesQueryKeys } from '@/data/services/querykeys';
import ConditionalRenderer from '@/components/common/ConditionalRenderer';
import useListService from '@/data/hook/useListService';
import LessonBar from '@/components/lessons/LessonBar/LessonBar';
import { Lesson } from '@/models/Lesson';
import { shouldShowScheduledLesson } from '@/utils/shouldShowScheduledLesson';
import useStudentContext from '@/data/hook/student';
import { useStudentLessons } from '@/data/hook/useStudentLessons';

type StudentKlassLessonsProps = KlassProgressProps;

export default function StudentKlassLessons({
  progress,
}: StudentKlassLessonsProps) {
  const { t } = useTranslation('translation', {
    keyPrefix: 'klassProgress.lessonProgressList',
  });

  const { coursePath, klassId, enrollmentStatus } = progress;

  const {
    scheduledLessons,
    lessons,
    hasNextPage,
    isFetchingNextPage,
    error,
    validEnrollment,
    loading,
    fetchNextPage,
  } = useStudentLessons({
    slugCourseName: coursePath.slug,
    enrollmentStatus,
    klassId,
  });

  const scheduledLessonsMemo = useMemo(
    () =>
      scheduledLessons.map(scheduled => (
        <LessonItem
          key={scheduled.id}
          courseSlug={coursePath.slug}
          scheduledLesson={scheduled}
          courseProgressId={progress.id}
        />
      )),
    [coursePath.slug, scheduledLessons, progress.id],
  );

  const lessonsMemo = useMemo(
    () =>
      lessons
        .sort((a, b) => a.order - b.order)
        .map(lesson => (
          <LessonItem
            key={lesson.id}
            courseSlug={coursePath.slug}
            lesson={lesson}
            scheduledLesson={scheduledLessons.find(
              scheduledLesson => scheduledLesson.lesson.id === lesson.id,
            )}
            courseProgressId={progress.id}
          />
        )),
    [coursePath.slug, lessons, scheduledLessons, progress.id],
  );

  if (loading) return <LoadingView classNameContent="h-fit" />;

  if (error)
    return (
      <Text
        testId="error"
        text={getErrorMessage(error)}
        format="rubik-500"
        className="text-primary"
      />
    );

  return (
    <InfinityList
      hasNextPage={hasNextPage}
      isFetchingNextPage={isFetchingNextPage}
      onReachEnd={fetchNextPage}
      className="gap-4"
    >
      <ConditionalRenderer condition={validEnrollment}>
        {scheduledLessonsMemo}
      </ConditionalRenderer>

      <ConditionalRenderer condition={!validEnrollment}>
        {lessonsMemo}
      </ConditionalRenderer>

      <ConditionalRenderer
        condition={!scheduledLessons.length && !lessons.length}
      >
        <Text
          testId="noLessons"
          text={t('noLessons')}
          format="rubik-500"
          className="text-primary"
        />
      </ConditionalRenderer>

      <LegalInformationCourseCollapse course={coursePath} />
    </InfinityList>
  );
}

type LessonItemProps = {
  courseProgressId: number;
  courseSlug: string;
  scheduledLesson?: ScheduledLesson;
  lesson?: Lesson;
};

function LessonItem({
  scheduledLesson,
  courseSlug,
  lesson,
  courseProgressId,
}: LessonItemProps) {
  const { results, isInitialLoading: loading } = useListService({
    ...lessonsProgressesQueryKeys.list({
      lesson: scheduledLesson?.lesson.id || lesson?.id,
      courseProgressId: [courseProgressId],
    }),
    staleTime: REQUEST_STALE_TIME_IN_MS,
    enabled: !!scheduledLesson?.lesson.id || !!lesson?.id,
  });

  const lessonProgress = results.at(0);

  const { nextLesson } = useStudentContext();

  if (loading)
    return (
      <Skeleton className="flex h-[75px] w-full rounded-lg bg-secondary-content" />
    );

  if (lesson && lessonProgress)
    return (
      <LessonBar
        slugCourseName={courseSlug}
        lesson={lesson}
        color="secondary"
        scheduledLesson={scheduledLesson}
        lessonProgress={lessonProgress}
      />
    );

  if (scheduledLesson && lessonProgress)
    return (
      <LessonBar
        slugCourseName={courseSlug}
        lesson={scheduledLesson.lesson}
        color="secondary"
        scheduledLesson={scheduledLesson}
        lessonProgress={lessonProgress}
        klassId={scheduledLesson.klass}
        blocked={
          !shouldShowScheduledLesson(
            scheduledLesson,
            lessonProgress,
            nextLesson,
          )
        }
      />
    );

  return null;
}
