import React from 'react';

type backgroundColorType = 'purple' | 'orage';

interface InfosProps {
  text: React.ReactNode;
  onClick?: () => void;
  color?: backgroundColorType;
  className?: string;
  testId?: string;
}

const backgroundColor: Record<backgroundColorType, string> = {
  purple: 'bg-secondary-content text-secondary',
  orage: 'bg-warning-content text-warning',
};

export default function ActivityAttemptInfo({
  text: content,
  onClick,
  color = 'purple',
  className,
  testId,
}: InfosProps) {
  return (
    <span
      data-testid={testId}
      onClick={onClick}
      className={`${backgroundColor[color]} w-24 shrink-0 rounded-lg truncate 
        px-2.5 py-0.5 font-400 text-14 text-center flex items-center h-fit self-center
        ${onClick ? 'cursor-pointer' : 'cursor-default'} ${className || ''}`}
    >
      {content}
    </span>
  );
}
