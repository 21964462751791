import { Link } from 'react-router-dom';
import { HeartIcon as SolidHeartIcon } from '@heroicons/react/solid';
import { EyeIcon, HeartIcon } from '@heroicons/react/outline';

import { Project } from '@/models/Project';
import AvatarName from '@/components/common/dataDisplay/AvatarName';
import Tag from '@/components/common/dataDisplay/Tag';
import Skeleton from '@/components/common/Skeleton';
import InteractionsContainer from './InteractionsContainer';
import Text from '@/components/common/dataDisplay/Text';
import ConditionalRenderer from '@/components/common/ConditionalRenderer';
import AstroImage from '@/components/common/AstroImage';
import { Tooltip } from '@/components/common/dataDisplay/Tooltip';
import { handleUserFullName } from '@/functions/handleUserFullName';

interface ProjectCardProps {
  project?: Project;
  isLoading?: boolean;
}

export default function ProjectCard({ project, isLoading }: ProjectCardProps) {
  if (isLoading) {
    return <Skeleton className="card card-compact w-64 h-56 bg-primary/40" />;
  }

  if (project) {
    const to = `${project.id}`;
    return (
      <li className="flex flex-col w-64 h-56 bg-base-100 shadow-default rounded-xl snap-center sm:snap-none">
        <Link to={to} className="flex flex-col h-full">
          <Image image={project.image} />
          <Content project={project} />
        </Link>
      </li>
    );
  }

  return null;
}

function Image({ image }: { image?: string }) {
  const renderLoading = () => (
    <Skeleton className="rounded-t-xl flex w-full h-40 bg-neutral-content" />
  );

  return (
    <ConditionalRenderer
      condition={image}
      fallback={<div className="h-40 flex w-full bg-neutral-content" />}
    >
      <AstroImage
        className="object-cover flex w-full self-center rounded-t-xl h-36"
        src={image}
        validation={{
          renderLoading,
        }}
      />
    </ConditionalRenderer>
  );
}

type PickProject = Pick<ProjectCardProps, 'project'>;

type ContentProps = Required<PickProject>;

function Content({ project }: ContentProps) {
  return (
    <div className="relative gap-1 px-2.5 py-1.5 flex w-64 h-full">
      <Tag
        className="absolute left-0 -top-2.5 text-14 py-0.5 font-400 text-base-100"
        color={project.tool.color ? 'custom' : 'success'}
        text={project.tool.name}
        style={{ backgroundColor: project.tool.color ?? '' }}
      />
      <div className="flex flex-col overflow-hidden gap-1 justify-between grow">
        <Text text={project.title} className="py-2 text-20 truncate" />
        <div className="flex justify-between items-center gap-3">
          <div className="flex items-center gap-1 text-base-content">
            <InteractionsContainer
              count={project.views}
              icon={({ className }) => <EyeIcon className={className} />}
            />
            <InteractionsContainer
              upvoted={project.upVoted}
              count={project.upVotes}
              icon={({ className }) => (
                <ConditionalRenderer
                  condition={project.upVoted}
                  fallback={<HeartIcon className={className} />}
                >
                  <SolidHeartIcon className={className} />
                </ConditionalRenderer>
              )}
            />
          </div>

          <Tooltip
            text={handleUserFullName(project.user)}
            classNameContainer="overflow-x-hidden"
            color="accent"
          >
            <AvatarName
              className="gap-1 text-base-content text-14 text-right overflow-x-hidden"
              textClassName="truncate"
              user={project.user}
            />
          </Tooltip>
        </div>
      </div>
    </div>
  );
}
