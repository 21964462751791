import { useCallback, useState } from 'react';

export default function useToggle(startOpen = false) {
  const [isOpen, setIsOpen] = useState(startOpen);

  const toggle = () => {
    setIsOpen(prev => !prev);
  };
  const open = useCallback(() => setIsOpen(true), []);
  const close = useCallback(() => setIsOpen(false), []);

  return {
    isOpen,
    toggle,
    open,
    close,
  };
}
