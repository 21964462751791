import {
  BehaviorAssessment as StudentBehaviorAssessment,
  BehaviorRatingEnum as StudentBehaviorRatingEnum,
} from '@/models/StudentAssessment';
import {
  BehaviorAssessment as KlassBehaviorAssessment,
  BehaviorRatingEnum as KlassBehaviorRatingEnum,
} from '@/models/KlassAssessment';
import Text from '@/components/common/dataDisplay/Text';
import { useTranslation } from 'react-i18next';
import ConditionalRenderer from '@/components/common/ConditionalRenderer';

type BehaviorAssessmentPreviewProps = {
  studentBehaviorAssessment?: StudentBehaviorAssessment;
  klassBehaviorAssessment?: KlassBehaviorAssessment;
};

type BehaviorRatingEnum = StudentBehaviorRatingEnum | KlassBehaviorRatingEnum;

type AssessmentPreviewType = {
  text: string;
  value?: BehaviorRatingEnum;
};

export default function BehaviorAssessmentPreview({
  studentBehaviorAssessment,
  klassBehaviorAssessment,
}: BehaviorAssessmentPreviewProps) {
  const { t } = useTranslation('translation', {
    keyPrefix: 'behaviorAssessmentForm',
  });

  const studentAssessments: AssessmentPreviewType[] = [
    {
      text: t('labels.attention'),
      value: studentBehaviorAssessment?.attention,
    },
    {
      text: t('labels.apprenticeship'),
      value: studentBehaviorAssessment?.apprenticeship,
    },
    {
      text: t('labels.initiative'),
      value: studentBehaviorAssessment?.initiative,
    },
    {
      text: t('labels.socialization'),
      value: studentBehaviorAssessment?.socialization,
    },
    {
      text: t('labels.organization'),
      value: studentBehaviorAssessment?.organization,
    },
  ];

  const klassAssessments: AssessmentPreviewType[] = [
    {
      text: t('labels.energy'),
      value: klassBehaviorAssessment?.energy,
    },
    {
      text: t('labels.speed'),
      value: klassBehaviorAssessment?.speed,
    },
    {
      text: t('labels.maturity'),
      value: klassBehaviorAssessment?.maturity,
    },
  ];

  return (
    <ul className="flex flex-wrap gap-x-4 gap-y-2">
      <ConditionalRenderer condition={studentBehaviorAssessment}>
        {studentAssessments.map(({ text, value }, index, array) => (
          <AssessmentPreview
            text={text}
            value={value}
            key={text}
            divider={index < array.length - 1}
            length={5}
          />
        ))}
      </ConditionalRenderer>
      <ConditionalRenderer condition={klassBehaviorAssessment}>
        {klassAssessments.map(({ text, value }, index, array) => (
          <AssessmentPreview
            text={text}
            value={value}
            key={text}
            divider={index < array.length - 1}
            length={3}
          />
        ))}
      </ConditionalRenderer>
    </ul>
  );
}

export function AssessmentPreview({
  text,
  value,
  divider,
  length,
}: AssessmentPreviewType & { divider?: boolean; length: number }) {
  return (
    <li className={`flex gap-4 items-center ${value ? '' : 'text-neutral/50'}`}>
      <Text text={text} />
      <BehaviorRating value={value} length={length} />
      <ConditionalRenderer condition={divider}>
        <hr className="h-[14px] w-px border-base-content" />
      </ConditionalRenderer>
    </li>
  );
}

function BehaviorRating({
  value,
  length,
}: {
  value?: BehaviorRatingEnum;
  length: number;
}) {
  return (
    <span className="flex flex-col-reverse gap-px w-[14px] h-[14px]">
      {Array.from({ length }, (_, index) => index + 1).map(rating => (
        <span
          key={rating}
          className={`w-full h-full ${
            Number(value) >= rating ? 'bg-yellow-500' : 'bg-neutral-content'
          }`}
        />
      ))}
    </span>
  );
}
