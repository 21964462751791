import AddCourse from '@/components/admin/courses/AddCourse';
import ListCourses from '@/components/admin/courses/ListCourses';
import AdminButton from '@/components/common/AdminButton';
import HeadTitle from '@/components/common/HeadTitle';
import TableSearchIcon from '@/components/common/TableSearchIcon';
import Dropdown from '@/components/common/filter/Dropdown';
import TableStatistics from '@/components/common/table/TableStatistics';
import Layout from '@/components/template/Layout';
import { MANAGEMENT_PAGE_SIZE } from '@/constants';
import useStatisticService from '@/data/hook/useStatistics';
import { ListCourseBaseFilters } from '@/data/services/courseServices';
import { CourseTypeEnum } from '@/models/Course';
import { CourseHeader } from '@/utils/HeaderTypes';
import useFilterParams from '@/utils/UseFilterParams';
import { unitStatisticsCalculator } from '@/utils/statistics/unitStatisticsCalculators';
import React, { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import NoAdminScreenForMobile from './NoAdminScreenForMobile';
import PaginationComponent from './PaginationComponent';
import useListService from '@/data/hook/useListService';
import {
  courseBasesQueryKeys,
  courseStatisticsQueryKeys,
  unitsStatisticsQueryKeys,
} from '@/data/services/querykeys';
import { CategoryEnum } from '@/models/Rewards';

export default function ManageCourse() {
  const { t } = useTranslation('translation', { keyPrefix: 'manageCourse' });

  const { isAdd } = useParams();

  const [isSearch, setIsSearch] = useState(false);

  const [isAddCourse, setIsAddCourse] = useState(isAdd === 'add');

  const { filterParams, setFilterParams } =
    useFilterParams<ListCourseBaseFilters>();

  const pageNumber = Number(filterParams.get('pageNumber') ?? '1');
  const search = filterParams.get('search') ?? undefined;
  const courseType =
    (filterParams.getAll('courseType') as CourseTypeEnum[]) ?? undefined;
  const category =
    (filterParams.getAll('category') as CategoryEnum[]) ?? undefined;
  const pageSize = MANAGEMENT_PAGE_SIZE;

  const params = {
    pageNumber,
    pageSize,
    search,
    courseType,
    category,
  };
  const {
    results: coursesBases,
    meta: metaCoursesBases,
    isLoading: isLoadingCoursesBases,
    isPreviousData: isPreviousDataCoursesBases,
    invalidate: updateCoursesBases,
  } = useListService({
    ...courseBasesQueryKeys.list(params),
    keepPreviousData: true,
  });

  const isLoadingPage = isLoadingCoursesBases || isPreviousDataCoursesBases;

  const titles: CourseHeader = {
    name: {
      title: t('listCourses.name'),
      extraFunction: [
        <TableSearchIcon onClick={() => setIsSearch(!isSearch)} />,
      ],
      size: 15,
    },

    version: {
      title: t('listCourses.version'),
      size: 6,
    },
    category: {
      title: t('listCourses.category'),
      size: 13,
      extraFunction: [<Dropdown filter={courseType ?? []} type="courseType" />],
    },
    students: {
      title: t('listCourses.students'),
      size: 9,
    },
    churn: {
      title: t('listCourses.churn'),
      size: 8,
    },
    presence: {
      title: t('listCourses.presence'),
      size: 10.5,
    },
    status: {
      title: t('listCourses.status'),
      size: 5,
    },
    actions: {
      title: t('listCourses.actions'),
      size: 12,
    },
  };

  const changePages = (numberOfPage: string) => {
    setFilterParams({ pageNumber: Number(numberOfPage) });
  };

  const {
    calculated: unitStatistics,
    isInitialLoading: isLoadingUnitStatistics,
    invalidate: updateCoursesStatistics,
  } = useStatisticService(
    unitsStatisticsQueryKeys.list(),
    unitStatisticsCalculator,
  );

  const removeQueries = useCallback(() => {
    updateCoursesBases();
    updateCoursesStatistics();
  }, [updateCoursesBases, updateCoursesStatistics]);

  const renderAddCourse = () => setIsAddCourse(prev => !prev);

  const {
    statistic: coursesStatistic,
    isInitialLoading: isLoadingTotalCourses,
  } = useStatisticService(courseStatisticsQueryKeys.list()._ctx.totalSum);

  const statisticsCards = [
    {
      title: t('coursesQuantity'),
      info: coursesStatistic?.coursesCount || 0,
      isLoading: isLoadingTotalCourses,
    },
    {
      title: t('studentsTotal'),
      info: unitStatistics?.totalStudents || 0,
      isLoading: isLoadingUnitStatistics,
    },
    {
      title: t('klassesTotal'),
      info: unitStatistics?.totalKlasses || 0,
      isLoading: isLoadingUnitStatistics,
    },
  ];

  return (
    <React.Fragment>
      <HeadTitle routeInfo={t('courses')} />
      <div className="md:hidden">
        <NoAdminScreenForMobile />
      </div>
      <Layout className="gap-2 flex-col hidden md:flex">
        <TableStatistics
          headingText={t('title')}
          statisticsCards={statisticsCards}
          adminButton={
            <AdminButton
              title={t('add')}
              onClick={renderAddCourse}
              open={isAddCourse}
            />
          }
        />

        <AddCourse
          showAddCourse={isAddCourse}
          updateCourseList={removeQueries}
          setShowAddCourse={setIsAddCourse}
        />

        <ListCourses
          isLoading={isLoadingPage}
          coursesBases={coursesBases || []}
          updateCourseList={removeQueries}
          titles={titles}
          isSearch={isSearch}
        />

        <PaginationComponent
          amountOf={metaCoursesBases?.pagination.count || 0}
          amountPages={metaCoursesBases?.pagination.pages || 1}
          changePages={changePages}
          managerType="courses"
          paramsPage={pageNumber}
        />
      </Layout>
    </React.Fragment>
  );
}
