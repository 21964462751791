import { useTranslation } from 'react-i18next';

import { NextLessonView } from '@/components/klass/student/StudentKlassLessons/NextLessonView/NextLessonView';
import KlassLessons from '@/components/klass/student/StudentKlassLessons/StudentKlassLessons';
import Text from '@/components/common/dataDisplay/Text';
import { SimplifiedCourseProgress } from '@/models/StudentCourseProgress';

export type KlassProgressProps = {
  progress: SimplifiedCourseProgress;
  enabled?: boolean;
};

export default function KlassProgress({ progress }: KlassProgressProps) {
  const { t } = useTranslation('translation', {
    keyPrefix: 'klassProgress',
  });

  return (
    <div className="flex flex-col gap-5">
      <div className="flex flex-wrap justify-between items-center gap-4">
        <Text
          text={t('myLessons')}
          format="poppins-600"
          className="text-24 text-primary"
        />
        <NextLessonView />
      </div>

      <KlassLessons progress={progress} />
    </div>
  );
}
