import ReactGA from 'react-ga4';

import { EventTrack } from '@/models/EventTrack';
import { getErrorMessage } from '@/utils/getErrorMessage';

export const eventTrack = (event: EventTrack) => {
  try {
    ReactGA.event(event);
  } catch (error) {
    ReactGA.event({
      ...event,
      action: getErrorMessage(error),
      nonInteraction: true,
    });
  }
};
