import IconButton from '@/components/common/buttons/IconButton';
import { MoveAreaIcon } from '@/components/icons';
import useTreeArea from '@/data/hook/useTreeArea';
import Area from '@/models/Area';
import useFilterParams from '@/utils/UseFilterParams';
import { FolderIcon, PencilIcon } from '@heroicons/react/outline';
import { useState } from 'react';
import { TopicsFiltersParams } from '../../../TopicsPageContent';
import AreaRename from '../../actions/AreaRename';
import DeleteArea from '../../actions/DeleteArea';
import MoveTo from '../../actions/MoveTo';
import ConditionalRenderer from '@/components/common/ConditionalRenderer';

export type ParentAreaProps = {
  area: Area;
};
export default function ParentArea({ area }: ParentAreaProps) {
  const { getPath } = useTreeArea();
  const { setFilterParams } = useFilterParams<TopicsFiltersParams>();

  const [isRenaming, setIsRenaming] = useState(false);
  const [isMoving, setIsMoving] = useState(false);

  const onClick = () => {
    const path = getPath(area.id);
    setFilterParams({
      path,
    });
  };

  return (
    <li className="flex flex-col gap-2.5 p-2.5 border border-primary rounded-lg">
      <header className="flex justify-between items-center">
        <div
          className="flex items-center gap-2.5 flex-grow cursor-pointer"
          role="button"
          onClick={onClick}
        >
          <FolderIcon className="w-6 h-6 text-primary" />
          <AreaRename
            area={area}
            key={area.name}
            isRenaming={isRenaming}
            setIsRenaming={setIsRenaming}
            wrapper={<p className="font-500 text-primary" />}
          />
        </div>
        <div className="flex gap-2.5 items-center">
          <IconButton
            icon={<PencilIcon className="w-5 h-5 text-primary" />}
            onClick={() => setIsRenaming(prev => !prev)}
          />
          <IconButton
            icon={<MoveAreaIcon className="w-5 h-5 text-primary" />}
            onClick={() => setIsMoving(prev => !prev)}
          />

          <DeleteArea area={area} />
        </div>
      </header>
      <ConditionalRenderer condition={isMoving}>
        <MoveTo area={area} setIsMoving={setIsMoving} />
      </ConditionalRenderer>
    </li>
  );
}
