import requester from '@/axios';
import { QuizActivityElement } from '@/models/ActivityElement';

export const getQuizActivity = async (questionId: number) => {
  const { data: quiz } = await requester().get<QuizActivityElement>(
    `quiz-activity/${questionId}/`,
  );
  return quiz;
};

export const deleteQuizActivity = async (questionId: number) => {
  await requester().delete<QuizActivityElement>(`quiz-activity/${questionId}/`);
};
