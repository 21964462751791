type SetClassroomPathProps = {
  baseUrl: string;
  klassId?: number;
  route?: string;
};

export const setClassroomPath = (params: SetClassroomPathProps) => {
  const { baseUrl, klassId, route } = params;

  if (klassId) return `${baseUrl}/class/${klassId}${route || ''}`;
  else return `${baseUrl}${route ?? ''}`;
};
