import { ChangeEvent } from 'react';
import { twMerge } from 'tailwind-merge';
import { FilterIcon as SolidIcon } from '@heroicons/react/solid';
import { FilterIcon as OutlineIcon } from '@heroicons/react/outline';

import Badge from '../dataDisplay/Badge';
import Popup from '../Popup';
import ConditionalRenderer from '../ConditionalRenderer';
import { ClassNameComponent } from '@/utils/ClassNameComponent';
import { scrollBarClassName } from '@/utils/scrollBarClassName';
import Checkbox, { CheckboxProps } from '../dataInput/Checkbox';
import { ClassScheduleFiltersType } from '@/models/ClassScheduleFilters';
import Text from '../dataDisplay/Text';

export type PopupFilterItem = Omit<CheckboxProps, 'id'> & {
  id: string | number;
  type: string;
};

type PopupFilterProps = {
  type: ClassScheduleFiltersType;
  label?: string;
  options?: PopupFilterItem[];
  onChange: (
    event: Pick<ChangeEvent<HTMLInputElement>, 'target'>,
    type: ClassScheduleFiltersType,
  ) => void;
  className?: ClassNameComponent;
};

export default function PopupFilter({
  type,
  label,
  options = [],
  onChange,
  className,
}: PopupFilterProps) {
  const badgeCount = options.reduce<number>((acc, option) => {
    if (option.checked) return acc + 1;

    return acc;
  }, 0);

  return (
    <Popup
      hover
      className={{
        reference: twMerge('cursor-pointer', className?.reference),
        popup: className?.popup,
      }}
      reference={
        <Badge count={badgeCount} position="-top-1 -right-1" color="info">
          <span className="flex gap-1">
            <ConditionalRenderer condition={label}>
              <Text
                text={label}
                className={twMerge('text-base-content', className?.text)}
              />
            </ConditionalRenderer>

            <ConditionalRenderer
              condition={badgeCount > 0}
              fallback={<OutlineIcon className="w-4 text-primary" />}
            >
              <SolidIcon className="w-4 text-primary" />
            </ConditionalRenderer>
          </span>
        </Badge>
      }
    >
      <section className="bg-base-100 p-3.5 shadow-default rounded-xl border border-neutral-content flex flex-col gap-3.5 max-w-48 max-h-32">
        <ConditionalRenderer condition={label}>
          <Text
            text={label}
            className={twMerge('text-primary font-500', className?.text)}
          />
        </ConditionalRenderer>
        <ul
          className={twMerge(
            'flex flex-col gap-1.5 pr-1',
            className?.ul,
            scrollBarClassName,
          )}
        >
          {options.map(option => (
            <PopupFilterOption
              key={option.id}
              item={option}
              onChange={onChange}
              type={type}
            />
          ))}
        </ul>
      </section>
    </Popup>
  );
}

type PopupFilterOptionProps = PopupFilterProps & {
  item: PopupFilterItem;
};

function PopupFilterOption({ item, onChange, type }: PopupFilterOptionProps) {
  const { id, checked, label, value } = item;
  return (
    <li className="cursor-pointer flex items-center justify-between gap-6 hover:text-primary transition ease-in-out duration-300 text-14">
      <Checkbox
        id={String(id)}
        checked={checked}
        onChange={event => onChange(event, type)}
        value={value}
        label={label}
        className={{ div: 'flex flex-row-reverse justify-between w-full' }}
      />
    </li>
  );
}
