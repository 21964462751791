import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import Controls from '../../common/Controls';
import Chapter from '@/models/Chapter';

interface AdminMaterialControlProps {
  chapters: Chapter[] | undefined;
  isTeacherBook?: boolean;
  baseUrl: string;
}

function AdminMaterialControl({
  chapters,
  baseUrl,
  isTeacherBook,
}: AdminMaterialControlProps) {
  const { t } = useTranslation('translation', {
    keyPrefix: 'lesson.controlButton',
  });
  const navigate = useNavigate();

  const { chapterOrder } = useParams();

  function handleChapters(direction: 1 | -1) {
    window.scroll({ top: 0, behavior: 'smooth' });
    const routePrefix = `${baseUrl}${isTeacherBook ? '/t' : ''}/book/chapter`;

    navigate(`${routePrefix}/${Number(chapterOrder) + direction}`);
  }

  const isLastChapter = chapters?.at(-1)?.order === Number(chapterOrder);

  return (
    <Controls
      hidePrev={chapterOrder === '1'}
      nextText={t('bookNext')}
      prevText={t('bookPrev')}
      buttonColor="text-primary"
      goBack={() => handleChapters(-1)}
      goNext={() => handleChapters(1)}
      isOnLast={isLastChapter}
      onFinish={() => {
        navigate(baseUrl);
        window.scroll({ top: 0, behavior: 'smooth' });
      }}
    />
  );
}

export default AdminMaterialControl;
