import { DragDropContext, Droppable, DropResult } from '@hello-pangea/dnd';

interface LessonReplacerProps {
  onDragEnd?: (result: DropResult) => void;
  children: JSX.Element[];
  isDropDisabled?: boolean;
}
function LessonReplacer({
  isDropDisabled,
  onDragEnd,
  children,
}: LessonReplacerProps) {
  return (
    <DragDropContext onDragEnd={onDragEnd ?? (() => {})}>
      <Droppable {...{ isDropDisabled }} droppableId="droppable-1">
        {provided => (
          <div
            ref={provided.innerRef}
            {...provided.droppableProps}
            className="flex flex-col justify-around gap-4"
          >
            {children}
            {provided.placeholder}
          </div>
        )}
      </Droppable>
    </DragDropContext>
  );
}

export default LessonReplacer;
