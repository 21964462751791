import React from 'react';
import Text from '../../common/dataDisplay/Text';
import { TeamHeader } from '@/utils/HeaderTypes';
import SearchAndFilterButtons from '../../common/SearchAndFilterButtons';
import Header from '../../common/table/Header';
import DataTeamTable from './DataTeamTable';
import ConditionalRenderer from '../../common/ConditionalRenderer';
import { useTranslation } from 'react-i18next';
import { debounce } from 'lodash';
import { LoadingIcon } from '../../icons';
import useFilterParams from '@/utils/UseFilterParams';
import User from '@/models/User';
import { ListUsersFilters } from '@/data/services/userServices';
import { QuickFilterProps as QuickFilterItem } from '@/components/common/filter/QuickFilter';
import QuickFilters from '@/components/common/filter/QuickFilters';

interface ListTeamProps {
  isLoading?: boolean;
  team: User[];
  searchTeacher: string;
  titles: TeamHeader;
  isSearch: boolean;
}

export default function ListTeam({
  team,
  isLoading,
  isSearch,
  titles,
}: ListTeamProps) {
  const { t } = useTranslation('translation', {
    keyPrefix: 'manageTeacher.dataUserTable',
  });

  const { filterParams, setFilterParams, deleteFilterParams } =
    useFilterParams<ListUsersFilters>({});

  const requestSearch = (value: string) => {
    if (!value) {
      deleteFilterParams('search');
    } else {
      setFilterParams({ search: value });
    }

    setFilterParams({ pageNumber: 1 });
  };

  function clearSearch() {
    deleteFilterParams('search');
    setFilterParams({ pageNumber: 1 });
  }

  function clearFilters() {
    deleteFilterParams(['search', 'unit', 'userType']);
    setFilterParams({ pageNumber: 1 });
  }

  const quickFilters: QuickFilterItem[] = [
    {
      type: 'unit',
      filters: filterParams.getAll('unit'),
    },
    {
      type: 'userType',
      filters: filterParams.getAll('userType'),
    },
  ];

  return (
    <React.Fragment>
      <div className="flex gap-4 justify-between items-center">
        <SearchAndFilterButtons
          searchInitial={filterParams.get('search') ?? ''}
          onChange={debounce(e => requestSearch(e.target.value), 500)}
          isSearch={isSearch}
          clearFiltersButton={clearFilters}
          clearSearch={clearSearch}
        />
        <QuickFilters quickFilters={quickFilters} />
      </div>

      <div>
        <table className="flex flex-col gap-y-2.5">
          <Header headers={Object.values(titles)} />
          <ConditionalRenderer condition={!isLoading}>
            <DataTeamTable titles={titles} team={team} />
          </ConditionalRenderer>
        </table>
        <ConditionalRenderer condition={!team.length && !isLoading}>
          <Text
            text={t('searchNotFound')}
            className="flex justify-center mt-5"
          />
        </ConditionalRenderer>
        <ConditionalRenderer condition={isLoading}>
          <div className="flex w-full h-[518px] justify-center items-center p-3.5">
            <LoadingIcon className="text-primary/40 w-10 h-10" />
          </div>
        </ConditionalRenderer>
      </div>
    </React.Fragment>
  );
}
