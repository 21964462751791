import { UnitsStatistics } from '@/models/Statistics';
import churnPercentage from './churnPercentage';
import attendancePercentage from './attendencePercentage';

export type UnitCalculatorResult = {
  studentPerTeacherCount: number;
  studentsPerKlassCount: number;
  attendancePercentage: number;
  totalStudents: number;
  totalTeachers: number;
  totalKlasses: number;
  churnRate: number;
};

function initialUnitStatistics(): UnitCalculatorResult {
  return {
    studentPerTeacherCount: 0,
    attendancePercentage: 0,
    studentsPerKlassCount: 0,
    totalKlasses: 0,
    totalStudents: 0,
    totalTeachers: 0,
    churnRate: 0,
  };
}

export const unitStatisticsCalculator = (stats: UnitsStatistics) => {
  const calcStats = initialUnitStatistics();
  if (stats) {
    calcStats.studentPerTeacherCount = parseInt(
      (stats.totalActiveStudentsCount / stats.totalActiveTeachersCount).toFixed(
        2,
      ),
    );
    calcStats.churnRate = churnPercentage({
      activeEnrollments: stats.totalActiveStudentsCount,
      canceledEnrollments: stats.dailyCancelledEnrollmentsCount,
    });
    calcStats.attendancePercentage = attendancePercentage({
      presences: stats.dailyLessonReportPresencesCount,
      totalReports:
        stats.dailyLessonReportAbsencesCount +
        stats.dailyLessonReportPresencesCount,
    });
    calcStats.studentsPerKlassCount = parseInt(
      (stats.totalActiveStudentsCount / stats.totalActiveClassesCount).toFixed(
        2,
      ),
    );
    calcStats.totalKlasses = stats.totalActiveClassesCount;
    calcStats.totalStudents = stats.totalActiveStudentsCount;
    calcStats.totalTeachers = stats.totalActiveTeachersCount;
  }
  return calcStats;
};
