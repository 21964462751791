import { useTranslation } from 'react-i18next';

import Klass from '@/models/Klass';
import ScheduledLesson from '@/models/ScheduledLesson';
import { ROLES } from '@/utils/roles';
import { ROUTES } from '@/utils/routes';
import ConditionalRenderer from '@/components/common/ConditionalRenderer';
import { Tooltip } from '@/components/common/dataDisplay/Tooltip';
import IconButton from '@/components/common/buttons/IconButton';
import { ActivityColorIcon } from '@/components/icons';
import Tag from '@/components/common/dataDisplay/Tag';

type ReportButtonProps = {
  render?: boolean;
  disabled?: boolean;
  klass: Klass;
  scheduledLesson: ScheduledLesson;
};

export function ReportButton({
  disabled,
  klass,
  scheduledLesson,
  render,
}: ReportButtonProps) {
  const { t } = useTranslation('translation', {
    keyPrefix: 'calendar.admin.manageKlass',
  });

  const { id: klassId } = klass;

  const { id: scheduledLessonId, isActive } = scheduledLesson;

  const tooltipMessage = disabled ? t('noStudentsInClass') : t('report');

  const delayed = ROLES.SCHEDULED_LESSON.isDelayed({ klass, scheduledLesson });

  const reportLink = disabled
    ? undefined
    : ROUTES.CLASS.LESSON_REPORT(klassId, scheduledLessonId);

  return (
    <ConditionalRenderer condition={render}>
      <div className="flex gap-2 items-center">
        <Tooltip text={tooltipMessage}>
          <IconButton
            href={reportLink}
            disabled={disabled}
            className={`p-0.5 bg-base-100 rounded-full border hover:ring ring-primary-content
            hover:bg-primary-content disabled:cursor-not-allowed transition ease-in-out duration-200
            transform hover:scale-105 ${
              delayed ? 'border-warning' : 'border-primary'
            } `}
            icon={<ActivityColorIcon className="w-6 h-6" />}
          />
        </Tooltip>
        <ConditionalRenderer condition={delayed && isActive}>
          <Tag
            color="warning"
            text={t('delayedReport')}
            className="text-12 md:text-14"
          />
        </ConditionalRenderer>
      </div>
    </ConditionalRenderer>
  );
}
