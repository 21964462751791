import { SaveIcon, XIcon } from '@heroicons/react/outline';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import MainButton from '@/components/common/buttons/MainButton';
import DateInput from '@/components/common/dataInput/DateInput';
import SelectInput from '@/components/common/dataInput/SelectInput';
import Input from '@/components/common/dataInput/TextInput';
import { isSuperAdmin } from '@/functions/auth';
import Text from '@/components/common/dataDisplay/Text';
import { ExclamationIcon } from '@heroicons/react/outline';
import {
  extractCityAndState,
  returnLocationString,
} from '@/utils/placesLocationUtils';
import moment from 'moment';
import { useEffect, useState } from 'react';
import UserProfile from '@/models/UserProfile';
import alert from '@/utils/UseAlert';
import User, { UserStatusEnum, UserTypeEnum } from '@/models/User';
import { ApiError } from '@/models/Errors';
import ConditionalRenderer from '@/components/common/ConditionalRenderer';
import { createUser, updateUser } from '@/data/services/userServices';
import { updateUserProfile } from '@/data/services/userProfileServices';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { isEmpty, every, some } from 'lodash';
import PlacesAutoCompleteInput from '@/components/common/dataInput/PlacesAutoCompleteInput';
import UsernameInput from '@/components/common/dataInput/UsernameInput';
import { buildChangedObject } from '@/utils/buildChangedObject';
import PasswordAndConfirmInput from '@/components/common/dataInput/PasswordAndConfirmInput';
import InfiniteSearchInput from '@/components/common/dataInput/InfiniteSearchInput';
import useAuth from '@/data/hook/useAuth';
import Unit from '@/models/Unit';
import { isUnitAdmin } from '@/functions/auth';
import { getAuthorizedUnits } from '@/utils/getAuthorizedUnits';
import {
  teachersQuerykeys,
  unitsQueryKeys,
  userProfilesQueryKeys,
} from '@/data/services/querykeys';

export interface TeacherFormProps {
  teacher?: User;
  disabled?: boolean;
  className?: string;
  formMode?: 'edit' | 'create';
  onUpdate?: (userProfile?: UserProfile) => void;
  onCancel?: () => void;
}

export default function TeacherForm(props: TeacherFormProps) {
  const {
    teacher,
    disabled,
    className,
    onUpdate,
    onCancel,
    formMode = 'edit',
  } = props;

  const [hasAlter, setHasAlter] = useState<boolean>(false);

  const { user: authUser } = useAuth();

  const { t } = useTranslation('translation', {
    keyPrefix: 'manageTeacher.addUser',
  });

  const { t: tStatus } = useTranslation('translation', {
    keyPrefix: 'userStatus',
  });

  const { t: tRole } = useTranslation('translation', {
    keyPrefix: 'manageTeam',
  });

  type TeacherForm = Omit<User, 'profile'> &
    Omit<UserProfile, 'user' | 'dateOfBirth'> & {
      confirmPassword: string;
      dateOfBirth: string;
      location: string;
    };
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    watch,
    control,
  } = useForm<TeacherForm>({
    defaultValues: {
      ...teacher,
      dateOfBirth: teacher?.profile.dateOfBirth,
      location: returnLocationString(
        teacher?.profile.city,
        teacher?.profile.state,
      ),
      password: '',
      confirmPassword: '',
    },
  });

  const queryClient = useQueryClient();

  const blockChanges = some(errors, error => !!error?.message);

  const status = [
    {
      text: tStatus(UserStatusEnum.REGISTRED),
      value: UserStatusEnum.REGISTRED,
    },
    {
      text: tStatus(UserStatusEnum.INACTIVE),
      value: UserStatusEnum.INACTIVE,
    },
  ];

  const onEdit = async ({
    firstName,
    lastName,
    username,
    location,
    dateOfBirth,
    email,
    status,
  }: TeacherForm) => {
    if (!teacher) throw new Error('Teacher not found');
    const { city, state } = extractCityAndState(location);

    const parcialProfile = buildChangedObject<UserProfile>(teacher.profile, {
      city,
      state,
      dateOfBirth: dateOfBirth
        ? moment(dateOfBirth).format('YYYY-MM-DD')
        : undefined,
    });

    const parcialUser = buildChangedObject<User>(teacher, {
      firstName,
      lastName,
      username,
      email,
      status,
    });

    !every(parcialUser, isEmpty) && (await updateUser(teacher.id, parcialUser));

    !every(parcialProfile, isEmpty) &&
      (await updateUserProfile(teacher.profile.id, parcialProfile));
  };

  const isMultipleUnits =
    authUser && isUnitAdmin(authUser.userType) && authUser.unitsIds?.length > 1;

  const { data: teacherUnit } = useQuery({
    ...unitsQueryKeys.get(teacher?.unitsIds[0] ?? 0),
    enabled: !!teacher && formMode === 'edit',
  });

  const [selectedUnit, setSelectedUnit] = useState<Unit | undefined>(
    teacherUnit,
  );

  const onCreate = async ({
    firstName,
    lastName,
    username,
    email,
    password,
    location,
    dateOfBirth,
    unitsIds,
  }: TeacherForm) => {
    const { city, state } = extractCityAndState(location);
    const profile: Partial<UserProfile> = {
      city,
      state,
      dateOfBirth: dateOfBirth
        ? moment(dateOfBirth).format('YYYY-MM-DD')
        : undefined,
    };
    await createUser({
      firstName,
      lastName,
      username,
      email,
      password,
      userType: UserTypeEnum.TEACHER,
      unitsIds,
      profile,
    });
  };

  const teachersQueryKey = teachersQuerykeys.list._def;
  const teacherProfileQueryKey = userProfilesQueryKeys.get(
    teacher?.username ?? '',
  ).queryKey;

  const { mutate: update, isLoading: isUpdating } = useMutation(onEdit, {
    onSuccess: () => {
      setHasAlter(false);
      alert.success(t('successEditUser'));
      queryClient.invalidateQueries(teachersQueryKey);
      queryClient.invalidateQueries(teacherProfileQueryKey);
      onCancel?.();
      onUpdate?.();
    },
    onError: (error: any) => {
      const apiError = new ApiError(error);
      alert.error(apiError.getErrorMessage());
    },
  });

  const { mutate: create, isLoading: isCreating } = useMutation(onCreate, {
    onSuccess: () => {
      setHasAlter(false);
      alert.success(t('successCreateUser'));
      queryClient.invalidateQueries(teachersQueryKey);
      onCancel?.();
    },
    onError: (error: any) => {
      const apiError = new ApiError(error);
      alert.error(apiError.getErrorMessage());
    },
  });

  const onSubmit = async (data: TeacherForm) => {
    formMode === 'create' ? create(data) : update(data);
    setHasAlter(false);
  };

  const onReset = () => {
    reset({
      ...teacher,
      dateOfBirth: teacher?.profile.dateOfBirth,
      location: returnLocationString(
        teacher?.profile.city,
        teacher?.profile.state,
      ),
    });
    onCancel?.();
    setHasAlter(false);
  };

  useEffect(() => {
    const subscription = watch(() => setHasAlter(true));
    return () => subscription.unsubscribe();
  }, [watch, setHasAlter]);

  return (
    <form
      onSubmit={handleSubmit(onSubmit)}
      className={`flex flex-col gap-4 ${className || ''}`}
    >
      <ConditionalRenderer condition={formMode === 'create'}>
        <Controller
          name="unitsIds"
          control={control}
          render={({ field: { onChange }, fieldState: { error } }) => (
            <InfiniteSearchInput
              service={unitsQueryKeys.list}
              blockDeselect={isMultipleUnits}
              displayName={unit => unit.name}
              selectedItem={selectedUnit}
              onSelect={unit => {
                const units = [unit.id];
                onChange(units);
                setSelectedUnit(unit);
              }}
              filters={{
                idIn: getAuthorizedUnits(authUser)?.toString(),
                isActive: true,
              }}
              input={{
                label: t('unit'),
                errorLabelText: error?.message,
                labelPosition: 'left',
                disabled,
                testId: 'selectUnit',
                className: { base: 'font-500 text-16' },
              }}
              onDeselect={() => onChange(null)}
            />
          )}
          rules={{
            required: formMode === 'create' ? t('errorMessageUnit') : undefined,
          }}
        />
      </ConditionalRenderer>
      <Input
        label={t('name')}
        register={register('firstName', {
          required: t('errorMessageName'),
        })}
        testId={'firstName'}
        disabled={disabled}
        errorLabelText={errors.firstName?.message}
        labelPosition="left"
        className={{ base: 'font-500 text-16' }}
      />
      <Input
        label={t('lastName')}
        register={register('lastName', {
          required: t('errorMessageLastName'),
        })}
        testId={'lastName'}
        disabled={disabled}
        errorLabelText={errors.lastName?.message}
        labelPosition="left"
        className={{ base: 'font-500 text-16' }}
      />

      <Input
        label={t('email')}
        type="email"
        register={register('email', {
          required: t('errorMessageEmail'),
        })}
        testId={'email'}
        disabled={disabled}
        errorLabelText={errors.email?.message}
        labelPosition="left"
        className={{ base: 'font-500 text-16' }}
      />
      <Controller
        control={control}
        name={'dateOfBirth'}
        render={({ field: { onChange, value } }) => (
          <DateInput
            label={t('birth')}
            labelPosition="left"
            testId="birth"
            value={value}
            className={{ base: 'font-500 text-16' }}
            onDateChange={onChange}
            disabled={disabled}
          />
        )}
      />
      <UsernameInput
        control={control}
        user={teacher}
        disabled={disabled}
        labelPosition="left"
        required
        className={{ base: 'font-500 text-16' }}
      />
      <PlacesAutoCompleteInput
        disabled={disabled}
        testId={'city'}
        placeholder={disabled ? '' : t('selectCity')}
        label={t('city')}
        labelPosition="left"
        control={control}
        name="location"
        className={{ base: 'font-500 text-16' }}
      />

      <ConditionalRenderer condition={formMode === 'create'}>
        <PasswordAndConfirmInput
          control={control}
          disabled={disabled}
          labelPosition="left"
          className={{ base: 'font-500 text-16' }}
        />
      </ConditionalRenderer>
      <div
        role="alert"
        className="alert bg-warning-content text-warning font-500"
      >
        <ExclamationIcon className="w-6 h-6" />
        <Text text={tRole('teacherWarning')} />
      </div>
      <ConditionalRenderer condition={formMode === 'edit'}>
        <SelectInput
          id="select-status"
          testId={'status'}
          label={t('status')}
          labelPosition="left"
          className={{ base: 'font-500 text-16' }}
          defaultValue={tStatus(`${teacher?.status}`)}
          hiddenSelect={disabled}
          register={register('status')}
          disabled={!isSuperAdmin(authUser?.userType)}
        >
          {status.map(({ text, value }) => {
            return (
              <option key={value} value={String(value)}>
                {text}
              </option>
            );
          })}
        </SelectInput>
      </ConditionalRenderer>

      <ConditionalRenderer condition={!disabled}>
        <span className="flex gap-5 self-end">
          <MainButton
            disabled={isUpdating || isCreating}
            type="button"
            icon={<XIcon />}
            text={t('cancelButton')}
            color="neutral"
            onClick={onReset}
            dataTestId={'cancelButton'}
          />
          <MainButton
            disabled={blockChanges || !hasAlter}
            type="submit"
            icon={<SaveIcon />}
            text={t('saveButton')}
            loading={isUpdating || isCreating}
            dataTestId={'saveButton'}
          />
        </span>
      </ConditionalRenderer>
    </form>
  );
}
