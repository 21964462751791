export type OptionsFrequentQuestions = {
  header: string;
  content: string;
};

export const optionsHowItWorksView: OptionsFrequentQuestions[] = [
  {
    header: 'Quais conceitos a criança vai aprender durante as aulas?',
    content:
      'Nossas aulas são sempre muito práticas e divertidas. Os alunos aprendem conceitos de lógica de programação, algoritmos, criação de games e aplicativos e a utilizar circuitos eletrônicos. E por meio de nossa metodologia exclusiva, os alunos desenvolvem diversas habilidades, como raciocínio lógico, criatividade, trabalho em equipe, inglês técnico, diversas disciplinas e comunicação.',
  },
  {
    header: 'Quais ferramentas serão trabalhadas ao longo dos cursos?',
    content:
      'Nosso propósito é formar o aluno para se tornar um cidadão de sucesso no futuro. Observando as habilidades mais importantes levantadas pela ONU e Fórum Econômico Mundial, desenvolvemos nossa metodologia pedagógica para contemplar essas habilidades. Sendo assim, ao longo dos cursos, os alunos irão utilizar ferramentas com linguagens de blocos (parecido com Lego), que serve para facilitar o aprendizado da lógica de programação, e com o passar das aulas, o aluno vai entrando em linguagens de programação de mercado como: Python, HTML5 CSS3, Java Script, JQuery e muitos outros. Nosso intuito não é formar novos programadores, e sim, formar cidadãos com conhecimentos críticos capazes de impactar positivamente o mundo em que vivemos.',
  },
  {
    header: 'Os cursos possuem certificação?',
    content:
      'Sim, ao final de todos os nossos cursos os alunos recebem um certificado de conclusão comprovando as horas cursadas e os conteúdos que foram vistos nas aulas.',
  },
];

export const optionsParentDashboard: OptionsFrequentQuestions[] = [
  {
    header: 'Quais são as formas de pagamento?',
    content: '',
  },
  {
    header: 'Como cancelo o curso do meu filho(a)?',
    content: '',
  },
  {
    header: 'É seguro fazer pagamento online?',
    content: '',
  },
  {
    header: 'O que são as 2 aulas grátis do plano Online Basic?',
    content: '',
  },
];
