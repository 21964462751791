import { useTranslation } from 'react-i18next';
import { DocumentTextIcon, UsersIcon } from '@heroicons/react/outline';

import Popup from '@/components/common/Popup';
import { useActiveStudents } from '@/components/scheduledLessonReport/StudentReports.hook';
import Klass from '@/models/Klass';
import Text from '@/components/common/dataDisplay/Text';
import AvatarName from '@/components/common/dataDisplay/AvatarName';
import { ROUTES } from '@/utils/routes';
import { handleUserFullName } from '@/functions/handleUserFullName';
import { Tooltip } from '@/components/common/dataDisplay/Tooltip';
import IconButton from '@/components/common/buttons/IconButton';
import ConditionalRenderer from '../common/ConditionalRenderer';
import { LoadingIcon } from '../icons';
import { getErrorMessage } from '@/utils/getErrorMessage';
import useToggle from '@/data/hook/useToggle';
import ScheduledLesson from '@/models/ScheduledLesson';

type StudentsPopupProps = {
  klass: Pick<Klass, 'enrollsCount' | 'id'>;
  scheduledLesson?: Pick<ScheduledLesson, 'id' | 'datetime'>;
};

export default function StudentsPopup({
  klass,
  scheduledLesson,
}: StudentsPopupProps) {
  const { t: tStudentsPopup } = useTranslation('translation', {
    keyPrefix: 'teacherDashboard.teacherKlasses',
  });

  const [t] = useTranslation('translation', {
    keyPrefix: 'common',
  });

  const { id: klassId, enrollsCount } = klass;

  const { isOpen, close, open, toggle } = useToggle();

  const {
    enrollments,
    isInitialLoading: loading,
    error,
  } = useActiveStudents({
    klassId,
    scheduledLesson,
    enabled: isOpen,
  });

  return (
    <Popup
      opened={isOpen}
      onToggle={toggle}
      onClose={close}
      onOpen={open}
      reference={
        <span
          data-testid="studentPopupReference"
          className="flex text-primary items-center gap-x-1 cursor-pointer"
        >
          <Text text={enrollsCount} format="rubik-500" />
          <UsersIcon className="h-3.5 stroke-[3]" />
        </span>
      }
    >
      <ul className="flex flex-col gap-1 bg-base-100 rounded-xl p-2 border border-neutral-content shadow-default">
        <ConditionalRenderer condition={loading}>
          <div className="flex w-full justify-center">
            <LoadingIcon className="text-primary/40 w-10" />
          </div>
        </ConditionalRenderer>

        <ConditionalRenderer condition={error}>
          <Text text={getErrorMessage(error)} />
        </ConditionalRenderer>

        <ConditionalRenderer condition={enrollments.length}>
          {enrollments.map(({ student }) => (
            <span
              data-testid="studentPopupItem"
              key={student.id}
              className="flex transition ease-in-out duration-500 gap-2 justify-between"
            >
              <AvatarName
                className="underline text-primary font-500 grow-0 shrink text-start"
                href={ROUTES.ADMIN.STUDENTS.INFO({
                  search: handleUserFullName(student),
                })}
                user={student}
              />

              <Tooltip text={tStudentsPopup('studentSheet')}>
                <IconButton
                  testId={`studentSheetLink${student.id}`}
                  href={ROUTES.ADMIN.STUDENTS.SHEET({
                    search: handleUserFullName(student),
                  })}
                  icon={<DocumentTextIcon className="text-primary w-6 h-6" />}
                />
              </Tooltip>
            </span>
          ))}
        </ConditionalRenderer>

        <ConditionalRenderer
          condition={!enrollments.length && !loading && !error}
        >
          <Text
            text={`${t('noResults')} ¯\\_(ツ)_/¯`}
            className="overflow-hidden self-center text-primary"
          />
        </ConditionalRenderer>
      </ul>
    </Popup>
  );
}
