import { useParams, useLocation } from 'react-router-dom';
import useAuth from '@/data/hook/useAuth';
import { isStudent } from '@/functions/auth';
import AdminStepperContainer from './AdminStepperContainer';
import TeamActivityItem from '../lessons/activities/team/TeamActivityItem';
import StepperContainer from './StepperContainer/StepperContainer';

export default function ActivityView() {
  const { klassId } = useParams();
  const { user } = useAuth();

  if (isStudent(user?.userType)) {
    return <StepperContainer />;
  }
  return !!klassId ? <RenderActivityViewContent /> : <AdminStepperContainer />;
}

const RenderActivityViewContent = () => {
  const location = useLocation();
  const pathname = location.pathname;

  if (pathname.includes('view')) {
    return <AdminStepperContainer />;
  } else return <TeamActivityItem />;
};
