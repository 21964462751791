import { useTranslation } from 'react-i18next';
import { RefreshIcon } from '@heroicons/react/outline';

import Layout from '@/components/template/Layout';
import Feedback from '@/components/common/CannyFeedback';
import PageTitle from '@/components/common/PageTitle';
import MainButton from '@/components/common/buttons/MainButton';

export default function SuggestionsPage() {
  const { t } = useTranslation('translation', {
    keyPrefix: 'suggestionsPage',
  });

  return (
    <Layout>
      <PageTitle
        headingText={t('suggestions')}
        subheadingText={t('subheading')}
      />
      <MainButton
        icon={<RefreshIcon />}
        data-canny-changelog
        text={t('updates')}
        className="mb-2"
      />
      <Feedback path="/sugestoes" />
    </Layout>
  );
}
