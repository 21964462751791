import { Fragment } from 'react';

import ModalChangePassword from '@/components/common/modals/ModalChangePassword';
import { ModalRewards } from '@/components/common/modals/ModalRewards/ModalRewards';
import User from '@/models/User';

export type StudentModalsType = 'alterPassword' | 'rewards';

export type StudentModalState = {
  modal?: StudentModalsType;
  student?: User;
};

type StudentModalsProps = {
  modalState?: StudentModalState;
  onChangeModal?: (modal?: StudentModalsType, student?: User) => void;
};

export default function StudentModals({
  modalState,
  onChangeModal,
}: StudentModalsProps) {
  if (!modalState?.student) return null;

  const { student, modal } = modalState;
  return (
    <Fragment>
      <ModalChangePassword
        user={student}
        visible={modal === 'alterPassword'}
        onClickCancel={() => onChangeModal?.()}
      />
      <ModalRewards
        student={student}
        isVisible={modal === 'rewards'}
        onClickConfirm={() => onChangeModal?.()}
        onClickCancel={() => onChangeModal?.()}
      />
    </Fragment>
  );
}
