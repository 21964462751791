import React, { createContext, useContext } from 'react';
import useAuth from '../hook/useAuth';
import { Avatar } from '@/models/Avatar';
import { useQuery } from '@tanstack/react-query';
import { avatarsQueryKeys } from '../services/querykeys';

type AvatarContextProps = {
  userAvatar: Avatar | undefined;
  isLoadingUserAvatar: boolean;
};

const AvatarContext = createContext<AvatarContextProps>(
  {} as AvatarContextProps,
);

type Props = {
  children: React.ReactNode;
};

export const AvatarProvider = ({ children }: Props) => {
  const { user } = useAuth();
  const { data: userAvatar, isInitialLoading: isLoadingUserAvatar } = useQuery({
    ...avatarsQueryKeys.get(user?.id ?? 0),
    enabled: !!user,
    refetchOnWindowFocus: false,
  });

  return (
    <AvatarContext.Provider
      value={{
        userAvatar,
        isLoadingUserAvatar,
      }}
    >
      {children}
    </AvatarContext.Provider>
  );
};

export const useAvatar = (): AvatarContextProps => useContext(AvatarContext);
