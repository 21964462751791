import requester from '@/axios';
import { VideoActivityElement } from '@/models/ActivityElement';

const baseUrl = 'video-activity';

export type UpdateVideoActivity = {
  videoActivityId: number;
  changes: Partial<Omit<VideoActivityElement, 'id'>>;
};

export const getVideoActivity = async (id: number) => {
  const { data: video } = await requester().get<VideoActivityElement>(
    `${baseUrl}/${id}/`,
  );
  return video;
};

export const deleteVideoActivity = async (questionId: number) => {
  await requester().delete<VideoActivityElement>(`${baseUrl}/${questionId}/`);
};

export const createVideoActivity = async (
  body: Partial<VideoActivityElement>,
) => {
  const { data: videoActivity } = await requester().post<VideoActivityElement>(
    `${baseUrl}/`,
    body,
  );

  return videoActivity;
};

export const updateVideoActivity = async (
  videoActivityId: number,
  changes: Partial<Omit<VideoActivityElement, 'id'>>,
) => {
  await requester().patch<VideoActivityElement>(
    `${baseUrl}/${videoActivityId}/`,
    changes,
  );
};
