import { REQUEST_STALE_TIME_IN_MS } from '@/constants';
import useInfiniteService from '@/data/hook/useInfiniteService';
import useListService from '@/data/hook/useListService';
import {
  enrollmentsQueryKeys,
  scheduledLessonReportsQueryKeys,
} from '@/data/services/querykeys';
import {
  activeEnrollmentStatus,
  inactiveEnrollmentStatus,
} from '@/functions/enrollment';
import Enrollment, { EnrollmentStatusEnum } from '@/models/Enrollment';
import ScheduledLesson from '@/models/ScheduledLesson';
import ScheduledLessonReport, {
  ScheduledLessonReportEnum,
} from '@/models/ScheduledLessonReport';
import moment from 'moment';

type ScheduledLessonInfo = Pick<ScheduledLesson, 'datetime' | 'id'>;

type useReportsActiveProps = {
  klassId: number;
  scheduledLesson?: ScheduledLessonInfo;
  active?: boolean;
  enabled?: boolean;
};

export type EnrollmentReports = {
  enrollment: Enrollment;
  report?: ScheduledLessonReport;
};

export const useActiveStudents = ({
  klassId,
  scheduledLesson,
  active = true,
  enabled = true,
}: useReportsActiveProps) => {
  const pageSize = 50;
  const { results: enrollmentsResults, ...enrollmentsProps } =
    useInfiniteService({
      ...enrollmentsQueryKeys.list({
        klassId: klassId,
        pageSize,
      })._ctx.infinity,
      enabled: !!klassId && !!enabled,
      staleTime: REQUEST_STALE_TIME_IN_MS,
    });

  const scheduledLessonId = scheduledLesson?.id;

  const { results: reports } = useListService({
    ...scheduledLessonReportsQueryKeys.list({
      scheduledLesson: scheduledLessonId,
      status: [ScheduledLessonReportEnum.ACTIVE],
      pageSize,
    }),
    staleTime: REQUEST_STALE_TIME_IN_MS,
    enabled: !!scheduledLessonId && !!enabled,
  });

  const inactiveStudents: Enrollment[] = [];

  const activeStudents: Enrollment[] = [];

  function isStudentActiveInLesson(
    enrollment: Enrollment,
    lessonDatetime: string | undefined,
  ) {
    if (!lessonDatetime) {
      return false;
    }
    const isInactiveStatus = inactiveEnrollmentStatus.includes(
      enrollment.status,
    );
    if (isInactiveStatus) {
      const enrollmentUpdatedDatetime = moment(enrollment.updatedAt);
      return enrollmentUpdatedDatetime.isAfter(lessonDatetime);
    }
    return true;
  }

  function isEnrollmentActive(status: EnrollmentStatusEnum) {
    return status === EnrollmentStatusEnum.ACTIVE;
  }

  function getMostRecentEnrollment(
    enrollment1: Enrollment,
    enrollment2: Enrollment,
  ) {
    if (moment(enrollment1.updatedAt).isAfter(enrollment2.updatedAt)) {
      return enrollment1;
    }
    return enrollment2;
  }

  function addActiveStudent(enrollment: Enrollment) {
    const isActive = isEnrollmentActive(enrollment.status);
    const studentEnrollmentIndex = activeStudents.findIndex(({ student }) =>
      isSameStudent({ student }, enrollment),
    );
    if (studentEnrollmentIndex === -1) {
      activeStudents.push(enrollment);
      return;
    }
    if (isActive) {
      activeStudents[studentEnrollmentIndex] = enrollment;
      return;
    }
    const studentEnrollment = activeStudents[studentEnrollmentIndex];
    if (isEnrollmentActive(studentEnrollment.status)) {
      return;
    }
    const recentEnrollment = getMostRecentEnrollment(
      enrollment,
      studentEnrollment,
    );
    activeStudents[studentEnrollmentIndex] = recentEnrollment;
  }
  function isSameStudent(
    { student }: Pick<Enrollment, 'student'>,
    targetEnrollment: Enrollment,
  ) {
    return student.id === targetEnrollment.student.id;
  }
  function addInactiveStudent(enrollment: Enrollment) {
    const enrollmentIndex = inactiveStudents.findIndex(({ student }) =>
      isSameStudent({ student }, enrollment),
    );
    if (enrollmentIndex === -1) {
      inactiveStudents.push(enrollment);
      return;
    }
    const studentEnrollment = inactiveStudents[enrollmentIndex];
    const recentEnrollment = getMostRecentEnrollment(
      enrollment,
      studentEnrollment,
    );
    inactiveStudents[enrollmentIndex] = recentEnrollment;
  }

  for (const enrollment of enrollmentsResults) {
    const activeEnrollment = activeEnrollmentStatus.includes(enrollment.status);

    if (
      activeEnrollment ||
      isStudentActiveInLesson(enrollment, scheduledLesson?.datetime)
    )
      addActiveStudent(enrollment);
    else addInactiveStudent(enrollment);
  }

  return {
    ...enrollmentsProps,
    enrollments: active ? activeStudents : inactiveStudents,
    reports,
  };
};
