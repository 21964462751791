import { UsersIcon, AcademicCapIcon } from '@heroicons/react/outline';

import Tag from '../common/dataDisplay/Tag';
import Text from '../common/dataDisplay/Text';
import useStatisticService from '@/data/hook/useStatistics';
import { teacherStudentsCountQueryKeys } from '@/data/services/querykeys';
import Skeleton from '../common/Skeleton';

type TeacherSummaryCountProps = {
  teacherId?: number;
  unitId?: number[];
};

export function TeacherSummaryCount({
  teacherId,
  unitId,
}: TeacherSummaryCountProps) {
  const filters = {
    teacherId: teacherId ? [teacherId] : undefined,
    unitId,
  };

  const { statistic, isInitialLoading: loading } = useStatisticService(
    teacherStudentsCountQueryKeys.list(filters),
  );

  const sectionClassname = 'flex text-14 gap-0.5 items-center';

  if (loading)
    return <Skeleton className="w-36 h-6 bg-primary-content rounded-xl" />;

  if (statistic)
    return (
      <Tag
        color="custom"
        className="px-2.5 py-1 bg-primary text-base-100 gap-2.5 items-center"
      >
        <Text format="rubik-500" text="Total" />
        <section className={sectionClassname}>
          <Text format="rubik-500" text={statistic.studentsCount} />
          <UsersIcon className="w-3.5" />
        </section>
        <section className={sectionClassname}>
          <Text format="rubik-500" text={statistic.klassesCount} />
          <AcademicCapIcon className="w-3.5" />
        </section>
      </Tag>
    );

  return null;
}
