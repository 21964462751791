import { REQUEST_STALE_TIME_IN_MS } from '@/constants';
import { scheduledLessonsQueryKeys } from '@/data/services/querykeys';
import { Alert } from '@/models/Alert';
import { useQuery } from '@tanstack/react-query';
import { createMessage } from '../AlertMessageFactory';
import useAuth from '@/data/hook/useAuth';
import { handleUserFullName } from '@/functions/handleUserFullName';

export const useAlertMessage = (alert: Alert) => {
  const { user } = useAuth();

  const { data: scheduledLesson, isInitialLoading } = useQuery({
    ...scheduledLessonsQueryKeys.get(alert.targetId),
    staleTime: REQUEST_STALE_TIME_IN_MS,
    enabled: !!alert.targetId,
  });

  if (!scheduledLesson)
    return {
      message: '',
      isInitialLoading,
    };

  const message = createMessage({
    type: alert.type,
    body: scheduledLesson,
    recipient: alert.actor.title,
    sender: handleUserFullName(user),
  });

  return {
    message,
    isInitialLoading,
  };
};
