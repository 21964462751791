import { LessonFormType } from '@/components/forms/LessonForm';
import { listAreaParents } from '@/data/services/areaServices';
import { tagsQueryKeys } from '@/data/services/querykeys';
import Tag from '@/models/Tag';
import { extractParents, mountTopicTagName } from '@/utils/area';
import { useQuery } from '@tanstack/react-query';
import { useEffect, useState } from 'react';
import { Control, useFieldArray } from 'react-hook-form';

export const useInitTags = (
  tagsIds: number[],
  control?: Control<LessonFormType>,
) => {
  const { replace } = useFieldArray({
    control,
    name: 'tags',
  });

  const [isLoadingParents, setIsLoadingParents] = useState(true);

  const { data, isInitialLoading } = useQuery(
    tagsQueryKeys.list({ id: tagsIds }),
  );

  const tags: Tag[] = data?.results || [];

  const tagsListOptm = JSON.stringify(tags);

  useEffect(() => {
    const fetchTags = async () => {
      const tagsList = JSON.parse(tagsListOptm) as Tag[];
      if (tagsList.length !== tagsIds.length) return;
      const tagsWithNameRequests = tagsList.map(async tag => {
        const parentsRequest = await listAreaParents(tag.areaId);

        const parentsExtract = extractParents(parentsRequest.results[0]);
        return {
          ...tag,
          name: mountTopicTagName(tag, parentsExtract),
        };
      });
      const tagsWithName = await Promise.all(tagsWithNameRequests);
      replace(tagsWithName);
      setIsLoadingParents(false);
    };
    fetchTags();
  }, [tagsListOptm, tagsIds, replace]);

  return { isLoading: isInitialLoading || isLoadingParents };
};
