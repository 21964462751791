import { AnimatePresence, motion } from 'framer-motion';

import UserForm from './StudentForm';
import { accordion } from '../../../utils/animations/commom';
import { UserTypeEnum } from '@/models/User';
import ComponentGuard from '@/components/common/ComponentGuard';

export default function AddStudent({
  show,
  setShow,
}: {
  show: boolean;
  setShow: (show: boolean) => void;
}) {
  return (
    <ComponentGuard roles={[UserTypeEnum.SUPER_ADMIN]}>
      <div className={show ? 'overflow-visible' : 'overflow-hidden'}>
        <AnimatePresence>
          {show && (
            <motion.div className="flex justify-end" {...accordion}>
              <UserForm
                formMode="create"
                className="py-2 w-[600px]"
                onCancel={() => setShow(false)}
              />
            </motion.div>
          )}
        </AnimatePresence>
      </div>
    </ComponentGuard>
  );
}
