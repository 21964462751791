import { useTranslation } from 'react-i18next';
import useCourseEditing from '../../../data/hook/useCourseEditing';
import CoursePath from '../../../models/Course';
import { Lesson } from '../../../models/Lesson';
import MainButton from '../../common/buttons/MainButton';
import TooltipHandler from '../../common/TooltipHandler';
import { TrashIcon } from '@heroicons/react/outline';
import LessonForm from '../../forms/LessonForm';
import useAuth from '../../../data/hook/useAuth';
import { UserTypeEnum } from '../../../models/User';

interface EditLessonProps {
  lesson: Lesson;
  course: CoursePath;
  updateLessons: () => Promise<void>;
  deleteLesson(lesson: Lesson): void;
}

export default function LessonDetails({
  lesson,
  course,
  updateLessons,
  deleteLesson,
}: EditLessonProps) {
  const { user } = useAuth();
  const { t } = useTranslation();
  const { allowBigChanges } = useCourseEditing();

  const { t: editRulesT } = useTranslation('translation', {
    keyPrefix: 'adminPage.editCourseRules',
  });

  return (
    <div className="flex flex-col gap-4">
      <LessonForm
        slugCourseName={course.slug}
        lesson={lesson}
        updateLessons={updateLessons}
        userType={user?.userType ?? UserTypeEnum.SUPER_ADMIN}
      />

      <hr className="h-px my-4 border-secondary-content" />

      <TooltipHandler
        renderTooltip={!allowBigChanges}
        tooltipMessage={editRulesT('cantModify')}
      >
        <MainButton
          className="flex self-end"
          disabled={!allowBigChanges}
          color="warning"
          icon={<TrashIcon />}
          onClick={() => deleteLesson(lesson)}
          text={t('adminPage.lesson.lessonDetails.deleteLesson')}
        />
      </TooltipHandler>
    </div>
  );
}
