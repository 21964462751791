import PageTitle from '@/components/common/PageTitle';
import { RenderRewards } from '@/components/common/dataDisplay/RewardsView';
import Text from '@/components/common/dataDisplay/Text';
import InfiniteSearchInput from '@/components/common/dataInput/InfiniteSearchInput';
import { HistoricTagGroup } from '@/components/historic/HistoricCourseTag';
import { HistoricPanel } from '@/components/historic/HistoricPanel';
import AvatarCharacter from '@/components/store/AvatarCharacter';
import Layout from '@/components/template/Layout';
import useAuth from '@/data/hook/useAuth';
import useListService from '@/data/hook/useListService';
import {
  avatarsQueryKeys,
  rewardsQueryKeys,
  simplifiedCourseProgressesQueryKeys,
  usersQueryKeys,
} from '@/data/services/querykeys';
import { isTeacher } from '@/functions/auth';
import { handleUserFullName } from '@/functions/handleUserFullName';
import { UserTypeEnum } from '@/models/User';
import { getAuthorizedUnits } from '@/utils/getAuthorizedUnits';
import { useQuery } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';

export default function StudentHistoryPage() {
  const { t } = useTranslation('translation', {
    keyPrefix: 'studentHistoric',
  });
  const { studentId: studentIdParam } = useParams();
  const { user: authUser } = useAuth();
  const navigate = useNavigate();

  const studentId = Number(studentIdParam);
  const defaultQueryKeys = {
    enabled: !!studentId && !isNaN(studentId),
  };

  const { data: student } = useQuery({
    ...defaultQueryKeys,
    ...usersQueryKeys.get(studentId),
  });

  const { results: progress, isInitialLoading: isLoadingProgress } =
    useListService({
      ...simplifiedCourseProgressesQueryKeys.list(studentId),
    });

  const { data: userAvatar, isInitialLoading: isLoadingAvatar } = useQuery({
    ...defaultQueryKeys,
    ...avatarsQueryKeys.get(studentId),
  });

  const { data: rewards, isInitialLoading: isLoadingRewards } = useQuery({
    ...defaultQueryKeys,
    ...rewardsQueryKeys.get(studentId),
  });

  return (
    <Layout>
      <div className="flex flex-col gap-3 lg:gap-5">
        <div className="flex flex-col gap-2 lg:flex-row lg:justify-between w-full lg:items-center h-fit">
          <PageTitle headingText={t('title')} position="mb-0" />
          <InfiniteSearchInput
            service={usersQueryKeys.list}
            selectedItem={student}
            onSelect={({ id }) => navigate(`/admin/student-history/${id}`)}
            displayName={user => handleUserFullName(user)}
            input={{
              testId: 'studentSelect',
              className: { base: 'max-w-96 md:w-96' },
            }}
            filters={{
              userType: [UserTypeEnum.STUDENT],
              unit: getAuthorizedUnits(authUser),
              teacher: isTeacher(authUser?.userType) ? authUser?.id : undefined,
            }}
            className={{ base: 'w-fit flex self-end' }}
            blockDeselect
          />
        </div>
        <div className="flex flex-col lg:flex-row gap-3 lg:gap-5">
          <div className="flex flex-row lg:flex-col w-full lg:w-60 max-h-24 lg:max-h-none lg:h-fit bg-base-100 border border-primary-content rounded-xl items-center justify-between lg:justify-normal p-3 lg:p-5 lg:gap-3 relative">
            <div className="flex flex-col md:flex-row lg:flex-col w-28 lg:w-full gap-1.5 md:gap-3 items-center">
              <AvatarCharacter
                className="w-auto h-10 lg:w-full aspect-square lg:h-auto"
                isLoading={isLoadingAvatar}
                userAvatar={userAvatar}
              />
              <Text
                format="rubik-500"
                className="text-center"
                text={handleUserFullName(student)}
              />
            </div>
            <RenderRewards
              rewardsData={rewards}
              isFetchingRewards={isLoadingRewards}
            />
            <HistoricTagGroup
              progress={progress}
              isLoading={isLoadingProgress}
              className="flex-wrap"
            />
          </div>
          <HistoricPanel key={studentId} studentId={Number(studentId)} />
        </div>
      </div>
    </Layout>
  );
}
