import { CheckCircleIcon } from '@heroicons/react/solid';
import { useTranslation } from 'react-i18next';
import { useMutation } from '@tanstack/react-query';
import { useForm } from 'react-hook-form';

import alert from '@/utils/UseAlert';
import Modal from '@/components/common/modals/Modal';
import RoundedButton from '@/components/common/buttons/RoundedButton';
import { updateScheduledLesson } from '@/data/services/scheduledLessonsServices';
import Text from '@/components/common/dataDisplay/Text';
import TextInput from '@/components/common/dataInput/TextInput';
import { getErrorMessage } from '@/utils/getErrorMessage';
import ScheduledLesson from '@/models/ScheduledLesson';

interface UpdateKlassLinkProps {
  onClickCancel: () => void;
  klassId: number;
  scheduledLesson: number;
  klassLink?: string;
  updateScheduledLessons?: (a?: any) => Promise<void>;
}

export default function UpdateKlassLink({
  klassLink,
  scheduledLesson: scheduledLessonId,
  onClickCancel,
  ...rest
}: UpdateKlassLinkProps) {
  const [t] = useTranslation('translation', {
    keyPrefix: 'common',
  });

  const { t: tComponent } = useTranslation('translation', {
    keyPrefix: 'calendar.admin.manageKlass.updateKlassLink',
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
    watch,
  } = useForm<Partial<ScheduledLesson>>({
    defaultValues: { url: klassLink },
  });

  type UpdateLinkMutation = {
    scheduledLessonId: number;
    changes: Partial<ScheduledLesson>;
  };

  const { mutate: update, isLoading: updating } = useMutation(
    ({ scheduledLessonId, changes }: UpdateLinkMutation) =>
      updateScheduledLesson(scheduledLessonId, changes),
    {
      async onSuccess() {
        await rest.updateScheduledLessons?.();
        alert.success(t('saved'));
        onClickCancel();
      },
      onError(error) {
        alert.error(getErrorMessage(error));
      },
    },
  );

  const replaceUrl = (url?: string) =>
    `https://${url?.replace(/^https?:\/\//, '')}`;

  const onSubmit = async (changes: Partial<ScheduledLesson>) =>
    update({
      scheduledLessonId,
      changes: { url: replaceUrl(changes.url) },
    });

  const watchUrl = watch('url');

  return (
    <Modal
      visible
      onClose={onClickCancel}
      className="flex flex-col w-full gap-4"
    >
      <Text
        className="text-primary text-18 font-500"
        text={tComponent('updateKlassLinkTitle')}
      />

      <Text className="font-500" text={klassLink} />

      <form onSubmit={handleSubmit(onSubmit)} className="flex flex-col gap-4">
        <TextInput
          errorLabelText={errors.url?.message}
          register={register('url')}
          icon={<CheckCircleIcon />}
        />

        <div className="flex w-full justify-around gap-6">
          <RoundedButton
            type="button"
            text={t('cancel')}
            color="neutral"
            className="w-full"
            onClick={onClickCancel}
          />
          <RoundedButton
            type="submit"
            text={t('confirm')}
            className="w-full"
            loading={updating}
            onClick={handleSubmit(onSubmit)}
            disabled={watchUrl === '' || !watchUrl}
          />
        </div>
      </form>
    </Modal>
  );
}
