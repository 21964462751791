import { MANAGEMENT_PAGE_SIZE } from '@/constants';
import { ListAlertsFilters } from '@/data/services/alertServices';
import { useTranslation } from 'react-i18next';
import ConditionalRenderer from '../../common/ConditionalRenderer';
import Text from '../../common/dataDisplay/Text';
import InfinityList from '../../common/InfinityList';
import { LoadingIcon } from '../../icons';
import NotificationFactory from '../item/factory/NotificationFactory';
import { useSoundAlert } from '@/data/hook/useSoundAlert';
import { useFaviconAlert } from '@/data/hook/useFaviconAlert';
import SoundAlert from './SoundAlert';
import { useAlertsService } from './NotificationsList.hooks';

type ListNotifications = {
  filters: ListAlertsFilters;
};

export default function NotificationsList({
  filters: filtersProp,
}: ListNotifications) {
  const { t } = useTranslation('translation', {
    keyPrefix: 'alerts.list',
  });

  const filters = {
    ...filtersProp,
    pageSize: MANAGEMENT_PAGE_SIZE,
  };
  const { ref, playSound } = useSoundAlert();

  const { changeFavicon } = useFaviconAlert();
  const { alerts, isInitialLoading, fetchNextPage, hasNextPage } =
    useAlertsService({
      filters,
      onHasDifference: () => {
        playSound();
        changeFavicon();
      },
    });

  return (
    <div
      className="w-full pt-2 flex flex-col gap-2.5 overflow-hidden"
      data-testid="notificationsList"
    >
      <SoundAlert ref={ref} />
      <ConditionalRenderer condition={isInitialLoading}>
        <div className="flex justify-center w-full text-primary">
          <LoadingIcon className="w-5 h-5" />
        </div>
      </ConditionalRenderer>
      <ConditionalRenderer condition={alerts.length === 0 && !isInitialLoading}>
        <div className="flex justify-center w-full text-primary">
          <Text text={t('empty')} className="text-base-content" />
        </div>
      </ConditionalRenderer>
      <InfinityList
        className="flex flex-col gap-1.5 h-full pr-2 w-full"
        onReachEnd={fetchNextPage}
        scroll
        hasNextPage={hasNextPage}
      >
        {alerts.map(notification => (
          <NotificationFactory
            key={notification.id}
            filters={filters}
            notification={notification}
          />
        ))}
      </InfinityList>
    </div>
  );
}
