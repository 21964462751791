import { CourseDurationEnum, CourseDurationMinuteEnum } from '../models/Course';

export const courseDurationNumberForString = (minute: number | undefined) => {
  if (minute) {
    const keysEnum = Object.values(CourseDurationMinuteEnum).filter(
      value => typeof value === 'string',
    );

    const keyValue = keysEnum.find(
      keyEnum =>
        CourseDurationMinuteEnum[
          keyEnum as keyof typeof CourseDurationMinuteEnum
        ] === minute,
    );

    return CourseDurationEnum[keyValue as keyof typeof CourseDurationEnum];
  }

  return '';
};
