import ComponentGuard from '@/components/common/ComponentGuard';
import { UserTypeEnum } from '@/models/User';
import LessonProgress from '@/models/LessonProgress';
import {
  activityNotification,
  bookNotification,
  challengeNotification,
  homeworkNotification,
  toolUrlNotification,
  watchedInstructionNotification,
} from '@/utils/lessonProgressNotifications';
import ConditionalRenderer from '@/components/common/ConditionalRenderer';
import LessonIconContainer, {
  IconType,
} from '@/components/common/LessonIconContainer';
import ActivityProgress from '@/models/ActivityProgress';
import BookProgress from '@/models/BookProgress';
import { Lesson } from '@/models/Lesson';
import ScheduledLesson from '@/models/ScheduledLesson';
import { setClassroomPath } from '@/utils/setClassroomPath';
import { LessonBarProps } from './LessonBar';

type LessonBarIconsProps = LessonBarProps & {
  lesson: Lesson;
  scheduledLesson?: ScheduledLesson;
  slugCourseName?: string;
  lessonProgress?: LessonProgress;
  hideProgress?: boolean;
};

export default function LessonBarIcons({
  lesson,
  lessonProgress,
  scheduledLesson,
  blocked,
  slugCourseName,
  hideProgress,
  klassId,
}: LessonBarIconsProps) {
  const baseUrl = `/courses/${slugCourseName}/lesson/${lesson.id}`;

  const block = !slugCourseName || blocked;

  const lessonRoute = (route?: string) =>
    block
      ? ''
      : setClassroomPath({
          baseUrl,
          klassId,
          route,
        });

  return (
    <div className="hidden lg:flex gap-4">
      <LessonBarToolIcon
        href={lessonRoute('/help')}
        toolUrl={lesson.toolUrl}
        instructionsUrl={lesson.instructionsUrl}
        lessonProgress={lessonProgress}
      />

      <LessonBarBookIcon
        bookId={lesson.book}
        href={lessonRoute('/book/chapter/1')}
        bookProgress={lessonProgress?.bookProgress}
        type="studentBook"
        freeLesson={hideProgress}
      />

      <ComponentGuard
        roles={[
          UserTypeEnum.TEACHER,
          UserTypeEnum.SUPER_ADMIN,
          UserTypeEnum.UNIT_ADMIN,
        ]}
      >
        <LessonBarBookIcon
          bookId={lesson.teacherBook}
          href={lessonRoute('/t/book/chapter/1')}
          type="teacherBook"
        />
      </ComponentGuard>

      <LessonBarActivityIcon
        href={lessonRoute('/activities')}
        activities={lesson.activities}
        homeworkId={scheduledLesson?.homeworkActivity}
        lessonProgress={lessonProgress}
      />

      <LessonBarChallengeIcon
        challengeId={lesson.challenge}
        challengeProgress={lessonProgress?.challengeProgress}
        href={lessonRoute('/challenge')}
      />
    </div>
  );
}

type LessonBarToolIconProps = {
  toolUrl?: string | null;
  instructionsUrl?: string | null;
  lessonProgress?: LessonProgress;
  href: string;
};

export function LessonBarToolIcon({
  toolUrl,
  instructionsUrl,
  lessonProgress,
  href,
}: LessonBarToolIconProps) {
  const renderCondition = !!toolUrl || !!instructionsUrl;

  const notifications = lessonProgress
    ? toolUrlNotification(lessonProgress.hasAccessedTool, toolUrl) +
      watchedInstructionNotification(
        lessonProgress.hasWatchedInstruction ?? true,
        instructionsUrl,
      )
    : undefined;

  return (
    <ConditionalRenderer condition={renderCondition}>
      <LessonIconContainer
        testId="accessIcon"
        notification={notifications}
        type="access"
        hasTooltip
        href={href}
      />
    </ConditionalRenderer>
  );
}

type LessonBarChallengeIconProps = {
  challengeId: number | null;
  challengeProgress?: ActivityProgress | null;
  href: string;
};

export function LessonBarChallengeIcon({
  challengeId,
  challengeProgress,
  href,
}: LessonBarChallengeIconProps) {
  const renderCondition = !!challengeId;

  const notifications = challengeProgress
    ? challengeNotification(challengeProgress)
    : undefined;

  return (
    <ConditionalRenderer condition={renderCondition}>
      <LessonIconContainer
        testId="challengeIcon"
        type="challenge"
        notification={notifications}
        href={href}
        hasTooltip
      />
    </ConditionalRenderer>
  );
}

type LessonBarActivityIconProps = {
  activities: number[];
  homeworkId?: number | null;
  lessonProgress?: LessonProgress;
  href: string;
};

export function LessonBarActivityIcon({
  activities,
  homeworkId,
  lessonProgress,
  href,
}: LessonBarActivityIconProps) {
  const renderCondition = !!activities.length || !!homeworkId;

  const notifications = lessonProgress
    ? activityNotification(lessonProgress.activitiesProgress) +
      homeworkNotification(lessonProgress.homeworkProgress)
    : undefined;

  return (
    <ConditionalRenderer condition={renderCondition}>
      <LessonIconContainer
        testId="activityIcon"
        notification={notifications}
        type="activity"
        hasTooltip
        href={href}
      />
    </ConditionalRenderer>
  );
}

type LessonBarBookIconProps = {
  bookId: number | null;
  bookProgress?: BookProgress;
  href: string;
  type: IconType;
  freeLesson?: boolean;
};

export function LessonBarBookIcon({
  bookId,
  bookProgress,
  href,
  type,
  freeLesson,
}: LessonBarBookIconProps) {
  const renderCondition = !!bookId;

  const notifications = bookProgress
    ? bookNotification(bookProgress, freeLesson)
    : undefined;

  return (
    <ConditionalRenderer condition={renderCondition}>
      <LessonIconContainer
        testId={type + 'Icon'}
        type={type}
        notification={notifications}
        hasTooltip
        href={href}
        hidePercent={bookProgress ? false : true}
      />
    </ConditionalRenderer>
  );
}
