import { useEffect, useRef, useState } from 'react';
import YouTube, { YouTubeEvent } from 'react-youtube';
import ErrorComponent from './ErrorComponent';
import { useTranslation } from 'react-i18next';

interface YoutubePlayerProps {
  videoUrl: string;
  onVideoProgress?(currentProgress: number): Promise<void> | void;
  shouldWatchProgress?: boolean;
}

function YoutubePlayer({
  videoUrl,
  onVideoProgress,
  shouldWatchProgress,
}: YoutubePlayerProps) {
  const [isPlaying, setIsPlaying] = useState(false);
  const playerRef = useRef<YT.Player | null>(null);
  const onReady = (event: YouTubeEvent) => {
    playerRef.current = event.target;
  };

  const { t } = useTranslation('translation', {
    keyPrefix: 'klassView.instruction',
  });
  function handleYtVideoId(videoUrl?: string) {
    if (!videoUrl) return;
    if (videoUrl?.includes('youtu.be/')) {
      const videoId = videoUrl.split('youtu.be/')[1];
      return videoId.split('?')[0];
    } else if (!videoUrl.includes('youtube.com')) {
      return videoUrl;
    }
    const regexPattern =
      /(?:youtube\.com\/(?:[^/]+\/.+\/|(?:v|e(?:mbed)?)\/|\S*?[?&]v=)|youtu\.be\/)([\w-]{11})/;
    const match = videoUrl.match(regexPattern);
    return match ? match[1] : undefined;
  }
  const videoId = handleYtVideoId(videoUrl);
  const getVideoProgress = () => {
    const currentTime = playerRef.current?.getCurrentTime();
    const duration = playerRef.current?.getDuration();

    return currentTime && duration ? currentTime / duration : 0;
  };
  useEffect(() => {
    const watchVideoProgress = () => {
      onVideoProgress?.(getVideoProgress());
    };
    if (isPlaying && shouldWatchProgress && onVideoProgress) {
      const interval = setInterval(watchVideoProgress, 5 * 1000);
      return () => clearInterval(interval);
    }
  }, [isPlaying, onVideoProgress, shouldWatchProgress]);

  if (!videoId) {
    return (
      <ErrorComponent
        errorTextSubTitle={t('errorTextSubTitle')}
        errorTextTitle={t('errorTextTitle')}
      />
    );
  }

  return (
    <YouTube
      videoId={videoId}
      onReady={onReady}
      onPlay={() => setIsPlaying(true)}
      onPause={() => setIsPlaying(false)}
    />
  );
}

export default YoutubePlayer;
