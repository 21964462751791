export const pathChecker = (): boolean => {
  let isIncludedPath: boolean = false;
  const pathname = window.location.pathname;

  if (pathname.includes('lesson')) isIncludedPath = true;
  else if (pathname.includes('/scheduledLessonReport')) isIncludedPath = true;
  else isIncludedPath = false;

  return isIncludedPath;
};
