import { useEffect, useState } from 'react';
import { motion } from 'framer-motion';
import { isEmpty } from 'lodash';
import { Controller, useForm } from 'react-hook-form';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import {
  PlusIcon,
  ChevronDownIcon,
  ExclamationCircleIcon,
  XIcon,
  CheckIcon,
  UploadIcon,
} from '@heroicons/react/outline';
import { AnimatePresence } from 'framer-motion';
import { useTranslation } from 'react-i18next';

import Text from '@/components/common/dataDisplay/Text';
import MyCkeditor from '../editor/MyCkeditor';
import { ImageFolderEnum } from '@/models/CkEditor';
import MainButtonDisplay from '@/components/common/dataDisplay/MainButtonDisplay';
import ModalWarning from '@/components/common/modals/ModalWarning';
import { DashedCircleIcon } from '../icons';
import ScheduledLesson from '@/models/ScheduledLesson';
import { HomeworkGradeMarker } from './HomeworkGradeMarker';
import { AssignmentType, HomeworkActivity } from '@/models/HomeworkActivity';
import { updateScheduledLesson } from '@/data/services/scheduledLessonsServices';
import alert from '@/utils/UseAlert';
import { ApiError } from '@/models/Errors';
import {
  createHomeworkActivity,
  updateHomeworkActivity,
} from '@/data/services/homeworkActivityServices';
import { createScheduledLessonMgActivity } from '@/data/services/mgActivityServices';
import SaveCancelGroup from '@/components/common/buttons/SaveCancelGroup';
import ConditionalRenderer from '@/components/common/ConditionalRenderer';
import { accordion } from '@/utils/animations/commom';
import {
  homeworkActivitiesQueryKeys,
  homeworkProgressQueryKeys,
  scheduledLessonsQueryKeys,
} from '@/data/services/querykeys';

interface HomeworkEditProps {
  klassId: number;
  scheduledLesson: ScheduledLesson;
  homeworkActivity?: HomeworkActivity;
}

export const HomeworkEdit = ({
  klassId,
  homeworkActivity,
  scheduledLesson,
}: HomeworkEditProps) => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'manageScheduledLessonReport',
  });
  interface HomeworkActivityForm {
    notHasHomeworkActivity: boolean;
    content: string;
    hasSubmission: boolean;
  }
  const { control, handleSubmit, watch, setValue, register } =
    useForm<HomeworkActivityForm>();
  const queryClient = useQueryClient();
  const content = watch('content');
  const withoutHomework = watch('notHasHomeworkActivity');

  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [hasChanges, setHasChanges] = useState<boolean>(false);

  const [isOpenModal, setIsOpenModal] = useState<boolean>(false);

  const onConfirmModal = async ({
    hasSubmission,
    content,
  }: HomeworkActivityForm) => {
    if (isEmpty(content)) throw new Error('emptyContent');

    const homeworkActivityId = homeworkActivity?.id;
    const assignmentType = hasSubmission
      ? AssignmentType.TEXT
      : AssignmentType.NOTHING;
    if (homeworkActivityId) {
      await updateHomeworkActivity(homeworkActivityId, {
        content,
        assignmentType,
      });
    } else {
      if (!scheduledLesson) return;
      let mgActivityId = scheduledLesson.homeworkActivity;
      if (!mgActivityId) {
        const mgActivity = await createScheduledLessonMgActivity(
          scheduledLesson?.id,
        );
        mgActivityId = mgActivity.id;
      }
      await createHomeworkActivity({
        activity: mgActivityId as number,
        assignmentType,
        content,
        isActive: true,
      });
    }
  };
  const { mutate, isLoading } = useMutation(handleSubmit(onConfirmModal), {
    onSuccess() {
      setIsOpen(false);
      setIsOpenModal(false);
      alert.success(t('savedHomework'));
      const homeworkQueryKey = homeworkActivitiesQueryKeys.list._def;
      queryClient.invalidateQueries(homeworkQueryKey);
      queryClient.invalidateQueries(homeworkProgressQueryKeys.list._def);
      return queryClient.invalidateQueries(
        scheduledLessonsQueryKeys.get(scheduledLesson.id).queryKey,
      );
    },
    onError(error: any) {
      if (error?.message === 'emptyContent') {
        alert.error(t('emptyContent'));
        return;
      }
      const apiError = new ApiError(error);
      alert.error(apiError.getErrorMessage());
    },
    onSettled() {
      setIsOpenModal(false);
    },
  });

  interface HandleHomeworkParams {
    scheduledLessonId: number;
    changes: Partial<ScheduledLesson>;
  }

  const { mutate: handleHasHomework, isLoading: isLoadingHasHomework } =
    useMutation(
      ({ scheduledLessonId, changes }: HandleHomeworkParams) =>
        updateScheduledLesson(scheduledLessonId, changes),
      {
        onSuccess() {
          alert.success(t('saved'));
          queryClient.invalidateQueries(homeworkProgressQueryKeys.list._def);
          return queryClient.invalidateQueries(
            scheduledLessonsQueryKeys.get(scheduledLesson.id),
          );
        },
        onError(error: any) {
          const apiError = new ApiError(error);
          alert.error(apiError.getErrorMessage());
        },
        onSettled() {
          setIsOpenModal(false);
        },
      },
    );

  const setButtonDesign = () => {
    if (content && content !== homeworkActivity?.content) {
      return {
        className: 'text-warning bg-warning-content border-warning',
        icon: <DashedCircleIcon className="w-4 h-4 text-warning" />,
        text: t('draft'),
      };
    }

    if (content === homeworkActivity?.content) {
      return {
        className: 'text-success bg-success-content border-success',
        icon: <CheckIcon className="w-4 h-4 text-success" />,
        text: t('published'),
      };
    }

    return {
      className: 'text-error bg-error-content border-error',
      icon: <ExclamationCircleIcon className="w-4 h-4 text-error" />,
      text: t('pending'),
    };
  };

  useEffect(() => {
    if (homeworkActivity) {
      const content = homeworkActivity.content || '';
      setValue('content', content);
      setValue(
        'hasSubmission',
        homeworkActivity.assignmentType !== AssignmentType.NOTHING,
      );
    } else {
      setValue('content', '');
      setValue('hasSubmission', false);
    }
    setValue('notHasHomeworkActivity', !scheduledLesson?.hasHomeworkActivity);
    setIsOpen(false);
    setHasChanges(false);
  }, [homeworkActivity, scheduledLesson, setValue]);

  const buttonHomeworkStatus = setButtonDesign();

  const scheduledLessonOrder =
    scheduledLesson.order > 1 ? scheduledLesson.order : undefined;

  return (
    <div className="flex flex-col gap-1.5">
      <ModalWarning
        visible={isOpenModal}
        translationString="modalSaveHomework"
        onClickConfirm={mutate}
        onClickCancel={() => setIsOpenModal(false)}
        isLoading={isLoading}
      />

      <div
        data-testid="homeworkEdit"
        className="flex items-center gap-2.5 justify-between flex-wrap"
      >
        <div
          data-testid="homeworkEditHeader"
          className="flex justify-center items-center gap-2.5 cursor-pointer"
          onClick={() => setIsOpen(!isOpen)}
        >
          <Text
            testId="homeworkText"
            text={t('homework')}
            format="rubik-500"
            size="text-16"
            className="select-none"
          />
          <ConditionalRenderer condition={!withoutHomework}>
            <MainButtonDisplay
              icon={buttonHomeworkStatus.icon}
              color="custom"
              text={buttonHomeworkStatus.text}
              className={`px-2.5 py-1.5 gap-2.5 select-none border ${buttonHomeworkStatus.className}`}
            />
          </ConditionalRenderer>
          {content ? (
            <ChevronDownIcon
              className={`transition transform ease-in-out w-5 h-5 text-primary ${
                isOpen ? 'rotate-180' : 'rotate-0'
              }`}
            />
          ) : (
            <PlusIcon
              className={`w-5 h-5 text-primary transition transform ease-in-out ${
                isOpen ? 'rotate-45' : 'rotate-0'
              }`}
            />
          )}
        </div>

        <HomeworkGradeMarker
          klassId={klassId}
          scheduledLessonOrder={scheduledLessonOrder}
        />
      </div>
      <Text
        text={
          content === homeworkActivity?.content
            ? t('homeworkPublishedDesc')
            : t('homeworkNotPublishedDesc')
        }
        className="text-base-content text-14"
      />
      <AnimatePresence>
        {isOpen && (
          <motion.div
            className="flex flex-col gap-3 overflow-hidden"
            {...accordion}
          >
            <ConditionalRenderer
              condition={!content && !homeworkActivity?.content}
            >
              <div className="flex gap-2 items-center">
                <input
                  type="checkbox"
                  {...register('notHasHomeworkActivity', {
                    onChange(event) {
                      if (!scheduledLesson) return;
                      handleHasHomework({
                        scheduledLessonId: scheduledLesson?.id,
                        changes: { hasHomeworkActivity: !event.target.checked },
                      });
                      setValue('content', '');
                      setHasChanges(true);
                    },
                  })}
                  className="checkbox-xs checkbox checkbox-primary rounded-sm"
                />
                <Text text={`${t('hasHomeworkActivity')}`} />
              </div>
            </ConditionalRenderer>

            <Controller
              name="content"
              control={control}
              render={({ field: { onChange, value } }) => (
                <MyCkeditor
                  content={value}
                  format="book"
                  handleContentChange={content => {
                    onChange(content);
                    setHasChanges(true);
                  }}
                  folder={ImageFolderEnum.HOMEWORK}
                  disabled={withoutHomework}
                />
              )}
            />
            <div className="flex gap-2 items-center">
              <input
                type="checkbox"
                {...register('hasSubmission', {
                  onChange: () => setHasChanges(true),
                })}
                className="checkbox-xs checkbox checkbox-primary rounded-sm"
                disabled={withoutHomework}
              />
              <Text text={`${t('hasSubmittion')}`} />
            </div>
            <SaveCancelGroup
              loading={isLoading || isLoadingHasHomework}
              cancel={{
                testId: 'cancelHomeworkButton',
                icon: <XIcon data-testid="xIcon" />,
                onClick: () => {
                  setIsOpen(false);
                  setValue('content', homeworkActivity?.content || '');
                },
              }}
              save={{
                testId: 'saveHomeworkButton',
                icon: <UploadIcon data-testid="saveIcon" />,
                onClick: () => setIsOpenModal(true),
                text: t('publish'),
                disabled: !hasChanges,
              }}
              disable={withoutHomework}
            />
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
};
