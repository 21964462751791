import { ClockIcon } from '@heroicons/react/outline';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import useActivities from '@/data/hook/useActivities';
import useActivityView from '@/data/hook/useActivityView';
import useAuth from '@/data/hook/useAuth';
import {
  activityProgressesQueryKeys,
  homeworkProgressQueryKeys,
} from '@/data/services/querykeys';
import { isStudent } from '@/functions/auth';
import { activityProgressGenerator } from '@/functions/generators';
import Activity from '@/models/Activity';
import ActivityProgress from '@/models/ActivityProgress';
import { HomeworkActivityProgress } from '@/models/HomeworkActivity';
import { GradeEnum } from '@/models/ScheduledLessonReport';
import { handleTeacherHomeworkRevision } from '@/utils/teacherHomeworkNotification';
import { useQueries, useQuery } from '@tanstack/react-query';
import { Fragment } from 'react';
import {
  CircleIcon,
  DashedCircleIcon,
  SolidGradientCheckCircleIcon,
} from '../icons';
import LessonCard, { LessonCardProps } from '../lessons/LessonCard/LessonCard';
import LessonCardItem from '../lessons/LessonCard/LessonCardItem';
import ConditionalRenderer from './ConditionalRenderer';
import Skeleton from './Skeleton';
import Badge from './dataDisplay/Badge';
import Tag from './dataDisplay/Tag';

type ActivityCardProps = LessonCardProps & {
  activityIds?: number[];
  activityProgressIds?: number[];
  lessonId?: number;
  klassId?: number;
  courseSlug?: string;
  homeworkProgresses?: HomeworkActivityProgress[];
  homeworkId?: number | null;
  homeworkProgressId?: number;
};

export default function ActivityCard(props: ActivityCardProps) {
  const {
    type,
    notification,
    active,
    homeworkProgresses,
    activityProgressIds,
    activityIds,
    homeworkId,
    homeworkProgressId,
  } = props;

  const renderCondition =
    activityIds?.length || activityProgressIds?.length || homeworkId;

  return (
    <ConditionalRenderer condition={renderCondition}>
      <LessonCard type={type} active={active} notification={notification}>
        <Activities
          {...props}
          activityIds={activityIds}
          activityProgressIds={activityProgressIds}
          homeworkProgresses={homeworkProgresses}
          homeworkProgressId={homeworkProgressId}
        />
      </LessonCard>
    </ConditionalRenderer>
  );
}

type ActivitiesProps = Partial<ActivityCardProps> & {
  activitiesProgress?: ActivityProgress[];
  homeworkGrade?: GradeEnum | null;
  homeworkNotification?: number;
};

function Activities({
  activityProgressIds = [],
  activityIds = [],
  courseSlug,
  lessonId,
  klassId,
  active,
  homeworkId,
  homeworkProgressId,
  homeworkProgresses,
}: ActivitiesProps) {
  const { user } = useAuth();
  const location = useLocation();
  const { t } = useTranslation('translation', {
    keyPrefix: 'lesson.activity',
  });
  const klasseURL = klassId ? `class/${klassId}/` : '';
  const baseURL = `/courses/${courseSlug}/lesson/${lessonId}/${klasseURL}activities/`;

  type ManualGradeNotification = (
    prev: number,
    homeworkProgress: HomeworkActivityProgress,
  ) => number;

  const generateManualGradeNotification: ManualGradeNotification = (
    prev,
    { status, homework: { assignmentType } },
  ) => {
    const feedbackStatus = handleTeacherHomeworkRevision(
      status,
      assignmentType,
    );
    return feedbackStatus === 'pending' ? prev + 1 : prev;
  };

  const manualGradeNotification = homeworkProgresses?.reduce(
    generateManualGradeNotification,
    0,
  );

  const { activities } = useActivities({
    activityIds,
    enabled: !isStudent(user?.userType),
  });

  const activityProgress = useQueries({
    queries: activityProgressIds.map(activityProgressId => {
      return {
        keepPreviousData: true,
        enabled: !!activityProgressId,
        placeholderData: activityProgressGenerator({ id: activityProgressId }),
        ...activityProgressesQueryKeys.get(activityProgressId),
      };
    }),
  });

  const { data: homeworkProgress } = useQuery({
    enabled: !!homeworkId && !!homeworkProgressId,
    ...homeworkProgressQueryKeys.get({ homeworkId, homeworkProgressId }),
  });

  const pathnames = location.pathname.split('/');

  const selected = active && pathnames.includes('homework');

  return (
    <ul className="flex flex-col mt-4 relative gap-2">
      <ConditionalRenderer condition={homeworkId}>
        <Tag
          color="secondary"
          className="absolute right-4 -top-1 text-12 border border-secondary px-1.5 py-0.5"
          text={t('givenByTeacher')}
        />
        <LessonCardItem
          selected={selected}
          link={baseURL + 'homework'}
          icon={
            <ActivityIcon
              notification={manualGradeNotification}
              homeworkProgress={homeworkProgress}
              homework
            />
          }
          content={t('homework')}
        />
      </ConditionalRenderer>

      <ConditionalRenderer condition={activities.length}>
        {activities.map(
          ({ data: activity, status, isPlaceholderData }, index) => {
            return (
              <ActivityItem
                isLoading={isPlaceholderData || status === 'loading'}
                key={activity?.id}
                klassId={klassId}
                lessonId={lessonId}
                courseSlug={courseSlug}
                status={status}
                index={index}
                activity={activity}
                active={active}
              />
            );
          },
        )}
      </ConditionalRenderer>

      <ConditionalRenderer condition={activityProgress.length}>
        {activityProgress.map(
          (
            { data: activityProgress, isLoading, status, isPlaceholderData },
            index,
          ) => {
            return (
              <ActivityItem
                key={activityProgress?.id}
                isLoading={isPlaceholderData || isLoading}
                klassId={klassId}
                lessonId={lessonId}
                courseSlug={courseSlug}
                status={status}
                index={index}
                activityProgress={activityProgress}
                active={active}
              />
            );
          },
        )}
      </ConditionalRenderer>
    </ul>
  );
}

type IconProps = {
  activityProgress?: ActivityProgress;
  homework?: boolean;
  notification?: number;
  homeworkProgress?: HomeworkActivityProgress;
};

function ActivityIcon(props: IconProps) {
  const { notification, homework, homeworkProgress, activityProgress } = props;

  if (homeworkProgress) {
    const notGraded =
      homeworkProgress?.answer &&
      homeworkProgress?.activityProgress.grade === null;
    if (notGraded)
      return <ClockIcon className="w-4 h-4 shrink-0 text-secondary" />;
    else if (homeworkProgress.activityProgress.hasDone)
      return (
        <SolidGradientCheckCircleIcon className="w-4 h-4 shrink-0 text-secondary" />
      );
    else
      return <DashedCircleIcon className="w-4 h-4 shrink-0 text-secondary" />;
  }

  if (activityProgress) {
    if (activityProgress.hasDone)
      return (
        <SolidGradientCheckCircleIcon className="w-4 h-4 shrink-0 text-secondary" />
      );

    return <DashedCircleIcon className="w-4 h-4 shrink-0 text-secondary" />;
  }

  return (
    <ConditionalRenderer
      condition={homework}
      fallback={<CircleIcon className="w-4 h-4 shrink-0 text-primary" />}
    >
      <Badge position="-top-1 -right-1" count={notification}>
        <ConditionalRenderer
          condition={notification === 0}
          fallback={
            <DashedCircleIcon className="w-4 h-4 shrink-0 text-neutral/50" />
          }
        >
          <CircleIcon className="w-4 h-4 shrink-0 text-primary" />
        </ConditionalRenderer>
      </Badge>
    </ConditionalRenderer>
  );
}

type RequestStatus = 'loading' | 'success' | 'error';

type ActivityItemProps = {
  index: number;
  status: RequestStatus;
  activityProgress?: ActivityProgress;
  activity?: Activity;
  isLoading?: boolean;
  klassId?: number;
  courseSlug?: string;
  lessonId?: number;
  active?: boolean;
};

function ActivityItem({
  index,
  status,
  activityProgress,
  activity,
  klassId,
  courseSlug,
  lessonId,
  isLoading,
  active,
}: ActivityItemProps) {
  const navigate = useNavigate();
  const { activityId } = useParams();

  const { resetState } = useActivityView();

  const klasseURL = klassId ? `class/${klassId}/` : '';
  const baseURL = `/courses/${courseSlug}/lesson/${lessonId}/${klasseURL}activities/`;

  const selected =
    active && Number(activityId) === (activityProgress?.id || activity?.id);

  const onNavigate = () => {
    navigate(baseURL + (activityProgress?.id || activity?.id));
    if (activityProgress) {
      resetState(activityProgress.answersRevealed ? 'answerkey' : 'activity');
    }
  };

  const content = {
    loading: (
      <Skeleton className="flex h-5 w-full rounded-md bg-primary-content" />
    ),
    success: (
      <LessonCardItem
        index={index}
        loading={isLoading}
        selected={selected}
        content={activityProgress?.activity.name || activity?.name}
        icon={<ActivityIcon activityProgress={activityProgress} />}
        onClick={() => onNavigate()}
      />
    ),
    error: <Fragment />,
  };

  return content[status];
}
