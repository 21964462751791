import { useTranslation } from 'react-i18next';
import moment from 'moment';

import OutlineButton from '@/components/common/buttons/OutlineButton';
import Text from '@/components/common/dataDisplay/Text';
import { updateSettings } from '@/functions/userSettings';

const Language = () => {
  const { i18n } = useTranslation();

  const lngs = {
    en: { nativeName: 'English' },
    pt_BR: { nativeName: 'Português' },
  };

  const { t } = useTranslation();
  return (
    <div className="flex flex-col gap-1">
      <Text text={t('changeLanguage')} />
      <div className="flex gap-2">
        {Object.keys(lngs).map(language => (
          <OutlineButton
            key={language}
            active={i18n.resolvedLanguage === language}
            type="submit"
            onClick={() => {
              i18n.changeLanguage(language);
              moment.locale(language);
              updateSettings({ updates: { language } });
            }}
          >
            {lngs[language as keyof typeof lngs].nativeName}
          </OutlineButton>
        ))}
      </div>
    </div>
  );
};

export default Language;
