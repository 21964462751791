import React, { PropsWithChildren } from 'react';
import Text from '../../../common/dataDisplay/Text';
import { useTranslation } from 'react-i18next';

function SubmissionListContainer({ children }: PropsWithChildren<{}>) {
  const { t } = useTranslation('translation', {
    keyPrefix: 'lesson.teacher.activity.klass',
  });
  return (
    <React.Fragment>
      <hr className="h-px my-8 w-full border-secondary-content" />
      <div className="flex flex-col gap-8">
        <Text
          text={t('submittions')}
          format="poppins-600"
          className="text-primary self-center text-20"
        />
        <div className="flex flex-col gap-2.5">{children}</div>
      </div>
    </React.Fragment>
  );
}

export default SubmissionListContainer;
