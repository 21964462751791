import useCourseEditing from '@/data/hook/useCourseEditing';
import LoadingView from '@/pages/courses/LoadingView';
import LessonBar from './LessonBar/LessonBar';
import { ScheduledLessonTypeEnum } from '@/models/ScheduledLesson';
import InfinityList from '@/components/common/InfinityList';
import useScheduledLessons from '@/data/hook/useScheduledLessons';
import useInfiniteService from '@/data/hook/useInfiniteService';
import { lessonsQueryKeys } from '@/data/services/querykeys';

type LessonListManagerProps = {
  slugCourseName: string;
  klassId: number;
};

export default function LessonListManager({
  slugCourseName,
  klassId,
}: LessonListManagerProps) {
  const hasKlassId = Boolean(klassId);

  if (hasKlassId) {
    return (
      <ScheduledLessonList klassId={klassId} slugCourseName={slugCourseName} />
    );
  }

  return <LessonList klassId={klassId} slugCourseName={slugCourseName} />;
}

function ScheduledLessonList({
  klassId,
  slugCourseName,
}: {
  klassId: number;
  slugCourseName: string;
}) {
  const { reorderScheduledLessons } = useCourseEditing();

  const {
    results: scheduledLessons,
    fetchNextPage,
    isFetchingNextPage,
    refetching,
    loading,
    hasNextPage,
  } = useScheduledLessons({ klassId });

  const reorder = reorderScheduledLessons.length
    ? reorderScheduledLessons
    : scheduledLessons;

  if (loading || refetching) {
    return <LoadingView />;
  }

  if (reorder.length) {
    return (
      <InfinityList
        isFetchingNextPage={isFetchingNextPage}
        hasNextPage={hasNextPage}
        onReachEnd={fetchNextPage}
        className="gap-4"
      >
        {reorder.map((scheduledLesson, i) => {
          const isExtraLesson =
            scheduledLesson.type !== ScheduledLessonTypeEnum.NORMAL;
          return (
            <LessonBar
              color={isExtraLesson ? 'accent' : 'secondary'}
              key={scheduledLesson.id}
              lesson={scheduledLesson.lesson}
              index={i}
              slugCourseName={slugCourseName}
              scheduledLesson={scheduledLesson}
              klassId={klassId}
            />
          );
        })}
      </InfinityList>
    );
  }

  return null;
}

function LessonList({ slugCourseName, klassId }: LessonListManagerProps) {
  const {
    results: lessons,
    isInitialLoading,
    isRefetching,
    isFetchingNextPage: isFetchingNextLessonPage,
    hasNextPage,
    fetchNextPage,
  } = useInfiniteService({
    ...lessonsQueryKeys.nestedList(slugCourseName)._ctx.infinity,
    keepPreviousData: true,
    enabled: !klassId && !!slugCourseName,
  });

  if (isInitialLoading || isRefetching) {
    return <LoadingView />;
  }

  if (lessons.length) {
    return (
      <InfinityList
        isFetchingNextPage={isFetchingNextLessonPage}
        hasNextPage={hasNextPage}
        onReachEnd={fetchNextPage}
        className="gap-4"
      >
        {lessons.map((lesson, i) => (
          <LessonBar
            color="secondary"
            key={lesson.id}
            lesson={lesson}
            index={i}
            slugCourseName={slugCourseName}
          />
        ))}
      </InfinityList>
    );
  }

  return null;
}
