import { AxiosError, AxiosResponse, Method } from 'axios';
import { RequestEvent } from './observers/RequestObserver';

export const createRequestEvent = (
  response: AxiosError | AxiosResponse<any, any>,
): RequestEvent => {
  const responseContent =
    response instanceof AxiosError ? response.response : response;

  return {
    method: (responseContent?.config?.method as Method) ?? 'get',
    statusCode: responseContent?.status ?? 500,
    url: response.config.url ?? '',
    params: responseContent?.config?.params ?? {},
  };
};
