export default interface Rewards {
  id: number;
  xp: number;
  coins: number;
  gems: number;
  student: number;
}

export interface Reward {
  id: number;
  amount: number;
  category: CategoryEnum;
}

export enum TransactionEnum {
  WON = 'WON',
  PURCHASE = 'PURCHASE',
}

export enum RewardEnum {
  XP = 'XP',
  COIN = 'COIN(S)',
  GEM = 'GEM(S)',
}

export enum CategoryEnum {
  MINOR_PROJECT = 'MINOR_PROJECT',
  MAJOR_PROJECT = 'MAJOR_PROJECT',
  PRESENCE = 'PRESENCE',
  BEHAVIOR = 'BEHAVIOR',
  PURCHASE = 'PURCHASE',
  SELECT_THE_REASON = 'SELECT_THE_REASON',
}
