import { ReactNode, ButtonHTMLAttributes } from 'react';
import { Link } from 'react-router-dom';
import { twMerge } from 'tailwind-merge';

import ConditionalRenderer from '../ConditionalRenderer';
import ConditionalWrapper from '../ConditionalWrapper';

type OutlineButtonProps = ButtonHTMLAttributes<HTMLButtonElement> & {
  testId?: string;
  active?: boolean;
  text?: string;
  className?: string;
  disabled?: boolean;
  href?: string;
  icon?: ReactNode;
};

const disableClasses: string = 'disabled disabled:opacity-25';

const regularClasses: string =
  'text-primary bg-base-100 enabled:hover:bg-primary-content focus-visible:ring-primary/40';

const activeClasses: string =
  'bg-primary text-base-100 enabled:hover:bg-primary focus-visible:ring-secondary/40';

export default function OutlineButton(props: OutlineButtonProps) {
  return (
    <ConditionalWrapper
      condition={!!props.href && !props.disabled}
      wrapper={
        <Link
          to={props.href || ''}
          data-testid={props.href ? props.testId : null}
        />
      }
    >
      <Button {...props} />
    </ConditionalWrapper>
  );
}

function Button(props: OutlineButtonProps) {
  const { testId, active, text, className, disabled, href, icon, ...rest } =
    props;

  return (
    <button
      {...rest}
      data-testid={href ? null : testId}
      disabled={disabled}
      className={twMerge(
        'shrink-0 px-3 py-1 text-16 leading-none border-primary border flex rounded-full enabled:active:scale-95 items-center font-rubik duration-150 transition disabled:cursor-default focus-visible:ring focus:outline-none focus-visible:ring-primary/40',
        className,
        `${disabled ? disableClasses : ''}`,
        `${active ? activeClasses : regularClasses}`,
      )}
    >
      <ConditionalRenderer condition={!!icon}>{icon}</ConditionalRenderer>
      {text || props.children}
    </button>
  );
}
