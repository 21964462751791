import { LoadingIcon } from '@/components/icons';
import useInfiniteService from '@/data/hook/useInfiniteService';
import JsonApiResponse from '@/models/JsonApiResponse';
import { BaseFilters } from '@/models/Service';
import { QueryKey, UseInfiniteQueryOptions } from '@tanstack/react-query';
import { debounce } from 'lodash';
import {
  ChangeEventHandler,
  forwardRef,
  InputHTMLAttributes,
  useCallback,
  useMemo,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import ConditionalRenderer from '../ConditionalRenderer';
import Text from '../dataDisplay/Text';
import InfinityList from '../InfinityList';
import { SearchAndFilterInput } from '../SearchAndFilterButtons';
import CheckGroup, { CheckOption } from './CheckGroup';

export type BaseModel = {
  id: number;
};

type ServiceReturn<Model> = {
  _ctx: QueryCtx<Model>;
};

type QueryCtx<Model> = {
  infinity: {
    queryFn(ctx: any): Promise<JsonApiResponse<Model>> | JsonApiResponse<Model>;
    queryKey: QueryKey;
  };
};
export type InfiniteSearchInputProps<
  Filter extends BaseFilters = BaseFilters,
  Model extends BaseModel = BaseModel,
> = InputHTMLAttributes<HTMLInputElement> & {
  service: (filters?: Filter) => ServiceReturn<Model>;
  filters?: Filter;
  selecteds?: number[];
  showSearch?: boolean;
  displayName: (option: Model) => string;
  options?: Omit<UseInfiniteQueryOptions<JsonApiResponse<Model>>, 'queryKey'>;
};

const SearchCheckGroup = forwardRef<HTMLInputElement, InfiniteSearchInputProps>(
  (
    {
      service,
      showSearch,
      displayName,
      selecteds,
      options: queryOptions,
      filters,
      ...inputProps
    },
    ref,
  ) => {
    const { t } = useTranslation('translation', {
      keyPrefix: 'common',
    });
    const [searchFilter, setSearchFilter] = useState<string>();
    const [searchValue, setSearchValue] = useState<string>();

    const {
      results: items,
      isInitialLoading,
      isPreviousData,
      hasNextPage,
      isFetchingNextPage,
      fetchNextPage,
    } = useInfiniteService({
      ...queryOptions,
      keepPreviousData: true,
      ...service({
        ...filters,
        search: searchFilter,
      } as BaseFilters)._ctx.infinity,
    });

    const options: CheckOption[] = items.map(item => ({
      value: item.id,
      checked: !!selecteds?.map(Number)?.includes(item.id),
      label: displayName(item),
    }));

    const memoedDebounce: ChangeEventHandler<HTMLInputElement> = useMemo(
      () => debounce(e => setSearchFilter(e.target.value), 500),
      [],
    );

    const debouncedSearch: ChangeEventHandler<HTMLInputElement> = useCallback(
      e => {
        setSearchValue(e.target.value);
        memoedDebounce(e);
      },
      [memoedDebounce],
    );

    const onDeselect = () => {
      setSearchFilter('');
      setSearchValue('');
    };

    return (
      <div className="flex flex-col gap-2 w-full">
        <SearchAndFilterInput
          hiddenSearchIcon
          searchingMode={showSearch}
          search={searchValue || ''}
          isLoading={isPreviousData}
          onDeselect={onDeselect}
          className={{
            base: 'w-full',
          }}
          onChange={debouncedSearch}
        />
        <ConditionalRenderer condition={isInitialLoading}>
          <div className="w-full flex justify-center">
            <LoadingIcon className="w-5 h-5 text-primary" />
          </div>
        </ConditionalRenderer>
        <ConditionalRenderer condition={!items.length && !isInitialLoading}>
          <div className="w-full flex justify-center text-base-content">
            <Text text={t('noResults')} />
          </div>
        </ConditionalRenderer>
        <InfinityList
          className="relative max-h-[200px] pr-5 gap-4 overflow-x-hidden"
          scroll
          onReachEnd={fetchNextPage}
          hasNextPage={hasNextPage}
          isFetchingNextPage={isFetchingNextPage}
        >
          <CheckGroup options={options} {...inputProps} ref={ref} />
        </InfinityList>
      </div>
    );
  },
) as <Filter extends BaseFilters, Model extends BaseModel>(
  props: InfiniteSearchInputProps<Filter, Model> & {
    ref?: React.Ref<HTMLInputElement>;
  },
) => JSX.Element;

export default SearchCheckGroup;
