import { v4 as uuidv4 } from 'uuid';

export type QuizAnswerEditType = 'new' | 'edit' | 'isCorrectEdit' | 'remove';

export default class MultipleChoiceAnswer {
  answerVerifier: string;
  id?: number;
  private _answer: File | string;
  private _isCorrect: boolean;
  private _altText?: string;
  isRevealed?: boolean;
  editType?: QuizAnswerEditType;

  constructor(
    value: File | string,
    correct: boolean,
    revealed = false,
    editType?: QuizAnswerEditType,
  ) {
    this.answerVerifier = uuidv4();
    this._answer = value;
    this._isCorrect = correct;
    this.isRevealed = revealed;
    this.editType = editType;
  }
  private handleEditType(type: QuizAnswerEditType = 'edit') {
    if (this.editType !== 'new' && this.editType !== 'isCorrectEdit') {
      this.editType = type;
    }
  }
  get answer() {
    return this._answer;
  }
  set answer(value: string | File) {
    this.handleEditType();
    this._answer = value;
  }

  get isCorrect() {
    return this._isCorrect;
  }
  set isCorrect(value: boolean) {
    if (value !== this.isCorrect) {
      this.handleEditType('isCorrectEdit');
    }
    this._isCorrect = value;
  }

  get altText(): string | undefined {
    return this._altText;
  }
  set altText(value: string | undefined) {
    this.handleEditType();
    this._altText = value;
  }

  static correct(value: string) {
    return new MultipleChoiceAnswer(value, true);
  }

  static wrong(value: string) {
    return new MultipleChoiceAnswer(value, false);
  }

  reveal?() {
    return new MultipleChoiceAnswer(this.answer, this.isCorrect, true);
  }
  remove() {
    this.editType = this.id ? 'remove' : undefined;
  }
  static createUsingObject(obj: MultipleChoiceAnswer): MultipleChoiceAnswer {
    const newObject = new MultipleChoiceAnswer(
      obj.answer,
      obj.isCorrect,
      obj.isRevealed,
    );
    newObject.answerVerifier = obj.answerVerifier;
    Object.assign(newObject, obj);
    return newObject;
  }

  toObject() {
    return {
      id: this.id,
      answer: this.answer,
      isCorrect: this.isCorrect,
      isRevealed: this.isRevealed,
      editType: this.editType,
      answerVerifier: this.answerVerifier,
      altText: this.altText,
    };
  }
}
