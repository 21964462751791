import { MINUTES_OPTIONS } from '@/constants';
import React, { useState } from 'react';

export type BlockedTimes = {
  [key: number]: number[];
};

interface TimePickerProps {
  onChange: (newValue: string, canClean: boolean) => void;
  className?: string;
  setIsOpen?: React.Dispatch<React.SetStateAction<boolean>>;
  beforeClose?: () => void;
  blockedTimes?: BlockedTimes;
}

const formatTime = (
  hours: number | string | undefined,
  minutes: number | string | undefined,
) => {
  let unsetHours = '--';
  let unsetMinutes = '--';

  if (typeof hours === 'number') {
    unsetHours = hours < 10 ? `0${hours}` : hours.toString();
  }
  if (typeof minutes === 'number') {
    unsetMinutes = minutes < 10 ? `0${minutes}` : minutes.toString();
  }

  return `${unsetHours}:${unsetMinutes}`;
};

const TimePicker: React.FC<TimePickerProps> = ({
  onChange,
  className,
  setIsOpen,
  beforeClose,
  blockedTimes = {},
}) => {
  const [hours, setHours] = useState<number>();
  const [minutes, setMinutes] = useState<number>();

  const onChangeValues = (hours?: number, minutes?: number) => {
    if (hours && minutes && setIsOpen) {
      onChange(formatTime(hours || '--', minutes || '--'), true);
      if (beforeClose) {
        beforeClose();
      }
      setIsOpen(false);
    } else {
      onChange(formatTime(hours, minutes), false);
    }
  };

  const handleHoursChange = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
  ) => {
    setHours(parseInt(event.currentTarget.value));
    onChangeValues(parseInt(event.currentTarget.value), minutes);
  };

  const handleMinutesChange = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
  ) => {
    setMinutes(parseInt(event.currentTarget.value));
    onChangeValues(hours, parseInt(event.currentTarget.value));
  };

  const isHourDisabled = (hour: number) => {
    const isHourInBlockedTimes = hour in blockedTimes;
    if (minutes !== undefined) {
      return isHourInBlockedTimes && blockedTimes[hour].includes(minutes);
    }
    return (
      hour in blockedTimes &&
      blockedTimes[hour].length === MINUTES_OPTIONS.length
    );
  };

  const isMinuteDisabled = (minute: number) => {
    return (
      hours !== undefined &&
      hours in blockedTimes &&
      blockedTimes[hours].includes(minute)
    );
  };

  return (
    <div
      className={`h-fit w-fit bg-base-100 shadow-default rounded-xl p-3 gap-2 flex ${
        className || ''
      }`}
    >
      <div
        className="h-40 w-14 flex overflow-scroll flex-col scrollbar-thin 
          scrollbar-thumb-primary/40 scrollbar-track-primary-content scrollbar-thumb-rounded-full 
          scrollbar-track-rounded-full"
      >
        {[...Array(24)].map((_, i) => (
          <OptionButton
            isSelected={hours === i}
            testId={'hour' + i}
            key={i}
            value={i}
            disabled={isHourDisabled(i)}
            onClick={handleHoursChange}
          />
        ))}
      </div>
      <div className="h-40 w-10 flex flex-col">
        {MINUTES_OPTIONS.map(m => (
          <OptionButton
            testId={'minute' + m}
            value={m}
            key={m}
            onClick={handleMinutesChange}
            isSelected={minutes === m}
            disabled={isMinuteDisabled(m)}
          />
        ))}
      </div>
    </div>
  );
};

type OptionButtonProps = {
  value: number;
  isSelected?: boolean;
  onClick?(e: React.MouseEvent<HTMLButtonElement, MouseEvent>): void;
  disabled?: boolean;
  testId: string;
};

const OptionButton: React.FC<OptionButtonProps> = ({
  onClick,
  value,
  disabled,
  testId,
  isSelected,
}) => {
  return (
    <button
      type="button"
      className={`px-2 disabled:hover:bg-transparent disabled:cursor-default disabled:text-base-content disabled:opacity-40 w-10 rounded-md hover:bg-primary-content ${
        isSelected ? 'bg-primary text-base-100' : ''
      }`}
      disabled={disabled}
      data-testid={testId}
      value={value}
      onClick={onClick}
    >
      {value < 10 ? `0${value}` : value}
    </button>
  );
};

export default TimePicker;
