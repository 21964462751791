import { AcademicCapIcon, UsersIcon } from '@heroicons/react/outline';
import { useTranslation } from 'react-i18next';
import moment from 'moment';

import Klass, { KlassStatusEnum } from '@/models/Klass';
import User from '@/models/User';
import Text from '@/components/common/dataDisplay/Text';
import Skeleton from '@/components/common/Skeleton';
import WeekdayColumn from './WeekdayColumn';
import LongCollapse from '@/components/common/dataDisplay/LongCollapse';
import useListService from '@/data/hook/useListService';
import { getAuthorizedUnits } from '@/utils/getAuthorizedUnits';
import useAuth from '@/data/hook/useAuth';
import { LoadingIcon } from '@/components/icons';
import useStatisticService from '@/data/hook/useStatistics';
import { TeacherStudentsCount } from '@/models/Statistics';
import {
  klassesQueryKeys,
  teacherStudentsCountQueryKeys,
} from '@/data/services/querykeys';

interface TeacherCollapseItemProps {
  teacher: User;
  selectedUnit?: number[];
}

export default function TeacherCollapseItem({
  teacher,
  selectedUnit,
}: TeacherCollapseItemProps) {
  const { user: authUser } = useAuth();

  const unitId = selectedUnit || getAuthorizedUnits(authUser);

  const {
    statistic: teacherStudentStatistics,
    isInitialLoading: isLoadingUnitStatistics,
  } = useStatisticService({
    ...teacherStudentsCountQueryKeys.list({ teacherId: [teacher.id], unitId }),
    enabled: !!teacher.id,
  });

  const hasKlasses = (teacherStudentStatistics?.klassesCount ?? 0) > 0;

  const { results: klasses, isInitialLoading: isLoadingKlasses } =
    useListService({
      enabled: !!teacher.id && hasKlasses,

      ...klassesQueryKeys.list({
        teacherId: teacher.id,
        unitId,
        status: [KlassStatusEnum.IN_PROGRESS],
      }),
    });

  return (
    <LongCollapse
      testId={`longCollapse${teacher.id}`}
      header={
        <div className="flex flex-row items-center justify-between w-full">
          <Text
            format="rubik-500"
            text={`${teacher.firstName} ${teacher.lastName}`}
          />

          <div className="flex self-end gap-2.5 lg:gap-5">
            <CourseBadge
              isLoading={isLoadingUnitStatistics}
              teacherStats={teacherStudentStatistics}
            />
          </div>
        </div>
      }
    >
      <CollapseContent isLoading={isLoadingKlasses} klasses={klasses} />
    </LongCollapse>
  );
}

const CollapseContent = ({
  isLoading,
  klasses,
}: {
  isLoading?: boolean;
  klasses: Klass[];
}) => {
  const { t } = useTranslation();

  if (isLoading) {
    return (
      <div className="w-full h-14 flex items-center justify-center">
        <LoadingIcon className="w-10 h-10 text-primary" />
      </div>
    );
  }

  if (!!klasses?.length) {
    return (
      <div className="grid grid-cols-7 gap-4">
        {moment.weekdays().map((_, index) => {
          return (
            <WeekdayColumn
              key={index}
              weekday={index}
              klasses={klasses.filter(
                klass =>
                  moment(
                    klass.klassEndDate,
                    'YYYY-MM-DDTHH:mm:ssZ',
                  ).weekday() === index,
              )}
            />
          );
        })}
      </div>
    );
  } else return <Text text={t('teacherCalendar.teacherNoKlass')} />;
};

const CourseBadge = ({
  isLoading,
  teacherStats,
}: {
  isLoading?: boolean;
  teacherStats?: TeacherStudentsCount;
}) => {
  if (isLoading) {
    return <Skeleton className="h-5 w-32 rounded-full bg-primary/50" />;
  }
  return (
    <div className="flex text-14 gap-2.5 rounded-full w-fit shrink-0 px-4 py-1 bg-primary text-base-100">
      <Text format="rubik-700" text="Total" />
      <div className="flex">
        <Text format="rubik-700" text={teacherStats?.klassesCount ?? 0} />
        <AcademicCapIcon className="w-3 h-3" strokeWidth={2.5} />
      </div>
      <div className="flex">
        <Text format="rubik-700" text={teacherStats?.studentsCount ?? 0} />
        <UsersIcon className="w-3 h-3" strokeWidth={3} />
      </div>
    </div>
  );
};
