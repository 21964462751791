import { DaysOfWeekTypeEnum } from '@/models/Klass';
import WeekdayKlassCell from './WeekdayKlassCell';
import User from '@/models/User';
import { StudentModalsType } from './StudentModals';
import useInfiniteService from '@/data/hook/useInfiniteService';
import { klassesQueryKeys } from '@/data/services/querykeys';
import { ListKlassesFilters } from '@/data/services/klassServices';
import { useEffect } from 'react';
import {
  KlassDayPeriod,
  KlassDayPeriodState,
  Period,
} from './TeachingWeekdays';
import { MANAGEMENT_PAGE_SIZE, REQUEST_STALE_TIME_IN_MS } from '@/constants';

export type TeachingWeekdayProps = {
  onChangeModal?: (modal?: StudentModalsType, student?: User) => void;
  weekday: DaysOfWeekTypeEnum;
  filters: ListKlassesFilters;
  period: Period;
  updateKlassDayPeriodInfo(
    klassDayPeriod: KlassDayPeriod,
    payload: KlassDayPeriodState,
  ): void;
};

export default function TeachingWeekday({
  filters,
  weekday,
  onChangeModal,
  period,
  updateKlassDayPeriodInfo,
}: TeachingWeekdayProps) {
  function handleDefaultFilters(): ListKlassesFilters {
    const filters: ListKlassesFilters = {
      dayOfWeek: [weekday],
    };
    function handleTimeFilters(): ListKlassesFilters {
      if (period !== 'ALL_DAY') {
        return {
          timeAfter: period === 'PM' ? '12:00' : undefined,
          timeBefore: period === 'AM' ? '12:00' : undefined,
        };
      }
      return {};
    }

    return {
      ...filters,
      ...handleTimeFilters(),
    };
  }
  const defaultFilters = handleDefaultFilters();

  const requestFilters: ListKlassesFilters = {
    ...filters,
    ...defaultFilters,
    pageSize: MANAGEMENT_PAGE_SIZE,
  };
  const hasWeekDayFilter = !!filters.dayOfWeek?.length;
  const shouldDisplayKlassesList =
    !hasWeekDayFilter || filters.dayOfWeek?.includes(weekday);

  const {
    results: klasses,
    hasNextPage,
    fetchNextPage,
    meta,
    isFetchingNextPage,
    isLoading,
    isFetching,
  } = useInfiniteService({
    staleTime: REQUEST_STALE_TIME_IN_MS,
    ...klassesQueryKeys.list(requestFilters)._ctx.infinity,
    enabled: shouldDisplayKlassesList,
  });

  const klassesList = shouldDisplayKlassesList ? klasses : [];

  const klassDayPeriod: KlassDayPeriod = `${weekday}_${period}`;

  useEffect(() => {
    if (!meta) return;
    async function onReachEnd() {
      if (hasNextPage && !isFetchingNextPage) {
        await fetchNextPage();
      }
    }
    updateKlassDayPeriodInfo(klassDayPeriod, {
      isFetchingNextPage,
      isLoading,
      count: meta.pagination.count,
      fetchNextPage: onReachEnd,
      hasNextPage,
      isFetching,
    });
  }, [
    fetchNextPage,
    hasNextPage,
    isFetching,
    isFetchingNextPage,
    isLoading,
    klassDayPeriod,
    meta,
    updateKlassDayPeriodInfo,
  ]);

  return (
    <ul className="flex flex-col items-center text-base-100 gap-2 transition ease-in-out grow-0">
      {klassesList.map(item => (
        <WeekdayKlassCell
          key={item.id}
          klass={item}
          onChangeModal={onChangeModal}
        />
      ))}
    </ul>
  );
}
