import React from 'react';
import { Link } from 'react-router-dom';
import ConditionalWrapper from '../ConditionalWrapper';
import UserAvatar from '../../store/UserAvatar';
import { twMerge } from 'tailwind-merge';

interface AvatarProps
  extends React.DetailedHTMLProps<
    React.AnchorHTMLAttributes<HTMLAnchorElement>,
    HTMLAnchorElement
  > {
  testId?: string;
  userId: number;
  size?: AvatarSizeType;
  className?: string;
  url?: string;
  onClick?(e: unknown): void | Promise<void>;
}

export type AvatarSizeType = '5' | '6' | '7' | '14' | '16' | '20' | '28' | '48';

const avatarSizeRecord: Record<AvatarSizeType, string> = {
  '5': 'w-5 h-5',
  '6': 'w-6 h-6',
  '7': 'w-7 h-7',
  '14': 'w-14 h-14',
  '16': 'w-16 h-16',
  '20': 'w-20 h-20',
  '28': 'w-28 h-28',
  '48': 'w-48 h-48',
};

export const Avatar = (props: AvatarProps) => {
  const { testId, size, className, userId, onClick, url } = props;

  return (
    <ConditionalWrapper
      wrapper={
        <Link
          onClick={onClick}
          data-testid={testId}
          to={url || ''}
          className={avatarSizeRecord[size || 6]}
        />
      }
      fallback={
        <div
          onClick={onClick}
          data-testid={testId}
          className={avatarSizeRecord[size || 6]}
        />
      }
      condition={!!url}
    >
      <div
        className={`${avatarSizeRecord[size || 6]}  ${twMerge(
          'rounded-full bg-accent/40 overflow-hidden pt-[20%]',
          className,
        )}`}
      >
        <UserAvatar className="scale-150" userId={userId} />
      </div>
    </ConditionalWrapper>
  );
};
