import { ChevronLeftIcon } from '@heroicons/react/outline';
import { motion } from 'framer-motion';

type BackArrowProps = { onClick: () => void };

const BackArrow = ({ onClick }: BackArrowProps) => (
  <motion.button
    whileHover={{ scale: 1.1 }}
    animate={{ x: 0 }}
    initial={{ x: -100 }}
    whileTap={{ scale: 0.9 }}
    onClick={onClick}
    className="relative"
  >
    <ChevronLeftIcon className="w-6 h-6 text-base-content" />
  </motion.button>
);

export default BackArrow;
