import { DragDropContext, DropResult, Droppable } from '@hello-pangea/dnd';
import DNDColumnProgress from '@/models/DNDColumnProgress';
import DNDItemProgress from '@/models/DNDItemProgress';
import Text from '@/components/common/dataDisplay/Text';
import DNDPositionalItemProgress from './DNDPositionalItemProgress';
import { useEffect, useState } from 'react';
import { ActivityMode } from '@/models/ActivityProgress';
import { updateDndPositionalColumnProgress } from '@/data/services/dndColumnProgressServices';
import alert from '@/utils/UseAlert';
import { useTranslation } from 'react-i18next';
import { DNDColumn } from '@/models/DNDColumn';
import React from 'react';
import ConditionalRenderer from '@/components/common/ConditionalRenderer';

interface DNDPositionalColumnProgressProps {
  dndPositionalColumnProgress: DNDColumnProgress;
  activityMode: ActivityMode;
  setHasChange(hasChange: boolean): Promise<void> | void;
  columnProgress?: DNDColumnProgress;
  answerColumn?: DNDColumn;
}

export default function DNDPositionalColumnProgress({
  dndPositionalColumnProgress,
  activityMode,
  setHasChange,
  columnProgress,
  answerColumn,
}: DNDPositionalColumnProgressProps) {
  const { column, itemsProgress, id } = dndPositionalColumnProgress;
  const { t } = useTranslation('translation', {
    keyPrefix: 'lesson.activity.dragAndDropPositional',
  });

  const [isReordenationItems, setIsReordenationItems] = useState(
    activityMode !== 'activity',
  );

  const [itemsState, setItemsState] =
    useState<DNDItemProgress[]>(itemsProgress);

  async function handleOnDragEnd(result: DropResult) {
    if (!result.destination) return;

    if (itemsState) {
      const itemsSource = [...itemsState];
      const items = itemsState;
      const [reorderedItem] = items.splice(result.source.index, 1);

      items.splice(result.destination.index, 0, reorderedItem);

      try {
        setIsReordenationItems(true);
        await Promise.all([
          updateDndPositionalColumnProgress(id, items),
          setHasChange(true),
        ]);

        setItemsState(items);
      } catch (error) {
        setItemsState(itemsSource);
        alert.error(t('errorReordenation'));
      } finally {
        setIsReordenationItems(false);
      }
    }
  }

  useEffect(() => {
    setItemsState(itemsProgress);
  }, [itemsProgress]);

  return (
    <div className="flex flex-col gap-3 items-center w-full">
      <ConditionalRenderer condition={!!column.name}>
        <Text format="rubik-500" className="text-accent" text={column.name} />
      </ConditionalRenderer>
      <DragDropContext onDragEnd={handleOnDragEnd}>
        <Droppable
          droppableId="dndPositionalItemProgressList"
          isDropDisabled={isReordenationItems}
        >
          {provided => (
            <div
              className="flex flex-col justify-around w-full gap-3"
              {...provided.droppableProps}
              ref={provided.innerRef}
            >
              {itemsState.map(
                (dndPositionalItemProgress: DNDItemProgress, index) => {
                  if (columnProgress) {
                    const findDndItemProgress =
                      columnProgress.itemsProgress.find(
                        itemProgress =>
                          itemProgress.id === dndPositionalItemProgress.id,
                      );

                    if (findDndItemProgress) {
                      const findAnswer = answerColumn?.items.find(
                        ({ id }) => id === findDndItemProgress.item.id,
                      );
                      return (
                        <DNDPositionalItemProgress
                          index={index}
                          key={findDndItemProgress.id}
                          dndPositionalItemProgress={findDndItemProgress}
                          activityMode={activityMode}
                          isReordenationItems={isReordenationItems}
                          answerOrder={findAnswer?.order}
                        />
                      );
                    } else return <React.Fragment />;
                  } else {
                    return (
                      <DNDPositionalItemProgress
                        index={index}
                        key={dndPositionalItemProgress.id}
                        dndPositionalItemProgress={dndPositionalItemProgress}
                        activityMode={activityMode}
                        isReordenationItems={isReordenationItems}
                      />
                    );
                  }
                },
              )}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>
    </div>
  );
}
