import Area from '@/models/Area';
import Observable from '@/utils/observers/ObserverPattern';
import TreeArea from '@/utils/trees/TreeArea';
import useFilterParams from '@/utils/UseFilterParams';
import { useQueryClient } from '@tanstack/react-query';
import {
  createContext,
  MutableRefObject,
  PropsWithChildren,
  useRef,
} from 'react';
import { areasQueryKeys } from '../services/querykeys';
import { TopicsFiltersParams } from '@/components/topics/TopicsPageContent';

type InsertArgs = {
  area: Area;
  parentElementId?: number;
};
export interface TreeContextProps {
  tree: MutableRefObject<TreeArea>;
  getPath: (id: number) => number[] | undefined;
  insert: (args: InsertArgs) => void;
  invalidateParent: (args: { parentId?: number | null }) => Promise<void>;
  pathParams: number[];
  finalMountTreeObserver: MutableRefObject<Observable<number>>;
}

export const TreeContext = createContext<TreeContextProps>(
  {} as TreeContextProps,
);

export default function TreeProvider({ children }: PropsWithChildren) {
  const queryClient = useQueryClient();

  const observer = useRef(new Observable<number>());
  const { filterParams } = useFilterParams<TopicsFiltersParams>();
  const tree = useRef<TreeArea>(new TreeArea({ id: 0, name: 'Root' }));
  const getPath = (id: number) => {
    const path = tree.current.findPath(id);
    return path?.map(a => a.id);
  };

  const pathParams = filterParams.getAll('path')?.map(Number) || [];

  const insert = ({ area, parentElementId }: InsertArgs) => {
    tree.current.insert(area, parentElementId);
    if (area.id === pathParams.at(-1)) observer.current.notifyAll();
  };

  const invalidateParent = async ({
    parentId,
  }: {
    parentId?: number | null;
  }) => {
    if (parentId)
      await queryClient.invalidateQueries(
        areasQueryKeys.list({ parentAreaId: [parentId] }),
      );
    else
      await queryClient.invalidateQueries(
        areasQueryKeys.list({ isRoot: true }),
      );
  };

  return (
    <TreeContext.Provider
      value={{
        tree,
        getPath,
        insert,
        invalidateParent,
        pathParams,
        finalMountTreeObserver: observer,
      }}
    >
      {children}
    </TreeContext.Provider>
  );
}
