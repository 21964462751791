import {
  FacebookIcon,
  InstagramIcon,
  LinkedinIcon,
  RobloxIcon,
  TikTokIcon,
  YoutubeIcon,
  LinkIcon,
  GithubIcon,
} from '@/components/icons';
import { MediaName } from '@/models/UserProfile';

export function handleMediaName(link: string) {
  if (link.includes('youtube')) {
    return MediaName.YOUTUBE;
  } else if (link.includes('tiktok')) {
    return MediaName.TIKTOK;
  } else if (link.includes('roblox')) {
    return MediaName.ROBLOX;
  } else if (link.includes('linkedin')) {
    return MediaName.LINKEDIN;
  } else if (link.includes('instagram')) {
    return MediaName.INSTAGRAM;
  } else if (link.includes('facebook')) {
    return MediaName.FACEBOOK;
  } else if (link.includes('github')) {
    return MediaName.GITHUB;
  } else {
    return MediaName.OTHER;
  }
}

export function handleIcon(mediaName?: MediaName) {
  switch (mediaName) {
    case MediaName.FACEBOOK:
      return FacebookIcon;
    case MediaName.INSTAGRAM:
      return InstagramIcon;
    case MediaName.LINKEDIN:
      return LinkedinIcon;
    case MediaName.ROBLOX:
      return RobloxIcon;
    case MediaName.TIKTOK:
      return TikTokIcon;
    case MediaName.GITHUB:
      return GithubIcon;
    case MediaName.YOUTUBE:
      return YoutubeIcon;
    default:
      return LinkIcon;
  }
}
