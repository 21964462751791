import ActionDetailCard from '@/components/common/buttons/ActionDetailCard';
import Area from '@/models/Area';
import { PlusIcon } from '@heroicons/react/outline';
import { useTranslation } from 'react-i18next';

type ActionsCardProps = {
  area: Area;
  actions: {
    onAddArea: () => void;
    onAddTag: () => void;
  };
};
export default function AddActionsCards({ actions }: ActionsCardProps) {
  const { t } = useTranslation('translation', {
    keyPrefix: 'topics.tooltip',
  });
  return (
    <div className="flex items-start flex-col gap-1.5">
      <ActionDetailCard
        testId="addSubareaButton"
        title={t('area.title')}
        details={t('area.desc')}
        icon={PlusIcon}
        onClick={actions.onAddArea}
      />
      <ActionDetailCard
        testId="addTagButton"
        title={t('tag.title')}
        details={t('tag.desc')}
        icon={PlusIcon}
        onClick={actions.onAddTag}
      />
    </div>
  );
}
