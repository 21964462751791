import { ArrowRightIcon, SpeakerphoneIcon } from '@heroicons/react/outline';
import { useTranslation } from 'react-i18next';
import MainButton from './buttons/MainButton';
import { useEffect } from 'react';
import { initCanny } from '@/utils/canny';
import useConfigContext from '@/data/hook/config';

export default function SuggestionsCard() {
  const { t } = useTranslation('translation', {
    keyPrefix: 'suggestionsPage',
  });
  const { userSettings } = useConfigContext();
  useEffect(
    () => initCanny('/sugestoes', userSettings?.theme ?? 'light'),
    [userSettings?.theme],
  );

  return (
    <div className="flex shrink-0 items-center gap-3">
      <MainButton
        href="/sugestoes"
        className="shrink-0"
        icon={<ArrowRightIcon />}
        text={t('suggestions') + ' Portal'}
      />
      <MainButton
        icon={<SpeakerphoneIcon />}
        data-canny-changelog
        text={t('updates')}
      />
    </div>
  );
}
