import { useTranslation } from 'react-i18next';
import { ClipboardListIcon, RefreshIcon } from '@heroicons/react/outline';
import { useQueryClient } from '@tanstack/react-query';

import ReportsList from './ReportsList';
import CollapsableCard from './CollapsableCard';
import useAuth from '@/data/hook/useAuth';
import { ScheduledLessonFilters } from '@/data/services/scheduledLessonsServices';
import IconButton from '../common/buttons/IconButton';
import useInfiniteService from '@/data/hook/useInfiniteService';
import {
  klassesQueryKeys,
  scheduledLessonsQueryKeys,
} from '@/data/services/querykeys';
import { ListKlassesFilters } from '@/data/services/klassServices';
import { KlassStatusEnum } from '@/models/Klass';
import { ClassNameComponent } from '@/utils/ClassNameComponent';

type TeacherReportsProps = {
  className?: ClassNameComponent;
};

export default function TeacherReports({ className }: TeacherReportsProps) {
  const { t } = useTranslation('translation', {
    keyPrefix: 'teacherDashboard.teacherReports',
  });
  const { user } = useAuth();
  const queryClient = useQueryClient();

  const filters: ListKlassesFilters = {
    status: [KlassStatusEnum.IN_PROGRESS, KlassStatusEnum.OPEN],
    teacherId: user?.id ?? 0,
  };

  const {
    results: klasses,
    isInitialLoading: loadingKlasses,
    isFetchingNextPage: fetchingNexKlassPage,
    hasNextPage: hasNextKlassPage,
    fetchNextPage: fetchNextKlassPage,
  } = useInfiniteService({
    keepPreviousData: true,
    ...klassesQueryKeys.list(filters)._ctx.infinity,
  });

  const scheduledLessonFilters: ScheduledLessonFilters = {
    isPendingReport: true,
    teacher: user?.id,
  };

  const { queryKey: scheduledLessonsQuerykey, queryFn: listScheduledLessons } =
    scheduledLessonsQueryKeys.list(scheduledLessonFilters)._ctx.infinity;

  const {
    results: pastScheduledLessons,
    meta,
    isInitialLoading: loadingScheduledLessons,
    isFetchingNextPage: fetchingNextScheduledPage,
    hasNextPage: hasNextScheduledPage,
    fetchNextPage: fetchNextScheduledPage,
  } = useInfiniteService({
    queryKey: scheduledLessonsQuerykey,
    queryFn: listScheduledLessons,
    enabled: !!user,
  });

  const count = meta?.pagination.count ?? 0;
  const isEmpty = pastScheduledLessons.length === 0;
  const onClickRefresh = () => {
    queryClient.invalidateQueries(scheduledLessonsQuerykey);
  };

  return (
    <CollapsableCard
      className={className}
      badgeCount={count}
      headerIcon={<ClipboardListIcon className="w-6 stroke-[2]" />}
      title={t('pendingReports').toLocaleUpperCase()}
      isCollapsable={!isEmpty}
      isLoading={loadingKlasses || loadingScheduledLessons}
      header={
        <IconButton
          onClick={onClickRefresh}
          icon={<RefreshIcon className="w-6" />}
        />
      }
      testId="teacherReports"
      isFetchingNextPage={fetchingNextScheduledPage || fetchingNexKlassPage}
      hasNextPage={hasNextScheduledPage || hasNextKlassPage}
      onReachEnd={fetchNextScheduledPage || fetchNextKlassPage}
    >
      <ReportsList klasses={klasses} scheduledLessons={pastScheduledLessons} />
    </CollapsableCard>
  );
}
