import { toast, ToastOptions } from 'react-hot-toast';
import { Alert, TypeAlertEnum } from '../components/common/dataDisplay/Alert';

const alertGenerator =
  (type: TypeAlertEnum) => (message: string, options?: ToastOptions) => {
    toast.remove();
    toast.custom(() => {
      return <Alert message={message} type={type} />;
    }, options);
  };

const alert = (message: string, opts?: ToastOptions) =>
  alertGenerator(TypeAlertEnum.SUCCESS)(message, opts);

alert.success = alertGenerator(TypeAlertEnum.SUCCESS);
alert.error = alertGenerator(TypeAlertEnum.ERROR);
alert.warning = alertGenerator(TypeAlertEnum.WARNING);
alert.info = alertGenerator(TypeAlertEnum.INFO);

export default alert;
