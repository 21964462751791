import { PlusIcon } from '@heroicons/react/outline';
import Text from './dataDisplay/Text';

interface ButtonProps {
  url?: string;
  title?: string;
  onClick?: (e: any) => void;
  open: boolean;
  testId?: string;
}

export default function AdminButton(props: ButtonProps) {
  const { title, onClick, testId } = props;
  return (
    <button
      data-testid={testId}
      className="text-center self-end bg-secondary-content flex text-secondary
       w-fit rounded-lg h-12 px-4 py-5 items-center gap-x-2.5"
      onClick={onClick}
    >
      <PlusIcon className="w-6 h-6" />
      <Text
        text={`${title}`}
        size="text-16 lg:text-18"
        format="rubik-500"
        className="uppercase"
      />
    </button>
  );
}
