import requester from '@/axios';
import JsonApiResponse from '@/models/JsonApiResponse';
import { BaseFilters } from '@/models/Service';
import {
  BehaviorAssessment,
  CommentAssessment,
  StudentAssessment,
} from '@/models/StudentAssessment';

export type ListStudentAssessments = BaseFilters & {
  studentId?: number;
};

export const listStudentAssessments = async (
  params: ListStudentAssessments = {},
) => {
  const { data } = await requester().get<JsonApiResponse<StudentAssessment>>(
    'student-assessments/',
    {
      params,
    },
  );

  return data;
};

export const getStudentAssessment = async (studentAssessmentId: number) => {
  const { data } = await requester().get<StudentAssessment>(
    `student-assessments/${studentAssessmentId}/`,
  );

  return data;
};

export type StudentAssessmentBody = {
  klassId?: number;
  pedagogicalSheetId?: number;
  behaviorAssessment?: Partial<BehaviorAssessment>;
  commentAssessment?: Partial<CommentAssessment>;
};

export const createStudentAssessment = async (body: StudentAssessmentBody) => {
  const { data } = await requester().post<StudentAssessment>(
    'student-assessments/',
    body,
  );

  return data;
};

type StudentAssessmentRequest = {
  assessmentId: number;
  body: Partial<StudentAssessmentBody>;
};

export const updateStudentAssessment = async ({
  assessmentId,
  body,
}: StudentAssessmentRequest) => {
  const { data } = await requester().patch<StudentAssessment>(
    `student-assessments/${assessmentId}/`,
    body,
  );

  return data;
};
