import ConditionalRenderer from '@/components/common/ConditionalRenderer';
import InfiniteSearchInput from '@/components/common/dataInput/InfiniteSearchInput';
import useStudentContext from '@/data/hook/student';
import { simplifiedCourseProgressesQueryKeys } from '@/data/services/querykeys';
import { SimplifiedCourseProgress } from '@/models/StudentCourseProgress';

type SelectProgressProps = {
  userId: number;
  selected?: SimplifiedCourseProgress;
};

export function SelectProgress({ userId, selected }: SelectProgressProps) {
  const { progress, selectProgress: onSelect } = useStudentContext();

  return (
    <ConditionalRenderer condition={progress.length > 1}>
      <InfiniteSearchInput
        service={() =>
          simplifiedCourseProgressesQueryKeys.list(userId, {
            isActive: true,
          })
        }
        displayName={option => option.coursePath.course.name}
        onSelect={onSelect}
        selectedItem={selected}
        blockDeselect
        input={{ className: { base: 'w-full md:w-80 font-500' } }}
      />
    </ConditionalRenderer>
  );
}
