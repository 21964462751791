import Book from '../models/Book';
import Chapter from '../models/Chapter';

export const stringToStringArray = (content: string): string[] => {
  let pattern = /',/;
  if (content[1] === '"') {
    pattern = /",\s"/;
  }
  let transformed: string | string[] = (content = content.slice(
    2,
    content.length - 2,
  ));
  transformed = content.split(pattern);

  return transformed.map(str => {
    if (str[0] === ' ') {
      return str.slice(2, str.length);
    } else if (str[0] === '') {
      return str.slice(2, str.length);
    } else {
      return str;
    }
  });
};

export const checkIfOldBook = (book: Book) => {
  return book && book.chapters[0].content.slice(0, 1) === '[';
};

export const anOldChapter = (chapter: Chapter) => {
  return chapter.content.slice(0, 1) === '[';
};
