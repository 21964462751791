import { ICourse } from '@/models/Course';
import { SimplifiedCourseProgress } from '@/models/StudentCourseProgress';
import Skeleton from '../common/Skeleton';
import CourseItem from './CourseItem';
import ProgressBarCourse from './ProgressBarCourse';

interface CourseProgressListProps {
  currentProgress?: SimplifiedCourseProgress;
  courses: ICourse[];
  hideProgress?: boolean;
  loading?: boolean;
  testId?: string;
}

export default function CourseProgressList({
  courses,
  hideProgress,
  currentProgress,
  loading,
  testId,
}: CourseProgressListProps) {
  const courseName =
    currentProgress?.coursePath.course.name ||
    courses.at(0)?.courseType?.replace('_', '+') ||
    '';

  if (loading)
    return <Skeleton className="flex w-full h-40 bg-primary-content" />;

  if (courses.length)
    return (
      <div className="flex flex-col gap-3" data-testid={testId}>
        <ProgressBarCourse
          isStudent={!hideProgress}
          className={hideProgress ? 'hidden' : ''}
          courseName={courseName}
          courseType={currentProgress?.coursePath.course.courseType}
          progress={currentProgress?.progress}
          hideProgress={!currentProgress}
        />

        <div className="grid sm:grid-cols-4 md:grid-cols-4 lg:grid-cols-4 gap-3">
          {courses.map(course => (
            <CourseItem
              key={course.id}
              course={course}
              isActive={currentProgress?.coursePath.course.id === course.id}
            />
          ))}
        </div>
      </div>
    );

  return null;
}
