import requester from '@/axios';
import { TextActivityElement } from '@/models/ActivityElement';

export type UpdateTextActivity = {
  idTextActivity: number;
  changes: Partial<Omit<TextActivityElement, 'id'>>;
};

export const getTextActivity = async (id: number) => {
  const { data: textActivity } = await requester().get<TextActivityElement>(
    `text-activity/${id}/`,
  );

  return textActivity;
};

export const updateTextActivity = async (
  idTextActivity: number,
  changes: Partial<Omit<TextActivityElement, 'id'>>,
) => {
  const { data: textActivity } = await requester().patch<TextActivityElement>(
    `text-activity/${idTextActivity}/`,
    changes,
  );

  return textActivity;
};

export const deleteTextActivity = async (questionId: number) => {
  await requester().delete<TextActivityElement>(`text-activity/${questionId}/`);
};

export const createTextActivity = async (
  body: Partial<TextActivityElement>,
) => {
  const { data: textActivity } = await requester().post<TextActivityElement>(
    `text-activity/`,
    body,
  );

  return textActivity;
};
