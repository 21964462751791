interface CirclesBackgroundProps {
  primary: string;
  secondary: string;
  secondaryLight: string;
  accent: string;
  className: string;
}
export default function CirclesBackground(props: CirclesBackgroundProps) {
  const { primary, secondary, secondaryLight, accent, className } = props;
  return (
    <svg
      className={className}
      viewBox="0 0 711 378"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle
        r="134.257"
        transform="matrix(1 0 0 -1 514.257 208.452)"
        fill={primary} //yellow
      />
      <circle
        r="88"
        transform="matrix(1 0 0 -1 88 215.709)"
        fill={secondaryLight}
      />
      <circle
        r="144"
        transform="matrix(-0.386093 -0.92246 -0.92246 0.386093 522.469 188.709)"
        stroke={secondary} //red
        strokeWidth="2"
        strokeLinecap="round"
        stroke-dasharray="4 8"
      />
      <circle
        r="32"
        transform="matrix(-0.483131 -0.875548 -0.875548 0.483131 655.983 243.995)"
        fill="var(--base-200)"
      />
      <circle
        r="24"
        transform="matrix(-0.483131 -0.875548 -0.875548 0.483131 656.781 243.955)"
        fill="var(--base-200)"
        stroke={secondary}
        strokeWidth="2"
        strokeLinecap="round"
        stroke-dasharray="4 6"
      />
      <circle
        r="72"
        transform="matrix(-0.258819 -0.965926 -0.965926 0.258819 127.25 239.972)"
        stroke={accent}
        strokeWidth="2"
        strokeLinecap="round"
        stroke-dasharray="4 8"
      />
      <circle
        r="12"
        transform="matrix(-0.258819 -0.965926 -0.965926 0.258819 60.4809 265.109)"
        fill={secondaryLight}
        stroke={accent}
        strokeWidth="2"
        strokeLinecap="round"
        stroke-dasharray="4 8"
      />
    </svg>
  );
}
