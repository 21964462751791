import { CourseTypeEnum } from '@/models/Course';
import { ProgressSpace } from '../../../ProgressIcons';
import Text from '../../Text';

interface CourseTypeBarProps {
  type: CourseTypeEnum;
  first?: boolean;
}

export default function CourseTypeBar({ type, first }: CourseTypeBarProps) {
  const separator = type.split('_');
  const courseTypeName = separator[0] + '+' + separator[1];

  const roundedClassName = first ? 'rounded-b-lg rounded-r-lg' : 'rounded-lg';

  return (
    <div
      className={`flex flex-col bg-secondary-content items-stretch shadow-default ${roundedClassName}`}
    >
      <div className="flex flex-row items-center pl-3.5 pr-8 py-2 ">
        <ProgressSpace className="mr-7" progress={100} type={type} />
        <Text
          id="courseType"
          text={courseTypeName}
          format="rubik-500"
          className="text-secondary text-18"
        />
      </div>
    </div>
  );
}
