export interface RegexValidator {
  pattern: RegExp;
  message: string;
}

export const validEmail: RegexValidator = {
  pattern: new RegExp('^[a-zA-Z0-9._:$!%-]+@[a-zA-Z0-9.-]+.[a-zA-Z]$'),
  message: 'E-mail inválido',
};

export const min8Chars: RegexValidator = {
  pattern: new RegExp('.{8,}$'),
  message: 'Mínimo de 8 characteres.',
};

export const min1LowerChar: RegexValidator = {
  pattern: new RegExp('.*?[a-z]'),
  message: 'Pelo menos 1 letra minúscula.',
};

export const min1UpperChar: RegexValidator = {
  pattern: new RegExp('.*?[A-Z]'),
  message: 'Pelo menos 1 letra maiúscula.',
};

export const min1NumberChar: RegexValidator = {
  pattern: new RegExp('.*?[0-9]'),
  message: 'Pelo menos 1 número.',
};

export const min1EspecialChar: RegexValidator = {
  pattern: new RegExp('.*?[#?!@$%^&*-]'),
  message: 'Pelo menos 1 caracter especial.',
};

export const isValidEmail = (email: string) => {
  if (/^[a-zA-Z0-9._:$!%-]+@[a-zA-Z0-9.-]+.[a-zA-Z]$/.test(email)) {
    return true;
  } else {
    return false;
  }
};

export const validateLink = /\..{2,}$/;

export const validateHexadecimalCode = /^#?([a-f0-9]{6}|[a-f0-9]{3})$/;
