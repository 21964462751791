import { UPDATES_SETTINGS } from '@/constants';
import { Update, Updates } from '@/models/Updates';
export type ConditionWhen<Keywords> = (
  keyword: Keywords,
  value?: Update,
) => boolean;
type DeleteSettings<Keywords> = {
  when: ConditionWhen<Keywords>;
};
export class UpdatesRepository<Model extends Record<string, Update> = Updates> {
  private storage: Storage;
  constructor(storage: Storage) {
    this.storage = storage;
  }

  getAll = (): Record<string, Update> => {
    const readUpdates = this.storage.getItem(UPDATES_SETTINGS);
    if (readUpdates) return JSON.parse(readUpdates) as Model;
    return {};
  };

  get = (keyword: keyof Model) => {
    const readUpdates = this.storage.getItem(UPDATES_SETTINGS);
    if (!readUpdates) return null;
    const parsedReadUpdates = JSON.parse(readUpdates) as Model;
    return parsedReadUpdates[keyword];
  };

  update(keyword: keyof Model, updates: Update) {
    let readUpdates = this.getAll() || {};
    readUpdates = { ...readUpdates, [keyword]: updates };
    this.storage.setItem(UPDATES_SETTINGS, JSON.stringify(readUpdates));
  }

  remove({ when }: DeleteSettings<keyof Model>) {
    const readUpdates = this.getAll() || {};
    for (const [key, value] of Object.entries(readUpdates)) {
      if (when(key as keyof Model, value as Update)) {
        delete readUpdates[key];
      }
    }
    this.storage.setItem(UPDATES_SETTINGS, JSON.stringify(readUpdates));
  }

  removeAll = () => this.storage.removeItem(UPDATES_SETTINGS);
}

const updatesRepository = new UpdatesRepository(localStorage);
export default updatesRepository;
