import {
  QueryKey,
  UseQueryOptions,
  useQuery,
  useQueryClient,
} from '@tanstack/react-query';
import JsonApiResponse from '@/models/JsonApiResponse';

type T<
  Model,
  TError = unknown,
  TQueryKey extends QueryKey = QueryKey,
> = UseQueryOptions<
  JsonApiResponse<Model>,
  TError,
  JsonApiResponse<Model>,
  TQueryKey
>;

export default function useListService<
  Model,
  TError = unknown,
  TQueryKey extends QueryKey = QueryKey,
>(options: T<Model, TError, TQueryKey>) {
  const { data, ...rest } = useQuery(options);

  const queryClient = useQueryClient();

  const invalidate = () => queryClient.invalidateQueries(options.queryKey);

  return {
    ...rest,
    ...data,
    results: data?.results ?? [],
    invalidate,
  };
}
