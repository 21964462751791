export enum ImageFolderEnum {
  ACTIVITY = 'ACTIVITY',
  CHALLENGE = 'CHALLENGE',
  HOMEWORK = 'HOMEWORK',
  BIO = 'BIO',
  BOOK = 'BOOK',
  PROJECT = 'PROJECT',
  COURSE = 'COURSE',
}

export interface ImageUploaded {
  id: number;
  image: string;
}
