import { getErrorMessage } from '@/utils/getErrorMessage';
import ErrorComponent from '../../components/common/ErrorComponent';
import Layout from '../../components/template/Layout';

interface ErrorPageProps {
  statusCode?: number;
  title?: string;
  subtitle?: string;
  error?: unknown;
}

export default function ErrorPage(props: ErrorPageProps) {
  const { statusCode, title, subtitle, error } = props;

  const message = getErrorMessage(error);

  return (
    <Layout>
      <ErrorComponent
        statusCode={statusCode}
        errorTextSubTitle={subtitle}
        errorTextTitle={title || message || ''}
      />
    </Layout>
  );
}
