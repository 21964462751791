export enum AvatarItemsEnum {
  hair = 'hair',
  base = 'base',
  face = 'face',
  hat = 'hat',
  headAcessory = 'headAcessory',
  topFaceAcessory = 'topFaceAcessory',
  bottomFaceAcessory = 'bottomFaceAcessory',
  neckAcessory = 'neckAcessory',
  shirt = 'shirt',
  coat = 'coat',
  pants = 'pants',
  shoe = 'shoe',
  leftHandAcessory = 'leftHandAcessory',
  rightHandAcessory = 'rightHandAcessory',
}

export interface Avatar {
  id: number;
  user: number;
  [AvatarItemsEnum.hair]: Hair;
  [AvatarItemsEnum.face]: Face;
  [AvatarItemsEnum.base]: Base;
  [AvatarItemsEnum.hat]: Hat;
  [AvatarItemsEnum.headAcessory]: HeadAcessory;
  [AvatarItemsEnum.topFaceAcessory]: TopFaceAcessory;
  [AvatarItemsEnum.bottomFaceAcessory]: BottomFaceAcessory;
  [AvatarItemsEnum.neckAcessory]: NeckAcessory;
  [AvatarItemsEnum.shirt]: Shirt;
  [AvatarItemsEnum.coat]: Coat;
  [AvatarItemsEnum.pants]: Pants;
  [AvatarItemsEnum.shoe]: Shoe;
  [AvatarItemsEnum.leftHandAcessory]: LeftHandAcessory;
  [AvatarItemsEnum.rightHandAcessory]: RightHandAcessory;
}

export interface InventoryItems {
  hair: BaseItem[];
  base: BaseItem[];
  face: BaseItem[];
  hat: BaseItem[];
  headAcessory: BaseItem[];
  topFaceAcessory: BaseItem[];
  bottomFaceAcessory: BaseItem[];
  neckAcessory: BaseItem[];
  shirt: BaseItem[];
  coat: BaseItem[];
  pants: BaseItem[];
  shoe: BaseItem[];
  handAcessory: BaseItem[];
}
export interface Inventory extends InventoryItems {
  avatar: number;
}

export interface BaseItem {
  id: number;
  name: string;
  rarity: string;
  baseColor: string;
  image: string;
  allowUserColor: boolean;
  default: boolean;
  itemType?: AvatarItemsEnum;
}
export interface ShopItem {
  id: number;
  item: BaseItem;
  coinsPrice: number | null;
  gemsPrice: number | null;
  isActive: boolean;
}

export interface AvatarColor {
  color: string | null;
}

export interface Hair extends AvatarColor {
  [AvatarItemsEnum.hair]: BaseItem | null;
}

export interface Base extends AvatarColor {
  [AvatarItemsEnum.base]: BaseItem | null;
}
export interface Face extends AvatarColor {
  [AvatarItemsEnum.face]: BaseItem | null;
}

export interface Hat extends AvatarColor {
  [AvatarItemsEnum.hat]: BaseItem | null;
}

export interface HeadAcessory extends AvatarColor {
  [AvatarItemsEnum.headAcessory]: BaseItem | null;
}

export interface TopFaceAcessory extends AvatarColor {
  [AvatarItemsEnum.topFaceAcessory]: BaseItem | null;
}

export interface BottomFaceAcessory extends AvatarColor {
  [AvatarItemsEnum.bottomFaceAcessory]: BaseItem | null;
}

export interface NeckAcessory extends AvatarColor {
  [AvatarItemsEnum.neckAcessory]: BaseItem | null;
}

export interface Shirt extends AvatarColor {
  [AvatarItemsEnum.shirt]: BaseItem | null;
}

export interface Coat extends AvatarColor {
  [AvatarItemsEnum.coat]: BaseItem | null;
}

export interface Pants extends AvatarColor {
  [AvatarItemsEnum.pants]: BaseItem | null;
}

export interface Shoe extends AvatarColor {
  [AvatarItemsEnum.shoe]: BaseItem | null;
}

export interface LeftHandAcessory extends AvatarColor {
  handAcessory: BaseItem | null;
}

export interface RightHandAcessory extends AvatarColor {
  handAcessory: BaseItem | any;
}
