import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import useAuth from '../../data/hook/useAuth';
import Login from './Login';
import SendEmail from './SendEmail';

export default function LoginHandler() {
  const { user } = useAuth();
  const navigate = useNavigate();

  const [mode, setMode] = useState<'login' | 'reset'>('login');

  const onHandleModeChange = (mode: 'login' | 'reset') => {
    setMode(mode);
  };

  useEffect(() => {
    let mounted = true;

    if (mounted && user !== undefined) {
      navigate('/');
    }

    return () => {
      mounted = false;
    };
  }, [user, navigate]);

  return (
    <React.Fragment>
      {mode === 'login' ? (
        <Login
          id="loginComponent"
          mode={mode}
          onChangeMode={onHandleModeChange}
        />
      ) : (
        <SendEmail id="resetComponent" onChangeMode={onHandleModeChange} />
      )}
    </React.Fragment>
  );
}
