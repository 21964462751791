import requester from '../../axios';
import { DNDItem } from '../../models/DNDItem';

export async function postDndPositionalItem(
  dndColumnId: number,
  dndItem: DNDItem,
  order: number,
): Promise<DNDItem> {
  const formData = new FormData();

  Object.entries(dndItem).forEach(([key, value]) => {
    if (value) {
      formData.set(key, value);
    }
  });

  formData.set('dndPositionalColumn', String(dndColumnId));
  formData.set('order', String(order));

  const { data } = await requester().post<DNDItem>(
    `dnd-positional-column/${dndColumnId}/dnd-positional-item/`,
    formData,
    {
      headers: {
        'Content-Type': 'multipart/form-data',
        accept: 'application/json',
      },
    },
  );

  return data;
}

export async function deleteDndPositionalItem(
  dndColumnId?: number,
  dndItemId?: number,
) {
  await requester().delete<DNDItem>(
    `dnd-positional-column/${dndColumnId}/dnd-positional-item/${dndItemId}/`,
  );
}

export async function patchDndPositionalItem(
  dndColumnId: number,
  dndItem: DNDItem,
): Promise<DNDItem> {
  const formData = new FormData();

  Object.entries(dndItem).forEach(([key, value]) => {
    if (key === 'image' && !(value instanceof File)) {
      return;
    }
    if (value) {
      formData.set(key, value);
    }
  });

  const { data } = await requester().patch<DNDItem>(
    `dnd-positional-column/${dndColumnId}/dnd-positional-item/${dndItem.id}/`,
    formData,
    {
      headers: {
        'Content-Type': 'multipart/form-data',
        accept: 'application/json',
      },
    },
  );

  return data;
}

export async function patchExistentDndPositionalItem(
  dndColumnId: number,
  dndItem: DNDItem,
): Promise<DNDItem> {
  const formData = new FormData();

  Object.entries(dndItem).forEach(([key, value]) => {
    if (key === 'image' && !(value instanceof File)) {
      return;
    }

    if (key === 'text' && !value) {
      formData.set(key, '');
    }

    if (key === 'order') return;

    if (value) {
      formData.set(key, value);
    }
  });

  const { data } = await requester().patch<DNDItem>(
    `dnd-positional-column/${dndColumnId}/dnd-positional-item/${dndItem.id}/`,
    formData,
    {
      headers: {
        'Content-Type': 'multipart/form-data',
        accept: 'application/json',
      },
    },
  );

  return data;
}
