import useTreeArea from '@/data/hook/useTreeArea';
import Area from '@/models/Area';
import { useEffect } from 'react';
import TopicTreeArea from './TopicsTreeArea';
import TopicTreeTag from './TopicsTreeTag';

export type TopicTreeItemFactoryProps = {
  area: Area;
};
export default function TopicTreeItemFactory({
  area,
}: TopicTreeItemFactoryProps) {
  const tree = useTreeArea();

  useEffect(
    () => tree.insert({ area, parentElementId: area.parentAreaId || 0 }),
    [tree, area],
  );

  if (area.isLeaf) return <TopicTreeTag area={area} />;
  else return <TopicTreeArea area={area} />;
}
