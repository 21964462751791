import { Variant } from 'framer-motion';

export const defaultAnimation: Record<string, Variant> = {
  open: {
    transition: {
      velocity: 0.1,
      staggerChildren: 0.05,
      delayChildren: 0.1,
      staggerDirection: 1,
      type: 'spring',
      duration: 0.5,
    },
    height: 'auto',
  },
  closed: {
    transition: {
      velocity: 0.1,
      staggerChildren: 0.05,
      staggerDirection: -1,
      delayChildren: 0.1,
      duration: 0.5,
      type: 'spring',
    },
    height: '0rem',
  },
};
