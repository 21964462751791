import requester from '@/axios';
import { LessonComment, Comment } from '@/models/Comment';
import JsonApiResponse from '@/models/JsonApiResponse';
import { BaseFilters } from '@/models/Service';

export const createLessonComment = async (
  comment: string,
  lessonId: number,
  reply?: number,
) => {
  const response = await requester().post<Comment>(
    `lessons/${lessonId}/comments/`,
    {
      comment,
      reply,
    },
  );
  return response;
};

export const listLessonComments = async (
  lessonId: number,
  params: BaseFilters = {},
) => {
  const { data } = await requester().get<JsonApiResponse<LessonComment>>(
    `lessons/${lessonId}/comments/`,
    {
      params,
    },
  );

  return data;
};

interface UpdateCommentParams {
  lessonId: number;
  commentId: number;
}

export const deleteLessonComment = async ({
  commentId,
  lessonId,
}: UpdateCommentParams) => {
  await requester().delete(`lessons/${lessonId}/comments/${commentId}/`);
};

export const upvoteLessonComment = async ({
  commentId,
  lessonId,
}: UpdateCommentParams) => {
  const { data: lessonComment } = await requester().post<LessonComment>(
    `lessons/${lessonId}/comments/${commentId}/add-up-vote/`,
  );
  return lessonComment;
};
