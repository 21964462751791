import { useState } from 'react';
import { TrashIcon } from '@heroicons/react/outline';
import { useTranslation } from 'react-i18next';
import MainButton from '../../common/buttons/MainButton';
import { ApiError } from '@/models/Errors';
import ModalDisable from '../../common/modals/ModalDisable';
import alert from '@/utils/UseAlert';
import { useMutation } from '@tanstack/react-query';
import LessonForm from '../../forms/LessonForm';
import useAuth from '@/data/hook/useAuth';
import { UserTypeEnum } from '@/models/User';
import ScheduledLesson from '@/models/ScheduledLesson';
import {
  deleteScheduledLesson,
  getScheduledLesson,
} from '@/data/services/scheduledLessonsServices';

interface TeacherLessonDetailProps {
  klassId: number;
  slugCourseName: string;
  updateScheduledLessons: (scheduledLesson?: ScheduledLesson) => Promise<void>;
  scheduledLesson: ScheduledLesson;
  readOnly: boolean;
  canEditScheudledLesson?: boolean;
}

export default function TeacherLessonDetail({
  klassId,
  slugCourseName,
  updateScheduledLessons,
  scheduledLesson,
  readOnly,
  canEditScheudledLesson,
}: TeacherLessonDetailProps) {
  const { user } = useAuth();

  const { id: scheduledLessonId, lesson } = scheduledLesson;

  const { t } = useTranslation('translation', {
    keyPrefix: 'klass.lessons.lessonDetail',
  });

  const onUpdateDateScheduledLesson = async () => {
    const updatedScheduledLesson = await getScheduledLesson(scheduledLessonId);
    await updateScheduledLessons(updatedScheduledLesson);
  };

  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleModalDisplay = (show = false) => setIsModalOpen(show);

  const { mutate: deleteExtraLesson, isLoading: isDeleting } = useMutation(
    deleteScheduledLesson,
    {
      async onSuccess() {
        alert.success(t('deleteSuccess'));
        await updateScheduledLessons();
      },

      onError(error) {
        const apiError = new ApiError(error as any);
        alert.error(apiError.getErrorMessage());
      },
    },
  );

  const onDelete = () => deleteExtraLesson({ klassId, scheduledLessonId });

  return (
    <div className="flex flex-col gap-4">
      <LessonForm
        canEditScheduledLesson={canEditScheudledLesson}
        klassId={klassId}
        lesson={lesson}
        readOnly={readOnly}
        slugCourseName={slugCourseName}
        scheduledLesson={scheduledLesson}
        updateLessons={onUpdateDateScheduledLesson}
        userType={user?.userType ?? UserTypeEnum.TEACHER}
      />

      <hr className="h-px my-4 border-secondary-content" />

      <MainButton
        className="flex self-end"
        color="warning"
        icon={<TrashIcon />}
        text={t('exclude')}
        onClick={() => handleModalDisplay(true)}
      />

      <ModalDisable
        isRequesting={isDeleting}
        modalType="delete"
        visible={isModalOpen}
        selectedObjectName={lesson?.name}
        translationString={'modalDisableLesson'}
        onClickConfirm={onDelete}
        onClickCancel={() => handleModalDisplay()}
      />
    </div>
  );
}
