import { dropdown } from '@/utils/animations/commom';
import { AnimatePresence, motion } from 'framer-motion';
import { twMerge } from 'tailwind-merge';

type BadgeColor =
  | 'primary'
  | 'secondary'
  | 'accent'
  | 'warning'
  | 'success'
  | 'neutral';

interface PillComponentProps {
  className?: string;
  children: any;
  color?: BadgeColor;
  onClick?: () => void;
  testId?: string;
  outline?: boolean;
  isFilled?: boolean;
}

const badgeColorClasses: Record<BadgeColor, string> = {
  primary: 'badge-primary focus-visible:ring-primary/40',
  secondary: 'badge-secondary focus-visible:ring-secondary/40',
  accent: 'badge-accent focus-visible:ring-accent/40',
  warning: 'badge-warning focus-visible:ring-warning-300',
  success: 'badge-success focus-visible:ring-success-200',
  neutral: 'badge-neutral focus-visible:ring-neutral-200',
};

export default function PillComponent(props: PillComponentProps) {
  return (
    <AnimatePresence>
      <motion.div
        {...dropdown}
        data-testid={props.testId}
        className={twMerge(
          'badge h-fit self-center leading-none content-center items-center align-middle flex',
          props.className,
          `${props.isFilled ? '' : 'badge-outline'}`,
          badgeColorClasses[props.color || 'primary'],
        )}
        onClick={props.onClick}
      >
        {props.children}
      </motion.div>
    </AnimatePresence>
  );
}
