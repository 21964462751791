import Checkbox from '@/components/common/dataInput/Checkbox';
import { WorkspaceConfigProps } from './WorkspaceOptions';

import NumberInput from '@/components/common/dataInput/NumberInput';
import { ChangeEventHandler, useState } from 'react';

import alert from '@/utils/UseAlert';
import { useController, useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

export const WorkspaceOptionsForm = ({
  control,
  values: defaultValues,
  workspace,
}: WorkspaceConfigProps) => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'activity.manageActivity.activityCode.workspaceOptions',
  });
  const values = useWatch({
    control,
  });
  const { field: maxBlocksField } = useController({
    control,
    name: 'maxBlocks',
    defaultValue: defaultValues.maxBlocks,
  });

  const { field: coinsField } = useController({
    control,
    name: 'goal.coins',
    defaultValue: defaultValues.goal?.coins,
  });

  const [defineMaxBlocks, setDefineMaxBlocks] = useState(!!values.maxBlocks);

  const onChangeMaxBlocks: ChangeEventHandler<HTMLInputElement> = e => {
    if (!workspace) return;
    const value = Number(e.target.value);
    if (value < workspace.getAllBlocks().length) {
      alert.error(t('maxBlocksAlert'));
      return;
    }
    maxBlocksField.onChange(value);
  };

  return (
    <div className="p-3 bg-base-100 w-[200px] rounded-lg shadow flex flex-col gap-2">
      <div className="flex gap-2 items-center">
        <Checkbox
          label={t('maxBlocks')}
          defaultChecked={defineMaxBlocks}
          className={{ input: 'w-5 h-5' }}
          onChange={e => {
            const checked = e.target.checked;
            setDefineMaxBlocks(checked);
            maxBlocksField.onChange(
              !checked ? '' : workspace?.getAllBlocks().length,
            );
          }}
        />
        <NumberInput
          type="number"
          className={{ input: 'w-8 h-8 disabled:bg-base-200' }}
          {...maxBlocksField}
          value={maxBlocksField.value || ''}
          onChange={onChangeMaxBlocks}
          disabled={!defineMaxBlocks}
        />
      </div>
      <NumberInput
        type="number"
        className={{ input: 'w-8 h-8' }}
        {...coinsField}
        onChange={e => {
          const value = Number(e.target.value);
          coinsField.onChange(value);
        }}
        label={t('coins')}
        labelPosition="left"
      />
    </div>
  );
};
