import { useTranslation } from 'react-i18next';
import {
  AcademicCapIcon,
  BookOpenIcon,
  CalendarIcon,
  FlagIcon,
  TagIcon,
  UserGroupIcon,
  UserIcon,
  UsersIcon,
} from '@heroicons/react/outline';

import MenuItem from '@/components/admin/main/MenuItem';
import ComponentGuard from '@/components/common/ComponentGuard';
import HeadTitle from '@/components/common/HeadTitle';
import RoundedButton from '@/components/common/buttons/RoundedButton';
import Text from '@/components/common/dataDisplay/Text';
import Layout from '@/components/template/Layout';
import useAuth from '@/data/hook/useAuth';
import { KlassStatusEnum } from '@/models/Klass';
import { UserStatusEnum, UserTypeEnum } from '@/models/User';
import { isSuperAdmin, isUnitAdmin } from '@/functions/auth';
import { createSearchParams } from 'react-router-dom';
import { EnrollmentStatusEnum } from '@/models/Enrollment';
import { ROUTES } from '@/utils/routes';
import TeachingWeek from '@/components/staff/TeachingWeek/TeachingWeek';
import NoAdminScreenForMobile from './NoAdminScreenForMobile';

export default function AdminPage() {
  const { user } = useAuth();
  const { t } = useTranslation();

  const isUnitAdminMultipleUnits =
    user && isUnitAdmin(user.userType) && user.unitsIds?.length > 1;

  const isMultipleUnits =
    isUnitAdminMultipleUnits || isSuperAdmin(user?.userType);

  const pageNumber = '1';

  const teamParams = {
    pageNumber,
  };

  const studentParams = {
    pageNumber,
    enrollmentStatus: String([EnrollmentStatusEnum.ACTIVE]),
  };

  interface PageItem {
    icon: JSX.Element;
    text: string;
    href: string;
    testId: string;
    search?: string;
  }

  const klassesParams = {
    pageNumber,
    status: [KlassStatusEnum.IN_PROGRESS, KlassStatusEnum.OPEN],
  };
  const teacherParams = {
    pageNumber,
    status: [UserStatusEnum.REGISTRED],
  };

  const unitListItem: PageItem = {
    icon: <FlagIcon />,
    text: t('adminPage.units'),
    href: '/admin/units',
    testId: 'unitsCard',
  };

  const recessListItem: PageItem = {
    icon: <CalendarIcon />,
    text: t('adminPage.recesses'),
    href: isMultipleUnits
      ? `/admin/units/${user?.unitsIds[0]}/recess-calendar`
      : '/admin/recesscalendars',
    testId: 'recessCard',
  };

  const teamListItem: PageItem = {
    icon: <UsersIcon />,
    text: t('adminPage.team'),
    href: '/admin/team',
    testId: 'teamCard',
    search: '?' + createSearchParams(teamParams),
  };

  const coursesListItem: PageItem = {
    icon: <BookOpenIcon />,
    text: t('adminPage.courses'),
    href: '/admin/courses',
    testId: 'coursesCard',
  };

  const topicsListItem: PageItem = {
    icon: <TagIcon />,
    text: t('adminPage.topics'),
    href: '/admin/topics',
    testId: 'topicCard',
  };

  let data: PageItem[] = [
    {
      icon: <UserIcon />,
      text: t('adminPage.students'),
      href: '/admin/students',
      testId: 'studentsCard',
      search: '?' + createSearchParams(studentParams),
    },
    {
      icon: <UserGroupIcon />,
      text: t('adminPage.classes'),
      href: '/admin/classes',
      testId: 'classesCard',
      search: '?' + createSearchParams(klassesParams),
    },
    {
      icon: <AcademicCapIcon />,
      text: t('adminPage.teachers'),
      href: '/admin/teachers',
      testId: 'teachersCard',
      search: '?' + createSearchParams(teacherParams),
    },
  ];

  if (user?.userType === UserTypeEnum.SUPER_ADMIN) {
    data = data.concat([
      teamListItem,
      unitListItem,
      coursesListItem,
      topicsListItem,
    ]);
  } else if (user?.userType === UserTypeEnum.UNIT_ADMIN) {
    data = data.concat([teamListItem, recessListItem]);
  }

  const AdminCards = () => {
    return (
      <div className="flex justify-between md:flex-row flex-col">
        <div className="flex-col">
          <Text
            format="poppins-600"
            text="Admin"
            className="text-primary pb-4 text-28"
          />
          <div className="flex w-full gap-2 flex-wrap pb-8">
            {data.map(listItem => {
              return (
                <div key={listItem.text}>
                  <MenuItem
                    testId={listItem.testId}
                    icon={listItem.icon}
                    pathname={listItem.href}
                    text={listItem.text}
                    search={listItem.search}
                  />
                </div>
              );
            })}
          </div>
        </div>
        <ComponentGuard roles={[UserTypeEnum.SUPER_ADMIN]}>
          <div className="flex flex-col py-14 md:pt-0 md:w-60">
            <Text
              format="poppins-600"
              text={t('adminPage.paths')}
              className="text-primary text-28"
            />
            <div className="flex flex-col justify-center md:justify-end items-center gap-2.5 pt-2.5">
              <RoundedButton
                text={t('adminPage.addStudent')}
                href="/admin/students/add"
                className="w-60"
              />
              <RoundedButton
                text={t('adminPage.createClass')}
                href={ROUTES.ADMIN.CLASSES.ACTION('add')}
                className="w-60"
              />
              <RoundedButton
                text={t('adminPage.createTeacher')}
                href="/admin/teachers/add"
                className="w-60"
              />
              <RoundedButton
                text={t('adminPage.createCourse')}
                href="/admin/courses/add"
                className="w-60"
              />
            </div>
          </div>
        </ComponentGuard>
      </div>
    );
  };

  if (user)
    return (
      <Layout>
        <HeadTitle routeInfo={t('sideMenu.admin')} />
        <div className="md:hidden">
          <NoAdminScreenForMobile />
        </div>
        <div className="hidden md:block w-full">
          <div className="flex flex-col gap-2">
            <AdminCards />

            <TeachingWeek user={user} className={{ section: 'h-[45rem]' }} />
          </div>
        </div>
      </Layout>
    );

  return null;
}
