import React from 'react';
import { useTranslation } from 'react-i18next';
import Text from '../../../components/common/dataDisplay/Text';
import HeadTitle from '../../../components/common/HeadTitle';
import CoursePath from '../../../models/Course';

interface VersioningHeaderProps {
  page: 'version' | 'edit' | 'draft';
  course?: CoursePath;
}

function VersioningHeader({ page, course }: VersioningHeaderProps) {
  const { t } = useTranslation('translation', {
    keyPrefix: 'adminPage.lesson',
  });
  function handlePageTitle() {
    let translateKey = 'editingTitle';
    if (page !== 'edit') {
      translateKey = page === 'draft' ? 'draftTitle' : 'versioningTitle';
    }
    return t(translateKey);
  }
  const pageTitle = handlePageTitle();
  const courseAbbreviation = course?.course.abbreviation ?? '';
  const title = courseAbbreviation
    ? `${courseAbbreviation} - ${pageTitle}`
    : pageTitle;
  return (
    <div data-testid="versioningHeader">
      <HeadTitle routeInfo={title} />
      <Text
        text={pageTitle}
        format="poppins-600"
        className="text-primary"
        size="text-24"
      />
      {page !== 'draft' && (
        <div className="mt-6 mb-2.5" data-testid={`${page}Warning`}>
          <Text
            size="text-16"
            text={t(
              page === 'version' ? 'versioningWarning' : 'editingWarning',
            )}
            className="text-base-content"
          />
        </div>
      )}
    </div>
  );
}

export default VersioningHeader;
