import HeadTitle from '@/components/common/HeadTitle';
import PageTitle from '@/components/common/PageTitle';
import Layout from '@/components/template/Layout';

import React from 'react';
import { useTranslation } from 'react-i18next';
import NotificationsPageContent from '../../components/notifications/page/NotificationsPageContent';

export default function NotificationsPage() {
  const { t } = useTranslation('translation', {
    keyPrefix: 'alerts',
  });
  return (
    <React.Fragment>
      <HeadTitle routeInfo={t('title')} />

      <Layout>
        <div className="w-full">
          <PageTitle headingText={t('title')} position="mb-4 lg:mb-12" />
          <NotificationsPageContent />
        </div>
      </Layout>
    </React.Fragment>
  );
}
