import React from 'react';
import { useTranslation } from 'react-i18next';
import RoundedButton from '../../../components/common/buttons/RoundedButton';
import Text from '../../../components/common/dataDisplay/Text';
import Modal from '../../../components/common/modals/Modal';

interface CancelChangesModalProps {
  closeModal(): void;
  visible: boolean;
  itemName: string;
  isLoading: boolean;
  onConfirm(): void | Promise<void>;
}

export default function CancelChangesModal(props: CancelChangesModalProps) {
  const { closeModal, visible, itemName, isLoading, onConfirm } = props;
  const { t } = useTranslation('translation', {
    keyPrefix: 'adminPage.lesson',
  });
  return (
    <Modal onClose={closeModal} visible={visible}>
      <div className="flex flex-col items-center gap-6">
        <Text
          text={t('modal.cancel.title')}
          className="text-error text-center"
          format="rubik-500"
          size="text-20"
        />
        <Text text={itemName} format="rubik-500" size="text-20" />
        <Text text={t('modal.cancel.warning')} className="text-center" />
        <div className="flex items-center gap-7 w-full">
          <RoundedButton
            text={t('modal.cancel.cancel')}
            onClick={closeModal}
            color="neutral"
            className="w-full"
          />
          <RoundedButton
            text={t('modal.cancel.confirm')}
            loading={isLoading}
            onClick={onConfirm}
            testId="confirmCancelAction"
            color="warning"
            className="w-full"
          />
        </div>
      </div>
    </Modal>
  );
}
