import { useTranslation } from 'react-i18next';
import Text from '../dataDisplay/Text';
import { ArrowDownIcon } from '@heroicons/react/outline';
import ConditionalRenderer from '../ConditionalRenderer';
import { ClassNameComponent } from '@/utils/ClassNameComponent';
import { twMerge } from 'tailwind-merge';
import { ReactNode } from 'react';

interface SortToggleProps {
  id: string;
  hideLabel?: boolean;
  icon?: ReactNode;
  text?: {
    ascending: string;
    descending: string;
  };
  isDescending: boolean;
  onToggleSort?(checked: boolean): void;
  className?: ClassNameComponent;
  testId?: string;
}

const SortToggle = ({
  isDescending,
  hideLabel = false,
  icon,
  text,
  id = 'sort',
  onToggleSort,
  className,
  testId,
}: SortToggleProps) => {
  const defaultIconClassName = 'w-3.5';
  const { t } = useTranslation('translation', {
    keyPrefix: 'manageKlasses.dataKlassTable.scheduledLessonProgressView',
  });
  return (
    <div className="flex items-center gap-2">
      <ConditionalRenderer condition={!hideLabel}>
        <Text text={t('sort')} size="text-14" />
      </ConditionalRenderer>
      <label htmlFor={id} data-testid={testId ?? 'sortLabel'}>
        <div
          className={twMerge(
            'cursor-pointer text-primary flex gap-2 items-center',
            className?.div,
          )}
        >
          <Text
            size="text-14"
            text={
              isDescending
                ? text?.descending || t('descending')
                : text?.ascending || t('ascending')
            }
          />
          <input
            type="checkbox"
            className="sr-only"
            id={id}
            data-testid={id}
            checked={isDescending}
            onChange={() => {
              onToggleSort?.(!isDescending);
            }}
          />

          <ConditionalRenderer
            condition={icon}
            fallback={
              <ArrowDownIcon
                className={`${defaultIconClassName} transition-all duration-150 ease-in-out transform ${
                  isDescending ? '' : 'rotate-180'
                }`}
              />
            }
          >
            {icon}
          </ConditionalRenderer>
        </div>
      </label>
    </div>
  );
};

export default SortToggle;
