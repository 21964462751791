import { TrashIcon } from '@heroicons/react/outline';
import React from 'react';
import IconButton from '../buttons/IconButton';
import Text from '../dataDisplay/Text';
import { CameraIcon } from '@heroicons/react/solid';
import { useTranslation } from 'react-i18next';
import ConditionalRenderer from '../ConditionalRenderer';
import AstroImage from '../AstroImage';
import { twMerge } from 'tailwind-merge';

interface ImageInputProps extends React.InputHTMLAttributes<HTMLInputElement> {
  testId?: string;
  isDisabled?: boolean;
  image: string | File | null;
  onRemove?: (image: any) => void;
  hideRemoveButton?: boolean;
  hidePreview?: boolean;
  hideText?: boolean;
  color?: string;
  errorMessage?: string;
}

export default function ImageInput(props: ImageInputProps) {
  const { t } = useTranslation('translation', {
    keyPrefix: 'imageInput',
  });
  const {
    onRemove,
    hideText,
    testId,
    isDisabled,
    className,
    image,
    hideRemoveButton,
    hidePreview,
    color,
    errorMessage,
    ...rest
  } = props;

  const handleRemove = () => {
    onRemove && onRemove(null);
  };

  const errorClassName = 'bg-error text-error-content';
  const defaultClassName =
    'bg-secondary-content text-secondary hover:bg-secondary-content';
  const paddingClassName = !!image || hideText ? 'p-2' : 'py-2 px-4 mr-4';

  return (
    <React.Fragment>
      <label
        className={`lock ${className || ''} ${
          !!image ? 'flex gap-2 items-end' : ''
        }`}
      >
        <input
          accept="image/png, image/svg, image/jpg, image/jpeg"
          {...rest}
          data-testid={testId ? `${testId}Input` : undefined}
          type="file"
          disabled={isDisabled}
          style={{ opacity: 0, position: 'absolute', zIndex: -1 }}
        />
        <div
          data-testid={testId}
          className={twMerge(
            `${
              errorMessage ? errorClassName : defaultClassName
            } ${paddingClassName}`,
            'cursor-pointer block w-fit text-16 rounded-full border-0 font-500 transition-all',
            isDisabled ? 'bg-neutral-content text-neutral/50' : '',
            color,
          )}
        >
          {!!image || hideText ? (
            <CameraIcon className="w-4 h-4" />
          ) : (
            t('chooseImage')
          )}
        </div>
        <ConditionalRenderer condition={image && !hidePreview}>
          {image && <ImagePreview image={image} />}
        </ConditionalRenderer>
        <ConditionalRenderer condition={errorMessage}>
          <Text text={errorMessage} className="text-error" />
        </ConditionalRenderer>
      </label>
      <ConditionalRenderer
        condition={image && !isDisabled && !hideRemoveButton}
      >
        <div
          onClick={handleRemove}
          className="mt-2 flex gap-2 text-16 text-error font-500 items-center"
        >
          <Text text={t('delete')} />
          <IconButton icon={<TrashIcon className="w-5 h-5 text-error" />} />
        </div>
      </ConditionalRenderer>
    </React.Fragment>
  );
}

interface ImagePreviewProps {
  image: string | File;
}

const ImagePreview: React.FC<ImagePreviewProps> = ({ image }) => {
  if (typeof image == 'string') {
    return <AstroImage src={image} className="w-32" />;
  }

  return (
    <div className="mt-2 flex gap-5 items-center">
      <img src={URL.createObjectURL(image)} alt="Preview" className="w-32" />
      {image.name}
    </div>
  );
};
