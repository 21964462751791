import MainButton from '@/components/common/buttons/MainButton';
import Tag from '@/components/common/dataDisplay/Tag';
import { HistoricPanel } from '@/components/historic/HistoricPanel';
import User from '@/models/User';
import { fadeIn } from '@/utils/animations/commom';
import { ExternalLinkIcon, FolderOpenIcon } from '@heroicons/react/outline';
import { motion } from 'framer-motion';
import { useTranslation } from 'react-i18next';

export default function StudentHistoric({ student }: { student: User }) {
  const { t } = useTranslation('translation', {
    keyPrefix: 'studentHistoric',
  });

  return (
    <motion.div className="flex flex-col w-full gap-2 px-8 py-5" {...fadeIn}>
      <div className="flex gap-10 items-center">
        <Tag text={t('title')} icon={<FolderOpenIcon className="w-6 h-6" />} />
        <MainButton
          color="custom"
          className="text-primary items-center font-500 flex flex-row-reverse gap-1.5"
          text={t('fullScreen')}
          href={`/admin/student-history/${student.id}`}
          icon={<ExternalLinkIcon className="w-4 h-4" />}
        />
      </div>
      <HistoricPanel key={student.id} studentId={student.id} />
    </motion.div>
  );
}
