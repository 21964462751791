import ReactGA from 'react-ga4';

export const initGA = () => {
  const analyticsId = process.env.REACT_APP_ANALYTICS_TRACKING_KEY;

  if (!analyticsId) return;

  const script = document.createElement('script');
  script.src = `https://www.googletagmanager.com/gtag/js?id=${analyticsId}`;
  script.async = true;
  document.head.appendChild(script);

  window.dataLayer = window.dataLayer || [];
  function gtag() {
    window.dataLayer.push(arguments);
  }
  gtag('js', new Date());
  gtag('config', analyticsId);

  ReactGA.initialize(analyticsId);
};
