import { useTranslation } from 'react-i18next';
import LoadingView from '../../../../pages/courses/LoadingView';
import ErrorComponent from '../../../common/ErrorComponent';
import Text from '../../../common/dataDisplay/Text';
import SubmissionListContainer from './SubmissionListContainer';
import User from '../../../../models/User';

interface StudentActivitiesListProps {
  isKlassLoading: boolean;
  isKlassError: boolean;
  students: User[];
  children: React.ReactNode;
}
export default function StudentActivitiesList({
  isKlassError,
  isKlassLoading,
  students,
  children,
}: StudentActivitiesListProps) {
  const { t } = useTranslation('translation', {
    keyPrefix: 'lesson.teacher.activity.klass',
  });
  if (isKlassLoading) {
    return <LoadingView />;
  } else if (isKlassError) {
    return (
      <ErrorComponent
        statusCode={404}
        errorTextSubTitle={t('errorTextSubTitle')}
        errorTextTitle={t('errorTextTitle')}
      />
    );
  } else if (!students.length) {
    return <Text text={`${t('noStudents')} (°ロ°)☝`} />;
  }
  return <SubmissionListContainer>{children}</SubmissionListContainer>;
}
