import { Variant, useAnimation, useInView } from 'framer-motion';
import { useRef, useEffect } from 'react';

type Variants = Record<'initial' | 'animate', Variant>;

const useAnimationOnView = ({
  variants,
  custom = 0,
}: {
  variants: Variants;
  custom?: number;
}) => {
  const controls = useAnimation();
  const ref = useRef(null);

  const inView = useInView(ref);

  useEffect(() => {
    if (inView) {
      controls.start('animate');
    }
  }, [controls, inView]);

  return {
    variants,
    ref,
    animate: controls,
    initial: 'initial',
    custom: custom,
  };
};

export default useAnimationOnView;
