import {
  AbsenceNotificationGroupBy,
  AbsenceNotificationScrollHandler,
} from './AbsenceNotificationGroups';
import Klass, { Teacher } from '@/models/Klass';
import { handleUserFullName } from '@/functions/handleUserFullName';
import User from '@/models/User';
import { ListAbsenceNotificationParams } from '@/data/services/absenceNotificationServices';
import useInfiniteService from '@/data/hook/useInfiniteService';
import { klassesQueryKeys, teachersQuerykeys } from '@/data/services/querykeys';

type UseGroupItemsParams = {
  groupBy: AbsenceNotificationGroupBy;
};

export type AbsenceNotificationGroupItem = {
  title: string;
  key: number;
  notificationFilters?: ListAbsenceNotificationParams;
};

type UseGroupFiltersParams = {
  filters?: ListAbsenceNotificationParams;
};

export const useGroupFilters = ({
  filters: initialFilters = {},
}: UseGroupFiltersParams = {}) => {
  const filters = {
    ...initialFilters,
    latestOnly: true,
    hasRead: false,
  };
  const groupFilters = {
    ...initialFilters,
    ...filters,
    pageSize: 5,
  };

  return {
    filters,
    groupFilters,
  };
};

export const useGroupItems = ({ groupBy }: UseGroupItemsParams) => {
  const commonFilters = {
    hasAbsenceNotification: true,
  };

  const {
    results: teachers,
    isInitialLoading: isLoadingTeachers,
    fetchNextPage: fetchNextTeacherPage,
    hasNextPage: hasNextTeacherPage,
    isFetchingNextPage: isFetchingNextTeacherPage,
  } = useInfiniteService({
    ...teachersQuerykeys.list(commonFilters)._ctx.infinity,
    enabled: groupBy === 'teacher',
  });

  const {
    results: klasses,
    isInitialLoading: isLoadingKlassess,
    fetchNextPage: fetchNextKlassPage,
    hasNextPage: hasNextKlassPage,
    isFetchingNextPage: isFetchingNextKlassPage,
  } = useInfiniteService({
    ...klassesQueryKeys.list(commonFilters)._ctx.infinity,
    enabled: groupBy === 'klass',
  });

  type Group = {
    items: AbsenceNotificationGroupItem[];
    isLoading: boolean;
    scrollHandler?: AbsenceNotificationScrollHandler;
  };

  const klassScrollHandler: AbsenceNotificationScrollHandler = {
    fetchNextPage: fetchNextKlassPage,
    hasNextPage: hasNextKlassPage ?? false,
    isFetchingNextPage: isFetchingNextKlassPage,
  };

  const teacherScrollHandler: AbsenceNotificationScrollHandler = {
    fetchNextPage: fetchNextTeacherPage,
    hasNextPage: hasNextTeacherPage ?? false,
    isFetchingNextPage: isFetchingNextTeacherPage,
  };

  const defaultGroup: Group = {
    isLoading: false,
    items: [],
  };

  const items: Record<AbsenceNotificationGroupBy, Group> = {
    klass: {
      items: klasses.map(parseKlassToGroupItem),
      isLoading: isLoadingKlassess,
      scrollHandler: klassScrollHandler,
    },
    teacher: {
      items: teachers.map(parseTeacherToGroupItem),
      isLoading: isLoadingTeachers,
      scrollHandler: teacherScrollHandler,
    },
    all: defaultGroup,
  };

  return items[groupBy];
};

const parseKlassToGroupItem = (klass: Klass) => ({
  key: klass.id,
  title: klass.name,
  notificationFilters: {
    klassId: klass.id,
  },
});

const parseTeacherToGroupItem = (teacher: Teacher) => ({
  ...createUserGroupInfo(teacher),
  notificationFilters: {
    teacherId: teacher.id,
  },
});

const createUserGroupInfo = (
  user: User,
): Pick<AbsenceNotificationGroupItem, 'key' | 'title'> => {
  return {
    title: handleUserFullName(user),
    key: user.id,
  };
};
