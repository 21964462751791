import Header from './Header';
import ForceAuth from '../auth/ForceAuth';
import SidebarMobile from './Sidebar/SidebarMobile';
import SidebarDesktop from './Sidebar/SidebarDesktop';
import MadeByCtrlPlay from '../common/MadeByCtrlPlay';
import { twMerge } from 'tailwind-merge';
import { ReactNode } from 'react';

type LayoutProps = {
  children?: ReactNode;
  className?: string;
};

export default function Layout(props: LayoutProps) {
  const { className, children } = props;
  return (
    <ForceAuth>
      <div className="flex min-h-screen">
        <SidebarMobile />
        <SidebarDesktop />
        <div
          className={twMerge(
            'flex flex-col py-2.5 px-5 xl:px-8 lg:px-6 w-full overflow-hidden',
            className,
          )}
        >
          <Header />
          <div className="flex flex-col flex-grow w-full gap-4">{children}</div>
          <MadeByCtrlPlay />
        </div>
      </div>
    </ForceAuth>
  );
}
