import { StudentAgeEnum } from '../../models/User';
import getCourseStudentAge from '../../utils/getCourseStudentAge';

interface TrialInfoCardProps {
  studentAge: StudentAgeEnum;
  header: JSX.Element;
  children: JSX.Element;
  className?: string;
}

export default function TrialInfoCard(props: TrialInfoCardProps) {
  const { studentAge, header, children, className } = props;

  const course = getCourseStudentAge(studentAge);

  return (
    <div
      className={`bg-primary-content items-center pr-3.5 pl-6 py-6 flex flex-col gap-5 rounded-2xl w-fit relative after:content-[''] after:w-full after:h-full after:absolute after:top-2 after:left-2 after:border after:pointer-events-none after:rounded-2xl after:border-accent ${className}`}
    >
      {header}
      <img
        className="w-full max-w-[244px] h-auto rounded-2xl"
        src="/images/course-default-image.png"
        alt={`ctrl+${course}`}
      />
      {children}
    </div>
  );
}
