import { AxiosResponse } from 'axios';
import requester from '@/axios';
import { TextActivityProgress } from '@/models/ActivityProgress';

export interface GetTextActivityProgressParams {
  textActivityId: number;
  textProgressId: number;
}

export const getTextActivityProgress = async ({
  textActivityId,
  textProgressId,
}: GetTextActivityProgressParams) => {
  const { data: textActivityProgress } =
    await requester().get<TextActivityProgress>(
      `text-activity/${textActivityId}/text-activity-progress/${textProgressId}/`,
    );
  return textActivityProgress;
};

type UpdateTextActivity = Pick<TextActivityProgress, 'isDone'>;

export const updateTextActivityProgress = async (
  { textActivityId, textProgressId }: GetTextActivityProgressParams,
  body: UpdateTextActivity,
) => {
  type Response = AxiosResponse<TextActivityProgress>;
  const { data: textActivityProgress } = await requester().patch<
    UpdateTextActivity,
    Response
  >(
    `text-activity/${textActivityId}/text-activity-progress/${textProgressId}/`,
    body,
  );
  return textActivityProgress;
};
