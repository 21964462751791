const INCREMENT = 100;
const EXPONENTIAL = 1.2;
const INITIAL_LEVEL = 1;

export function levelPercentageToXpCount(
  level: number,
  percentage: number = 1,
): number {
  let xpTotal = 0;
  let nextLevelStep = 0;
  level -= INITIAL_LEVEL;
  let nextLevelXp: number = 0;
  for (level; level >= 0; level--) {
    nextLevelStep = getExponentialValue(nextLevelStep);
    xpTotal += nextLevelStep;
    if (level === 1) {
      nextLevelXp = xpTotal;
    }
  }
  const levelDiff = xpTotal - nextLevelXp;
  const levelPercentage = levelDiff * percentage + nextLevelXp;

  return levelPercentage;
}

interface LevelCounter {
  level: number;
  percentage: number;
  totalLevel: number;
}

export function calculateLevel(xp: number): LevelCounter {
  let level = INITIAL_LEVEL;
  let nextLevelStep = 0;

  while (xp > getExponentialValue(nextLevelStep)) {
    level++;
    nextLevelStep = getExponentialValue(nextLevelStep);
    xp -= nextLevelStep;
  }

  const maxXpLevel = getExponentialValue(nextLevelStep);
  const isLevelCompleted = xp === maxXpLevel;

  level += isLevelCompleted ? 1 : 0;
  const totalLevel = levelPercentageToXpCount(level);
  const percentage = isLevelCompleted ? 0 : xp / maxXpLevel;

  return {
    level,
    percentage,
    totalLevel,
  };
}

function getExponentialValue(value: number) {
  return value + INCREMENT * EXPONENTIAL;
}
