import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { AdminProvider } from '../context/AdminContext';
import { AuthProvider } from '../context/AuthContext';
import { AvatarProvider } from '../context/AvatarContext';
import { CourseEditingProvider } from '../context/CourseEditingContext';
import { LessonProvider } from '../context/LessonContext';
import { SidebarProvider } from '../context/SidebarContext';
import { StudentProvider } from '../context/StudentContext';
import { PropsWithChildren } from 'react';
import { HelmetProvider } from 'react-helmet-async';
import { ConfigProvider } from '../context/ConfigContext';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import TreeProvider from '../context/TreeContext';

const client = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
});

export default function Providers({ children }: PropsWithChildren) {
  return (
    <QueryClientProvider client={client}>
      <ReactQueryDevtools initialIsOpen={false} position="bottom-right" />
      <HelmetProvider>
        <ConfigProvider>
          <AuthProvider>
            <SidebarProvider>
              <AvatarProvider>
                <StudentProvider>
                  <LessonProvider>
                    <AdminProvider>
                      <TreeProvider>
                        <CourseEditingProvider>
                          {children}
                        </CourseEditingProvider>
                      </TreeProvider>
                    </AdminProvider>
                  </LessonProvider>
                </StudentProvider>
              </AvatarProvider>
            </SidebarProvider>
          </AuthProvider>
        </ConfigProvider>
      </HelmetProvider>
    </QueryClientProvider>
  );
}
