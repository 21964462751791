import { FilterFields } from '@/data/context/BankFiltersContext';
import useAuth from '@/data/hook/useAuth';
import { LessonFilter } from '@/data/services/lessonServices';
import { coursePathsQueryKeys } from '@/data/services/querykeys';
import { VersioningStatusEnum } from '@/enums/VersioningStatus';
import { UserTypeEnum } from '@/models/User';
import cleanupObj from '@/utils/cleanupObj';
import { useQuery } from '@tanstack/react-query';
import { compact } from 'lodash';
import { useParams } from 'react-router-dom';

export const useBuildFilters = (filters: FilterFields): LessonFilter => {
  const { slugCourseName = '' } = useParams();
  const { user } = useAuth();
  const { data: course } = useQuery({
    ...coursePathsQueryKeys.get(slugCourseName),
    enabled: !!slugCourseName,
    refetchOnMount: false,
  });

  const { unit, tool, author, courseAbbreviation } = filters || {};
  const filterLessons: LessonFilter = {};

  const authorWithoutMy = author.filter(a => a !== 'my');

  if (author?.includes('my') && !authorWithoutMy.length) {
    filterLessons.authorId = user?.id;
  }

  filterLessons.authorUnitId = unit.join(',');
  filterLessons.courseAbbreviation = courseAbbreviation
    ? course?.course.abbreviation
    : undefined;
  filterLessons.tools = tool?.join(',');
  filterLessons.authorUserType = compact(authorWithoutMy) as UserTypeEnum[];
  filterLessons.status = VersioningStatusEnum.PUBLISHED;
  filterLessons.search = filters?.search;
  filterLessons.inBank = filters?.inBank;

  const filterCleanup = cleanupObj(filterLessons);

  return filterCleanup;
};
