import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { BookOpenIcon } from '@heroicons/react/outline';

import { SimplifiedCourseProgress } from '@/models/StudentCourseProgress';
import User from '@/models/User';
import { StudentCourseDisplay } from './StudentCourseDisplay';
import { useStudentLessons } from '@/data/hook/useStudentLessons';
import { IconWithText } from '@/components/common/dataDisplay/IconWithText';
import ConditionalRenderer from '@/components/common/ConditionalRenderer';
import Skeleton from '@/components/common/Skeleton';
import Text from '@/components/common/dataDisplay/Text';
import { getErrorMessage } from '@/utils/getErrorMessage';
import { ActivityProgressView } from './ActivityProgressView';
import InfinityList from '@/components/common/InfinityList';
import { LessonView } from './LessonView';
import { PresenceEnum } from '@/models/ScheduledLessonReport';

export type CourseProgressViewProps = {
  user: User;
  progress: SimplifiedCourseProgress;
};

export function CourseProgressView({
  user,
  progress,
}: CourseProgressViewProps) {
  const { t } = useTranslation('translation', {
    keyPrefix: 'dashboard',
  });

  const { coursePath, enrollmentStatus, klassId } = progress;

  const {
    scheduledLessons,
    lessons,
    hasNextPage,
    isFetchingNextPage,
    error,
    validEnrollment,
    loading,
    fetchNextPage,
    reports,
  } = useStudentLessons({
    slugCourseName: coursePath.slug,
    enrollmentStatus,
    klassId,
  });

  const scheduledLessonsMemo = useMemo(
    () =>
      scheduledLessons.map(scheduled => (
        <LessonView
          key={scheduled.id}
          lesson={scheduled.lesson}
          scheduledLesson={scheduled}
          url={`/courses/${coursePath.slug}/lesson/${scheduled.lesson.id}/class/${klassId}`}
          presence={
            reports.find(report => report.scheduledLesson === scheduled.id)
              ?.presence
          }
        />
      )),
    [coursePath.slug, klassId, reports, scheduledLessons],
  );

  const lessonsMemo = useMemo(
    () =>
      lessons
        .sort((a, b) => a.order - b.order)
        .map(lesson => (
          <LessonView
            key={lesson.id}
            lesson={lesson}
            url={`/courses/${progress.coursePath.slug}/lesson/${lesson.id}`}
          />
        )),
    [lessons, progress.coursePath.slug],
  );

  const presences = reports.filter(
    report => report.presence === PresenceEnum.ATTENDED,
  ).length;

  const count = lessons.length || scheduledLessons.length;

  const activitiesIds = lessons.length
    ? lessons.flatMap(lesson => lesson.activities)
    : scheduledLessons.flatMap(({ lesson }) => lesson.activities);

  return (
    <div
      data-testid="courseProgressView"
      className="flex flex-col md:flex-row justify-center items-stretch p-5 sm:p-8 md:p-6 
      gap-3.5 sm:gap-6 md:gap-3.5 rounded-lg sm:rounded-2xl bg-accent-content w-full shadow-default"
    >
      <StudentCourseDisplay
        courseName={coursePath.course.name}
        userId={user.id}
      />
      <hr className="md:w-px md:h-full md:bg-primary/40 md:border-0 h-px border-primary/40" />

      <div className="w-full flex gap-4 justify-start items-start">
        <div className="flex flex-col gap-4 justify-start items-start w-[50%] h-full">
          <IconWithText
            icon={
              <BookOpenIcon
                className="shrink-0 w-5 md:w-6 h-auto text-base-100 bg-accent rounded-full p-0.5"
                strokeWidth={1.3}
              />
            }
            text={t('totalClasses')}
            className={{ text: 'sm:text-18 md:text-20', base: 'text-accent' }}
          />

          <div className="flex flex-col items-start w-full gap-2">
            <ConditionalRenderer condition={validEnrollment}>
              <div className="flex self-end items-end">
                <Text
                  testId="presencesCount"
                  text={presences}
                  format="rubik-500"
                  size="text-16 md:text-24"
                  className="flex text-secondary items-center"
                />

                <Text
                  testId="scheduledLessonsCount"
                  text={`/ ${count}`}
                  format="rubik-500"
                  size="text-12 md:text-16"
                  className="flex text-accent items-center"
                />
              </div>
            </ConditionalRenderer>

            <ConditionalRenderer condition={loading}>
              <Skeleton
                testId="loadingLessons"
                className="w-full h-28 bg-primary-content rounded-lg"
              />
            </ConditionalRenderer>

            <ConditionalRenderer condition={error}>
              <Text
                text={getErrorMessage(error)}
                format="rubik-500"
                className="text-primary"
              />
            </ConditionalRenderer>

            <ConditionalRenderer condition={!loading && !error}>
              <InfinityList
                className="flex flex-row flex-wrap gap-1 items-start"
                hasNextPage={hasNextPage}
                isFetchingNextPage={isFetchingNextPage}
                onReachEnd={fetchNextPage}
              >
                <ConditionalRenderer condition={validEnrollment}>
                  {scheduledLessonsMemo}
                </ConditionalRenderer>

                <ConditionalRenderer condition={!validEnrollment}>
                  {lessonsMemo}
                </ConditionalRenderer>
              </InfinityList>
            </ConditionalRenderer>
          </div>
        </div>
        <hr className="md:w-px md:h-full md:bg-primary/40 md:border-0 h-px border-primary/40" />
        <ActivityProgressView
          coursePathSlug={coursePath.slug}
          activitiesIds={activitiesIds}
          loading={loading}
          klassId={validEnrollment ? klassId : undefined}
          courseProgressId={progress.id}
        />
      </div>
    </div>
  );
}
