import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useQuery } from '@tanstack/react-query';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

import ToolCard from '@/components/common/ToolCard';
import {
  AstroBreadcrumb,
  breadcrumbArray,
} from '@/components/common/AstroBreadcrumb';
import ChallengeCard from '@/components/common/ChallengeCard';
import ComponentGuard from '@/components/common/ComponentGuard';
import ConditionalRenderer from '@/components/common/ConditionalRenderer';
import Controls from '@/components/common/Controls';
import ErrorComponent from '@/components/common/ErrorComponent';
import HeadTitle from '@/components/common/HeadTitle';
import MaterialCard from '@/components/common/MaterialCard';
import PageTitle from '@/components/common/PageTitle';
import Skeleton from '@/components/common/Skeleton';
import { BugReport } from '@/components/common/bugReport/BugReport';
import { StudentFeedbackCard } from '@/components/lessons/feedback/StudentFeedbackCard';
import { TeacherFeedbackCard } from '@/components/lessons/feedback/TeacherFeedbackCard';
import LessonCommentSection from '@/components/teacher/LessonCommentSection';
import Layout from '@/components/template/Layout';
import useLessonContext from '@/data/hook/lesson';
import useStudentContext from '@/data/hook/student';
import useAuth from '@/data/hook/useAuth';
import { isStudent, isTeacher } from '@/functions/auth';
import { formatLessonNamePrefix } from '@/functions/lessonsName';
import Klass from '@/models/Klass';
import { UserTypeEnum } from '@/models/User';
import { makeLessonHeadTitle } from '@/utils/lessonHeadTitle';
import { bookNotification } from '@/utils/lessonProgressNotifications';
import {
  booleanToNotification,
  handleToolNotification,
} from '@/utils/notification';
import { shouldShowScheduledLesson } from '@/utils/shouldShowScheduledLesson';
import ErrorPage from '../notFound/ErrorPage';
import InfiniteSearchInput from '@/components/common/dataInput/InfiniteSearchInput';
import { translateKlassName } from '@/utils/translateKlassName';
import LessonBar from '@/components/lessons/LessonBar/LessonBar';
import ActivityCard from '@/components/common/ActivityCard';
import { ScheduledLessonTypeEnum } from '@/models/ScheduledLesson';
import ActivityContainer from '@/components/common/ActivityContainer';
import InstructionsVideo from '@/components/common/InstructionsVideo';
import TeacherMaterialView from '@/components/lessons/book/TeacherMaterialView';
import StudentMaterialView from '@/components/lessons/book/StudentMaterialView';
import AuthGuard from '@/components/auth/AuthGuard';
import Challenge from '@/components/lessons/challenge/Challenge';
import InitialView from '@/components/lessons/InitialView';
import ActivityViewProvider from '@/data/context/ActivityViewContext';
import { REQUEST_STALE_TIME_IN_MS } from '@/constants';
import {
  coursePathsQueryKeys,
  homeworkActivitiesQueryKeys,
  homeworkProgressQueryKeys,
  klassesQueryKeys,
} from '@/data/services/querykeys';
import useListService from '@/data/hook/useListService';
import { getAuthorizedUnits } from '@/utils/getAuthorizedUnits';

export default function KlassView() {
  const { user } = useAuth();
  const navigate = useNavigate();
  const location = useLocation();

  const params = useParams();
  const { slugCourseName, lessonId, klassId } = params;

  const {
    setParams: setLessonParams,
    lessonProgress,
    scheduledLesson,
    pagination,
    lesson: lessonData,
    loading: loadingList,
  } = useLessonContext();

  const {
    nextLesson,
    currentProgress: courseProgress,
    loadingProgress: loadingCourseProgress,
    klass: studentKlass,
    progressError: courseProgressError,

    setParams: setCourseParams,
  } = useStudentContext();

  const studentView = !!user && isStudent(user.userType);
  const teacherView = !!user && isTeacher(user.userType);

  const {
    data: coursePathData,
    isInitialLoading: loadingCourse,
    error: courseError,
  } = useQuery({
    ...coursePathsQueryKeys.get(slugCourseName ?? ''),
    enabled: !!slugCourseName && !courseProgress,
  });

  const coursePath = courseProgress?.coursePath || coursePathData;

  const { data: unitKlass } = useQuery({
    ...klassesQueryKeys.get(Number(klassId)),
    enabled: !isNaN(Number(klassId)) && !studentKlass,
  });

  const klass = studentKlass || unitKlass;

  const lesson = lessonProgress?.lesson || lessonData;

  const loading = loadingCourseProgress || loadingCourse || loadingList;

  const error = courseProgressError || courseError;

  const {
    hasAccessedTool,
    hasWatchedInstruction,
    challengeProgress,
    homeworkProgress,
  } = lessonProgress || {};

  const { t } = useTranslation('translation');

  const challhengeNotification = challengeProgress
    ? booleanToNotification(challengeProgress.hasDone)
    : undefined;

  const shouldBlockLesson =
    studentView &&
    !!scheduledLesson &&
    !!lessonProgress &&
    !shouldShowScheduledLesson(scheduledLesson, lessonProgress, nextLesson);

  const homeworkActivityId = scheduledLesson?.homeworkActivity;

  const hashomeworkActivityId =
    !!homeworkActivityId && !isNaN(Number(homeworkActivityId));

  const { results: homeworksActivities } = useListService({
    ...homeworkActivitiesQueryKeys.list({
      activity: homeworkActivityId ?? 0,
    }),
    enabled: !!hashomeworkActivityId && !studentView,
    staleTime: REQUEST_STALE_TIME_IN_MS,
  });

  const homeworkActivity = homeworksActivities?.at(0);

  const { results: homeworkProgresses } = useListService({
    enabled: !!homeworkActivity?.id && !studentView,
    refetchOnWindowFocus: false,
    ...homeworkProgressQueryKeys.list(homeworkActivity?.id ?? 0),
  });

  const freeLesson = scheduledLesson?.type === ScheduledLessonTypeEnum.FREE;

  const onSelectKlass: (klass: Klass) => void = ({ coursePathSlug, id }) => {
    const isSameCourse = coursePathSlug === slugCourseName;
    if (isSameCourse) {
      navigate(`/courses/${slugCourseName}/lesson/${lessonId}/class/${id}`);
    } else {
      navigate(`/courses/${coursePathSlug}/class/${id}`, {
        replace: true,
      });
    }
  };

  const onDeselectKlass = () => {
    navigate(`/courses/${slugCourseName}/lesson/${lessonId}`);
  };

  const pathnames = location.pathname.split('/');

  const activeBookCard = pathnames.includes('book') && !pathnames.includes('t');

  const activeTeacherBookCard =
    pathnames.includes('book') && pathnames.includes('t');

  const activeActivityCard = pathnames.includes('activities');

  const activityIconNotification =
    lessonProgress?.activitiesProgress.reduce(
      (acc, activity) => (!activity.hasDone ? ++acc : acc),
      0,
    ) ?? 0;

  function hasHomework(): boolean {
    return !!scheduledLesson?.homeworkActivity;
  }

  const getHomeworkNotification = () => {
    const isValidHomework = !!(
      homeworkProgress && 'activityProgress' in homeworkProgress
    );
    if (!hasHomework() || !isValidHomework) return 0;

    return homeworkProgress?.answer !== null ||
      homeworkProgress.activityProgress.grade !== null
      ? 0
      : 1;
  };

  const materialNotification = lessonProgress?.bookProgress
    ? bookNotification(lessonProgress.bookProgress, freeLesson)
    : undefined;

  const toolNotification =
    lesson && lessonProgress
      ? handleToolNotification({
          hasAccessedTool,
          hasWatchedInstruction,
          instructionsUrl: lesson.instructionsUrl,
          toolUrl: lesson.toolUrl,
        })
      : undefined;

  const activityCardNotification = studentView
    ? activityIconNotification + getHomeworkNotification()
    : undefined;

  const activityProgressIds = lessonProgress?.activitiesProgress?.map(
    progress => progress.id,
  );

  useEffect(() => {
    if (params) {
      setLessonParams(params);
      setCourseParams(params);
    }

    return () => {
      setLessonParams({});
      setCourseParams({});
    };
  }, [params, setLessonParams, setCourseParams]);

  function getBackUrlForBackButton() {
    const klassUrl = !!klassId ? `/class/${klassId}` : '';
    return studentView ? `/courses/` : `/courses/${slugCourseName}${klassUrl}`;
  }

  const homeworkId = studentView
    ? lessonProgress?.homeworkProgress?.homework.id
    : scheduledLesson?.homeworkActivity;

  if (loading)
    return (
      <Layout>
        <KlassViewSkeleton />
      </Layout>
    );

  if (error) return <ErrorPage error={error} />;

  if (coursePath && lesson) {
    const { slug: slugCourseName } = coursePath;

    const title = makeLessonHeadTitle({
      lessonOrder: lesson.order,
      coursePath,
      lessonType: scheduledLesson?.type,
    });

    const handleBreadcrumb = (): breadcrumbArray[] => {
      let courseName: string | null = '';

      courseName = coursePath.course.name;

      const klassUrl = !!klass ? `class/${klass.id}` : '';

      let breadcrumbCourse: breadcrumbArray[] = [
        { url: '/', name: 'Home', routeType: 'home' },
        {
          url: '/courses',
          name: t('sideMenu.courses'),
          routeType: 'courses',
        },
        studentView
          ? { name: courseName, url: '/courses', routeType: 'courses' }
          : {
              name: courseName,
              url: `/courses/${slugCourseName}/${klassUrl}`,
              routeType: 'courses',
            },
        {
          url: `/courses/${slugCourseName}/lesson/${lessonId}/${klassUrl}`,
          name: lesson.name,
          routeType: 'lessons',
        },
      ];
      return breadcrumbCourse;
    };

    const goNext = () => {
      navigate(
        `/courses/${slugCourseName}/lesson/${
          pagination.next?.lesson.id || ''
        }/${!!klassId ? `class/${klassId}` : ''}`,
        { replace: true },
      );
    };

    const goBack = () => {
      navigate(
        `/courses/${slugCourseName}/lesson/${
          pagination.prev?.lesson.id || ''
        }/${!!klassId ? `class/${klassId}` : ''}`,
        { replace: true },
      );
    };

    return (
      <ActivityViewProvider>
        <Layout className="overflow-visible">
          <div className="flex flex-col lg:gap-2 relative">
            <div className="flex items-center justify-between gap-y-5 flex-wrap">
              <AstroBreadcrumb
                className="w-fit"
                breadcrumbList={handleBreadcrumb()}
              />
              <ComponentGuard
                roles={[UserTypeEnum.UNIT_ADMIN, UserTypeEnum.TEACHER]}
              >
                <InfiniteSearchInput
                  selectedItem={klass}
                  service={klassesQueryKeys.list}
                  filters={{ unitId: getAuthorizedUnits(user) }}
                  displayName={klass => translateKlassName(klass.name)}
                  input={{
                    className: {
                      base: 'w-[calc(100%-20px)] lg:w-96 self-center justify-self-center lg:justify-self-end font-500',
                    },
                  }}
                  onSelect={onSelectKlass}
                  onDeselect={onDeselectKlass}
                />
              </ComponentGuard>
            </div>
            <ConditionalRenderer
              condition={!shouldBlockLesson}
              fallback={
                <ErrorComponent
                  statusCode={403}
                  errorTextSubTitle={t('lesson.availableExplain')}
                  errorTextTitle={t('lesson.availableSoon')}
                  animation={{
                    src: '/images/ongoing-construction.json',
                    className: 'w-80',
                  }}
                />
              }
            >
              <div className="flex flex-col gap-1">
                <HeadTitle routeInfo={title} />
                <PageTitle
                  headingText={t('countdown.classroomButton')}
                  className="lg:hidden"
                  position="mb-1"
                />

                <Controls
                  goNext={goNext}
                  hidePrev={!pagination.prev}
                  hideNext={!pagination.next}
                  nextText={formatLessonNamePrefix({
                    lessonOrder: pagination.next?.lesson.order ?? 0,
                    scheduledLessonType: pagination.next?.type,
                  })}
                  prevText={formatLessonNamePrefix({
                    lessonOrder: pagination.prev?.lesson.order ?? 0,
                    scheduledLessonType: pagination.prev?.type,
                  })}
                  goBack={goBack}
                />
                <div className="flex flex-col flex-wrap pb-3">
                  <span className="w-full">
                    <ConditionalRenderer condition={!!lesson?.name}>
                      <LessonBar
                        key={lesson.id}
                        slugCourseName={slugCourseName}
                        lesson={lesson}
                        color="primary"
                        backUrl={getBackUrlForBackButton()}
                        hasBackbutton
                        lessonProgress={lessonProgress}
                        scheduledLesson={scheduledLesson}
                      />
                    </ConditionalRenderer>
                  </span>
                  <div className="w-full grid grid-cols-1 lg:grid-cols-3 gap-4 pt-4 2xl:grid-cols-4">
                    <div className="w-full lg:col-span-2 2xl:col-span-3">
                      <KlassViewContainer />
                    </div>

                    <div className="sticky top-0 self-start">
                      <div className="md:grid md:grid-cols-2 w-full lg:grid-cols-1 flex flex-col gap-4">
                        <ToolCard
                          type="access"
                          active={pathnames.includes('help')}
                          toolUrl={lesson.toolUrl}
                          instructionsUrl={lesson.instructionsUrl}
                          hasAccessedTool={lessonProgress?.hasAccessedTool}
                          lessonProgressId={lessonProgress?.id}
                          notification={toolNotification}
                          lessonId={lessonProgress?.lesson.id}
                        />

                        <MaterialCard
                          bookId={lesson.book}
                          notification={materialNotification}
                          bookProgressId={lessonProgress?.bookProgress?.id}
                          type="studentBook"
                          active={activeBookCard}
                        />

                        <MaterialCard
                          bookId={studentView ? undefined : lesson.teacherBook}
                          type="teacherBook"
                          active={activeTeacherBookCard}
                        />

                        <ActivityCard
                          type="activity"
                          activityProgressIds={activityProgressIds}
                          homeworkProgresses={homeworkProgresses}
                          lessonId={lesson.id}
                          klassId={klass?.id}
                          courseSlug={slugCourseName}
                          active={activeActivityCard}
                          notification={activityCardNotification}
                          activityIds={
                            studentView ? undefined : lesson?.activities
                          }
                          homeworkId={homeworkId}
                          homeworkProgressId={
                            lessonProgress?.homeworkProgress?.id
                          }
                        />

                        <ChallengeCard
                          type="challenge"
                          active={pathnames.includes('challenge')}
                          notification={
                            studentView ? challhengeNotification : undefined
                          }
                          challengeId={lesson.challenge}
                        />

                        <TeacherFeedbackCard
                          lessonId={lesson.id}
                          teacherId={teacherView ? user?.id : undefined}
                        />

                        <StudentFeedbackCard
                          lessonId={lesson.id}
                          klassId={klass?.id}
                          studentId={studentView ? user?.id : undefined}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <LessonCommentSection
                lessonId={lesson.id}
                enabled={!studentView}
              />
            </ConditionalRenderer>
          </div>

          <BugReport lessonOrder={lesson.order} coursePath={coursePath} />
        </Layout>
      </ActivityViewProvider>
    );
  }

  return null;
}

const KlassViewSkeleton = ({ toolUrl }: { toolUrl?: string }) => (
  <div className="flex flex-col gap-2">
    <Skeleton className="w-[80%] h-4 rounded-sm bg-neutral-content" />
    <div className="relative flex justify-between top-3">
      <Skeleton className="h-10 w-20 rounded-xl mb-5 bg-neutral-content" />
      <Skeleton className="h-10 w-20 rounded-xl mb-5 bg-neutral-content" />
    </div>
    <Skeleton className="h-16 w-full rounded-xl mb-1 bg-neutral-content" />
    <div className="w-full grid grid-cols-1 rounded-xl lg:grid-cols-3 gap-4 pt-4 2xl:grid-cols-4">
      <Skeleton className="w-full h-[373px] rounded-xl lg:col-span-2 2xl:col-span-3 bg-neutral-content" />
      <div className="flex flex-col rounded-xl md:grid md:grid-cols-2 lg:flex lg:flex-col gap-4">
        <ConditionalRenderer condition={toolUrl !== ''}>
          <Skeleton className="h-16 w-full rounded-2xl bg-neutral-content" />
        </ConditionalRenderer>

        <Skeleton className="h-16 w-full rounded-2xl bg-neutral-content" />
        <Skeleton className="h-16 w-full rounded-2xl bg-neutral-content" />
        <Skeleton className="h-16 w-full rounded-2xl bg-neutral-content" />
      </div>
    </div>
  </div>
);

function KlassViewContainer() {
  const location = useLocation();
  const pathname = location.pathname;

  if (pathname.includes('activities')) {
    return <ActivityContainer />;
  } else if (pathname.includes('help')) {
    return <InstructionsVideo />;
  } else if (pathname.includes('/t/')) {
    return (
      <AuthGuard
        roles={[
          UserTypeEnum.SUPER_ADMIN,
          UserTypeEnum.UNIT_ADMIN,
          UserTypeEnum.TEACHER,
        ]}
      >
        <TeacherMaterialView />
      </AuthGuard>
    );
  } else if (pathname.includes('book/chapter')) {
    return <StudentMaterialView />;
  } else if (pathname.includes('challenge')) {
    return <Challenge />;
  } else return <InitialView />;
}
