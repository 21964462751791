export enum ChapterStatusEnum {
  NEVER_OPEN = 'NEVER_OPEN',
  OPEN = 'OPEN',
  READ = 'READ',
}

export default interface ChapterProgress {
  id: number;
  chapterId: number;
  chapterTitle: string;
  timer: number;
  status: ChapterStatusEnum;
  chapterOrder: number;
}

export function instanceOfChapterProgress(
  object: any,
): object is ChapterProgress {
  return 'id' in object && 'chapterId' in object && 'chapterTitle' in object;
}

export type ChaptersProgressesContent = {
  [key: string]: ChapterProgress;
};
