import React from 'react';
import { Link } from 'react-router-dom';
import { twMerge } from 'tailwind-merge';

interface LinkTextProps {
  id?: string;
  text: string | undefined;
  testId?: string;
  className?: string;
  onClick?: (e: any) => void;
  href?: string;
}

export default function LinkText(props: LinkTextProps) {
  const { id, text, testId, className, onClick, href } = props;
  return (
    <Link
      id={id}
      data-testid={testId}
      to={href || ''}
      onClick={onClick}
      className={twMerge(
        'font-rubik font-500 duration-200 text-primary hover:text-secondary cursor-pointer underline',
        className,
      )}
    >
      {text}
    </Link>
  );
}
