import ConditionalRenderer from '@/components/common/ConditionalRenderer';
import useTreeArea from '@/data/hook/useTreeArea';
import Area from '@/models/Area';
import useFilterParams from '@/utils/UseFilterParams';
import { FolderOpenIcon } from '@heroicons/react/outline';
import { twMerge } from 'tailwind-merge';
import { TopicsFiltersParams } from '../TopicsPageContent';
import AreaRename from './actions/AreaRename';

export type TopicsBreadcrumbProps = {
  path: Area[];
  isRenaming?: boolean;
  setIsRenaming?: (isRenaming: boolean) => void;
};

export default function TopicsBreadcrumb({
  path,
  setIsRenaming,
  isRenaming,
}: TopicsBreadcrumbProps) {
  const pathWithoutLast = path.slice(0, path.length - 1);
  const lastArea = path.at(-1);

  return (
    <div
      className={twMerge(
        'relative flex gap-1.5 items-end flex-wrap',
        isRenaming ? 'items-center' : 'items-end',
      )}
    >
      <FolderOpenIcon
        className={twMerge(
          'relative w-6 h-6 text-primary',
          isRenaming ? '-top-0.5' : 'top-1',
        )}
      />
      {pathWithoutLast.map((area, index, arr) => (
        <BreadCrumbItem
          key={area.id}
          area={area}
          isLast={arr.length - 1 === index}
        />
      ))}
      <ConditionalRenderer condition={pathWithoutLast.length > 0}>
        <span className="text-primary font-500 text-base !leading-none">/</span>
      </ConditionalRenderer>

      {lastArea && (
        <AreaRename
          area={lastArea}
          key={lastArea.name}
          isRenaming={isRenaming}
          setIsRenaming={setIsRenaming}
          wrapper={
            <span className="text-primary font-500 text-base underline !leading-none" />
          }
        />
      )}
    </div>
  );
}

type BreadCrumbItemProps = {
  area: Area;
  isLast?: boolean;
};

function BreadCrumbItem({ area, isLast }: BreadCrumbItemProps) {
  const { getPath } = useTreeArea();
  const { setFilterParams } = useFilterParams<TopicsFiltersParams>();

  const onClick = () => {
    const path = getPath(area.id);
    setFilterParams({
      path,
    });
  };
  return (
    <div className="flex items-center gap-1.5 text-sm">
      <span
        className="text-base-content underline font-500 !leading-none cursor-pointer whitespace-nowrap"
        onClick={onClick}
      >
        {area.name}
      </span>
      <ConditionalRenderer condition={!isLast}>
        <span className="text-base-content !leading-none">/</span>
      </ConditionalRenderer>
    </div>
  );
}
