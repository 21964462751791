export enum MediaName {
  FACEBOOK = 'FACEBOOK',
  INSTAGRAM = 'INSTAGRAM',
  ROBLOX = 'ROBLOX',
  YOUTUBE = 'YOUTUBE',
  LINKEDIN = 'LINKEDIN',
  TIKTOK = 'TIKTOK',
  GITHUB = 'GITHUB',
  OTHER = 'OTHER',
}

export interface UserprofileProgress {
  courseName: string;
  progress: number;
  hasDoneActivities: number;
}

export interface SocialMedia {
  mediaName: MediaName;
  mediaUrl: string;
}

export default interface UserProfile {
  id: number;
  bannerUrl: string | null;
  bio?: string;
  city?: string;
  dateOfBirth?: string;
  phoneNumber?: string;
  socialMedia?: SocialMedia[];
  state?: string;
}

export interface VisitProfile {
  id: number;
  username: string;
  progress?: number;
  hasDoneActivities?: number;
  profile: {
    fullName: string;
    avatarUrl: string;
    bannerUrl: string;
    bio: string;
    socialMedia: SocialMedia[];
  };
}

export enum UserStateEnum {
  SP = 'São Paulo',
  MG = 'Minas Gerais',
  AC = 'Acre',
  AL = 'Alagoas',
  AP = 'Amapá',
  AM = 'Amazonas',
  BA = 'Bahia',
  CE = 'Ceará',
  DF = 'Distrito Federal',
  ES = 'Espírito Santo',
  GO = 'Goiás',
  MA = 'Maranhão',
  MT = 'Mato Grosso',
  MS = 'Mato Grosso do Sul',
  PA = 'Pará',
  PB = 'Paraíba',
  PR = 'Paraná',
  PE = 'Pernambuco',
  PI = 'Piauí',
  RJ = 'Rio de Janeiro',
  RN = 'Rio Grande do Norte',
  RS = 'Rio Grande do Sul',
  RO = 'Rondonia',
  RR = 'Roraima',
  SC = 'Santa Catarina',
  SE = 'Sergipe',
  TO = 'Tocantins',
}
