import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import AreaInput from '../common/dataInput/TextAreaInput';
import SaveCancelGroup from '../common/buttons/SaveCancelGroup';

interface ScheduledLessonAnnotationsEditProps {
  notes?: string;
  onClick: (homework: string) => void;
  isLoading?: boolean;
  disabled?: boolean;
}

export const ScheduledLessonAnnotationsEdit = ({
  onClick,
  notes,
  isLoading,
  disabled,
}: ScheduledLessonAnnotationsEditProps) => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'manageScheduledLessonReport',
  });
  const [notesValue, setNotesValue] = useState<string>();
  const [hasChange, setHasChange] = useState(false);

  useEffect(() => {
    setNotesValue(notes);
  }, [notes]);

  return (
    <div
      data-testid="scheduledLessonAnnotationsEdit"
      className="flex flex-col gap-3"
    >
      <AreaInput
        disabled={disabled}
        testId="annotationsTextarea"
        value={notesValue || ''}
        placeholder={t('annotationsPlaceholder')}
        onChange={e => {
          setNotesValue(e.target.value);
          setHasChange(true);
        }}
      />

      <SaveCancelGroup
        save={{
          onClick: () => {
            notesValue && onClick(notesValue);
            setHasChange(false);
          },
          testId: 'saveButtonAnnotationEdit',
        }}
        cancel={{
          onClick: () => {
            setNotesValue(notes);
            setHasChange(false);
          },
          testId: 'cancelButtonAnnotationEdit',
        }}
        loading={isLoading}
        disable={!hasChange}
      />
    </div>
  );
};
