import { ReactNode } from 'react';
import ConditionalWrapper from '../ConditionalWrapper';
import { Link } from 'react-router-dom';

type TextFormatType = 'poppins-600' | 'rubik-700' | 'rubik-500' | 'rubik-400';

type textTag = 'p' | 'span' | 'h2' | 'h3' | 'h4';

export interface TextProps extends React.HTMLAttributes<HTMLParagraphElement> {
  id?: string;
  testId?: string;
  text: string | ReactNode;
  format?: TextFormatType;
  className?: string;
  size?: string;
  onClick?: React.MouseEventHandler<HTMLParagraphElement>;
  isLink?: boolean | true;
  href?: string;
  as?: textTag;
}

const textFormatRecord: Record<TextFormatType, string> = {
  'poppins-600': 'font-poppins font-600',
  'rubik-700': 'font-rubik font-700',
  'rubik-500': 'font-rubik font-500',
  'rubik-400': 'font-rubik font-400',
};

export default function Text(props: TextProps) {
  const {
    text,
    format,
    className,
    size,
    href,
    testId,
    isLink,
    as = 'p',
    ...rest
  } = props;
  const Tag = as;
  return (
    <ConditionalWrapper
      condition={!!href}
      wrapper={<Link data-testid={testId} to={href as string} />}
    >
      <Tag
        {...rest}
        data-testid={!href && testId}
        className={`items-center align-middle leading-none tracking-normal ${
          format ? textFormatRecord[format] : ''
        } ${className || ''} ${size || ''} ${
          isLink
            ? 'underline text-primary cursor-pointer hover:text-primary/40'
            : ''
        }`}
      >
        {text}
      </Tag>
    </ConditionalWrapper>
  );
}
