import { LoadingIcon } from '@/components/icons';
import useToggle from '@/data/hook/useToggle';
import {
  accordion,
  animMerge,
  fadeIn,
  slideHorizontal,
} from '@/utils/animations/commom';
import { ColorProperties } from '@/utils/modalBankUtils';
import {
  CheckIcon,
  ChevronDownIcon,
  MinusIcon,
} from '@heroicons/react/outline';
import { AnimatePresence, motion } from 'framer-motion';
import { compact, uniqueId } from 'lodash';
import { CSSProperties, PropsWithChildren, ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import ConditionalRenderer from '../../ConditionalRenderer';
import ConditionalWrapper from '../../ConditionalWrapper';
import Text from '../../dataDisplay/Text';
import { Tooltip } from '../../dataDisplay/Tooltip';
import { twMerge } from 'tailwind-merge';
export default function Card({
  children,
  expansibleContent,
  color,
  testId,
  inUse,
}: {
  children: React.ReactNode;
  color: ColorProperties;
  expansibleContent?: React.ReactNode;
  inUse?: boolean;
  testId?: string;
}) {
  const { isOpen, toggle } = useToggle();

  return (
    <div
      className={`relative flex flex-col w-full border-[1px] bg-base-100 rounded-lg ${
        color.border
      } ${
        inUse ? color.cardBackgroundUsage : color.cardBackground
      } px-3.5 py-3 shadow-default`}
    >
      <div
        className="relative cursor-pointer w-full gap-2 justify-between items-center flex justify-items-stretch pr-10"
        data-testid={testId}
        onClick={toggle}
      >
        {children}

        <ChevronUpIconWithAnimation
          animate={{
            rotate: isOpen ? 180 : 0,
          }}
          className="w-7 text-primary justify-self-end absolute right-0"
        />
      </div>
      <AnimatePresence>
        {isOpen && (
          <motion.div
            {...accordion}
            transition={{ type: 'spring', stiffness: 900, damping: 40 }}
            className="overflow-hidden"
          >
            {expansibleContent}
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
}

const ChevronUpIconWithAnimation = motion(ChevronDownIcon);

const CardAnimation = ({
  children,
  disabled,
}: PropsWithChildren<{ testId?: string; disabled?: boolean }>) => {
  return (
    <ConditionalWrapper
      condition={!disabled}
      wrapper={
        <motion.div
          transition={{
            type: 'spring',
            stiffness: 900,
            damping: 40,
          }}
          layout
        />
      }
      fallback={<div />}
    >
      {children}
    </ConditionalWrapper>
  );
};

const IconContainer = ({ children }: { children: React.ReactNode }) => {
  return (
    <div className="aspect-square group relative p-2 h-fit shrink-0 rounded-full flex items-center justify-center bg-primary/40">
      {children}
    </div>
  );
};

const LeftContent = ({ children }: { children: React.ReactNode }) => {
  return <div className="flex flex-col gap-2 overflow-hidden">{children}</div>;
};

const Checkable = ({
  children,
  isLoading,
  onCheck,
  checked,
}: {
  children?: React.ReactNode;
  checked: boolean;
  onCheck: (value: boolean) => void;
  isLoading?: boolean;
}) => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'modalBank.tooltip',
  });
  return (
    <div className="relative flex items-center h-fit w-full">
      {children}
      <Tooltip
        text={checked ? t('insideBank') : t('outsideBank')}
        classNameContainer="h-full w-fit"
        className="w-30"
        placement="left"
      >
        <motion.div
          className="relative h-[60px] rounded-r-lg cursor-pointer"
          animate={{ width: '20px' }}
          initial={{ width: '0px' }}
          exit={{ width: '0px' }}
          whileHover={{ width: '30px' }}
        >
          <ConditionalRenderer condition={!isLoading}>
            {checked && (
              <div className="relative bg-success rounded-r-lg h-full flex items-center justify-center">
                <CheckIcon className="w-4" />
              </div>
            )}
            {!checked && (
              <div className="relative bg-neutral flex items-center justify-center rounded-r-lg h-full">
                <MinusIcon className="w-4 text-base-200" />
              </div>
            )}

            <input
              type="checkbox"
              className="absolute w-full h-full opacity-0 cursor-pointer inset-0"
              checked={checked}
              onChange={e => onCheck(e.target.checked)}
            />
          </ConditionalRenderer>
          <ConditionalRenderer condition={isLoading}>
            <div className="relative bg-neutral flex items-center justify-center rounded-r-lg h-full">
              <LoadingIcon className="w-4 text-base-200" />
            </div>
          </ConditionalRenderer>
        </motion.div>
      </Tooltip>
    </div>
  );
};

const Header = ({
  icon: Icon,
  title,
  color,
  meta,
}: {
  icon: React.ElementType;
  title: string;
  meta: React.ReactElement[];
  color: CSSProperties['color'];
}) => {
  return (
    <div className="flex gap-4 w-full">
      <div className="flex gap-2 items-center overflow-x-hidden">
        <Icon className={`w-6 ${color}`} />
        <Text text={title} className="truncate" />
      </div>
      <div className="flex gap-2 items-center">{meta}</div>
    </div>
  );
};

const Meta = ({
  icon: Icon,
  label,
  className,
  tooltip,
}: {
  icon: React.ElementType;
  label?: number | string | null;
  className?: string;
  tooltip?: string;
}) => {
  const formatter = new Intl.NumberFormat(undefined, {
    maximumFractionDigits: 2,
  });
  return (
    <ConditionalWrapper
      condition={!!tooltip}
      wrapper={<Tooltip text={tooltip} placement="right" />}
    >
      <div
        className={twMerge('flex gap-1 items-center justify-center', className)}
      >
        {typeof label === 'number' && (
          <Text text={formatter.format(label ?? 0)} />
        )}
        {typeof label === 'string' && <Text text={label} />}
        <Icon className="w-4 h-4" />
      </div>
    </ConditionalWrapper>
  );
};

const Tags = ({ tags, color }: { tags: string[]; color: ColorProperties }) => {
  const tagsNotNullable = compact(tags);
  return (
    <div className="flex gap-2 flex-wrap">
      <AnimatePresence>
        {tagsNotNullable.map(tag => (
          <motion.div
            {...animMerge(slideHorizontal(-200), fadeIn)}
            key={tag}
            className={`rounded-full px-2 py-1 text-base-100 whitespace-nowrap flex items-center ${color.background}`}
          >
            <Text text={tag} size="text-14" />
          </motion.div>
        ))}
      </AnimatePresence>
    </div>
  );
};

const IconsList = ({
  icons,
}: {
  icons: { icon: ReactNode; tooltip?: string }[];
}) => {
  return (
    <div className="flex gap-2 flex-wrap justify-self-end">
      {icons.map(({ icon, tooltip }) => {
        return (
          <ConditionalWrapper
            key={uniqueId()}
            condition={!!tooltip}
            wrapper={<Tooltip text={tooltip} className="absolute" />}
          >
            {icon}
          </ConditionalWrapper>
        );
      })}
    </div>
  );
};

function DescContainer({
  children,
}: {
  children: React.ReactNode;
  className?: string;
}) {
  return <div className="flex gap-2 items-center flex-wrap">{children}</div>;
}

Card.Meta = Meta;
Card.Header = Header;
Card.LeftContent = LeftContent;
Card.Tags = Tags;
Card.IconsList = IconsList;
Card.IconContainer = IconContainer;
Card.DescContainer = DescContainer;
Card.Checkable = Checkable;
Card.Animation = CardAnimation;
