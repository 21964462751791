import User from './User';

export default interface Enrollment {
  id: number;
  klassId: number;
  student: User;
  status: EnrollmentStatusEnum;
  updatedAt: string;
  createdAt: string;
}

export enum EnrollmentStatusEnum {
  ACTIVE = 'ACTIVE',
  AWAITING_CLASS = 'AWAITING_CLASS',
  AWAITING_PAYMENT = 'AWAITING_PAYMENT',
  COURSE_TRANSFERRED = 'COURSE_TRANSFERRED',
  CLASS_TRANSFERRED = 'CLASS_TRANSFERRED',
  CONCLUDED = 'CONCLUDED',
  CANCELED = 'CANCELED',
}

export enum TrialEnrollmentStatusEnum {
  ACTIVE = 'ACTIVE',
  CANCELED = 'CANCELED',
  UNQUALIFIED = 'UNQUALIFIED',
  CONCLUDED = 'CONCLUDED',
  OVERDUE = 'OVERDUE',
}
