import MainButton from '../../../common/buttons/MainButton';
import { PencilIcon, UploadIcon } from '@heroicons/react/outline';
import AddMaterial from './AddMaterial';
import CoursePath from '../../../../models/Course';
import { Lesson } from '../../../../models/Lesson';
import LoadingView from '../../../../pages/courses/LoadingView';
import { useTranslation } from 'react-i18next';
import useCourseEditing from '../../../../data/hook/useCourseEditing';
import { bookType } from '../../../../models/Book';
import { bookEndpoints } from '../../../../utils/setBookType';
import { useNavigate } from 'react-router-dom';
import TooltipHandler from '../../../common/TooltipHandler';
import { ApiError } from '../../../../models/Errors';
import { useMutation } from '@tanstack/react-query';
import alert from '../../../../utils/UseAlert';
import { createChapter } from '../../../../data/services/chapterServices';
import { createBook } from '../../../../data/services/bookServices';

export type Mode = 'upload' | 'default';

interface MaterialModeProps {
  course: CoursePath;
  lesson: Lesson;
  updateLessons?: () => Promise<void>;
  bookType: bookType;
  isLoadingContainer: boolean;
}

export default function MaterialMode({
  course,
  lesson,
  updateLessons,
  bookType,
  isLoadingContainer,
}: MaterialModeProps) {
  const { materialMode, setMaterialMode, allowBigChanges } = useCourseEditing();
  const { t } = useTranslation('translation', {
    keyPrefix: 'editMaterialPage',
  });
  const { t: editRulesT } = useTranslation('translation', {
    keyPrefix: 'adminPage.editCourseRules',
  });
  const navigate = useNavigate();

  const endpoint = bookEndpoints[bookType];

  const editBookRoute = `/admin/courses/${course.slug}/lessons/${lesson.id}/${endpoint}`;

  const { mutate: chapterCreate } = useMutation(createChapter, {
    onSuccess(data) {
      navigate(`${editBookRoute}/${data.book}`);
    },
    onError(error: any) {
      const apiError = new ApiError(error);
      alert.error(apiError.getErrorMessage());
    },
  });

  const { mutate: bookCreate } = useMutation(createBook, {
    onSuccess(data) {
      chapterCreate({
        lessonId: lesson.id,
        bookType: data.bookType,
        bookId: data.id,
      });
    },
    onError(error: any) {
      const apiError = new ApiError(error);
      alert.error(apiError.getErrorMessage());
    },
  });
  const onClickCreate = () => {
    bookCreate({
      bookType,
      lessonId: lesson.id,
    });
  };

  if (isLoadingContainer) {
    return <LoadingView />;
  }

  switch (materialMode) {
    case 'upload':
      return (
        <AddMaterial
          course={course}
          lesson={lesson}
          updateLessons={updateLessons}
          bookType={bookType}
          setMaterialMode={setMaterialMode}
        />
      );
    default:
      return (
        <div className="flex gap-4">
          <TooltipHandler
            tooltipMessage={editRulesT('cantModify')}
            renderTooltip={!allowBigChanges}
          >
            <MainButton
              text={t('uploadMaterial')}
              icon={<UploadIcon />}
              disabled={!allowBigChanges}
              onClick={() => setMaterialMode('upload')}
            />
          </TooltipHandler>
          <TooltipHandler
            tooltipMessage={editRulesT('cantModify')}
            renderTooltip={!allowBigChanges}
          >
            <MainButton
              text={t('createMaterial')}
              icon={<PencilIcon />}
              disabled={!allowBigChanges}
              onClick={onClickCreate}
            />
          </TooltipHandler>
        </div>
      );
  }
}
