import moment from 'moment';
import LessonProgress from '../models/LessonProgress';
import ScheduledLesson from '../models/ScheduledLesson';

export const shouldShowScheduledLesson = (
  scheduledLesson?: ScheduledLesson,
  lessonProgress?: LessonProgress,
  nextLesson?: ScheduledLesson,
) => {
  if (!scheduledLesson || !lessonProgress) {
    return false;
  }

  const isPastLesson = moment(scheduledLesson.datetime).isBefore(moment());

  const nextSevenDays =
    moment(scheduledLesson.datetime).isAfter(moment()) &&
    moment(scheduledLesson.datetime).isBefore(moment().add(7, 'days'));

  const isNextLesson = scheduledLesson.id === nextLesson?.id;

  const hasPresence =
    lessonProgress.scheduledLessonReport?.presence === 'ATTENDED';

  return (
    isPastLesson ||
    hasPresence ||
    (nextSevenDays && !nextLesson) ||
    scheduledLesson.hasDone ||
    isNextLesson
  );
};
