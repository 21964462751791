import { HTMLMotionProps, motion } from 'framer-motion';
import { CSSProperties } from 'react';
import { twMerge } from 'tailwind-merge';
import Skeleton from '../Skeleton';
import ConditionalRenderer from '../ConditionalRenderer';

export type TagColor =
  | 'primary'
  | 'secondary'
  | 'accent'
  | 'error'
  | 'warning'
  | 'confirm'
  | 'success'
  | 'info'
  | 'custom';

export type TagProps = HTMLMotionProps<'div'> & {
  className?: string;
  testId?: string;
  text?: string;
  icon?: JSX.Element;
  color?: TagColor;
  hideIconMobile?: boolean;
  style?: CSSProperties;
  loading?: boolean;
};

const tagColorClasses: Record<TagColor, string> = {
  primary: 'text-primary bg-primary-content',
  secondary: 'text-secondary bg-secondary-content',
  accent: 'text-accent bg-accent-content border',
  warning: 'text-warning bg-warning-content border',
  confirm: 'text-base-100 bg-accent border',
  success: 'text-success bg-success-content border',
  info: 'text-info bg-info-content border',
  error: 'text-error bg-error-content border',
  custom: '',
};

export default function Tag(props: TagProps) {
  const {
    className,
    testId,
    text,
    icon,
    hideIconMobile,
    style,
    loading,
    children,
    ...rest
  } = props;

  if (loading)
    return <Skeleton className="h-6 w-40 bg-primary-content rounded-full" />;
  return (
    <motion.div
      {...rest}
      initial={{ opacity: 0, scale: 0.95 }}
      animate={{ opacity: 1, scale: 1 }}
      transition={{ duration: 0.1 }}
      data-testid={testId}
      className={twMerge(
        'max-w-fit font-rubik flex h-fit content-center items-center align-middle rounded-full px-3.5 py-1.5 text-16 font-500 gap-4',
        className,
        tagColorClasses[props.color || 'primary'],
      )}
      style={style}
    >
      <ConditionalRenderer condition={icon}>
        <div
          className={`${
            hideIconMobile ? 'hidden lg:flex' : 'flex'
          } justify-start items-center`}
        >
          {icon}
        </div>
      </ConditionalRenderer>

      <ConditionalRenderer condition={text}>
        <p
          className="leading-none align-middle text-center truncate"
          data-testid={testId ? testId + 'Text' : 'tagText'}
        >
          {text}
        </p>
      </ConditionalRenderer>

      {children}
    </motion.div>
  );
}
