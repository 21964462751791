import MainButton from '@/components/common/buttons/MainButton';
import { Tooltip } from '@/components/common/dataDisplay/Tooltip';
import { FilterIcon } from '@heroicons/react/solid';
import { ComponentProps } from 'react';
import { useTranslation } from 'react-i18next';
import NotificationFiltersForm from './NotificationLeftFiltersForm';
import { twMerge } from 'tailwind-merge';

const NotificationMobileFilter = (props: ComponentProps<'aside'>) => {
  const { t: tPage } = useTranslation('translation', {
    keyPrefix: 'alerts.page',
  });

  return (
    <aside className={twMerge('md:hidden', props.className)} {...props}>
      <Tooltip
        color="white"
        className="p-3 rounded-md w-[250px]"
        options={{
          placement: 'bottom-end',
          interactive: true,
          delayHide: 200,
        }}
        text={<NotificationFiltersForm />}
      >
        <MainButton
          color="custom"
          text={tPage('filters').toLowerCase()}
          icon={<FilterIcon className="w-4 h-4" />}
          className="flex-row-reverse gap-1"
        />
      </Tooltip>
    </aside>
  );
};

export default NotificationMobileFilter;
