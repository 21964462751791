import { ErrorCapturer } from '@/adapters/ErrorCapturer';
import ConditionalRenderer from '@/components/common/ConditionalRenderer';
import BodyCell from '@/components/common/table/BodyCell';
import { BranchIcon, LoadingIcon } from '@/components/icons';
import useCourseEditing from '@/data/hook/useCourseEditing';
import { CourseCategoryEnum, ICourse } from '@/models/Course';
import { ApiError } from '@/models/Errors';
import LoadingView from '@/pages/courses/LoadingView';
import { CourseHeader } from '@/utils/HeaderTypes';
import alert from '@/utils/UseAlert';

import IconButton from '@/components/common/buttons/IconButton';
import Text from '@/components/common/dataDisplay/Text';
import { editFormAnimation } from '@/utils/animations/formAnimations';
import {
  CheckCircleIcon,
  ChevronUpIcon,
  EyeIcon,
  InformationCircleIcon,
  PencilIcon,
} from '@heroicons/react/outline';
import Skeleton from '@/components/common/Skeleton';
import { Tooltip } from '@/components/common/dataDisplay/Tooltip';
import Row from '@/components/common/table/Row';
import { CoursePathErrorDispatcher } from '@/errors/coursePath';
import courseCalculator from '@/utils/statistics/courseCalculator';
import { AxiosError } from 'axios';
import { AnimatePresence, motion } from 'framer-motion';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import EditCourse from './EditCourse';
import { VersioningStatusEnum } from '@/enums/VersioningStatus';
import Observable from '@/utils/observers/ObserverPattern';
import useStatisticService from '@/data/hook/useStatistics';
import { courseStatisticsQueryKeys } from '@/data/services/querykeys';

interface DataCourseTableProps {
  updateCourseList(): void;
  titles: CourseHeader;
  coursesBases: ICourse[];
  setSelectedCourse: (course: ICourse) => void;
  setBetaModal: React.Dispatch<React.SetStateAction<boolean>>;
}

interface RenderRowProps
  extends Omit<DataCourseTableProps, 'coursesStatistics' | 'coursesBases'> {
  index: number;
  observer?: Observable<number>;
  courseBase: ICourse;
}

interface ActionCourseIconProps {
  Icon: React.FC<{ className: string }>;
  tooltipText: string;
  href?: string;
  onClick?: () => void;
  isLoading?: boolean;
  disable?: boolean;
}

export default function DataCourseTable(props: DataCourseTableProps) {
  const {
    coursesBases,
    titles,
    setBetaModal,
    setSelectedCourse,
    updateCourseList,
  } = props;

  const observer = new Observable<number>();

  return (
    <tbody className="bg-transparent flex flex-col gap-y-2.5">
      {coursesBases.map((courseBase, i) => (
        <RenderRow
          key={courseBase.id}
          index={i}
          courseBase={courseBase}
          observer={observer}
          titles={titles}
          setSelectedCourse={setSelectedCourse}
          setBetaModal={setBetaModal}
          updateCourseList={updateCourseList}
        />
      ))}
    </tbody>
  );
}

function RenderRow({
  courseBase,
  index,
  observer,
  titles,
  setSelectedCourse,
  setBetaModal,
  updateCourseList,
}: RenderRowProps) {
  const draftStatus = [
    VersioningStatusEnum.BETA,
    VersioningStatusEnum.EDITING,
    VersioningStatusEnum.VERSIONING,
  ];

  const viewCourseHref = `/courses/${courseBase.coursePathSlug}`;

  const { t } = useTranslation('translation', {
    keyPrefix: 'manageCourse.dataCourseTable',
  });

  const {
    calculated: courseStatisticCalculated,
    isInitialLoading: isLoadingCourseStatistic,
    error: errorCourseStatistic,
  } = useStatisticService(
    courseStatisticsQueryKeys.list({ courseId: courseBase.id }),
    courseCalculator,
  );

  const { allowEditingMode, allowVersioningMode } = useCourseEditing();

  const [viewInformations, setViewInformations] = useState(false);

  const [isActionLoading, setIsActionLoading] = useState<string>('none');

  const isDraft = draftStatus.includes(
    courseBase.coursePathStatus ?? VersioningStatusEnum.VERSIONING,
  );

  const toogleViewInformations = () => {
    if (!viewInformations) observer?.notifyAll(courseBase.id);
    setViewInformations(old => !old);
  };

  function nameAndCategory(name: string, category: CourseCategoryEnum) {
    const schoolCategory = t('school')?.charAt(0);

    const categories: {
      [key in CourseCategoryEnum]: string;
    } = {
      F2F: 'P',
      ONLINE: 'O',
      SCHOOL: schoolCategory,
    };

    return `${categories[category]} - ${name}`;
  }

  const tratedVersion = () => {
    const lastVertsion = courseBase.lastCoursePathVersion ?? 0;
    return String(lastVertsion.toFixed(1));
  };

  const handlingCourseTypes = () => {
    if (courseBase.courseType) {
      if (courseBase.courseType === 'CTRL_QUICK') {
        return t('Quick');
      } else {
        return courseBase.courseType.slice(5);
      }
    }
  };

  const courseHasCoursePath = ({
    coursePathSlug,
    coursePathStatus,
    lastCoursePathVersion,
  }: ICourse) => {
    return Boolean(coursePathSlug || coursePathStatus || lastCoursePathVersion);
  };

  async function handleDraftMode(selected: ICourse, index: number) {
    setSelectedCourse(selected);
    if (courseBase.coursePathStatus === VersioningStatusEnum.BETA) {
      setBetaModal(true);
    } else if (courseBase.coursePathStatus === VersioningStatusEnum.EDITING) {
      setIsActionLoading(`edit${index}`);
      await handleEditingMode(selected, index);
    } else {
      setIsActionLoading(`version${index}`);
      await handleVersioningMode(selected, index);
    }
    setIsActionLoading('none');
  }

  async function handleEditingMode(selected: ICourse, index: number) {
    setSelectedCourse(selected);
    const { coursePathSlug, coursePathStatus } = selected;
    setIsActionLoading(`edit${index}`);
    try {
      if (!courseHasCoursePath(selected)) {
        throw new Error('There is no CoursePath');
      }
      await allowEditingMode({
        slug: coursePathSlug ?? '',
        status: coursePathStatus ?? VersioningStatusEnum.PUBLISHED,
      });
    } catch (error) {
      let message = t('noCoursePath');
      if (error instanceof AxiosError) {
        const api = new ApiError(error);
        message = api.getErrorMessage();
      }
      alert.error(message);
    } finally {
      setIsActionLoading('none');
    }
  }
  async function handleVersioningMode(selected: ICourse, index: number) {
    setSelectedCourse(selected);
    const { coursePathSlug, coursePathStatus, lastCoursePathVersion } =
      selected;
    try {
      setIsActionLoading(`version${index}`);
      if (!courseHasCoursePath(selected)) {
        throw new Error('There is no CoursePath');
      }
      const latVersion = lastCoursePathVersion ?? 0;
      await allowVersioningMode(
        {
          slug: coursePathSlug ?? '',
          status: coursePathStatus ?? VersioningStatusEnum.PUBLISHED,
        },
        latVersion <= 1.0,
      );
    } catch (error: any) {
      let message = t('noCoursePath');
      if (error instanceof AxiosError) {
        const api = new ApiError(error);
        message = api.getErrorMessage();
      }
      alert.error(message);
    } finally {
      setIsActionLoading('none');
    }
  }

  useEffect(() => {
    observer?.subscribe({
      id: courseBase.id,
      action: () => {
        setViewInformations(false);
      },
    });
  }, [courseBase.id, observer]);

  useEffect(() => {
    if (!courseHasCoursePath(courseBase)) {
      const errorDispatcher = new CoursePathErrorDispatcher(
        courseBase.abbreviation,
      );
      const errorCapturer = new ErrorCapturer(errorDispatcher);

      errorCapturer.dispatchError();
    }
  }, [courseBase]);

  return (
    <Row key={courseBase.id} testId="courseRow">
      <BodyCell className="justify-between p-0">
        <div
          style={{ width: `${titles.name.size}%` }}
          className="flex items-center"
          onClick={toogleViewInformations}
        >
          <IconButton
            testId="teamInfoButton"
            className="transition ease-in-out duration-150"
            icon={
              <ChevronUpIconWithAnimation
                animate={{
                  rotate: !viewInformations ? 180 : 0,
                }}
                className="w-3 h-3"
              />
            }
          />
          <Text
            text={nameAndCategory(courseBase.name, courseBase.category)}
            className="cursor-pointer pl-5"
            format="rubik-400"
          />
        </div>

        <div
          style={{ width: `${titles.version.size}%` }}
          className="flex items-center"
        >
          <Text text={tratedVersion()} format="rubik-400" />
        </div>

        <div
          style={{ width: `${titles.category.size}%` }}
          className="flex items-center"
        >
          <Text
            text={handlingCourseTypes()}
            className="cursor-pointer"
            format="rubik-400"
          />
        </div>

        <div
          className="flex items-center"
          style={{ width: `${titles.students.size}%` }}
        >
          <StatisticCell
            value={courseStatisticCalculated?.studentsCount}
            error={!!errorCourseStatistic}
            isLoading={isLoadingCourseStatistic}
          />
        </div>

        <div
          className="flex items-center"
          style={{ width: `${titles.churn.size}%` }}
        >
          <StatisticCell
            value={courseStatisticCalculated?.churnRate}
            error={!!errorCourseStatistic}
            isLoading={isLoadingCourseStatistic}
            isPercentage
          />
        </div>

        <div
          className="flex items-center"
          style={{ width: `${titles.presence.size}%` }}
        >
          <StatisticCell
            isLoading={isLoadingCourseStatistic}
            value={courseStatisticCalculated?.attendancePercentage}
            error={!!errorCourseStatistic}
            isPercentage
          />
        </div>

        <div
          className="flex items-center"
          style={{ width: `${titles.status.size}%` }}
        >
          <ConditionalRenderer
            condition={courseBase.isActive}
            fallback={<CheckCircleIcon className="w-5 h-5 text-neutral/50" />}
          >
            <CheckCircleIcon className="w-5 h-5 text-primary" />
          </ConditionalRenderer>
        </div>

        <div
          className="flex items-center gap-x-2.5"
          style={{ width: `${titles.actions.size}%` }}
        >
          <ActionCourseIcon
            Icon={InformationCircleIcon}
            tooltipText={t('infoTooltip')}
            disable={isActionLoading !== 'none'}
            onClick={toogleViewInformations}
          />

          <ActionCourseIcon
            Icon={EyeIcon}
            tooltipText={t('viewTooltip')}
            href={viewCourseHref}
            disable={isActionLoading !== 'none'}
          />

          <ActionCourseIcon
            onClick={
              isDraft
                ? () => handleDraftMode(courseBase, index)
                : () => handleEditingMode(courseBase, index)
            }
            Icon={PencilIcon}
            isLoading={isActionLoading === `edit${index}`}
            tooltipText={t('editTooltip')}
            disable={isActionLoading !== 'none'}
          />

          <ConditionalRenderer condition={!isDraft}>
            <ActionCourseIcon
              onClick={() => handleVersioningMode(courseBase, index)}
              Icon={BranchIcon}
              tooltipText={t('branchTooltip')}
              isLoading={isActionLoading === `version${index}`}
              disable={isActionLoading !== 'none'}
            />
          </ConditionalRenderer>
        </div>
      </BodyCell>
      <BodyCell className="overflow-hidden">
        <AnimatePresence>
          {viewInformations && (
            <motion.div
              className="w-full overflow-hidden"
              {...editFormAnimation}
            >
              <CourseDetails course={courseBase} onUpdate={updateCourseList} />
            </motion.div>
          )}
        </AnimatePresence>
      </BodyCell>
    </Row>
  );
}

function ActionCourseIcon(props: ActionCourseIconProps) {
  const iconClassName = 'text-primary w-6 h-6 self-center';
  const { Icon, href, tooltipText, disable, onClick, isLoading } = props;
  const navigate = useNavigate();

  function handleOnClickAction() {
    if (onClick) {
      return onClick;
    } else {
      return () => navigate(href || '/');
    }
  }

  if (isLoading) {
    return <LoadingIcon className="w-6 h-6 text-primary" />;
  }

  return (
    <Tooltip
      text={tooltipText}
      className={`${
        disable && 'pointer-events-none text-neutral/50'
      } flex items-center`}
    >
      <IconButton
        testId="ActionCourseIcon"
        onClick={handleOnClickAction()}
        icon={<Icon className={`w-5 h-5 ${iconClassName}`} />}
      />
    </Tooltip>
  );
}

const CourseDetails = ({
  course,
  onUpdate,
}: {
  course: ICourse;
  onUpdate: () => void;
}) => {
  return (
    <ConditionalRenderer
      condition={course}
      fallback={
        <LoadingView className="flex w-full h-64 justify-center items-center" />
      }
    >
      <div className="flex py-2.5 w-[70%]">
        <EditCourse course={course} updateCourseList={onUpdate} />
      </div>
    </ConditionalRenderer>
  );
};

type StatisticCellProps = {
  value?: number;
  isLoading: boolean;
  error: boolean;
  isPercentage?: boolean;
};
function StatisticCell({
  value = 0,
  isLoading,
  error,
  isPercentage,
}: StatisticCellProps) {
  const { t } = useTranslation('translation', {
    keyPrefix: 'manageCourse.dataCourseTable',
  });
  const formatedValue = isPercentage ? `${value}%` : value;
  return (
    <ConditionalRenderer
      condition={!isLoading}
      fallback={
        <Skeleton className="rounded-full h-4 w-6 bg-primary-content" />
      }
    >
      <ConditionalRenderer
        condition={!error}
        fallback={<Text text={t('statisticNotFound')} format="rubik-400" />}
      >
        <Text text={formatedValue} format="rubik-400" />
      </ConditionalRenderer>
    </ConditionalRenderer>
  );
}

const ChevronUpIconWithAnimation = motion(ChevronUpIcon);
