import React, { useRef, useState } from 'react';
import { useQueryClient, useQuery } from '@tanstack/react-query';
import moment from 'moment';
import {
  BadgeCheckIcon,
  ClockIcon,
  PuzzleIcon,
} from '@heroicons/react/outline';
import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/solid';
import { useNavigate } from 'react-router-dom';
import Text from '../common/dataDisplay/Text';
import SelectInput from '../common/dataInput/SelectInput';
import Skeleton from '../common/Skeleton';
import DateCard from './DateCard';
import TrialInfoCard from './TrialInfoCard';
import { StudentAgeEnum } from '@/models/User';
import ScheduledDay from '@/models/ScheduledDay';
import alert from '@/utils/UseAlert';
import getCourseStudentAge from '@/utils/getCourseStudentAge';
import ButtonTime from './ButtonTime';
import requester from '@/axios';
import JsonApiResponse from '@/models/JsonApiResponse';
import { useTranslation } from 'react-i18next';
import studentAgeDict from '@/utils/studentAgeDict';
import TrialEnrollment from '@/models/TrialEnrollment';
import listTimeZones from '@/functions/listTimeZones';
import { getTimezoneOffset } from 'date-fns-tz';
import datesDistincts from '@/functions/datesDistincts';
import useAuth from '@/data/hook/useAuth';

interface ScheduleTrialLessonProps {
  trialDates?: string[];
  studentAge: StudentAgeEnum;
  isLoadingGetDates: boolean;
  alterDate: boolean;
  trialEnrollment?: TrialEnrollment | null;
  setAlterDate: React.Dispatch<React.SetStateAction<boolean>>;
}

export default function ScheduleTrialLesson({
  studentAge,
  trialDates,
  isLoadingGetDates,
  alterDate,
  trialEnrollment,
  setAlterDate,
}: ScheduleTrialLessonProps) {
  const queryClient = useQueryClient();
  const { t } = useTranslation('translation', {
    keyPrefix: 'common.studentAge',
  });
  const { user } = useAuth();
  const trialDatesDistinct = datesDistincts(trialDates || []);
  const timeZones = listTimeZones();
  const navigate = useNavigate();
  const courseType = getCourseStudentAge(studentAge);
  const timezoneOffset = new Date().getTimezoneOffset() / 60;
  const contentDatesRef = useRef<HTMLDivElement>(null);
  const [idTrialLessonSelected, setIdTrialLessonSelected] = useState<number>();
  const [dateSelected, setDateSelected] = useState<string | undefined>(
    trialDatesDistinct.length > 0 ? trialDatesDistinct[0] : undefined,
  );
  const timeZone = new Intl.DateTimeFormat('default').resolvedOptions()
    .timeZone;

  const handlePrev = () => {
    const positionScrollLeft = contentDatesRef.current?.scrollLeft;

    if (typeof positionScrollLeft === 'number' && contentDatesRef.current) {
      contentDatesRef.current.scrollTo({
        left: positionScrollLeft - 348,
        behavior: 'smooth',
      });
    }
  };

  const handleNext = () => {
    const positionScrollLeft = contentDatesRef.current?.scrollLeft;

    if (typeof positionScrollLeft === 'number' && contentDatesRef.current) {
      contentDatesRef.current.scrollTo({
        left: positionScrollLeft + 348,
        behavior: 'smooth',
      });
    }
  };

  const {
    data: trialLessonsByDate,
    isInitialLoading: isLoadingTrialLessonsByDate,
  } = useQuery(
    ['trialLessonScheduledDay', dateSelected],
    async () => {
      const dateFormat = moment(dateSelected).format('YYYY-MM-DD');
      const res = await requester().get<JsonApiResponse<ScheduledDay>>(
        `trial-lesson/scheduled-day/?date=${dateFormat}&course_type=${courseType}&unit_id=${user?.unitsIds?.toString()}`,
      );
      return res.data;
    },
    { enabled: trialDates && trialDates.length > 0 },
  );

  async function handleOnSubmit() {
    if (!idTrialLessonSelected) {
      alert.error('Selecione um horário para prosseguir');
      return;
    }

    if (alterDate && trialEnrollment) {
      await requester().patch(
        `trial-lesson/enrollment/${trialEnrollment.id}/`,
        {
          scheduled_day_id: idTrialLessonSelected,
        },
      );
      queryClient.refetchQueries(['trialEnrollmentStudent']);
      alert.success('Aula experimental reagendada');
      setAlterDate(false);
      return;
    }

    navigate(
      `/agendamento/${idTrialLessonSelected}/?course_type=${courseType}`,
    );
  }

  const TrialDatesExeptions = () => {
    if (isLoadingGetDates) {
      return (
        <Skeleton className="bg-primary-content w-full h-52 rounded-2xl" />
      );
    }

    if (trialDates?.length === 0 || !trialDates) {
      return (
        <Text
          size="text-32"
          className="text-secondary shrink"
          format="poppins-600"
          text="Não há aulas de agendamento disponiveis"
        />
      );
    }

    return null;
  };

  return (
    <div className="w-full md:bg-circles md:bg-top bg-auto bg-top bg-circles90 bg-no-repeat sm:w-fit flex items-center flex-col-reverse md:flex-row gap-14">
      <TrialInfoCard
        header={
          <Text
            className="text-accent"
            format="rubik-500"
            text="Aula Experimental (60min)"
          />
        }
        studentAge={studentAge}
      >
        <React.Fragment>
          <div>
            <Text
              className="inline shrink-0 min-w-fit"
              size="text-14"
              text="Indicado para alunos de "
            />
            <Text
              format="rubik-500"
              className="inline shrink-0 min-w-fit"
              size="text-14"
              text={`${t(studentAgeDict[studentAge])} anos`}
            />
          </div>
          <div className="flex flex-col gap-3.5 text-14 w-full">
            <Text
              className="text-accent"
              format="rubik-500"
              text="O que está incluso:"
            />
            <div className="flex gap-3">
              <PuzzleIcon className="text-accent w-4 h-4" />
              <Text text="criação do primeiro jogo" />
            </div>
            <div className="flex gap-3">
              <BadgeCheckIcon className="text-accent w-4 h-4" />
              <Text text="certificado de participação" />
            </div>
            <div className="flex gap-3">
              <ClockIcon className="text-accent w-4 h-4" />
              <Text text="uma aula de 60 minutos de duração" />
            </div>
          </div>
        </React.Fragment>
      </TrialInfoCard>
      <div className="flex flex-col gap-6 w-full md:max-w-[447px]">
        <Text
          size="text-32"
          className="text-primary shrink"
          format="poppins-600"
          text="Agende sua Aula Experimental Grátis"
        />
        <div className="flex flex-col gap-5">
          <div className="flex flex-col gap-3 w-full">
            <div className="flex w-full items-center justify-between gap-2 flex-col sm:flex-row">
              <Text text="Escolha o dia e horário da aula" />
              <SelectInput
                defaultValue={timeZone}
                className={{ base: 'bg-base-100' }}
              >
                {timeZones.map(tz => {
                  const tzOffSet = getTimezoneOffset(tz, new Date()) / 3600000;

                  return (
                    <option key={tz} value={tz}>{`${tz.replace(
                      '_',
                      ' ',
                    )} (GMT ${tzOffSet}:00)`}</option>
                  );
                })}
                <option>{`${timeZone} (GMT -0${timezoneOffset}:00)`}</option>
              </SelectInput>
            </div>

            <React.Fragment>
              {isLoadingGetDates || !trialDates || trialDates.length === 0 ? (
                <TrialDatesExeptions />
              ) : (
                <div className="flex flex-row justify-between relative w-full">
                  <ChevronLeftIcon
                    onClick={handlePrev}
                    className="w-5 cursor-pointer absolute -left-5 top-2/4 -translate-y-1/2 text-primary hover:text-secondary"
                  />
                  <div
                    ref={contentDatesRef}
                    className="flex flex-row overflow-y-hidden scrollbar-none gap-5"
                  >
                    {datesDistincts(trialDates)?.map(date => {
                      const dateFormat = moment(date, 'YYYY-MM-DD');
                      return (
                        <DateCard
                          key={date.toString()}
                          selected={date === dateSelected}
                          date={dateFormat.toDate()}
                          onClick={() => setDateSelected(date)}
                        />
                      );
                    })}
                  </div>
                  <ChevronRightIcon
                    onClick={handleNext}
                    className="w-5 cursor-pointer absolute -right-5 top-2/4 -translate-y-1/2 text-primary hover:text-secondary"
                  />
                </div>
              )}
            </React.Fragment>

            <div className="flex flex-col gap-5">
              <div className="flex flex-wrap gap-x-4 max-w-screen-xs:justify-between sm:gap-x-1.5 gap-y-1.5">
                {isLoadingTrialLessonsByDate &&
                trialDates &&
                trialDates.length > 0 ? (
                  <Skeleton className="w-full bg-neutral-content rounded-lg h-8" />
                ) : (
                  trialLessonsByDate?.results.map(({ id, dateTime }, index) => {
                    const dateMoment = moment(new Date(dateTime));
                    const timeStart = dateMoment.format('HH:mm');
                    const timeEnd = dateMoment.add(1, 'hours').format('HH:mm');

                    return (
                      <ButtonTime
                        key={id}
                        onClick={() => setIdTrialLessonSelected(id)}
                        timeEnd={timeEnd}
                        timeStart={timeStart}
                        isSelected={id === idTrialLessonSelected}
                        delayAnimation={index + 1}
                      />
                    );
                  })
                )}
              </div>
              <button
                disabled={!trialLessonsByDate?.results}
                onClick={handleOnSubmit}
                className="w-full px-6 py-2 rounded-md leading-none text-base-100 bg-gradient-to-r
              from-secondary to-primary enabled:hover:from-secondary/40 enabled:hover:to-primary/40
              disabled:from-secondary-content disabled:to-primary-content focus-visible:ring-secondary/40"
              >
                {alterDate ? 'Alterar' : 'Avançar'}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
