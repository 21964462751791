import useActivityView from '@/data/hook/useActivityView';
import { useCallback, useRef } from 'react';

export const useAnswerDebounce = <T,>(time: number = 800) => {
  const timerRef = useRef<NodeJS.Timeout>();
  const { blockStepNavigate } = useActivityView();

  type TimerFn = () => T | Promise<T>;

  function debounceCallback(fn: TimerFn) {
    blockStepNavigate(true);
    async function handleAnswer() {
      await fn();
      blockStepNavigate(false);
    }
    const timer = setTimeout(handleAnswer, time);
    const currentTimer = timerRef.current;
    if (currentTimer) {
      clearTimeout(currentTimer);
    }
    timerRef.current = timer;
  }

  const debounce = useCallback(debounceCallback, [blockStepNavigate, time]);

  return { debounce };
};
