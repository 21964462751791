import { ReactNode } from 'react';
import { motion } from 'framer-motion';

import { fadeIn, fadeInVariants } from '@/utils/animations/commom';
import ConditionalRenderer from './ConditionalRenderer';
import { LoadingIcon } from '../icons';
import Text from './dataDisplay/Text';
import MainButton from './buttons/MainButton';
import { PlusIcon } from '@heroicons/react/outline';
import { useTranslation } from 'react-i18next';
import { twMerge } from 'tailwind-merge';

type ButtonInfiniteListProps = {
  testId?: string;
  isLoading?: boolean;
  fetchNextPage(): unknown | Promise<unknown>;
  hasNextPage?: boolean;
  isFetchingNextPage?: boolean;
  error?: string;
  children: ReactNode;
  listClassName?: string;
  divClassName?: string;
};

export default function ButtonInfiniteList({
  testId,
  isLoading,
  isFetchingNextPage,
  error,
  children,
  hasNextPage,
  fetchNextPage,
  listClassName,
  divClassName,
}: ButtonInfiniteListProps) {
  const { t } = useTranslation('translation', {
    keyPrefix: 'buttonInfiniteList',
  });

  return (
    <motion.div
      data-testid={testId ?? 'buttonInfiniteList'}
      className={`${twMerge('flex w-full', divClassName)} flex w-full ${
        isLoading ? 'justify-center' : ''
      }`}
      {...fadeIn}
    >
      <ConditionalRenderer condition={isLoading}>
        <LoadingIcon className="text-primary/40 w-10" />
      </ConditionalRenderer>
      <ConditionalRenderer condition={!isLoading && !!error}>
        <Text className="text-primary/40" text={error} />
      </ConditionalRenderer>
      <ConditionalRenderer condition={!isLoading}>
        <ul className={twMerge('flex flex-col w-full gap-6', listClassName)}>
          {children}

          <motion.div
            className="flex w-fit"
            animate={hasNextPage ? 'show' : 'hidden'}
            variants={fadeInVariants}
          >
            <MainButton
              loading={isFetchingNextPage}
              disabled={!hasNextPage}
              className="text-primary"
              icon={<PlusIcon className="w-4" />}
              text={t('loadMore')}
              color="custom"
              onClick={async () => await fetchNextPage()}
            />
          </motion.div>
        </ul>
      </ConditionalRenderer>
    </motion.div>
  );
}
