import { AxiosResponse } from 'axios';
import requester from '@/axios';
import { TrueOrFalseActivityProgress } from '@/models/ActivityProgress';

export interface GetTrueOrFalseProgressParams {
  trueOrFalseId: number;
  trueOrFalseProgressId: number;
}

export const getTrueOrFalseProgress = async ({
  trueOrFalseId,
  trueOrFalseProgressId,
}: GetTrueOrFalseProgressParams) => {
  const { data: trueOrFalseProgress } =
    await requester().get<TrueOrFalseActivityProgress>(
      `true-or-false-activity/${trueOrFalseId}/true-or-false-activity-progress/${trueOrFalseProgressId}/`,
    );
  return trueOrFalseProgress;
};

type UpdateTureOrFalseProgress = Pick<
  TrueOrFalseActivityProgress,
  'isDone' | 'answer'
>;

export const updateTrueOrFalseProgress = async (
  { trueOrFalseId, trueOrFalseProgressId }: GetTrueOrFalseProgressParams,
  body: UpdateTureOrFalseProgress,
) => {
  type Response = AxiosResponse<TrueOrFalseActivityProgress>;
  const { data: trueOrFalseProgress } = await requester().patch<
    UpdateTureOrFalseProgress,
    Response
  >(
    `true-or-false-activity/${trueOrFalseId}/true-or-false-activity-progress/${trueOrFalseProgressId}/`,
    body,
  );
  return trueOrFalseProgress;
};
