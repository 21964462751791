import React, { PropsWithChildren } from 'react';
import { twMerge } from 'tailwind-merge';
import useSidebar from '@/data/hook/useSidebar';

type OverlayColors = 'base' | 'neutral' | 'custom';

interface OverlayProps {
  className?: string;
  color?: OverlayColors;
  type?: 'window' | 'other';
}

const overlayColors: Record<OverlayColors, string> = {
  base: 'bg-base-100/90',
  neutral: 'bg-neutral/90 text-neutral-content',
  custom: '',
};

const Overlay: React.FC<PropsWithChildren<OverlayProps>> = ({
  children,
  className,
  color = 'neutral',
  type = 'window',
}) => {
  const { isOpen } = useSidebar();
  const isWindowOverlay = type === 'window';
  const containerClassName = isWindowOverlay
    ? '-left-5 lg:-left-12'
    : 'left-0 top-0 h-full';
  const windowOverlayPosition = `fixed px-5 ${isOpen ? 'lg:w-[90vw]' : ''}`;

  return (
    <div className={`z-20 absolute w-full ${containerClassName}`}>
      <div
        className={twMerge(
          `w-full h-full ${isWindowOverlay ? windowOverlayPosition : ''}`,
          className,
          overlayColors[color],
        )}
      >
        {children}
      </div>
    </div>
  );
};

export default Overlay;
