import { ReactNode, useRef, useEffect, useCallback, useState } from 'react';
import { motion } from 'framer-motion';
import { twMerge } from 'tailwind-merge';
import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/outline';

import useVisibleElement from '@/data/hook/useVisibleElement';
import { fadeIn } from '@/utils/animations/commom';
import { LoadingIcon } from '@/components/icons';
import ConditionalRenderer from './ConditionalRenderer';
import IconButton from './buttons/IconButton';

type CarouselProps = {
  children: ReactNode;
  hasNextPage?: boolean;
  fetchingNextPage?: boolean;
  className?: string;
  listClassName?: string;
  onReachEnd?(): unknown | Promise<unknown>;
  hiddenButtons?: boolean;
};

export default function Carousel({
  children,
  hasNextPage,
  fetchingNextPage,
  className,
  listClassName,
  onReachEnd,
  hiddenButtons,
}: CarouselProps) {
  const listRef = useRef<HTMLUListElement>(null);

  const startRef = useRef<HTMLSpanElement>(null);

  const endRef = useRef<HTMLSpanElement>(null);

  const [disableStartButton, setDisableStartButton] = useState(false);

  const [disableEndButton, setDisableEndButton] = useState(false);

  const scrollValue = 120 * 2.5;

  const endLoadingRef = useRef<HTMLDivElement>(null);

  const options: IntersectionObserverInit = {
    root: listRef.current,
    rootMargin: '100px',
  };

  const endIntersecting = useVisibleElement(endLoadingRef, options);

  const start = useVisibleElement(startRef, options);

  const end = useVisibleElement(endRef, options);

  const scroll = (left: number) => {
    listRef.current?.scrollTo({
      left,
      behavior: 'smooth',
    });
  };

  const onScroll = (direction: 1 | -1) => {
    if (listRef.current) {
      const scrollLeft = listRef.current.scrollLeft;

      if (direction === -1) {
        scroll(scrollLeft - scrollValue);
      } else {
        scroll(scrollLeft + scrollValue);
      }
    }
  };

  const reachEnd = useCallback(() => onReachEnd?.(), [onReachEnd]);

  useEffect(() => {
    if (endIntersecting) {
      reachEnd();
    }
  }, [endIntersecting, reachEnd]);

  useEffect(() => {
    if (start) setDisableStartButton(true);

    return () => setDisableStartButton(false);
  }, [start]);

  useEffect(() => {
    if (end) setDisableEndButton(true);

    return () => setDisableEndButton(false);
  }, [end]);

  return (
    <div className={twMerge('flex items-center', className)}>
      <IconButton
        icon={<ChevronLeftIcon className="w-6 text-primary" />}
        className={`-ml-6 ${
          disableStartButton || hiddenButtons ? 'disabled invisible' : ''
        }`}
        onClick={() => onScroll(-1)}
      />
      <motion.ul
        {...fadeIn}
        ref={listRef}
        className={twMerge(
          'flex py-4 gap-4 w-full',
          listClassName,
          'overflow-x-scroll relative scrollbar-hide hover:overscroll-y-contain scroll-smooth snap-x sm:snap-none',
        )}
      >
        <span className="absolute" ref={startRef} />
        {children}
        <ConditionalRenderer condition={hasNextPage}>
          <div
            data-positiontarget="end"
            className="flex w-full justify-center"
            ref={endLoadingRef}
          >
            <ConditionalRenderer condition={fetchingNextPage}>
              <LoadingIcon className="w-6 text-primary" />
            </ConditionalRenderer>
          </div>
        </ConditionalRenderer>
        <span ref={endRef} />
      </motion.ul>
      <IconButton
        icon={<ChevronRightIcon className="w-6 text-primary" />}
        className={`-mr-6 ${
          disableEndButton || hiddenButtons ? 'disabled invisible' : ''
        }`}
        onClick={() => onScroll(1)}
      />
    </div>
  );
}
