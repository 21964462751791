import requester from '@/axios';
import JsonApiResponse from '@/models/JsonApiResponse';
import { Project, ProjectMetadata } from '@/models/Project';
import { AxiosResponse } from 'axios';
import { BaseFilters, ListServiceFunc } from '@/models/Service';

export type ProjectFilters = BaseFilters & {
  byUpVotes?: 'more' | 'less';
  courseAbbreviation?: string;
  klass?: string;
  toolName?: string;
  user?: number;
  title?: string;
  'page[number]'?: number;
};

export const listProjects: ListServiceFunc<
  Project,
  ProjectFilters
> = async params => {
  const { data } = await requester().get<JsonApiResponse<Project>>(
    `projects/`,
    {
      params,
    },
  );
  return data;
};

export const getProject = async (id: number) => {
  const { data: project } = await requester().get<Project>(`projects/${id}/`);
  return project;
};

export const deleteProject = async (id: number) => {
  await requester().delete(`projects/${id}/`);
};

export type CreateProject = Pick<
  Project,
  'projectUrl' | 'title' | 'description' | 'shared'
> & {
  image?: File;
  imageUrl?: string;
  toolId: number;
  userId: number;
};

export const createProject = async (body: CreateProject) => {
  const formData = new FormData();
  Object.entries(body).forEach(([key, value]) => {
    if (value && key) {
      if (typeof value === 'object') {
        formData.set(key, value);
      } else {
        formData.set(key, value.toString());
      }
    }
  });
  const { data: project } = await requester().post<
    null,
    AxiosResponse<Project>,
    FormData
  >('projects/', formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
      accept: 'application/json',
    },
  });
  return project;
};

export const updateProject = async (id: number, body: CreateProject) => {
  const formData = new FormData();
  Object.entries(body).forEach(([key, value]) => {
    if (value && key) {
      if (typeof value === 'object') {
        formData.set(key, value);
      } else {
        formData.set(key, value.toString());
      }
    }
  });
  const { data: project } = await requester().patch<Project>(
    `projects/${id}/`,
    formData,
    {
      headers: {
        'Content-Type': 'multipart/form-data',
        accept: 'application/json',
      },
    },
  );
  return project;
};

export interface GetProjectDataBody {
  url: string;
  tool: string;
}

export const getProjectData = async (body: GetProjectDataBody) => {
  const { data: projectMetadata } = await requester().post<ProjectMetadata>(
    `get-project-data/by-tool/`,
    body,
  );
  return projectMetadata;
};

export const upvoteProject = async (id: number) => {
  await requester().post(`projects/${id}/add-up-vote/`);
};

export const deleteProjectUpvote = async (id: number) => {
  await requester().post(`projects/${id}/remove-up-vote/`);
};
