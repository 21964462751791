import ConditionalRenderer from '@/components/common/ConditionalRenderer';
import InfinityList from '@/components/common/InfinityList';
import Skeleton from '@/components/common/Skeleton';
import MainButton from '@/components/common/buttons/MainButton';
import Badge from '@/components/common/dataDisplay/Badge';
import Text from '@/components/common/dataDisplay/Text';
import { LoadingIcon } from '@/components/icons';
import useMutationObserver, {
  CustomMutationCallback,
} from '@/data/hook/useMutationObverser';
import { ClassNameComponent } from '@/utils/ClassNameComponent';
import { ArrowsExpandIcon } from '@heroicons/react/outline';
import { Variant, motion, useCycle } from 'framer-motion';
import { ReactNode, useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { twMerge } from 'tailwind-merge';

type Color = 'accent' | 'orange';

type CollapsableCardProps = {
  badgeCount?: number;
  headerIcon: JSX.Element;
  title: string;
  isLoading?: boolean;
  children: ReactNode;
  isCollapsable?: boolean;
  testId?: string;
  color?: Color;
  header?: ReactNode;
  onReachEnd?(): unknown | Promise<unknown>;
  isFetchingNextPage?: boolean;
  hasNextPage?: boolean;
  nav?: ReactNode;
  footer?: ReactNode;
  className?: ClassNameComponent;
};

const variants: Record<string, Variant> = {
  open: {
    transition: {
      staggerChildren: 0.07,
      delayChildren: 0.2,
      staggerDirection: 1,
      type: 'spring',
    },
    height: 'auto',
  },
  closed: {
    transition: {
      staggerChildren: 0.07,
      staggerDirection: -1,
      delayChildren: 0.02,
    },
    height: '16rem',
  },
};

type CardTheme = {
  background: string;
  text: string;
  border?: string;
};

const styles: Record<Color, CardTheme> = {
  accent: {
    background: 'bg-accent',
    text: 'text-accent',
  },
  orange: {
    background: 'bg-warning',
    text: 'text-warning',
  },
};

export default function CollapsableCard({
  badgeCount,
  headerIcon,
  title,
  isLoading,
  children,
  testId,
  color = 'accent',
  header,
  hasNextPage,
  onReachEnd,
  isFetchingNextPage,
  nav,
  footer,
  isCollapsable,
  className,
}: CollapsableCardProps) {
  const [isExpanded, toggleExpand] = useCycle(false, true);
  const { t } = useTranslation('translation', {
    keyPrefix: 'common',
  });
  const [hasScroll, setHasScroll] = useState(false);

  const checkScroll: CustomMutationCallback<HTMLUListElement> = useCallback(
    (_e, _o, ref) => ref && setHasScroll(ref.scrollHeight > 168),
    [],
  );

  const { ref } = useMutationObserver(checkScroll);

  return (
    <motion.div
      data-testid={testId}
      className={twMerge(
        'flex flex-col w-full max-h-full shadow-default shadow-primary-content rounded-2xl relative bg-base-100 border border-neutral-content',
        className?.base,
      )}
      animate={isExpanded ? 'open' : 'closed'}
      variants={variants}
    >
      <header
        className={twMerge(
          'flex items-center justify-between p-2.5 px-5',
          className?.header,
          `${styles[color].text} rounded-t-2xl`,
        )}
      >
        <div className="flex gap-5 items-center">
          <ConditionalRenderer
            condition={!isLoading}
            fallback={
              <Skeleton className="w-8 h-8 bg-accent-content rounded-full" />
            }
          >
            <Badge
              count={badgeCount}
              position="-right-1 top-0"
              className="border-none"
            >
              <div
                className={`p-1 text-base-100 rounded-full ${styles[color].background}`}
              >
                {headerIcon}
              </div>
            </Badge>
          </ConditionalRenderer>
          <Text text={title} format="rubik-500" className="uppercase" />
        </div>
        {header}
      </header>
      <nav className={twMerge('flex w-full', className?.nav)}>{nav}</nav>
      <div className="flex overflow-y-hidden">
        <InfinityList
          scroll
          className={twMerge(
            'grow min-h-[168px] max-h-96 flex flex-col gap-4 p-4',
            className?.ul,
          )}
          hasNextPage={hasNextPage}
          isFetchingNextPage={isFetchingNextPage}
          onReachEnd={onReachEnd}
          ref={ref}
        >
          <ConditionalRenderer
            condition={!isLoading}
            fallback={
              <div className="flex justify-center h-full items-center py-5">
                <LoadingIcon
                  data-testid="loading"
                  className="w-1/6 text-accent/40"
                />
              </div>
            }
          >
            {children}
          </ConditionalRenderer>
        </InfinityList>
      </div>
      <footer
        className={twMerge(
          'flex w-full',
          `${hasScroll && isCollapsable ? 'visible' : 'disabled invisible'}`,
          className?.footer,
        )}
      >
        {footer}

        <MainButton
          text={isExpanded ? t('collapse') : t('expand')}
          className="flex items-center gap-x-5 text-base-100 bg-accent px-5 py-2.5 rounded-b-2xl w-full"
          color="custom"
          icon={<ArrowsExpandIcon />}
          onClick={() => toggleExpand()}
        />
      </footer>
    </motion.div>
  );
}
