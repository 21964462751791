import Klass from '@/models/Klass';
import { twMerge } from 'tailwind-merge';
import { useTranslation } from 'react-i18next';
import {
  BookOpenIcon,
  DocumentReportIcon,
  DocumentTextIcon,
  InformationCircleIcon,
  UserGroupIcon,
} from '@heroicons/react/outline';

import { OptionItem } from '@/models/OptionItem';
import { ROUTES } from '@/utils/routes';
import { KlassOptionType } from '@/models/OptionType';

type UseKlassOptions = {
  klass: Klass;
  option?: KlassOptionType;
  classNameIcons?: string;
};

export default function useKlassOptions({
  klass,
  option,
  classNameIcons,
}: UseKlassOptions) {
  const { t } = useTranslation('translation', {
    keyPrefix: 'useKlassOption',
  });

  const className = twMerge('w-6 text-primary', classNameIcons);

  const options: {
    [key in KlassOptionType]: OptionItem;
  } = {
    info: {
      id: `info${klass.id}`,
      text: t('info'),
      icon: <InformationCircleIcon className={className} />,
      to: ROUTES.ADMIN.CLASSES.INFO({
        name: klass.name.replace('#', ''),
      }),
    },
    lessons: {
      id: `lessons${klass.id}`,
      text: t('lessons'),
      icon: <BookOpenIcon className={className} />,
      to: ROUTES.ADMIN.CLASSES.LESSONS({
        name: klass.name.replace('#', ''),
      }),
    },
    members: {
      id: `members${klass.id}`,
      text: t('members'),
      icon: <UserGroupIcon className={className} />,
      to: ROUTES.ADMIN.CLASSES.MEMBERS({
        name: klass.name.replace('#', ''),
      }),
    },
    progress: {
      id: `progress${klass.id}`,
      text: t('progress'),
      icon: <DocumentReportIcon className={className} />,
      to: ROUTES.CLASS.PROGRESS(klass.id),
    },
    sheet: {
      id: `sheet${klass.id}`,
      text: t('sheet'),
      icon: <DocumentTextIcon className={className} />,
      to: ROUTES.ADMIN.CLASSES.SHEET({
        name: klass.name.replace('#', ''),
      }),
    },
  };
  return { options, option: options[option ?? 'info'] };
}
