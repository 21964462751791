import { useEffect, useState } from 'react';
import User from '@/models/User';
import {
  LockClosedIcon,
  InformationCircleIcon,
  ChevronUpIcon,
  DocumentTextIcon,
  FolderOpenIcon,
  BriefcaseIcon,
} from '@heroicons/react/outline';
import EditStudent from './EditStudent';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import ModalChangePassword from '@/components/common/modals/ModalChangePassword';
import IconButton from '@/components/common/buttons/IconButton';
import { useParams } from 'react-router-dom';
import { StudentHeader } from '@/utils/HeaderTypes';
import Row from '@/components/common/table/Row';
import BodyCell from '@/components/common/table/BodyCell';
import Text from '@/components/common/dataDisplay/Text';
import { Tooltip } from '@/components/common/dataDisplay/Tooltip';
import { handleUserFullName } from '@/functions/handleUserFullName';
import { ModalRewards } from '../../common/modals/ModalRewards/ModalRewards';
import { CoinIcon, LoadingIcon } from '../../icons';
import TableCell from '../../common/TableCell';
import ConditionalRenderer from '../../common/ConditionalRenderer';
import Observable from '@/utils/observers/ObserverPattern';
import { AnimatePresence, motion } from 'framer-motion';
import { editFormAnimation } from '@/utils/animations/formAnimations';
import TextWithCopy from '@/components/common/dataDisplay/TextWithCopy';
import { IconByStatus } from '@/components/common/dataDisplay/IconByUserStatus';
import UnitDisplay from '@/components/common/table/UnitDisplay';
import PedagogicalSheet from './PedagogicalSheet/PedagogicalSheet';
import KlassesDisplay from '@/components/common/table/KlassesDisplay';
import StudentHistoric from './StudentHistoric';
import { useQuery } from '@tanstack/react-query';
import {
  rewardsQueryKeys,
  userProfilesQueryKeys,
} from '@/data/services/querykeys';
import useFilterParams from '@/utils/UseFilterParams';
import { ListUsersFilters } from '@/data/services/userServices';
import StudentVacationNotice from './StudentVacationNotice/StudentVacationNotice';

interface ListStudentsProps {
  students: User[];
  titles: StudentHeader;
  setVacationNoticeRef?: (ref: HTMLElement) => void;
}

export default function DataStudentsTable(props: ListStudentsProps) {
  const { students, titles, setVacationNoticeRef } = props;

  const observer = new Observable<number>();

  return (
    <tbody className="gap-y-2.5 flex flex-col">
      {students.map((student, index) => (
        <RenderRow
          key={student.id}
          student={student}
          titles={titles}
          observer={observer}
          setVacationNoticeRef={index === 0 ? setVacationNoticeRef : undefined}
        />
      ))}
    </tbody>
  );
}

interface RenderRowStudentsProps {
  student: User;
  titles: StudentHeader;
  observer?: Observable<number>;
  setVacationNoticeRef?: (ref: HTMLElement) => void;
}

type Tabs = 'info' | 'sheet' | 'historic' | 'vacation';

function RenderRow({
  student,
  titles,
  observer,
  setVacationNoticeRef,
}: RenderRowStudentsProps) {
  const { t } = useTranslation('translation', {
    keyPrefix: 'manageStudent',
  });

  const { actionId } = useParams();

  const { filterParams } = useFilterParams<ListUsersFilters>();

  const search = filterParams.get('search') ?? undefined;

  const [viewInformations, setViewInformations] = useState(
    search === handleUserFullName(student),
  );
  const [isVisibleModal, setIsVisibleModal] = useState<
    'password' | 'rewards'
  >();

  function renderLastLogin(date?: Date) {
    return !date ? t('dataUserTable.never') : moment(date).format('DD/MM/YYYY');
  }

  const [tab, setTab] = useState<Tabs>((actionId as Tabs) || 'info');

  const toogleViewInformations = (newTab?: Tabs) => {
    if (tab !== newTab) {
      newTab && setTab(newTab);
      setViewInformations(true);
      return;
    }
    if (!viewInformations) observer?.notifyAll(student?.id);
    setViewInformations(old => !old);
    if (tab) setTab(tab);
  };

  useEffect(() => {
    student.id &&
      observer?.subscribe({
        id: student.id,
        action: () => {
          setViewInformations(false);
        },
      });
  }, [student.id, observer]);

  return (
    <Row testId="trTestFilter">
      <BodyCell className="p-0 w-full">
        <div
          className="flex items-center cursor-pointer gap-5"
          style={{ width: `${titles.name.size}%` }}
          data-testid={`student${student.id}`}
          onClick={() => toogleViewInformations(tab)}
        >
          <IconButton
            testId="studentInfoButton"
            className="transition ease-in-out duration-150"
            icon={
              <ChevronUpIconWithAnimation
                animate={{
                  rotate: !viewInformations ? 180 : 0,
                }}
                className="w-3 h-3"
              />
            }
          />

          <Text text={handleUserFullName(student)} />
        </div>

        <KlassesDisplay
          klasses={student.klasses ?? []}
          width={titles.klass?.size}
        />

        <div
          className="flex items-center"
          style={{ width: `${titles.email?.size}%` }}
        >
          <TextWithCopy
            format="rubik-400"
            className={{ text: 'self-center h-fit break-all' }}
            label={student.email || ''}
          />
        </div>

        <UnitDisplay width={titles?.unit?.size} unitsIds={student.unitsIds} />

        <div
          data-testid="lastLogin"
          className="flex items-center"
          style={{ width: `${titles.lastAccess?.size}%` }}
        >
          <Text
            format="rubik-400"
            className="h-fit"
            text={renderLastLogin(student.lastLogin)}
          />
        </div>

        <div
          className="flex items-center"
          style={{ width: `${titles.status.size}%` }}
        >
          <Tooltip text={t(`status.${student.status}`)}>
            <IconByStatus status={student.status} />
          </Tooltip>
        </div>

        <div
          className="flex items-center gap-x-2.5"
          style={{ width: `${titles.actions.size}%` }}
        >
          <Tooltip text={t('dataUserTable.infoTooltip')}>
            <IconButton
              onClick={() => toogleViewInformations('info')}
              icon={<InformationCircleIcon className="text-primary w-6 h-6" />}
            />
          </Tooltip>
          <Tooltip text={t('dataUserTable.passwordTooltip')}>
            <IconButton
              testId="password"
              onClick={() => setIsVisibleModal('password')}
              icon={<LockClosedIcon className="text-primary w-6 h-6" />}
            />
          </Tooltip>
          <Tooltip text={t('dataUserTable.rewardsTooltip')}>
            <IconButton
              testId={`rewards${student.id}`}
              onClick={() => setIsVisibleModal('rewards')}
              icon={<CoinIcon className="text-primary w-6 h-6" />}
            />
          </Tooltip>
          <Tooltip text={t('dataUserTable.pedagogicalSheetTooltip')}>
            <IconButton
              testId={`sheet${student.id}`}
              onClick={() => toogleViewInformations('sheet')}
              icon={<DocumentTextIcon className="text-primary w-6 h-6" />}
            />
          </Tooltip>
          <Tooltip text={t('dataUserTable.historicTooltip')}>
            <IconButton
              testId={`historic${student.id}`}
              onClick={() => toogleViewInformations('historic')}
              icon={<FolderOpenIcon className="text-primary w-6 h-6" />}
            />
          </Tooltip>
          <section ref={setVacationNoticeRef}>
            <Tooltip text={t('dataUserTable.vacationNoticeTooltip')}>
              <IconButton
                testId={`historic${student.id}`}
                onClick={() => toogleViewInformations('vacation')}
                icon={<BriefcaseIcon className="text-primary w-6 h-6" />}
              />
            </Tooltip>
          </section>
        </div>
      </BodyCell>

      <TableCell>
        <ModalChangePassword
          user={student}
          visible={isVisibleModal === 'password'}
          onClickCancel={() => setIsVisibleModal(undefined)}
        />
        <ModalRewards
          student={student}
          isVisible={isVisibleModal === 'rewards'}
          onClickConfirm={() => setIsVisibleModal(undefined)}
          onClickCancel={() => setIsVisibleModal(undefined)}
        />
      </TableCell>
      <BodyCell>
        <AnimatePresence>
          {viewInformations && (
            <motion.div className="w-full" {...editFormAnimation}>
              <StudentDetails student={student} tab={tab} />
            </motion.div>
          )}
        </AnimatePresence>
      </BodyCell>
    </Row>
  );
}

interface StudentDetailsProps {
  student: User;
  tab: Tabs;
  onUpdate?: () => void;
}

function StudentDetails({ student, tab }: StudentDetailsProps) {
  const { data: rewards, isInitialLoading: isLoadingRewards } = useQuery({
    ...rewardsQueryKeys.get(student.id),
    enabled: !!student.id && tab === 'info',
  });

  const { data: profile, isInitialLoading: isLoadingProfile } = useQuery({
    ...userProfilesQueryKeys.get(student.username),
    enabled: !!student.username && tab === 'info',
  });

  const tabs = {
    info: (
      <ConditionalRenderer condition={!!profile}>
        {profile && (
          <EditStudent
            user={student}
            profile={profile}
            rewards={rewards}
            isLoadingRewards={isLoadingRewards}
          />
        )}
      </ConditionalRenderer>
    ),
    sheet: <PedagogicalSheet student={student} />,
    historic: <StudentHistoric student={student} />,
    vacation: <StudentVacationNotice student={student} />,
  };

  return (
    <div className="flex w-full">
      <ConditionalRenderer
        condition={!isLoadingProfile}
        fallback={
          <div className="flex w-full justify-center p-3.5">
            <LoadingIcon className="text-primary/40 w-10" />
          </div>
        }
      >
        {tabs[tab || 'info']}
      </ConditionalRenderer>
    </div>
  );
}

const ChevronUpIconWithAnimation = motion(ChevronUpIcon);
