interface CellProps extends React.TdHTMLAttributes<HTMLTableDataCellElement> {
  children: JSX.Element | JSX.Element[];
  onClick?: React.MouseEventHandler<HTMLTableDataCellElement>;
  className?: string;
  size?: string;
  testId?: string;
}

export default function Row(props: CellProps) {
  const { children, onClick, className, size, testId, ...rest } = props;

  return (
    <td
      {...rest}
      data-testid={testId}
      onClick={onClick}
      className={`flex items-center justify-between ${className || ''}`}
      style={{ width: `${size}%` }}
    >
      {children}
    </td>
  );
}
