import requester from '@/axios';
import { CourseStatistics } from '@/models/Course';
import JsonApiResponse from '@/models/JsonApiResponse';
import { BaseFilters, ListServiceFunc } from '@/models/Service';
import {
  HomeworkStatistics,
  PendingHomeworkCount,
  ScheduledLessonsStatistics,
  TeacherReportStatistics,
  TeacherReportStatisticsCount,
  TeacherStudentsCount,
  TotalCoursesStatistics,
  UnitsStatistics,
  UnitsStatisticsCount,
  UserCount,
  UserStatistics,
} from '@/models/Statistics';
import { UserTypeEnum } from '@/models/User';

export type UnitStatisticsFilters = BaseFilters & {
  dateAfter?: string;
  dateBefore?: string;
  unitId?: number[];
};

export const getUnitsStatistics: ListServiceFunc<
  UnitsStatistics,
  UnitStatisticsFilters
> = async function (params: UnitStatisticsFilters = {}) {
  const { data } = await requester().get<JsonApiResponse<UnitsStatistics>>(
    'analytics/units/statistics/',
    { params },
  );

  return data;
};

export const getTotalUnits = async function (
  params: UnitStatisticsFilters = {},
) {
  const { data } = await requester().get<JsonApiResponse<UnitsStatisticsCount>>(
    'analytics/units/statistics/total-sum/',
    { params },
  );

  return data;
};

export type TeacherReportFilters = BaseFilters & {
  teacherId?: number[];
  unitId?: number[];
};

export const getTeacherReportStatisticsCount = async (
  params: TeacherReportFilters = {},
) => {
  const { data } = await requester().get<
    JsonApiResponse<TeacherReportStatisticsCount>
  >('analytics/teachers/pending-summary/total-sum/', {
    params,
  });

  return data;
};

export const getTeacherReportStatistics: ListServiceFunc<
  TeacherReportStatistics,
  TeacherReportFilters
> = async (params: TeacherReportFilters = {}) => {
  const { data } = await requester().get<
    JsonApiResponse<TeacherReportStatistics>
  >('analytics/teachers/pending-summary/', {
    params,
  });

  return data;
};

export const getTeacherStudentsCount = async (
  params: TeacherReportFilters = {},
) => {
  const { data } = await requester().get<JsonApiResponse<TeacherStudentsCount>>(
    'analytics/teachers/students-count/',
    {
      params,
    },
  );

  return data;
};

type UpdateUnitStatistics = {
  unitId?: number;
};

export const updateUnitsStatistics = async (
  body: UpdateUnitStatistics = {},
) => {
  await requester().post('analytics/units/statistics/update/', body);
};

export interface UserStatisticsParams extends BaseFilters {
  userType?: UserTypeEnum;
  exUserType?: UserTypeEnum[];
  teacherId?: number[];
  unitId?: number[];
}

export const getAccessWeekByUserTypeCount = async (
  params: UserStatisticsParams = {},
) => {
  const { data } = await requester().get<JsonApiResponse<UserStatistics>>(
    'analytics/users/access-count/',
    {
      params: { ...params },
    },
  );
  return data;
};

export const getUsersByUserTypeCount = async (
  params: UserStatisticsParams = {},
) => {
  const exUserType = params.exUserType?.join(',');
  const { data } = await requester().get<JsonApiResponse<UserCount>>(
    'analytics/users/count/',
    {
      params: { ...params, exUserType },
    },
  );
  return data;
};

export interface ListPendingHomeworkFilters extends BaseFilters {
  teacherId?: number;
  klassId?: number;
  unitId?: number;
}

export const listPendingHomeworks = async (
  params: ListPendingHomeworkFilters = {},
) => {
  const { data } = await requester().get<JsonApiResponse<HomeworkStatistics>>(
    `analytics/teachers/pending-homeworks/`,
    {
      params,
    },
  );
  return data;
};

export const getPendingHomeworksCount: ListServiceFunc<
  PendingHomeworkCount,
  ListPendingHomeworkFilters
> = async (params: ListPendingHomeworkFilters = {}) => {
  const { data } = await requester().get<JsonApiResponse<PendingHomeworkCount>>(
    `analytics/teachers/pending-homeworks/total-sum/`,
    {
      params,
    },
  );
  return data;
};
export interface ListCourseStatisticsFilters {
  pageNumber?: number;
  pageSize?: number;
  search?: string;
  courseType?: string[];
  courseId?: number;
}

export const listCourseStatistics = async (
  params: ListCourseStatisticsFilters = {},
) => {
  const { data } = await requester().get<JsonApiResponse<CourseStatistics>>(
    'analytics/courses/statistics/',
    {
      params,
    },
  );
  return data;
};

export const getTotalCourses = async () => {
  const { data } = await requester().get<
    JsonApiResponse<TotalCoursesStatistics>
  >('analytics/courses/statistics/total-sum/');
  return data;
};

export interface ScheduledLessonStatisticsFilters extends BaseFilters {
  unitId?: number[];
  dateAfter?: string;
  dateBefore?: string;
  klassId?: number;
  studentId?: number;
}

export const getScheduledLessonsStatistics = async (
  params: ScheduledLessonStatisticsFilters = {},
) => {
  const { data } = await requester().get<
    JsonApiResponse<ScheduledLessonsStatistics>
  >('analytics/scheduled-lessons/statistics/', { params });
  return data;
};
