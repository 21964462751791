import { useRef, useEffect } from 'react';
import faviconNotify from '@/assets/image/icons/favicon-notify.png';
import favicon from '@/assets/image/icons/favicon.png';

export const useFaviconAlert = () => {
  const changedFavicon = useRef(false);
  const changeFavicon = (emoji = faviconNotify) => {
    if (typeof window === 'undefined') return;
    const existingLink = window.document.querySelector("link[rel*='icon']");
    if (existingLink) {
      existingLink.parentNode?.removeChild(existingLink);
    }

    const link: HTMLLinkElement = window.document.createElement('link');
    link.rel = 'icon';
    link.href = emoji;
    const head = window.document.getElementsByTagName('head')[0];
    head.insertBefore(link, head.firstChild);
    changedFavicon.current = true;
  };

  useEffect(() => {
    const restoreFavicon = () => {
      if (!changedFavicon.current) return;
      changeFavicon(favicon);
      changedFavicon.current = false;
    };
    window.addEventListener('mousemove', restoreFavicon);
    return () => {
      window.removeEventListener('mousemove', restoreFavicon);
    };
  }, []);

  return { changeFavicon };
};
