import ConditionalRenderer from '@/components/common/ConditionalRenderer';
import { LoadingIcon } from '@/components/icons';
import useVisibleElement from '@/data/hook/useVisibleElement';
import { fadeIn } from '@/utils/animations/commom';
import { scrollBarYClassName } from '@/utils/scrollBarClassName';
import { motion } from 'framer-motion';
import {
  ReactNode,
  UIEventHandler,
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from 'react';
import { twMerge } from 'tailwind-merge';

export type InfinityListProps = {
  children: ReactNode;
  onReachEnd?(): unknown | Promise<unknown>;
  onReachStart?(): unknown | Promise<unknown>;
  isFetchingNextPage?: boolean;
  isFetchingPreviousPage?: boolean;
  hasNextPage?: boolean;
  hasPreviousPage?: boolean;
  className?: string;
  scroll?: boolean;
  onScroll?: UIEventHandler<HTMLUListElement>;
};

const InfinityList = forwardRef<HTMLUListElement, InfinityListProps>(
  (
    {
      children,
      onReachStart,
      onReachEnd,
      isFetchingPreviousPage,
      isFetchingNextPage,
      className,
      hasNextPage,
      hasPreviousPage,
      scroll,
      onScroll,
    }: InfinityListProps,
    listRef,
  ) => {
    const startLoadingRef = useRef<HTMLDivElement>(null);
    const endLoadingRef = useRef<HTMLDivElement>(null);

    const [localListRef, setLocalListRef] = useState<HTMLUListElement | null>(
      null,
    );

    const options: IntersectionObserverInit = {
      root: scroll ? localListRef : undefined,
      rootMargin: '100px',
    };

    const endIntersecting = useVisibleElement(endLoadingRef, options);

    const startIntersecting = useVisibleElement(startLoadingRef, options);

    const reachEnd = useCallback(() => onReachEnd?.(), [onReachEnd]);

    const reachStart = useCallback(() => onReachStart?.(), [onReachStart]);

    useEffect(() => {
      if (startIntersecting) reachStart();
    }, [startIntersecting, reachStart]);

    useEffect(() => {
      if (endIntersecting) reachEnd();
    }, [endIntersecting, reachEnd]);

    useImperativeHandle(listRef, () => localListRef as HTMLUListElement);

    const listClassName = scroll ? scrollBarYClassName : '';

    return (
      <motion.ul
        onScroll={onScroll}
        ref={setLocalListRef}
        className={twMerge('flex flex-col w-full', className, listClassName)}
        {...fadeIn}
      >
        <ConditionalRenderer condition={hasPreviousPage}>
          <div data-positiontarget="start" ref={startLoadingRef}>
            <ConditionalRenderer condition={isFetchingPreviousPage}>
              <LoadingIcon className="w-6 m-2 text-primary" />
            </ConditionalRenderer>
          </div>
        </ConditionalRenderer>

        {children}

        <ConditionalRenderer condition={hasNextPage}>
          <div
            data-positiontarget="end"
            ref={endLoadingRef}
            className="flex w-full justify-center col-span-full"
          >
            <ConditionalRenderer condition={isFetchingNextPage}>
              <LoadingIcon className="w-6 m-2 text-primary" />
            </ConditionalRenderer>
          </div>
        </ConditionalRenderer>
      </motion.ul>
    );
  },
);

export default InfinityList;
