import { useTranslation } from 'react-i18next';
import DNDPositionalActivityProgress from '@/models/DNDPositionalActivityProgress';
import ActivityHeading from '../../ActivityHeading';
import { ActivityMode } from '@/models/ActivityProgress';
import DNDPositionalColumnProgress from './DNDPositionalColumnProgress';
import DNDPositionalColumn from '../DNDPositionalColumns/DNDPositionalColumn';
import { DNDColumn } from '@/models/DNDColumn';
import { Fragment } from 'react';

interface DNDPositionalColumnsProgressProps {
  dndPositionalActivityProgress: DNDPositionalActivityProgress;
  activityMode: ActivityMode;
  setHasChange(hasChange: boolean): Promise<void> | void;
  answerColumn?: DNDColumn;
  attemptElement?: any;
}

export default function DNDPositionalColumnsProgress({
  dndPositionalActivityProgress,
  activityMode,
  setHasChange,
  answerColumn,
  attemptElement,
}: DNDPositionalColumnsProgressProps) {
  const { dndPositionalActivity, columnProgress, questionColumn } =
    dndPositionalActivityProgress;
  const { title, subtitle } = dndPositionalActivity;

  const { t } = useTranslation('translation', {
    keyPrefix: 'lesson.activity.dragAndDropPositional',
  });

  switch (activityMode) {
    case 'activity': {
      return (
        <div className="flex flex-col gap-8 items-center">
          <ActivityHeading
            heading={title}
            subheading={t('instruction')}
            content={subtitle}
          />

          <div className="grid grid-cols-2 gap-10 w-full md:max-w-[80%]">
            <DNDPositionalColumn dndPositionalColumn={questionColumn} />
            <DNDPositionalColumnProgress
              dndPositionalColumnProgress={columnProgress}
              activityMode={activityMode}
              setHasChange={setHasChange}
            />
          </div>
        </div>
      );
    }

    case 'review':
    case 'answerkey': {
      const attemptProgress =
        attemptElement && 'answers' in attemptElement
          ? attemptElement.answers
          : undefined;

      if (attemptProgress) {
        return (
          <div className="flex flex-col gap-8 items-center">
            <ActivityHeading
              heading={title}
              subheading={t('instruction')}
              content={subtitle}
            />
            <div className="grid grid-cols-2 gap-10 w-full md:max-w-[80%]">
              <DNDPositionalColumn dndPositionalColumn={questionColumn} />
              <DNDPositionalColumnProgress
                dndPositionalColumnProgress={attemptProgress.columnProgress}
                activityMode={activityMode}
                setHasChange={setHasChange}
                columnProgress={columnProgress}
                answerColumn={answerColumn}
              />
            </div>
          </div>
        );
      }

      return <Fragment />;
    }
    default:
      return <Fragment />;
  }
}
