export type bankTypes = 'all' | 'lessons' | 'activities' | 'questions';

export type Colors = Record<bankTypes, ColorProperties>;

export type ColorProperties = {
  border: string;
  color: string;
  background: string;
  cardBackground?: string;
  cardBackgroundUsage?: string;
};

export const modalColors: Colors = {
  all: {
    border: 'border-primary',
    color: 'text-primary',
    background: 'bg-primary',
  },
  lessons: {
    border: 'border-warning',
    color: 'text-warning',
    background: 'bg-warning',
    cardBackground: 'bg-warning-content/30',
    cardBackgroundUsage: 'bg-warning-content/50',
  },
  activities: {
    border: 'border-secondary',
    color: 'text-secondary',
    background: 'bg-secondary',
    cardBackground: 'bg-secondary-content/30',
  },
  questions: {
    border: 'border-accent',
    color: 'text-accent',
    background: 'bg-accent',
    cardBackground: 'bg-accent-content/30',
  },
};

export type Views = 'list' | 'add';
