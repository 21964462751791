import { useState } from 'react';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';
import { SaveIcon, ShoppingBagIcon, XIcon } from '@heroicons/react/outline';
import useAuth from '@/data/hook/useAuth';
import AvatarCharacter from './AvatarCharacter';
import MainButton from '@/components/common/buttons/MainButton';
import { Avatar, BaseItem, AvatarItemsEnum } from '@/models/Avatar';
import alert from '@/utils/UseAlert';
import {
  getAvatarInfo,
  handleAvatarPatchRequest,
  inventoryCategoriesKeys,
} from '@/utils/userAvatarUtils';
import { ListItemsInventory } from './ListItemsInventory';
import {
  avatarInventoriesQueryKeys,
  avatarsQueryKeys,
} from '@/data/services/querykeys';
import { updateUserAvatar } from '@/data/services/avatarServices';
import Tag from '@/components/common/dataDisplay/Tag';
import { BackpackIcon, Coin, Gem } from '@/components/icons';
import ConditionalRenderer from '@/components/common/ConditionalRenderer';
import { isStudent } from '@/functions/auth';
import Text from '@/components/common/dataDisplay/Text';
import { ListItemsShop } from './ListItemsShop';
import InventoryShopTabs from './InventoryShopTabs';
import useStudentContext from '@/data/hook/student';
import { EventTrack } from '@/models/EventTrack';
import { eventTrack } from '@/functions/eventTrack';

interface InventoryModalProps {
  visible: boolean;
  onClose: () => void;
  userAvatar: Avatar;
}

export default function InventoryModal({
  onClose,
  visible,
  userAvatar,
}: InventoryModalProps) {
  const { user } = useAuth();
  const { t } = useTranslation('translation', {
    keyPrefix: 'inventory',
  });
  const [selectedCategory, setSelectedCategory] =
    useState<inventoryCategoriesKeys>('base');
  const [selectedSubcategory, setSelectedSubcategory] =
    useState<AvatarItemsEnum>(AvatarItemsEnum.base);
  const queryClient = useQueryClient();
  const [previewAvatar, setPreviewAvatar] = useState<Avatar>(userAvatar);
  const [shopAvatar, setShopAvatar] = useState<Avatar>(previewAvatar);
  const { inventoryCategory } = getAvatarInfo(
    previewAvatar,
    selectedSubcategory,
  );
  const [isSaving, setIsSaving] = useState(false);
  const [modalType, setModalType] = useState<'inventory' | 'shop'>('inventory');
  const isInventory = modalType === 'inventory';
  const isStudentType = isStudent(user?.userType);
  const { rewardsData } = useStudentContext();

  const { data: userInventory, isLoading: isLoadingUserInventory } = useQuery(
    avatarInventoriesQueryKeys.get(userAvatar.id),
  );

  const handleSaveButton = async () => {
    try {
      setIsSaving(true);
      await updateUserAvatar(
        userAvatar.user,
        handleAvatarPatchRequest(previewAvatar),
      );

      queryClient.invalidateQueries(
        avatarsQueryKeys.get(userAvatar.user ?? 0).queryKey,
      );
      alert.success(t('alterSuccess'));

      const event: EventTrack = {
        category: 'Inventory',
        action: 'Inventory update',
        label: user?.userType ?? '',
      };

      eventTrack(event);

      onClose();
    } catch (err) {
      alert.error(t('alterFail'));
    } finally {
      setIsSaving(false);
    }
  };

  const handleOnChangeInventory = (
    newValue: BaseItem | null,
    color: string,
  ) => {
    setPreviewAvatar({
      ...previewAvatar,
      [selectedSubcategory]: {
        color,
        [inventoryCategory]: newValue,
      },
    });
    setShopAvatar({
      ...shopAvatar,
      [selectedSubcategory]: {
        color,
        [inventoryCategory]: newValue,
      },
    });
  };

  const handleOnChangeShop = (newValue: BaseItem | null, color: string) => {
    setShopAvatar({
      ...shopAvatar,
      [selectedSubcategory]: {
        color,
        [inventoryCategory]: newValue,
      },
    });
  };

  const displayInventoryItems = () => {
    if (userInventory) {
      if (
        selectedSubcategory === AvatarItemsEnum.rightHandAcessory ||
        selectedSubcategory === AvatarItemsEnum.leftHandAcessory
      ) {
        return userInventory.handAcessory;
      } else return userInventory[selectedSubcategory];
    } else return [];
  };

  const tagClassname = (type: string) => {
    return `text-14 cursor-pointer gap-1 pl-1 pr-1.5 py-0.5 rounded-full border border-warning ${
      modalType === type
        ? 'text-base-100 bg-warning '
        : 'text-warning bg-base-100'
    }`;
  };

  return (
    <div
      data-testid="modal-backpack"
      className={`modal ${
        visible ? 'modal-open' : ''
      } flex justify-center overflow-visible text-center h-screen w-screen`}
    >
      <div className="modal-box max-w-[34rem] overflow-visible relative h-fit bg-base-100 p-0">
        <Text
          format="poppins-600"
          className={`${
            isStudentType ? 'top-10 hidden sm:flex' : 'top-4'
          } absolute  top-10 left-4 z-50 text-16 md:text-32 text-base-100`}
          text={isInventory ? t('inventory') : t('shop')}
        />
        <ConditionalRenderer condition={isStudentType}>
          <div className="flex flex-col sm:flex-row gap-1.5 text-12 md:text-14 absolute z-10 top-4 left-4">
            <Tag
              testId="inventoryTab"
              onClick={() => {
                setModalType('inventory');
              }}
              color="custom"
              className={tagClassname('inventory')}
              text={t('inventory')}
              icon={<BackpackIcon className="w-3 h-3 sm:w-4 sm:h-4" />}
            />
            <Tag
              testId="shopTab"
              onClick={() => {
                setModalType('shop');
              }}
              className={tagClassname('shop')}
              color="custom"
              text={t('shop')}
              icon={<ShoppingBagIcon className="w-3 h-3 sm:w-4 sm:h-4" />}
            />
          </div>
        </ConditionalRenderer>
        <ConditionalRenderer condition={!isInventory}>
          <div className="absolute right-12 top-4 z-50 text-14 md:text-16 gap-1 md:gap-2 flex flex-col sm:flex-row">
            <Tag
              color="custom"
              icon={<Coin className="w-3 h-3 md:w-4 md:h-4" />}
              text={`${rewardsData?.coins || 0}`}
              className="flex px-1.5 py-0.5 md:px-2.5 rounded-full text-base-100 font-500 flex-row-reverse gap-0.5 hover:ring transition ease-in-out duration-300 bg-yellow-400 ring-yellow-100"
            />
            <Tag
              color="custom"
              icon={<Gem className="w-3 h-3 md:w-4 md:h-4" />}
              text={`${rewardsData?.gems || 0}`}
              className="flex px-1.5 py-0.5 md:px-2.5 rounded-full text-base-100 font-500 flex-row-reverse gap-0.5 hover:ring transition ease-in-out duration-300 bg-success ring-success-content"
            />
          </div>
        </ConditionalRenderer>
        <XIcon
          data-testid="closeInventoryModal"
          onClick={() => {
            onClose();
            setPreviewAvatar(userAvatar);
            setShopAvatar(userAvatar);
          }}
          className="cursor-pointer absolute z-10 top-4 right-4 text-base-100 self-end w-6 h-6"
        />
        <div className="flex flex-col w-full justify-center items-center gap-2">
          <div
            className={`w-full h-fit flex rounded-t-2xl justify-center items-center ${
              isInventory ? 'bg-accent' : 'bg-warning'
            } relative`}
          >
            <div className="w-36 h-36 relative">
              <AvatarCharacter
                userAvatar={isInventory ? previewAvatar : shopAvatar}
              />
            </div>
          </div>
          <div className="px-2 w-full">
            <InventoryShopTabs
              selectedCategory={selectedCategory}
              selectedSubcategory={selectedSubcategory}
              setCategory={setSelectedCategory}
              setSubcategory={setSelectedSubcategory}
              isInventory={isInventory}
            />
            {isInventory ? (
              <ListItemsInventory
                isLoading={isLoadingUserInventory}
                subcategory={selectedSubcategory}
                previewAvatar={previewAvatar}
                setType={() => setModalType('shop')}
                onChange={handleOnChangeInventory}
                items={displayInventoryItems()}
              />
            ) : (
              <ListItemsShop
                inventory={userInventory}
                subcategory={selectedSubcategory}
                shopAvatar={shopAvatar}
                onChange={handleOnChangeShop}
                onChangeInventory={handleOnChangeInventory}
              />
            )}
          </div>
          <div
            className={`flex justify-end w-full ${
              isInventory ? 'h-12' : 'h-3'
            } gap-2 px-4 pb-5`}
          >
            <ConditionalRenderer condition={isInventory}>
              <MainButton
                type="button"
                color="neutral"
                text={t('cancel')}
                icon={<XIcon />}
                onClick={() => {
                  onClose();
                  setPreviewAvatar(userAvatar);
                  setShopAvatar(userAvatar);
                }}
              />
              <MainButton
                type="button"
                text={t('save')}
                dataTestId="inventorySaveButton"
                loading={isSaving}
                onClick={() => handleSaveButton()}
                icon={<SaveIcon />}
              />
            </ConditionalRenderer>
          </div>
        </div>
      </div>
    </div>
  );
}
