import { twMerge } from 'tailwind-merge';

export default function Card({
  className,
  children,
}: {
  children: React.ReactNode;
  className?: string;
}) {
  return (
    <div
      className={twMerge(
        'flex bg-base-100 md:justify-center md:items-center text-center w-full rounded-2xl md:h-44 md:w-44 shadow-default mb-3 md:mr-3',
        className,
      )}
    >
      {children}
    </div>
  );
}
