import { updateActivityProgress } from '@/data/services/activityProgressServices';
import Skeleton from '@/components/common/Skeleton';
import ConditionalRenderer from '@/components/common/ConditionalRenderer';
import ModalWarning from '@/components/common/modals/ModalWarning';
import { Dispatch, SetStateAction, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import ActivityProgress from '@/models/ActivityProgress';
import Text from '@/components/common/dataDisplay/Text';
import {
  DashedCircleIcon,
  SolidGradientCheckCircleIcon,
} from '@/components/icons';
import RoundedButton from '@/components/common/buttons/RoundedButton';
import { useTranslation } from 'react-i18next';
import Activity from '@/models/Activity';
import ActivityAttemptInfo from './ActivityAttemptInfo';
import { Rating } from '@/components/common/dataDisplay/Rating';
import {
  HomeworkActivity,
  HomeworkActivityProgress,
} from '@/models/HomeworkActivity';
import { t } from 'i18next';
import { ClockIcon } from '@heroicons/react/outline';
import React from 'react';
import useLessonContext from '@/data/hook/lesson';
import Tag from '@/components/common/dataDisplay/Tag';
import useActivities from '@/data/hook/useActivities';
import useActivityView from '@/data/hook/useActivityView';
import { REQUEST_STALE_TIME_IN_MS } from '@/constants';
import { useQuery } from '@tanstack/react-query';
import {
  activityProgressesQueryKeys,
  homeworkActivitiesQueryKeys,
  homeworkProgressQueryKeys,
} from '@/data/services/querykeys';

export interface ActivityViewItemProps {
  activityProgressId?: number;
  homeworkProgressId?: number;
  activityId?: number;
  homeworkId?: number;
}

export default function ActivityViewItem({
  activityProgressId,
  activityId,
  homeworkId,
  homeworkProgressId,
}: ActivityViewItemProps) {
  const {
    data: activityProgress,
    isInitialLoading: isLoadingActivityProgress,
    error: isErrorActivityProgress,
  } = useQuery({
    ...activityProgressesQueryKeys.get(activityProgressId ?? 0),
    enabled: !!activityProgressId,
  });

  const {
    data: homeworkActivityProgress,
    isInitialLoading: isLoadingHomeworkActivityProgress,
    isError: isErrorHomeworkActivityProgress,
  } = useQuery({
    enabled: !!homeworkId && !!homeworkProgressId,
    ...homeworkProgressQueryKeys.get({ homeworkId, homeworkProgressId }),
  });

  const { activities } = useActivities({
    activityIds: activityId ? [activityId] : undefined,
  });

  const activity = activities.length ? activities[0] : undefined;

  const {
    data: homeworkActivitiesResponse,
    isInitialLoading: isLoadingHomeworkActivity,
    isError: isErrorHomeworkActivity,
  } = useQuery({
    ...homeworkActivitiesQueryKeys.list({
      activity: homeworkId,
    }),
    enabled: !!homeworkId && !homeworkProgressId,
    staleTime: REQUEST_STALE_TIME_IN_MS,
  });
  const homeworkActivities = homeworkActivitiesResponse?.results ?? [];

  const homeworkActivity = homeworkActivities?.at(0);

  const isLoadingItem =
    isLoadingActivityProgress ||
    isLoadingHomeworkActivityProgress ||
    activity?.fetchStatus === 'fetching' ||
    isLoadingHomeworkActivity;

  const isErrorItem =
    isErrorActivityProgress ||
    isErrorHomeworkActivityProgress ||
    activity?.status === 'error' ||
    isErrorHomeworkActivity;

  if (isLoadingItem) {
    return <Skeleton className="w-full h-5 rounded-md bg-accent/40" />;
  }

  if (isErrorItem) {
    return (
      <Text
        testId="errorText"
        text={t('errors.serverError').toString()}
        className="flex text-primary"
        size="text-18"
        format="rubik-500"
      />
    );
  }

  if (activityProgress)
    return <ActivityItem activityProgress={activityProgress} />;

  if (homeworkActivityProgress)
    return <ActivityItem homeworkActivityProgress={homeworkActivityProgress} />;

  if (activity?.data) return <ActivityItem activity={activity.data} />;

  if (homeworkActivity)
    return <ActivityItem homeworkActivity={homeworkActivity} />;

  return null;
}

interface ActivityItemProps extends Partial<ActivityViewItemProps> {
  activityProgress?: ActivityProgress;
  activity?: Activity;
  homeworkActivityProgress?: HomeworkActivityProgress;
  homeworkActivity?: HomeworkActivity;
}

function ActivityItem(props: ActivityItemProps) {
  const {
    activityProgress,
    activity,
    homeworkActivityProgress,
    homeworkActivity,
  } = props;

  const [isOpenModal, setIsOpenModal] = useState<boolean>(false);

  const navigate = useNavigate();

  const { updateLessonProgress } = useLessonContext();

  const { lessonId, slugCourseName, klassId } = useParams();

  const { t } = useTranslation('translation', {
    keyPrefix: 'lesson.activity',
  });

  const klassUrl = klassId ? `class/${klassId}/` : '';

  const baseUrl = `/courses/${slugCourseName}/lesson/${lessonId}/${klassUrl}activities/`;

  const activityName =
    activityProgress?.activity?.name || activity?.name || t('homework');

  const goToHomework = () => navigate(baseUrl + 'homework');

  const { resetState } = useActivityView();

  const goToActivityProgress = () => {
    resetState(activityProgress?.answersRevealed ? 'answerkey' : 'activity');
    navigate(baseUrl + activityProgress?.id.toString());
  };

  const goToActivity = () => navigate(baseUrl + activity?.id.toString());

  const [loading, setLoading] = useState(false);

  const handleUnlockAnswersKey = async () => {
    if (activityProgress) {
      try {
        setLoading(true);
        const unlockedActivityProgress = await updateActivityProgress(
          activityProgress.id,
          {
            answersRevealed: true,
          },
        );

        await updateLessonProgress();

        navigate(baseUrl + unlockedActivityProgress.id.toString());
        setIsOpenModal(false);
      } catch (error: any) {
      } finally {
        setLoading(false);
      }
    }
  };

  const activityRoute = () => {
    if (activityProgress) return goToActivityProgress();

    if (activity) return goToActivity();

    if (homeworkActivity || homeworkActivityProgress) return goToHomework();

    return '';
  };

  const activityButtonCondition =
    (activityProgress && !activityProgress.answersRevealed) ||
    !!homeworkActivityProgress ||
    !!activity ||
    !!homeworkActivity;

  const homeworkGradeCondition =
    homeworkActivityProgress &&
    homeworkActivityProgress.activityProgress.hasDone &&
    (!!homeworkActivityProgress.activityProgress.grade ||
      homeworkActivityProgress.activityProgress.grade === 0);

  const hasActivityCorrection =
    !!activityProgress?.grade || activityProgress?.grade === 0;

  const hasHomeworkCorrection =
    !!homeworkActivityProgress?.activityProgress.grade ||
    homeworkActivityProgress?.activityProgress.grade === 0;

  const hasCorrection = hasActivityCorrection || hasHomeworkCorrection;

  const hasDone =
    activityProgress?.hasDone || !!homeworkActivityProgress?.answer;

  return (
    <div className="list-none flex flex-col xs:flex-row lg:flex-col xl:flex-row gap-2.5 justify-between">
      <ConditionalRenderer condition={activityProgress}>
        <ModalWarning
          visible={isOpenModal}
          translationString="modalAnswerKey"
          onClickConfirm={handleUnlockAnswersKey}
          onClickCancel={() => setIsOpenModal(false)}
          isLoading={loading}
        />
      </ConditionalRenderer>

      <span className="flex w-full md:w-fit items-center gap-x-4 relative">
        <ConditionalRenderer
          condition={!!activityProgress || !!homeworkActivityProgress}
        >
          <FeedbackStudentIcon
            hasDone={hasDone}
            hasCorrection={hasCorrection}
          />
        </ConditionalRenderer>
        <Text
          testId="activityNameText"
          text={activityName}
          className="flex text-primary"
          size="text-18"
          format="rubik-500"
        />
        <ConditionalRenderer
          condition={!!homeworkActivity || !!homeworkActivityProgress}
        >
          <Tag
            color="secondary"
            className="border border-secondary text-14 px-2 py-0.5"
            text={t('givenByTeacher')}
          />
        </ConditionalRenderer>
      </span>
      <div className="flex w-fit gap-2.5 items-center self-end flex-col sm:flex-row">
        <ConditionalRenderer
          condition={activityProgress && activityProgress.hasDone}
        >
          <ActivityGrade
            setIsOpenModal={setIsOpenModal}
            baseUrl={baseUrl}
            activityProgress={activityProgress}
          />
        </ConditionalRenderer>
        <ConditionalRenderer condition={homeworkGradeCondition}>
          <Rating
            testId="homeworkRating"
            length={5}
            value={homeworkActivityProgress?.activityProgress.grade || 0}
            isDisabled
          />
        </ConditionalRenderer>
        <ConditionalRenderer condition={activityButtonCondition}>
          <RoundedButton
            testId="startActivityButton"
            text={t('start')}
            className="w-40 self-end"
            onClick={activityRoute}
          />
        </ConditionalRenderer>
      </div>
    </div>
  );
}

function FeedbackStudentIcon({
  hasDone,
  hasCorrection,
}: {
  hasDone?: boolean;
  hasCorrection?: boolean;
}) {
  if (hasDone && hasCorrection) {
    return <SolidGradientCheckCircleIcon className="w-6 h-6 shrink-0" />;
  }

  if (hasDone && !hasCorrection) {
    return (
      <ClockIcon
        data-testid="notCorrection"
        className="w-6 h-6 shrink-0 text-secondary"
      />
    );
  }

  if (!hasDone && !hasCorrection) {
    return <DashedCircleIcon className="w-6 h-6 shrink-0 text-secondary" />;
  }

  return <React.Fragment />;
}

function ActivityGrade({
  setIsOpenModal,
  baseUrl,
  activityProgress,
}: {
  setIsOpenModal: Dispatch<SetStateAction<boolean>>;
  baseUrl: string;
  activityProgress?: ActivityProgress;
}) {
  const { t } = useTranslation('translation', {
    keyPrefix: 'lesson.activity',
  });

  const navigate = useNavigate();

  const { setStatus, resetState } = useActivityView();

  function handleAnswerKeyClick(activityProgress?: ActivityProgress) {
    if (activityProgress && activityProgress.answersRevealed) {
      resetState('answerkey');
      setStatus('inProgress');

      navigate(baseUrl + activityProgress.id);
    } else {
      setIsOpenModal(true);
    }
  }

  function score(score?: number) {
    if (score) return Math.round(score * 100);
    return 0;
  }

  return (
    <div className="flex w-full xl:w-fit justify-end gap-2.5">
      <ActivityAttemptInfo
        testId="feedback"
        text={t('feedback').toUpperCase()}
        onClick={() => handleAnswerKeyClick(activityProgress)}
        className="hover:opacity-80 hover:ring-2 transition ease-out duration-150 ring-secondary-content"
      />

      <ActivityAttemptInfo
        testId="activityGrade"
        text={`${t('score').toUpperCase()}: ${score(activityProgress?.grade)}%`}
      />
    </div>
  );
}
