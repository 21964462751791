import React from 'react';
import NoAdminScreenForMobile from '@/pages/admin/NoAdminScreenForMobile';
import CourseTitle from '@/components/common/CourseTitle';
import Skeleton from '@/components/common/Skeleton';
import Layout from '@/components/template/Layout';

interface ManageActivityEditContainerProps {
  isLoading: boolean;
  courseName?: string;
  children: React.ReactNode;
}

function ManageActivityEditContainer(props: ManageActivityEditContainerProps) {
  const { children, courseName, isLoading } = props;
  if (isLoading) {
    return (
      <div className="hidden md:block w-full">
        <Layout>
          <div className="flex flex-col gap-5">
            <Skeleton className="w-full bg-primary-content rounded-lg h-14" />
            <Skeleton className="w-full bg-primary-content rounded-lg h-72" />
          </div>
        </Layout>
      </div>
    );
  }

  return (
    <React.Fragment>
      <div className="md:hidden">
        <NoAdminScreenForMobile />
      </div>
      <div className="hidden md:block w-full">
        <Layout>
          <div className="h-auto flex flex-col gap-5">
            {courseName ? (
              <CourseTitle title={courseName} />
            ) : (
              <Skeleton className="w-full h-16" />
            )}
            {children}
          </div>
        </Layout>
      </div>
    </React.Fragment>
  );
}

export default ManageActivityEditContainer;
