import useConfigContext from '@/data/hook/config';
import { VolumeOffIcon, VolumeUpIcon } from '@heroicons/react/outline';
import React from 'react';

const SoundControl: React.FC = () => {
  const { userSettings, changeUserSettings } = useConfigContext();

  const sound = userSettings?.alertSound ?? true;

  return (
    <div className="flex gap-2 items-center">
      <VolumeOffIcon className="w-5 h-5  text-base-content" />
      <input
        type="checkbox"
        className="toggle toggle-primary"
        defaultChecked={sound}
        onChange={e =>
          changeUserSettings({
            alertSound: e.target.checked,
          })
        }
      />
      <VolumeUpIcon className="w-5 h-5 text-base-content" />
    </div>
  );
};

export default SoundControl;
