import { v4 } from 'uuid';

export class DNDItem {
  id?: number;
  dndPositionalColumn?: number;
  image?: File | string;
  altText?: string;
  text?: string;
  order?: number;
  identifier?: string;
  editType?: 'new' | 'edit' | 'remove';
  errors?: string[];

  constructor(
    image: File | string | undefined = undefined,
    text: string | undefined = undefined,
    alt_text: string | undefined = undefined,
  ) {
    this.identifier = v4();
    this.image = image;
    this.text = text;
    this.altText = alt_text;
    this.editType = 'new';
  }
}
