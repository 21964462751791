import { Fragment, ReactNode } from 'react';
import { Config, usePopperTooltip } from 'react-popper-tooltip';
import 'react-popper-tooltip/dist/styles.css';
import { createPortal } from 'react-dom';
import { twMerge } from 'tailwind-merge';

import Text from './Text';
import ConditionalRenderer from '../ConditionalRenderer';
import { Placement } from '@/models/Placement';

type TooltipProps = {
  text: string | ReactNode;
  color?: Color;
  className?: string;
  classNameContainer?: string;
  children?: ReactNode;
  placement?: Placement;
  testId?: string;
  options?: Config;
  enable?: boolean;
};

export type Color =
  | 'red'
  | 'blue'
  | 'purple'
  | 'accent'
  | 'white'
  | 'yellow'
  | 'green'
  | 'gray'
  | 'lightBlue'
  | 'lightPurple';

const ArrowColorClasses: Record<Color, string> = {
  red: 'text-error',
  blue: 'text-primary',
  accent: 'text-accent',
  purple: 'text-secondary',
  white: 'text-base-100',
  yellow: 'text-warning',
  green: 'text-success',
  gray: 'text-neutral',
  lightBlue: 'text-primary-content',
  lightPurple: 'text-secondary-content',
};

const ContentColorClasses: Record<Color, string> = {
  red: 'bg-error text-base-100',
  blue: 'bg-primary text-base-100',
  accent: 'bg-accent text-base-100',
  purple: 'bg-secondary text-base-100',
  white: 'bg-base-100',
  yellow: 'bg-warning text-base-100',
  green: 'bg-success text-base-100',
  gray: 'bg-neutral text-base-100',
  lightBlue: 'bg-primary-content text-primary',
  lightPurple: 'bg-secondary-content text-secondary',
};

export const Tooltip = ({
  text: content,
  color,
  className,
  classNameContainer,
  children,
  placement = 'auto',
  testId,
  enable = true,
  options,
}: TooltipProps) => {
  const {
    getArrowProps,
    getTooltipProps,
    setTooltipRef,
    setTriggerRef,
    visible,
  } = usePopperTooltip({
    placement,
    ...options,
  });

  const render = visible && content;

  if (!enable) return <Fragment>{children}</Fragment>;

  return (
    <Fragment>
      <div
        data-testid={testId ?? 'tooltip'}
        ref={setTriggerRef}
        className={twMerge('items-center w-fit', classNameContainer)}
      >
        {children}
      </div>

      <ConditionalRenderer condition={render}>
        {createPortal(
          <div
            className="flex shrink-0"
            ref={setTooltipRef}
            {...getTooltipProps({
              className: twMerge(
                `tooltip-container text-14 text-center border-0 font-rubik shadow-default z-50 ${
                  ContentColorClasses[color || 'gray']
                }`,
                className,
              ),
            })}
          >
            {typeof content === 'string' ? <Text text={content} /> : content}
            <div
              {...getArrowProps({
                className: `tooltip-arrow ${
                  ArrowColorClasses[color || 'gray']
                }`,
              })}
            />
          </div>,
          document.body,
        )}
      </ConditionalRenderer>
    </Fragment>
  );
};
