import { deleteCodeActivity } from '@/data/services/activityElement/codeServices';
import { deleteDndOrdenationActivity } from '../data/services/activityElement/dndOrdenationActivityServices';
import { deleteDndPositionalActivity } from '../data/services/activityElement/dndPositionalActivityServices';
import { deleteQuizActivity } from '../data/services/activityElement/quizServices';
import { deleteTextActivity } from '../data/services/activityElement/textActivityServices';
import { deleteTrueOrFalseActivity } from '../data/services/activityElement/trueOrFalseActivityServices';
import { deleteVideoActivity } from '../data/services/activityElement/videoActivityService';
import { ActivityTypeEnum } from '../models/Activity';
import ActivityElement from '../models/ActivityElement';
import { deleteCodeEditorActivity } from '@/data/services/activityElement/codeEditorActivityServices';

const deleteActivityByType = async ({ id, type }: ActivityElement) => {
  const routes: {
    [key in ActivityTypeEnum]: (questionId: number) => Promise<void>;
  } = {
    [ActivityTypeEnum.TEXT]: deleteTextActivity,
    [ActivityTypeEnum.VIDEO]: deleteVideoActivity,
    [ActivityTypeEnum.QUIZ]: deleteQuizActivity,
    [ActivityTypeEnum.TRUE_OR_FALSE]: deleteTrueOrFalseActivity,
    [ActivityTypeEnum.DND_ORDENATION]: deleteDndOrdenationActivity,
    [ActivityTypeEnum.DND_POSITIONAL]: deleteDndPositionalActivity,
    [ActivityTypeEnum.CODE]: deleteCodeActivity,
    [ActivityTypeEnum.CODE_EDITOR]: deleteCodeEditorActivity,
  };
  const deleteActivityFunction = routes[type as keyof typeof routes];
  await deleteActivityFunction(id);
};

export default deleteActivityByType;
