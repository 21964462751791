import React, { useState } from 'react';
import moment from 'moment';
import { useQuery } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';
import ScheduleTrialLesson from '@/components/acquisition/ScheduleTrialLesson';
import FrequentQuestions from '@/components/parents/FrequentQuestions';
import TrialCourseCard from '@/components/acquisition/TrialCourseCard';
import Skeleton from '@/components/common/Skeleton';
import Text from '@/components/common/dataDisplay/Text';
import getCourseStudentAge from '@/utils/getCourseStudentAge';
import { optionsParentDashboard } from '@/utils/optionsFrequentQuestions';
import { AdditionalInformation, StudentAgeEnum } from '@/models/User';
import useAuth from '@/data/hook/useAuth';
import JsonApiResponse from '@/models/JsonApiResponse';
import requester from '@/axios';
import TrialInfoCard from '@/components/acquisition/TrialInfoCard';
import TrialEnrollment from '@/models/TrialEnrollment';
import ConditionalRenderer from '@/components/common/ConditionalRenderer';

export default function ParentDashboardView() {
  const [alterDate, setAlterDate] = useState(false);
  const dateNow = moment(Date.now()).add(1, 'day').format('YYYY-MM-DD');
  const { user } = useAuth();
  const { t } = useTranslation('translation', {
    keyPrefix: 'dashboard',
  });

  const {
    data: trialEnrollmentStudent,
    isLoading: isLoadingTrialEnrollmentStudent,
  } = useQuery<TrialEnrollment | null>(
    ['trialEnrollmentStudent'],
    () => getTrialEnrollmentStudent(),
    { enabled: false },
  );

  const {
    data: responseAdditionalInformation,
    isLoading: isLoadingAdditionalInformation,
  } = useQuery(
    ['userProfileParent'],
    () =>
      requester().get<AdditionalInformation>(
        '/users/parent/additional-information/',
      ),
    {
      refetchOnWindowFocus: false,
      enabled: false,
    },
  );

  const { data: responseGetDates, isInitialLoading: isLoadingGetDates } =
    useQuery(
      ['trialLessonDates'],
      () =>
        requester()
          .get<JsonApiResponse<string>>(
            `trial-lesson/scheduled-day/dates/?date_gte=${dateNow}&course_type=${getCourseStudentAge(
              responseAdditionalInformation?.data.studentAge ||
                StudentAgeEnum.TEN_TO_THIRTEEN,
            )}&unit_id=${user?.unitsIds?.[0]}`,
          )
          .then(res => res.data),
      {
        enabled: false,
        refetchOnWindowFocus: false,
      },
    );

  async function getTrialEnrollmentStudent() {
    const studentId = await requester()
      .get(`userprofiles/${user?.id}/relationships/`)
      .then(({ data }) => {
        if (data.length > 0) {
          return data[0].student;
        }

        return null;
      });

    if (studentId) {
      const { data } = await requester().get<TrialEnrollment>(
        `trial-lesson/enrollment/student/${studentId}/`,
      );

      return data;
    }

    return null;
  }

  const DateInformation = () => {
    const scheduledDay = trialEnrollmentStudent?.scheduledDay;
    if (scheduledDay && typeof scheduledDay !== 'number') {
      const dateTimeMoment = moment(new Date(scheduledDay.dateTime));
      const dateTimeFormat = dateTimeMoment.format('dddd, D [de] MMMM,');
      const hourStart = dateTimeMoment.format(`H[h] [às]`);
      const hoursEnd = dateTimeMoment.add(1, 'hour').format('H[h]');

      return (
        <React.Fragment>
          <Text text={dateTimeFormat} />
          <Text text={`${hourStart} ${hoursEnd}`} />
        </React.Fragment>
      );
    }

    return null;
  };

  const RenderComponent = () => {
    if (trialEnrollmentStudent?.scheduledDay && !alterDate) {
      return (
        <TrialInfoCard
          studentAge={
            responseAdditionalInformation?.data.studentAge ||
            StudentAgeEnum.FOURTEEN_TO_SEVENTEEN
          }
          className="max-w-[320px] text-16"
          header={
            <Text
              text="Pedido"
              format="rubik-500"
              className="text-center flex justify-center text-accent px-6 py-2.5 rounded-full bg-base-100 w-full"
            />
          }
        >
          <div className="w-full flex flex-col gap-5">
            <Text
              text="Aula Experimental (60min)"
              format="rubik-500"
              className="text-center flex justify-center text-accent w-full"
            />
            <Text
              text="A aula acontecerá dentro dessa plataforma. O aluno precisará de um computador ou notebook com microfone e câmera para fazer a aula."
              className="flex"
            />
            <div className="w-full flex flex-col">
              <DateInformation />
              <button
                onClick={() => setAlterDate(true)}
                className="rounded-full flex self-end px-2.5 py-1 text-14 bg-accent/40 text-accent leading-none"
              >
                alterar
              </button>
            </div>
          </div>
        </TrialInfoCard>
      );
    }

    return (
      <ScheduleTrialLesson
        studentAge={
          responseAdditionalInformation?.data.studentAge ||
          StudentAgeEnum.TEN_TO_THIRTEEN
        }
        trialDates={responseGetDates?.results}
        isLoadingGetDates={isLoadingGetDates}
        alterDate={alterDate}
        setAlterDate={setAlterDate}
        trialEnrollment={trialEnrollmentStudent}
      />
    );
  };

  return (
    <React.Fragment>
      <div className="flex flex-col items-start flex-wrap gap-2 md:gap-4">
        <Text
          text={t('hello') + user?.firstName + ' 👋'}
          className="text-primary w-fit"
          format="poppins-600"
          size="text-28"
        />
        <Text text={t('wellcome.parent')} />
      </div>

      <div className="flex flex-col relative justify-center items-center py-8 gap-y-24">
        <ConditionalRenderer condition={false}>
          {isLoadingAdditionalInformation || isLoadingTrialEnrollmentStudent ? (
            <div className="w-full gap-14 flex justify-center">
              <Skeleton className="bg-primary-content max-w-xs w-full h-96 rounded-2xl" />
              <Skeleton className="bg-primary-content w-full h-96 rounded-2xl max-w-[447px]" />
            </div>
          ) : (
            <RenderComponent />
          )}
        </ConditionalRenderer>
        <div className="flex flex-col md:flex-row justify-around items-center w-full gap-12">
          <TrialCourseCard
            studentAge={
              responseAdditionalInformation?.data.studentAge ||
              StudentAgeEnum.TEN_TO_THIRTEEN
            }
          />
          <FrequentQuestions
            options={optionsParentDashboard}
            title="Perguntas Frequentes"
            className="items-center"
          />
        </div>
      </div>
    </React.Fragment>
  );
}
