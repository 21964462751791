import { AxiosResponse } from 'axios';
import requester from '@/axios';
import {
  QuestionProgress,
  QuizActivityProgress,
} from '@/models/ActivityProgress';

export interface GetQuizProgressParams {
  quizId: number;
  quizProgressId: number;
}

export const getQuizProgress = async ({
  quizId,
  quizProgressId,
}: GetQuizProgressParams) => {
  const { data: quizProgress } = await requester().get<QuizActivityProgress>(
    `quiz-activity/${quizId}/quiz-activity-progress/${quizProgressId}/`,
  );
  return quizProgress;
};

type UpdateQuizProgress = Pick<QuestionProgress, 'isDone'> & {
  answer?: string;
};

export const updateQuizProgress = async (
  { quizId, quizProgressId }: GetQuizProgressParams,
  body: UpdateQuizProgress,
) => {
  type Response = AxiosResponse<QuizActivityProgress>;
  const { data: quizProgress } = await requester().patch<
    UpdateQuizProgress,
    Response
  >(`quiz-activity/${quizId}/quiz-activity-progress/${quizProgressId}/`, body);
  return quizProgress;
};
