import { motion } from 'framer-motion';
import { buildMotionLIProps } from '@/utils/animations/lessonCardAnimation';
import { Link } from 'react-router-dom';
import Text from '@/components/common/dataDisplay/Text';
import Skeleton from '@/components/common/Skeleton';
import ConditionalWrapper from '@/components/common/ConditionalWrapper';

type LessonCardItemProps = {
  icon: JSX.Element;
  content?: string;
  link?: string;
  index?: number;
  onClick?(): void;
  selected?: boolean;
  loading?: boolean;
  testId?: string;
};

export default function LessonCardItem({
  index,
  content,
  link,
  icon,
  onClick,
  selected,
  loading,
  testId,
}: LessonCardItemProps) {
  const className = selected
    ? 'bg-primary-content/50 font-600 disabled select-none'
    : '';

  const wrapperClassName =
    'text-14 font-500 text-primary font-rubik w-full flex items-center gap-3 px-3 py-2 cursor-pointer';

  if (loading)
    return (
      <Skeleton className="flex h-5 w-full rounded-md bg-primary-content" />
    );

  return (
    <motion.li
      {...buildMotionLIProps(index ?? 0)}
      whileHover={{ scale: 1.05 }}
      className={`hover:bg-primary-content/50 rounded-md ${className}`}
      data-testid={testId ?? 'lessonItem'}
      onClick={onClick}
    >
      <ConditionalWrapper
        condition={!!link}
        wrapper={<Link to={link ?? ''} className={wrapperClassName} />}
        fallback={<span className={wrapperClassName} />}
      >
        {icon}

        <Text text={content} className="truncate" />
      </ConditionalWrapper>
    </motion.li>
  );
}
