import { useEffect, useRef } from 'react';
import { HtmlPreview } from './HtmlPreview';

interface EmbedPreviewProps {
  src: string;
  className?: string;
}

export default function EmbedPreview({ src, className }: EmbedPreviewProps) {
  const iframeRef = useRef<HTMLIFrameElement>(null);

  function verifyLink(src: string): string {
    const constructRegex = /[-/](\d+)\/play/;
    const match = src.match(constructRegex);

    if (src.includes('construct') && match && match[1]) {
      const gameId = match[1];
      return `https://games.construct.net/${gameId}/latest`;
    } else if (src.includes('scratch') && !src.includes('/embed')) {
      if (src.endsWith('/')) {
        return src + 'embed';
      }
      return src + '/embed';
    }
    return src;
  }

  useEffect(() => {
    if (iframeRef.current) {
      const iframe = iframeRef.current;

      iframe.src = verifyLink(src);

      const preventAudioContextError = () => {
        const AudioContext =
          window.AudioContext || (window as any).webkitAudioContext;
        if (!AudioContext) return;

        const originalCreate = AudioContext.prototype.createOscillator;
        AudioContext.prototype.createOscillator = function () {
          return originalCreate.apply(this);
        };
      };

      iframe.addEventListener('load', () => {
        preventAudioContextError();
      });

      return () => {
        iframe.removeEventListener('load', () => {
          preventAudioContextError();
        });
      };
    }
  }, [src]);
  if (src.includes('<iframe')) {
    return <HtmlPreview html={src} />;
  }
  return (
    <div className="h-auto aspect-video">
      <iframe
        ref={iframeRef}
        title="Embed Preview"
        width="100%"
        allow="autoplay; fullscreen"
        className={className}
      />
    </div>
  );
}
