import React from 'react';
import ComponentGuard from '../../common/ComponentGuard';
import Text from '../../common/dataDisplay/Text';
import RoundedButton from '../../common/buttons/RoundedButton';
import { useTranslation } from 'react-i18next';
import Activity from '../../../models/Activity';
import { UserTypeEnum } from '../../../models/User';

interface ActivityItemDisplayProps {
  activity?: Activity;
  onClick(): void;
  icon?: JSX.Element;
  children?: React.ReactNode;
  shouldShowButton?: boolean;
  isViewMode?: boolean;
}

function ActivityItemDisplay({
  activity,
  onClick,
  icon,
  children,
  shouldShowButton = true,
  isViewMode,
}: ActivityItemDisplayProps) {
  const { t } = useTranslation('translation', {
    keyPrefix: 'lesson.activity',
  });

  return (
    <div className="flex flex-col md:flex-row items-center gap-4 justify-between">
      <span className="flex w-full md:w-fit items-center gap-x-4">
        {icon && (
          <ComponentGuard roles={[UserTypeEnum.STUDENT]}>{icon}</ComponentGuard>
        )}
        <Text
          text={activity?.name || t('homework')}
          className="flex text-primary"
          size="text-18"
          format="rubik-500"
        />
      </span>
      <div className="flex flex-col xs:flex-row self-end gap-4">
        {children && children}
        {shouldShowButton && (
          <RoundedButton
            testId={`startActivity${activity?.id.toString()}`}
            id={`start-activity-${activity?.id.toString()}`}
            text={t(isViewMode ? 'view' : 'start')}
            className="w-40 self-end"
            onClick={onClick}
          />
        )}
      </div>
    </div>
  );
}

export default ActivityItemDisplay;
