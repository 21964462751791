import Tabs, {
  Pane,
  serializerTabsContents,
} from '@/components/common/dataDisplay/Tabs/Tabs';
import useCourseEditing from '@/data/hook/useCourseEditing';
import CoursePath from '@/models/Course';
import { Lesson, LessonTab } from '@/models/Lesson';
import {
  BookOpenIcon,
  ClipboardListIcon,
  InformationCircleIcon,
  LightningBoltIcon,
} from '@heroicons/react/outline';
import { useTranslation } from 'react-i18next';
import { ChallengeEditing } from './ChallengeEditing';
import CollapsibleLesson from './CollapsibleLesson';
import LessonDetails from './LessonDetails';
import LessonReplacerItem from './LessonReplacerItem';
import ListActivities from './ListActivities';
import MaterialContainer from './material/MaterialContainer';

interface DraggableLessonProps {
  lesson: Lesson;
  index: number;
  course: CoursePath;
  deleteLesson: (lesson: Lesson) => void;
  updateLessons: () => Promise<void>;
}

function DraggableLesson({
  lesson,
  index,
  course,
  ...props
}: DraggableLessonProps) {
  const { deleteLesson, updateLessons } = props;
  const { allowBigChanges, activeLessonTab, handleOpenTab, handleLessonTab } =
    useCourseEditing();
  const { activeLessonIndex, activeTab } = activeLessonTab || {
    activeLessonIndex: null,
    activeTab: 'details',
  };
  const { t: editRulesT } = useTranslation('translation', {
    keyPrefix: 'adminPage.editCourseRules',
  });

  const isActive = activeLessonIndex === index;

  const { t } = useTranslation('translation', {
    keyPrefix: 'adminPage.lesson.lessonTabs',
  });

  const onChangeTab = (tab: string) =>
    handleLessonTab({
      activeTab: tab as LessonTab,
    });

  const panes: Pane[] = [
    {
      key: 'details',
      text: t('details'),
      icon: InformationCircleIcon,
      content: (
        <LessonDetails
          {...{
            lesson,
            course,
            deleteLesson,
            updateLessons,
          }}
        />
      ),
    },
    {
      key: 's-material',
      text: t('material'),
      icon: BookOpenIcon,
      content: (
        <MaterialContainer
          updateLessons={updateLessons}
          bookType="STUDENT"
          {...{ course, lesson }}
        />
      ),
    },
    {
      key: 't-material',
      text: t('teacherMaterial'),
      icon: BookOpenIcon,
      content: (
        <MaterialContainer
          updateLessons={updateLessons}
          bookType="TEACHER"
          {...{ course, lesson }}
        />
      ),
    },
    {
      key: 'activities',
      text: t('activity'),
      icon: ClipboardListIcon,
      content: (
        <ListActivities
          updateLessons={updateLessons}
          lesson={lesson}
          coursePath={course}
        />
      ),
    },
    {
      key: 'challenge',
      text: t('challenge'),
      icon: LightningBoltIcon,
      content: <ChallengeEditing onUpdate={updateLessons} lesson={lesson} />,
    },
  ];

  return (
    <LessonReplacerItem
      key={lesson.id}
      dragableHandler={{
        disabled: !allowBigChanges,
        message: editRulesT('cantModify'),
      }}
      draggableId={lesson.id.toString()}
      index={index}
    >
      <div className="w-full" onClick={() => handleOpenTab(isActive, index)}>
        <CollapsibleLesson
          id={`lessonBar${index}`}
          open={isActive}
          lesson={lesson}
        >
          <Tabs
            active={activeTab}
            contents={serializerTabsContents(panes)}
            onChange={onChangeTab}
          >
            <Tabs.Container>
              <Tabs.List>
                {panes.map(({ key, text, icon: Icon }) => (
                  <Tabs.Trigger key={key} id={key}>
                    <Icon className="w-6" />
                    {text}
                  </Tabs.Trigger>
                ))}
              </Tabs.List>
              <Tabs.ShowContent />
            </Tabs.Container>
          </Tabs>
        </CollapsibleLesson>
      </div>
    </LessonReplacerItem>
  );
}

export default DraggableLesson;
