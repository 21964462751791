import { EyeIcon, EyeOffIcon } from '@heroicons/react/outline';
import { useState } from 'react';
import TextInput, { TextInputProps } from './TextInput';

export default function PasswordInput(props: TextInputProps) {
  const [isShown, setIsShown] = useState(false);
  return (
    <TextInput
      {...props}
      onClickIcon={() => setIsShown(prev => !prev)}
      type={isShown ? 'text' : 'password'}
      icon={isShown ? <EyeOffIcon /> : <EyeIcon />}
      color={props.errorLabelText ? 'warning' : 'primary'}
    />
  );
}
