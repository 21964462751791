import { EnrollmentStatusEnum } from '@/models/Enrollment';
import {
  ActiveEnum,
  EnrollmentAndReportStatus,
  TranferredEnum,
} from '@/models/ScheduledLessonReport';
import { ScheduledLessonReportEnum } from '@/models/ScheduledLessonReport';

export type StudentReportStatusProps = {
  enrollmentStatus: EnrollmentStatusEnum;
  wasUpdatedAfterLesson: boolean;
  reportStatus?: ScheduledLessonReportEnum;
};

// TODO: deve retornar status e contexto, ou só o contexto
export const studentReportStatus = ({
  enrollmentStatus,
  reportStatus,
  wasUpdatedAfterLesson,
}: StudentReportStatusProps): EnrollmentAndReportStatus => {
  const activated =
    enrollmentStatus === EnrollmentStatusEnum.ACTIVE &&
    (reportStatus === ScheduledLessonReportEnum.ACTIVE || !reportStatus);

  if (activated) return EnrollmentStatusEnum.ACTIVE;

  if (enrollmentStatus === EnrollmentStatusEnum.CONCLUDED)
    return enrollmentStatus;

  const activeLessonStatus = {
    [EnrollmentStatusEnum.CANCELED]: ActiveEnum.CANCELED_IN_FUTURE,
    [EnrollmentStatusEnum.COURSE_TRANSFERRED]: ActiveEnum.TRANSFERED_IN_FUTURE,
    [EnrollmentStatusEnum.CLASS_TRANSFERRED]: ActiveEnum.TRANSFERED_IN_FUTURE,
  };

  if (enrollmentStatus in activeLessonStatus && wasUpdatedAfterLesson) {
    return activeLessonStatus[
      enrollmentStatus as keyof typeof activeLessonStatus
    ];
  }

  const courseTransferred =
    enrollmentStatus === EnrollmentStatusEnum.COURSE_TRANSFERRED;

  if (courseTransferred) return EnrollmentStatusEnum.COURSE_TRANSFERRED;
  const classTransferred =
    enrollmentStatus === EnrollmentStatusEnum.CLASS_TRANSFERRED;

  if (classTransferred) return TranferredEnum.TRANSFERRED_FROM_CLASS;
  const transferedToClass =
    enrollmentStatus === EnrollmentStatusEnum.ACTIVE &&
    reportStatus === ScheduledLessonReportEnum.NOT_ENROLLED;
  if (transferedToClass) return TranferredEnum.TRANSFERRED_TO_CLASS;

  const canceledThisLesson =
    enrollmentStatus === EnrollmentStatusEnum.CANCELED &&
    (reportStatus === ScheduledLessonReportEnum.CANCELED || !reportStatus);

  if (canceledThisLesson) return EnrollmentStatusEnum.CANCELED;

  const awaitingPayment =
    enrollmentStatus === EnrollmentStatusEnum.AWAITING_PAYMENT && !reportStatus;
  if (awaitingPayment) return EnrollmentStatusEnum.AWAITING_PAYMENT;

  return enrollmentStatus;
};
