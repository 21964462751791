import React from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import useAuth from '@/data/hook/useAuth';
import ChapterRender from '../../common/ChapterRender';
import ErrorComponent from '../../common/ErrorComponent';
import AdminMaterialControl from './AdminMaterialControl';
import ChapterContainer from './ChapterContainer';
import StudentMaterialControl from './StudentMaterialControl';
import { useQuery } from '@tanstack/react-query';
import useStudentContext from '@/data/hook/student';
import HeadTitle from '../../common/HeadTitle';
import { makeLessonHeadTitle } from '@/utils/lessonHeadTitle';
import useLessonContext from '@/data/hook/lesson';
import Chapter from '@/models/Chapter';
import { isStudent } from '@/functions/auth';
import getKlassViewBaseUrl from '@/utils/getKlassViewBaseUrl';
import { ApiError } from '@/models/Errors';
import {
  booksQueryKeys,
  coursePathsQueryKeys,
} from '@/data/services/querykeys';

export default function StudentMaterialView() {
  const { t: tError } = useTranslation('translation', {
    keyPrefix: 'errors.errorPage',
  });
  const { t } = useTranslation('translation', {
    keyPrefix: 'klassView',
  });

  const { lesson, scheduledLesson } = useLessonContext();
  const { book: bookId, order } = lesson || {};
  const { currentProgress } = useStudentContext();
  const { chapterOrder, slugCourseName = '', lessonId, klassId } = useParams();

  const {
    data: book,
    error,
    isInitialLoading: isLoading,
  } = useQuery({ ...booksQueryKeys.get(Number(bookId)), enabled: !!bookId });

  const chapters = book?.chapters ?? [];
  const chapter = chapters?.at(Number(chapterOrder) - 1);

  const { data: course } = useQuery({
    enabled: !!slugCourseName && !currentProgress,
    ...coursePathsQueryKeys.get(slugCourseName ?? ''),
  });
  const coursePath = course ?? currentProgress?.coursePath;
  const courseAndLessonTitle = makeLessonHeadTitle({
    lessonOrder: order || 0,
    coursePath,
    lessonType: scheduledLesson?.type,
  });
  const titleWithoutChapter = `${t(
    'studentMaterial',
  )} - ${courseAndLessonTitle}`;

  const title = chapter
    ? `${chapter.title} - ${t('studentMaterial')} - ${courseAndLessonTitle}`
    : titleWithoutChapter;

  const apiError = error ? new ApiError(error as any) : undefined;

  if (apiError?.statusCode) {
    return (
      <React.Fragment>
        <HeadTitle routeInfo={title} />
        <ErrorComponent
          statusCode={apiError.statusCode}
          errorTextTitle={
            apiError.statusCode === 500
              ? tError('internError.title')
              : tError('materialNotFound.title')
          }
          errorTextSubTitle={
            apiError.statusCode === 500
              ? tError('internError.subtitle')
              : tError('materialNotFound.subtitle')
          }
        />
      </React.Fragment>
    );
  }

  return (
    <ChapterContainer isLoading={isLoading}>
      <HeadTitle routeInfo={title} />
      <ChapterRender chapter={chapter} />
      <MaterialControl
        hasChapter={!!chapter}
        chapters={chapters}
        baseUrl={getKlassViewBaseUrl({
          slugCourseName,
          lessonId: Number(lessonId),
          klassId: Number(klassId),
        })}
      />
    </ChapterContainer>
  );
}

function MaterialControl({
  hasChapter,
  chapters,
  baseUrl,
}: {
  hasChapter: boolean;
  chapters: Chapter[];
  baseUrl: string;
}) {
  const { user } = useAuth();
  if (!hasChapter) {
    return null;
  }
  if (isStudent(user?.userType)) {
    return <StudentMaterialControl baseUrl={baseUrl} />;
  }
  return <AdminMaterialControl baseUrl={baseUrl} chapters={chapters} />;
}
