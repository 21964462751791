import {
  ForwardedRef,
  forwardRef,
  KeyboardEventHandler,
  InputHTMLAttributes,
} from 'react';
import phoneHandler from '@/functions/phoneHandler';
import ConditionalRenderer from '../ConditionalRenderer';
import Skeleton from '../Skeleton';
import { BaseInputProps } from './BaseInput';
import { twMerge } from 'tailwind-merge';
import { useTranslation } from 'react-i18next';

export type TextInputProps = Omit<
  InputHTMLAttributes<HTMLInputElement>,
  'className'
> &
  BaseInputProps & {
    type?: 'text' | 'email' | 'phone' | 'password';
    color?: 'primary' | 'warning';
    errorLabelText?: string;
    typeInput?: string;
    centerText?: boolean;
    maxLenght?: number;
    mask?(value: string): string;
    onClickIcon?(): void;
    prefixColor?: string;
  };

interface IPreClasses {
  default: string;
  login: string;
}

const TextInput = forwardRef<HTMLInputElement, TextInputProps>(
  TextInputComponent,
);
export default TextInput;

function TextInputComponent(
  props: TextInputProps,
  ref: ForwardedRef<HTMLInputElement>,
) {
  const [t] = useTranslation('translation', {
    keyPrefix: 'common',
  });

  const {
    label,
    className,
    icon,
    testId,
    type,
    typeInput,
    color,
    errorLabelText,
    dataTestId,
    fontWeight,
    register,
    bgColor,
    fontSize,
    centerText,
    isLoading,
    labelPosition = 'top',
    errorLabelPosition = 'top',
    maxLenght,
    mask,
    disabled,
    onClickIcon,
    prefix,
    prefixColor,
    isOptional = false,
    removeDisableStyle,
    ...rest
  } = props;
  const name = register?.name;
  const preClasses: IPreClasses = {
    default: `${
      color === 'warning'
        ? 'input-error focus-visible:ring-error-content'
        : 'input-primary focus-visible:ring-primary-content'
    } input-sm pt-0.5`,
    login: 'h-10 focus:input-primary focus-visible:ring-primary-content',
  };

  const maskHandler: KeyboardEventHandler<HTMLInputElement> = e => {
    const input = e.currentTarget;
    if (mask) {
      input.value = mask(input.value);
    }
  };

  const formatLabel = isOptional
    ? `${label} (${t('optional').toLowerCase()})`
    : label;

  const disabledState =
    'disabled:text-base-content disabled:cursor-default disabled:bg-base-100 disabled:border-none';

  return (
    <div
      className={twMerge(
        `form-control relative flex text-14 ${
          labelPosition === 'left' ? 'flex-row w-full gap-3' : 'flex-col'
        }`,
        className?.base,
      )}
    >
      <ConditionalRenderer condition={label}>
        <label
          htmlFor={name}
          className={twMerge(
            `label leading-none p-0 w-full ${
              labelPosition === 'left' ? 'max-w-32' : ''
            }`,
            className?.label,
          )}
        >
          {formatLabel}
        </label>
      </ConditionalRenderer>
      <div className="flex-col w-full justify-center flex">
        <ConditionalRenderer condition={errorLabelText}>
          <label
            data-testid="errorLabel"
            className={`label text-error p-0 ${
              errorLabelPosition === 'bottom' ? 'absolute top-full w-full' : ''
            } ${fontSize || 'text-14'}`}
          >
            {errorLabelText}
          </label>
        </ConditionalRenderer>
        <div className="flex items-center relative w-full">
          <ConditionalRenderer
            condition={!isLoading}
            fallback={
              <Skeleton className="h-8 rounded-lg w-full bg-primary-content" />
            }
          >
            <ConditionalRenderer condition={!!prefix}>
              <span
                className={`flex w-[5rem] font-500 text-16 absolute pl-2 cursor-not-allowed ${
                  prefixColor ?? 'text-primary'
                }`}
              >
                {prefix}
              </span>
            </ConditionalRenderer>
            <input
              ref={ref}
              {...register}
              {...rest}
              placeholder={rest.placeholder}
              disabled={disabled}
              data-testid={testId || dataTestId}
              type={type}
              onKeyUp={type === 'phone' ? phoneHandler : maskHandler}
              maxLength={type === 'phone' ? 15 : maxLenght}
              className={twMerge(
                'w-full focus-visible:ring focus:outline-none input input-bordered text-base-content',
                typeInput
                  ? preClasses[typeInput as keyof IPreClasses]
                  : preClasses.default,
                color === 'warning'
                  ? 'input-error focus-visible:ring-error-content'
                  : 'input-primary focus-visible:ring-primary-content',
                icon ? 'pr-8' : '',
                prefix ? 'pl-[5rem]' : '',
                disabledState,
                className?.input,
              )}
            />
          </ConditionalRenderer>
          <ConditionalRenderer condition={icon}>
            <label
              onClick={onClickIcon}
              className={`${onClickIcon ? 'cursor-pointer' : ''} ${
                errorLabelText ? 'text-error' : 'text-primary'
              } ${
                disabled ? 'pointer-events-none' : ''
              } w-4 h-4 absolute right-2.5`}
            >
              {icon}
            </label>
          </ConditionalRenderer>
        </div>
      </div>
    </div>
  );
}
