import { AxiosResponse } from 'axios';
import DNDPositionalActivityProgress from '../../../models/DNDPositionalActivityProgress';
import requester from '../../../axios';

interface DNDActivityProgressParams {
  activityId: number;
  activityProgressId: number;
}

type UpdateDndPositionalProgress = Pick<
  DNDPositionalActivityProgress,
  'isDone'
>;

export const updateDNDPositionalActivityProgress = async (
  { activityId, activityProgressId }: DNDActivityProgressParams,
  body: UpdateDndPositionalProgress,
) => {
  type Response = AxiosResponse<DNDPositionalActivityProgress>;
  const { data: dndPositionalProgress } = await requester().patch<
    UpdateDndPositionalProgress,
    Response
  >(
    `dnd-positional-activity/${activityId}/dnd-positional-activity-progress/${activityProgressId}/`,
    body,
  );
  return dndPositionalProgress;
};
