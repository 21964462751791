import { AxiosError } from 'axios';
import i18n from '../i18n';

interface ErrorDetail<Field> {
  field?: Field;
  code: string;
  detail: string;
}

export interface IApiError<Fields = string> {
  errors: ErrorDetail<Fields>[];
}

export class ApiError<Fields = string> implements IApiError<Fields> {
  errors: ErrorDetail<Fields>[];
  statusCode: number;

  constructor(error: AxiosError<IApiError<Fields>>) {
    const response = error.response;
    const responseData = response && response.data;

    this.statusCode = response?.status ?? 500;

    if (typeof responseData === 'object' && 'errors' in responseData) {
      this.errors = responseData.errors;
    } else {
      this.errors = this.createError(this.statusCode);
    }
  }
  /**
   * This function will return translated error message by the code
   */

  public getErrorMessage() {
    return this.messages.join(', ');
  }
  get messages() {
    return this.errors.map(({ detail }) => detail);
  }

  get codes() {
    return this.errors.map(({ code }) => code);
  }

  get fields() {
    const fields: Fields[] = [];
    this.errors.forEach(({ field }) => {
      if (field) {
        fields.push(field);
      }
    });

    return fields;
  }

  getMessageByField(field: Fields): string | undefined {
    const error = this.errors.find(
      ({ field: erroField }) => erroField === field,
    );
    return error?.detail;
  }

  private createError(statusCode: number) {
    switch (statusCode) {
      case 404:
        return [
          {
            code: 'not_found_error',
            detail: i18n.t('errors.errorPage.badRequest.title'),
          },
        ];

      case 403:
        return [
          {
            code: 'forbidden_error',
            detail: i18n.t('errors.errorPage.forbidden.title'),
          },
        ];

      default:
        return [
          {
            code: 'internal_server_error',
            detail: i18n.t('errors.serverError'),
          },
        ];
    }
  }
}

export interface IHandleError {
  statusCode: number;
  defaultDetail: {
    code: string;
    message: string;
    type: string;
  };
}

export class HandleError implements IHandleError {
  statusCode!: number;
  defaultDetail!: {
    code: string;
    message: string;
    type: string;
  };
}
