import ErrorDispatcher from './ErrorDispatcher';

export class CoursePathErrorDispatcher implements ErrorDispatcher {
  public readonly courseAbbreviation: string;
  constructor(courseAbbreviation: string) {
    this.courseAbbreviation = courseAbbreviation;
  }

  getError(): Error {
    return new Error(`${this.courseAbbreviation} does not have course path`);
  }
}
