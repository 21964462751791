import { QuizQuestion } from '@/models/ActivityProgress';
import Text from '@/components/common/dataDisplay/Text';
import { stringAnswer } from '@/utils/activities';
import { QuizAnswersType } from '@/models/ActivityElement';
import ConditionalRenderer from '@/components/common/ConditionalRenderer';

interface AnswerProps {
  value: QuizQuestion;
  index: number;
  onClickAnswer?: (answer: QuizQuestion) => void;
  selectedAnswer?: QuizQuestion;
  disabled?: boolean;
  answerType: QuizAnswersType;
  updateAnswer?: (answer: QuizQuestion) => Promise<void> | void;
}

export default function Answer({ onClickAnswer, ...props }: AnswerProps) {
  const {
    value: answer,
    answerType,
    index,
    selectedAnswer,
    updateAnswer,
    disabled,
  } = props;
  const imageType = answerType === QuizAnswersType.IMAGE;

  const selected = selectedAnswer?.id === answer.id;

  const letter = stringAnswer(index);

  const imageTypeClassName = imageType
    ? 'rounded-lg'
    : 'rounded-full sm:w-[90%]';

  const onClick = () => updateAnswer?.(answer);

  return (
    <button
      className={`
          flex w-full text-start shadow-default cursor-pointer items-center ${imageTypeClassName} 
          justify-start py-2 px-7 min-h-16 gap-4 ${
            selected
              ? 'bg-gradient-to-tr from-primary to-secondary'
              : 'bg-base-100'
          } 
          duration-500 disabled:cursor-default break-words
        `}
      onClick={onClick}
      disabled={disabled}
    >
      <span
        className={`flex w-9 h-9 rounded-full shrink-0 justify-center items-center ${
          selected ? 'text-secondary bg-base-100' : 'text-base-100 bg-secondary'
        } `}
      >
        <Text text={letter} format="rubik-500" />
      </span>

      <ConditionalRenderer
        condition={imageType}
        fallback={
          <Text
            text={answer.answer}
            className={selected ? 'text-base-100' : 'text-base-content'}
          />
        }
      >
        <img className="w-[85%]" src={answer.answer} alt={answer.altText} />
      </ConditionalRenderer>
    </button>
  );
}
