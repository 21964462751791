import { USER_SETTINGS } from '@/constants';
import { UserSettings } from '@/models/UserSettings';

type UpdateUserSettings = {
  updates: Partial<UserSettings>;
};

export function updateSettings({ updates }: UpdateUserSettings) {
  const settings = localStorage.getItem(USER_SETTINGS);

  let userSettings: UserSettings = settings
    ? (JSON.parse(settings) as UserSettings)
    : {};

  userSettings = { ...userSettings, ...updates };

  localStorage.setItem(USER_SETTINGS, JSON.stringify(userSettings));
}

type DeleteSettings = {
  removals: (keyof UserSettings)[];
};

export function deleteSettings({ removals }: DeleteSettings) {
  const settings = localStorage.getItem(USER_SETTINGS);

  let userSettings: UserSettings = settings
    ? (JSON.parse(settings) as UserSettings)
    : {};

  for (const key in removals) {
    delete userSettings[key as keyof UserSettings];
  }

  localStorage.setItem(USER_SETTINGS, JSON.stringify(userSettings));
}

export const getUserSettings = () => {
  const userSettings = localStorage.getItem(USER_SETTINGS);

  if (userSettings) return JSON.parse(userSettings) as UserSettings;
};

export const removeLocalSettings = () => localStorage.removeItem(USER_SETTINGS);
