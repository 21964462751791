import { VersioningStatusEnum } from '@/enums/VersioningStatus';
import { CircleIcon, DashedCircleIcon } from '@/components/icons';
import { CheckCircleIcon } from '@heroicons/react/solid';
import { t } from 'i18next';
import React from 'react';

interface ButtonContentProps {
  icon: JSX.Element;
  text: string;
}

type size = `w-${number} h-${number}`;

export function isPulished(status: VersioningStatusEnum) {
  return status === VersioningStatusEnum.PUBLISHED;
}

export default function handleButtonContent(
  status: VersioningStatusEnum,
  version?: number,
  size: size = `w-4 h-4`,
): ButtonContentProps {
  switch (status) {
    case VersioningStatusEnum.EDITING:
      return {
        icon: (
          <DashedCircleIcon data-testid="dashed-circle" className={`${size}`} />
        ),
        text: t(`courses.status.${VersioningStatusEnum.EDITING}`),
      };
    case VersioningStatusEnum.VERSIONING:
      const textKey =
        Number(version) === 1.0 ? 'NEW' : VersioningStatusEnum.VERSIONING;
      return {
        icon: (
          <DashedCircleIcon data-testid="dashed-circle" className={`${size}`} />
        ),
        text: t(`courses.status.${textKey}`),
      };
    case VersioningStatusEnum.PUBLISHED:
      return {
        icon: (
          <CheckCircleIcon data-testid="checked-circle" className={`${size}`} />
        ),
        text: t(`courses.status.${VersioningStatusEnum.PUBLISHED}`),
      };

    case VersioningStatusEnum.BETA:
      return {
        icon: <CircleIcon data-testid="circle" className={`${size}`} />,
        text: t(`courses.status.${VersioningStatusEnum.BETA}`),
      };

    default:
      return {
        icon: <React.Fragment />,
        text: '',
      };
  }
}
