import { animMerge, scale, slideVertical } from '@/utils/animations/commom';
import { motion } from 'framer-motion';
import { twMerge } from 'tailwind-merge';

const badgeColors = {
  info: 'badge-success',
  success: 'badge-success',
  warning: 'badge-warning',
  error: 'badge-error',
};
interface BadgeProps {
  count?: number | string;
  className?: string;
  classNameContainer?: string;
  testId?: string;
  color?: keyof typeof badgeColors;
  children?: JSX.Element;
  position?: string;
}
export default function Badge(props: BadgeProps) {
  const {
    count,
    className,
    classNameContainer,
    testId,
    children,
    position,
    color,
  } = props;
  return (
    <div className={twMerge('relative', classNameContainer)}>
      {children}
      <motion.div
        data-testid={testId}
        {...animMerge(slideVertical(10), scale)}
        transition={{ duration: 0.1 }}
        className={twMerge(
          `badge badge-xs ${
            badgeColors[color || 'error']
          } absolute leading-none ${
            position || 'right-0 bottom-0'
          } px-[0.1875rem] border-base-100 text-10 py-1.5 `,
          className,
          count ? '' : 'hidden',
        )}
      >
        {count}
      </motion.div>
    </div>
  );
}
