import Area, { AreaWithParent } from '@/models/Area';
import Tag, { LevelUnion } from '@/models/Tag';

export function extractParentsId(area?: AreaWithParent): number[] {
  if (!area) {
    return [];
  }
  return [...extractParentsId(area.parentArea), area.id];
}

export function extractParents(area?: AreaWithParent): Area[] {
  if (!area) {
    return [];
  }
  return [...extractParents(area.parentArea), area];
}

export const mountTopicTagName = (tag: Tag, stack: Area[]) => {
  const lastArea = stack.at(-1);
  if (!lastArea) return '';
  const path = stack
    .slice(0, -1)
    .map(area => area.name.slice(0, 4))
    .join('/');
  return `${path}/${mountTagName(tag, lastArea)}`;
};

export const numberToRoman = (num: LevelUnion) => {
  const roman = ['I', 'II', 'III', 'IV', 'V'];

  return roman[num - 1];
};

export const mountTagName = (tag: Tag, area: Area) => {
  return `${area.name}-[${tag.difficulty.slice(0, 3)}-${numberToRoman(
    tag.level,
  )}]`;
};
