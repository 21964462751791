import Checkbox from '@/components/common/dataInput/Checkbox';
import SortToggle from '@/components/common/dataInput/SortToggle';
import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { AlertFiltersOptions } from './NotificationsPageContent';
import NotificationStatusFilter from './NotificationStatusFilter';

const NotificationTopFilters: React.FC = () => {
  const { t } = useTranslation('translation', { keyPrefix: 'alerts.filters' });
  const { control, register } = useFormContext<AlertFiltersOptions>();

  return (
    <article className="flex flex-wrap gap-4 justify-between">
      <NotificationStatusFilter />
      <div className="flex gap-5">
        <Checkbox {...register('onlyPendings')} label={t('onlyPendings')} />
        <Controller
          name="sortBy"
          control={control}
          render={({ field }) => (
            <SortToggle
              id="topFiltersSort"
              hideLabel
              isDescending={field.value === 'desc'}
              text={{
                ascending: t('oldest'),
                descending: t('newest'),
              }}
              onToggleSort={checked => field.onChange(checked ? 'desc' : 'asc')}
            />
          )}
        />
      </div>
    </article>
  );
};

export default NotificationTopFilters;
