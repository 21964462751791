import useMutationObserver, {
  CustomMutationCallback,
} from '@/data/hook/useMutationObverser';
import { motion, useAnimation } from 'framer-motion';
import { useCallback, useEffect } from 'react';
import { twMerge } from 'tailwind-merge';
import { useTabsPanelContext } from './Tabs';

type TabsContentProps = {
  className?: string;
  carrouselContent?: string;
  onSwapContent?: (sizes: { width: number; height: number }) => void;
};

const TabsContent = ({
  className,
  carrouselContent,
  onSwapContent,
}: TabsContentProps) => {
  const { orientation, active, contents, triggerListSize } =
    useTabsPanelContext();

  const isVertical = orientation === 'vertical';

  const controls = useAnimation();
  useEffect(() => {
    const animate = async () => {
      await controls.start({
        scale: 0.95,
        opacity: 0,
        transition: {
          duration: 0.05,
        },
      });

      await controls.start({
        scale: [0.95, 1],
        opacity: [0, 1],
        transition: {
          duration: 0.15,
        },
      });
    };
    !carrouselContent && animate();
  }, [active, controls, carrouselContent]);

  const swapContent = useCallback(
    (ref: HTMLDivElement) => {
      const isCarrouselSelected = carrouselContent === active;
      if (onSwapContent && ref && isCarrouselSelected) {
        onSwapContent({
          width: ref.offsetWidth,
          height: ref.offsetHeight,
        });
      }
    },
    [active, carrouselContent, onSwapContent],
  );

  const cbSwapContent: CustomMutationCallback<HTMLDivElement> = useCallback(
    (_ref, _obj, ref) => {
      if (ref) {
        swapContent(ref);
      }
    },
    [swapContent],
  );

  const { ref } = useMutationObserver<HTMLDivElement>(cbSwapContent, {
    attributes: true,
    subtree: true,
  });

  useEffect(() => {
    if (ref.current) swapContent(ref.current);
  }, [active, ref, swapContent]);

  return (
    <motion.aside
      ref={ref}
      className={twMerge(
        'relative w-full h-fit py-3.5 px-5 rounded-md bg-base-100',
        className,
      )}
      style={{
        minHeight: isVertical ? triggerListSize?.height : 'auto',
      }}
      animate={controls}
      data-testid={`${active}-panel`}
    >
      {contents.get(carrouselContent || active)}
    </motion.aside>
  );
};

export default TabsContent;
