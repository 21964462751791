import { Lesson } from '@/models/Lesson';
import ScheduledLesson, {
  ScheduledLessonTypeEnum,
} from '@/models/ScheduledLesson';

export const formatLessonName = (
  lesson: Lesson,
  scheduledLesson?: ScheduledLesson,
): string => {
  const { name } = lesson;
  if (scheduledLesson && !scheduledLesson.isActive) {
    return formatOldNames(name);
  }

  const scheduledLessonType =
    scheduledLesson?.type ??
    (lesson.order
      ? ScheduledLessonTypeEnum.NORMAL
      : ScheduledLessonTypeEnum.EXTRA);

  const prefix = formatLessonNamePrefix({
    lessonOrder: lesson.order,
    scheduledLessonType,
  });

  return `${prefix} - ${formatOldNames(name)}`;
};

const extraLessonPrefix = 'Aula Extra';

export const formatOldNames = (name: string) =>
  name ? name.replace(/Aula [0-9]+ - /, '') : '';

interface FormatLessonNamePrefixParam {
  lessonOrder: number;
  scheduledLessonType?: ScheduledLessonTypeEnum;
}

export const formatLessonNamePrefix = ({
  lessonOrder,
  scheduledLessonType,
}: FormatLessonNamePrefixParam) => {
  if (lessonOrder === undefined && !scheduledLessonType) {
    return '';
  }

  if (
    scheduledLessonType !== ScheduledLessonTypeEnum.NORMAL &&
    scheduledLessonType
  ) {
    return extraLessonPrefix;
  }
  const needAddZero = lessonOrder < 10;
  return `Aula ${needAddZero ? `0${lessonOrder}` : lessonOrder}`;
};
