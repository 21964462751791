import moment from 'moment';
import { formatLessonName } from '../functions/lessonsName';
import Klass from '../models/Klass';
import ScheduledLesson from '../models/ScheduledLesson';
import { translateKlassName } from './translateKlassName';

export const formatScheduledLessonText = (
  scheduledLesson: ScheduledLesson,
  klass?: Klass,
): string => {
  const scheduledLessonDate = moment(scheduledLesson.datetime);

  const klassName = translateKlassName(klass?.name);

  const lessonName = formatLessonName(
    scheduledLesson.lesson,
    scheduledLesson,
  ).slice(0, 7);

  const lessonDate = moment(scheduledLessonDate).format('(DD/MM)');

  return `${klassName} - ${lessonName} ${lessonDate}`;
};
