import { useEffect, useState } from 'react';
import { DropResult } from '@hello-pangea/dnd';
import CoursePath from '@/models/Course';
import { Lesson } from '@/models/Lesson';
import DraggableLesson from './DraggableLesson';
import LessonReplacer from './LessonReplacer';
import { useMutation } from '@tanstack/react-query';
import { updateLessonsOrder } from '@/data/services/lessonServices';
import alert from '@/utils/UseAlert';
import { useTranslation } from 'react-i18next';
import LoadingView from '@/pages/courses/LoadingView';
import { ApiError } from '@/models/Errors';
import InfinityList from '@/components/common/InfinityList';

interface ListLessonsProps {
  lessons?: Lesson[];
  course: CoursePath;
  updateLessons: () => Promise<void>;
  deleteLesson(lesson: Lesson): void;
  isLoadingLessonList: boolean;
  hasNextPage?: boolean;
  isFetchingNextPage?: boolean;
  onReachEnd?(): unknown | Promise<unknown>;
}

export function ListLessons({
  course,
  deleteLesson,
  updateLessons,
  lessons = [],
  isLoadingLessonList,
  hasNextPage,
  isFetchingNextPage,
  onReachEnd,
}: ListLessonsProps) {
  const { t } = useTranslation('translation', {
    keyPrefix: 'adminPage.lesson',
  });

  const [list, setList] = useState<Lesson[]>(lessons);

  useEffect(() => {
    if (lessons.length) setList(lessons);
  }, [lessons]);

  const swapLessons = (sourceIndex: number, destinationIndex: number) => {
    const newList = list;
    const [sourceData] = newList.splice(sourceIndex, 1);
    newList.splice(destinationIndex, 0, sourceData);

    return newList;
  };

  const handleDragEnd = (res: DropResult) => {
    if (!res.destination || res.destination.index === res.source.index) return;

    const lessonsUpdated = swapLessons(res.source.index, res.destination.index);

    onUpdate({ course, lessons: lessonsUpdated });

    setList(lessonsUpdated);
  };

  const saveLessonsOrder = async ({
    course,
    lessons,
  }: {
    course: CoursePath;
    lessons: Lesson[];
  }) => {
    const updatedLessons = lessons.map(({ id }) => ({ id }));
    return await updateLessonsOrder(course.slug, updatedLessons);
  };

  const { mutate: onUpdate } = useMutation(saveLessonsOrder, {
    onSuccess: async () => {
      alert.success(t('successOrderMessage'));
      await updateLessons();
    },
    onError: error => {
      const apiError = new ApiError(error as any);

      if (apiError.fields.includes('abbreviation')) {
        const fieldMessage = apiError.getMessageByField('abbreviation');
        fieldMessage && alert.warning(fieldMessage);
      } else {
        alert.error(apiError.getErrorMessage());
      }
    },
  });

  if (isLoadingLessonList) return <LoadingView />;

  return (
    <InfinityList
      isFetchingNextPage={isFetchingNextPage}
      onReachEnd={onReachEnd}
      hasNextPage={hasNextPage}
      className="gap-4"
    >
      <LessonReplacer onDragEnd={handleDragEnd}>
        {list.map((lesson, index) => (
          <DraggableLesson
            key={lesson.id}
            lesson={lesson}
            course={course}
            deleteLesson={deleteLesson}
            updateLessons={updateLessons}
            index={index}
          />
        ))}
      </LessonReplacer>
    </InfinityList>
  );
}
