interface VimeoEmbedProps {
  url: string;
  title: string;
}

export default function VimeoEmbed(props: VimeoEmbedProps) {
  return (
    <div className="video-responsive">
      <iframe
        src={`https://player.vimeo.com/video/${props.url}`}
        frameBorder="0"
        allow="autoplay; fullscreen; picture-in-picture"
        allowFullScreen
        title={props.title}
      />
    </div>
  );
}
