import Text from './dataDisplay/Text';
import { useTranslation } from 'react-i18next';
import RoundedButton from './buttons/RoundedButton';
import { useMutation } from '@tanstack/react-query';
import ConditionalRenderer from './ConditionalRenderer';
import useLessonContext from '@/data/hook/lesson';
import { updateLessonProgress as updateProgress } from '@/data/services/lessonProgressServices';
import LessonCard, { LessonCardProps } from '../lessons/LessonCard/LessonCard';

type ToolCardProps = LessonCardProps & {
  toolUrl?: string;
  instructionsUrl?: string;
  hasAccessedTool?: boolean;
  lessonProgressId?: number;
  lessonId?: number;
};

export default function ToolCard(props: ToolCardProps) {
  const { toolUrl, instructionsUrl, notification, active, type } = props;

  return (
    <ConditionalRenderer condition={toolUrl || instructionsUrl}>
      <LessonCard notification={notification} type={type} active={active}>
        <Content {...props} />
      </LessonCard>
    </ConditionalRenderer>
  );
}

type ContentProps = ToolCardProps;

function Content(props: ContentProps) {
  const { hasAccessedTool, lessonProgressId, toolUrl, instructionsUrl } = props;
  const { t } = useTranslation('translation', {
    keyPrefix: 'lesson.access',
  });

  const { updateLessonProgress } = useLessonContext();

  const { mutate: update } = useMutation(updateProgress, {
    onSuccess: updateLessonProgress,
  });

  const updateAccess = () => {
    if (!lessonProgressId || hasAccessedTool) return;

    const lessonProgress = {
      hasAccessedTool: true,
    };

    update({ lessonProgressId, lessonProgress });
  };

  return (
    <div className="flex flex-col gap-3 p-2">
      <Text size="text-14" text={t('hasVideoInstallationText')} />
      <Text size="text-14" text={t('clickToWatchVideoInstalationText')} />
      <ConditionalRenderer condition={instructionsUrl}>
        <RoundedButton
          testId="instructionUrlButton"
          text={t('accessVideo')}
          href="help"
          className="w-full"
        />
      </ConditionalRenderer>
      <ConditionalRenderer condition={toolUrl}>
        <RoundedButton
          onClick={updateAccess}
          testId="toolUrlButton"
          href={toolUrl}
          isExternalLink={true}
          text={t('accessProgram')}
          disabled={false}
          className="w-full whitespace-nowrap"
        />
      </ConditionalRenderer>
    </div>
  );
}
