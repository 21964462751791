import ConditionalRenderer from '@/components/common/ConditionalRenderer';
import MainButton from '@/components/common/buttons/MainButton';
import SaveCancelGroup from '@/components/common/buttons/SaveCancelGroup';
import TextWithCopy from '@/components/common/dataDisplay/TextWithCopy';
import TextAreaInput from '@/components/common/dataInput/TextAreaInput';
import useToggle from '@/data/hook/useToggle';
import { PencilIcon, PlusIcon } from '@heroicons/react/outline';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { twMerge } from 'tailwind-merge';
import { NotificationActionsProps } from '../card/NotificationTypes';
import { ContactsButton } from './Contacts';
import { useAlertMessage } from './factory/NotificationCard.hooks';

const NotificationActions: React.FC<NotificationActionsProps> = ({
  onSendMessage,
  onAddComment,
  notification,
}) => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'alerts.buttons',
  });
  const { t: tInput } = useTranslation('translation', {
    keyPrefix: 'alerts.inputs',
  });
  const { message, isInitialLoading } = useAlertMessage(notification);

  const { isOpen, toggle, close } = useToggle(false);
  const [comment, setComment] = useState(notification.comment);
  const disableSubmit = comment === notification.comment;

  const hasComment = !!notification.comment;

  const CommentIcon = hasComment ? PencilIcon : PlusIcon;
  const commentText = t(hasComment ? 'comment.edit' : 'comment.add');
  return (
    <div className="flex flex-col gap-1.5">
      <div className="flex gap-1 items-center flex-wrap">
        <ConditionalRenderer condition={!notification.isRead}>
          <ContactsButton
            notification={notification}
            onSendMessage={onSendMessage}
          />
        </ConditionalRenderer>

        <ConditionalRenderer condition={!notification.isRead}>
          <TextWithCopy
            label={t('copyMessage')}
            target={message}
            clickText
            className={{
              base: twMerge(
                'rounded-full bg-primary-content border-[1px] border-primary px-2 py-1 gap-1.5 cursor-pointer flex-row-reverse',
                isInitialLoading ? 'disabled' : '',
              ),
              text: 'text-primary text-14',
            }}
          />
        </ConditionalRenderer>

        <MainButton
          className="rounded-full bg-primary-content border-[1px] border-primary !text-primary px-2 py-1 gap-1.5 opacity-100"
          onClick={toggle}
          color="custom"
          size="regular"
          icon={<CommentIcon />}
          text={commentText}
        />
      </div>
      <ConditionalRenderer condition={isOpen}>
        <div className="flex flex-col gap-1.5">
          <TextAreaInput
            value={comment}
            placeholder={tInput('commentPlaceholder')}
            onChange={e => setComment(e.target.value)}
          />
          <SaveCancelGroup
            save={{
              onClick: () => {
                onAddComment(notification.id, comment);
                close();
              },
              type: 'button',
              disabled: !!disableSubmit,
            }}
            cancel={{
              onClick: () => {
                setComment(notification.comment);
                close();
              },
            }}
          />
        </div>
      </ConditionalRenderer>
    </div>
  );
};

export default NotificationActions;
