import BookProgress from '@/models/BookProgress';
import { booleanToNotification } from './notification';
import ActivityProgress from '@/models/ActivityProgress';
import { HomeworkActivityProgress } from '@/models/HomeworkActivity';
import { ChapterStatusEnum } from '@/models/ChapterProgress';
import { PresenceEnum } from '@/models/ScheduledLessonReport';

export function bookNotification(
  bookProgress?: BookProgress,
  freeLesson?: boolean,
) {
  if (bookProgress) {
    if (freeLesson) {
      const chaptersCount = bookProgress.chaptersProgress.length;
      const readCount = bookProgress.chaptersProgress.reduce(
        (acc, current) =>
          current.status === ChapterStatusEnum.READ ? ++acc : acc,
        0,
      );

      return Math.ceil((readCount / chaptersCount) * 100);
    } else return Math.ceil(bookProgress.progress * 100);
  }

  return 0;
}

export function activityNotification(
  activityProgressList?: ActivityProgress[],
) {
  if (activityProgressList && activityProgressList.length)
    return activityProgressList
      .map(activity => activity.hasDone)
      .filter(hasDone => !hasDone).length;
  else return 0;
}

export function homeworkNotification(
  homeworkProgress?: HomeworkActivityProgress | null,
) {
  if (homeworkProgress)
    return booleanToNotification(homeworkProgress.activityProgress.hasDone);
  else return 0;
}

export function challengeNotification(
  challengeProgress?: ActivityProgress | null,
) {
  if (challengeProgress)
    return booleanToNotification(challengeProgress.hasDone);
  else return 0;
}

export function toolUrlNotification(
  hasAccessedTool: boolean,
  toolUrl?: string | null,
) {
  if (toolUrl) return booleanToNotification(hasAccessedTool);
  else return 0;
}

export function watchedInstructionNotification(
  hasWatchedInstruction: boolean,
  instructionsUrl?: string | null,
) {
  if (instructionsUrl) return booleanToNotification(hasWatchedInstruction);
  else return 0;
}

export const isDoneNotification = (
  notification: number,
  isPercentage: boolean = false,
) => {
  const percentageCondition = isPercentage && notification === 100;
  return (notification === 0 && !isPercentage) || percentageCondition;
};

export const presenceNotification = (
  presence: PresenceEnum | undefined | null,
) => {
  const attendenceValues = [PresenceEnum.ATTENDED, PresenceEnum.RESCHEDULED];
  if (!presence) {
    return -1;
  }
  if (attendenceValues.includes(presence)) {
    return 0;
  }
  return presence === PresenceEnum.MISSED ? 1 : -1;
};
