import { AnimationProps, Variant } from 'framer-motion';

export const lessonCardAnimation: AnimationProps = {
  initial: { opacity: 0, height: 0 },
  animate: {
    opacity: 1,
    height: 'auto',
    transition: {
      type: 'spring',
    },
  },
  exit: { opacity: 0, height: 0 },
};

export const buildMotionLIProps = (
  i: number,
  direction: 'right' | 'left' = 'left',
  delay = 0.1,
) => {
  const variants: Record<'animate' | 'initial', Variant> = {
    animate: (i: number) => ({
      x: 0,
      opacity: 1,
      transition: {
        type: 'spring',
        duration: 0.2,
        delay: 0.05 + i * delay,
        mass: 0.8,
      },
    }),
    initial: {
      x: direction === 'right' ? 200 : -200,
      opacity: 0,
    },
  };

  return {
    variants,
    animate: 'animate',
    initial: 'initial',
    custom: i,
  };
};
