import React, { useRef, useCallback } from 'react';
import { Avatar, AvatarItemsEnum, BaseItem, Inventory } from '@/models/Avatar';
import { useTranslation } from 'react-i18next';
import { LoadingIcon } from '../icons';
import Text from '../common/dataDisplay/Text';
import { BanIcon } from '@heroicons/react/outline';
import ItemShop from './ItemShop';
import { getAvatarInfo, getBaseItemCategory } from '@/utils/userAvatarUtils';
import ConditionalRenderer from '../common/ConditionalRenderer';
import useInfiniteService from '@/data/hook/useInfiniteService';
import { shopQueryKeys } from '@/data/services/querykeys';

export const ListItemsShop = ({
  inventory,
  onChange,
  onChangeInventory,
  shopAvatar,
  subcategory,
}: {
  onChange: (newValue: BaseItem | null, color: string) => void;
  onChangeInventory: (newValue: BaseItem | null, color: string) => void;
  shopAvatar: Avatar;
  inventory?: Inventory;
  subcategory: AvatarItemsEnum;
}) => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'inventory',
  });
  const inventoryCategory = getBaseItemCategory(subcategory);

  const {
    fetchNextPage,
    isFetching: isLoading,
    hasNextPage,
    results,
  } = useInfiniteService({
    ...shopQueryKeys.list({ itemType: inventoryCategory })._ctx.infinity,
  });

  const loadedFirstPage = !!results;
  const hasItems = !!results.length;

  const intObserver = useRef<IntersectionObserver | null>(null);

  const secondLastLIRef = useCallback(
    (li: HTMLDivElement) => {
      if (isLoading) return;

      if (intObserver.current) intObserver.current.disconnect();

      intObserver.current = new IntersectionObserver(li => {
        if (li[0].isIntersecting && hasNextPage) {
          fetchNextPage();
        }
      });

      if (li) intObserver.current.observe(li);
    },
    [isLoading, fetchNextPage, hasNextPage],
  );

  const { baseItem } = getAvatarInfo(shopAvatar, subcategory);

  return (
    <div
      className={`flex flex-row ${
        hasItems ? '' : 'justify-center items-center'
      } flex-wrap overflow-y-scroll overflow-x-visible pt-0.5 px-1.5 h-[11.75rem] gap-1.5 w-full scrollbar-thin scrollbar-w-[4px] scrollbar-thumb-primary/40
    scrollbar-track-primary-content scrollbar-thumb-rounded-full scrollbar-track-rounded-full`}
    >
      <ConditionalRenderer condition={!isLoading && !hasItems}>
        <Text text={t('noItems')} className="text-primary" format="rubik-500" />
      </ConditionalRenderer>
      <ConditionalRenderer condition={loadedFirstPage && hasItems}>
        <div
          onClick={() => onChange(null, '#fff')}
          className={`bg-warning-content aspect-square flex flex-col gap-0.5 h-20 items-center justify-center rounded-xl cursor-pointer border-2 ${
            baseItem ? 'border-warning-content' : 'border-warning'
          }  relative`}
        >
          <BanIcon className="w-10 h-10 text-warning" />
          <Text
            text={t('removeItem')}
            className="text-warning font-500 text-12"
          />
        </div>

        {results.map(item => {
          return (
            <div ref={hasNextPage ? secondLastLIRef : null} key={item.id}>
              <ItemShop
                inventory={inventory}
                subcategory={subcategory}
                shopAvatar={shopAvatar}
                onChange={onChange}
                onChangeInventory={onChangeInventory}
                item={item}
              />
            </div>
          );
        })}
      </ConditionalRenderer>
      <ConditionalRenderer condition={isLoading}>
        <div className="w-full flex justify-center">
          <LoadingIcon className="w-20 h-20 text-warning-content" />
        </div>
      </ConditionalRenderer>
    </div>
  );
};
