import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useQueryClient } from '@tanstack/react-query';
import alert from '@/utils/UseAlert';
import { ApiError } from '@/models/Errors';
import RoundedButton from '../../common/buttons/RoundedButton';
import Text from '../../common/dataDisplay/Text';
import Modal from '../../common/modals/Modal';
import { coursePathsQueryKeys } from '@/data/services/querykeys';

interface PublishBetaModalProps {
  onClose(): void;
  onConfirm(...params: any[]): Promise<void>;
  visible: boolean;
}

function PublishBetaModal({
  onClose,
  visible,
  onConfirm,
}: PublishBetaModalProps) {
  const { t } = useTranslation('translation', {
    keyPrefix: 'adminPage.lesson',
  });
  const query = useQueryClient();
  const [isLoading, setIsLoading] = useState(false);
  async function courseToPublish() {
    try {
      setIsLoading(true);
      await onConfirm();
      alert.success(t('modal.publishBeta.success'));
      setIsLoading(false);
      query.invalidateQueries(coursePathsQueryKeys.list._def);
      onClose();
    } catch (error: any) {
      const apiError = new ApiError(error);
      alert.error(apiError.getErrorMessage());
      setIsLoading(false);
    }
  }
  return (
    <Modal onClose={onClose} visible={visible}>
      <div className="flex flex-col items-center gap-6">
        <Text
          text={t('modal.publishBeta.title')}
          className="text-primary text-center"
          format="rubik-500"
          size="text-20"
        />
        <Text text={t('modal.publishBeta.warning')} className="text-center" />
        <Text text={t('modal.publishBeta.question')} className="text-center" />
        <div className="flex items-center gap-7 w-full">
          <RoundedButton
            className="w-full"
            text={t('modal.publishBeta.cancel')}
            onClick={onClose}
            color="neutral"
          />
          <RoundedButton
            className="w-full"
            testId="confirmPublishButton"
            loading={isLoading}
            onClick={courseToPublish}
            text={t('modal.publishBeta.confirm')}
            color="gradient"
          />
        </div>
      </div>
    </Modal>
  );
}

export default PublishBetaModal;
