import { BaseFilters } from '@/models/Service';
import requester from '@/axios';
import Tool from '@/models/Tool';
import JsonApiResponse from '@/models/JsonApiResponse';

export interface ToolParams {
  toolId?: number;
}

export type ToolBody = Omit<Tool, 'id' | 'image'> & {
  image?: File | string | null;
};

export interface ToolRequest {
  params: ToolParams;
  body: Partial<ToolBody>;
}

export interface ListToolsFilters extends BaseFilters {
  name?: string;
  site?: string;
}

export const listTools = async (params: ListToolsFilters = {}) => {
  const { data } = await requester().get<JsonApiResponse<Tool>>('tools/', {
    params,
  });
  return data;
};

export const addTool = async (request: ToolRequest) => {
  const { body } = request;
  const formData = new FormData();

  Object.entries(body).forEach(([key, value]) => {
    if (key === 'image' && !value) {
      formData.set(key, '');
    }

    if (value) {
      formData.set(key, value instanceof File ? value : value.toString());
    }
  });

  const { data } = await requester().post<Tool>('tools/', formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
      accept: 'application/json',
    },
  });

  return data;
};

export const editTool = async (request: ToolRequest) => {
  const { body, params } = request;
  const formData = new FormData();

  Object.entries(body).forEach(([key, value]) => {
    if (key === 'image' && !value) {
      formData.set(key, '');
    }

    if (value) {
      formData.set(key, value instanceof File ? value : value.toString());
    }
  });

  const { data } = await requester().patch<Tool>(
    `tools/${params.toolId}/`,
    formData,
    {
      headers: {
        'Content-Type': 'multipart/form-data',
        accept: 'application/json',
      },
    },
  );

  return data;
};
