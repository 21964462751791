import { useTranslation } from 'react-i18next';
import { XIcon } from '@heroicons/react/outline';

import Text from '@/components/common/dataDisplay/Text';
import RoundedButton from '@/components/common/buttons/RoundedButton';
import ConditionalRenderer from '@/components/common/ConditionalRenderer';
import IconButton from '@/components/common/buttons/IconButton';
import { CookieIcon } from '@/components/icons';

export type ConsentModalProps = {
  acknowledged?: boolean;
  setAcknowledged: (acknowledged: boolean) => void;
};

export default function ConsentModal({
  acknowledged,
  setAcknowledged,
}: ConsentModalProps) {
  const { t } = useTranslation('translation', {
    keyPrefix: 'consentModal',
  });

  return (
    <ConditionalRenderer condition={!acknowledged}>
      <div
        data-testid="consentModal"
        className="toast toast-center bg-base-100 rounded-2xl shadow-default items-center flex mb-5 text-neutral w-[90%] xs:max-w-[400px] gap-4"
      >
        <IconButton
          testId="ignoreButton"
          onClick={() => setAcknowledged(true)}
          className="absolute right-0 top-0 pt-4 pr-4"
          icon={<XIcon className="min-w-5 min-h-5 text-base-content/30" />}
        />
        <div className="flex flex-col justify-center items-center gap-4">
          <CookieIcon className="min-w-10 min-h-10" />
          <Text
            text={t('message')}
            className="flex text-center text-14 text-balance"
          />
        </div>

        <RoundedButton
          testId="acceptButton"
          text={t('accept')}
          onClick={() => setAcknowledged(true)}
        />
      </div>
    </ConditionalRenderer>
  );
}
