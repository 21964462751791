import { ICourse } from '../../models/Course';
import CourseCard from './CourseCard';

interface CourseItemProps {
  course: ICourse;
  isActive: boolean;
}

export default function CourseItem({ course, isActive }: CourseItemProps) {
  return (
    <CourseCard
      isActive={isActive}
      bannerImage={course.bannerImg || ''}
      name={course.name}
    />
  );
}
