import React from 'react';
import { Avatar, AvatarSizeType } from './Avatar';
import Badge from './Badge';
import User from '../../../models/User';
import ConditionalRenderer from '../ConditionalRenderer';

interface UserAvatarProps
  extends React.DetailedHTMLProps<
    React.AnchorHTMLAttributes<HTMLAnchorElement>,
    HTMLAnchorElement
  > {
  testId?: string;
  user?: User;
  className?: string;
  size?: AvatarSizeType;
  onClick?: () => void;
}

export const AvatarBadge = (props: UserAvatarProps) => {
  const { testId, user, className, size, onClick } = props;

  const isNotCompletedProfile = [
    user?.profile.bannerUrl !== null,
    user?.profile.bio !== null && user?.profile.bio !== '',
    user?.profile.socialMedia?.length !== 0,
  ];

  const notDone = isNotCompletedProfile.filter(notDone => !notDone).length;

  return (
    <Badge
      position="-right-1 -bottom-1"
      testId="profileAvatarAvatarBadge"
      count={notDone !== 0 ? notDone : undefined}
    >
      <ConditionalRenderer condition={user}>
        {user && (
          <Avatar
            testId={testId}
            url={`/profile/${user.username}`}
            size={size}
            userId={user.id}
            onClick={onClick}
            className={className}
          />
        )}
      </ConditionalRenderer>
    </Badge>
  );
};
