import React, { ReactNode, useState } from 'react';
import Text from '@/components/common/dataDisplay/Text';
import { ChevronDownIcon } from '@heroicons/react/outline';
import Skeleton from '@/components/common/Skeleton';
import AccordionContainer from '@/components/common/cards/AccordionContainer';
import ConditionalRenderer from '@/components/common/ConditionalRenderer';

type DivProps = React.DetailedHTMLProps<
  React.HTMLAttributes<HTMLDivElement>,
  HTMLDivElement
>;

interface SubmissionContainerProps extends DivProps {
  studentName: string;
  collapseContent?: ReactNode;
  isLoading?: boolean;
}

function SubmissionContainer({
  children,
  studentName,
  collapseContent,
  isLoading,
  className,
  ...props
}: SubmissionContainerProps) {
  const [open, setOpen] = useState<boolean>(false);

  if (isLoading) {
    return <Skeleton className="w-full rounded-lg h-14 bg-primary-content" />;
  }

  return (
    <div
      {...props}
      className="w-full shadow-default shadow-primary-content rounded-lg border
      border-primary-content flex flex-col px-5 py-1.5 max-h-full"
    >
      <div
        className={`min-h-full p-0 w-full flex flex-col gap-2.5 sm:flex-row sm:justify-between ${
          className || ''
        }`}
      >
        <div
          data-testid="submittionContainer"
          className="flex items-center gap-5 cursor-pointer"
          onClick={() => setOpen(prev => !prev)}
        >
          <ConditionalRenderer condition={collapseContent}>
            <ChevronDownIcon
              className={`w-4 h-4 duration-150 transition-all ease-in-out ${
                open ? 'rotate-180' : ''
              }`}
            />
          </ConditionalRenderer>

          <Text
            text={studentName}
            format="rubik-400"
            className="text-base-content"
          />
        </div>
        {children}
      </div>

      <ConditionalRenderer condition={collapseContent}>
        <AccordionContainer animate={open ? 'open' : 'closed'}>
          {collapseContent}
        </AccordionContainer>
      </ConditionalRenderer>
    </div>
  );
}

export default SubmissionContainer;
