import ActivityPreview from '@/components/activities/ActivityPreview';
import ConditionalRenderer from '@/components/common/ConditionalRenderer';
import IconButton from '@/components/common/buttons/IconButton';
import MainButton from '@/components/common/buttons/MainButton';
import Text from '@/components/common/dataDisplay/Text';
import { useBankFilters } from '@/data/context/BankFiltersContext';
import useAuth from '@/data/hook/useAuth';
import useToggle from '@/data/hook/useToggle';
import { updateActivityInBank } from '@/data/services/activityServices';
import { isSuperAdmin } from '@/functions/auth';
import Activity from '@/models/Activity';
import { activityTypeOptions } from '@/utils/activityTypeOptions';
import { fadeIn } from '@/utils/animations/commom';
import { EyeIcon, SaveIcon } from '@heroicons/react/outline';
import { motion } from 'framer-motion';
import { useTranslation } from 'react-i18next';
import Modal from '../../Modal';
import { useItems } from '../ListBankCards.hooks';
import { ResumeField } from '../ResumeField';
import { ExpansibleCardProps } from './ExpansibleCardProps';

export default function ExpansibleActivityCard({
  item: activity,
  used,
  onUse,
  loading,
}: ExpansibleCardProps<Activity>) {
  const { t } = useTranslation('translation', {
    keyPrefix: 'modalBank.useButton',
  });

  return (
    <div className="mt-4 flex flex-col gap-4">
      <ResumeActivity activity={activity} />
      <div className="flex gap-4 items-center flex-wrap">
        <ConditionalRenderer condition={!!onUse && !used}>
          <MainButton
            loading={loading}
            text={t('activities')}
            onClick={() => onUse?.(activity)}
            icon={<SaveIcon className="w-4" />}
          />
        </ConditionalRenderer>
        <ConditionalRenderer condition={loading}>
          <motion.div {...fadeIn}>
            <Text
              text={t('addingActivity')}
              format="rubik-500"
              className="text-primary animate-bounce"
            />
          </motion.div>
        </ConditionalRenderer>
        <ActivityPublicChange activity={activity} />
      </div>
    </div>
  );
}

type PublicChangeProps = {
  activity: Activity;
};
const ActivityPublicChange = ({ activity }: PublicChangeProps) => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'lessonForm',
  });
  const { user } = useAuth();
  const { filters } = useBankFilters();
  const { invalidate: lessonQueryInvalidate } = useItems(
    'activities',
    filters,
    {
      enabled: false,
    },
  );

  const isSuper = isSuperAdmin(user?.userType);

  const onSetPublic = async (isPublicInBank: boolean) => {
    await updateActivityInBank({
      activityId: activity.id,
      bank: { isPublicInBank, inBank: activity.inBank },
    });
    await lessonQueryInvalidate();
  };

  return (
    <ConditionalRenderer condition={isSuper && activity.inBank}>
      <div className="flex gap-2 items-center justify-start">
        <input
          type="checkbox"
          className="toggle toggle-sm toggle-primary"
          checked={activity.isPublicInBank}
          onChange={e => onSetPublic(e.target.checked)}
        />
        <Text text={t('isPublicInBank')} className="flex" />
      </div>
    </ConditionalRenderer>
  );
};

type ResumeActivityProps = {
  activity: Activity;
};
function ResumeActivity({ activity }: ResumeActivityProps) {
  const { t: tActivityTypes } = useTranslation('translation', {
    keyPrefix: 'activity.manageActivity.activityTypes',
  });
  const { t: tFields } = useTranslation('translation', {
    keyPrefix: 'modalBank.expansibleContent.activity',
  });
  const { isOpen, open, close } = useToggle();

  const activityFields = activity.questions.map(q => {
    const activityIcon = activityTypeOptions.find(item => item.type === q.type);
    const { icon: Icon } = activityIcon || {};
    return (
      <div className="flex gap-4" key={JSON.stringify({ ...q, ...activity })}>
        {Icon && <Icon className="w-5 h-5" />}
        <Text
          format="rubik-500"
          text={tActivityTypes(`${activityIcon?.type}`)}
        />
      </div>
    );
  });

  return (
    <div className="flex flex-col gap-4">
      <ResumeField label={tFields('name')} value={activity.name} />
      <ResumeField
        icon={
          <IconButton
            icon={<EyeIcon className="w-5 h-5 text-primary" />}
            onClick={open}
          />
        }
        label={tFields('viewActivities')}
        value={activityFields}
      />
      <Modal visible={isOpen} onClose={close} className="max-w-[90vw]">
        <ConditionalRenderer condition={isOpen}>
          <ActivityPreview activity={activity} />
        </ConditionalRenderer>
      </Modal>
    </div>
  );
}
