import { ReactNode } from 'react';

import Loader from '@/pages/courses/LoadingView';
import Card from '@/components/common/Card';

interface BookContainerProps {
  children: ReactNode;
  isLoading?: boolean;
}

function ChapterContainer({ isLoading, children }: BookContainerProps) {
  return (
    <Card className="rounded-2xl block shadow-default md:w-full md:h-fit px-[8%] py-[8%] bg-no-repeat relative overflow-hidden">
      {isLoading ? <Loader /> : children}
    </Card>
  );
}

export default ChapterContainer;
