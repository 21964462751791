import { motion, Variants } from 'framer-motion';
import { useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { CourseCategoryEnum, CourseTypeEnum } from '@/models/Course';
import CoursePathList from './CoursePathList';
import UnpublishedCoursesList from '../UnpublishedCoursesList';
import { getUserSettings, updateSettings } from '@/functions/userSettings';

type TabType = CourseCategoryEnum | 'ALL';

export interface Tab {
  id: TabType;
  name: string;
}

export default function CoursesTab() {
  const { t: tCategory } = useTranslation('translation', {
    keyPrefix: 'courseCategory',
  });
  const { t } = useTranslation('translation', {
    keyPrefix: 'klassProgressPage',
  });

  const [courseParams, setCourseParams] = useSearchParams();

  const userSettings = getUserSettings();

  const courseTabId = userSettings?.courseTabId;

  const course =
    (courseTabId as TabType) ??
    (courseParams.get('course') as TabType) ??
    'ALL';

  const [selected, setSelected] = useState<TabType>(course);

  const tabs: Tab[] = ['ALL', ...Object.values(CourseCategoryEnum)].map(
    category => ({
      id: category as TabType,
      name: category === 'ALL' ? t('all') : tCategory(category),
    }),
  );

  const category =
    selected === 'ALL' ? undefined : (selected as CourseCategoryEnum);

  const onSelect = (tabId: TabType) => {
    setSelected(tabId);
    setCourseParams({ course: tabId });

    updateSettings({
      updates: { courseTabId: String(tabId) },
    });
  };

  return (
    <div className="flex flex-col">
      <Tabs tabs={tabs} selected={selected} onSelect={onSelect} />
      <TabContent category={category} />
      <UnpublishedCoursesList category={category} />
    </div>
  );
}

interface TabsProps {
  tabs: Tab[];
  selected: TabType;
  onSelect: (id: TabType) => void;
}

function Tabs({ tabs, selected, onSelect }: TabsProps) {
  return (
    <ul role="tablist" className="tabs w-fit">
      {tabs.map(tab => {
        const active =
          tab.id === selected
            ? 'tab-active bg-secondary-content border-transparent'
            : 'bg-base-100 border-secondary-content';

        return (
          <li
            role="tab"
            onClick={() => onSelect(tab.id)}
            key={tab.id}
            data-testid={tab.id.toLocaleLowerCase()}
            className={`tab text-secondary rounded-t-lg transition ease-in-out 
            duration-200 border-t border-x ${active}`}
          >
            <span className="transition ease-in-out duration-200 hover:scale-105">
              {tab.name}
            </span>
          </li>
        );
      })}
    </ul>
  );
}

function TabContent({ category }: { category?: CourseCategoryEnum }) {
  const variants: Variants = {
    initial: {
      y: 10,
      opacity: 0,
    },
    enter: {
      y: 0,
      opacity: 1,
    },
    exit: {
      y: -10,
      opacity: 0,
    },
  };

  const motionProps = {
    variants: variants,
    initial: 'initial',
    animate: 'enter',
  };

  return (
    <motion.div
      data-testid="coursesPath"
      className="w-[100%] h-[100%] flex flex-col gap-4"
      {...motionProps}
    >
      <CoursePathList
        category={category}
        type={CourseTypeEnum.CTRL_KIDS}
        first
      />
      <CoursePathList category={category} type={CourseTypeEnum.CTRL_TEENS} />
      <CoursePathList category={category} type={CourseTypeEnum.CTRL_YOUNG} />
      <CoursePathList category={category} type={CourseTypeEnum.CTRL_QUICK} />
      <CoursePathList category={category} type={CourseTypeEnum.CTRL_PRO} />
    </motion.div>
  );
}
