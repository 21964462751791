import requester from '@/axios';
import BookProgress from '@/models/BookProgress';

export async function getBookProgress(
  bookProgressId: number,
): Promise<BookProgress> {
  const { data } = await requester().get<BookProgress>(
    `book-progress/${bookProgressId}/`,
  );

  return data;
}
