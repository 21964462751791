import { useQuery } from '@tanstack/react-query';
import Skeleton from '../common/Skeleton';
import { useTranslation } from 'react-i18next';
import Text from '../common/dataDisplay/Text';
import { formatScheduledLessonText } from '@/utils/formatScheduledLessonText';
import { HomeworkStatistics } from '@/models/Statistics';
import { ClipboardListIcon } from '@heroicons/react/outline';
import Badge from '../common/dataDisplay/Badge';
import { Link } from 'react-router-dom';
import { klassesQueryKeys } from '@/data/services/querykeys';

interface PendingHomeworkItemProps {
  pendingHomework: HomeworkStatistics;
}

export default function PendingHomeworkItem({
  pendingHomework,
}: PendingHomeworkItemProps) {
  const { pendingHomeworksCount, scheduledLesson } = pendingHomework;
  const klassId = scheduledLesson.klass;
  const { t } = useTranslation('translation', {
    keyPrefix: 'teacherDashboard.pendingHomeworks',
  });
  const {
    data: klass,
    isLoading: loadingKlass,
    isError,
  } = useQuery({
    ...klassesQueryKeys.get(klassId),
  });

  if (loadingKlass) {
    return (
      <Skeleton className="bg-neutral-content opacity-25 rounded-md w-full h-4" />
    );
  } else if (isError || !klass) {
    return <Text text={t('klassNotFound')} />;
  }

  const klassUrl = `/courses/${klass.coursePathSlug}/lesson/${scheduledLesson.lesson.id}/class/${klassId}`;
  const homeworkUrl = `activities/homework`;

  return (
    <Link to={`${klassUrl}/${homeworkUrl}`} className="text-inherit">
      <div className="flex items-center justify-between">
        <Text
          format="rubik-500"
          text={formatScheduledLessonText(scheduledLesson, klass)}
        />
        <div className="flex">
          <Badge count={pendingHomeworksCount} position="-top-0.5 right-0">
            <ClipboardListIcon className="w-6" />
          </Badge>
        </div>
      </div>
    </Link>
  );
}
