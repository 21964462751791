import { useTranslation } from 'react-i18next';
import PillComponent from '../../../common/dataDisplay/PillComponent';
import Text from '../../../common/dataDisplay/Text';

interface ProgressPillProps {
  progress?: number;
}

export const ProgressPill = ({ progress = 0 }: ProgressPillProps) => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'calendar.admin.manageKlass',
  });

  return (
    <PillComponent testId="progressPill">
      <div className="flex content-center items-center h-5 gap-1">
        <Text
          text={t('progress')}
          className="text-primary self-center"
          format="rubik-500"
        />
        <Text
          text={`${Math.round(progress * 100)}%`}
          className="text-base-content leading-none align-middle items-center self-center"
          format="rubik-400"
        />
      </div>
    </PillComponent>
  );
};
