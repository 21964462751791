import React from 'react';

interface BodyTextProps {
  text: string | undefined;
  className?: string;
  weight?: '600' | '500';
  id?: string;
}

export default function BodyText(props: BodyTextProps) {
  return (
    <p
      id={props.id}
      className={`font-rubik font-${props.weight || 'body_text'} text-16 ${
        props.className
      }`}
    >
      {props.text}
    </p>
  );
}
