import { ActivityMode } from '@/models/ActivityProgress';
import {
  ColumnDND,
  ColumnProgress,
  DndOrdenationActivityProgress,
} from '../../../../../../models/DNDOrdenation';
import ActivityHeading from '../../ActivityHeading';
import { useTranslation } from 'react-i18next';
import DNDOrdenationColumnProgress from './DNDOrdenationColumnProgress';
import { Fragment } from 'react';

interface DNDOrdenationProgressColumnListProps {
  dndOrdenationActivityProgress: DndOrdenationActivityProgress;
  activityMode: ActivityMode;
  setHasChange(hasChange: boolean): Promise<void> | void;
  answerColumns?: ColumnDND[];
  attemptElement?: any;
}

export default function DNDOrdenationColumnProgressList({
  dndOrdenationActivityProgress,
  activityMode,
  setHasChange,
  answerColumns,
  attemptElement,
}: DNDOrdenationProgressColumnListProps) {
  const {
    dndOrdenationActivity: { title, subtitle },
    columnsProgress,
  } = dndOrdenationActivityProgress;

  const { t } = useTranslation('translation', {
    keyPrefix: 'lesson.activity.dragAndDropOrdenation',
  });

  switch (activityMode) {
    case 'activity': {
      return (
        <div className="flex flex-col items-center gap-8">
          <ActivityHeading
            heading={title}
            content={subtitle}
            subheading={t('instruction')}
          />

          <div
            className={`grid w-full gap-10 place-items-center max-w-[80%] ${
              columnsProgress.length > 1
                ? 'grid-cols-1 lg:grid-cols-2'
                : 'lg:max-w-[50%]'
            }`}
          >
            {columnsProgress.map(dndOrdenationColumnProgress => {
              return (
                <DNDOrdenationColumnProgress
                  key={dndOrdenationColumnProgress.id}
                  dndOrdenationColumnProgress={dndOrdenationColumnProgress}
                  activityMode={activityMode}
                  setHasChange={setHasChange}
                />
              );
            })}
          </div>
        </div>
      );
    }

    case 'review':
    case 'answerkey': {
      const attemptProgress =
        attemptElement && 'answers' in attemptElement
          ? attemptElement.answers
          : undefined;

      if (attemptProgress) {
        return (
          <div className="flex flex-col items-center gap-8">
            <ActivityHeading
              heading={title}
              content={subtitle}
              subheading={t('instruction')}
            />

            <div
              className={`grid w-full gap-10 place-items-center max-w-[80%] ${
                columnsProgress.length > 1
                  ? 'grid-cols-1 lg:grid-cols-2'
                  : 'lg:max-w-[50%]'
              }`}
            >
              {attemptProgress.columnsProgress?.map(
                (attemptDndOrdenationColumnProgress: ColumnProgress) => {
                  const findDndColumnProgress = columnsProgress.find(
                    ({ id }) => attemptDndOrdenationColumnProgress.id === id,
                  );

                  const findDndAnswerColumn = answerColumns?.find(
                    ({ id }) =>
                      id === attemptDndOrdenationColumnProgress.column.id,
                  );

                  return (
                    <DNDOrdenationColumnProgress
                      key={attemptDndOrdenationColumnProgress.id}
                      dndOrdenationColumnProgress={
                        attemptDndOrdenationColumnProgress
                      }
                      activityMode={activityMode}
                      setHasChange={setHasChange}
                      columnProgress={findDndColumnProgress}
                      answerColumn={findDndAnswerColumn}
                    />
                  );
                },
              )}
            </div>
          </div>
        );
      }

      return <Fragment />;
    }

    default:
      return <Fragment />;
  }
}
