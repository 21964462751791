import requester from '@/axios';
import { VersioningStatusEnum } from '@/enums/VersioningStatus';
import CoursePath, {
  CourseCategoryEnum,
  CourseTypeEnum,
  ICoursePath,
} from '@/models/Course';
import JsonApiResponse from '@/models/JsonApiResponse';
import { BaseFilters } from '@/models/Service';

export interface ListCoursePathFilters extends BaseFilters {
  category?: CourseCategoryEnum;
  courseType?: CourseTypeEnum;
  status?: VersioningStatusEnum[];
  uniqueCourse?: boolean;
  slug?: string;
  name?: string;
  course?: number;
}

export const listCoursesPath = async (params: ListCoursePathFilters = {}) => {
  const { data } = await requester().get<JsonApiResponse<ICoursePath>>(
    'courses/',
    {
      params,
    },
  );

  return data;
};

export const getCoursePath = async (slugCourseName?: string) => {
  const { data } = await requester().get<ICoursePath>(
    `courses/${slugCourseName}/`,
  );

  return data;
};

export type UpdateCoursePath = Partial<Pick<CoursePath, 'status' | 'isbnCode'>>;

export const updateCoursePath = async (
  slug: string,
  changes: UpdateCoursePath,
) => {
  const { data: coursePath } = await requester().patch<ICoursePath>(
    `courses/${slug}/`,
    changes,
  );
  return coursePath;
};
