import { EyeIcon, PencilIcon } from '@heroicons/react/outline';
import React, { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import useCourseEditing from '@/data/hook/useCourseEditing';
import CoursePath, { ICoursePath } from '@/models/Course';
import { ApiError } from '@/models/Errors';
import { UserTypeEnum } from '@/models/User';
import ComponentGuard from '@/components/common/ComponentGuard';
import { BranchIcon, LoadingIcon } from '@/components/icons';
import IconButton from '@/components/common/buttons/IconButton';
import MainButtonDisplay from '@/components/common/dataDisplay/MainButtonDisplay';
import handleButtonContent from '@/functions/handleCourseStatusIcon';
import EnableEditiModal from './EnableEditModal';
import CourseCard from '@/components/courses/CourseCard';
import { Tooltip } from '@/components/common/dataDisplay/Tooltip';
import alert from '@/utils/UseAlert';
import { VersioningStatusEnum } from '@/enums/VersioningStatus';

interface AdminListCoursesItemProps extends ICoursePath {
  isCoursePage?: boolean;
  componentId?: string;
  isDraft?: boolean;
}

interface ActionCourseIconProps {
  Icon: React.FC<{ className: string }>;
  tooltipText: string;
  href?: string;
  onClick?: () => void;
  isLoading?: boolean;
  disable?: boolean;
}

type isActionLoadingType = 'none' | 'version' | 'edit';

export default function CoursePathItem(props: AdminListCoursesItemProps) {
  const { slug, status, version, isDraft } = props;
  const [betaModal, setBetaModal] = useState(false);
  const { allowEditingMode, allowVersioningMode } = useCourseEditing();
  const [isActionLoading, setIsActionLoading] =
    useState<isActionLoadingType>('none');
  const navigate = useNavigate();
  const { t } = useTranslation('translation', {
    keyPrefix: 'coursesView.admin',
  });
  const { t: tCategory } = useTranslation('translation', {
    keyPrefix: 'courseCategory',
  });

  const statusCourseButton = handleButtonContent(status, version, 'w-6 h-6');
  const iconContainerRef = useRef<HTMLDivElement | null>(null);
  function ActionCourseIcon(props: ActionCourseIconProps) {
    const { Icon, href, tooltipText, disable, onClick, isLoading } = props;

    function handleOnClickAction() {
      if (onClick) {
        return onClick;
      } else {
        return () => navigate(href || '/');
      }
    }

    const iconHoverClassName =
      'hover:text-primary transition duration-150 ease-in-out';

    if (isLoading) {
      return <LoadingIcon className="w-5 h-5" />;
    }

    return (
      <Tooltip
        text={tooltipText}
        className={`${disable && 'pointer-events-none text-neutral/50'}`}
      >
        <IconButton
          testId="ActionCourseIcon"
          onClick={handleOnClickAction()}
          icon={<Icon className={`w-5 h-5 ${iconHoverClassName}`} />}
        />
      </Tooltip>
    );
  }

  async function handleDraftMode() {
    if (status === VersioningStatusEnum.BETA) {
      setBetaModal(true);
    } else if (status === VersioningStatusEnum.EDITING) {
      setIsActionLoading('edit');
      await handleEditingMode();
    } else {
      await handleVersioningMode(true);
    }
    setIsActionLoading('none');
  }

  async function handleEditingMode() {
    try {
      setIsActionLoading('edit');
      await allowEditingMode(props as CoursePath);
    } catch (error: any) {
      const api = new ApiError(error);
      alert.error(api.getErrorMessage());
    } finally {
      setIsActionLoading('none');
    }
  }
  async function handleVersioningMode(isDraft = false) {
    try {
      setIsActionLoading(isDraft ? 'edit' : 'version');
      await allowVersioningMode(props as CoursePath, version <= 1.0);
    } catch (error: any) {
      const api = new ApiError(error);
      alert.error(api.getErrorMessage());
    } finally {
      setIsActionLoading('none');
    }
  }

  const onClick = (e?: React.MouseEvent<HTMLAnchorElement>) => {
    const isIconContainer =
      iconContainerRef.current &&
      iconContainerRef.current.contains(e?.target as HTMLElement);
    if (isIconContainer) {
      e?.preventDefault();
    }
  };

  return (
    <React.Fragment>
      <CourseCard
        isActive
        {...{ slug, version, onClick }}
        bannerImage={props.course.bannerImg || ''}
        href={`/courses/${slug}`}
        name={`${props.course.name} (${tCategory(props.course.category)})`}
      >
        <ComponentGuard roles={[UserTypeEnum.SUPER_ADMIN]}>
          <div className="absolute bottom-1.5 right-1.5 flex gap-2.5 items-center">
            {isDraft && (
              <MainButtonDisplay
                data-testid="draftFlag"
                color="custom"
                className="bg-secondary-content text-secondary px-2.5 py-1"
                text={statusCourseButton.text}
                icon={statusCourseButton.icon}
              />
            )}
            <div
              className="hidden rounded-md p-1.5 text-primary bg-primary-content lg:flex gap-1.5 icon-container w-fit h-fit"
              ref={iconContainerRef}
            >
              {!isDraft ? (
                <React.Fragment>
                  <ActionCourseIcon
                    Icon={EyeIcon}
                    tooltipText={t('viewCourse')}
                    href={`/courses/${slug}`}
                    disable={isActionLoading !== 'none'}
                  />
                  <ActionCourseIcon
                    Icon={BranchIcon}
                    onClick={() => handleVersioningMode(false)}
                    isLoading={isActionLoading === 'version'}
                    disable={isActionLoading !== 'none'}
                    tooltipText={t('newVersion')}
                  />
                  <ActionCourseIcon
                    onClick={handleEditingMode}
                    Icon={PencilIcon}
                    tooltipText={t('editCourse')}
                    isLoading={isActionLoading === 'edit'}
                    disable={isActionLoading !== 'none'}
                  />
                </React.Fragment>
              ) : (
                <ActionCourseIcon
                  onClick={handleDraftMode}
                  Icon={PencilIcon}
                  tooltipText={t('editCourse')}
                  isLoading={isActionLoading === 'edit'}
                  disable={isActionLoading !== 'none'}
                />
              )}
            </div>
          </div>
        </ComponentGuard>
      </CourseCard>
      <EnableEditiModal
        course={props as CoursePath}
        onClose={() => setBetaModal(false)}
        visible={betaModal}
      />
    </React.Fragment>
  );
}
