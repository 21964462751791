import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { CheckIcon, ClockIcon, XIcon } from '@heroicons/react/outline';

import { EnrollmentStatusEnum } from '@/models/Enrollment';
import Text from '../common/dataDisplay/Text';
import { HistoricTimeline } from './HistoricTimeline';
import Skeleton from '../common/Skeleton';
import useInfiniteService from '@/data/hook/useInfiniteService';
import { simplifiedCourseProgressesQueryKeys } from '@/data/services/querykeys';

export const HistoricPanel = ({ studentId }: { studentId: number }) => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'studentHistoric',
  });

  const { results: progresses, isFetching: isLoading } = useInfiniteService({
    enabled: !!studentId,
    ...simplifiedCourseProgressesQueryKeys.list(studentId)._ctx.infinity,
  });

  const progress = progresses.at(0);

  const [tabId, setTabId] = useState<number>();

  useEffect(() => {
    if (!tabId) setTabId(progress?.coursePath?.course?.id);
  }, [progress?.coursePath?.course?.id, tabId]);

  const tabs = progresses.map(progress => ({
    id: progress.coursePath.course.id,
    abbreviation: progress.coursePath.course.abbreviation,
    enrollmentStatus: progress.enrollmentStatus,
  }));

  if (isLoading) {
    return (
      <div className="w-full">
        <Skeleton className="bg-primary-content rounded-t-lg h-8 w-24" />
        <Skeleton className="bg-primary-content rounded-b-lg rounded-tr-lg w-full h-32" />
      </div>
    );
  }

  if (progresses.length)
    return (
      <div className="w-full flex flex-col gap-4">
        <div role="tablist" className="tabs w-fit">
          {tabs.map(tab => (
            <div
              role="tab"
              key={tab.id}
              onClick={() => setTabId(tab.id)}
              className={`tab text-primary flex gap-2 rounded-t-lg transition ease-in-out 
            duration-200 border-t border-x border-primary-content ${
              tabId === tab.id
                ? 'tab-active bg-base-100 font-500'
                : 'bg-primary-content border-b-primary-content'
            }`}
            >
              <RenderHistoryStatusIcon
                iconClassName="w-5 h-5 text-primary"
                status={tab.enrollmentStatus}
              />
              <Text className="uppercase" text={tab.abbreviation} />
            </div>
          ))}
        </div>

        <HistoricTimeline courseId={tabId} studentId={studentId} />
      </div>
    );
  return <Text text={t('noData')} />;
};

export const RenderHistoryStatusIcon = ({
  status,
  iconClassName,
}: {
  status: EnrollmentStatusEnum;
  iconClassName: string;
}) => {
  switch (status) {
    case EnrollmentStatusEnum.CANCELED:
    case EnrollmentStatusEnum.COURSE_TRANSFERRED:
      return <XIcon className={iconClassName} />;
    case EnrollmentStatusEnum.CONCLUDED:
      return <CheckIcon className={iconClassName} />;
    default:
      return <ClockIcon className={iconClassName} />;
  }
};
