export const panelAnimation = {
  initial: {
    y: -45,
  },
  animate: {
    y: 0,
  },
  exit: {
    y: -45,
    height: 0,
    opacity: 0,
  },
};
