import { Player } from '@lottiefiles/react-lottie-player';
import { twMerge } from 'tailwind-merge';

export default function LoadingView({
  className,
  classNameContent,
}: {
  className?: string;
  classNameContent?: string;
}) {
  return (
    <div
      className={twMerge(
        'flex justify-center items-center h-screen',
        classNameContent,
      )}
    >
      <Player
        className={className || 'w-52'}
        autoplay
        loop
        src="/images/loading-rocket.json"
      />
    </div>
  );
}
