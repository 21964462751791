import { StudentLessonRating } from '@/models/LessonRating';
import Text from '../dataDisplay/Text';
import ConditionalRenderer from '../ConditionalRenderer';
import FeedbackTag from './FeedbackTag';
import { Fragment, useState } from 'react';
import MainButton from '../buttons/MainButton';
import { ListLessonRatingParams } from '@/data/services/lessonRatingServices';
import { useQuery } from '@tanstack/react-query';
import FeedbackCard from './FeedbackCard';
import { ChevronDownIcon, ChevronUpIcon } from '@heroicons/react/outline';
import { useTranslation } from 'react-i18next';
import { LoadingIcon } from '@/components/icons';
import InfinityList from '../InfinityList';
import ToggleButtonGroup, { ToggleButton } from '../dataInput/ToggleGroup';
import {
  studentLessonsRatingAvaragesQueryKeys,
  studentLessonsRatingQueryKeys,
} from '@/data/services/querykeys';
import useInfiniteService from '@/data/hook/useInfiniteService';

export default function FeedbackHeader({
  title,
  isForceOpen,
  filters = {},
  hideIfEmpty,
  testId,
}: {
  title: string;
  isForceOpen?: boolean;
  filters?: ListLessonRatingParams;
  hideIfEmpty?: boolean;
  testId?: string;
}) {
  const { t } = useTranslation('translation', {
    keyPrefix: 'feedbackPage.header',
  });
  type FeedbackRatingValue = undefined | 1 | 2 | 3;
  const [isOpen, setIsOpen] = useState<boolean>(isForceOpen ?? false);
  const [isRecent, setIsRecent] = useState(true);
  const [rating, setRating] = useState<FeedbackRatingValue>(undefined);

  const buttons: ToggleButton<FeedbackRatingValue>[] = [
    {
      text: t('all'),
      value: undefined,
    },
    {
      text: t('positive'),
      value: 3,
    },
    {
      text: t('neutral'),
      value: 2,
    },
    {
      text: t('negative'),
      value: 1,
    },
  ];

  const { data: average, isLoading: isLoadingAverage } = useQuery({
    ...studentLessonsRatingAvaragesQueryKeys.get(filters),
  });

  const {
    results: ratingList,
    hasNextPage,
    isLoading: isLoadingList,
    isFetchingNextPage,
    fetchNextPage,
  } = useInfiniteService({
    enabled: isOpen,
    ...studentLessonsRatingQueryKeys.list({
      ...filters,
      rating: rating ? [rating] : undefined,
      ordering: isRecent ? '-updated_at' : 'updated_at',
    })._ctx.infinity,
  });

  if (hideIfEmpty && !average?.ratingCount) {
    return <Fragment />;
  }
  return (
    <div
      data-testid={testId}
      className="bg-base-100 rounded-lg flex flex-col w-full p-2.5 gap-3.5 shadow-default"
    >
      <div className="flex flex-wrap gap-x-3.5 gap-y-1.5 items-center">
        <Text
          format="rubik-500"
          className="uppercase text-20 text-primary"
          text={title}
        />
        <ConditionalRenderer
          condition={isLoadingAverage || !!average?.ratingCount}
          fallback={<div>{t('notFound')}</div>}
        >
          <div className="flex flex-wrap gap-3 5-items-center">
            <FeedbackTag
              text={t('average')}
              average={
                average && (average.lessonsAverage + average.teacherAverage) / 2
              }
              isLoading={isLoadingAverage}
              showCount
              count={average?.ratingCount}
            />
            <FeedbackTag
              text={t('lessonRating')}
              average={average?.lessonsAverage}
              count={average?.ratingCount}
              isLoading={isLoadingAverage}
            />
            <FeedbackTag
              text={t('teacherRating')}
              average={average?.teacherAverage}
              count={average?.ratingCount}
              isLoading={isLoadingAverage}
            />
          </div>
        </ConditionalRenderer>
      </div>
      <ConditionalRenderer
        condition={!isLoadingAverage && !!average?.ratingCount}
      >
        <MainButton
          text={isOpen ? t('closeFeedback') : t('loadFeedback')}
          color="custom"
          dataTestId="openFeedbackHeader"
          className="uppercase text-primary font-500"
          icon={isOpen ? <ChevronUpIcon /> : <ChevronDownIcon />}
          onClick={() => setIsOpen(!isOpen)}
        />
        <ConditionalRenderer condition={isOpen}>
          <div className="self-end w-fit flex gap-3 flex-wrap">
            <ToggleButtonGroup
              value={rating}
              onChange={setRating}
              buttons={buttons}
              size="small"
              exclusive
            />
            <MainButton
              text={isRecent ? t('newest') : t('oldest')}
              color="custom"
              dataTestId="orderingButton"
              className="lowercase self-end flex flex-row-reverse gap-1"
              icon={isRecent ? <ChevronUpIcon /> : <ChevronDownIcon />}
              onClick={() => setIsRecent(!isRecent)}
            />
          </div>
          <StudentFeedbackList
            fetchNextPage={fetchNextPage}
            hasNextPage={hasNextPage}
            isFetchingNextPage={isFetchingNextPage}
            ratingList={ratingList}
            isLoading={isLoadingList}
          />
        </ConditionalRenderer>
      </ConditionalRenderer>
    </div>
  );
}

function StudentFeedbackList({
  fetchNextPage,
  hasNextPage,
  isFetchingNextPage,
  ratingList,
  isLoading,
}: {
  fetchNextPage(): void;
  hasNextPage?: boolean;
  isFetchingNextPage?: boolean;
  isLoading?: boolean;
  ratingList?: StudentLessonRating[];
}) {
  const { t } = useTranslation('translation', {
    keyPrefix: 'feedbackPage.header',
  });
  if (isLoading) {
    return (
      <div className="h-20 w-full flex items-center justify-center">
        <LoadingIcon className="w-10 h-10 text-primary" />
      </div>
    );
  }
  if (!isLoading && !ratingList?.length) {
    return <div>{t('notFound')}</div>;
  }

  return (
    <InfinityList
      onReachEnd={fetchNextPage}
      hasNextPage={hasNextPage}
      isFetchingNextPage={isFetchingNextPage}
      className="gap-4"
    >
      {ratingList?.map(rating => (
        <FeedbackCard key={rating.id} rating={rating} />
      ))}
    </InfinityList>
  );
}
