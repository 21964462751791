import { useMutation } from '@tanstack/react-query';

import { REQUEST_STALE_TIME_IN_MS } from '@/constants';
import useListService from '@/data/hook/useListService';
import {
  createStudentReport,
  updateStudentReport,
} from '@/data/services/scheduledLessonReportServices';
import alert from '../../utils/UseAlert';
import { getErrorMessage } from '../../utils/getErrorMessage';
import { scheduledLessonReportsQueryKeys } from '@/data/services/querykeys';
import { useTranslation } from 'react-i18next';

type useStudentReportProps = {
  studentId?: number;
  scheduledLessonId?: number;
};

export default function useStudentReport({
  studentId,
  scheduledLessonId,
}: useStudentReportProps = {}) {
  const { t } = useTranslation('translation', {
    keyPrefix: 'common',
  });

  const {
    results: reports,
    isInitialLoading: loadingReport,
    invalidate: invalidateReports,
    error: reportError,
  } = useListService({
    ...scheduledLessonReportsQueryKeys.list({
      scheduledLesson: scheduledLessonId,
      student: studentId,
    }),
    enabled: !!scheduledLessonId && !!studentId,
    staleTime: REQUEST_STALE_TIME_IN_MS,
  });

  const report = reports.at(0);

  const {
    mutateAsync: create,
    isLoading: loadingCreate,
    error: createError,
  } = useMutation(createStudentReport, {
    async onSuccess() {
      await invalidateReports();
    },

    onError(error: any) {
      alert.error(getErrorMessage(error));
    },
  });

  const {
    mutateAsync: update,
    isLoading: loadingUpdate,
    error: updateError,
  } = useMutation(updateStudentReport, {
    async onSuccess() {
      alert.success(t('saved'));
      await invalidateReports();
    },

    onError(error: any) {
      alert.error(getErrorMessage(error));
    },
  });

  return {
    report,
    loadingReport,
    create,
    update,
    invalidateReports,
    loadingCreate,
    loadingUpdate,
    createError,
    updateError,
    reportError,
  };
}
