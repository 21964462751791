import ConditionalRenderer from '@/components/common/ConditionalRenderer';
import MainButton from '@/components/common/buttons/MainButton';
import Text from '@/components/common/dataDisplay/Text';
import Checkbox from '@/components/common/dataInput/Checkbox';
import TextInput from '@/components/common/dataInput/TextInput';
import ModalDisable from '@/components/common/modals/ModalDisable';
import useAuth from '@/data/hook/useAuth';
import useCourseEditing from '@/data/hook/useCourseEditing';
import {
  deleteActivity,
  updateActivityFromLesson,
  updateActivityInBank,
} from '@/data/services/activityServices';
import { activitiesQueryKeys } from '@/data/services/querykeys';
import { isSuperAdmin } from '@/functions/auth';
import Activity from '@/models/Activity';
import { Lesson } from '@/models/Lesson';
import alert from '@/utils/UseAlert';
import { buildChangedObject } from '@/utils/buildChangedObject';
import { SaveIcon, TrashIcon } from '@heroicons/react/outline';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { isEqual } from 'lodash';
import { ChangeEvent, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

interface ActivityDetailsEditProps {
  activity: Activity;
  lesson: Lesson;
  onChange(activityName: string): void;
}

type ActivityDetailsEditForm = Pick<
  Activity,
  'name' | 'inBank' | 'isPublicInBank'
>;

export default function ActivityDetailsEdit({
  activity,
  onChange,
  lesson,
}: ActivityDetailsEditProps) {
  const activityKey = activitiesQueryKeys.get(activity.id).queryKey;
  const queryClient = useQueryClient();
  const navigate = useNavigate();

  const [hasAlter, setHasAlter] = useState(false);

  const { user } = useAuth();

  const isSuper = isSuperAdmin(user?.userType);

  const { register, handleSubmit, watch } = useForm<ActivityDetailsEditForm>({
    defaultValues: activity,
  });

  const inBank = watch('inBank');
  const [isVisibleModal, setIsVisibleModal] = useState(false);

  const { id: activityId } = activity;
  const { id: lessonId } = lesson;

  const { t } = useTranslation('translation', {
    keyPrefix: 'activity.manageActivity.activityDetails',
  });
  const { allowBigChanges } = useCourseEditing();
  const { mutate: handleDelete, isLoading: isDeleting } = useMutation(
    () => deleteActivity({ activityId, lessonId }),
    {
      onError: () => {
        alert.error(t('deleteError'));
      },
      onSuccess: () => {
        queryClient.removeQueries(activityKey);
        alert.success(t('deleteSuccess'));
        navigate(-1);
      },
    },
  );

  const onUpdate = async (activityUpdated: ActivityDetailsEditForm) => {
    const parcialActivity = buildChangedObject(activity, activityUpdated);
    if (parcialActivity.inBank !== undefined) {
      await updateActivityInBank({
        activityId,
        bank: { inBank: parcialActivity.inBank },
      });
      delete parcialActivity.inBank;
    }
    await updateActivityFromLesson({ activityId, lessonId }, parcialActivity);
  };

  const { mutate: handleUpdate, isLoading: isUpdating } = useMutation(
    onUpdate,
    {
      onError: () => {
        alert.error(t('saveError'));
      },
      onSuccess: () => {
        queryClient.invalidateQueries(activityKey);
        alert.success(t('saveSuccess'));
        setHasAlter(false);
      },
    },
  );

  const onSubmit = (data: ActivityDetailsEditForm) => {
    handleUpdate(data);
  };

  useEffect(() => {
    const w = watch(data => setHasAlter(!isEqual(data, activity)));
    return () => w.unsubscribe();
  }, [watch, activity]);

  return (
    <form
      className="relative flex flex-col gap-3.5"
      onSubmit={handleSubmit(onSubmit)}
    >
      <TextInput
        label={t('name')}
        disabled={!allowBigChanges}
        testId="activityName"
        fontWeight="font-500"
        className={{ base: 'rounded-lg border-primary relative' }}
        register={register('name', {
          onChange: (e: ChangeEvent<HTMLInputElement>) => {
            onChange(e.target.value);
          },
        })}
      />

      <ConditionalRenderer condition={!activity.questions.length}>
        <Text
          text={t('noQuestionsWarning')}
          color="warning"
          className="relative"
        />
      </ConditionalRenderer>

      <ConditionalRenderer condition={allowBigChanges}>
        <Checkbox
          {...register('inBank')}
          className={{ input: 'w-5 h-5 border-2 border-primary rounded-md' }}
          label={t('inBank')}
        />
      </ConditionalRenderer>
      <ConditionalRenderer condition={allowBigChanges && inBank && isSuper}>
        <div className="flex gap-2 items-center justify-start">
          <input
            type="checkbox"
            className="toggle toggle-sm toggle-primary"
            {...register('isPublicInBank')}
          />
          <Text text={t('isPublicInBank')} className="flex" />
        </div>
      </ConditionalRenderer>

      <ConditionalRenderer condition={allowBigChanges}>
        <div className="flex justify-between w-full">
          <MainButton
            type="submit"
            disabled={!hasAlter}
            icon={<SaveIcon />}
            id="save-activity"
            text={t('save')}
            loading={isUpdating}
          />
          <MainButton
            icon={<TrashIcon />}
            type="button"
            color="warning"
            text={t('delete')}
            onClick={() => setIsVisibleModal(true)}
            loading={isDeleting}
          />
        </div>
      </ConditionalRenderer>
      <ModalDisable
        visible={isVisibleModal}
        translationString={'modalDisableActivity'}
        onClickConfirm={handleDelete}
        isRequesting={isDeleting}
        modalType="delete"
        onClickCancel={() => setIsVisibleModal(false)}
      />
    </form>
  );
}
