import {
  useRef,
  Fragment,
  PropsWithChildren,
  useEffect,
  useCallback,
} from 'react';
import Layout from '@/components/template/Layout';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { Controller, useForm } from 'react-hook-form';
import { isEqual } from 'lodash';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { PencilIcon } from '@heroicons/react/outline';
import moment from 'moment';

import NoAdminScreenForMobile from './NoAdminScreenForMobile';
import ModalWarning from '@/components/common/modals/ModalWarning';
import PageTitle from '@/components/common/PageTitle';
import ConditionalRenderer from '@/components/common/ConditionalRenderer';
import Skeleton from '@/components/common/Skeleton';
import HeadTitle from '@/components/common/HeadTitle';
import { UpdateUnit, updateUnit } from '@/data/services/unitServices';
import alert from '@/utils/UseAlert';
import useAuth from '@/data/hook/useAuth';
import InfiniteSearchInput from '@/components/common/dataInput/InfiniteSearchInput';
import { getAuthorizedUnits } from '@/utils/getAuthorizedUnits';
import { unitsQueryKeys } from '@/data/services/querykeys';
import RecessCalendar from '@/components/staff/calendar/Calendar';
import SaveCancelGroup from '@/components/common/buttons/SaveCancelGroup';
import MainButton from '@/components/common/buttons/MainButton';
import { getErrorMessage } from '@/utils/getErrorMessage';
import Unit from '@/models/Unit';
import { LoadingIcon } from '@/components/icons';
import User from '@/models/User';
import { ROUTES } from '@/utils/routes';

export default function ManageRecessCalendars() {
  const { t } = useTranslation('translation', {
    keyPrefix: 'manageRecessCalendars',
  });
  const { user } = useAuth();
  const mainUserUnitId = user?.unitsIds?.at(0) ?? 0;
  const { unitId = mainUserUnitId.toString() } = useParams();
  const [editing, setEditing] = useState<boolean>(false);

  const navigate = useNavigate();

  const hasUnitId = !isNaN(Number(unitId));

  const { data: unit, isInitialLoading: loading } = useQuery({
    ...unitsQueryKeys.get(Number(unitId)),
    enabled: hasUnitId,
  });

  const onEdit = () => setEditing(prev => !prev);

  const queryClient = useQueryClient();

  const updateData = async () =>
    await queryClient.invalidateQueries(unitsQueryKeys.get(Number(unitId)));

  if (loading)
    return (
      <BaseContainer>
        <div className="flex justify-center">
          <LoadingIcon className="text-primary-content w-[10rem]" />
        </div>
      </BaseContainer>
    );

  if (user && unit)
    return (
      <Fragment>
        <HeadTitle routeInfo={t('recessCalendar')} />
        <Layout>
          <div className="md:hidden">
            <NoAdminScreenForMobile />
          </div>
          <div className="hidden md:flex flex-col gap-2">
            <ConditionalRenderer
              condition={unit}
              fallback={
                <ConditionalRenderer
                  condition={!loading}
                  fallback={
                    <Skeleton className="bg-primary/50 h-7 w-[20rem] rounded-lg" />
                  }
                >
                  <PageTitle
                    headingText={t('manageRecessCalendars')}
                    backButton={true}
                    position="mb-0"
                  />
                </ConditionalRenderer>
              }
            >
              <Fragment>
                <HeadTitle routeInfo={`${t('recess')} ${unit.name}`} />
                <PageTitle
                  headingText={`${t('recess')} ${unit.name}`}
                  backButton={true}
                  position="mb-0"
                />
              </Fragment>
            </ConditionalRenderer>

            <ConditionalRenderer condition={!!unit}>
              <InfiniteSearchInput
                service={unitsQueryKeys.list}
                blockDeselect
                displayName={unit => unit.name}
                selectedItem={unit}
                onSelect={({ id }) =>
                  navigate(ROUTES.ADMIN.UNITS.RECESS_CALENDAR.INFO(id))
                }
                filters={{ idIn: getAuthorizedUnits(user)?.toString() }}
                input={{
                  testId: 'selectUnit',
                  className: { base: 'max-w-96' },
                }}
              />
            </ConditionalRenderer>

            <RecessCalendarForm
              key={unitId}
              user={user}
              unit={unit}
              editing={editing}
              onEdit={onEdit}
              updateData={updateData}
            />
          </div>
        </Layout>
      </Fragment>
    );

  return null;
}

function BaseContainer({ children }: PropsWithChildren) {
  const { t } = useTranslation('translation', {
    keyPrefix: 'manageRecessCalendars',
  });
  return (
    <Layout>
      <div className="md:hidden">
        <NoAdminScreenForMobile />
      </div>
      <HeadTitle routeInfo={t('recessCalendar')} />
      {children}
    </Layout>
  );
}

type RecessCalendarFormProps = {
  unit: Unit;
  user: User;
  editing?: boolean;
  onEdit: () => void;
  updateData?(): Promise<void>;
};

function RecessCalendarForm({
  unit,
  user,
  editing,
  onEdit,
  updateData,
}: RecessCalendarFormProps) {
  const [t] = useTranslation('translation', {
    keyPrefix: 'common',
  });

  const [visible, setVisible] = useState<'confirm' | 'discard'>();
  const [hasChanges, setHasChanges] = useState(false);

  type RecessCalendarFields = {
    recess: string[];
  };

  const setRecess = useCallback(
    (recess: string[]) =>
      recess.flat().map(date => moment(date).format('YYYY-MM-DD')),
    [],
  );

  const defaultValues = useRef<RecessCalendarFields>({
    recess: setRecess(unit.recess),
  });

  const { control, handleSubmit, watch } = useForm<RecessCalendarFields>({
    defaultValues: defaultValues.current,
  });

  const { mutate: update, isLoading } = useMutation(
    ({ id, changes }: { id: number; changes: UpdateUnit }) =>
      updateUnit(id, changes),
    {
      async onSuccess(data) {
        control._reset({ recess: setRecess(data.recess) });
        await updateData?.();
        setVisible(undefined);
        onEdit();
        alert.success(t('saved'));
      },

      onError(error) {
        alert.error(getErrorMessage(error));
      },
    },
  );

  const onSubmit = (changes: RecessCalendarFields) => {
    if (changes.recess.length === 0)
      return alert.warning(t('form.recess.validation'));

    if (visible) {
      const recess = changes.recess.map(date =>
        moment(date).format('YYYY-MM-DD'),
      );
      update({ id: unit.id, changes: { recess } });
    } else setVisible('confirm');
  };

  const onCancel = () => {
    onEdit();
    control._reset();
    setHasChanges(false);
  };

  useEffect(() => {
    const subscribe = watch(value =>
      setHasChanges(!isEqual(value, defaultValues.current)),
    );
    return () => {
      setHasChanges(false);
      subscribe.unsubscribe();
    };
  }, [defaultValues, watch]);

  return (
    <Fragment>
      <form onSubmit={handleSubmit(onSubmit)} className="flex flex-col gap-2">
        <div className="min-h-7 flex justify-end">
          <ConditionalRenderer
            condition={editing}
            fallback={
              <MainButton
                onClick={onCancel}
                className="text-primary self-end"
                color="custom"
                icon={<PencilIcon className="w-5 h-5 text-primary" />}
                text={t('edit')}
              />
            }
          >
            <div className="flex w-full justify-end items-center gap-x-5">
              <SaveCancelGroup
                className="self-start"
                save={{
                  type: 'submit',
                  testId: 'saveButton',
                  disabled: !hasChanges,
                }}
                cancel={{
                  testId: 'cancelButton',
                  onClick: onCancel,
                }}
              />
            </div>
          </ConditionalRenderer>
        </div>

        <Controller
          control={control}
          name="recess"
          render={({ field: { onChange, value } }) => (
            <RecessCalendar
              disabled={!editing}
              onDatesChange={onChange}
              value={value}
              timezone={unit.timezone}
            />
          )}
        />
      </form>
      <ModalWarning
        visible={visible === 'confirm'}
        translationString="modalSaveChanges"
        onClickConfirm={() => onSubmit({ recess: watch('recess') })}
        onClickCancel={() => setVisible(undefined)}
        isLoading={isLoading}
      />

      <ModalWarning
        visible={visible === 'discard'}
        translationString="modalDiscardChanges"
        onClickConfirm={onCancel}
        onClickCancel={() => setVisible(undefined)}
      />
    </Fragment>
  );
}
