import { useTranslation } from 'react-i18next';

import { KlassAssessment } from '@/models/KlassAssessment';
import { EditAssessment } from '../KlassSheet';
import { SheetTab } from '@/models/SheetTab';
import AssessmentHeader from '@/components/common/assessment/AssessmentHeader';
import useAuth from '@/data/hook/useAuth';
import { ChevronDownIcon, PencilIcon } from '@heroicons/react/outline';
import ConditionalRenderer from '@/components/common/ConditionalRenderer';
import { UserTypeEnum } from '@/models/User';
import MainButton from '@/components/common/buttons/MainButton';
import ComponentGuard from '@/components/common/ComponentGuard';
import Text from '@/components/common/dataDisplay/Text';
import useToggle from '@/data/hook/useToggle';
import AccordionContainer from '@/components/common/cards/AccordionContainer';
import BehaviorAssessmentPreview from '../../../../common/assessment/BehaviorAssessmentPreview';
import BehaviorAssessmentForm from '../KlassBehaviorAssessmentForm/BehaviorAssessmentForm';

type AssessmentProps = {
  assessment: KlassAssessment;
  editAssessment({ assessment, tab }: EditAssessment): void;
  tab: SheetTab;
};

export default function Assessment(props: AssessmentProps) {
  return (
    <li className="flex flex-col w-full gap-2" data-testid="assessmentItem">
      <CommentAssessmentItem {...props} />
      <BehaviorAssessmentItem {...props} />
    </li>
  );
}

function CommentAssessmentItem({
  assessment,
  editAssessment,
}: AssessmentProps) {
  const { user } = useAuth();

  const { t } = useTranslation('translation', {
    keyPrefix: 'common',
  });

  const { author, commentAssessment, behaviorAssessment, createdAt } =
    assessment;

  return (
    <ConditionalRenderer condition={commentAssessment && !behaviorAssessment}>
      <hr className="h-px border-primary/40" />
      <div className="flex flex-col gap-2">
        <AssessmentHeader
          type="comment"
          createdAt={createdAt}
          author={author}
        />
        <Text text={commentAssessment?.comment} />
        <ComponentGuard roles={[UserTypeEnum.TEACHER, UserTypeEnum.UNIT_ADMIN]}>
          <ConditionalRenderer condition={user?.id === author.id}>
            <MainButton
              dataTestId="editCommentButton"
              text={t('edit')}
              onClick={() => editAssessment({ assessment, tab: 'comment' })}
              icon={<PencilIcon className="w-4" />}
            />
          </ConditionalRenderer>
        </ComponentGuard>
      </div>
    </ConditionalRenderer>
  );
}

function BehaviorAssessmentItem({
  assessment,
  editAssessment,
  tab,
}: AssessmentProps) {
  const { user } = useAuth();

  const { t: tCommom } = useTranslation('translation', {
    keyPrefix: 'common',
  });

  const { t } = useTranslation('translation', {
    keyPrefix: 'pedagogicalSheet',
  });

  const { author, commentAssessment, behaviorAssessment } = assessment;

  const { isOpen, toggle } = useToggle();

  return (
    <ConditionalRenderer condition={behaviorAssessment}>
      <hr className="h-px border-primary/40" />
      <div className="flex flex-col gap-4">
        <AssessmentHeader
          type="behavior"
          createdAt={assessment.createdAt}
          author={author}
        />
        <BehaviorAssessmentPreview
          klassBehaviorAssessment={behaviorAssessment}
        />
        <ConditionalRenderer condition={!!commentAssessment?.comment}>
          <Text text={commentAssessment?.comment} />
        </ConditionalRenderer>

        <div className="flex flex-col gap-2">
          <MainButton
            className="text-primary uppercase font-500"
            icon={
              <ChevronDownIcon
                className={`w-4 text-primary transform ease-in-out 
                duration-150 stroke-[3] ${isOpen ? 'rotate-180' : ''}`}
              />
            }
            text={t('assessments.moreDetails')}
            color="custom"
            onClick={toggle}
          />

          <AccordionContainer
            initial="closed"
            animate={isOpen ? 'open' : 'closed'}
          >
            <BehaviorAssessmentForm
              klassAssessment={assessment}
              tab={tab}
              disabled
            />
          </AccordionContainer>
        </div>

        <ComponentGuard roles={[UserTypeEnum.TEACHER, UserTypeEnum.UNIT_ADMIN]}>
          <ConditionalRenderer condition={user?.id === author.id}>
            <MainButton
              dataTestId="editAssessmentButton"
              text={tCommom('edit')}
              onClick={() => editAssessment({ assessment, tab: 'behavior' })}
              icon={<PencilIcon className="w-4" />}
            />
          </ConditionalRenderer>
        </ComponentGuard>
      </div>
    </ConditionalRenderer>
  );
}
