/**
 * tranform keys from object
 * @param obj target object
 * @param transformFn function to transform key
 * @param ignoreKeys keys that your content will not be transformed
 * @returns object with keys transformed
 */

type TransformKeysOptions = {
  transformFn: (v: string) => string;
  ignoreKeys?: string[];
};
export default function transformKeys(
  obj: any,
  { transformFn, ignoreKeys = [] }: TransformKeysOptions,
): any {
  if (Array.isArray(obj)) {
    return obj.map(item => transformKeys(item, { transformFn, ignoreKeys }));
  } else if (obj !== null && typeof obj === 'object') {
    return Object.keys(obj).reduce((acc: any, key: string) => {
      const newKey = transformFn(key);
      if (ignoreKeys.includes(key)) {
        acc[newKey] = obj[key];
      } else {
        acc[newKey] = transformKeys(obj[key], { transformFn, ignoreKeys });
      }
      return acc;
    }, {});
  }
  return obj;
}
