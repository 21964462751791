import requester from '@/axios';
import JsonApiResponse from '@/models/JsonApiResponse';
import ScheduledDay from '@/models/ScheduledDay';

export async function listScheduledDays() {
  const { data } = await requester().get<JsonApiResponse<ScheduledDay>>(
    'trial-lesson/scheduled-day/',
  );

  return data;
}

type CreateScheduledDay = Omit<ScheduledDay, 'id' | 'klass'>;

export async function createScheduledDay(body: CreateScheduledDay) {
  const { data } = await requester().post('trial-lesson/scheduled-day/', body);

  return data;
}

export async function deleteScheduledDay(id: number) {
  await requester().delete(`trial-lesson/scheduled-day/${id}/`);
}
