import React from 'react';
import { Tooltip } from './dataDisplay/Tooltip';

type position =
  | 'tooltip-top'
  | 'tooltip-bottom'
  | 'tooltip-left'
  | 'tooltip-right';
interface TooltipHandlerProps {
  renderTooltip: boolean;
  children: React.ReactNode;
  tooltipMessage: string;
  position?: position;
  className?: string;
  classNameContainer?: string;
  color?:
    | 'red'
    | 'blue'
    | 'purple'
    | 'accent'
    | 'white'
    | 'yellow'
    | 'green'
    | 'gray';
}

const TooltipHandler = (props: TooltipHandlerProps) => {
  const { renderTooltip, children, tooltipMessage, color, ...rest } = props;
  if (renderTooltip) {
    return (
      <Tooltip
        options={{ placement: 'auto' }}
        text={tooltipMessage}
        color={color}
        {...rest}
      >
        {children}
      </Tooltip>
    );
  } else {
    return <React.Fragment>{children}</React.Fragment>;
  }
};

export default TooltipHandler;
