import useAuth from '../../data/hook/useAuth';
import useToggle from '../../data/hook/useToggle';
import {
  ChevronDownIcon,
  InformationCircleIcon,
} from '@heroicons/react/outline';
import Text from '../common/dataDisplay/Text';
import { useTranslation } from 'react-i18next';
import AccordionContainer from '../common/cards/AccordionContainer';
import CoursePathForm from '../admin/courses/CoursePathForm';
import CoursePath from '../../models/Course';
import ConditionalRenderer from '../common/ConditionalRenderer';
import { motion } from 'framer-motion';
import { UserTypeEnum } from '../../models/User';

interface IsbnCourseCollapseProps {
  course?: CoursePath;
  isViewMode?: boolean;
}

export default function LegalInformationCourseCollapse({
  course,
  isViewMode,
}: IsbnCourseCollapseProps) {
  const { user } = useAuth();
  const isStudent = user?.userType === UserTypeEnum.STUDENT;
  const { isOpen, toggle, close } = useToggle();
  const { t } = useTranslation('translation', {
    keyPrefix: 'courses',
  });
  return (
    <ConditionalRenderer condition={course?.isbnCode}>
      <div className="flex flex-col rounded-md">
        <button
          onClick={toggle}
          className="w-full py-1 flex items-center justify-between text-primary"
        >
          <div className="flex items-center gap-2">
            <InformationCircleIcon className="w-4" />
            <Text text={t('legalInformation')} />
          </div>

          <MotionChevronDownIcon
            animate={{
              rotate: isOpen ? 180 : 0,
            }}
            className="w-6"
          />
        </button>
        <ConditionalRenderer condition={course}>
          <AccordionContainer animate={isOpen ? 'open' : 'closed'}>
            <div className="p-5">
              {course && (
                <CoursePathForm
                  onReset={close}
                  readOnly={isStudent || isViewMode}
                  course={course}
                />
              )}
            </div>
          </AccordionContainer>
        </ConditionalRenderer>
      </div>
    </ConditionalRenderer>
  );
}

const MotionChevronDownIcon = motion(ChevronDownIcon);
