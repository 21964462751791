import { Fragment } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';
import { twMerge } from 'tailwind-merge';
import { UsersIcon } from '@heroicons/react/outline';

import UserAvatar from '@/components/store/UserAvatar';
import User, { UserStatusEnum, UserTypeEnum } from '@/models/User';
import Tag from '@/components/common/dataDisplay/Tag';
import { LoadingIcon } from '@/components/icons';
import Text from '@/components/common/dataDisplay/Text';
import Klass from '@/models/Klass';
import Skeleton from '@/components/common/Skeleton';
import ConditionalRenderer from '@/components/common/ConditionalRenderer';
import {
  avatarsQueryKeys,
  studentsQuerykeys,
  usersQueryKeys,
} from '@/data/services/querykeys';
import useListService from '@/data/hook/useListService';
import { REQUEST_STALE_TIME_IN_MS } from '@/constants';
import { ClassNameComponent } from '@/utils/ClassNameComponent';

type KlassMembersProps = {
  loading?: boolean;
  klass?: Klass;
};

export function KlassMembers({ loading, klass }: KlassMembersProps) {
  const { t } = useTranslation('translation', {
    keyPrefix: 'dashboard.dashboardClass',
  });

  if (loading)
    return (
      <Skeleton
        testId="loadingState"
        className="bg-primary-content rounded-xl h-56 w-full sm:w-96"
      />
    );

  return (
    <div data-testid="members" className="flex flex-col">
      <div className="flex px-6 py-2.5 gap-5 w-full sm:w-96 rounded-t-2xl items-center bg-accent text-base-100">
        <UsersIcon strokeWidth={2.5} className="w-4 h-4 sm:w-5 sm:h-5" />
        <Text
          format="rubik-500"
          size="text-16 sm:text-18"
          text={t('yourClass')}
          className="uppercase m-0"
        />
      </div>

      <AvatarClassroom klass={klass} />
    </div>
  );
}

type AvatarClassroomProps = {
  klass?: Klass;
};

function AvatarClassroom({ klass }: AvatarClassroomProps) {
  const { t } = useTranslation('translation', {
    keyPrefix: 'dashboard.dashboardClass',
  });

  const { results: userAvatars, isInitialLoading: loadingUserAvatars } =
    useListService({
      ...avatarsQueryKeys.list({
        klass: klass?.id,
      }),
      enabled: !!klass?.id,
      staleTime: REQUEST_STALE_TIME_IN_MS,
    });

  const { results: students } = useListService({
    ...studentsQuerykeys.list({
      klassId: klass?.id ?? 0,
      status: [UserStatusEnum.ACTIVE_ENROLLMENT],
    }),
    staleTime: REQUEST_STALE_TIME_IN_MS,
    enabled: !!klass?.id,
  });

  const { data: teacher } = useQuery({
    ...usersQueryKeys.get(klass?.teacherId ?? 0),
    enabled: !!klass?.teacherId,
    staleTime: REQUEST_STALE_TIME_IN_MS,
  });

  return (
    <div className="flex w-full sm:w-96 relative bg-accent/40 aspect-video rounded-b-2xl shadow-default">
      <img src="/images/cenario.png" alt="" className="w-full rounded-b-2xl" />

      <ConditionalRenderer
        condition={klass}
        fallback={
          <Text
            format="rubik-500"
            className="text-20 rounded-b-2xl text-primary-content p-5 text-center w-full h-auto aspect-video absolute flex place-self-end self-end bg-primary/40 justify-self-end"
            text={t('teacherKlassMembers')}
          />
        }
      >
        <Fragment>
          <ConditionalRenderer condition={loadingUserAvatars}>
            <div className="flex w-full h-full items-center justify-center absolute">
              <LoadingIcon className="w-20 h-20 text-accent/40" />
            </div>
          </ConditionalRenderer>

          <AvatarTag
            className={{ base: 'self-center top-2 absolute w-full' }}
            user={teacher}
          />

          <ConditionalRenderer condition={userAvatars.length}>
            <div className="flex flex-wrap-reverse absolute bottom-3 justify-between w-full">
              {students.map(student => (
                <AvatarTag
                  key={student.id}
                  className={{ base: 'odd:pb-4 h-fit aspect-square' }}
                  user={student}
                />
              ))}
            </div>
          </ConditionalRenderer>
        </Fragment>
      </ConditionalRenderer>
    </div>
  );
}

type AvatarTagProps = {
  user?: User;
  className?: ClassNameComponent;
};

function AvatarTag({ user, className }: AvatarTagProps) {
  if (!user) return null;

  return (
    <Link
      to={`/profile/${user.username}`}
      className={twMerge(
        'flex flex-col items-center gap-0.5 h-fit',
        className?.base,
      )}
    >
      <Tag
        text={user.firstName}
        color={user.userType === UserTypeEnum.STUDENT ? 'accent' : 'primary'}
        className="text-[0.5rem] rounded-full px-1 py-0.5"
      />
      <UserAvatar className="scale-90 xs:scale-125" userId={user.id} />
    </Link>
  );
}
