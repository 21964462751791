import handleButtonContent from '@/functions/handleCourseStatusIcon';
import MainButtonDisplay from '@/components/common/dataDisplay/MainButtonDisplay';
import { VersioningStatusEnum } from '@/enums/VersioningStatus';

interface CourseStatusProps {
  status: VersioningStatusEnum;
  version: number;
}

function CourseStatus({ status, version }: CourseStatusProps) {
  const buttonContent = handleButtonContent(status, version, 'w-6 h-6');

  return (
    <MainButtonDisplay
      data-testid="courseStatus"
      color="custom"
      className="text-primary font-500"
      size="medium"
      text={buttonContent.text}
      icon={buttonContent.icon}
    />
  );
}

export default CourseStatus;
