import React, { useState, InputHTMLAttributes } from 'react';
import { ClockIcon } from '@heroicons/react/outline';

import TimePicker, { BlockedTimes } from './TimePicker';
import ConditionalRenderer from '../ConditionalRenderer';
import { BaseInputProps } from './BaseInput';
import TextInput from './TextInput';

type TimeInputProps = Omit<InputHTMLAttributes<HTMLInputElement>, 'className'> &
  BaseInputProps & {
    placeholder?: string;

    testId?: string;
    position?: string;
    onTimeChange: (newValue: string) => void;
    beforeClose?: () => void;
    isClearAfter?: boolean;
    defaultSelected?: string;
    blockedTimes?: BlockedTimes;
  };

export default function TimeInput(props: TimeInputProps) {
  const {
    position,
    placeholder,
    onTimeChange,
    beforeClose,
    defaultSelected,
    isClearAfter = false,
    disabled,
    blockedTimes = {},
    ...rest
  } = props;

  const [isOpen, setIsOpen] = useState(false);
  const [time, setTime] = useState(defaultSelected);

  const handleChangeTimePicker = (value: string, canClean: boolean) => {
    if (canClean && isClearAfter) {
      setTime('');
      onTimeChange(value);
      return;
    }
    setTime(value);
    onTimeChange(value);
  };

  const timeValue = rest.value?.toString() || time || '';

  return (
    <div className="relative">
      <TextInput
        {...rest}
        onChange={e => handleChangeTimePicker(e.target.value, false)}
        type="text"
        color={rest.errorLabelText ? 'warning' : 'primary'}
        value={timeValue.substring(0, 5)}
        disabled={disabled}
        onClick={() => setIsOpen(!isOpen)}
        icon={
          <ConditionalRenderer condition={!disabled}>
            <ClockIcon
              data-testid="clockButton"
              onClick={() => setIsOpen(!isOpen)}
            />
          </ConditionalRenderer>
        }
      />
      {isOpen && (
        <React.Fragment>
          <TimePicker
            blockedTimes={blockedTimes}
            setIsOpen={setIsOpen}
            onChange={handleChangeTimePicker}
            className={`absolute ${position || 'top-6 right-10'} m-0 z-20`}
            beforeClose={beforeClose}
          />
          <div
            onClick={() => setIsOpen(false)}
            className="fixed w-screen top-0 left-0 h-screen z-10"
          />
        </React.Fragment>
      )}
    </div>
  );
}
