import moment from 'moment-timezone';

export const timezonedToBrowserDate = (
  date: string | Date,
  timezone: string = 'America/Sao_Paulo',
) => {
  const browserTimezoneOffset = new Date().getTimezoneOffset();
  const userTimezoneDate = moment(date).tz(timezone);

  const userOffet = userTimezoneDate.utcOffset() * -1;

  const tzDiff = browserTimezoneOffset - userOffet;

  if (tzDiff < 0) {
    return moment(date).subtract(tzDiff, 'minutes').toDate();
  }

  return moment(date).add(tzDiff, 'minutes').toDate();
};

export const formatTimezoneOffset = (timezone: string) => {
  const offset = moment().tz(timezone);
  const formatedTimezonName = timezone.replace(/.*\//, '').replace(/_/g, ' ');
  return `${formatedTimezonName} (GMT ${offset.format('Z')})`;
};

const DEFAULT_LESSONS_GAP = 60;

const getPreviousBlockedDatetime = (
  datetime: string | Date,
  timezone: string,
  duration: number = 90,
) => {
  return moment(datetime)
    .tz(timezone)
    .add(duration + DEFAULT_LESSONS_GAP, 'minutes')
    .toISOString();
};

const getNextBlockedDatetime = (
  datetime: string | Date,
  timezone: string,
  duration: number = 90,
) => {
  return moment(datetime)
    .tz(timezone)
    .subtract(duration + DEFAULT_LESSONS_GAP, 'minutes')
    .toISOString();
};

export const getBlockedTimeByType = (
  datetime: string | Date,
  type: 'previous' | 'next',
  timezone: string,
  duration: number = 90,
) => {
  const getBlockTimeFns = {
    previous: getPreviousBlockedDatetime,
    next: getNextBlockedDatetime,
  };

  const getBlockTime = getBlockTimeFns[type];

  return getBlockTime(datetime, timezone, duration);
};
