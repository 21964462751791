import { useTranslation } from 'react-i18next';
import PillComponent from '../../../common/dataDisplay/PillComponent';
import Text from '../../../common/dataDisplay/Text';
import { ModalState } from '../ModalHandler';

interface AnticipatePostponePillProps {
  state: ModalState;
  setModalState: () => void;
  isDisable: boolean;
}

export const AnticipatePostponePill = ({
  state,
  setModalState,
  isDisable,
}: AnticipatePostponePillProps) => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'calendar.admin.manageKlass',
  });

  const clickableStyle =
    'cursor-pointer hover:opacity-75 transistion transition-all duration-75 ease-in-out';

  const testIds: {
    [key in ModalState]: string | undefined;
  } = {
    ANTICIPATE: 'antecipatePill',
    POSTPONE: 'postponePill',
    CLOSED: undefined,
    KLASS_DATE: 'klassDatePill',
    KLASS_LINK: 'klassLink',
    TEACHER: undefined,
  };

  return (
    <PillComponent
      testId={testIds[state]}
      className={`cursor-pointer ease-in-out duration-150 origin-center text-[12px] md:text-[14px] ${
        isDisable ? 'disabled' : ''
      }`}
      onClick={setModalState}
    >
      <Text
        text={t(`${state.toLocaleLowerCase()}`)}
        className={`text-primary h-5 flex items-center ${clickableStyle}`}
        format="rubik-400"
      />
    </PillComponent>
  );
};
