import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { CategoryEnum, RewardEnum, Reward } from '@/models/Rewards';
import React from 'react';
import SelectInput from '../../dataInput/SelectInput';
import { Coin, Gem } from '../../../icons';
import RoundedButton from '../../buttons/RoundedButton';
import TextInput from '../../dataInput/TextInput';
import { Transaction } from '../../../../models/Transaction';
import RewardInput from '../../../teacher/RewardInput';

interface WonModeProps {
  onClickConfirm: (transition?: Transaction) => void;
  onClickCancel: (e?: any) => void;
  rewardsOptions?: Reward[];
  isWaitingTransactionRequest: boolean;
}

export const WonMode = ({
  onClickConfirm,
  onClickCancel,
  rewardsOptions,
  isWaitingTransactionRequest,
}: WonModeProps) => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'modalRewards',
  });

  const [option, setOption] = useState<CategoryEnum>(
    CategoryEnum.SELECT_THE_REASON,
  );

  const [transaction, setTransaction] = useState<Transaction>();

  const defineTransaction = (rewardOption: Reward) => {
    switch (rewardOption.category) {
      case CategoryEnum.MINOR_PROJECT:
        setTransaction({
          ...transaction,
          rewardType: RewardEnum.COIN,
          amount: rewardOption.amount,
          category: rewardOption.category,
          transactionType: 'WON',
        });
        break;
      case CategoryEnum.MAJOR_PROJECT:
        setTransaction({
          ...transaction,
          rewardType: RewardEnum.GEM,
          amount: rewardOption.amount,
          category: rewardOption.category,
          transactionType: 'WON',
        });
        break;
      default:
        setTransaction(undefined);
        break;
    }
  };

  const DefineRewardIcon = ({ option }: { option: string }) => {
    switch (option) {
      case CategoryEnum.MINOR_PROJECT:
        return <Coin />;
      case CategoryEnum.MAJOR_PROJECT:
        return <Gem />;
      default:
        return <React.Fragment />;
    }
  };

  const translateOption = (option: CategoryEnum) => {
    switch (option) {
      case CategoryEnum.MINOR_PROJECT:
        return t('select.minorProject');
      case CategoryEnum.MAJOR_PROJECT:
        return t('select.majorProject');
      default:
        return t('select.selectTheReason');
    }
  };

  const minValue = transaction?.amount === 1;

  function maxValue(option: CategoryEnum) {
    const findOption = rewardsOptions?.find(
      rewardOption => rewardOption.category === option,
    );

    return findOption?.amount ?? 1;
  }

  const removeCash = () => {
    if (!minValue) {
      setTransaction({
        ...transaction,
        amount: (transaction?.amount ?? 0) - 1,
      });
    }
  };

  const addCash = () => {
    setTransaction({
      ...transaction,
      amount: (transaction?.amount ?? 0) + 1,
    });
  };

  return (
    <div data-testid="wonMode" className="flex flex-col gap-4 justify-center">
      <SelectInput
        testId="wonModeSelect"
        defaultValue={option}
        className={{ input: 'text-base-content font-400' }}
        onChange={e => {
          setOption(e.target.value as CategoryEnum);
          const findOption = rewardsOptions?.find(
            rewardOption => rewardOption.category === e.target.value,
          );
          if (findOption) {
            defineTransaction(findOption);
          } else {
            setTransaction(undefined);
          }
        }}
      >
        <option disabled value={CategoryEnum.SELECT_THE_REASON}>
          {t('select.selectTheReason')}
        </option>
        {rewardsOptions &&
          rewardsOptions?.map(rewardOption => {
            const isAValidOption =
              rewardOption.category === CategoryEnum.MAJOR_PROJECT ||
              rewardOption.category === CategoryEnum.MINOR_PROJECT;

            if (isAValidOption) {
              return (
                <option
                  key={rewardOption.category}
                  value={rewardOption.category}
                >
                  {translateOption(rewardOption.category)}
                </option>
              );
            } else {
              return <React.Fragment key={rewardOption.category} />;
            }
          })}
      </SelectInput>
      <TextInput
        placeholder={t('inputPlaceholderReason')}
        testId="reasonInput"
        defaultValue={transaction?.reason}
        onChange={({ target }) =>
          setTransaction({ ...transaction, reason: target.value })
        }
      />
      <div className="flex justify-center items-center gap-1">
        <RewardInput
          testId="previewRewardValue"
          moreCoins={addCash}
          lessCoins={removeCash}
          coins={transaction?.amount ?? 0}
          maxCoins={maxValue(option)}
        />

        <DefineRewardIcon option={option} />
      </div>

      <div className="flex w-full justify-around gap-6">
        <RoundedButton
          color="neutral"
          className="w-full"
          text={t('cancelButton')}
          onClick={onClickCancel}
        />
        <RoundedButton
          color="success"
          loading={isWaitingTransactionRequest}
          text={t('confirmDepositButton')}
          className="w-full"
          onClick={() => onClickConfirm(transaction)}
        />
      </div>
    </div>
  );
};
