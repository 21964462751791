import { useLocation } from 'react-router-dom';

import { ACTIVE_LESSON_TAB_STORAGE_KEY } from '@/constants';
import { removeTokenResponse } from '@/functions/tokenSettings';
import { deleteSettings } from '@/functions/userSettings';

export const useErrorTratatives = () => {
  const { pathname } = useLocation();
  const tratativeFromEditingLesson = () =>
    sessionStorage.removeItem(ACTIVE_LESSON_TAB_STORAGE_KEY);

  const tratativeFromHome = () => removeTokenResponse();

  const tratativeFromCourseTab = () =>
    deleteSettings({ removals: ['courseTabId'] });

  const mountRoutesMap = () => ({
    home: {
      isCurrent: [pathname === '/'],
      tratative: tratativeFromHome,
    },
    lesson: {
      isCurrent: [
        /\/class\/\d+\/lessons/g.test(pathname),
        /\/admin\/courses\/[A-z0-9-]+\/lessons/g.test(pathname),
      ],
      tratative: tratativeFromEditingLesson,
    },
    course: {
      isCurrent: [/\/courses/g.test(pathname)],
      tratative: tratativeFromCourseTab,
    },
  });

  const tratativeFn = () => {
    const routesMap = mountRoutesMap();
    let route: keyof typeof routesMap;
    for (route in routesMap) {
      if (routesMap[route].isCurrent.some(test => test)) {
        routesMap[route].tratative();
        return;
      }
    }
  };

  return { tratativeFn };
};
