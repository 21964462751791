import requester from '@/axios';
import JsonApiResponse from '@/models/JsonApiResponse';
import {
  BehaviorAssessment,
  CommentAssessment,
  KlassAssessment,
  KlassAssessmentTypeEnum,
} from '@/models/KlassAssessment';
import { BaseFilters, ListServiceFunc } from '@/models/Service';

export type ListKlassAssesments = BaseFilters & {
  klassId?: number;
  type?: KlassAssessmentTypeEnum[];
};

export const listKlassAssessments: ListServiceFunc<
  KlassAssessment,
  ListKlassAssesments
> = async params => {
  const { data } = await requester().get<JsonApiResponse<KlassAssessment>>(
    'classes-assessments/',
    {
      params,
    },
  );

  return data;
};

export const getKlassAssessment = async (assessmentId: number) => {
  const { data } = await requester().get<KlassAssessment>(
    `classes-assessments/${assessmentId}/`,
  );

  return data;
};

export type KlassAssessmentBody = {
  klassId?: number;
  behaviorAssessment?: Partial<BehaviorAssessment>;
  commentAssessment?: Partial<CommentAssessment>;
};

export const createKlassAssessment = async (body: KlassAssessmentBody) => {
  const { data } = await requester().post<KlassAssessmentBody>(
    'classes-assessments/',
    body,
  );

  return data;
};

type KlassAssessmentRequest = {
  assessmentId: number;
  body: Partial<KlassAssessmentBody>;
};

export const updateKlassAssessment = async ({
  assessmentId,
  body,
}: KlassAssessmentRequest) => {
  const { data } = await requester().patch<KlassAssessment>(
    `classes-assessments/${assessmentId}/`,
    body,
  );

  return data;
};
