interface RowProps extends React.TableHTMLAttributes<HTMLTableRowElement> {
  children: JSX.Element | JSX.Element[];
  onClick?: React.MouseEventHandler<HTMLTableRowElement>;
  type?: 'header' | 'regular';
  className?: string;
  testId?: string;
}

export default function Row(props: RowProps) {
  const { children, onClick, type, className, testId, ...rest } = props;

  return (
    <tr
      {...rest}
      data-testid={testId}
      onClick={onClick}
      className={`flex rounded-lg w-full ${
        type === 'header'
          ? className
          : 'flex-col py-2 px-6 shadow-default   bg-base-100'
      }`}
    >
      {children}
    </tr>
  );
}
