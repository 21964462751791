import { SearchIcon } from '@heroicons/react/outline';
import { useTranslation } from 'react-i18next';
import IconButton from './buttons/IconButton';
import { Tooltip } from './dataDisplay/Tooltip';

interface TableSearchIconProps {
  onClick?(): void;
  className?: string;
}

export default function TableSearchIcon(props: TableSearchIconProps) {
  const { t } = useTranslation('translation', {
    keyPrefix: 'tableHeader',
  });

  const { onClick, className } = props;

  return (
    <Tooltip text={t('search')} className="font-400">
      <IconButton
        testId="searchIconButton"
        onClick={onClick}
        icon={
          <SearchIcon className={`w-4 h-4 ${className || 'text-base-100'}`} />
        }
      />
    </Tooltip>
  );
}
