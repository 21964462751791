import { fadeIn } from '@/utils/animations/commom';
import { Player, PlayerEvent } from '@lottiefiles/react-lottie-player';
import { AnimatePresence, motion } from 'framer-motion';
import React from 'react';

interface LottieAnimationProps {
  className?: string;
  setIsVisible?: React.Dispatch<React.SetStateAction<boolean>>;
  size?: string;
  src: string;
}

export default function LottieAnimation({
  className,
  setIsVisible,
  src,
  size,
}: LottieAnimationProps) {
  return (
    <AnimatePresence>
      <motion.div className={className || ''} {...fadeIn}>
        <Player
          className={size || 'w-52'}
          autoplay
          onEvent={e => {
            if (e === PlayerEvent.Complete) {
              if (setIsVisible) {
                setIsVisible(false);
              }
            }
          }}
          src={src}
        />
      </motion.div>
    </AnimatePresence>
  );
}
