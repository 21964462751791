import { Navigate } from 'react-router-dom';
import useAuth from '../../data/hook/useAuth';
import { UserTypeEnum } from '../../models/User';

export interface AuthGuardProps {
  roles?: (UserTypeEnum | undefined)[];
  children: JSX.Element;
  isAdmin?: boolean;
}

export default function AuthGuard(props: AuthGuardProps) {
  let { user, ...auth } = useAuth();

  if (auth.isLoading) {
    return <div></div>;
  }
  if (!props.roles?.includes(user?.userType)) {
    return <Navigate to="/forbidden" />;
  }

  return props.children;
}
