import Activity, { ActivityTypeEnum } from '@/models/Activity';
import ActivityElement, {
  CodeActivityEditElement,
  DNDOrdenationActivity,
  DNDPositionalActivity,
  QuizActivityEditElement,
  TextActivityElement,
  TrueOrFalseActivityElement,
  VideoActivityElement,
} from '@/models/ActivityElement';
import CodeElementEdit from './CodeElementEdit/CodeElementEdit';
import DragAndDropOrdenationElementEdit from './DNDOrdenationElementEdit/DNDOrdenationElementEdit';
import DNDPositionalElementEdit from './DNDPositionalElementEdit/DNDPositionalElementEdit';
import QuizElementEdit from './QuizElementEdit/QuizElementEdit';
import TextElementEdit from './TextElementEdit';
import TrueOrFalseElementEdit from './TrueOrFalseElementEdit';
import VideoElementEdit from './VideoElementEdit';
import { CodeEditorActivity } from '@/models/CodeEditorActivity';
import CodeEditorElementEdit from './CodeEditorElementEdit/CodeEditorForm';

export interface ElementEditFactoryProps {
  question: ActivityElement;
  activity: Activity;
  onEdit: (props: Partial<ActivityElement>) => void;
  onSave: (props: ActivityElement) => void;
}

export default function ElementEditFactory({
  question,
  ...props
}: ElementEditFactoryProps) {
  switch (question.type) {
    case ActivityTypeEnum.TEXT:
      return (
        <TextElementEdit
          {...(question as TextActivityElement)}
          order={question.order}
          {...props}
        />
      );

    case ActivityTypeEnum.VIDEO:
      return (
        <VideoElementEdit
          {...(question as VideoActivityElement)}
          order={question.order}
          {...props}
        />
      );

    case ActivityTypeEnum.QUIZ:
      return (
        <QuizElementEdit
          {...(question as QuizActivityEditElement)}
          order={question.order}
          {...props}
        />
      );

    case ActivityTypeEnum.TRUE_OR_FALSE:
      return (
        <TrueOrFalseElementEdit
          {...(question as TrueOrFalseActivityElement)}
          order={question.order}
          {...props}
        />
      );
    case ActivityTypeEnum.DND_ORDENATION:
      return (
        <DragAndDropOrdenationElementEdit
          {...(question as DNDOrdenationActivity)}
          order={question.order}
          {...props}
        />
      );
    case ActivityTypeEnum.DND_POSITIONAL:
      return (
        <DNDPositionalElementEdit
          {...(question as DNDPositionalActivity)}
          order={question.order}
          {...props}
        />
      );

    case ActivityTypeEnum.CODE:
      return (
        <CodeElementEdit
          question={question as CodeActivityEditElement}
          {...props}
        />
      );
    case ActivityTypeEnum.CODE_EDITOR:
      return (
        <CodeEditorElementEdit
          question={question as CodeEditorActivity}
          {...props}
        />
      );
    default:
      return null;
  }
}
