import { PropsWithChildren } from 'react';
import { twMerge } from 'tailwind-merge';

export type CardListItemColor = 'accent' | 'blue' | 'orange';

const style: Record<CardListItemColor, string> = {
  accent: 'text-accent bg-accent-content',
  blue: 'text-primary bg-primary-content',
  orange: 'bg-warning-content text-warning',
};

interface CardListItemProps {
  color: CardListItemColor;
  className?: string;
}

export default function CardListItem({
  color,
  className,
  children,
}: PropsWithChildren<CardListItemProps>) {
  return (
    <li
      className={twMerge(
        `w-full h-fit p-2.5 rounded-lg ${style[color]}`,
        className,
      )}
    >
      {children}
    </li>
  );
}
