import CourseTitle from '@/components/common/CourseTitle';
import Skeleton from '@/components/common/Skeleton';
import { formatLessonName } from '@/functions/lessonsName';
import { Lesson } from '@/models/Lesson';

type EditMaterialHeaderProps = {
  loading?: boolean;
  lesson: Lesson;
  backUrl?: string;
};

export default function EditMaterialHeader({
  lesson,
  loading,
  backUrl,
}: EditMaterialHeaderProps) {
  if (loading) {
    return <Skeleton className="h-16 rounded-lg bg-secondary-content" />;
  }

  return (
    <CourseTitle
      title={formatLessonName(lesson)}
      hasBackButton
      backUrl={backUrl}
    />
  );
}
