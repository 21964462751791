import { useEffect, useState } from 'react';
import {
  InformationCircleIcon,
  CalendarIcon,
  ChevronDownIcon,
} from '@heroicons/react/outline';
import { useTranslation } from 'react-i18next';
import Unit from '@/models/Unit';
import EditUnit from './EditUnit';
import { CheckCircleIcon } from '@heroicons/react/solid';
import IconButton from '../../common/buttons/IconButton';
import { UnitHeader } from '@/utils/HeaderTypes';
import Row from '../../common/table/Row';
import BodyCell from '../../common/table/BodyCell';
import Text from '../../common/dataDisplay/Text';
import { Link } from 'react-router-dom';
import { Tooltip } from '../../common/dataDisplay/Tooltip';
import Observable from '@/utils/observers/ObserverPattern';
import { AnimatePresence, motion } from 'framer-motion';
import { editFormAnimation } from '@/utils/animations/formAnimations';
import { unitStatisticsCalculator } from '@/utils/statistics/unitStatisticsCalculators';
import ConditionalRenderer from '@/components/common/ConditionalRenderer';
import Skeleton from '@/components/common/Skeleton';
import { unitsStatisticsQueryKeys } from '@/data/services/querykeys';
import useStatisticService from '@/data/hook/useStatistics';

interface ListUnitsProps {
  units: Unit[];
  titles: UnitHeader;
}

export default function DataUnitsTable({ titles, units }: ListUnitsProps) {
  const observer = new Observable<number>();

  return (
    <tbody className="bg-transparent flex flex-col gap-y-2.5">
      {units?.map(unit => (
        <RenderRow
          observer={observer}
          key={unit.id}
          unit={unit}
          titles={titles}
        />
      ))}
    </tbody>
  );
}

interface RenderRowProps {
  unit: Unit;
  titles: UnitHeader;
  observer: Observable<number>;
}

function RenderRow({ unit, titles, observer }: RenderRowProps) {
  const { t } = useTranslation('translation', {
    keyPrefix: 'adminPage.listUnits',
  });
  const [viewInformations, setViewInformations] = useState(false);

  const { calculated: stats, isInitialLoading: isLoadingStats } =
    useStatisticService(
      unitsStatisticsQueryKeys.list({ unitId: [unit.id] }),
      unitStatisticsCalculator,
    );

  const toogleViewInformations = () => {
    if (!viewInformations) observer.notifyAll(unit.id);
    setViewInformations(old => !old);
  };

  useEffect(() => {
    observer.subscribe({
      id: unit.id,
      action() {
        setViewInformations(false);
      },
    });
  }, [observer, unit.id]);

  return (
    <Row testId="unitRow">
      <BodyCell className="justify-between p-0">
        <div
          className="flex items-center"
          style={{ width: `${titles.unit.size}%` }}
          onClick={toogleViewInformations}
        >
          <IconButton
            testId="classInfoButton"
            className="transition ease-in-out duration-150"
            icon={
              <ChevronDownIcon
                className={`w-3 h-3 transform transition-all duration-150 ease-in-out ${
                  viewInformations ? 'rotate-180' : ''
                }`}
              />
            }
          />

          <Text
            text={unit.name}
            className="cursor-pointer pl-5"
            format="rubik-400"
          />
        </div>

        <div
          className="flex items-center"
          style={{ width: `${titles.teachers.size}%` }}
        >
          <ConditionalRenderer
            condition={!isLoadingStats}
            fallback={
              <Skeleton className="rounded-full h-4 w-6 bg-primary-content" />
            }
          >
            <Text format="rubik-400" text={stats?.totalTeachers ?? 0} />
          </ConditionalRenderer>
        </div>

        <div
          className="flex items-center"
          style={{ width: `${titles.students.size}%` }}
        >
          <ConditionalRenderer
            condition={!isLoadingStats}
            fallback={
              <Skeleton className="rounded-full h-4 w-6 bg-primary-content" />
            }
          >
            <Text format="rubik-400" text={stats?.totalStudents ?? 0} />
          </ConditionalRenderer>
        </div>

        <div
          className="flex items-center"
          style={{ width: `${titles.classes.size}%` }}
        >
          <ConditionalRenderer
            condition={!isLoadingStats}
            fallback={
              <Skeleton className="rounded-full h-4 w-6 bg-primary-content" />
            }
          >
            <Text format="rubik-400" text={stats?.totalKlasses ?? 0} />
          </ConditionalRenderer>
        </div>

        <div
          className="flex items-center"
          style={{ width: `${titles.churn.size}%` }}
        >
          <ConditionalRenderer
            condition={!isLoadingStats}
            fallback={
              <Skeleton className="rounded-full h-4 w-6 bg-primary-content" />
            }
          >
            <Text format="rubik-400" text={(stats?.churnRate ?? 0) + '%'} />
          </ConditionalRenderer>
        </div>

        <div
          className="flex items-center"
          style={{ width: `${titles.presence.size}%` }}
        >
          <ConditionalRenderer
            condition={!isLoadingStats}
            fallback={
              <Skeleton className="rounded-full h-4 w-6 bg-primary-content" />
            }
          >
            <Text
              format="rubik-400"
              text={(stats?.attendancePercentage ?? 0) + '%'}
            />
          </ConditionalRenderer>
        </div>

        <div
          className="flex items-center"
          style={{ width: `${titles.status.size}%` }}
        >
          {unit.isActive ? (
            <CheckCircleIcon className="w-5 h-5 text-primary" />
          ) : (
            <CheckCircleIcon className="w-5 h-5 text-neutral/50" />
          )}
        </div>

        <div
          className="flex gap-2.5 items-center"
          style={{ width: `${titles.actions.size}%` }}
        >
          <Tooltip text={t('infoTooltip')}>
            <IconButton
              onClick={toogleViewInformations}
              icon={<InformationCircleIcon className="text-primary w-6 h-6 " />}
            />
          </Tooltip>

          <Tooltip text={t('recess')}>
            <Link to={`${unit.id}/recess-calendar`}>
              <IconButton
                icon={<CalendarIcon className="text-primary w-6 h-6" />}
              />
            </Link>
          </Tooltip>
        </div>
      </BodyCell>
      <BodyCell className="overflow-hidden">
        <AnimatePresence>
          {viewInformations && (
            <motion.div
              className="w-full overflow-hidden"
              {...editFormAnimation}
            >
              <EditUnit unit={unit} />
            </motion.div>
          )}
        </AnimatePresence>
      </BodyCell>
    </Row>
  );
}
