import useAuth from '@/data/hook/useAuth';
import { isSuperAdmin } from '@/functions/auth';
import { useQueries } from '@tanstack/react-query';
import Text from '../dataDisplay/Text';
import TooltipHandler from '../TooltipHandler';
import ConditionalRenderer from '../ConditionalRenderer';
import { LoadingIcon } from '@/components/icons';
import { unitsQueryKeys } from '@/data/services/querykeys';

type UnitDisplayProps = {
  unitsIds?: number[];
  width?: number;
};

export default function UnitDisplay({
  width,
  unitsIds = [],
}: UnitDisplayProps) {
  const { user } = useAuth();

  const isMultipleUnits = user && user.unitsIds?.length > 1;
  const isAuthorizedUser = isSuperAdmin(user?.userType) || isMultipleUnits;

  const queries = useQueries({
    queries: unitsIds.map(unitId => ({
      enabled: !!unitId && isAuthorizedUser,
      refetchOnMount: false,
      ...unitsQueryKeys.get(unitId),
    })),
  });

  const unitNames = queries.reduce((prev, { data, isSuccess }, index) => {
    const text = isSuccess ? data.name : '';

    if (index === 0) {
      return text;
    }
    return text ? prev.concat(`, ${text}`) : '';
  }, '');

  const isLoading = queries.some(({ isInitialLoading }) => isInitialLoading);

  return (
    <ConditionalRenderer condition={isAuthorizedUser}>
      <div style={{ width: `${width}%` }}>
        <ConditionalRenderer
          condition={!isLoading}
          fallback={
            <LoadingIcon className="w-6 text-start text-primary mx-auto" />
          }
        >
          <TooltipHandler
            renderTooltip={unitsIds?.length > 1}
            tooltipMessage={unitNames}
            classNameContainer="w-full"
          >
            <Text text={unitNames} className="truncate" />
          </TooltipHandler>
        </ConditionalRenderer>
      </div>
    </ConditionalRenderer>
  );
}
