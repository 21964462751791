import { useTranslation } from 'react-i18next';
import TextInput from '../../../../../common/dataInput/TextInput';
import { DNDColumn } from '../../../../../../models/DNDColumn';
import ConditionalRenderer from '../../../../../common/ConditionalRenderer';

interface DNDPositionalColumnNameEditProps {
  questionColumn: DNDColumn;
  answerColumn: DNDColumn;
  handleOnChangesColumn: (value: string, keyColumn: string) => void;
  isLoading: boolean;
}

export default function DNDPositionalColumnNameEdit(
  props: DNDPositionalColumnNameEditProps,
) {
  const { questionColumn, handleOnChangesColumn, isLoading } = props;

  const questionColumnErros = questionColumn.errors;

  const { t } = useTranslation('translation', {
    keyPrefix: 'activity.manageActivity.dndPositionalElementEdit.dndColumnEdit',
  });

  const columnNameError =
    questionColumnErros && questionColumnErros.some(value => value === 'name');

  return (
    <div
      data-testid="dndPositionalColumnNameEdit"
      className={isLoading ? 'disabled' : ''}
    >
      <label
        data-testid="columnNameLabel"
        className="font-rubik text-base-content block"
      >
        {t('columnName')}
      </label>

      <ConditionalRenderer condition={columnNameError}>
        <label className="font-rubik text-error error-label">
          {t('dndErrors.nameColumnRequired')}
        </label>
      </ConditionalRenderer>

      <TextInput
        fontWeight="font-400"
        className={{
          base: 'rounded-lg border-primary h-9 font-600 font-rubik',
        }}
        type="text"
        testId="columnNameInput"
        color={columnNameError && !questionColumn.name ? 'warning' : 'primary'}
        required
        defaultValue={questionColumn.name}
        onChange={e => handleOnChangesColumn(e.target.value, 'name')}
      />
    </div>
  );
}
