import { DNDColumn } from '../../../../../../models/DNDColumn';
import DNDPositionalColumnNameEdit from './DNDPositionalColumnNameEdit';
import { DNDPositionalItemListEdit } from './DNDPositionalItemListEdit';
import { DNDItem } from '../../../../../../models/DNDItem';
import {
  DNDAttributesHandler,
  HandleRemoveItem,
} from '../DNDPositionalElementEdit';

interface DNDPositionalColumnEditProps {
  questionColumn: DNDColumn;
  answerColumn: DNDColumn;
  handleUpdateColumn: DNDAttributesHandler<DNDColumn>;
  handleRemoveItem: HandleRemoveItem;
  handleAddItem: DNDAttributesHandler<DNDColumn>;
  handleOrdenationItems: DNDAttributesHandler<DNDItem[]>;
  isLoading: boolean;
}

export default function DNDPositionalColumnEdit(
  props: DNDPositionalColumnEditProps,
) {
  const {
    questionColumn,
    answerColumn,
    handleUpdateColumn,
    handleRemoveItem,
    handleAddItem,
    handleOrdenationItems,
    isLoading,
  } = props;

  function handleOnChangesColumn(value: string, keyColumn: string) {
    const newQuestionColumn = { ...questionColumn, [keyColumn]: value };
    const newAnswersColumn = { ...answerColumn, [keyColumn]: value };

    handleUpdateColumn({
      question: newQuestionColumn,
      answer: newAnswersColumn,
    });
  }

  return (
    <div data-testid="dndPositionalColumnEdit" className="flex flex-col gap-5">
      <DNDPositionalColumnNameEdit
        questionColumn={questionColumn}
        answerColumn={answerColumn}
        handleOnChangesColumn={handleOnChangesColumn}
        isLoading={isLoading}
      />
      <DNDPositionalItemListEdit
        questionColumn={questionColumn}
        answerColumn={answerColumn}
        handleUpdateColumn={handleUpdateColumn}
        handleRemoveItem={handleRemoveItem}
        handleAddItem={handleAddItem}
        handleOrdenationItems={handleOrdenationItems}
        isLoading={isLoading}
      />
    </div>
  );
}
