import Area from '@/models/Area';
import { ChevronLeftIcon } from '@heroicons/react/outline';
import { Fragment } from 'react/jsx-runtime';
import useStack from '../../../../../data/hook/useStack';
import IconButton from '@/components/common/buttons/IconButton';
import Text from '@/components/common/dataDisplay/Text';

type TopbarProps = {
  stack: ReturnType<typeof useStack<Area>>;
};

const Topbar = ({ stack }: TopbarProps) => {
  return (
    <header className="flex w-full gap-2.5 items-center">
      <IconButton
        icon={<ChevronLeftIcon className="h-4 w-4" />}
        onClick={() => stack.pop()}
        type="button"
      />

      <div className="flex gap-1.5">
        {stack.value.map(area => (
          <Fragment key={area.id}>
            <Text text={area.name} className="text-12" />
            <Text
              key={area.id}
              text=">"
              className="text-12 last-of-type:hidden"
            />
          </Fragment>
        ))}
      </div>
    </header>
  );
};

export default Topbar;
