import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useQuery } from '@tanstack/react-query';
import { useParams } from 'react-router-dom';
import EditActivity from '@/components/admin/activities/EditActivity';
import ManageActivityEditContainer from '@/components/admin/activities/ManageActivityEditContainer';
import ErrorComponent from '@/components/common/ErrorComponent';
import Layout from '@/components/template/Layout';
import useCourseEditing from '@/data/hook/useCourseEditing';
import HeadTitle from '@/components/common/HeadTitle';
import { makeLessonHeadTitle } from '@/utils/lessonHeadTitle';
import { ScheduledLessonTypeEnum } from '@/models/ScheduledLesson';
import { VersioningStatusEnum } from '@/enums/VersioningStatus';
import {
  activitiesQueryKeys,
  coursePathsQueryKeys,
  klassesQueryKeys,
  lessonsQueryKeys,
} from '@/data/services/querykeys';

function ManageExtraLessonsActivity() {
  const { setCourseStatus } = useCourseEditing();
  const { t } = useTranslation('translation', {
    keyPrefix: 'activity.manageActivity',
  });
  const { klassId, lessonId, activityId } = useParams();

  const hasLessonId = Boolean(Number(lessonId));
  const hasActivityId = Boolean(Number(activityId));
  const hasKlassId = Boolean(Number(klassId));

  const { data: klass, isInitialLoading: isLoading } = useQuery({
    ...klassesQueryKeys.get(Number(klassId)),
    enabled: hasKlassId,
  });

  const { data: coursePath } = useQuery({
    enabled: !!klass?.coursePathSlug,
    ...coursePathsQueryKeys.get(klass?.coursePathSlug ?? ''),
  });

  const { data: lesson, error: lessonError } = useQuery({
    ...lessonsQueryKeys.get(Number(lessonId)),
    enabled: hasLessonId,
  });

  const { data: activity, error: activityError } = useQuery({
    ...activitiesQueryKeys.get(Number(activityId)),
    enabled: hasActivityId && hasLessonId && hasKlassId,
  });

  const hasLessonKlassAndActivity = !!activity && !!lesson && !!klass;
  const defaultTitle = t('editActivity');
  const title = hasLessonKlassAndActivity
    ? `${defaultTitle} - ${activity?.name} - ${makeLessonHeadTitle({
        lessonOrder: lesson.order,
        klass,
        lessonType: ScheduledLessonTypeEnum.NORMAL,
      })}`
    : defaultTitle;

  useEffect(() => {
    setCourseStatus(VersioningStatusEnum.VERSIONING);
  }, [setCourseStatus]);

  if ((!activity || !lesson) && (activityError || lessonError)) {
    return (
      <Layout>
        <ErrorComponent
          errorTextSubTitle={t('errorTextSubTitle')}
          errorTextTitle={t('errorTextSubTitle')}
        />
      </Layout>
    );
  }
  return (
    <ManageActivityEditContainer
      isLoading={isLoading}
      courseName={coursePath?.course.name}
    >
      <HeadTitle routeInfo={title} />
      {klassId && lesson && activity ? (
        <EditActivity klassId={+klassId} lesson={lesson} activity={activity} />
      ) : (
        <React.Fragment />
      )}
    </ManageActivityEditContainer>
  );
}

export default ManageExtraLessonsActivity;
