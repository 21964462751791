import React from 'react';
import Text from '../../components/common/dataDisplay/Text';
import SelectInput from '../../components/common/dataInput/SelectInput';
import MainButton from '../../components/common/buttons/MainButton';
import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/outline';
import { useTranslation } from 'react-i18next';
import Skeleton from '../../components/common/Skeleton';

interface PaginationComponentProps {
  paramsPage: number;
  amountPages: number;
  setParamsPage?: (newState: string | string[]) => void;
  changePages: (numberOfPage: string) => void;
  amountOf: number;
  managerType:
    | 'students'
    | 'klasses'
    | 'teachers'
    | 'courses'
    | 'team'
    | 'units';
  isLoading?: boolean;
}

export default function PaginationComponent({
  amountPages,
  paramsPage,
  changePages,
  amountOf,
  managerType,
  isLoading,
}: PaginationComponentProps) {
  const { t } = useTranslation('translation', {
    keyPrefix: 'componentPagination',
  });

  const prevPage = () => {
    if (paramsPage > 1 && paramsPage <= amountPages) {
      changePages(String(paramsPage - 1));
    }
  };

  const nextPage = () => {
    if (paramsPage >= 1 && paramsPage < amountPages) {
      changePages(String(paramsPage + 1));
    }
  };

  if (isLoading) {
    return (
      <div className="flex w-full justify-center">
        <Skeleton className="flex justify-center bg-accent/40 rounded-2xl w-28 h-6" />
      </div>
    );
  }

  if (amountPages !== 0) {
    return (
      <React.Fragment>
        <Text
          className="flex justify-start"
          text={`${t(`totalOf.${managerType}`)} ${amountOf}`}
        />
        <div className="flex w-full flex-col gap-2">
          <Text
            className="flex justify-center"
            text={`${paramsPage} ${t('of')} ${amountPages} ${
              amountPages > 1 ? t('pages') : t('page')
            }`}
          />
          <div className="flex w-full justify-center items-center gap-5">
            <MainButton
              dataTestId="prevPageButton"
              disabled={paramsPage === 1}
              color="custom"
              text={t('prev')}
              className="text-primary"
              onClick={prevPage}
              icon={<ChevronLeftIcon />}
            />
            <SelectInput
              name="pages"
              id=""
              value={paramsPage}
              onChange={e => changePages(e.target.value)}
            >
              {Array(amountPages)
                .fill('')
                .map((_, index) => {
                  const numberSelect = index + 1;
                  return (
                    <option
                      key={numberSelect}
                      disabled={paramsPage === numberSelect}
                      value={numberSelect}
                    >
                      {numberSelect}
                    </option>
                  );
                })}
            </SelectInput>
            <MainButton
              dataTestId="nextPageButton"
              color="custom"
              text={t('next')}
              className="text-primary flex-row-reverse"
              onClick={nextPage}
              icon={<ChevronRightIcon />}
              disabled={paramsPage === amountPages}
            />
          </div>
        </div>
      </React.Fragment>
    );
  }

  return <React.Fragment />;
}
