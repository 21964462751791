import requester from '../../axios';
import { MGActivityProgress } from '../../models/MGActivity';

export const updateMgActivityProgress = async (
  id: number,
  changes: Partial<MGActivityProgress>,
) => {
  const { data: mgActivityProgress } =
    await requester().patch<MGActivityProgress>(
      `manual-grade-activity-progress/${id}/`,
      changes,
    );
  return mgActivityProgress;
};

export async function getMgActivityProgress(
  id: number,
): Promise<MGActivityProgress> {
  const { data } = await requester().get<MGActivityProgress>(
    `manual-grade-activity-progress/${id}/`,
  );
  return data;
}
