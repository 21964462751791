import LessonIconContainer, {
  IconType as CardType,
} from '@/components/common/LessonIconContainer';
import AccordionContainer from '@/components/common/cards/AccordionContainer';
import { useTranslation } from 'react-i18next';
import { ReactNode } from 'react';
import { ChevronDownIcon } from '@heroicons/react/outline';
import Text from '@/components/common/dataDisplay/Text';
import ConditionalRenderer from '@/components/common/ConditionalRenderer';
import { LoadingIcon } from '@/components/icons';
import useToggle from '@/data/hook/useToggle';
import ConditionalWrapper from '@/components/common/ConditionalWrapper';
import { Link } from 'react-router-dom';

export type LessonCardProps = {
  active?: boolean;
  type: CardType;
  children?: ReactNode;
  notification?: number;
  loading?: boolean;
  isOpen?: boolean;
  testId?: string;
  link?: string;
};

export default function LessonCard({
  active,
  type,
  children,
  notification,
  loading,
  testId,
  link,
}: LessonCardProps) {
  const { isOpen, toggle: onClick } = useToggle(active);

  const className =
    active || isOpen
      ? 'shadow-default shadow-secondary-content border-secondary'
      : 'shadow-default shadow-primary-content border-transparent';

  return (
    <div
      data-testid={testId}
      className={`transition ease-in-out duration-150  
      shadow border rounded-xl p-3 bg-base-100 h-fit ${className}`}
    >
      <Header
        onClick={children ? onClick : undefined}
        isOpen={isOpen}
        type={type}
        notification={notification}
        chevron={!!children}
        link={link}
        active={active}
      />
      <Content isOpen={isOpen} loading={loading}>
        {children}
      </Content>
    </div>
  );
}

type HeaderProps = Partial<LessonCardProps> & {
  onClick?(): void;
  type: CardType;
  chevron?: boolean;
  className?: string;
};

function Header({
  onClick,
  notification,
  isOpen,
  type,
  chevron,
  link,
  active,
}: HeaderProps) {
  const { t } = useTranslation('translation', {
    keyPrefix: 'klassView',
  });

  const circleColor = active || isOpen ? 'bg-secondary/30' : 'bg-primary/30';

  const linkClassName = `flex items-center bg-base-100 h-fit ${
    active ? 'text-secondary disable cursor-default' : 'text-primary'
  }`;

  const linkWrapper = (
    <Link data-testid="headerLink" to={link ?? ''} className={linkClassName} />
  );

  const divWrapper = (
    <div
      data-testid="headerDiv"
      onClick={onClick}
      className={`flex items-center justify-between cursor-pointer select-none h-fit ${
        active || isOpen ? 'text-secondary' : 'text-primary'
      }`}
    />
  );

  return (
    <ConditionalWrapper condition={!!link} wrapper={linkWrapper}>
      <ConditionalWrapper condition={!link} wrapper={divWrapper}>
        <div className="flex items-center gap-2">
          <LessonIconContainer
            notification={notification}
            size="w-8 h-8"
            circleColor={circleColor}
            type={type}
          />
          <Text format="rubik-500" text={t(`iconType.${type}`)} />
        </div>
        <ConditionalRenderer condition={chevron}>
          <ChevronDownIcon
            data-testid="chevron"
            className={`w-6 h-6 transition-all ${isOpen ? 'rotate-180' : ''}`}
          />
        </ConditionalRenderer>
      </ConditionalWrapper>
    </ConditionalWrapper>
  );
}

type ContentProps = Partial<LessonCardProps> & {
  children?: ReactNode;
};

function Content({ isOpen, children, loading, active }: ContentProps) {
  const initial = active ? 'open' : 'closed';

  const renderLoading = (
    <ConditionalRenderer condition={children}>
      <div className="flex w-full justify-center">
        <LoadingIcon className="w-8 text-secondary" />
      </div>
    </ConditionalRenderer>
  );

  return (
    <ConditionalRenderer condition={children}>
      <AccordionContainer
        animate={isOpen && children ? 'open' : 'closed'}
        initial={initial}
      >
        <ConditionalRenderer condition={!loading} fallback={renderLoading}>
          {children}
        </ConditionalRenderer>
      </AccordionContainer>
    </ConditionalRenderer>
  );
}
