import Text from '@/components/common/dataDisplay/Text';
import { ComponentProps } from 'react';
import { useTranslation } from 'react-i18next';
import { twMerge } from 'tailwind-merge';
import NotificationFiltersForm from './NotificationLeftFiltersForm';

const NotificationLeftFilter = (props: ComponentProps<'aside'>) => {
  const { t: tPage } = useTranslation('translation', {
    keyPrefix: 'alerts.page',
  });

  return (
    <aside
      {...props}
      className={twMerge(
        'flex flex-col gap-4 shadow-default rounded-xl border border-neutral-content p-3.5',
        props.className,
      )}
    >
      <Text
        className="text-accent font-500"
        text={tPage('filters')}
        size="text-18"
      />
      <NotificationFiltersForm />
    </aside>
  );
};

export default NotificationLeftFilter;
