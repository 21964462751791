import {
  BookOpenIcon,
  ChevronUpIcon,
  DocumentReportIcon,
  DocumentTextIcon,
  InformationCircleIcon,
  UserGroupIcon,
} from '@heroicons/react/outline';
import { AnimatePresence, motion } from 'framer-motion';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { LoaderIcon } from 'react-hot-toast';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';

import IconButton from '@/components/common/buttons/IconButton';
import ConditionalRenderer from '@/components/common/ConditionalRenderer';
import Text from '@/components/common/dataDisplay/Text';
import { Tooltip } from '@/components/common/dataDisplay/Tooltip';
import BodyCell from '@/components/common/table/BodyCell';
import Row from '@/components/common/table/Row';
import UnitDisplay from '@/components/common/table/UnitDisplay';
import { ListKlassesFilters } from '@/data/services/klassServices';
import Klass, { KlassTypeEnum } from '@/models/Klass';
import { editFormAnimation } from '@/utils/animations/formAnimations';
import { KlassHeader } from '@/utils/HeaderTypes';
import Observable from '@/utils/observers/ObserverPattern';
import { translateKlassName } from '@/utils/translateKlassName';
import useFilterParams from '@/utils/UseFilterParams';
import EditKlass from './EditKlass';
import KlassMembersView from './KlassMembersView';
import KlassSheet from './KlassSheet/KlassSheet';
import KlassStatusIcon from './KlassStatusIcon';
import { ScheduledLessonProgressView } from './ScheduledLessonProgressView';
import ComponentGuard from '@/components/common/ComponentGuard';
import { UserTypeEnum } from '@/models/User';
import Tag from '@/components/common/dataDisplay/Tag';

interface ListKlassProps {
  klassList: Klass[];
  titles: KlassHeader;
}

export default function DataKlassTable({ klassList, titles }: ListKlassProps) {
  const observer = new Observable<number>();

  return (
    <tbody className="bg-transparent flex flex-col gap-y-2.5">
      {klassList.length > 0 &&
        klassList.map(klass => {
          return (
            <RenderRow
              key={klass.id}
              klass={klass}
              titles={titles}
              observer={observer}
            />
          );
        })}
    </tbody>
  );
}

interface RenderRowProps {
  klass: Klass;
  titles: KlassHeader;
  observer?: Observable<number>;
}

type Tabs = 'info' | 'members' | 'lessons' | 'sheet';

const RenderRow = ({ klass, observer, titles }: RenderRowProps) => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'manageKlasses.dataKlassTable',
  });

  const navigate = useNavigate();

  const { actionId } = useParams();

  const { filterParams } = useFilterParams<ListKlassesFilters>();

  const name = filterParams.get('name') ?? undefined;

  const [viewInformations, setViewInformations] = useState(
    `#${name}` === klass.name,
  );

  const [tab, setTab] = useState<Tabs>((actionId as Tabs) || 'info');

  const klassStartDateMoment = moment(klass.klassStartDate);
  const klassStartDate = klassStartDateMoment.isValid()
    ? klassStartDateMoment.format('DD MMM YYYY')
    : t('noDate');

  const toogleViewInformations = (newTab?: Tabs) => {
    if (tab !== newTab) {
      newTab && setTab(newTab);
      setViewInformations(true);
      return;
    }
    if (!viewInformations) observer?.notifyAll(klass.id);
    setViewInformations(old => !old);
    if (tab) setTab(tab);
  };

  useEffect(() => {
    klass.id &&
      observer?.subscribe({
        id: klass.id,
        action: () => {
          setViewInformations(false);
        },
      });
  }, [klass.id, observer]);

  return (
    <ConditionalRenderer
      condition={klass}
      fallback={
        <Row>
          <BodyCell>
            <LoaderIcon className="w-5 h-5 animate-spin" data-testid="loader" />
          </BodyCell>
        </Row>
      }
    >
      <Row testId="classRow">
        <BodyCell className="justify-between p-0">
          <div
            style={{ width: `${titles.klass.size}%` }}
            className="flex items-center"
            onClick={() => toogleViewInformations(tab)}
          >
            <IconButton
              testId="classInfoButton"
              className="transition ease-in-out duration-150"
              icon={
                <ChevronUpIconWithAnimation
                  animate={{
                    rotate: !viewInformations ? 180 : 0,
                  }}
                  className="w-3 h-3"
                />
              }
            />

            <div className="flex gap-4 items-center">
              <Text
                text={translateKlassName(klass.name)}
                className="cursor-pointer pl-5 self-center h-fit capitalize"
                format="rubik-400"
              />

              <ConditionalRenderer
                condition={klass.klassType === KlassTypeEnum.CONECTA}
              >
                <ComponentGuard
                  roles={[UserTypeEnum.SUPER_ADMIN, UserTypeEnum.UNIT_ADMIN]}
                >
                  <Tooltip text="Conecta">
                    <Tag
                      color="custom"
                      text="C"
                      className="aspect-square p-0 w-5 max-w-none flex justify-center items-center bg-secondary text-base-100 text-[0.875rem] pt-0.5"
                    />
                  </Tooltip>
                </ComponentGuard>
              </ConditionalRenderer>
            </div>
          </div>

          <UnitDisplay width={titles.unit?.size} unitsIds={[klass.unitId]} />

          <div style={{ width: `${titles.start.size}%` }} className="flex">
            <Text
              text={`${klassStartDate}`}
              format="rubik-400"
              className="self-center h-fit capitalize"
            />
          </div>

          <div style={{ width: `${titles.end.size}%` }} className="flex">
            <Text
              text={
                klass.klassEndDate
                  ? `${moment(klass.klassEndDate).format('DD MMM YYYY')}`
                  : ''
              }
              format="rubik-400"
              className="self-center h-fit capitalize"
            />
          </div>

          <div
            style={{ width: `${titles.status?.size}%` }}
            className="flex self-center"
          >
            <KlassStatusIcon status={klass.status} />
          </div>

          <div
            className="flex gap-x-2.5 self-center"
            style={{ width: `${titles.actions?.size}%` }}
          >
            <Tooltip text={t('editTooltip')}>
              <IconButton
                testId="klassInfoButton"
                onClick={() => toogleViewInformations('info')}
                icon={
                  <InformationCircleIcon className="text-primary w-6 h-6 " />
                }
              />
            </Tooltip>

            <Tooltip text={t('klassSheetTooltip')}>
              <IconButton
                testId={`sheet${klass.id}`}
                onClick={() => toogleViewInformations('sheet')}
                icon={<DocumentTextIcon className="text-primary w-6 h-6" />}
              />
            </Tooltip>
            <Tooltip
              text={
                klass.enrollsCount
                  ? t('classProgressTooltip')
                  : t('noStudentsToFollow')
              }
            >
              <IconButton
                disabled={!klass.enrollsCount}
                onClick={() => {
                  navigate(`/class/${klass.id}/progress`);
                }}
                icon={<DocumentReportIcon className="text-primary w-6 h-6" />}
              />
            </Tooltip>
            <Tooltip text={t('teacherStudentsTooltip')}>
              <IconButton
                testId={`members${klass.id}`}
                onClick={() => toogleViewInformations('members')}
                icon={<UserGroupIcon className="text-primary w-6 h-6" />}
              />
            </Tooltip>
            <Tooltip text={t('lessonsTooltip')}>
              <IconButton
                onClick={() => toogleViewInformations('lessons')}
                icon={<BookOpenIcon className="text-primary w-6 h-6" />}
              />
            </Tooltip>
          </div>
        </BodyCell>

        <BodyCell className="overflow-hidden">
          <AnimatePresence>
            {viewInformations && (
              <motion.div className="w-full" {...editFormAnimation}>
                <KlassDetails klass={klass} tab={tab} />
              </motion.div>
            )}
          </AnimatePresence>
        </BodyCell>
      </Row>
    </ConditionalRenderer>
  );
};

interface KlassDetailsProps {
  klass: Klass;
  tab: Tabs;
}

const KlassDetails = ({ klass, tab }: KlassDetailsProps) => {
  const tabs = {
    info: <EditKlass klass={klass} />,
    members: <KlassMembersView klass={klass} />,
    lessons: <ScheduledLessonProgressView klass={klass} />,
    sheet: <KlassSheet klass={klass} />,
  };

  return (
    <div className="flex w-full justify-center">{tabs[tab || 'info']}</div>
  );
};

const ChevronUpIconWithAnimation = motion(ChevronUpIcon);
