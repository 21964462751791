import Text from '../common/dataDisplay/Text';
import moment from 'moment';

interface DateCardProps {
  date: Date;
  selected?: boolean;
  onClick: () => void;
}
export default function DateCard({ date, selected, onClick }: DateCardProps) {
  const month = moment(date).format('MMM');
  const weekday = moment(date).format('ddd');

  return (
    <div
      onClick={onClick}
      className={`hover:cursor-pointer cursor-pointer transition-transform [perspective:1000px] w-[47%] sm:w-24 px-8 py-3 gap-3 flex flex-col items-center shadow-default   rounded-xl border ${
        selected
          ? 'bg-secondary text-base-100 border-secondary [transform:rotateY(360deg)] duration-1000'
          : 'text-base-content border-base-100 bg-base-100'
      }`}
    >
      <Text text={`${weekday}.`} />
      <Text
        format="rubik-500"
        size="text-24"
        text={new Date(date).getDate().toString()}
      />
      <Text className="uppercase" text={month} />
    </div>
  );
}
