import { ColumnDND } from '../../../../../../models/DNDOrdenation';
import ConditionalRenderer from '../../../../../common/ConditionalRenderer';
import Text from '../../../../../common/dataDisplay/Text';
import DNDOrdenationItem from './DNDOrdenationItem';

interface DNDOrdenationColumnProps {
  dndOrdenationColumn: ColumnDND;
}

export default function DNDOrdenationColumn({
  dndOrdenationColumn,
}: DNDOrdenationColumnProps) {
  return (
    <div className="flex flex-col gap-3 items-center w-full">
      <ConditionalRenderer condition={!!dndOrdenationColumn.name}>
        <Text
          format="rubik-500"
          className="text-accent"
          text={dndOrdenationColumn.name}
        />
      </ConditionalRenderer>

      <div className="flex flex-col w-full gap-3">
        {dndOrdenationColumn.items.map(dndOrdenationItem => {
          return (
            <DNDOrdenationItem
              key={dndOrdenationItem.id}
              dndOrdenationItem={dndOrdenationItem}
            />
          );
        })}
      </div>
    </div>
  );
}
