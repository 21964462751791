import { useEffect, useState } from 'react';

import ScheduledLesson, {
  ScheduledLessonTypeEnum,
} from '@/models/ScheduledLesson';
import { ROLES } from '@/utils/roles';
import { PERMISSIONS } from '@/utils/permissions';
import Klass from '@/models/Klass';
import User from '@/models/User';
import { VersioningStatusEnum } from '@/enums/VersioningStatus';

type UseManageScheduledLesson = {
  scheduledLesson: ScheduledLesson;
  user?: User;
  klass?: Klass;
  state?: 'view' | 'manager';
};

export default function useManageScheduledLesson({
  scheduledLesson,
  klass,
  user,
  state = 'manager',
}: UseManageScheduledLesson) {
  const { hasDone, lesson, isActive, type } = scheduledLesson;

  const anticipatePermission = PERMISSIONS.SCHEDULED_LESSON.ANTECIPATE({
    scheduledLesson: scheduledLesson,
  });

  const editPermission = PERMISSIONS.SCHEDULED_LESSON.EDIT({ klass, user });

  const isPublished = lesson.status === VersioningStatusEnum.PUBLISHED;

  const isToday = ROLES.SCHEDULED_LESSON.isTodayLessonDate({
    scheduledLesson,
  });

  const bankNotPublished = type === ScheduledLessonTypeEnum.EXTRA && !isActive;

  const startingSoonRole = !hasDone;

  const [isStartingSoon, setIsStartingSoon] = useState(
    ROLES.SCHEDULED_LESSON.isStartingSoon({
      scheduledLesson,
    }) && startingSoonRole,
  );

  useEffect(() => {
    const updateStartingSoon = () => {
      setIsStartingSoon(
        ROLES.SCHEDULED_LESSON.isStartingSoon({
          scheduledLesson,
        }) && startingSoonRole,
      );
    };

    const interval = isToday
      ? setInterval(updateStartingSoon, 5000)
      : undefined;

    return () => clearInterval(interval);
  }, [isToday, scheduledLesson, startingSoonRole, state]);

  return {
    isStartingSoon,
    editPermission,
    isPublished,
    anticipatePermission,
    bankNotPublished,
  };
}
