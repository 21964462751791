import normalize from './normalize';

type Churn = {
  activeEnrollments: number;
  canceledEnrollments: number;
};

export default function churnPercentage({
  canceledEnrollments,
  activeEnrollments,
}: Churn) {
  const churn =
    (canceledEnrollments / (activeEnrollments + canceledEnrollments)) * 100;

  return normalize(churn);
}
