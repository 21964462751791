import { useQuery } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import moment from 'moment';

import Skeleton from '@/components/common/Skeleton';
import Text from '@/components/common/dataDisplay/Text';
import PillComponent from '@/components/common/dataDisplay/PillComponent';
import { Avatar } from '@/components/common/dataDisplay/Avatar';
import { REQUEST_STALE_TIME_IN_MS } from '@/constants';
import { getErrorMessage } from '@/utils/getErrorMessage';
import { usersQueryKeys } from '@/data/services/querykeys';
import useStudentContext from '@/data/hook/student';
import { handleUserFullName } from '@/functions/handleUserFullName';

export function NextLessonView() {
  const { nextLesson, loadingNextLesson: loading } = useStudentContext();

  if (loading)
    return (
      <Skeleton
        testId="loading"
        className="hidden lg:flex w-full md:w-96 h-10 bg-primary-content rounded-lg"
      />
    );

  if (nextLesson) {
    const date = moment(nextLesson.datetime).format('dddd - HH:mm') + 'h';

    return (
      <div
        data-testid="nextLessonView"
        className="hidden lg:flex items-center justify-center bg-primary-content py-1.5 px-3 rounded-xl gap-6"
      >
        <Text
          testId="nextLessonDate"
          text={date}
          format="rubik-500"
          className="text-primary capitalize"
        />

        <TeacherView teacherId={nextLesson.teacher} />
      </div>
    );
  }

  return null;
}

type TeacherViewProps = {
  teacherId: number;
};

function TeacherView({ teacherId }: TeacherViewProps) {
  const { t } = useTranslation('translation', {
    keyPrefix: 'nextLessonView',
  });

  const {
    data: teacher,
    isInitialLoading: loading,
    error,
  } = useQuery({
    ...usersQueryKeys.get(teacherId ?? 0),
    enabled: !!teacherId,
    staleTime: REQUEST_STALE_TIME_IN_MS,
  });

  if (loading)
    return (
      <Skeleton
        testId="loading"
        className="flex w-40 h-8 bg-primary-content rounded-full"
      />
    );

  if (error)
    return (
      <Text
        testId="error"
        text={getErrorMessage(error)}
        format="rubik-500"
        className="text-primary"
      />
    );

  if (teacher)
    return (
      <PillComponent className="min-w-min badge-primary badge-outline flex items-center gap-2">
        <Link
          to={`/profile/${teacher.username}`}
          className="flex gap-2 items-center"
          data-testid="teacherLink"
        >
          <Avatar userId={teacher.id} size="5" />
          <Text
            text={handleUserFullName(teacher)}
            isLink
            format="rubik-500"
            className="text-16"
          />
        </Link>
      </PillComponent>
    );

  return (
    <Text text={t('noTeacher')} format="rubik-500" className="text-primary" />
  );
}
