import Text from '../../../../common/dataDisplay/Text';

interface StatisticsProps {
  value: any;
  text: string;
  className?: string;
  fontColor?: string;
  testId?: string;
}

export default function Statistic(props: StatisticsProps) {
  const { className, value, text, testId } = props;
  return (
    <div data-testid={testId} className="flex flex-col items-center">
      <div
        className={`flex justify-center items-center 
        rounded-full shadow-default   text-40 md:text-48
        ${className}`}
      >
        {value}
      </div>
      <Text
        text={text}
        format="rubik-400"
        size="text-16"
        className="pt-4 text-base-content"
      />
    </div>
  );
}
