import useAuth from '@/data/hook/useAuth';
import { UserTypeEnum } from '@/models/User';
import AdminCoursesView from './AdminCoursesView';
import StudentCoursesView from './student/StudentCoursesView';
import Layout from '@/components/template/Layout';
import { useTranslation } from 'react-i18next';
import HeadTitle from '@/components/common/HeadTitle';

interface RenderViewByRoleProps {
  role?: UserTypeEnum;
}

export default function CoursesPage() {
  const { user } = useAuth();
  const { t } = useTranslation('translation', {
    keyPrefix: 'sideMenu',
  });

  return (
    <Layout>
      <HeadTitle routeInfo={t('courses')} />
      <RenderViewByRole role={user?.userType} />
    </Layout>
  );
}

const RenderViewByRole = ({ role }: RenderViewByRoleProps) => {
  switch (role) {
    case UserTypeEnum.STUDENT:
      return <StudentCoursesView />;
    default:
      return <AdminCoursesView />;
  }
};
