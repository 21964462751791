import MainButton from '@/components/common/buttons/MainButton';
import ConditionalRenderer from '@/components/common/ConditionalRenderer';
import Text from '@/components/common/dataDisplay/Text';
import InfiniteSearchInput from '@/components/common/dataInput/InfiniteSearchInput';
import { MoveAreaIcon } from '@/components/icons';
import useTreeArea from '@/data/hook/useTreeArea';
import { listAreaParents, updateArea } from '@/data/services/areaServices';
import { areasQueryKeys } from '@/data/services/querykeys';
import Area from '@/models/Area';
import { extractParentsId } from '@/utils/area';
import useFilterParams from '@/utils/UseFilterParams';
import { SwitchHorizontalIcon } from '@heroicons/react/outline';
import { useMutation } from '@tanstack/react-query';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { TopicsFiltersParams } from '../../TopicsPageContent';

export type MoveToProps = {
  area: Area;
  setIsMoving: (isMoving: boolean) => void;
};

export default function MoveTo({ area, setIsMoving }: MoveToProps) {
  const { tree, invalidateParent } = useTreeArea();

  const { t } = useTranslation('translation', {
    keyPrefix: 'topics.moveTo',
  });

  const [destAreaId, setDestAreaId] = useState<number>();
  const { setFilterParams } = useFilterParams<TopicsFiltersParams>();
  const onSelectArea = (area: Area) => {
    setDestAreaId(area.id);
  };

  const onMove = async (destAreaId?: number | null) => {
    const areaUpdated = await updateArea(area.id, { parentAreaId: destAreaId });

    tree.current.move(areaUpdated, destAreaId || 0);
    if (!destAreaId) return [areaUpdated.id];
    const parents = await listAreaParents(destAreaId);
    const parentsIds = extractParentsId(parents.results.at(0));
    return parentsIds;
  };

  const { mutate: move, isLoading } = useMutation(onMove, {
    onSuccess: async path => {
      const invalidateOrigin = invalidateParent({
        parentId: area.parentAreaId,
      });
      const invalidateDest = invalidateParent({ parentId: destAreaId });
      await Promise.all([invalidateOrigin, invalidateDest]);
      setFilterParams({
        path,
      });
    },
    onSettled: () => setIsMoving(false),
  });

  return (
    <div className="relative flex items-center w-fit gap-2.5 ml-auto mb-8">
      <Text text={t('label')} className="whitespace-nowrap" />
      <div className="relative">
        <InfiniteSearchInput
          onSelect={onSelectArea}
          service={areasQueryKeys.list}
          filters={{
            isLeaf: false,
          }}
          displayName={area => area.name}
          className={{ base: 'w-full' }}
          blockDeselect
          input={{
            placeholder: t('placeholder'),
          }}
          createOption={areaOption => ({
            key: areaOption.id,
            value: areaOption,
            isDisabled: areaOption.id === area.id,
            tooltipMessage: t('currentArea'),
          })}
        />
        <ConditionalRenderer condition={!area.isLeaf && area.parentAreaId}>
          <MainButton
            className="absolute top-full py-1.5 left-0 bg-transparent text-primary font-500 text-14 enabled:active:bg-transparent !gap-1.5"
            icon={
              <SwitchHorizontalIcon className="h-3.5 w-3.5 text-primary " />
            }
            text={t('transformRoot')}
            color="custom"
            onClick={() => move(null)}
          />
        </ConditionalRenderer>
      </div>
      <MainButton
        text={t('move')}
        disabled={!destAreaId}
        icon={<MoveAreaIcon className="h-4 w-4" />}
        onClick={() => move(destAreaId)}
        loading={isLoading}
      />
      <MainButton
        text={t('cancel')}
        onClick={() => setIsMoving(false)}
        className="bg-error"
        color="neutral"
      />
    </div>
  );
}
