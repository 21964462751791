import { Link } from 'react-router-dom';

import ConditionalRenderer from '@/components/common/ConditionalRenderer';
import { Tooltip } from '@/components/common/dataDisplay/Tooltip';
import Skeleton from '@/components/common/Skeleton';
import { ActivityProgressShortView } from '@/models/ActivityProgress';

type ActivityProgressItemProps = {
  activityProgress?: ActivityProgressShortView;
  loading?: boolean;
  coursePathSlug: string;
  klassId?: number;
};

export function ActivityProgressItem({
  activityProgress,
  loading,
  coursePathSlug,
  klassId,
}: ActivityProgressItemProps) {
  const hasDone = activityProgress?.hasDone;

  const classroomUrl = `/courses/${coursePathSlug}/lesson/${
    activityProgress?.lessonId
  }${klassId ? `/class/${klassId}` : ''}/activities/${activityProgress?.id}`;

  if (loading)
    return (
      <Skeleton className="flex w-2.5 h-2.5 sm:w-3.5 sm:h-3.5 bg-primary-content" />
    );
  return (
    <ConditionalRenderer condition={!!activityProgress}>
      <li>
        <Tooltip text={activityProgress?.activityName} color="accent">
          <Link to={classroomUrl} data-testid="activityItem">
            <span
              className={`flex w-2.5 h-2.5 sm:w-3.5 sm:h-3.5 hover:opacity-50 transition duration-200 ease-in-out ${
                hasDone ? 'bg-accent' : 'bg-accent/40'
              } `}
            />
          </Link>
        </Tooltip>
      </li>
    </ConditionalRenderer>
  );
}
