import moment from 'moment';
import { twMerge } from 'tailwind-merge';
import { useTranslation } from 'react-i18next';

import User from '@/models/User';
import HideContainer, {
  HideContainerProps,
} from '@/components/common/HideContainer';
import useHiddenComponents from '@/data/hook/useHiddenComponent';
import useInfiniteService from '@/data/hook/useInfiniteService';
import { scheduledLessonsQueryKeys } from '@/data/services/querykeys';
import { MANAGEMENT_PAGE_SIZE, REQUEST_STALE_TIME_IN_MS } from '@/constants';
import InfinityList from '@/components/common/InfinityList';
import ConditionalRenderer from '@/components/common/ConditionalRenderer';
import { LoadingIcon } from '@/components/icons';
import Text from '@/components/common/dataDisplay/Text';
import { getErrorMessage } from '@/utils/getErrorMessage';
import TodayLessonsHeader from './TodayLessonsHeader';
import { useClassScheduleFilters } from '@/data/hook/useClassScheduleFilters';
import { ScheduledLessonFilters } from '@/data/services/scheduledLessonsServices';
import { isTeacher } from '@/functions/auth';
import { getAuthorizedUnits } from '@/utils/getAuthorizedUnits';
import ManageScheduledLesson from '@/components/admin/calendar/ManageScheduledLesson/ManageScheduledLesson';

export type TodayLessonsProps = Pick<
  HideContainerProps,
  'updateRender' | 'render' | 'className' | 'setRef'
> & {
  user: User;
};

export default function TodayLessons(props: TodayLessonsProps) {
  const { user, className } = props;

  const { t } = useTranslation('translation', {
    keyPrefix: 'common',
  });

  const { components } = useHiddenComponents();

  const component = components['todayLessons'];

  const { filters, ...rest } = useClassScheduleFilters<ScheduledLessonFilters>({
    teacher: isTeacher(user.userType) ? user.id : undefined,
    unit: getAuthorizedUnits(user),
    date: moment().format('YYYY-MM-DD'),
    isActive: true,
  });

  const {
    results: scheduledLessons,
    isInitialLoading: loading,
    hasNextPage,
    isFetchingNextPage,
    fetchNextPage,
    error,
  } = useInfiniteService({
    ...scheduledLessonsQueryKeys.list({
      ...filters,
      pageSize: MANAGEMENT_PAGE_SIZE,
    })._ctx.infinity,
    staleTime: REQUEST_STALE_TIME_IN_MS,
    retry: false,
  });

  return (
    <HideContainer
      {...props}
      icon={component.icon}
      title={component.title}
      type={component.id}
      className={{
        section: twMerge(
          'flex flex-col gap-3.5 p-4 w-full',
          className?.section,
        ),
        header: twMerge('p-0', className?.header),
      }}
      tooltip
    >
      <TodayLessonsHeader filters={filters} {...rest} />
      <InfinityList
        hasNextPage={hasNextPage}
        isFetchingNextPage={isFetchingNextPage}
        onReachEnd={fetchNextPage}
        className="gap-2 scrollbar-none"
        scroll
      >
        <ConditionalRenderer condition={loading}>
          <div className="flex w-full h-full justify-center">
            <LoadingIcon className="text-primary/40 w-10" />
          </div>
        </ConditionalRenderer>

        <ConditionalRenderer condition={error}>
          <div className="flex w-full h-full justify-center">
            <Text format="rubik-400" text={getErrorMessage(error)} />
          </div>
        </ConditionalRenderer>

        <ConditionalRenderer condition={scheduledLessons.length}>
          {scheduledLessons.map(scheduledLesson => (
            <ManageScheduledLesson
              state="view"
              key={scheduledLesson.id}
              scheduledLesson={scheduledLesson}
            />
          ))}
        </ConditionalRenderer>

        <ConditionalRenderer
          condition={!loading && !error && !scheduledLessons.length}
        >
          <div className="flex w-full h-full justify-center pt-4">
            <Text
              text={`${t('noResults')} ¯\\_(ツ)_/¯`}
              className="overflow-hidden self-center text-primary"
            />
          </div>
        </ConditionalRenderer>
      </InfinityList>
    </HideContainer>
  );
}
