import React, { useCallback, useEffect, useState } from 'react';
import Modal from '../common/modals/Modal';
import TextInput from '../common/dataInput/TextInput';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { debounce, isEmpty } from 'lodash';
import {
  EyeIcon,
  PhotographIcon,
  SaveIcon,
  XIcon,
} from '@heroicons/react/outline';
import { twMerge } from 'tailwind-merge';
import ImageInput from '@/components/common/dataInput/ImageInput';
import isAceptableType, { bannerConfig } from '@/utils/VerifyImageFile';
import Text from '@/components/common/dataDisplay/Text';
import EmbedPreview from '@/components/common/dataDisplay/EmbedPreview';
import MyCkeditor from '@/components/editor/MyCkeditor';
import { ImageFolderEnum } from '@/models/CkEditor';
import MainButton from '@/components/common/buttons/MainButton';
import {
  CreateProject,
  GetProjectDataBody,
  createProject,
  updateProject,
} from '@/data/services/projectServices';
import { AxiosError } from 'axios';
import { ApiError, IApiError } from '@/models/Errors';
import alert from '@/utils/UseAlert';
import { Project } from '@/models/Project';
import { getIFrameSrc } from '@/utils/embedLink';
import useAuth from '@/data/hook/useAuth';
import ComponentGuard from '@/components/common/ComponentGuard';
import { UserTypeEnum } from '@/models/User';
import { hanldeFormChange } from '@/utils/handleFormChanges';
import {
  projectsMetadaraQuerykeys,
  projectsQuerykeys,
  studentsQuerykeys,
  toolsQueryKeys,
} from '@/data/services/querykeys';
import InfiniteSearchInput from '@/components/common/dataInput/InfiniteSearchInput';
import { handleUserFullName } from '@/functions/handleUserFullName';
import ConditionalRenderer from '@/components/common/ConditionalRenderer';
import { getAuthorizedUnits } from '@/utils/getAuthorizedUnits';
import { EventTrack } from '@/models/EventTrack';
import { eventTrack } from '@/functions/eventTrack';

interface ModalCreateProjectProps {
  isVisible: boolean;
  onClose(): void;
  project?: Project;
  teacher?: number;
}

export default function ModalCreateProject({
  isVisible,
  onClose,
  project,
  teacher,
}: ModalCreateProjectProps) {
  const isUpdatingMode = !!project;
  const { user } = useAuth();
  const [image, setImage] = useState<File | string | null>(
    project?.image ?? null,
  );
  const [hasChanges, setHasChanges] = useState(false);
  const { t } = useTranslation('translation', {
    keyPrefix: 'projectPage.projectInfoModal',
  });

  const queryClient = useQueryClient();

  const invalidate = async () =>
    queryClient.invalidateQueries(projectsQuerykeys.list._def);

  const {
    register,
    setValue,
    control,
    handleSubmit,
    reset,
    watch,
    setError,
    formState: { errors, defaultValues },
    clearErrors,
  } = useForm<CreateProject>({
    defaultValues: {
      projectUrl: project?.projectUrl ?? '',
      title: project?.title ?? '',
      toolId: project?.tool.id ?? 0,
      description: project?.description ?? '',
      shared: true,
      userId: project ? project.user.id : user?.id,
    },
  });

  const [projectUrl, setProjectUrl] = useState(defaultValues?.projectUrl ?? '');

  const hasErrors = !isEmpty(errors);
  const isSaveButtonDisabled = hasErrors || !hasChanges;

  const onChangeProjectUrl: React.ChangeEventHandler<HTMLInputElement> = e => {
    setProjectUrl(e.target.value);
  };

  const deboundedOnChangeProjectUrl = debounce(onChangeProjectUrl, 800);

  const { data: toolsResponse } = useQuery({
    ...toolsQueryKeys.list({
      site: projectUrl,
    }),
    enabled: !!projectUrl,
  });

  const tools = toolsResponse?.results ?? [];
  const tool = projectUrl ? tools?.[0] : undefined;

  const validateUpload = (file: File | null) => {
    if (!file) return;
    if (!isAceptableType(bannerConfig.accept, file?.type)) {
      alert.error(`${t('imageUploadFail')} ${file.name}`);
    } else if (URL.createObjectURL(file)) {
      setImage(file);
    }
  };
  const shouldRequestMetadata =
    !!projectUrl && !projectUrl?.includes('<iframe') && !!tool;

  const projectMetadataParams: GetProjectDataBody = {
    tool: tool?.name ?? '',
    url: projectUrl,
  };

  const {
    isInitialLoading: isLoadingMetadata,
    data: metadata,
    isError: isMetadaError,
    isSuccess: isMetadataSuccess,
  } = useQuery({
    enabled: shouldRequestMetadata,
    retry: false,
    ...projectsMetadaraQuerykeys.get(projectMetadataParams),
  });

  const { mutate, isLoading: isCreating } = useMutation(createProject, {
    async onSuccess(data) {
      alert.success(t('successAddedMessage'));

      const event: EventTrack = {
        category: tool?.name ?? '',
        action: 'Add Project',
        label: String(teacher) ?? 'N/A',
      };

      eventTrack(event);

      await invalidate?.();
      closeReset();
    },
    onError(error: AxiosError<IApiError>) {
      const apiError = new ApiError(error);
      alert.error(apiError.getErrorMessage());
    },
  });

  const { mutate: update, isLoading: isUpdating } = useMutation(
    (data: CreateProject) => updateProject(project?.id || 0, data),
    {
      async onSuccess({ id: projectId }) {
        await queryClient.invalidateQueries(
          projectsQuerykeys.get(projectId).queryKey,
        );
        await invalidate?.();
        closeReset();
      },
      onError(error: AxiosError<IApiError>) {
        const apiError = new ApiError(error);
        alert.error(apiError.getErrorMessage());
      },
    },
  );

  const onSubmit = async (data: CreateProject) => {
    if (typeof image === 'string') {
      data.imageUrl = image;
      data.image = undefined;
    } else if (image !== null) {
      data.image = image;
      data.imageUrl = undefined;
    } else {
      return setError('image', {
        message: t('imageRequired'),
      });
    }
    if (isUpdatingMode) {
      update(data);
    } else {
      mutate(data);
    }
  };

  const onImageChange: React.ChangeEventHandler<HTMLInputElement> = e => {
    if (e.target.files?.length) {
      return validateUpload(e.target.files.item(0));
    }
  };

  const forEachMetadata = useCallback(
    (entry: [string, string]) => {
      const [key, value] = entry;
      if (value) {
        if (key === 'image') {
          setImage(value);
        } else if (!isUpdatingMode) {
          setValue(key as keyof CreateProject, value);
        }
      }
    },
    [isUpdatingMode, setValue],
  );

  useEffect(() => {
    const subscribe = watch(fields =>
      hanldeFormChange(defaultValues, fields, setHasChanges),
    );

    return () => {
      subscribe.unsubscribe();
    };
  }, [defaultValues, watch]);

  useEffect(() => {
    if (isMetadataSuccess) {
      clearErrors('projectUrl');
    }
  }, [clearErrors, isMetadataSuccess]);

  useEffect(() => {
    if (isMetadaError) {
      setError('projectUrl', {
        message: t('invalidLink'),
      });
    }

    return () => {
      clearErrors('projectUrl');
    };
  }, [clearErrors, isMetadaError, setError, t]);

  useEffect(() => {
    if (metadata && projectUrl !== defaultValues?.projectUrl) {
      Object.entries(metadata).forEach(forEachMetadata);
      clearErrors('title');
    }
  }, [
    clearErrors,
    defaultValues?.projectUrl,
    forEachMetadata,
    metadata,
    projectUrl,
  ]);

  useEffect(() => {
    let mounted = true;
    if (mounted && projectUrl?.includes('<iframe')) {
      const iframeSrc = getIFrameSrc(projectUrl);
      if (iframeSrc) {
        setValue('projectUrl', iframeSrc);
      }
    }

    return () => {
      mounted = false;
    };
  }, [projectUrl, setValue]);

  useEffect(() => {
    let mounted = true;

    if (mounted && projectUrl) {
      if (tool) {
        setValue('toolId', tool.id);
      }
    } else {
      setImage(null);
      setValue('toolId', 0);
    }

    return () => {
      mounted = false;
    };
  }, [projectUrl, setValue, tool]);

  useEffect(() => {
    if (image) {
      clearErrors('image');
    }
  }, [clearErrors, image]);

  const closeReset = () => {
    reset({
      description: defaultValues?.description ?? '',
      projectUrl: defaultValues?.projectUrl ?? '',
      title: defaultValues?.title ?? '',
      toolId: defaultValues?.toolId ?? 0,
      shared: true,
      userId: defaultValues?.userId ?? 0,
    });
    setProjectUrl('');
    setImage(null);
    onClose();
  };

  return (
    <Modal
      className="max-w-4xl overflow-y-scroll overflow-x-hidden scrollbar-thumb-primary/40 scrollbar-track-primary-content scrollbar-thumb-rounded-full 
    scrollbar-track-rounded-full scrollbar-thin"
      visible={isVisible}
      onClose={closeReset}
    >
      <ConditionalRenderer condition={isVisible}>
        <div className="flex flex-col gap-5 ">
          <Text
            text={t(isUpdatingMode ? 'updateProject' : 'publishNewProject')}
            size="text-20"
            className="text-primary"
            format="rubik-500"
          />
          <form
            onSubmit={handleSubmit(onSubmit)}
            className="flex flex-col gap-3 md:grid md:grid-cols-2 relative bg-inherit"
          >
            <div className="flex flex-col gap-3 md:col-start-2">
              <TextInput
                errorLabelText={errors.projectUrl?.message}
                color={errors.projectUrl?.message ? 'warning' : 'primary'}
                placeholder={t('projectUrlPlaceholder')}
                register={register('projectUrl', {
                  onChange: deboundedOnChangeProjectUrl,
                  required: t('projectUrlRequired'),
                })}
                label={t('projectUrl')}
              />
              <ComponentGuard
                roles={[UserTypeEnum.TEACHER, UserTypeEnum.UNIT_ADMIN]}
              >
                <Controller
                  control={control}
                  name="userId"
                  render={({ field: { onChange } }) => (
                    <InfiniteSearchInput
                      displayName={student => handleUserFullName(student)}
                      service={studentsQuerykeys.list}
                      filters={{ unit: getAuthorizedUnits(user) }}
                      onSelect={({ id }) => onChange(id)}
                      input={{
                        label: t('isStudentProject'),
                        testId: 'userInput',
                        placeholder: t('studentSelectPlaceholder'),
                      }}
                      className={{ base: 'max-w-lg' }}
                      onDeselect={() => onChange(user?.id)}
                    />
                  )}
                />
              </ComponentGuard>

              <TextInput
                isLoading={isLoadingMetadata}
                placeholder={t('titlePlaceholder')}
                register={register('title', {
                  required: t('titleRequired'),
                })}
                errorLabelText={errors.title?.message}
                color={errors.projectUrl?.message ? 'warning' : 'primary'}
                label={t('title')}
              />

              <div className="flex flex-col gap-1">
                <Text text={t('description')} className="text-left" />
                <Controller
                  control={control}
                  name="description"
                  render={({ field: { onChange, value } }) => (
                    <MyCkeditor
                      isLoading={isLoadingMetadata}
                      handleContentChange={onChange}
                      content={value ?? ''}
                      testId="projectEditor"
                      folder={ImageFolderEnum.PROJECT}
                    />
                  )}
                />
              </div>
              <Controller
                control={control}
                name="toolId"
                render={({ field: { onChange } }) => (
                  <InfiniteSearchInput
                    displayName={tool => tool.name}
                    service={toolsQueryKeys.list}
                    selectedItem={tool}
                    onSelect={({ id }) => onChange(id)}
                    input={{
                      label: t('selectTool'),
                      testId: 'toolSelect',
                      errorLabelText: errors.toolId?.message,
                      disabled: !projectUrl,
                      placeholder: t('selectTool'),
                    }}
                    className={{ input: 'max-w-lg' }}
                  />
                )}
              />
            </div>
            <div className="flex flex-col sm:flex-row md:flex-col gap-2 md:col-start-1 md:row-start-1">
              {projectUrl ? (
                <EmbedPreview
                  className="h-auto aspect-video"
                  src={projectUrl}
                />
              ) : (
                <Previewer
                  testId="projectPreview"
                  className="w-full sm:w-1/2 md:w-full aspect-video"
                  icon={<EyeIcon className="w-6" />}
                  image={null}
                />
              )}
              <ProjectImagePreview
                image={image}
                isLoadingMetadata={isLoadingMetadata}
                onImageChange={onImageChange}
                onRemoveImage={() => setImage(null)}
                errorMessage={errors.image?.message}
              />
            </div>
            <div className="flex justify-end w-full gap-2 md:col-span-2">
              <MainButton
                type="button"
                color="neutral"
                text={t('cancel')}
                icon={<XIcon />}
                onClick={closeReset}
              />
              <MainButton
                type="submit"
                disabled={isSaveButtonDisabled}
                text={t(isUpdatingMode ? 'updateProject' : 'publishProject')}
                dataTestId="inventorySaveButton"
                loading={isCreating || isUpdating || isLoadingMetadata}
                icon={<SaveIcon />}
              />
            </div>
          </form>
        </div>
      </ConditionalRenderer>
    </Modal>
  );
}

interface PreviewerProps {
  isLoading?: boolean;
  image: string | null;
  icon: JSX.Element;
  className?: string;
  testId?: string;
}

const Previewer: React.FC<PreviewerProps> = ({
  icon,
  image,
  isLoading,
  className,
  testId,
}) => {
  return (
    <div
      data-testid={testId}
      className={`${
        isLoading ? 'bg-primary/40 animate-pulse' : 'bg-neutral/50'
      } ${twMerge(
        'flex items-center text-base-100 justify-center rounded-md bg-no-repeat bg-cover bg-center',
        className,
      )}`}
      style={{
        backgroundImage: isLoading ? undefined : `url(${image})`,
      }}
    >
      {icon}
    </div>
  );
};

interface ProjectImagePreviewProps {
  isLoadingMetadata: boolean;
  image: string | File | null;
  onImageChange: React.ChangeEventHandler<HTMLInputElement>;
  onRemoveImage(): void;
  errorMessage?: string;
}

const ProjectImagePreview: React.FC<ProjectImagePreviewProps> = ({
  isLoadingMetadata,
  image,
  onImageChange,
  onRemoveImage,
  errorMessage,
}) => {
  const imagePreview =
    typeof image === 'object'
      ? URL.createObjectURL(image || new Blob())
      : image;
  return (
    <div className="aspect-video w-full md:w-3/5">
      <Previewer
        testId="imagePreview"
        icon={!image ? <PhotographIcon className="w-12" /> : <React.Fragment />}
        image={imagePreview}
        isLoading={isLoadingMetadata}
        className="h-full"
      />
      <ImageInput
        image={image}
        onChange={onImageChange}
        onRemove={onRemoveImage}
        hidePreview
        hideRemoveButton
        errorMessage={errorMessage}
        className="-mt-2"
      />
    </div>
  );
};
