import { useQuery } from '@tanstack/react-query';
import { useParams } from 'react-router-dom';
import useAuth from '@/data/hook/useAuth';
import ChallengeContainer from './ChallengeContainer';
import StudentChallengeProgress from './StudentChallengeProgressView';
import ConditionalRenderer from '../../common/ConditionalRenderer';
import { isStudent } from '@/functions/auth';
import useLessonContext from '@/data/hook/lesson';
import { challengesQueryKeys } from '@/data/services/querykeys';

export default function Challenge() {
  const { slugCourseName, lessonId: lessonIdParam } = useParams();
  const lessonId = Number(lessonIdParam);
  const { lessonProgress } = useLessonContext();

  const { challengeProgress } = lessonProgress || {};
  const { user } = useAuth();

  const {
    data: challenge,
    isInitialLoading: isLoading,
    isError,
  } = useQuery({
    enabled: !isNaN(lessonId) && !!slugCourseName,
    ...challengesQueryKeys.get(lessonId),
  });

  return (
    <ChallengeContainer
      isError={isError}
      isLoading={isLoading}
      challenge={challenge?.questions.at(0)}
    >
      <ConditionalRenderer
        condition={!!challengeProgress && isStudent(user?.userType)}
      >
        <StudentChallengeProgress challengeProgress={challengeProgress} />
      </ConditionalRenderer>
    </ChallengeContainer>
  );
}
