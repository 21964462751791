import { SimpleTag } from '@/models/Tag';
import { XIcon } from '@heroicons/react/outline';
import React from 'react';
import ConditionalRenderer from '../../ConditionalRenderer';
import Skeleton from '../../Skeleton';
import Popper from '../../dataDisplay/Popper';
import Tag from '../../dataDisplay/Tag';
import Text from '../../dataDisplay/Text';
import { BaseInputProps } from '../BaseInput';
import { twMerge } from 'tailwind-merge';
import { ClassNameComponent } from '@/utils/ClassNameComponent';

export interface TagInputProps
  extends Omit<React.SelectHTMLAttributes<HTMLSelectElement>, 'className'>,
    BaseInputProps {
  tags?: SimpleTag[];
  onDelete?: (tag: SimpleTag) => void;
  addTagComponent: React.ReactNode;
  className?: ClassNameComponent & {
    tag?: string;
  };
}

type HandleDeleteArgs = {
  tag: SimpleTag;
  event: React.MouseEvent;
};

export default function TagInput(props: TagInputProps) {
  const {
    tags,
    disabled,
    id,
    className,
    errorLabelText,
    onDelete,
    label,
    addTagComponent,
    isLoading,
  } = props;

  const handleDelete = ({ tag, event }: HandleDeleteArgs) => {
    event.stopPropagation();
    onDelete?.(tag);
  };

  return (
    <div className="form-control relative flex flex-col">
      <Popper
        placement="bottom-start"
        disabled={disabled}
        referenceClassName="flex w-full"
        referenceContent={
          <div className="flex flex-col grow">
            <label
              htmlFor={id}
              className={twMerge(
                'label p-0 text-14 cursor-pointer',
                className?.label,
              )}
            >
              {label}
            </label>
            <ConditionalRenderer
              condition={!isLoading}
              fallback={
                <Skeleton
                  testId="tagInputSkeleton"
                  className={`w-full h-8 rounded-md bg-primary-content ${
                    className ?? 'lg:max-w-[100%]'
                  }`}
                />
              }
            >
              <div
                className="flex items-center gap-2 min-h-8 rounded-lg px-2.5 py-1 border border-primary flex-wrap"
                data-testid="tagPreview"
              >
                {tags?.map(tag => {
                  return (
                    <Tag
                      key={tag.id}
                      testId="selectedTag"
                      color="custom"
                      text={tag.name}
                      style={{
                        backgroundColor: tag.color || 'var(--primary)',
                        color: 'var(--base-100)',
                      }}
                      className={twMerge(
                        'text-14 shadow-default h-5 px-2.5 gap-1 flex-row-reverse items-center',
                        className?.tag,
                      )}
                      icon={
                        <XIcon
                          className="w-3.5"
                          onClick={event => handleDelete({ tag, event })}
                        />
                      }
                    />
                  );
                })}
              </div>
            </ConditionalRenderer>
          </div>
        }
        popperContent={
          <div className="border-[1px] relative w-[432px] border-base-content/20 rounded-md">
            {addTagComponent}
          </div>
        }
      />
      <ConditionalRenderer condition={!!errorLabelText}>
        <Text text={errorLabelText} className="pt-2 text-primary" />
      </ConditionalRenderer>
    </div>
  );
}
