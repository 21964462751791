import React from 'react';
import Text from '../common/dataDisplay/Text';
import ViewAvatar from '../store/ViewAvatar';
import { handleUserFullName } from '@/functions/handleUserFullName';
import User from '@/models/User';
import { SimplifiedCourseProgress } from '@/models/StudentCourseProgress';
import { ProgressSpace } from '../common/ProgressIcons';
import Skeleton from '../common/Skeleton';
import ConditionalRenderer from '../common/ConditionalRenderer';
import { isStudent } from '@/functions/auth';
import StudentLevelProgress from '../level/StudentLevelProgress';
import useStudentContext from '@/data/hook/student';
import { useQuery } from '@tanstack/react-query';
import { rewardsQueryKeys } from '@/data/services/querykeys';

interface ProfileUserCardProps {
  isUser: boolean;
  studentProgress?: SimplifiedCourseProgress[];
  user: User;
  isLoading: boolean;
  isLoadingProgress?: boolean;
}

export function ProfileUserCard({
  isUser,
  user,
  isLoading,
  isLoadingProgress,
  studentProgress,
}: ProfileUserCardProps) {
  const { rewardsData: userRewards } = useStudentContext();
  const { data: rewardsData } = useQuery({
    ...rewardsQueryKeys.get(user.id ?? 0),
    enabled: isStudent(user.userType),
    initialData: isUser ? userRewards : undefined,
  });

  if (isLoading) {
    return (
      <Skeleton className="z-30 self-stretch lg:self-start flex flex-col justify-center items-center gap-5 py-5 px-9 rounded-xl h-20 lg:h-72 shadow-default   lg:w-76 bg-accent-content sm:flex-row lg:flex-col -mt-20 lg:px-4 lg:py-6" />
    );
  }

  return (
    <div className="z-30 relative self-stretch lg:self-start flex flex-col justify-center items-center gap-3 py-5 px-9 rounded-xl shadow-default lg:w-76 bg-base-100 sm:flex-row lg:flex-col -mt-5 lg:px-4 lg:py-6">
      <div className="avatar absolute sm:static w-auto max-w-20 xs:max-w-none h-20 aspect-[4/3] -left-2 -top-5 sm:top-44 lg:w-auto lg:h-48 lg:left-0 lg:-top-32 lg:relative lg:-mb-36">
        <ViewAvatar
          userId={user.id}
          testId="myProfileAvatar"
          isLoading={isLoading}
          visiting={!isUser}
        />
      </div>

      <div className="flex mb-2 ml-6 sm:mb-0 sm:ml-0 flex-col justify-center items-center gap-3.5 lg:pt-4 lg:w-full">
        <Text testId="username" text={user.username} format="rubik-400" />
        <Text
          testId="fullName"
          text={handleUserFullName(user)}
          format="rubik-500"
          className="text-accent"
        />
      </div>

      <Divider />

      <StudentLevelProgress
        className="min-w-32 w-10/12 md:w-6/12 lg:w-10/12 shrink"
        rewardsAccount={rewardsData}
      />
      <ConditionalRenderer condition={isStudent(user.userType)}>
        <RenderUserProgress
          studentProgressList={studentProgress}
          isLoading={isLoadingProgress}
        />
      </ConditionalRenderer>
    </div>
  );
}

const Divider = () => (
  <div className="divider divider-vertical sm:divider-horizontal lg:divider-vertical m-0" />
);

const RenderUserProgress = ({
  studentProgressList,
  isLoading,
}: {
  studentProgressList?: SimplifiedCourseProgress[];
  isLoading?: boolean;
}) => {
  if (isLoading) {
    return <Skeleton className="w-full h-10 bg-accent-content rounded-xl" />;
  }

  return (
    <React.Fragment>
      <Divider />
      <ul className="flex flex-wrap items-center justify-center gap-4">
        {studentProgressList?.map(studentProgress => (
          <li
            key={studentProgress.id}
            className="self-center flex flex-rol gap-1 items-center"
          >
            <ProgressSpace
              className="scale-75 shrink-0"
              progress={studentProgress.progress * 100}
              type={studentProgress.coursePath.course.type}
            />
            <Text
              text={studentProgress.coursePath.course.name}
              className="text-accent"
              format="rubik-500"
            />
          </li>
        ))}
      </ul>
    </React.Fragment>
  );
};

export default ProfileUserCardProps;
