import { ActivityTypeEnum } from './Activity';
import {
  DNDOrdenationActivity,
  DNDPositionalActivity,
} from './ActivityElement';
import { DNDColumn } from './DNDColumn';
import DNDColumnProgress from './DNDColumnProgress';
import { Question } from './Question';

type QuestionAttemptType = Pick<Question, 'order' | 'type'> & {
  answers?: any;
  data?: any;
  progress?: TextAttempt;
};

export class QuestionAttempt {
  order!: number;
  type!: ActivityTypeEnum;
  answers?: any;
  data?: any;
  progress?: TextAttempt;

  constructor(questionAttempt: QuestionAttemptType) {
    Object.assign(this, questionAttempt);
  }
}

type TrueOrFalseAttemptAnwserType = {
  isCorrect: boolean;
  isDone: boolean;
  answer: boolean | null;
};

export class TrueOrFalseAttemptAnwser {
  isCorrect!: boolean;
  isDone!: boolean;
  answer!: boolean | null;

  constructor(questionAttempt: TrueOrFalseAttemptAnwserType) {
    Object.assign(this, questionAttempt);
  }
}

type QuizAttemptAnswerType = {
  isCorrect: boolean;
  isDone: boolean;
  answer: string | null;
};

export class QuizAttemptAnswer {
  isCorrect!: boolean;
  isDone!: boolean;
  answer!: string | null;

  constructor(questionAttempt: QuizAttemptAnswerType) {
    Object.assign(this, questionAttempt);
  }
}

type TextAttemptType = {
  timer: number;
  isDone: boolean;
};

export class TextAttempt {
  timer!: number;
  isDone!: boolean;

  constructor(questionAttempt: TextAttemptType) {
    Object.assign(this, questionAttempt);
  }
}

type DNDAttemptAnswerType = {
  id: number;
  gradePercent: number;
  isCurrentQuestion: boolean;
  isDone: boolean;
};

type DNDPositionalAttemptAnswerType = DNDAttemptAnswerType & {
  dndPositionalActivity: DNDPositionalActivity;
  columnProgress: DNDColumnProgress;
  questionColumn: DNDColumn;
};

export class DNDPositionalAttemptAnswer {
  id!: number;
  gradePercent!: number;
  isCurrentQuestion!: boolean;
  isDone!: boolean;
  dndPositionalActivity!: DNDPositionalActivity;
  columnProgress!: DNDColumnProgress;
  questionColumn!: DNDColumn;

  constructor(questionAttempt: DNDPositionalAttemptAnswerType) {
    Object.assign(this, questionAttempt);
  }
}

export type DNDOrdenationAttemptAnswerType = DNDAttemptAnswerType & {
  dndOrdenationActivity: DNDOrdenationActivity;
  columnProgress: DNDColumnProgress[];
};

export class DNDOrdenationAttemptAnswer {
  id!: number;
  gradePercent!: number;
  isCurrentQuestion!: boolean;
  isDone!: boolean;
  dndOrdenationActivity!: DNDOrdenationActivity;
  columnProgress!: DNDColumnProgress;
  questionColumn!: DNDColumn;

  constructor(questionAttempt: DNDOrdenationAttemptAnswerType) {
    Object.assign(this, questionAttempt);
  }
}

export type UpdateQuestionAttempt = {
  questionId: number;
  questionProgressId: number;
};
