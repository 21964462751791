import { BaseFilters } from '@/models/Service';
import requester from '@/axios';
import JsonApiResponse from '@/models/JsonApiResponse';
import LessonProgress from '@/models/LessonProgress';

export type LessonProgressFilters = {
  lesson?: number;
  studentId?: number[];
  klassId?: number;
  isActive?: boolean;
  courseProgressId?: number[];
} & BaseFilters;

export const listLessonProgressBySlugCourse = async (
  slugCourse?: string,
  params: LessonProgressFilters = {},
) => {
  const { data } = await requester().get<JsonApiResponse<LessonProgress>>(
    `course-progress/${slugCourse}/lessons/`,
    {
      params,
    },
  );

  return data;
};

export const updateLessonProgress = async ({
  lessonProgressId,
  lessonProgress,
}: {
  lessonProgressId: number;
  lessonProgress: Partial<LessonProgress>;
}) => {
  const { data: lessonProgressUpdated } =
    await requester().patch<LessonProgress>(
      `lesson-progress/${lessonProgressId}/`,
      lessonProgress,
    );
  return lessonProgressUpdated;
};

export const getLessonProgress = async (lessonProgressId: number) => {
  const { data } = await requester().get<LessonProgress>(
    `lesson-progress/${lessonProgressId}/`,
  );
  return data;
};

export const listLessonProgress = async (params: LessonProgressFilters) => {
  const { data } = await requester().get<JsonApiResponse<LessonProgress>>(
    'lesson-progress/',
    {
      params,
    },
  );
  return data;
};
