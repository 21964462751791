import React from 'react';
import { twMerge } from 'tailwind-merge';
import { CourseTypeEnum } from '../../models/Course';
import {
  GradientCheckIcon,
  RocketKidsColorIcon,
  RocketQuickColorIcon,
  RocketTeensColorIcon,
  RocketYoungColorIcon,
} from '../icons';

interface ProgressIconsProps {
  progress: number;
  className?: string;
  type?: string;
}

interface ProgressIconsPropsWithChildren extends ProgressIconsProps {
  children: React.ReactNode;
  showProgress?: boolean;
}

export function ProgressCircle({
  progress,
  children,
  className,
}: ProgressIconsPropsWithChildren) {
  const circleOff = 2 * Math.PI * 18.5 * ((100 - progress) / 100);
  return (
    <svg
      width="45"
      height="45"
      viewBox="-22.5 -22.5 45 45"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <circle
        cx="0"
        cy="0"
        r="18.5"
        stroke="url(#gradient_op15)"
        strokeWidth="7"
      />
      <circle
        cx="0"
        cy="0"
        r="18.5"
        stroke="url(#gradient)"
        strokeWidth="7"
        strokeLinecap="round"
        strokeDasharray={2 * Math.PI * 18.5}
        strokeDashoffset={isNaN(circleOff) ? 0 : circleOff}
        transform="rotate(-90)"
        style={{ transition: 'stroke-dashoffset 0.5s ease-in-out' }}
      />
      <defs>
        <linearGradient
          id="gradient"
          x1="40.7393"
          y1="0.968936"
          x2="38.2087"
          y2="46.9206"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="var(--secondary)" />
          <stop offset="1" stopColor="var(--primary)" />
        </linearGradient>

        <linearGradient
          id="gradient_op15"
          x1="40.7393"
          y1="0.968936"
          x2="38.2087"
          y2="46.9206"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="rgb(130, 90, 224, 0.15)" />
          <stop offset="1" stopColor="rgb(27, 159, 255, 0.15)" />
        </linearGradient>
      </defs>
      {children}
    </svg>
  );
}

export function ProgressSpace(props: ProgressIconsProps) {
  function IconHandler({ type }: { type: string }) {
    switch (type) {
      case CourseTypeEnum.CTRL_KIDS:
        return (
          <svg x={-19} y={-18}>
            <RocketKidsColorIcon />
          </svg>
        );
      case CourseTypeEnum.CTRL_TEENS:
        return (
          <svg x={-18} y={-18.5}>
            <RocketTeensColorIcon />
          </svg>
        );
      case CourseTypeEnum.CTRL_YOUNG:
        return (
          <svg x={-17.5} y={-18}>
            <RocketYoungColorIcon />
          </svg>
        );
      default:
        return (
          <svg x={-19} y={-18}>
            <RocketQuickColorIcon />
          </svg>
        );
    }
  }
  return (
    <ProgressCircle className={props.className} progress={props.progress}>
      <IconHandler type={props.type || ''} />
    </ProgressCircle>
  );
}

export function LessonProgressIconCheck({
  children,
  className,
  progress,
  showProgress = true,
}: ProgressIconsPropsWithChildren) {
  const fullCheckOffset = 21.31096649169922;
  progress *= 100;
  progress = progress > 100 ? 100 : progress;
  const checkStrokeoffset = fullCheckOffset * ((100 - progress) / 100);
  return (
    <div className={twMerge('relative z-10 flex items-center', className)}>
      <ProgressCircle
        className={`${!showProgress ? 'opacity-0' : ''}`}
        progress={progress}
      >
        <GradientCheckIcon strokeDashoffset={checkStrokeoffset} />
      </ProgressCircle>

      <div className="avatar h-full flex justify-center items-center absolute left-0 top-0 -z-30 overflow-hidden">
        {children}
      </div>
    </div>
  );
}
