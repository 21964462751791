import Area from '@/models/Area';
import { Tree } from './Tree';
import { isEqual } from 'lodash';

export default class TreeArea extends Tree<Area> {
  insert(element: Area, parentElementId?: number) {
    const node = this.nodes[element.id];
    if (node && isEqual(node.data, element)) return;
    super.insert(element, parentElementId);
  }

  move(element: Area, newParent: number) {
    const auxChildren = this.nodes[element.id].children;
    this.delete(element.id);
    this.insert(element, newParent);
    this.nodes[element.id].children = auxChildren;
  }
}
