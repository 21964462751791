import moment from 'moment';
import { useTranslation } from 'react-i18next';
import RoundedButton from '@/components/common/buttons/RoundedButton';
import Modal from '@/components/common/modals/Modal';
import DateInput from '@/components/common/dataInput/DateInput';
import Skeleton from '@/components/common/Skeleton';
import { Controller, useForm } from 'react-hook-form';
import TimeInput from '@/components/common/dataInput/TimeInput';
import Text from '@/components/common/dataDisplay/Text';
import { ApiError } from '@/models/Errors';
import alert from '@/utils/UseAlert';
import { useMutation, useQuery } from '@tanstack/react-query';
import { updateScheduledLesson } from '@/data/services/scheduledLessonsServices';
import ScheduledLesson from '@/models/ScheduledLesson';
import { Matcher } from 'react-day-picker';
import { BlockedTimes } from '@/components/common/dataInput/TimePicker';
import { formatLessonName } from '@/functions/lessonsName';
import { useEffect } from 'react';
import { isEmpty } from 'lodash';
import { MINUTES_OPTIONS } from '@/constants';
import useAuth from '@/data/hook/useAuth';
import {
  formatTimezoneOffset,
  getBlockedTimeByType,
  timezonedToBrowserDate,
} from '@/utils/datetimes';
import OutlineButton from '@/components/common/buttons/OutlineButton';
import { ModalState } from './ModalHandler';
import ConditionalRenderer from '@/components/common/ConditionalRenderer';
import {
  scheduledLessonsQueryKeys,
  unitsQueryKeys,
} from '@/data/services/querykeys';
import useInfiniteService from '@/data/hook/useInfiniteService';

interface UpdateDateKlassProps {
  onClickCancel: () => void;
  scheduledLesson: ScheduledLesson;
  updateScheduled?: (a: any) => Promise<void>;
  duration?: number;
  unitId?: number;
  redirectToModal?(modal: ModalState): void;
}

export default function UpdateDateKlass({
  scheduledLesson,
  onClickCancel,
  duration = 90,
  unitId,
  updateScheduled,
  redirectToModal,
}: UpdateDateKlassProps) {
  const { t } = useTranslation('translation', {
    keyPrefix: 'calendar.admin.manageKlass.updateDateKlass',
  });

  const { t: manageKlassT } = useTranslation('translation', {
    keyPrefix: 'calendar.admin.manageKlass',
  });

  const datetime = scheduledLesson.datetime;
  const klassId = scheduledLesson.klass;

  type DatetimeForm = {
    date: string;
    time: string;
  };

  const {
    control,
    getValues,
    watch,
    handleSubmit,
    setValue,
    formState: { errors },
    reset,
  } = useForm<DatetimeForm>({
    defaultValues: {
      date: datetime,
      time: moment(datetime).format('HH:mm'),
    },
  });

  const DEFAULT_DATE_FORMAT = 'YYYY-MM-DD';
  const { user } = useAuth();

  const { data: unit, isInitialLoading: isFetchingRecessCalendar } = useQuery({
    ...unitsQueryKeys.get(Number(unitId)),
    enabled: !!unitId && !isNaN(unitId),
  });

  const {
    results: previousLessons,
    isInitialLoading: isLoadingPreviousLesson,
  } = useInfiniteService({
    enabled: scheduledLesson.order > 1,
    ...scheduledLessonsQueryKeys.list({
      klassId,
      pageSize: 1,
      isActive: true,
      orderLt: scheduledLesson.order,
      ordering: '-datetime',
    })._ctx.infinity,
  });

  const previousLesson = previousLessons.at(0);

  const { results: nextLessons, isInitialLoading: isLoadingNextLesson } =
    useInfiniteService({
      ...scheduledLessonsQueryKeys.list({
        klassId,
        pageSize: 1,
        isActive: true,
        orderGt: scheduledLesson.order,
      }),
    });
  const nextLesson = nextLessons.at(0);

  const recessDates =
    unit?.recess.map(date => timezonedToBrowserDate(date, unit.timezone)) ?? [];

  const parseUserTimezoneToUnitTimezone = (date?: string) => {
    return moment(date)
      .tz(user?.timezone ?? 'America/Sao_Paulo')
      .clone()
      .tz(unit?.timezone ?? 'America/Sao_Paulo')
      .toISOString();
  };

  const beforeLimitMock = new Date('1900-01-01');

  const userTimezone = user?.timezone ?? 'America/Sao_Paulo';

  const minimalDatetime = getBlockedTimeByType(
    previousLesson?.datetime ?? beforeLimitMock,
    'previous',
    userTimezone,
    duration,
  );

  const maximalDate = getBlockedTimeByType(
    nextLesson?.datetime ?? '',
    'next',
    userTimezone,
    duration,
  );

  const rangeDateLimitBefore = timezonedToBrowserDate(
    parseUserTimezoneToUnitTimezone(minimalDatetime),
    unit?.timezone,
  );
  const rangeDateLimitAfter = timezonedToBrowserDate(
    maximalDate,
    unit?.timezone,
  );

  const rangeDateLimit = {
    before: previousLesson ? rangeDateLimitBefore : beforeLimitMock,
    after: nextLesson ? rangeDateLimitAfter : undefined,
  };

  const disableDates: Matcher[] = [...recessDates, rangeDateLimit];

  const createPreviousLessonBlockedTime = () => {
    const previousLessonDatetime = moment(
      parseUserTimezoneToUnitTimezone(minimalDatetime),
    ).tz(unit?.timezone ?? 'America/Sao_Paulo');

    const hour = previousLessonDatetime.hour();
    const minutes = previousLessonDatetime.minute();

    const blockedHours = Array.from(
      {
        length: hour + 1,
      },
      (_, index) => index,
    );

    const blockedTimes: BlockedTimes = blockedHours.reduce<BlockedTimes>(
      (prev, hour, i, list) => {
        prev[hour] =
          list.length === i + 1
            ? MINUTES_OPTIONS.filter(min => min < minutes)
            : MINUTES_OPTIONS;
        return prev;
      },
      {},
    );
    return blockedTimes;
  };

  const createNextLessonBlockedTime = () => {
    const nextLessonDatetime = moment(
      parseUserTimezoneToUnitTimezone(maximalDate),
    ).tz(unit?.timezone ?? 'America/Sao_Paulo');
    const hour = nextLessonDatetime.hour();
    const minute = nextLessonDatetime.minute();

    const blockedHours = Array.from(
      {
        length: 24 - hour,
      },
      (_, index) => hour + index,
    );

    const blockedTimes: BlockedTimes = blockedHours.reduce<BlockedTimes>(
      (prev, hour, i) => {
        prev[hour] =
          i === 0
            ? MINUTES_OPTIONS.filter(min => min > minute)
            : MINUTES_OPTIONS;
        return prev;
      },
      {},
    );
    return blockedTimes;
  };

  const createCombinedBlockedTimes = () => {
    const previousBlockedTimes = createPreviousLessonBlockedTime();
    const nextBlockedTime = createNextLessonBlockedTime();
    const mergedTimes = Object.entries(nextBlockedTime).reduce(
      (prev, [key, value]) => {
        const otherBlockedTime = previousBlockedTimes[+key];

        return {
          ...prev,
          [+key]: otherBlockedTime ? otherBlockedTime.concat(value) : value,
        };
      },
      {},
    );

    return {
      ...previousBlockedTimes,
      ...mergedTimes,
    };
  };

  const formatBlockedDate = (date: string) => {
    const unitTimezone = unit?.timezone ?? 'America/Sao_Paulo';
    return moment(date)
      .tz(user?.timezone ?? 'America/Sao_Paulo')
      .clone()
      .tz(unitTimezone)
      .format(DEFAULT_DATE_FORMAT);
  };

  const getBlockedTimes = (selectedDate: string): BlockedTimes => {
    const formatedDate = formatBlockedDate(selectedDate);
    const beforeLimit = formatBlockedDate(minimalDatetime);
    const afterLimit = formatBlockedDate(maximalDate);

    const blockedTimeFactories = {
      [beforeLimit]: createPreviousLessonBlockedTime,
      [afterLimit]: createNextLessonBlockedTime,
      sameDate: createCombinedBlockedTimes,
    };

    const createBlockedTime =
      blockedTimeFactories[
        beforeLimit === afterLimit ? 'sameDate' : formatedDate
      ];

    const blockedTimes = createBlockedTime?.() ?? {};

    return blockedTimes;
  };

  const blockedTimes = getBlockedTimes(watch('date'));

  const isValidTime = (time: string) => {
    const [hour = '--', minute = '--'] = time.split(':');

    return !isNaN(+hour) && !isNaN(+minute);
  };

  const isLoadingLessons = isLoadingNextLesson || isLoadingPreviousLesson;

  const blockedTimeValues = Object.values(blockedTimes);

  const hasAvailableDatetimes =
    blockedTimeValues.length <= 22 ||
    blockedTimeValues.some(time => time.length < MINUTES_OPTIONS.length - 1);

  const hasErrors = !!Object.keys(errors).length;
  const isConfirmButtonDisabled = !hasAvailableDatetimes || hasErrors;

  async function onFinishChanges({ date, time }: DatetimeForm) {
    const [hour, minute] = time.split(':');

    const formatedDatetime = moment(date)
      .tz(unit?.timezone ?? 'America/Sao_Paulo')
      .set('hours', Number(hour))
      .set('minutes', Number(minute))
      .toISOString();

    if (formatedDatetime) {
      await updateScheduledLesson(scheduledLesson.id, {
        datetime: formatedDatetime,
      });
    }
  }

  const onSubmit = (data: DatetimeForm) => onFinish(data);

  const { mutate: onFinish, isLoading: isUpdating } = useMutation(
    onFinishChanges,
    {
      async onSuccess() {
        await updateScheduled?.('');
        alert.success(t('success'));
      },
      onError(error: any) {
        const api = new ApiError(error);
        alert.error(api.getErrorMessage());
      },
      onSettled() {
        onClickCancel();
      },
    },
  );

  const validateTime = (time: string) => {
    return isValidTime(time) ? undefined : t('insertValidTime');
  };

  useEffect(() => {
    let mounted = true;
    const resetTime = () => {
      const time = getValues('time');
      if (!isValidTime(time) || isEmpty(blockedTimes)) {
        return;
      }
      const [hour, minute] = time.split(':');
      const isBlockedTime = blockedTimes[+hour]?.includes(Number(minute));

      isBlockedTime && setValue('time', '--:--');
    };
    if (mounted) {
      resetTime();
    }

    return () => {
      mounted = false;
    };
  }, [blockedTimes, getValues, setValue]);

  const redirect = (targetModal: ModalState) => {
    onClickCancel();

    redirectToModal?.(targetModal);
  };

  useEffect(() => {
    if (unit && user) {
      const timezonedMoment = moment(datetime)
        .tz(user?.timezone)
        .clone()
        .tz(unit.timezone);
      reset({
        date: timezonedMoment.toISOString(),
        time: timezonedMoment.format('HH:mm'),
      });
    }
  }, [datetime, reset, unit, user]);

  return (
    <Modal visible={true} onClose={onClickCancel}>
      <form
        onSubmit={handleSubmit(onSubmit)}
        className="flex flex-col gap-y-6 w-full overflow-visible"
      >
        <Text
          format="rubik-500"
          text={t('updateDateKlassTitle')}
          className="text-primary text-24"
        />
        <div className="flex gap-2 flex-wrap justify-center">
          <Text
            format="rubik-400"
            text={t('postponeAnticipateWarning')}
            className="text-18"
          />
          <p className="flex gap-2 items-center">
            <OutlineButton
              onClick={() => redirect(ModalState.POSTPONE)}
              text={manageKlassT('postpone')}
            />
            <Text as="span" text={t('or')} />
            <OutlineButton
              onClick={() => redirect(ModalState.ANTICIPATE)}
              text={manageKlassT('anticipate')}
            />
          </p>
        </div>
        <ConditionalRenderer
          condition={!isLoadingLessons}
          fallback={
            <Skeleton className="w-full h-28 rounded-xl bg-primary-content" />
          }
        >
          <ConditionalRenderer
            condition={hasAvailableDatetimes}
            fallback={
              <div className="flex flex-col gap-2 border border-error rounded-xl p-3">
                <Text
                  text={t('noAvailableDatetime')}
                  format="rubik-500"
                  className="text-error text-18"
                />
                <Text text={t('updateOtherLessonDate')} />
              </div>
            }
          >
            <Text
              format="rubik-400"
              text={t('selectDate')}
              className="text-18"
            />
            <div className="flex flex-col p-6 rounded-xl gap-3.5 items-center justify-center border border-primary">
              <Text
                format="rubik-500"
                text={formatLessonName(scheduledLesson.lesson, scheduledLesson)}
                className="text-primary text-18"
              />
              {isFetchingRecessCalendar ? (
                <Skeleton className="w-full rounded-lg mb-4 h-8 bg-primary-content" />
              ) : (
                <div className="flex gap-2 justify-center items-center">
                  <Controller
                    name="date"
                    control={control}
                    render={({ field: { onChange, value } }) => (
                      <DateInput
                        disabledDates={disableDates}
                        autoComplete="off"
                        className={{ base: 'w-40' }}
                        value={value}
                        timezone={unit?.timezone}
                        onDateChange={date => onChange(date)}
                      />
                    )}
                  />
                  <Controller
                    control={control}
                    name="time"
                    rules={{ validate: validateTime }}
                    render={({ field: { onChange, value } }) => (
                      <TimeInput
                        blockedTimes={blockedTimes}
                        autoComplete="off"
                        className={{ base: 'w-32' }}
                        value={value}
                        onTimeChange={time => onChange(time)}
                      />
                    )}
                  />
                </div>
              )}
              <Text
                format="rubik-400"
                text={t('timezoneWarning', {
                  timezone: formatTimezoneOffset(
                    unit?.timezone ?? 'America/Sao_Paulo',
                  ),
                })}
                className="text-18 text-warning"
              />
              <Text
                format="rubik-400"
                text={errors.time?.message}
                className="text-18 text-error"
              />
            </div>
            <Text
              format="rubik-400"
              text={t('confirmDate')}
              className="text-18"
            />
          </ConditionalRenderer>
        </ConditionalRenderer>
        <div className="flex w-full justify-around gap-6">
          <RoundedButton
            text={t('cancel')}
            color="neutral"
            type="reset"
            className="w-full"
            onClick={onClickCancel}
          />
          <RoundedButton
            text={t('confirm')}
            className="w-full"
            loading={isUpdating}
            disabled={isConfirmButtonDisabled}
          />
        </div>
      </form>
    </Modal>
  );
}
