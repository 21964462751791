import moment from 'moment';

export const translateKlassName = (klassName?: string) => {
  let newName = klassName || '';
  if (klassName) {
    moment
      .localeData('en')
      .weekdays()
      .forEach((day, i) => {
        const regex = new RegExp(day, 'gi');

        if (klassName.toLowerCase().includes(day.toLowerCase())) {
          newName = newName.replace(
            regex,
            moment.weekdaysShort(i).toString().toUpperCase(),
          );
        }
      });
  }
  return newName;
};
