import { SwitchHorizontalIcon } from '@heroicons/react/outline';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { AnimatePresence, motion } from 'framer-motion';

import MainButton from '@/components/common/buttons/MainButton';
import PillComponent from '@/components/common/dataDisplay/PillComponent';
import Text from '@/components/common/dataDisplay/Text';
import ConditionalRenderer from '@/components/common/ConditionalRenderer';
import { accordionX } from '@/utils/animations/commom';

type AlterDatePillProps = {
  dateTime: Date;
  klassDuration?: number;
  editing?: boolean;
  setModalState?: () => void;
  editPermission?: boolean;
};

export function AlterDatePill({
  dateTime,
  klassDuration,
  editing,
  setModalState,
  editPermission,
}: AlterDatePillProps) {
  const { t } = useTranslation('translation', {
    keyPrefix: 'calendar.admin.manageKlass',
  });

  return (
    <PillComponent testId="alterDate" className="w-fit flex-wrap">
      <Text
        text={moment(dateTime).format('DD/MM')}
        size="text-12 md:text-14"
        format="poppins-600"
        className="text-primary h-5 flex self-center text-[0.75rem] lg:text-[1rem]"
      />
      <Text
        text={`${moment(dateTime).format('HH:mm')}-${moment(dateTime)
          .add(klassDuration || 90, 'minutes')
          .format('HH:mm')}`}
        size="text-14"
        className="text-base-content h-fit ml-2 text-[0.75rem] lg:text-[1rem] shrink-0"
      />
      <ConditionalRenderer condition={editPermission}>
        <AnimatePresence>
          <motion.div {...accordionX(editing)} className="overflow-hidden">
            <MainButton
              dataTestId="alterLessonDateButton"
              icon={<SwitchHorizontalIcon />}
              color="custom"
              className="text-primary gap-1 ml-2 whitespace-nowrap text-[0.75rem] lg:text-[1rem]"
              onClick={setModalState}
              text={t('alterLessonDate')}
            />
          </motion.div>
        </AnimatePresence>
      </ConditionalRenderer>
    </PillComponent>
  );
}
