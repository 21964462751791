import { useState } from 'react';
import User, { UserStatusEnum } from '@/models/User';
import {
  InformationCircleIcon,
  BookOpenIcon,
  ChevronUpIcon,
} from '@heroicons/react/outline';
import UserProfile from '@/models/UserProfile';
import moment from 'moment';
import EditTeacher from './EditTeacher';
import { useTranslation } from 'react-i18next';
import IconButton from '../../common/buttons/IconButton';
import Row from '../../common/table/Row';
import BodyCell from '../../common/table/BodyCell';
import Text from '../../common/dataDisplay/Text';
import { CheckCircleIcon } from '@heroicons/react/solid';
import { Teacher } from '@/models/Klass';
import { ScheduledLessonsTeacher } from './ScheduledLessonsTeacher';
import { TeacherHeader } from '@/utils/HeaderTypes';
import { Tooltip } from '../../common/dataDisplay/Tooltip';
import { motion, AnimatePresence } from 'framer-motion';
import Observable from '@/utils/observers/ObserverPattern';
import { editFormAnimation } from '@/utils/animations/formAnimations';
import UnitDisplay from '../../common/table/UnitDisplay';
import useFilterParams from '@/utils/UseFilterParams';
import { ListUsersFilters } from '@/data/services/userServices';
import { useParams } from 'react-router-dom';
import { handleUserFullName } from '@/functions/handleUserFullName';

interface ListTeachersProps {
  teachersList: Teacher[];
  titles: TeacherHeader;
}

interface RenderRowProps {
  teacher: Teacher;
  titles: TeacherHeader;
  onUpdate?: (userProfile?: UserProfile, teacher?: Teacher) => void;
  observer?: Observable<number>;
}

type Tabs = 'info' | 'lessons';

export default function DataTeachersTable({
  teachersList,
  titles,
}: ListTeachersProps) {
  const observer = new Observable<number>();
  return (
    <tbody className="bg-transparent flex flex-col gap-y-2.5">
      {teachersList.length > 0 &&
        teachersList.map(teacher => (
          <RenderRow
            key={teacher.id}
            titles={titles}
            teacher={teacher}
            observer={observer}
          />
        ))}
    </tbody>
  );
}

function RenderRow({ teacher, onUpdate, titles, observer }: RenderRowProps) {
  const { actionId } = useParams();

  const { filterParams } = useFilterParams<ListUsersFilters>();

  const search = filterParams.get('search') ?? undefined;

  const [viewInformations, setViewInformations] = useState(
    search === handleUserFullName(teacher),
  );

  const [tab, setTab] = useState<Tabs>((actionId as Tabs) || 'info');

  teacher.id &&
    observer?.subscribe({
      id: teacher.id,
      action: () => {
        setViewInformations(false);
      },
    });

  const toogleViewInformations = (newTab?: Tabs) => {
    if (tab !== newTab) {
      newTab && setTab(newTab);
      setViewInformations(true);
      return;
    }
    if (!viewInformations) observer?.notifyAll(teacher.id);
    setViewInformations(old => !old);
    if (tab) setTab(tab);
  };
  const { t } = useTranslation('translation', {
    keyPrefix: 'manageTeacher.dataUserTable',
  });

  function renderLastLogin(date: Date | undefined) {
    if (date === null || date === undefined) {
      return t('never');
    } else {
      const newDate = new Date(date);
      let dateString = moment(newDate).format('DD/MM/YYYY');
      return dateString;
    }
  }

  return (
    <Row testId="teacherRow">
      <BodyCell className="justify-between p-0">
        <div
          style={{ width: `${titles.name.size}%` }}
          className="flex items-center"
          onClick={() => toogleViewInformations(tab)}
        >
          <IconButton
            testId="teamInfoButton"
            className="transition ease-in-out duration-150"
            icon={
              <ChevronUpIconWithAnimation
                animate={{
                  rotate: !viewInformations ? 180 : 0,
                }}
                className="w-3 h-3"
              />
            }
          />
          <Text
            text={`${teacher.firstName} ${teacher.lastName}`}
            className="cursor-pointer pl-5"
            format="rubik-400"
          />
        </div>

        <div
          style={{ width: `${titles?.students.size}%` }}
          className="flex items-center"
        >
          <Text format="rubik-400" text={String(teacher.studentsCount)} />
        </div>

        <div
          style={{ width: `${titles?.churn.size}%` }}
          className="flex items-center"
        >
          <Text
            format="rubik-400"
            text={`${Math.trunc(teacher.studentsChurn)}%`}
          />
        </div>

        <div
          style={{ width: `${titles?.presence.size}%` }}
          className="flex items-center"
        >
          <Text
            format="rubik-400"
            text={`${Math.trunc(teacher.studentsPresence)}%`}
          />
        </div>

        <div
          style={{ width: `${titles?.lastAccess.size}%` }}
          className="flex items-center"
        >
          <Text
            format="rubik-400"
            text={`${renderLastLogin(teacher.lastLogin)}`}
          />
        </div>
        <UnitDisplay width={titles.unit?.size} unitsIds={teacher.unitsIds} />

        <div
          style={{ width: `${titles?.status.size}%` }}
          className="flex items-center"
        >
          {teacher.status === UserStatusEnum.INACTIVE ? (
            <CheckCircleIcon className="w-5 h-5 text-neutral-content" />
          ) : (
            <CheckCircleIcon className="w-5 h-5 text-primary" />
          )}
        </div>

        <div
          style={{ width: `${titles?.actions?.size}%` }}
          className="flex items-center gap-2.5"
        >
          <Tooltip text={t('IntoTooltip')}>
            <IconButton
              onClick={() => toogleViewInformations('info')}
              icon={<InformationCircleIcon className="text-primary w-6 h-6" />}
            />
          </Tooltip>
          <Tooltip text={t('lessonsTooltip')}>
            <IconButton
              onClick={() => toogleViewInformations('lessons')}
              icon={<BookOpenIcon className="text-primary w-6 h-6" />}
            />
          </Tooltip>
        </div>
      </BodyCell>
      <BodyCell className="overflow-hidden">
        <AnimatePresence>
          {viewInformations && (
            <motion.div className="w-full" {...editFormAnimation}>
              <TeacherProfileDetails
                teacher={teacher}
                tab={tab}
                onUpdate={onUpdate}
              />
            </motion.div>
          )}
        </AnimatePresence>
      </BodyCell>
    </Row>
  );
}

const TeacherProfileDetails = ({
  teacher,
  tab,
  onUpdate,
}: {
  teacher: User;
  tab?: Tabs;
  onUpdate?: () => void;
}) => {
  const tabs = {
    info: <EditTeacher teacher={teacher} onUpdate={onUpdate} />,
    lessons: <ScheduledLessonsTeacher user={teacher} />,
  };
  return <div className="flex w-full">{tabs[tab || 'info']}</div>;
};

const ChevronUpIconWithAnimation = motion(ChevronUpIcon);
