import React, { useEffect, useState } from 'react';
import { handleIcon } from '../../functions/handleSocialMedia';
import UserProfile from '../../models/UserProfile';
import ConditionalRenderer from '../common/ConditionalRenderer';
import IconButton from '../common/buttons/IconButton';
import ImageInput from '../common/dataInput/ImageInput';
import { validateUpload } from '../../utils/VerifyImageFile';
import { Control, Controller } from 'react-hook-form';

interface BannerProps {
  isLoading: boolean;
  profile?: UserProfile;
  isEdit: boolean;
  control: Control<any>;
  setHasChanges: (value: boolean) => void;
  name: string;
}

export default function ProfileBanner({
  isLoading,
  profile,
  control,
  name,
  isEdit,
  setHasChanges,
  ...props
}: BannerProps) {
  const [banner, setBanner] = useState<string>(profile?.bannerUrl || '');

  useEffect(() => {
    let mounted = true;
    if (mounted) {
      if (!isEdit) {
        setBanner(profile?.bannerUrl || '');
      }
    }
    return () => {
      mounted = false;
    };
  }, [isEdit, profile?.bannerUrl]);

  if (isLoading) {
    return (
      <div className="animate-pulse bg-accent overflow-hidden relative items-center self-stretch grow-0 rounded-xl shadow-default   w-full h-32 lg:h-60"></div>
    );
  }

  if (!isLoading && !profile) {
    <div className="bg-accent overflow-hidden relative items-center self-stretch grow-0 rounded-xl shadow-default   w-full h-32 lg:h-60"></div>;
  }

  if (profile) {
    return (
      <div className="bg-accent overflow-hidden relative items-center self-stretch grow-0 rounded-xl shadow-default w-full h-32 lg:h-60">
        <img
          data-testid="bannerImg"
          className="w-full bg-center bg-cover self-stretch grow rounded-xl shadow-default  "
          src={banner}
          alt=""
        />
        <div className="flex w-auto justify-end absolute right-2 top-2">
          <ConditionalRenderer condition={profile.socialMedia?.length}>
            <div className="flex gap-1.5">
              {profile.socialMedia?.map((link, index) => {
                return (
                  <a
                    data-testid={`myProfileMediaLink${index}`}
                    href={`${link.mediaUrl}`}
                    target="_blank"
                    key={index}
                    rel="noreferrer"
                    className="cursor-pointer"
                  >
                    <IconButton
                      icon={handleIcon(link.mediaName)}
                      className="pointer-events-none w-8 h-8 flex flex-row bg-gradient-to-r from-primary to-secondary shadow-default   text-base-100 justify-center items-center p-1.5 rounded-full hover:opacity-80 transition duration-300 ease-in-out"
                    />
                  </a>
                );
              })}
            </div>
          </ConditionalRenderer>
          <ConditionalRenderer condition={isEdit}>
            <div className="pl-1.5">
              <Controller
                control={control}
                name={name}
                render={({ field: { onChange } }) => (
                  <ImageInput
                    id="uploadBanner"
                    testId="bannerUploader"
                    accept="image/png, image/svg, image/jpg, image/jpeg"
                    type="file"
                    hideRemoveButton
                    color="bg-primary shadow-default   text-base-100"
                    hidePreview
                    hideText
                    image={banner}
                    onChange={({ target }) => {
                      if (target.files) {
                        const value = target.files.item(0);
                        if (value && !validateUpload(value)) {
                          onChange(value);
                          setHasChanges(true);
                          setBanner(URL.createObjectURL(value));
                        }
                      }
                    }}
                  />
                )}
              />
            </div>
          </ConditionalRenderer>
        </div>
      </div>
    );
  }

  return <React.Fragment />;
}
