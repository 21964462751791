import InfinityList, {
  InfinityListProps,
} from '@/components/common/InfinityList';
import Area from '@/models/Area';
import { twMerge } from 'tailwind-merge';
import TopicTreeItemFactory from './items/TopicTreeItemFactory';

export type TopicsTreeListProps = {
  items: Area[];
  isRoot?: boolean;
  isLoading?: boolean;
  fetchNextPage: () => void;
} & Pick<InfinityListProps, 'hasNextPage' | 'isFetchingNextPage'>;

export default function TopicsTreeList({
  items,
  isRoot,
  fetchNextPage,
  isLoading,
  ...infinityListProps
}: TopicsTreeListProps) {
  return (
    <InfinityList
      className={twMerge(
        'max-h-[668px] relative overflow-x-hidden w-full',
        isRoot ? '' : 'ml-5 w-[calc(100%-5)]',
      )}
      hasNextPage={infinityListProps.hasNextPage}
      isFetchingNextPage={infinityListProps.isFetchingNextPage}
      scroll
      onReachEnd={fetchNextPage}
    >
      {items.map(item => (
        <TopicTreeItemFactory key={item.id} area={item} />
      ))}
    </InfinityList>
  );
}
