import { ChangeEventHandler, useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useQueryClient } from '@tanstack/react-query';

import Text from '@/components/common/dataDisplay/Text';
import SelectInput from '@/components/common/dataInput/SelectInput';
import { ContentGivenEnum } from '@/models/ScheduledLessonReport';
import ScheduledLesson from '@/models/ScheduledLesson';
import alert from '@/utils/UseAlert';
import { updateScheduledLesson } from '@/data/services/scheduledLessonsServices';
import { ApiError } from '@/models/Errors';
import useAuth from '@/data/hook/useAuth';
import { isAdmin } from '@/functions/auth';
import TooltipHandler from '@/components/common/TooltipHandler';
import { EventTrack } from '@/models/EventTrack';
import { eventTrack } from '@/functions/eventTrack';
import { scheduledLessonsQueryKeys } from '@/data/services/querykeys';

interface ContentGivenSelectProps {
  scheduledLessonData: ScheduledLesson;
  courseSlug: string;
}

export const ContentGivenSelect = ({
  scheduledLessonData,
  courseSlug,
}: ContentGivenSelectProps) => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'manageScheduledLessonReport',
  });

  const queryClient = useQueryClient();

  const { user } = useAuth();

  const hasPermissionToEdit =
    isAdmin(user?.userType) || scheduledLessonData.teacher === user?.id;

  const contentGiveOptions = Object.values(ContentGivenEnum);

  const [option, setOption] = useState(scheduledLessonData.givenContent);

  const saveContentGivenSelected = useCallback(
    async (scheduledLessonId: number, value: ContentGivenEnum) => {
      try {
        await updateScheduledLesson(scheduledLessonId, {
          givenContent: value,
        });
        queryClient.invalidateQueries(
          scheduledLessonsQueryKeys.get(scheduledLessonId),
        );
        alert.success(t('savedGivenContent'));
      } catch (error: any) {
        const apiError = new ApiError(error);
        alert.error(apiError.getErrorMessage());
      }
    },
    [t, queryClient],
  );

  const onChangeGivenContent: ChangeEventHandler<HTMLSelectElement> = async ({
    target,
  }) => {
    setOption(target.value as ContentGivenEnum);
    await saveContentGivenSelected(
      scheduledLessonData.id,
      target.value as ContentGivenEnum,
    );

    const event: EventTrack = {
      category: courseSlug,
      action: 'Content Given',
      label: target.value,
      value: scheduledLessonData.lesson.order,
    };

    eventTrack(event);
  };

  return (
    <div data-testid="contentGivenSelect">
      <div className="flex flex-col md:flex-row gap-3 w-full items-center">
        <Text
          testId="contentGivenText"
          text={t('contentGiven')}
          format="rubik-500"
          size="text-16"
          className="self-start md:self-center md:w-[60%] xl:w-[30%]"
        />

        <TooltipHandler
          renderTooltip={!hasPermissionToEdit}
          tooltipMessage={t('hasNoPermission')}
          classNameContainer="w-full"
        >
          <SelectInput
            testId="contentGivenSelectInput"
            value={option ?? ''}
            className={{ base: 'text-base-content font-400 w-full' }}
            onChange={onChangeGivenContent}
            disabled={!hasPermissionToEdit}
          >
            <option value="" disabled>
              {t('selectAnOptionPhrase')}
            </option>
            {contentGiveOptions.map(option => {
              return (
                <option key={option} value={option}>
                  {t(`contentGiveOptions.${option}`)}
                </option>
              );
            })}
          </SelectInput>
        </TooltipHandler>
      </div>
      <Text
        text={t('annotationsDesc')}
        className="text-base-content mt-1.5 text-14 "
      />
    </div>
  );
};
