import { useLocation, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Text from './dataDisplay/Text';
import ConditionalRenderer from './ConditionalRenderer';
import ActivitiesView from '../lessons/activities/ActivitiesView';
import ActivityView from '../activities/ActivityView';
import HomeworkView from '../activities/homework/HomeworkView';

export default function ActivityContainer() {
  const { activityId: activityOrder } = useParams();
  const location = useLocation();
  const showDefaultTitle =
    !activityOrder && !location.pathname.includes('homework');
  const { t } = useTranslation('translation', { keyPrefix: 'lesson' });

  return (
    <div
      id="activityContainer"
      className="relative rounded-xl bg-base-100 shadow-default flex flex-col w-full p-9 gap-8"
    >
      <ConditionalRenderer condition={showDefaultTitle}>
        <Text
          text={t('activity.titleHeader')}
          className="text-center text-primary"
          format="poppins-600"
          size="text-28"
        />
      </ConditionalRenderer>

      <RenderActivityContent />
    </div>
  );
}

const RenderActivityContent = () => {
  const { activityId } = useParams();
  const location = useLocation();
  const pathname = location.pathname;

  if (pathname.includes(String(activityId))) {
    return <ActivityView />;
  } else if (pathname.includes('homework')) {
    return <HomeworkView />;
  } else return <ActivitiesView />;
};
