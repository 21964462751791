import notificationSound from '@/assets/audio/mp3/notification-sound.mp3';
import { ComponentProps, forwardRef } from 'react';

type SoundAlertProps = ComponentProps<'audio'>;

export default forwardRef<HTMLAudioElement, SoundAlertProps>(
  function SoundAlert({ src = notificationSound, ...audioProps }, ref) {
    return <audio ref={ref} {...audioProps} src={src} />;
  },
);
