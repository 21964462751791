import { Item, Token } from '@cna-br/astro-code';
import { useTranslation } from 'react-i18next';
import Tag from '../../dataDisplay/Tag';
import Text from '../../dataDisplay/Text';
import { colorByTokenType, tokenTypes } from './ModalGameEditor';
import AstroImage from '../../AstroImage';
import { Tooltip } from '../../dataDisplay/Tooltip';
import { useGameTokens } from './ModalGameEditor.hooks';

type SelectGroundItemProps<T extends Token> = {
  items: Item<T>[];
  type: tokenTypes;
  onSelect: (groundItem: Item<T>) => void;
  className?: string;
};

export default function SelectBlocks<T extends Token>({
  type,
  items,
  className,
  onSelect,
}: SelectGroundItemProps<T>) {
  const { describes, names } = useGameTokens();
  const [tLayers] = useTranslation('translation', {
    keyPrefix: 'game.layers',
  });
  const [tSelectBlocks] = useTranslation('translation', {
    keyPrefix: 'game.selectBlocks',
  });

  return (
    <div className={className}>
      <div className="flex gap-2 justify-center items-center">
        <Text
          text={tSelectBlocks(`${type}.title`)}
          className="text-primary text-center"
          format="poppins-600"
          size="text-14 lg:text-16"
        />
        <Tag
          testId="tag"
          text={tLayers(type)}
          color={colorByTokenType[type].tag}
          className="text-12 lg:text-14 px-1.5 py-0.5 lg:px-2.5 lg:py-1 items-center"
        />
      </div>
      <Text
        text={tSelectBlocks(`${type}.description`)}
        className="text-14 lg:text-16 my-3 text-left"
      />
      <div className="flex gap-3 justify-center">
        {Object.values<Item<T>>(items).map(block => (
          <Tooltip
            key={block.sprite.toString()}
            text={`${names[block.token]}. ${describes[block.token]}`}
            placement="bottom"
            className="w-48"
          >
            <button
              type="button"
              onClick={() => onSelect(block)}
              className="p-2 rounded-md"
            >
              <AstroImage
                src={block.sprite.toString()}
                alt={block.token}
                width={50}
              />
            </button>
          </Tooltip>
        ))}
      </div>
    </div>
  );
}
