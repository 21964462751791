import requester from '@/axios';
import { ImageUploaded, ImageFolderEnum } from '@/models/CkEditor';

export async function imageUpload<T extends Object>({
  file,
  folder,
  extraBody,
}: {
  file: string | Blob;
  folder: ImageFolderEnum;
  extraBody?: T;
}) {
  const formData = new FormData();
  formData.append('image', file);
  formData.append('folder', folder);
  if (extraBody) {
    Object.entries(extraBody).forEach(([key, value]) => {
      if (typeof value !== 'undefined') {
        formData.append(key, value);
      }
    });
  }
  const response = await requester().post<ImageUploaded>(
    '/images-upload/',
    formData,
    {
      headers: {
        'Content-Type': 'multipart/form-data',
        accept: 'application/json',
      },
    },
  );
  return response.data;
}
