import { LayoutGroup } from 'framer-motion';
import StepIndicator from './StepIndicator';
import ConditionalRenderer from '../ConditionalRenderer';

type StepsProps = {
  activeStep: number;
  steps: Step[];
  concludedOnce?: boolean;
  disabled?: boolean;
  hideIndicator?: boolean;
  loading?: boolean;
};

export type StepStatus =
  | 'done'
  | 'todo'
  | 'active'
  | 'skipped'
  | 'error'
  | 'success'
  | 'info'
  | 'loading';

export type Step = {
  id: number;
  status: StepStatus;
  content?: string;
  caption?: string;
  onClick?(index: number): void | Promise<void>;
};

export default function Steps({
  activeStep,
  steps,
  disabled,
  hideIndicator,
  loading,
}: StepsProps) {
  return (
    <div
      data-testid="steps"
      className={`relative scrollbar-thin scrollbar-h-0.5 scrollbar-thumb-primary/40
       scrollbar-track-primary-content scrollbar-thumb-rounded-full scrollbar-track-rounded-full 
       font-rubik font-500 text-18 flex w-full py-2 transition ease-in-out duration-150 ${
         disabled ? 'disabled' : ''
       } overflow-y-hidden`}
    >
      <LayoutGroup>
        {steps.map((step, index, array) => {
          const { className: stepClassName, content } = stepStatus({
            step: { ...step, status: loading ? 'loading' : step.status },
          });

          const onClick = () => step.onClick?.(index);
          const className = `${stepClassName} ${
            step.onClick ? 'cursor-pointer clickable' : 'pointer-events-none'
          } rounded-full w-8 h-8 flex items-center justify-center z-10 
          transform hover:scale-110 ${step.caption ? 'text-12' : 'text-20'} 
          transition ease-in-out duration-150`;

          const pathColorStatus: StepStatus[] = [
            'success',
            'error',
            'skipped',
            'info',
          ];

          const lastIndexDone = steps
            .map((step, index) => ({ index, status: step.status }))
            .reduce(
              (lastIndex, step) =>
                step.status === 'done' ? step.index : lastIndex,
              -1,
            );

          const lineLeftColorCondition =
            index <= activeStep ||
            pathColorStatus.includes(steps[index + 1]?.status) ||
            pathColorStatus.includes(step.status) ||
            lastIndexDone >= index;

          const lineRightColorCondition =
            index < activeStep ||
            pathColorStatus.includes(step.status) ||
            lastIndexDone > index;

          return (
            <div
              key={step.id}
              className="flex flex-col items-center gap-1 min-w-[60px] w-full relative"
            >
              <span
                data-testid={`step${step.id}`}
                onClick={onClick}
                className={className}
                data-content={content}
              >
                <ConditionalRenderer
                  condition={step.status !== 'loading' && !loading}
                >
                  {step.caption ?? content ?? index + 1}
                </ConditionalRenderer>
              </span>
              <div className="absolute grid grid-cols-2 w-full h-[32px] items-center">
                <hr
                  data-testid="left"
                  className={`h-2 ${index > 0 ? '' : 'invisible'} ${
                    lineLeftColorCondition && !loading
                      ? 'bg-accent border-accent'
                      : 'bg-accent-content border-accent-content'
                  } ${loading ? 'animate-pulse' : ''}`}
                />
                <hr
                  data-testid="right"
                  className={`h-2 ${
                    index < array.length - 1 ? '' : 'invisible'
                  } ${
                    lineRightColorCondition && !loading
                      ? 'bg-accent border-accent'
                      : 'bg-accent-content border-accent-content'
                  } ${loading ? 'animate-pulse' : ''}`}
                />
              </div>
              <ConditionalRenderer
                condition={activeStep === index && !hideIndicator}
              >
                <StepIndicator
                  layoutId="stepIndicator"
                  className={loading ? 'invisible' : ''}
                />
              </ConditionalRenderer>
            </div>
          );
        })}
      </LayoutGroup>
    </div>
  );
}

type StepStatusProps = {
  step: Step;
  loadingActivityProgress?: boolean;
};

type StepFormat = {
  content?: string;
  className?: string;
};

function stepStatus({ step }: StepStatusProps): Step & StepFormat {
  const { status } = step;

  const active = 'bg-accent-content text-accent border-2 border-accent';
  const done = 'bg-accent text-accent-content border-2 border-accent';
  const info = 'text-base-100 bg-primary';
  const error = 'text-base-100 bg-error';
  const loading = 'bg-accent-content animate-pulse border-accent-content';
  const neutral = 'bg-accent-content text-accent';
  const success = 'text-base-100 bg-success';

  const stepFormats: {
    [key in StepStatus]: StepFormat;
  } = {
    skipped: {
      className: active,
    },
    success: { className: success, content: '✓' },
    error: { className: error, content: '✕' },
    info: { className: info },
    done: { className: done },
    loading: { className: loading },
    active: { className: active },
    todo: { className: neutral },
  };

  return {
    ...step,
    content: stepFormats[status].content ?? step.content,
    className: stepFormats[status].className,
  };
}
