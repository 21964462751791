import ConditionalRenderer from '@/components/common/ConditionalRenderer';
import SearchAndFilterButtons from '@/components/common/SearchAndFilterButtons';
import { QuickFilterProps as QuickFilterItem } from '@/components/common/filter/QuickFilter';
import QuickFilters from '@/components/common/filter/QuickFilters';
import { LoadingIcon } from '@/components/icons';
import { ICourse } from '@/models/Course';
import Text from '@/components/common/dataDisplay/Text';
import Header from '@/components/common/table/Header';
import { CourseHeader } from '@/utils/HeaderTypes';
import useFilterParams from '@/utils/UseFilterParams';
import { debounce } from 'lodash';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import DataCourseTable from './DataCourseTable';
import EnableEditModal from './EnableEditModal';
import { useQuery } from '@tanstack/react-query';
import { ListCoursePathFilters } from '@/data/services/coursePathServices';
import { coursePathsQueryKeys } from '@/data/services/querykeys';

interface ListCourseProps {
  isLoading: boolean;
  coursesBases: ICourse[];
  updateCourseList(): void;
  titles: CourseHeader;
  isSearch: boolean;
}

export default function ListCourses(props: ListCourseProps) {
  const { t } = useTranslation('translation', {
    keyPrefix: 'manageCourse.dataCourseTable',
  });

  const { isSearch, titles, updateCourseList, coursesBases, isLoading } = props;

  const [betaModal, setBetaModal] = useState<boolean>(false);
  const [selectedCourse, setSelectedCourse] = useState<ICourse>();

  const { filterParams, setFilterParams, deleteFilterParams } =
    useFilterParams<ListCoursePathFilters>({});

  const hasParams = [...filterParams.keys()].length !== 0;

  const search = filterParams.get('search') ?? '';

  const hasSearch = !!search || isSearch;

  const { data: coursePath } = useQuery({
    ...coursePathsQueryKeys.get(selectedCourse?.coursePathSlug ?? ''),
    enabled: !!selectedCourse?.coursePathSlug,
  });

  const requestSearch = (value: string) => {
    if (!value) {
      deleteFilterParams('search');
    } else {
      setFilterParams({ search: value });
    }
    setFilterParams({ pageNumber: 1 });
  };

  function clearSearch() {
    deleteFilterParams('search');
    setFilterParams({ pageNumber: 1 });
  }

  function clearFilters() {
    deleteFilterParams(['search', 'courseType']);
    setFilterParams({ pageNumber: 1 });
  }

  const quickFilters: QuickFilterItem[] = [
    {
      type: 'courseType',
      filters: filterParams.getAll('courseType'),
    },
  ];

  return (
    <React.Fragment>
      <div className="flex gap-4 justify-between items-center">
        <SearchAndFilterButtons
          onChange={debounce(e => requestSearch(e.target.value), 500)}
          isSearch={hasSearch}
          clearFiltersButton={clearFilters}
          clearSearch={clearSearch}
        />
        <QuickFilters quickFilters={quickFilters} />
      </div>
      <div>
        <table className="flex flex-col table-fixed gap-y-2.5">
          <Header headers={Object.values(titles)} />
          <ConditionalRenderer condition={!isLoading}>
            <DataCourseTable
              setSelectedCourse={setSelectedCourse}
              coursesBases={coursesBases}
              titles={titles}
              updateCourseList={updateCourseList}
              setBetaModal={setBetaModal}
            />
          </ConditionalRenderer>
        </table>

        <ConditionalRenderer condition={!coursesBases.length && !isLoading}>
          <Text
            text={hasParams ? t('searchNotFound') : t('noCourses')}
            className="flex justify-center mt-5"
          />
        </ConditionalRenderer>
        <ConditionalRenderer condition={isLoading}>
          <div className="flex w-full h-[518px] justify-center items-center p-3.5">
            <LoadingIcon className="text-primary/40 w-10 h-10" />
          </div>
        </ConditionalRenderer>
      </div>
      <ConditionalRenderer condition={coursePath}>
        {coursePath && (
          <EnableEditModal
            course={coursePath}
            onClose={() => setBetaModal(false)}
            visible={betaModal}
          />
        )}
      </ConditionalRenderer>
    </React.Fragment>
  );
}
