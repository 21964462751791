import Card from '../../common/Card';
import { Link } from 'react-router-dom';
import Text from '../../common/dataDisplay/Text';

interface MenuItemProps {
  icon?: JSX.Element;
  pathname: string;
  text: string;
  testId?: string;
  onClick?: () => void;
  search?: string;
}

export default function MenuItem(props: MenuItemProps) {
  const { icon, pathname, search, text, testId, onClick } = props;

  return (
    <Link
      data-testid={testId}
      to={{ pathname, search }}
      className="m-0 p-0 flex w-full justify-start items-center"
      onClick={onClick}
    >
      <Card className="text-base-content hover:text-primary transition duration-200 ease-in-out">
        <div className="flex md:flex-col md:justify-center items-center">
          <svg className="justify-center items-center md:flex w-14 h-14">
            {icon}
          </svg>
          <span className="justify-center items-center pl-10 md:pl-0">
            <Text
              format="rubik-500"
              className="text-18 break-words"
              text={text}
            />
          </span>
        </div>
      </Card>
    </Link>
  );
}
