import ConditionalRenderer from '@/components/common/ConditionalRenderer';
import Tag from '@/components/common/dataDisplay/Tag';
import { VersioningStatusEnum } from '@/enums/VersioningStatus';
import { ScheduledLessonTypeEnum } from '@/models/ScheduledLesson';
import { useTranslation } from 'react-i18next';

type LessonBarStatusProps = {
  status: VersioningStatusEnum;
  type?: ScheduledLessonTypeEnum;
  isActive?: boolean;
};

export default function LessonBarStatus({
  status,
  type,
  isActive,
}: LessonBarStatusProps) {
  const isPublished = status === VersioningStatusEnum.PUBLISHED;

  const notPublishedInBank =
    type !== ScheduledLessonTypeEnum.NORMAL && !isActive;

  const { t } = useTranslation('translation', {
    keyPrefix: 'common',
  });

  return (
    <ConditionalRenderer condition={!isPublished && notPublishedInBank}>
      <Tag
        testId="lessonStatus"
        color="warning"
        className="rounded-full px-3 py-1 font-500 hidden xs:flex"
        text={t('lessonUnpublished')}
      />
    </ConditionalRenderer>
  );
}
