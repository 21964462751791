import { useTranslation } from 'react-i18next';
import Text from '../common/dataDisplay/Text';
import { ProgressSpace } from '../common/ProgressIcons';
import { CourseTypeEnum } from '@/models/Course';
import LessonProgressBar from '../lessons/LessonBar/LessonProgressBar';

interface ProgressBarCourseProps {
  className?: string;
  courseName?: string;
  courseType?: CourseTypeEnum;
  progress?: number;
  hideProgress?: boolean;
  isStudent?: boolean;
}

export default function ProgressBarCourse({
  courseName,
  courseType,
  progress,
  className,
  hideProgress,
  isStudent,
}: ProgressBarCourseProps) {
  const { t } = useTranslation('translation', { keyPrefix: 'coursesView' });

  return (
    <div
      className={`flex flex-col ${
        isStudent
          ? 'bg-accent-content text-accent'
          : 'bg-secondary-content text-secondary'
      } rounded-lg items-stretch shadow-default shadow-secondary-content ${
        className || ''
      }`}
    >
      <div className="flex items-center px-5 py-1.5 gap-7">
        <ProgressSpace
          progress={Math.round((progress || 0) * 100)}
          type={courseType}
        />
        <div className="flex justify-between items-center w-full">
          <Text
            text={courseName || courseType?.replace('_', '+')}
            format="rubik-500"
            size="text-18"
            className="uppercase"
          />

          <span className={`hidden ${hideProgress ? '' : 'md:block'}`}>
            <Text
              text={
                t('progress') + ': ' + Math.round((progress || 0) * 100) + '%'
              }
              format="rubik-500"
              size="text-18"
              className="uppercase"
            />
          </span>
        </div>
      </div>
      <LessonProgressBar
        progress={progress}
        hideProgress={!isStudent}
        color="accent"
      />
    </div>
  );
}
