import React, { useState } from 'react';
import { BackpackIcon, Coin, Gem } from '../icons';
import {
  AvatarItemsEnum,
  BaseItem,
  Avatar,
  ShopItem,
  Inventory,
} from '@/models/Avatar';
import {
  changeToAvatarModel,
  getBaseItemCategory,
} from '@/utils/userAvatarUtils';
import Tag from '../common/dataDisplay/Tag';
import useStudentContext from '@/data/hook/student';
import { Tooltip } from '../common/dataDisplay/Tooltip';
import ConditionalRenderer from '../common/ConditionalRenderer';
import PurchaseModal from './PurchaseModal';
import ItemDisplay from './ItemDisplay';
import { ShoppingBagIcon } from '@heroicons/react/outline';
import { useQueryClient } from '@tanstack/react-query';
import { updateUserAvatar } from '@/data/services/avatarServices';
import alert from '@/utils/UseAlert';
import { useTranslation } from 'react-i18next';
import ConditionalWrapper from '../common/ConditionalWrapper';
import { avatarsQueryKeys } from '@/data/services/querykeys';

export default function ItemShop({
  item,
  onChange,
  onChangeInventory,
  subcategory,
  shopAvatar,
  inventory,
}: {
  item: ShopItem;
  inventory?: Inventory;
  subcategory: AvatarItemsEnum;
  shopAvatar: Avatar;
  onChange: (newValue: BaseItem, color: string) => void;
  onChangeInventory: (newValue: BaseItem, color: string) => void;
}) {
  const { t } = useTranslation('translation', {
    keyPrefix: 'inventory',
  });
  const color = item.item.baseColor;
  const queryClient = useQueryClient();
  const [isVisible, setIsVisible] = useState(false);
  const { rewardsData } = useStudentContext();

  const isInventoryItem = () => {
    const invCategory = getBaseItemCategory(subcategory);
    if (inventory) {
      return !!inventory[invCategory].find(inv => inv.id === item.item.id);
    } else return false;
  };
  const isItemInventory = isInventoryItem();

  const handleEquipItem = async (shouldEquip: boolean, color?: string) => {
    const itemColor = color || item.item.baseColor;
    if (shouldEquip) {
      try {
        await updateUserAvatar(
          shopAvatar.user,
          changeToAvatarModel(subcategory, shopAvatar, item.item.id, itemColor),
        );
        onChange(item.item, itemColor);
        onChangeInventory(item.item, itemColor);
        queryClient.invalidateQueries(
          avatarsQueryKeys.get(shopAvatar.user ?? 0).queryKey,
        );
      } catch (error) {
        alert.error('tequipError');
      }
    }
  };

  const canPurchase = () => {
    if (
      rewardsData &&
      item.coinsPrice &&
      rewardsData.coins >= item.coinsPrice
    ) {
      return true;
    } else if (
      rewardsData &&
      item.gemsPrice &&
      rewardsData.gems >= item.gemsPrice
    ) {
      return true;
    } else return false;
  };

  const userCanPurchase = canPurchase();

  return (
    <div className="w-auto h-fit text-14 relative">
      <ConditionalRenderer condition={isVisible}>
        <PurchaseModal
          category={subcategory}
          onEquip={handleEquipItem}
          visible={isVisible}
          item={item}
          onClose={() => {
            setIsVisible(false);
          }}
        />
      </ConditionalRenderer>
      <div className={isItemInventory ? 'pointer-events-none' : ''}>
        <ItemDisplay
          color={color}
          onChange={onChange}
          isSameItem={false}
          type={'shop'}
          item={item.item}
          subcategory={subcategory}
        />
      </div>
      <div className="absolute -top-0.5 -left-1.5 z-50 w-[110%] text-12 flex justify-between">
        <ConditionalRenderer condition={!!item.coinsPrice && !isItemInventory}>
          <Tag
            color="custom"
            icon={<Coin className="w-3 h-3" />}
            text={`${item.coinsPrice}`}
            className="flex px-1.5 py-0.5 rounded-full text-base-100 font-500 flex-row-reverse gap-0.5 hover:ring transition ease-in-out duration-300 bg-yellow-400 ring-yellow-100"
          />
        </ConditionalRenderer>
        <ConditionalRenderer condition={!!item.gemsPrice && !isItemInventory}>
          <Tag
            color="custom"
            icon={<Gem className="w-3 h-3" />}
            text={`${item.gemsPrice}`}
            className="flex px-1.5 py-0.5 rounded-full text-base-100 font-500 flex-row-reverse gap-0.5 hover:ring transition ease-in-out duration-300 bg-success ring-success-content"
          />
        </ConditionalRenderer>
      </div>
      {isItemInventory ? (
        <BackpackIcon className="text-accent absolute top-2 left-2 z-40 h-4 w-4" />
      ) : (
        <div
          onClick={userCanPurchase ? () => setIsVisible(true) : () => {}}
          data-testid={`purchaseButton${item.item.name}`}
          className={`${
            userCanPurchase ? 'cursor-pointer bg-warning' : 'bg-warning-content'
          }  text-base-100 w-6 h-6 p-1 flex items-center justify-center rounded-full bottom-1 right-1 absolute z-50`}
        >
          <ConditionalWrapper
            condition={!userCanPurchase}
            wrapper={
              <Tooltip className="w-32 z-50" text={t('notEnoughCash')} />
            }
          >
            <ShoppingBagIcon className="h-4 w-4" />
          </ConditionalWrapper>
        </div>
      )}
    </div>
  );
}
