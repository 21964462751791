import { ReactNode, Fragment } from 'react';
import useAuth from '@/data/hook/useAuth';
import { UserTypeEnum } from '@/models/User';

type ComponentGuardProps = {
  children?: ReactNode;
  roles: UserTypeEnum[];
};

function ComponentGuard({ children, roles }: ComponentGuardProps) {
  const { user } = useAuth();
  if (!user?.userType) return <Fragment />;

  if (roles.length === 0) return <Fragment>{children}</Fragment>;

  if (!roles.includes(user?.userType)) return <Fragment />;

  return <Fragment>{children}</Fragment>;
}

export default ComponentGuard;
