import { AnimatePresence, motion } from 'framer-motion';
import { useTabsPanelContext } from './Tabs';
import TabsTriggerListContainer from './TabsTriggerListContainer';

const TabsList = ({ children }: { children: React.ReactNode }) => {
  const { orientation } = useTabsPanelContext();
  return (
    <TabsTriggerListContainer>
      <AnimatePresence>
        <motion.nav
          className={`relative flex w-full ${
            orientation === 'vertical'
              ? 'flex-col'
              : 'flex-row overflow-x-auto overflow-y-hidden scroll-tabpanel'
          }`}
        >
          {children}
        </motion.nav>
      </AnimatePresence>
    </TabsTriggerListContainer>
  );
};

export default TabsList;
