import {
  AssignmentType,
  HomeworkActivityProgress,
  HomeworkActivityProgressStatus,
} from '../models/HomeworkActivity';

type RevisionStatus = 'revised' | 'notDone' | 'pending';

export const handleTeacherHomeworkRevision = (
  status: HomeworkActivityProgressStatus | null,
  type?: AssignmentType,
): RevisionStatus => {
  if (status === HomeworkActivityProgressStatus.GRADED) return 'revised';

  if (
    status === HomeworkActivityProgressStatus.SENT ||
    type === AssignmentType.NOTHING
  )
    return 'pending';

  return 'notDone';
};

export const handleHomeworkNotDone = (
  homeworkProgress: HomeworkActivityProgress,
): boolean => {
  const hasSubmittion =
    homeworkProgress.homework.assignmentType !== AssignmentType.NOTHING;
  const grade = homeworkProgress.activityProgress.grade;
  if (grade === 0) {
    return !homeworkProgress.activityProgress.hasDone;
  } else if (hasSubmittion) {
    return grade === null && !homeworkProgress.answer;
  }
  return false;
};
