import { useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import BodyText from '@/components/common/BodyText';
import HeadTitle from '@/components/common/HeadTitle';
import RoundedButton from '@/components/common/buttons/RoundedButton';
import Text from '@/components/common/dataDisplay/Text';
import PasswordInput from '@/components/common/dataInput/PasswordInput';
import TextInput from '@/components/common/dataInput/TextInput';
import useAuth from '@/data/hook/useAuth';
import { getErrorMessage } from '@/utils/getErrorMessage';
import { useMutation } from '@tanstack/react-query';
import { auth } from '@/data/services/authService';

interface LoginProps {
  id?: string;
  mode: 'login' | 'reset';
  onChangeMode(mode: 'login' | 'reset'): void;
}

interface Inputs {
  username: string;
  password: string;
}

export default function Login(props: LoginProps) {
  const { handleAccessConfig } = useAuth();
  const { register, handleSubmit } = useForm<Inputs>();
  const { id } = props;
  const [remember, setRemember] = useState(false);
  const [loginError, setLoginError] = useState('');

  function showLoginError(msg: string, seconds = 5): void {
    setLoginError(msg);
    setTimeout(() => setLoginError(''), seconds * 1000);
  }

  const { mutate: login, isLoading: loadingToken } = useMutation(auth, {
    async onSuccess(tokenResponse, { remember }) {
      handleAccessConfig?.({ tokenResponse, remember });
    },
    onError(error: any) {
      showLoginError(getErrorMessage(error));
    },
  });

  const onSubmit: SubmitHandler<Inputs> = async ({ username, password }) => {
    return login?.({
      username,
      password,
      remember,
    });
  };

  const { t } = useTranslation('translation', {
    keyPrefix: 'login',
  });

  return (
    <div id={id} className="flex flex-col">
      <HeadTitle routeInfo="Login" />
      <Text
        format="poppins-600"
        text={t('motivationalMessage')}
        className="pb-1 text-center text-primary text-28"
      />

      {loginError ? (
        <BodyText
          id="errorText"
          text={`${loginError}`}
          className="flex self-center mb-10 text-error bg-error-content text-center py-1 rounded-md px-3 max-w-48"
        />
      ) : (
        <BodyText
          text={t('loginAccount')}
          className="text-base-content text-center pb-12"
        />
      )}

      <form onSubmit={handleSubmit(onSubmit)}>
        <TextInput
          testId="usernameInput"
          register={register('username')}
          id="username"
          label={t('login')}
          type="text"
          typeInput="login"
          required
          placeholder={t('loginPlaceholder')}
          className={{ base: 'mx-auto mb-6' }}
          fontSize="text-14"
          fontWeight="font-400"
        />

        <PasswordInput
          testId="passwordInput"
          fontSize="text-14"
          register={register('password')}
          id="password"
          label={t('password')}
          typeInput="login"
          required
          placeholder={t('passwordPlaceholder')}
          className={{ base: 'mx-auto mb-3' }}
        />

        <label className="flex gap-2.5 justify-start label cursor-pointer mx-auto mb-6">
          <input
            onChange={e => setRemember(e.target.checked)}
            type="checkbox"
            className="checkbox-xs checkbox checkbox-primary border rounded-sm"
          />
          <span className="shrink-0 text-left text-14 font-400 text-base-content">
            {t('keepConnected')}
          </span>
        </label>

        <span className="flex justify-center">
          <RoundedButton
            type="submit"
            size="text-16"
            id="submitButton"
            text={t('signIn')}
            disabled={loadingToken}
            className="w-[80%] sm:w-[50%] min-w-fit"
            testId="submitButton"
          />
        </span>
      </form>

      <Text
        id="forgotLink"
        size="text-14 md:text-16"
        format="rubik-500"
        text={t('forgotPassword')}
        onClick={() => props.onChangeMode('reset')}
        className="cursor-pointer w-full text-center mt-8 text-primary hover:text-secondary duration-100 ease-in-out transition-all underline"
      />
    </div>
  );
}
