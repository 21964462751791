import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ChevronDownIcon } from '@heroicons/react/outline';

import OutlineButton from '@/components/common/buttons/OutlineButton';
import { ScheduledLessonFilters } from '@/data/services/scheduledLessonsServices';
import {
  ClassScheduleFiltersProps,
  ClassScheduleEventFilter,
} from '@/models/ClassScheduleFilters';
import { OptionItem } from '@/models/OptionItem';
import SortToggle from '@/components/common/dataInput/SortToggle';
import { LessonTimeframeEnum } from '@/models/ScheduledLesson';

type TodayLessonsHeaderProps = Omit<
  ClassScheduleFiltersProps<ScheduledLessonFilters>,
  'type' | 'events'
> & {
  updateFilter: (filter: ScheduledLessonFilters) => void;
  clearFilter: () => void;
};

export default function TodayLessonsHeader({
  filters,
  updateFilter,
  updateFilters,
  clearFilter,
}: TodayLessonsHeaderProps) {
  const { t } = useTranslation('translation', {
    keyPrefix: 'timeframeFilter',
  });

  const { t: tCommon } = useTranslation('translation', {
    keyPrefix: 'common',
  });

  const onClick = (event: ClassScheduleEventFilter) =>
    updateFilters({
      type: 'lessonTimeframe',
      event,
    });

  const options: {
    [key in LessonTimeframeEnum]: OptionItem;
  } = {
    ALL: {
      id: LessonTimeframeEnum.ALL,
      text: t(LessonTimeframeEnum.ALL),
      active:
        filters?.lessonTimeframe?.length === 0 || !filters?.lessonTimeframe,
      onClick: clearFilter,
    },
    ONGOING: {
      id: LessonTimeframeEnum.ONGOING,
      text: t(LessonTimeframeEnum.ONGOING),
      active:
        !!filters?.lessonTimeframe &&
        filters.lessonTimeframe.includes(LessonTimeframeEnum.ONGOING),
    },
    PAST: {
      id: LessonTimeframeEnum.PAST,
      text: t(LessonTimeframeEnum.PAST),
      active:
        !!filters?.lessonTimeframe &&
        filters?.lessonTimeframe?.includes(LessonTimeframeEnum.PAST),
    },
    UPCOMING: {
      id: LessonTimeframeEnum.UPCOMING,
      text: t(LessonTimeframeEnum.UPCOMING),
      active:
        !!filters?.lessonTimeframe &&
        filters?.lessonTimeframe?.includes(LessonTimeframeEnum.UPCOMING),
    },
  };

  const [descending, setDescending] = useState(
    filters?.ordering === '-datetime',
  );

  const chevronIcon = (
    <span className="w-3.5">
      <ChevronDownIcon
        className={`w-3.5 transition-all duration-150 ease-in-out transform ${
          !descending ? 'rotate-180' : ''
        }`}
      />
    </span>
  );

  return (
    <nav className="flex justify-between flex-col sm:flex-row gap-2 pr-2.5">
      <ul className="flex gap-1.5 flex-wrap">
        {Object.values(options).map(option => {
          const onClickButton = option.onClick
            ? () => option.onClick?.()
            : () => onClick({ checked: !!!option.active, value: option.id });

          return (
            <li key={option.id}>
              <OutlineButton
                aria-pressed={option.active}
                testId={option.id}
                text={option.text}
                className="lowercase text-[0.75rem] sm:text-[0.875rem] px-1.5 py-0.5"
                onClick={onClickButton}
                active={option.active}
              />
            </li>
          );
        })}
      </ul>
      <SortToggle
        id="todayLessonsSortToogle"
        text={{
          ascending: tCommon('increasingTime'),
          descending: tCommon('descendingTime'),
        }}
        className={{ div: 'text-base-content lowercase' }}
        icon={chevronIcon}
        hideLabel
        isDescending={descending}
        onToggleSort={checked => {
          setDescending(checked);
          updateFilter({ ordering: checked ? '-datetime' : 'datetime' });
        }}
      />
    </nav>
  );
}
