type MessageLinkOptions = {
  text?: string;
};

export function createWhatsappLink(
  recipient: string,
  options: MessageLinkOptions = {},
) {
  const queryString = new URLSearchParams(options);

  const recipientEncoded = encodeURI(recipient);

  return `https://wa.me/${recipientEncoded}/${
    queryString.toString().length > 0 ? `?${queryString}` : ''
  }`;
}
