import { CodeIcon } from '@heroicons/react/outline';
import React, { useState } from 'react';
import Text from '../common/dataDisplay/Text';

const options = {
  kids: {
    info: {
      video: '',
      age: '07 a 09 anos',
      weekly: '2h',
      duration: '72 aulas | 1 ano',
      img: '',
    },
    lightBg: 'bg-warning-content',
    darkBg: 'bg-warning',
    mdLightBg: 'md:bg-warning-content',
    mdDarkBg: 'md:bg-warning',
    textColor: 'text-warning',
    modules: [
      {
        title: 'Módulo 1',
        detail: 'Lógica de programação e criação de jogos em blocos',
        gameImg: '/images/course-default-image.png',
        game: 'Dance Revolution',
        gameInfo: 'Um jogo de dança com a temática musical',
        items: [
          { icon: <CodeIcon />, text: 'Loops' },
          { icon: <CodeIcon />, text: 'Operadores' },
          { icon: <CodeIcon />, text: 'Variáveis' },
          { icon: <CodeIcon />, text: 'Eventos' },
        ],
      },
      {
        title: 'Módulo 2',
        detail: 'Criação de jogos 2D, 3D e aplicativos mobile',
        gameImg: '',
        game: 'Space Obby Adventure',
        gameInfo: 'Jogo de geração infinite runner, estilo Flappy Bird',
        items: [
          { icon: <CodeIcon />, text: 'Linguagem LUA' },
          { icon: <CodeIcon />, text: 'Jogo próprio no Roblox' },
          { icon: <CodeIcon />, text: 'App text-to-speech' },
          { icon: <CodeIcon />, text: 'App para acelerômetro' },
        ],
      },
      {
        title: 'Módulo 3',
        detail: 'Desenvolvimento de jogos com pixel art',
        gameImg: '',
        game: 'RPG Game',
        gameInfo: 'Jogo estilo top down, como Stardew Valley',
        items: [
          { icon: <CodeIcon />, text: 'Condicionalidade por contato' },
          { icon: <CodeIcon />, text: 'Estados de animação' },
          { icon: <CodeIcon />, text: 'Machine learning' },
        ],
      },
      {
        title: 'Módulo 4',
        detail: 'Battle Royale com Roblox e projetos de robótica',
        gameImg: '',
        game: 'Battle Royale',
        gameInfo: 'Crie um jogo no estilo do Fortnite',
        items: [
          { icon: <CodeIcon />, text: 'Linguagem LUA (avançado)' },
          { icon: <CodeIcon />, text: 'Eventos servidor-cliente' },
          { icon: <CodeIcon />, text: 'Arduino' },
          { icon: <CodeIcon />, text: 'Modelagem 3D' },
        ],
      },
    ],
  },
  teens: {
    info: {
      video: '',
      age: '07 a 09 anos',
      weekly: '2h',
      duration: '72 aulas | 1 ano',
      img: '',
    },
    lightBg: 'bg-primary-content',
    darkBg: 'bg-primary',
    textColor: 'text-primary',
    mdLightBg: 'md:bg-primary-content',
    mdDarkBg: 'md:bg-primary',
    modules: [
      {
        title: 'Teen 1',
        detail: 'Lógica de programação e criação de jogos em blocos',
        gameImg: '',
        game: 'Dance Revolution',
        gameInfo: 'Um jogo de dança com a temática musical',
        items: [
          { icon: <CodeIcon />, text: 'Loops' },
          { icon: <CodeIcon />, text: 'Operadores' },
          { icon: <CodeIcon />, text: 'Variáveis' },
          { icon: <CodeIcon />, text: 'Eventos' },
        ],
      },
      {
        title: 'Módulo 2',
        detail: 'Criação de jogos 2D, 3D e aplicativos mobile',
        gameImg: '',
        game: 'Space Obby Adventure',
        gameInfo: 'Jogo de geração infinite runner, estilo Flappy Bird',
        items: [
          { icon: <CodeIcon />, text: 'Linguagem LUA' },
          { icon: <CodeIcon />, text: 'Jogo próprio no Roblox' },
          { icon: <CodeIcon />, text: 'App text-to-speech' },
          { icon: <CodeIcon />, text: 'App para acelerômetro' },
        ],
      },
      {
        title: 'Módulo 3',
        detail: 'Desenvolvimento de jogos com pixel art',
        gameImg: '',
        game: 'RPG Game',
        gameInfo: 'Jogo estilo top down, como Stardew Valley',
        items: [
          { icon: <CodeIcon />, text: 'Condicionalidade por contato' },
          { icon: <CodeIcon />, text: 'Estados de animação' },
          { icon: <CodeIcon />, text: 'Machine learning' },
        ],
      },
      {
        title: 'Módulo 4',
        detail: 'Battle Royale com Roblox e projetos de robótica',
        gameImg: '',
        game: 'Battle Royale',
        gameInfo: 'Crie um jogo no estilo do Fortnite',
        items: [
          { icon: <CodeIcon />, text: 'Linguagem LUA (avançado)' },
          { icon: <CodeIcon />, text: 'Eventos servidor-cliente' },
          { icon: <CodeIcon />, text: 'Arduino' },
          { icon: <CodeIcon />, text: 'Modelagem 3D' },
        ],
      },
    ],
  },
  young: {
    info: {
      video: '',
      age: '07 a 09 anos',
      weekly: '2h',
      duration: '72 aulas | 1 ano',
      img: '',
    },
    lightBg: 'bg-secondary-content',
    textColor: 'text-secondary-content',
    darkBg: 'bg-secondary-content',
    mdLightBg: 'md:bg-secondary-content',
    mdDarkBg: 'md:bg-secondary-content',
    modules: [
      {
        title: 'Young 1',
        detail: 'Lógica de programação e criação de jogos em blocos',
        gameImg: '',
        game: 'Dance Revolution',
        gameInfo: 'Um jogo de dança com a temática musical',
        items: [
          { icon: <CodeIcon />, text: 'Loops' },
          { icon: <CodeIcon />, text: 'Operadores' },
          { icon: <CodeIcon />, text: 'Variáveis' },
          { icon: <CodeIcon />, text: 'Eventos' },
        ],
      },
      {
        title: 'Módulo 2',
        detail: 'Criação de jogos 2D, 3D e aplicativos mobile',
        gameImg: '',
        game: 'Space Obby Adventure',
        gameInfo: 'Jogo de geração infinite runner, estilo Flappy Bird',
        items: [
          { icon: <CodeIcon />, text: 'Linguagem LUA' },
          { icon: <CodeIcon />, text: 'Jogo próprio no Roblox' },
          { icon: <CodeIcon />, text: 'App text-to-speech' },
          { icon: <CodeIcon />, text: 'App para acelerômetro' },
        ],
      },
      {
        title: 'Módulo 3',
        detail: 'Desenvolvimento de jogos com pixel art',
        gameImg: '',
        game: 'RPG Game',
        gameInfo: 'Jogo estilo top down, como Stardew Valley',
        items: [
          { icon: <CodeIcon />, text: 'Condicionalidade por contato' },
          { icon: <CodeIcon />, text: 'Estados de animação' },
          { icon: <CodeIcon />, text: 'Machine learning' },
        ],
      },
    ],
  },
};

export default function CourseTrack() {
  const [tab, setTab] = useState<keyof typeof options>('kids');
  const activeKlass = `tab-active px-8 text-base-100 rounded-full ${options[tab].darkBg}`;

  return (
    <div className="flex flex-col gap-1.5 sm:gap-3 w-fit">
      <div
        className={`tabs flex-col xs:flex-row justify-center w-auto xs:justify-between h-fit tabs-boxed ${options[tab].lightBg} ${options[tab].textColor} rounded-full uppercase font-800 italic`}
      >
        <div
          onClick={() => setTab('kids')}
          className={`tab self-center h-fit px-6 text-16 sm:text-20 md:text-28 transition-all ${
            tab === 'kids' ? activeKlass : options[tab].textColor
          }`}
        >
          Ctrl+Kids
        </div>
        <div
          onClick={() => setTab('teens')}
          className={`tab self-center h-fit text-16 sm:text-20 md:text-28 transition-all px-6 ${
            tab === 'teens' ? activeKlass : options[tab].textColor
          }`}
        >
          Ctrl+Teens
        </div>
        <div
          onClick={() => setTab('young')}
          className={`tab self-center h-fit text-16 sm:text-20 md:text-28 px-6 transition-all ${
            tab === 'young' ? activeKlass : options[tab].textColor
          }`}
        >
          Ctrl+Young
        </div>
      </div>
      <div
        className={`flex flex-col sm:grid sm:grid-cols-2 md:flex md:flex-row gap-3 sm:gap-6 ${options[tab].mdLightBg} courseTrack w-full md:w-fit p-2 sm:p-4 rounded-xl`}
      >
        {options[tab].modules.map(option => {
          return (
            <article
              className={`flex md:h-[22.625rem] rounded-xl ${options[tab].lightBg} px-4 py-5 md:p-0 md:bg-none flex-col-reverse sm:flex-col md:even:mt-10 even:shrink-0 even:grow-0 gap-4 md:gap-10 relative items-center w-full md:w-48`}
            >
              <div className="flex sm:flex-col gap-2 items-center text-center text-14">
                <img
                  src={option.gameImg}
                  alt={option.game}
                  className="aspect-[1.5] h-10 w-auto"
                />
                <div className="flex flex-col">
                  <Text format="rubik-500" text={option.game} />
                  <Text text={option.gameInfo} />
                </div>
              </div>
              <figure className="hidden md:inline-block w-5 h-5 rounded-full z-20 border-4 border-primary/40 bg-primary shrink-0"></figure>
              <div className="flex w-full md:w-fit shrink-0 sm:flex-col gap-2.5 md:gap-3 items-center text-center">
                <div className="flex w-full md:w-fit flex-col gap-2">
                  <Text
                    format="rubik-500"
                    className={`bg-none ${options[tab].mdDarkBg} ${options[tab].textColor} md:text-base-100 md:rounded-full md:px-6 md:py-2`}
                    text={option.title}
                  />
                  <Text
                    format="rubik-500"
                    size="text-14"
                    text={option.detail}
                  />
                </div>
                <div className="flex w-full sm:w-fit flex-col gap-1.5 md:gap-2">
                  {option.items.map(item => {
                    return (
                      <div className="flex gap-4 text-left text-14">
                        <div className="w-3 h-3 shrink-0 grow-0">
                          {item.icon}
                        </div>
                        <Text text={item.text} />
                      </div>
                    );
                  })}
                </div>
              </div>
            </article>
          );
        })}
      </div>
    </div>
  );
}
