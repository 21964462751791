import { CheckIcon, XIcon } from '@heroicons/react/outline';
import React, { ChangeEventHandler, useEffect, useMemo, useState } from 'react';
import { LoadingIcon } from '../../icons';
import { getUserByUsername } from '../../../data/services/userServices';
import Input from './TextInput';
import { debounce } from 'lodash';
import { useTranslation } from 'react-i18next';
import User from '../../../models/User';
import { Control, Controller, useController } from 'react-hook-form';
import { BaseInputProps } from './BaseInput';

type UsernameInputStatus = 'loading' | 'none' | 'invalid' | 'valid';

interface UsernameInputProps extends BaseInputProps {
  user?: User;
  required?: boolean;
  onChange?(): void;
  control: Control<any>;
  name?: string;
}

export default function UsernameInput({
  user,
  disabled,
  required,
  onChange: sideEffect,
  testId = 'username',
  className,
  labelPosition = 'top',
  errorLabelPosition = 'top',
  control,
  name = 'username',
}: UsernameInputProps) {
  const { t } = useTranslation('translation');
  const {
    fieldState: { error },
  } = useController({ control, name });
  const [validationStatus, setValidationStatus] =
    useState<UsernameInputStatus>('none');

  const hasError = !!error?.message;

  const debouncedHandlerChange = useMemo(() => {
    const userNameValidation = /^[a-zA-Z][a-zA-Z0-9._-]*$/;

    const setErrorMessage = (message: string) => {
      control.setError(name, {
        message,
      });
    };
    const existsUsername = async (username: string) => {
      setValidationStatus('loading');
      const userByUsername = await getUserByUsername(username);
      return !!userByUsername && userByUsername?.id !== user?.id;
    };
    const isUsernameValid = async (username: string) => {
      setValidationStatus('none');
      if (!username) {
        setErrorMessage(t('formValidation.user.username.required'));
        return;
      }

      if (!/^[A-Za-z]$/.test(username[0])) {
        setErrorMessage(t('formValidation.user.username.startsLetters'));
        return;
      }

      if (username.length < 5) {
        setErrorMessage(t('formValidation.user.username.minLength'));
        return;
      }

      if (!userNameValidation.test(username)) {
        setErrorMessage(t('formValidation.user.username.pattern'));
        return;
      }

      setValidationStatus('loading');
      if (await existsUsername(username)) {
        setValidationStatus('invalid');
        setErrorMessage(t('formValidation.user.username.usernameExists'));
        return;
      }
      control.setError(name, {
        message: undefined,
      });
      setValidationStatus('valid');
    };

    const handleChange: ChangeEventHandler<HTMLInputElement> = async e => {
      const value = e.target.value;
      sideEffect?.();
      await isUsernameValid(value);
    };
    return debounce(handleChange, 500);
  }, [sideEffect, t, control, name, user?.id]);

  useEffect(() => {
    let mounted = true;
    if (mounted && hasError) {
      setValidationStatus('invalid');
    }
  }, [hasError]);

  useEffect(() => {
    let mounted = true;
    if (mounted) {
      setValidationStatus('none');
    }
  }, [disabled]);

  return (
    <Controller
      control={control}
      name={name}
      rules={{
        required: required && t('formValidation.user.username.required'),
      }}
      render={({ field: { onChange, value } }) => (
        <Input
          className={className}
          testId={testId}
          value={value ?? ''}
          type="text"
          label={t('userForm.username')}
          icon={<UsernameIconHandler status={validationStatus} />}
          color={hasError ? 'warning' : 'primary'}
          onChange={async e => {
            const value = e.target.value;
            onChange(value);
            await debouncedHandlerChange(e);
          }}
          disabled={disabled}
          errorLabelText={error?.message}
          labelPosition={labelPosition}
          errorLabelPosition={errorLabelPosition}
        />
      )}
    />
  );
}

interface UsernameIconHandlerProps {
  status: UsernameInputStatus;
}

const UsernameIconHandler: React.FC<UsernameIconHandlerProps> = ({
  status,
}) => {
  const className = 'w-4 h-4';
  switch (status) {
    case 'invalid':
      return <XIcon className={className} />;
    case 'loading':
      return <LoadingIcon className={className} />;
    case 'valid':
      return (
        <CheckIcon data-testid="validUsernameFeedback" className={className} />
      );
    default:
      return <React.Fragment />;
  }
};
