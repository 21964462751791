import React from 'react';
import { useTranslation } from 'react-i18next';
import { handleIcon, handleMediaName } from '../../functions/handleSocialMedia';
import { MediaName } from '../../models/UserProfile';
import { PlusIcon, TrashIcon } from '@heroicons/react/outline';
import TextInput from '../common/dataInput/TextInput';
import Text from '../common/dataDisplay/Text';
import ConditionalRenderer from '../common/ConditionalRenderer';
import { Control, Controller, useFieldArray } from 'react-hook-form';
import { ProfileInputs } from '../../pages/ProfilePage';

interface AddLinksProps {
  shouldRender: boolean;
  setHasChanges: (value: boolean) => void;
  control: Control<ProfileInputs>;
}

export default function ProfileLinks({
  shouldRender,
  control,
  setHasChanges,
}: AddLinksProps) {
  const { t } = useTranslation('translation', {
    keyPrefix: 'profile',
  });
  const socialMediaLimit = 6;

  const { fields, append, remove, update } = useFieldArray({
    control,
    name: 'socialMedia',
    rules: { minLength: 1, maxLength: socialMediaLimit },
  });

  function isValidLink(link: string): boolean {
    const linkRegex = /\..{2,}$/;

    return linkRegex.test(link);
  }

  function shouldRenderPlusButton(socialMediaLength: number, index: number) {
    return (
      socialMediaLength < socialMediaLimit && index + 1 === socialMediaLength
    );
  }
  return (
    <ConditionalRenderer condition={shouldRender}>
      <div className="flex flex-col gap-1 md:tall:pb-0">
        <Text format="rubik-500" text="Links" className="text-18 text-accent" />
        {fields.map((link, index, { length }) => {
          return (
            <div key={link.id} className="flex items-center gap-2 text-primary">
              <Controller
                name={`socialMedia.${index}`}
                rules={{
                  validate: value => {
                    if (value.mediaUrl) {
                      return isValidLink(value.mediaUrl);
                    } else return true;
                  },
                }}
                control={control}
                render={({
                  field: { value, onChange },
                  formState: { errors },
                }) => {
                  return (
                    <React.Fragment>
                      <TextInput
                        errorLabelText={
                          errors.socialMedia?.[index] ? t('errors.links') : ''
                        }
                        dataTestId={`socialMediaInput${index}`}
                        type="text"
                        value={value.mediaUrl}
                        onChange={e => {
                          onChange({
                            mediaUrl: e.target.value,
                            mediaName: handleMediaName(e.target.value),
                          });
                          setHasChanges(true);
                        }}
                        placeholder="https://www.minharedesocial.com"
                        className={{
                          base: 'flex w-3/4 lg:max-w-[479px] font-rubik text-16 text-base-content',
                        }}
                        color={
                          errors.socialMedia?.[index] ? 'warning' : 'primary'
                        }
                      />
                      <div className="hidden md:block align-middle">
                        {handleIcon(value.mediaName)}
                      </div>
                    </React.Fragment>
                  );
                }}
              />
              <TrashIcon
                className="w-6 h-6 text-error align-middle cursor-pointer hover:opacity-40 transition duration-100 ease-in"
                onClick={() => {
                  setHasChanges(true);
                  if (length === 1) {
                    update(index, {
                      mediaUrl: '',
                      mediaName: MediaName.OTHER,
                    });
                  } else remove(index);
                }}
              />
              <ConditionalRenderer
                condition={shouldRenderPlusButton(length, index)}
              >
                <PlusIcon
                  data-testid="addLinkButton"
                  className="text-primary w-6 h-6 cursor-pointer hover:opacity-40 transition duration-100 ease-in"
                  onClick={() => {
                    setHasChanges(true);
                    append({
                      mediaUrl: '',
                      mediaName: MediaName.OTHER,
                    });
                  }}
                />
              </ConditionalRenderer>
            </div>
          );
        })}
        <ConditionalRenderer condition={fields.length >= socialMediaLimit}>
          <Text
            className="text-error pt-1"
            size="text-14"
            text={t('sixLinks')}
          />
        </ConditionalRenderer>
      </div>
    </ConditionalRenderer>
  );
}
