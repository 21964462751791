import MainButton from '@/components/common/buttons/MainButton';
import { useEffect, useState } from 'react';
import { SaveIcon } from '@heroicons/react/outline';
import { ColumnDND, ItemDND } from '@/models/DNDOrdenation';
import Activity from '@/models/Activity';
import { DNDOrdenationActivity } from '@/models/ActivityElement';
import alert from '@/utils/UseAlert';
import { useTranslation } from 'react-i18next';
import ActivityEdit from '../commom/ActivityTitleEdit';
import DNDOrdenationColumnsEdit from './DNDOrdenationColumnsEdit/DNDOrdenationColumnsEdit';
import {
  deleteDndOrdenationColumn,
  deleteDndOrdenationItem,
  getDndOrdenationActivity,
  getDndOrdenationColumn,
  updateColumnItemsOrder,
  updateDndOrdenationActivity,
  updateDndOrdenationColumn,
  createDndOrdenationActivity,
  createDndOrdenationColumn,
  createDndOrdenationItem,
} from '@/data/services/activityElement/dndOrdenationActivityServices';
import { ApiError } from '@/models/Errors';
import ContainerActivityEdit from '../commom/ContainerActivityEdit';

interface DragDropElementEditProps
  extends Omit<DNDOrdenationActivity, 'activity'> {
  activity: Activity;
  onEdit: (props: any) => void;
  onSave: (props: DNDOrdenationActivity) => void;
}

export default function DNDOrdenationElementEdit({
  activity,
  onEdit,
  columns,
  onSave,
  ...rest
}: DragDropElementEditProps) {
  const isNew = isNaN(rest.id);

  const { t } = useTranslation('translation', {
    keyPrefix: 'activity.manageActivity.activityDNDOrdenation',
  });
  const [buttonDisabled, setButtonDisabled] = useState(
    typeof rest.changeStatus === 'undefined' || rest.changeStatus === 'edit',
  );
  const [activityFieldsErrors, setaAtivityFieldsErrors] = useState<string[]>(
    [],
  );
  const [isLoading, setIsLoading] = useState(false);

  const defaultColumns = [new ColumnDND()];

  useEffect(() => {
    let mounted = true;

    if (mounted) {
      setButtonDisabled(
        typeof rest.changeStatus === 'undefined' ||
          rest.changeStatus === 'edit',
      );
    }
  }, [rest.changeStatus]);

  function checkFields() {
    const columnsCheck = columns || defaultColumns;

    let hasError = false;
    if (!rest.title) {
      setaAtivityFieldsErrors(['noTitle']);
      hasError = true;
    } else {
      setaAtivityFieldsErrors([]);
      hasError = false;
    }

    const columnsCheckingErrors = columnsCheck.map(column => {
      const itemsCheckingErrors = column.items.map(item => {
        const errors = [];

        if (item.image && !item.altText) {
          errors.push('noAltText');
        }

        if (!item.image && item.altText) {
          errors.push('noImage');
        }

        if (!item.text && !item.image && !item.altText) {
          errors.push('noContent');
        }

        if (!!item.text && item.text.length > 150) {
          errors.push('maxLength');
        }

        if (!!item.altText && item.altText.length > 150) {
          errors.push('maxLengthAltText');
        }

        item.errors = errors;

        if (item.errors.length > 0) {
          hasError = true;
        }

        return item;
      });

      column.items = itemsCheckingErrors;

      return column;
    });

    onEdit({
      ...rest,
      columns: columnsCheckingErrors,
      activity,
    });

    return hasError;
  }

  function onChangeActivityFields(
    value: string,
    keyField: 'title' | 'subtitle',
  ) {
    onEdit({
      ...rest,
      columns,
      activity,
      [keyField]: value,
    });
  }

  function handleUpdateActivityColumns(columns: ColumnDND[]) {
    const changeStatus =
      rest.changeStatus !== 'new' ? 'changed' : rest.changeStatus;

    onEdit({
      ...rest,
      columns,
      activity,
      changeStatus,
    });
  }

  function handleAddColumn() {
    const editedColumns = columns || defaultColumns;

    editedColumns.push(new ColumnDND());

    handleUpdateActivityColumns(editedColumns);
  }

  async function requestAddColumn() {
    try {
      setIsLoading(true);

      const newColumn = new ColumnDND();
      const newItems = [
        new ItemDND(undefined, t('text')),
        new ItemDND(undefined, t('text')),
        new ItemDND(undefined, t('text')),
      ];

      newColumn.items = newItems;

      const newDndColumn = await createDndOrdenationColumn(newColumn, rest.id);

      columns.push(newDndColumn);

      handleUpdateActivityColumns(columns);

      alert.success(t('saveAddColumnSuccess'));
    } catch (error: any) {
      const apiError = new ApiError(error);
      alert.error(apiError.getErrorMessage());
    } finally {
      setIsLoading(false);
    }
  }

  function handleAddItem(indexColumn: number) {
    const editedColumns = columns || defaultColumns;

    editedColumns[indexColumn].items.push(new ItemDND());

    handleUpdateActivityColumns(editedColumns);
  }

  async function requestAddItem(indexColumn: number) {
    try {
      setIsLoading(true);

      const column = columns[indexColumn];
      const newItem = new ItemDND(undefined, t('text'));

      if (column.id) {
        const newDndItem = await createDndOrdenationItem(
          newItem,
          column.items.length + 1,
          column.id,
        );

        columns[indexColumn].items.push(newDndItem);

        handleUpdateActivityColumns(columns);

        alert.success(t('saveAddItemSuccess'));
      }
    } catch (error: any) {
      const apiError = new ApiError(error);
      alert.error(apiError.getErrorMessage());
    } finally {
      setIsLoading(false);
    }
  }

  function handleDeleteColumn(indexColumn: number) {
    const editedColumns = columns || defaultColumns;

    editedColumns.splice(indexColumn, 1);

    handleUpdateActivityColumns(editedColumns);
  }

  async function requestDeleteColumn(indexColumn: number) {
    try {
      setIsLoading(true);
      const column = columns[indexColumn];

      if (column.id && column.dndOrdenationActivity) {
        await deleteDndOrdenationColumn(column.id);

        const updatedDndOrdenationActivity = await getDndOrdenationActivity(
          column.dndOrdenationActivity,
        );

        columns = updatedDndOrdenationActivity.columns;

        handleUpdateActivityColumns(columns);

        alert.success(t('saveDeleteColumnSuccess'));
      }
    } catch (error: any) {
      const apiError = new ApiError(error);
      alert.error(apiError.getErrorMessage());
    } finally {
      setIsLoading(false);
    }
  }

  function handleDeleteItem({
    indexColumn,
    indexItem,
  }: {
    indexColumn: number;
    indexItem: number;
  }) {
    const editedColumns = columns || defaultColumns;

    editedColumns[indexColumn].items.splice(indexItem, 1);

    handleUpdateActivityColumns(editedColumns);
  }

  async function requestDeleteItem({
    indexColumn,
    indexItem,
  }: {
    indexColumn: number;
    indexItem: number;
  }) {
    try {
      setIsLoading(true);

      const column = columns[indexColumn];
      const item = columns[indexColumn].items[indexItem];

      if (column.id && item.id) {
        await deleteDndOrdenationItem(item.id, column.id);

        const updatedColumn = await getDndOrdenationColumn(column.id);

        columns[indexColumn].items = updatedColumn.items;

        handleUpdateActivityColumns(columns);

        alert.success(t('saveDeleteItemSuccess'));
      }
    } catch (error: any) {
      const apiError = new ApiError(error);
      alert.error(apiError.getErrorMessage());
    } finally {
      setIsLoading(false);
    }
  }

  async function requestSaveNewActivity() {
    if (checkFields()) return;

    try {
      setIsLoading(true);
      const dndOrdenationActivity = await createDndOrdenationActivity(
        activity,
        rest,
      );

      const dndOrdenationColumns = await Promise.all(
        columns.map(column =>
          createDndOrdenationColumn(column, dndOrdenationActivity.id),
        ),
      );

      onSave({
        ...rest,
        ...dndOrdenationActivity,
        columns: dndOrdenationColumns,
      });
      alert.success(t('saveSuccess'));
    } catch (error: any) {
      const apiError = new ApiError(error);
      alert.error(apiError.getErrorMessage());
    } finally {
      setIsLoading(false);
    }
  }

  async function requestSaveExistentActivity() {
    if (checkFields()) return;

    try {
      setIsLoading(true);
      const dndOrdenationActivity = await updateDndOrdenationActivity(rest);

      const dndOrdenationColumns = await Promise.all(
        columns.map(column => updateDndOrdenationColumn(column)),
      );

      onSave({
        ...rest,
        ...dndOrdenationActivity,
        columns: dndOrdenationColumns,
      });
      alert.success(t('saveSuccess'));
    } catch (error: any) {
      const apiError = new ApiError(error);
      alert.error(apiError.getErrorMessage());
    } finally {
      setIsLoading(false);
    }
  }

  function handleOrdenationItems({
    items,
    indexColumn,
  }: {
    items: ItemDND[];
    indexColumn: number;
  }) {
    const editedColumns = columns || defaultColumns;

    editedColumns[indexColumn].items = items;

    handleUpdateActivityColumns(editedColumns);
  }

  async function requestOrdenationItems({
    items,
    indexColumn,
  }: {
    items: ItemDND[];
    indexColumn: number;
  }) {
    try {
      setIsLoading(true);
      const updatedColumnListOrder = items.map(({ id }) => ({
        id,
      }));

      const columnId = columns[indexColumn].id;

      if (columnId && updatedColumnListOrder) {
        await updateColumnItemsOrder(updatedColumnListOrder, columnId);

        const updatedQuestionColumnItems = await getDndOrdenationColumn(
          columnId,
        );

        columns.splice(indexColumn, 1, updatedQuestionColumnItems);

        handleUpdateActivityColumns(columns);
        alert.success(t('saveOrderSuccess'));
      }
    } catch (error: any) {
      const apiError = new ApiError(error);
      alert.error(apiError.getErrorMessage());
    } finally {
      setIsLoading(false);
    }
  }

  interface Actions {
    newActivity: DNDOrdenationActions;
    editActivity: DNDOrdenationActions;
  }

  interface DNDOrdenationActions {
    addColumn: () => void;
    addItem: (indexColumn: number) => void;
    deleteColumn: (indexColumn: number) => void;
    deleteItem: ({
      indexColumn,
      indexItem,
    }: {
      indexColumn: number;
      indexItem: number;
    }) => void;

    ordenationItems: ({
      items,
      indexColumn,
    }: {
      items: ItemDND[];
      indexColumn: number;
    }) => void;
    handleSave: () => void;
  }

  const actions: Actions = {
    newActivity: {
      addColumn: handleAddColumn,
      addItem: handleAddItem,
      deleteColumn: handleDeleteColumn,
      deleteItem: handleDeleteItem,
      handleSave: requestSaveNewActivity,
      ordenationItems: handleOrdenationItems,
    },
    editActivity: {
      addColumn: requestAddColumn,
      addItem: requestAddItem,
      deleteColumn: requestDeleteColumn,
      deleteItem: requestDeleteItem,
      handleSave: requestSaveExistentActivity,
      ordenationItems: requestOrdenationItems,
    },
  };

  const {
    addColumn,
    addItem,
    deleteColumn,
    deleteItem,
    handleSave,
    ordenationItems,
  } = actions[isNew ? 'newActivity' : 'editActivity'];

  return (
    <ContainerActivityEdit data-testid="dndOrdenationElementEdit">
      <ActivityEdit
        title={rest.title}
        subtitle={rest.subtitle}
        activityFieldsErrors={activityFieldsErrors}
        onChangeActivityFields={onChangeActivityFields}
        isLoading={isLoading}
      />
      <DNDOrdenationColumnsEdit
        handleUpdateActivityColumns={handleUpdateActivityColumns}
        columns={columns || defaultColumns}
        handleAddColumn={addColumn}
        handleDeleteColumn={deleteColumn}
        handleAddItem={addItem}
        handleDeleteItem={deleteItem}
        ordenationItems={ordenationItems}
        isLoading={isLoading}
      />
      <hr className="h-px my-4 border-primary/40" />
      <MainButton
        dataTestId="saveButton"
        icon={<SaveIcon />}
        text={t('save')}
        onClick={handleSave}
        loading={isLoading}
        disabled={buttonDisabled}
      />
    </ContainerActivityEdit>
  );
}
