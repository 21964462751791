import { ApiError } from '@/models/Errors';
import ErrorDispatcher from './ErrorDispatcher';

type BookErrorDispatcherType = 'create' | 'update' | 'delete' | 'updateOrder';

export class BookErrorDispatcher implements ErrorDispatcher {
  constructor(
    private type: BookErrorDispatcherType,
    private payload: {
      lessonId: number;
      bookId: number;
      apiError: ApiError;
      chapterId?: number;
    },
  ) {}

  public getError() {
    const errors: Record<BookErrorDispatcherType, () => Error> = {
      create: this.createError,
      update: this.updateError,
      delete: this.deleteError,
      updateOrder: this.updateOrderError,
    };

    return errors[this.type].bind(this)();
  }
  public baseError(action: BookErrorDispatcherType) {
    return `An error on ${action} chapter - lesson: ${this.payload.lessonId} - book: ${this.payload.bookId}`;
  }

  public createError() {
    return new Error(this.baseError('create'));
  }

  public updateError() {
    return new Error(
      `${this.baseError('update')} - chapter: ${
        this?.payload.chapterId
      } - api: ${this.payload.apiError?.getErrorMessage()}`,
    );
  }

  public deleteError() {
    return new Error(
      `${this.baseError('delete')} - chapter: ${this?.payload.chapterId}`,
    );
  }

  public updateOrderError() {
    return new Error(this.baseError('updateOrder'));
  }
}
