import i18next, { t } from 'i18next';
import moment from 'moment';
import React, { useEffect, useMemo, useState } from 'react';
import Countdown, { zeroPad } from 'react-countdown';
import {
  ChevronDownIcon,
  ChevronUpIcon,
  ClockIcon,
  ExclamationCircleIcon,
} from '@heroicons/react/outline';
import { AnimatePresence, motion } from 'framer-motion';

import Skeleton from './Skeleton';
import RoundedButton from './buttons/RoundedButton';
import Text from './dataDisplay/Text';
import { formatLessonName } from '@/functions/lessonsName';
import Klass from '@/models/Klass';
import ScheduledLesson from '@/models/ScheduledLesson';
import { Lesson } from '@/models/Lesson';
import { lessonCardAnimation } from '@/utils/animations/lessonCardAnimation';
import { ROLES } from '@/utils/roles';
import { ROUTES } from '@/utils/routes';

type AstroCountdownBaseProps = {
  loading?: boolean;
  klass?: Klass;
  nextLesson?: ScheduledLesson;
};

export default function AstroCountdown({
  klass,
  loading,
  nextLesson,
}: AstroCountdownBaseProps) {
  if (loading)
    return (
      <Skeleton className="bg-primary-content rounded-xl h-[300px] w-full sm:w-[34.438rem] md:w-full lg:w-[34.438rem]" />
    );

  if (klass && nextLesson)
    return <CountdownContainer klass={klass} nextLesson={nextLesson} />;

  return null;
}

type CountdownContainerProps = Required<
  Pick<AstroCountdownBaseProps, 'klass' | 'nextLesson'>
>;

function CountdownContainer({ klass, nextLesson }: CountdownContainerProps) {
  const [isExpanded, toggleExpand] = useState(true);

  const dateTime = useMemo(() => {
    return moment(nextLesson.datetime);
  }, [nextLesson]);

  const baseUrl = ROUTES.COURSES.LESSON.INFO(
    klass.coursePathSlug,
    nextLesson.lesson.id,
    klass.id,
  );

  const { translate, url } = renderButtonValues(nextLesson);

  const isToday = ROLES.SCHEDULED_LESSON.isTodayLessonDate({
    scheduledLesson: nextLesson,
  });

  const [isClassroomTime, setIsClassroomTime] = useState(
    ROLES.SCHEDULED_LESSON.isTodayLessonTime({
      klass,
      scheduledLesson: nextLesson,
    }),
  );

  useEffect(() => {
    const updateClassroomTime = () => {
      setIsClassroomTime(
        ROLES.SCHEDULED_LESSON.isTodayLessonTime({
          klass,
          scheduledLesson: nextLesson,
        }),
      );
    };

    const interval = isToday
      ? setInterval(updateClassroomTime, 5000)
      : undefined;

    return () => clearInterval(interval);
  }, [isToday, klass, nextLesson]);

  return (
    <div
      data-testid="countdown"
      className={`flex flex-col ${
        isExpanded
          ? 'w-full sm:w-fit md:w-full lg:w-fit'
          : 'w-full max-w-[34.438rem]'
      } `}
    >
      <div
        onClick={() => toggleExpand(old => !old)}
        className={`flex px-6 py-2.5 justify-between w-full items-center cursor-pointer bg-accent text-base-100 ${
          isExpanded
            ? 'rounded-t-2xl lg:pointer-events-none lg:cursor-default'
            : 'rounded-2xl'
        }`}
      >
        <div className="flex items-center gap-5">
          <ClockIcon strokeWidth={2.5} className="w-4 h-4 sm:w-5 sm:h-5" />
          <Text
            format="rubik-500"
            size="text-16 sm:text-18"
            text={t('countdown.nextClass').toString()}
            className="uppercase m-0"
          />
        </div>
        <div className={isExpanded ? 'lg:hidden' : ''}>
          {isExpanded ? (
            <ChevronUpIcon className="w-4 h-4 sm:w-5 sm:h-5" />
          ) : (
            <ChevronDownIcon className="w-4 h-4 sm:w-5 sm:h-5" />
          )}
        </div>
      </div>
      <AnimatePresence>
        {isExpanded && (
          <motion.div
            className="flex flex-col bg-base-100 shadow-default p-2 xs:p-3 lg:p-6 xs:flex-row md:flex-col lg:flex-row gap-2 md:gap-3.5 justify-between rounded-b-2xl w-full"
            {...lessonCardAnimation}
          >
            <div className="w-full sm:w-281 md:w-full lg:w-281 justify-between gap-4 2xl:gap-0 flex flex-col">
              <Text
                text={formatLessonName(nextLesson.lesson)}
                id="lessonName"
                format="rubik-500"
                className="text-18 lg:text-20 text-secondary"
              />
              <div>
                <NextClassMessage dateTime={dateTime} />
                <Countdown
                  date={dateTime.valueOf()}
                  renderer={CountdownRenderer}
                />
              </div>
            </div>

            <div className="flex flex-col gap-y-3 relative w-full justify-end h-full sm:w-52 md:w-full lg:w-52 text-base-100">
              <ExclamationCircleIcon className="absolute -top-2.5 -right-2.5 h-8 w-8 text-secondary" />
              <p className="font-rubik text-14 lg:text-16 bg-secondary-content rounded-xl p-3.5 leading-4 lg:leading-4 text-base-content">
                {`${t(renderInstructionText(nextLesson.lesson))}`}
              </p>
              <RoundedButton
                testId="accessLessonButton"
                href={baseUrl + url}
                text={t(translate)}
                className="w-full text-14 md:text-16"
              />
              <RoundedButton
                href={nextLesson.url}
                isExternalLink
                text={t('countdown.accessClassroom')}
                disabled={!isClassroomTime}
                className="w-full text-14 md:text-16"
              />
            </div>
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
}

const CountdownRenderer = ({
  days,
  hours,
  minutes,
  seconds,
}: {
  days: number;
  hours: number;
  minutes: number;
  seconds: number;
}) => {
  return (
    <div className="grid grid-cols-4 justify-between text-base-content gap-2">
      <div className="justify-center text-center items-center rounded flex flex-col bg-secondary-content">
        <span className="font-rubik text-10">
          {t('countdown.days').toString()}
        </span>

        <Text
          text={zeroPad(days, 2)}
          format="poppins-600"
          className="text-base-content text-32"
        />
      </div>

      <div className="justify-center text-center items-center rounded p-1.5 flex flex-col bg-secondary-content">
        <span className="font-rubik text-10">{`${t('countdown.hours')}`}</span>
        <Text
          format="poppins-600"
          text={zeroPad(hours, 2)}
          className="text-32 text-base-content"
        />
      </div>

      <div className="justify-center text-center items-center rounded p-1.5 flex flex-col bg-secondary-content">
        <span className="font-rubik text-10">{`${t(
          'countdown.minutes',
        )}`}</span>
        <Text
          format="poppins-600"
          text={zeroPad(minutes, 2)}
          className="text-base-content text-32"
        />
      </div>
      <div className="justify-center text-center items-center rounded p-1.5 flex flex-col bg-secondary-content">
        <span className="font-rubik text-10">
          {t('countdown.seconds').toString()}
        </span>
        <Text
          format="poppins-600"
          text={zeroPad(seconds, 2)}
          className="text-base-content text-32"
        />
      </div>
    </div>
  );
};

const NextClassMessage = ({ dateTime }: { dateTime: moment.Moment }) => {
  if (i18next.language === 'pt_BR') {
    return (
      <React.Fragment>
        {dateTime && (
          <div>
            <Text
              format="rubik-500"
              id="countDownDay"
              text={dateTime.format('[Dia] DD [de] MMMM')}
              className="text-secondary capitalize lg:text-18"
            />

            <Text
              id="countDownFrequency"
              text={dateTime.format('dddd [às] HH:mm[h], [daqui a:]')}
              className="text-base-content pb-1.5 flex"
            />
          </div>
        )}
      </React.Fragment>
    );
  } else {
    return (
      <React.Fragment>
        <Text
          format="rubik-500"
          id="countDownDay"
          text={dateTime.format('MMMM DD')}
          className="text-secondary capitalize text-18"
        />

        <Text
          id="countDownFrequency"
          text={dateTime.format('dddd [at] HH:mm[h], [in:]')}
          className="text-base-content pb-1.5 flex"
        />
      </React.Fragment>
    );
  }
};

type RenderButtonValuesProps = {
  translate: string;
  url: string;
};

function renderButtonValues(
  scheduledLesson?: ScheduledLesson,
): RenderButtonValuesProps {
  let translate = '';
  let url = '';

  if (scheduledLesson) {
    const {
      lesson: { instructionsUrl, book },
    } = scheduledLesson;

    if (!!instructionsUrl) {
      translate = 'countdown.watchVideo';
      url = '/help';
      return { translate, url };
    } else if (!!book) {
      translate = 'countdown.materialButton';
      url = '/book/chapter/1';
      return { translate, url };
    } else {
      translate = 'countdown.accessLesson';
      return { translate, url };
    }
  }

  return { translate, url };
}

function renderInstructionText(lesson?: Lesson) {
  if (lesson?.instructionsUrl) {
    return 'countdown.instructionUrlText';
  } else if (lesson?.book) {
    return 'countdown.materialText';
  } else {
    return 'countdown.lessonText';
  }
}
