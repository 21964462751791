import { useEffect, useState } from 'react';
import { Control, Controller, SubmitHandler, useForm } from 'react-hook-form';
import { buildChangedObject } from '@/utils/buildChangedObject';
import { useTranslation } from 'react-i18next';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import {
  createStudentLessonRating,
  updateStudentLessonRating,
} from '@/data/services/lessonRatingServices';
import { StudentLessonRating } from '@/models/LessonRating';
import { SaveIcon } from '@heroicons/react/outline';
import { isEqual } from 'lodash';
import TextAreaInput from '@/components/common/dataInput/TextAreaInput';
import MainButton from '@/components/common/buttons/MainButton';
import Text from '@/components/common/dataDisplay/Text';
import alert from '@/utils/UseAlert';
import { LoadingIcon } from '@/components/icons';
import ConditionalRenderer from '@/components/common/ConditionalRenderer';
import ComponentGuard from '@/components/common/ComponentGuard';
import { UserTypeEnum } from '@/models/User';
import LessonCard from '../LessonCard/LessonCard';
import {
  klassesQueryKeys,
  studentLessonsRatingQueryKeys,
  teacherLessonRatingssQuerykeys,
} from '@/data/services/querykeys';

type StudentFeedbackCardProps = {
  lessonId: number;
  klassId?: number;
  studentId?: number;
};

interface RatingInputs {
  lessonRating: number;
  teacherRating: number;
  comment: string;
}
export const StudentFeedbackCard = ({
  lessonId,
  klassId,
  studentId,
}: StudentFeedbackCardProps) => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'klassView.feedback',
  });

  const {
    data: lessonRating,
    isInitialLoading,
    isFetching: isLoading,
  } = useQuery({
    enabled: !!lessonId && !!studentId && !!klassId,
    refetchOnMount: false,
    ...studentLessonsRatingQueryKeys.list({ lessonId, userId: studentId }),
  });

  const studentLessonRating = lessonRating?.results[0];

  const { data: klass } = useQuery({
    enabled: !!klassId,
    refetchOnMount: false,
    ...klassesQueryKeys.get(klassId ?? 0),
  });

  const queryClient = useQueryClient();

  const [isRequesting, setIsRequesting] = useState(false);
  const [hasChanges, setHasChanges] = useState(false);

  const { handleSubmit, control, register, watch, reset } =
    useForm<RatingInputs>({ defaultValues: studentLessonRating });

  useEffect(() => {
    const changes = watch(value => {
      setHasChanges(!isEqual(value, studentLessonRating));
    });

    return () => changes.unsubscribe();
  }, [watch, studentLessonRating]);

  useEffect(() => {
    reset({
      lessonRating: studentLessonRating?.lessonRating || 0,
      teacherRating: studentLessonRating?.teacherRating || 0,
      comment: studentLessonRating?.comment || '',
    });
  }, [studentLessonRating, reset]);

  const onSubmit: SubmitHandler<RatingInputs> = async ({
    lessonRating,
    teacherRating,
    comment,
  }) => {
    if (klass) {
      const body: Omit<
        StudentLessonRating,
        'userId' | 'createdAt' | 'id' | 'klassName'
      > = {
        lessonId,
        teacherId: klass.teacherId,
        lessonRating,
        teacherRating,
        comment,
      };

      try {
        setIsRequesting(true);

        if (!studentLessonRating?.id) {
          if (!lessonRating || !teacherRating) {
            alert.warning(t('alertWarning'));
            return;
          } else {
            await createStudentLessonRating(body);
          }
        } else {
          const changedFields = buildChangedObject<StudentLessonRating>(
            studentLessonRating,
            body,
          );
          await updateStudentLessonRating(
            studentLessonRating.id,
            changedFields,
          );
        }
        queryClient.invalidateQueries(teacherLessonRatingssQuerykeys.list._def);
        setHasChanges(false);
        alert.success(t('saveSuccess'));
      } catch (error) {
        alert.error(t('saveError'));
      } finally {
        setIsRequesting(false);
      }
    }
  };

  return (
    <ComponentGuard roles={[UserTypeEnum.STUDENT]}>
      <ConditionalRenderer condition={klassId}>
        <LessonCard
          type="studentFeedback"
          testId="studentFeedbackCard"
          notification={studentLessonRating ? 0 : 1}
        >
          <ConditionalRenderer
            condition={!isInitialLoading}
            fallback={
              <div className="flex justify-center items-center w-full h-16">
                <LoadingIcon className="w-8 h-8 text-secondary/40" />
              </div>
            }
          >
            <form
              onSubmit={handleSubmit(onSubmit)}
              className="flex flex-col gap-2 pt-1"
            >
              <StudentRating
                title={t('contentAndActivity')}
                rating={studentLessonRating?.lessonRating || 0}
                control={control}
                testId="studentLessonRating"
                name="lessonRating"
              />
              <StudentRating
                title={t('teacherExplanation')}
                name="teacherRating"
                testId="studentTeacherRating"
                rating={studentLessonRating?.teacherRating || 0}
                control={control}
              />
              <TextAreaInput
                label={t('comment')}
                defaultValue={studentLessonRating?.comment || ''}
                register={register('comment')}
                testId="studentRatingComment"
              />
              <MainButton
                loading={isRequesting || isLoading}
                icon={<SaveIcon />}
                disabled={!hasChanges}
                text={t('save')}
                dataTestId="studentRatingSave"
                type="submit"
              />
            </form>
          </ConditionalRenderer>
        </LessonCard>
      </ConditionalRenderer>
    </ComponentGuard>
  );
};

const StudentRating = ({
  title,
  rating,
  control,
  name,
  testId,
}: {
  title: string;
  rating: number;
  control: Control<RatingInputs>;
  name: 'lessonRating' | 'teacherRating';
  testId: string;
}) => {
  const commonClasses =
    'text-32 border-4 rounded-full h-10 w-10 flex items-center align-middle justify-center aspect-square leading-none';
  return (
    <Controller
      name={name}
      control={control}
      render={({ field: { onChange, value } }) => (
        <div data-testid={testId} className="flex flex-col gap-y-0.5">
          <Text text={title} />
          <div className="flex justify-between w-full">
            <button
              data-testid={testId + '1'}
              onClick={() => onChange(1)}
              type="button"
              className={`${commonClasses} border-error-content ${
                (value || rating) === 1 || 'opacity-40'
              }`}
            >
              😖
            </button>
            <button
              data-testid={testId + '2'}
              onClick={() => onChange(2)}
              type="button"
              className={`${commonClasses} border-warning-content ${
                (value || rating) === 2 || 'opacity-40'
              }`}
            >
              😐
            </button>
            <button
              data-testid={testId + '3'}
              onClick={() => onChange(3)}
              type="button"
              className={`${commonClasses} border-success-content ${
                (value || rating) === 3 || 'opacity-40'
              }`}
            >
              😍
            </button>
          </div>
        </div>
      )}
    />
  );
};
