import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { InformationCircleIcon, PencilIcon } from '@heroicons/react/outline';
import MainButton from '@/components/common/buttons/MainButton';
import Klass from '@/models/Klass';
import Tag from '@/components/common/dataDisplay/Tag';
import KlassForm from './KlassForm';
import ComponentGuard from '@/components/common/ComponentGuard';
import { UserTypeEnum } from '@/models/User';

interface EditKlassProps {
  klass: Klass;
}

export default function EditKlass({ klass }: EditKlassProps) {
  const { t } = useTranslation('translation', {
    keyPrefix: 'manageKlasses.addKlass',
  });
  const [editMode, setEditMode] = useState(false);

  return (
    <div className="flex flex-col w-full font-rubik pt-5 pb-5 ml-8">
      <div className="flex w-auto items-center gap-8 mb-3 ">
        <Tag
          text={t('info')}
          icon={<InformationCircleIcon className="w-6 h-6" />}
        />
        <ComponentGuard roles={[UserTypeEnum.SUPER_ADMIN]}>
          <MainButton
            disabled={editMode}
            onClick={() => setEditMode(true)}
            text={t('edit')}
            icon={<PencilIcon />}
            color="custom"
            className="text-primary"
            dataTestId="editButton"
          />
        </ComponentGuard>
      </div>
      <KlassForm
        klass={klass}
        formMode="edit"
        disabled={!editMode}
        className="w-[400px] mb-5"
        onCancel={() => setEditMode(false)}
      />
    </div>
  );
}
