import React from 'react';
import { Draggable } from '@hello-pangea/dnd';
import TooltipHandler from '../../common/TooltipHandler';
import { DragIcon } from '../../icons';

interface LessonReplacerItemProps {
  dragableHandler: {
    disabled: boolean;
    message: string;
  };
  testId?: string;
  index: number;
  draggableId: string;
  children: React.ReactNode;
}

function LessonReplacerItem({
  children,
  dragableHandler,
  draggableId,
  index,
  testId,
}: LessonReplacerItemProps) {
  return (
    <Draggable
      isDragDisabled={dragableHandler.disabled}
      data-testid={testId}
      index={index}
      draggableId={draggableId}
    >
      {provided => (
        <div
          data-testid="lessonTab"
          className="flex flex-row items-center"
          {...provided.draggableProps}
          ref={provided.innerRef}
        >
          {!dragableHandler.disabled && (
            <div className="-ml-6" {...provided.dragHandleProps}>
              <TooltipHandler
                renderTooltip={dragableHandler.disabled}
                tooltipMessage={dragableHandler.message}
              >
                <DragIcon data-testid="dragIcon" />
              </TooltipHandler>
            </div>
          )}
          {children}
        </div>
      )}
    </Draggable>
  );
}

export default LessonReplacerItem;
