import { useTranslation } from 'react-i18next';
import { ColumnDND, ItemDND } from '../../../../../../models/DNDOrdenation';
import useCourseEditing from '../../../../../../data/hook/useCourseEditing';
import DNDOrdenationItemListEdit from './DNDOrdenationItemListEdit';
import TooltipHandler from '../../../../../common/TooltipHandler';
import { PlusIcon } from '@heroicons/react/outline';
import MainButton from '../../../../../common/buttons/MainButton';

interface DNDOrdenationColumnsEditProps {
  columns: ColumnDND[];
  handleUpdateActivityColumns: (columns: ColumnDND[]) => void;
  handleAddColumn: () => void;
  handleAddItem: (indexColumn: number) => void;
  handleDeleteColumn: (indexColumn: number) => void;
  handleDeleteItem: ({
    indexColumn,
    indexItem,
  }: {
    indexColumn: number;
    indexItem: number;
  }) => void;
  ordenationItems: ({
    items,
    indexColumn,
  }: {
    items: ItemDND[];
    indexColumn: number;
  }) => void;
  isLoading: boolean;
}

export default function DNDOrdenationColumnsEdit(
  props: DNDOrdenationColumnsEditProps,
) {
  const {
    columns,
    handleUpdateActivityColumns,
    handleAddColumn,
    handleAddItem,
    handleDeleteColumn,
    handleDeleteItem,
    ordenationItems,
    isLoading,
  } = props;

  const { allowBigChanges } = useCourseEditing();

  const { t: tVersioningRules } = useTranslation('translation', {
    keyPrefix: 'adminPage.editCourseRules',
  });

  const { t } = useTranslation('translation', {
    keyPrefix: 'activity.manageActivity.activityDNDOrdenation',
  });

  function handleUpdateColumns({
    column,
    indexColumn,
  }: {
    column: ColumnDND;
    indexColumn: number;
  }) {
    columns.splice(indexColumn, 1, column);

    handleUpdateActivityColumns(columns);
  }

  return (
    <div className={`flex flex-col gap-y-5 ${isLoading ? 'disabled' : ''}`}>
      <label
        data-testid="columnsLabel"
        className="font-rubik text-base-content font-600"
      >
        {t('columns')}
      </label>

      {columns.map((column, index) => {
        return (
          <DNDOrdenationItemListEdit
            key={column.id ?? column.identifier}
            column={column}
            indexColumn={index}
            handleUpdateColumns={handleUpdateColumns}
            handleAddItem={handleAddItem}
            handleDeleteColumn={handleDeleteColumn}
            handleDeleteItem={handleDeleteItem}
            ordenationItems={ordenationItems}
            isBlockedDeleteColumn={columns.length === 1}
          />
        );
      })}

      <div className="w-fit">
        <TooltipHandler
          renderTooltip={!allowBigChanges}
          tooltipMessage={tVersioningRules('cantModify')}
        >
          <MainButton
            dataTestId="addColumnButton"
            disabled={!allowBigChanges}
            icon={<PlusIcon />}
            color="secondary"
            text={t('newColumn')}
            onClick={handleAddColumn}
          />
        </TooltipHandler>
      </div>
    </div>
  );
}
