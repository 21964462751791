import requester from '@/axios';
import DNDPositionalActivityProgress from '@/models/DNDPositionalActivityProgress';

export type GetDndPositionActivityProgressParams = {
  questionId: number;
  progressId: number;
};

export async function getDndPositionalActivityProgress({
  questionId,
  progressId,
}: GetDndPositionActivityProgressParams): Promise<DNDPositionalActivityProgress> {
  const { data } = await requester().get<DNDPositionalActivityProgress>(
    `/dnd-positional-activity/${questionId}/dnd-positional-activity-progress/${progressId}/`,
  );

  return data;
}
