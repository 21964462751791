import requester from '@/axios';
import JsonApiResponse from '@/models/JsonApiResponse';
import Rewards, { CategoryEnum, RewardEnum, Reward } from '@/models/Rewards';
import { Transaction } from '@/models/Transaction';

export const getRewardsByStudentId = async (studentId: number) => {
  const { data } = await requester().get<Rewards>(
    `students/rewards/${studentId}/`,
  );

  return data;
};

export const createTransaction = async (transaction: Transaction) => {
  const { data } = await requester().post<Transaction>(
    `/students/rewards/transactions/`,
    transaction,
  );

  return data;
};

export const getRewardsByCategory = async (category: CategoryEnum) => {
  const { data } = await requester().get<Reward>(
    `students/rewards/coin-values/${category}/`,
  );

  return data;
};

export async function listRewardTypeValues(reward: RewardEnum) {
  const { data } = await requester().get<JsonApiResponse<Reward>>(
    `students/rewards/${reward.toLocaleLowerCase().replace('(s)', '')}-values/`,
  );

  return data;
}
