import React from 'react';
import Unit from '@/models/Unit';
import DataUnitsTable from './DataUnitsTable';
import SearchAndFilterButtons from '../../common/SearchAndFilterButtons';
import { UnitHeader } from '@/utils/HeaderTypes';
import Header from '../../common/table/Header';
import { RefreshIcon } from '@heroicons/react/solid';
import { LoadingIcon } from '../../icons';
import Text from '../../common/dataDisplay/Text';
import { useTranslation } from 'react-i18next';
import { debounce } from 'lodash';
import moment from 'moment';
import ConditionalRenderer from '../../common/ConditionalRenderer';
import useFilterParams from '@/utils/UseFilterParams';
import { ListUnitsFilters } from '@/data/services/unitServices';
import { unitsStatisticsQueryKeys } from '@/data/services/querykeys';
import useStatisticService from '@/data/hook/useStatistics';
interface ListUnitsProps {
  units: Unit[];
  titles: UnitHeader;
  isSearch: boolean;
  updateUnitsStatistics(): Promise<void>;
  isLoading: boolean;
}

export default function ListUnits({
  units,
  titles,
  isSearch,
  updateUnitsStatistics,
  isLoading,
}: ListUnitsProps) {
  const { statistic, isInitialLoading: isLoadingStatistics } =
    useStatisticService(unitsStatisticsQueryKeys.list());
  const lastUpdate = moment(statistic?.updatedAt).format('DD/MM/yyyy HH:mm');

  const { t } = useTranslation('translation', {
    keyPrefix: 'adminPage.listUnits',
  });

  const { filterParams, setFilterParams, deleteFilterParams } =
    useFilterParams<ListUnitsFilters>({});

  const requestSearch = (value: string) => {
    if (!value) {
      deleteFilterParams('search');
    } else {
      setFilterParams({ search: value });
    }

    setFilterParams({ pageNumber: 1 });
  };

  function clearSearch() {
    deleteFilterParams('search');
    setFilterParams({ pageNumber: 1 });
  }

  function clearFilters() {
    deleteFilterParams(['search']);
    setFilterParams({ pageNumber: 1 });
  }

  return (
    <React.Fragment>
      <div className="flex justify-between items-center flex-wrap gap-4">
        <div className="flex justify-between items-center w-5/8">
          <SearchAndFilterButtons
            searchInitial={filterParams.get('search') || ''}
            onChange={debounce(e => requestSearch(e.target.value), 500)}
            isSearch={isSearch}
            clearFiltersButton={clearFilters}
            clearSearch={clearSearch}
          />
        </div>

        <div className="flex justify-between gap-x-2.5 items-center">
          <ConditionalRenderer
            condition={!isLoadingStatistics}
            fallback={
              <LoadingIcon className="w-6 h-6 text-primary cursor-pointer" />
            }
          >
            <RefreshIcon
              onClick={updateUnitsStatistics}
              className="w-6 h-6 text-primary cursor-pointer"
            />
          </ConditionalRenderer>

          <Text
            className="text-primary"
            text={`${t('lastUpdate')} ${lastUpdate}`}
          />
        </div>
      </div>
      <div>
        <table className="w-full flex flex-col gap-y-2.5">
          <Header headers={Object.values(titles)} />
          <ConditionalRenderer condition={!isLoading}>
            <DataUnitsTable titles={titles} units={units} />
          </ConditionalRenderer>
        </table>
        <ConditionalRenderer condition={!units.length && !isLoading}>
          <Text
            text={t('searchNotFound')}
            className="flex justify-center mt-5"
          />
        </ConditionalRenderer>
        <ConditionalRenderer condition={isLoading}>
          <div className="flex w-full h-[518px] justify-center items-center p-3.5">
            <LoadingIcon className="text-primary/40 w-10 h-10" />
          </div>
        </ConditionalRenderer>
      </div>
    </React.Fragment>
  );
}
