export type Notification = {
  id: number;
  hasRead: boolean;
  userId: number;
  createdAt: string;
  type: NotificationTypeEnum | null;
};

export enum NotificationTypeEnum {
  ABSENCE = 'ABSENCE',
}
