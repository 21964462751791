import { XIcon } from '@heroicons/react/outline';
import ConditionalRenderer from '../../ConditionalRenderer';

type TabsTriggerIconListProps = {
  statusIcon?: React.ReactNode;
  onClose?: () => void;
};

const TabsTriggerIconList = ({
  statusIcon,
  onClose,
}: TabsTriggerIconListProps) => {
  return (
    <div className="flex gap-2">
      <ConditionalRenderer condition={statusIcon}>
        {statusIcon}
      </ConditionalRenderer>
      <ConditionalRenderer condition={onClose}>
        <button>
          <XIcon
            className="relative right-0 w-4 text-base-content"
            onClick={onClose}
          />
        </button>
      </ConditionalRenderer>
    </div>
  );
};

export default TabsTriggerIconList;
