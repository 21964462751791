import {
  DocumentTextIcon,
  InformationCircleIcon,
  LockClosedIcon,
} from '@heroicons/react/outline';
import { motion } from 'framer-motion';
import moment from 'moment';
import { useTranslation } from 'react-i18next';

import useAnimationOnView from '@/data/hook/useMotionViewAnimation';
import { handleUserFullName } from '@/functions/handleUserFullName';
import Enrollment, { EnrollmentStatusEnum } from '@/models/Enrollment';
import { OptionItem } from '@/models/OptionItem';
import { EnrollmentOptionType } from '@/models/OptionType';
import User from '@/models/User';
import { buildMotionLIProps } from '@/utils/animations/lessonCardAnimation';
import { ROUTES } from '@/utils/routes';
import IconButton from '../common/buttons/IconButton';
import ConditionalRenderer from '../common/ConditionalRenderer';
import Tag from '../common/dataDisplay/Tag';
import Text from '../common/dataDisplay/Text';
import TextWithCopy from '../common/dataDisplay/TextWithCopy';
import { Tooltip } from '../common/dataDisplay/Tooltip';
import { CoinIcon } from '../icons';
import { TeachingWeekdayProps } from './TeachingWeek/TeachingWeekday';
import { useEnrollmentStatusTranslation } from '@/data/hook/useEnrollmentStatusTranslation';

type EnrollmentViewProps = Partial<TeachingWeekdayProps> & {
  enrollment: Enrollment;
  index?: number;
};

export default function EnrollmentView({
  enrollment,
  index = 0,
  onChangeModal,
}: EnrollmentViewProps) {
  const { t } = useTranslation('translation', {
    keyPrefix: 'common',
  });

  const { student, status } = enrollment;

  const email = student.email ?? '';
  const lastLogin = student.lastLogin
    ? moment(student.lastLogin).format('DD MMM YYYY')
    : t('never');

  const { variants, custom } = buildMotionLIProps(index, 'right', 0.1);

  const motionLiProps = useAnimationOnView({
    variants,
    custom,
  });

  return (
    <motion.li
      {...motionLiProps}
      data-testid="enrollmentItem"
      className="grid grid-cols-2 md:grid-cols-4 gap-4 w-full items-center px-5 py-4 border border-primary/40 rounded-lg"
    >
      <TextWithCopy
        truncate
        href={ROUTES.ADMIN.STUDENTS.INFO({
          search: handleUserFullName(student),
        })}
        className={{
          text: 'break-all truncate text-base-content text-left',
        }}
        format="rubik-400"
        label={handleUserFullName(student)}
      />

      <EnrollmentTag status={status} />

      <TextWithCopy
        className={{
          base: 'order-2 xl:order-none p-0 m-0',
          text: 'break-all truncate text-base-content text-left',
        }}
        format="rubik-400"
        label={email}
      />

      <div className="flex gap-2 items-center order-3 xl:order-none">
        <Text text={lastLogin} className="capitalize text-base-content" />
        <Tooltip text={t('lastLogin')}>
          <InformationCircleIcon className="text-primary w-4 h-4" />
        </Tooltip>
      </div>
      <Actions student={student} onChangeModal={onChangeModal} />
    </motion.li>
  );
}

type ActionsProps = Partial<EnrollmentViewProps> & {
  student: User;
};

function Actions({ student, onChangeModal }: ActionsProps) {
  const { t } = useTranslation('translation', {
    keyPrefix: 'common',
  });

  const query = {
    search: handleUserFullName(student),
  };

  const buttons: {
    [key in EnrollmentOptionType]: OptionItem;
  } = {
    info: {
      id: 'info',
      text: t('info'),
      to: ROUTES.ADMIN.STUDENTS.INFO(query),
      icon: <InformationCircleIcon className="text-primary w-6 h-6" />,
    },
    alterPassword: {
      id: 'alterPassword',
      text: t('alterPassword'),
      onClick: () => onChangeModal?.('alterPassword', student),
      icon: <LockClosedIcon className="text-primary w-6 h-6" />,
    },
    rewards: {
      id: 'rewards',
      text: t('rewards'),
      onClick: () => onChangeModal?.('rewards', student),
      icon: <CoinIcon className="text-primary w-6 h-6" />,
    },
    sheet: {
      id: 'sheet',
      text: t('sheet'),
      to: ROUTES.ADMIN.STUDENTS.SHEET(query),
      icon: <DocumentTextIcon className="text-primary w-6 h-6" />,
    },
  };

  return (
    <ul className="flex gap-2 items-center justify-self-end order-4">
      {Object.values(buttons).map(button => (
        <li key={button.id}>
          <Tooltip text={t(button.id)}>
            <IconButton
              testId={button.id}
              icon={button.icon}
              href={button.to}
              onClick={button.onClick}
            />
          </Tooltip>
        </li>
      ))}
    </ul>
  );
}

type EnrollmentTagProps = {
  status: EnrollmentStatusEnum;
};

function EnrollmentTag({ status }: EnrollmentTagProps) {
  const transaledStatus = useEnrollmentStatusTranslation(status);

  const enrollmentStatus = [
    EnrollmentStatusEnum.AWAITING_PAYMENT,
    EnrollmentStatusEnum.CONCLUDED,
  ];

  return (
    <ConditionalRenderer condition={enrollmentStatus.includes(status)}>
      <Tag
        color="warning"
        className="py-1 px-2 rounded-full text-12 flex text-center"
        text={transaledStatus}
      />
    </ConditionalRenderer>
  );
}
