import { useEffect, FC } from 'react';

import useConfigContext from '@/data/hook/config';
import { initCanny } from '@/utils/canny';

type FeedbackProps = {
  path: String;
};

const Feedback: FC<FeedbackProps> = ({ path }) => {
  const { userSettings } = useConfigContext();

  useEffect(
    () => initCanny(path, userSettings?.theme ?? 'light'),
    [userSettings?.theme, path],
  );

  return <div data-canny />;
};

export default Feedback;
