import {
  AcademicCapIcon,
  DesktopComputerIcon,
  QuestionMarkCircleIcon,
  UserGroupIcon,
} from '@heroicons/react/outline';
import Text from '../common/dataDisplay/Text';
import {
  AnimationIcon,
  AppsIcon,
  Color3DIcon,
  GamesIcon,
  ProgrammingIcon,
  RoboticsIcon,
} from '../icons';

const gridOptions = [
  {
    icon: <ProgrammingIcon />,
    text: 'Programação',
    className: 'text-primary bg-primary-content border-primary/40',
  },
  {
    icon: <RoboticsIcon />,
    text: 'Robótica',
    className: 'text-warning bg-warning-content border-warning',
  },
  {
    icon: <GamesIcon />,
    text: 'Criação de Jogos',
    className: 'text-secondary bg-secondary-content border-secondary/40',
  },
  {
    icon: <Color3DIcon />,
    text: 'Modelagem 3D',
    className: 'text-success bg-success-content border-success',
  },
  {
    icon: <AppsIcon />,
    text: 'Aplicativos',
    className: 'text-error bg-error-content border-error',
  },
  {
    icon: <AnimationIcon />,
    text: 'Animação',
    className: 'text-fuchsia-600 bg-fuchsia-100 border-fuchsia-300',
  },
];

const columnOptions = [
  {
    icon: <DesktopComputerIcon strokeWidth={1.3} />,
    text: 'Atividades de fixação lúdicas e interativas no Portal do Aluno',
  },
  {
    icon: <AcademicCapIcon strokeWidth={1.3} />,
    text: 'Projetos de conclusão de curso por módulo, com tutoria de um professor',
  },
  {
    icon: <UserGroupIcon strokeWidth={1.5} />,
    text: 'Turmas reduzidas que garantem atenção a cada aluno, mas mantendo socialização',
  },
  {
    icon: <QuestionMarkCircleIcon strokeWidth={1.7} />,
    text: 'Plantões de dúvidas',
  },
];

export default function AboutCurriculum() {
  return (
    <div className="flex flex-col md:flex-row gap-4 md:gap-8 h-fit">
      <div className="flex flex-col gap-3">
        <div className="flex flex-col gap-2">
          <Text
            format="poppins-600"
            className="text-primary text-20"
            text="Currículo Multidisciplinar"
          />
          <Text text="Nossos alunos se tornam competentes em diversas áreas da tecnologia ao invés de apenas uma" />
        </div>
        <div className="grid grid-cols-2 gap-3">
          {gridOptions.map(option => {
            return (
              <div
                className={`flex gap-4 px-4 w-full text-14 sm:text-16 py-3 border ${option.className} rounded-lg shrink-0 items-center`}
              >
                <div className="w-6 h-6 shrink-0 grow-0 stroke-1">
                  {option.icon}
                </div>
                <Text text={option.text} />
              </div>
            );
          })}
        </div>
      </div>
      <div className="grid sm:grid-cols-2 gap-2 md:flex md:flex-col h-auto md:justify-between">
        {columnOptions.map(option => {
          return (
            <div className="flex gap-5 items-center ">
              <div className="flex items-center w-8 h-8 sm:w-12 sm:h-12 shrink-0 grow-0 p-2 sm:p-3 justify-center rounded-full bg-accent/40 text-accent">
                {option.icon}
              </div>
              <Text
                format="rubik-500"
                className="text-accent"
                text={option.text}
              />
            </div>
          );
        })}
      </div>
    </div>
  );
}
