import { SaveIcon } from '@heroicons/react/outline';
import { isEqual } from 'lodash';
import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useForm, Controller } from 'react-hook-form';
import { useMutation } from '@tanstack/react-query';

import Activity from '@/models/Activity';
import {
  VideoActivityElement,
  VideoPlayerEnum,
} from '@/models/ActivityElement';
import MainButton from '@/components/common/buttons/MainButton';
import alert from '@/utils/UseAlert';
import ContainerActivityEdit from './commom/ContainerActivityEdit';
import { buildChangedObject } from '@/utils/buildChangedObject';
import {
  createVideoActivity,
  updateVideoActivity,
  UpdateVideoActivity,
} from '@/data/services/activityElement/videoActivityService';
import { getErrorMessage } from '@/utils/getErrorMessage';
import TextInput from '@/components/common/dataInput/TextInput';
import EnumInput from '@/components/common/dataInput/EnumInput';

export type VideoElementEditProps = Omit<VideoActivityElement, 'activity'> & {
  activity: Activity;
  onEdit: (props: any) => void;
  onSave: (props: any) => void;
};

export default function VideoElementEdit(props: VideoElementEditProps) {
  const { t: tVideoActivity } = useTranslation('translation', {
    keyPrefix: 'activity.manageActivity.activityVideo',
  });

  const [t] = useTranslation('translation', {
    keyPrefix: 'common',
  });

  const {
    onEdit,
    changeStatus,
    activity,
    type,
    identifier,
    order,
    onSave,
    title,
    subtitle,
    videoPlayer,
    url,
  } = props;

  const [hasChanges, setHasChanges] = useState(false);

  type VideoActivityFields = Pick<
    VideoActivityElement,
    'title' | 'subtitle' | 'videoPlayer' | 'url'
  >;

  const defaultValues = useRef<VideoActivityFields>({
    title,
    subtitle,
    videoPlayer,
    url,
  });

  const {
    watch,
    handleSubmit,
    register,
    control,
    formState: { errors },
  } = useForm<VideoActivityFields>({ defaultValues: defaultValues.current });

  useEffect(() => {
    const subscribe = watch(value =>
      setHasChanges(!isEqual(value, defaultValues.current)),
    );
    return () => {
      subscribe.unsubscribe();
    };
  }, [defaultValues, watch]);

  const { mutate: create, isLoading: loadingCreate } = useMutation(
    createVideoActivity,
    {
      async onSuccess(data) {
        onSave({ ...props, id: data.id });
        setHasChanges(false);
        alert.success(t('saved'));
      },
      onError(error) {
        alert.error(getErrorMessage(error));
      },
    },
  );

  const { mutate: update, isLoading: loadingUpdate } = useMutation(
    (params: UpdateVideoActivity) =>
      updateVideoActivity(params.videoActivityId, params.changes),
    {
      onSuccess: () => {
        onSave({ ...props });
        setHasChanges(false);
        alert.success(t('saved'));
      },
      onError: error => {
        alert.error(getErrorMessage(error));
      },
    },
  );

  const onSubmit = async (changes: Partial<VideoActivityFields>) => {
    const changedFields = buildChangedObject(defaultValues.current, changes);

    if (Object.values(changedFields).length) {
      if (changeStatus === 'new')
        create({
          ...changedFields,
          activity: activity.id,
          type,
          identifier,
          order,
        });
      else update({ videoActivityId: props.id, changes: changedFields });
    }
  };

  const loading = loadingCreate || loadingUpdate;

  return (
    <ContainerActivityEdit className="astro-select" id="VideoElementEdit">
      <form className="flex flex-col gap-2" onSubmit={handleSubmit(onSubmit)}>
        <TextInput
          testId="videoActivityTitle"
          {...register('title', {
            onChange({ target }) {
              onEdit({ ...props, title: target.value });
            },
            required: tVideoActivity('titleError'),
          })}
          label={tVideoActivity('title')}
          errorLabelText={errors.title?.message}
          className={{ base: 'text-16' }}
          color={errors.title?.message ? 'warning' : 'primary'}
        />

        <TextInput
          testId="videoActivitySubtitle"
          {...register('subtitle', {
            onChange({ target }) {
              onEdit({ ...props, subtitle: target.value });
            },
            required: tVideoActivity('subtitleError'),
          })}
          label={tVideoActivity('subtitle')}
          errorLabelText={errors?.subtitle?.message}
          className={{ base: 'text-16' }}
          color={errors.subtitle?.message ? 'warning' : 'primary'}
        />

        <Controller
          rules={{ required: tVideoActivity('videoPlayerError') }}
          name="videoPlayer"
          control={control}
          render={({ field: { value, onChange }, fieldState: { error } }) => (
            <EnumInput
              testId="videoActivityPlayer"
              errorLabelText={error?.message}
              label={tVideoActivity('videoPlayer')}
              enumModel={VideoPlayerEnum}
              displayName={option => option}
              onSelect={option => onChange(option)}
              type="string"
              onDeselect={() => onChange(null)}
              selected={value}
              className={{ base: 'text-16' }}
            />
          )}
        />

        <TextInput
          testId="videoActivityUrl"
          {...register('url', {
            onChange({ target }) {
              onEdit({ ...props, url: target.value });
            },
            required: tVideoActivity('videoIDError'),
          })}
          label={tVideoActivity('videoID')}
          errorLabelText={errors.url?.message}
          className={{ base: 'text-16' }}
          color={errors.subtitle?.message ? 'warning' : 'primary'}
        />

        <p className="text-14">
          {tVideoActivity('exampleMessage1')}
          <span className="font-700 underline">'3LXuQyz1elk'</span>
          {tVideoActivity('exampleMessage2')}
          <span className="font-700 ">
            https://www.youtube.com/watch?v=3LXuQyz1elk
          </span>
        </p>

        <MainButton
          dataTestId="videoActivitySave"
          type="submit"
          disabled={!hasChanges || loading}
          loading={loadingUpdate}
          icon={<SaveIcon />}
          id="video-activity-save"
          text={tVideoActivity('save')}
        />
      </form>
    </ContainerActivityEdit>
  );
}
