import { useTranslation } from 'react-i18next';
import ProjectCard from './ProjectCard';
import Text from '@/components/common/dataDisplay/Text';
import { ProjectFilters } from '@/data/services/projectServices';
import { REQUEST_STALE_TIME_IN_MS } from '@/constants';
import ConditionalRenderer from '@/components/common/ConditionalRenderer';
import { LoadingIcon } from '@/components/icons';
import Carousel from '@/components/common/Carousel';
import { getErrorMessage } from '@/utils/getErrorMessage';
import useInfiniteService from '@/data/hook/useInfiniteService';
import { projectsQuerykeys } from '@/data/services/querykeys';

type ProjectCarouselProps = {
  title?: string;
  filters?: ProjectFilters;
};

export default function ProjectCarousel({
  title,
  filters,
}: ProjectCarouselProps) {
  const { t } = useTranslation('translation', { keyPrefix: 'projectPage' });

  const {
    results: projects,
    isInitialLoading: loading,
    isFetchingNextPage: fetchingNextPage,
    hasNextPage,
    fetchNextPage,
    error: projectsError,
  } = useInfiniteService({
    ...projectsQuerykeys.list(filters)._ctx.infinity,
    staleTime: REQUEST_STALE_TIME_IN_MS,
  });

  const projectsErrorDetail = getErrorMessage(projectsError);

  const divClassName = 'flex w-full h-[256px] justify-center items-center';

  return (
    <div className="flex flex-col">
      <ConditionalRenderer condition={title}>
        <Text format="rubik-500" text={title} className="text-accent" />
      </ConditionalRenderer>

      <Carousel
        hasNextPage={hasNextPage}
        fetchingNextPage={fetchingNextPage}
        onReachEnd={fetchNextPage}
        hiddenButtons={!projects.length}
      >
        <ConditionalRenderer condition={loading}>
          <div className={divClassName}>
            <LoadingIcon className="w-20 text-primary-content" />
          </div>
        </ConditionalRenderer>

        <ConditionalRenderer condition={projectsErrorDetail}>
          <div className={divClassName}>
            <Text
              format="rubik-500"
              text={projectsErrorDetail}
              className="text-accent"
            />
          </div>
        </ConditionalRenderer>
        <ConditionalRenderer condition={projects.length}>
          {projects.map(project => (
            <ProjectCard key={project.id} project={project} />
          ))}
        </ConditionalRenderer>
        <ConditionalRenderer
          condition={!loading && !projectsErrorDetail && !projects.length}
        >
          <div className="flex w-full h-[256px] justify-center items-center">
            <Text text={t('noProjectsFound')} />
          </div>
        </ConditionalRenderer>
      </Carousel>
    </div>
  );
}
