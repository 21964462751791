import { formatLessonNamePrefix } from '@/functions/lessonsName';
import { ICoursePath } from '@/models/Course';
import Klass from '@/models/Klass';
import { ScheduledLessonTypeEnum } from '@/models/ScheduledLesson';
import { translateKlassName } from './translateKlassName';

interface MakeLessonHeadTitleParams {
  lessonOrder: number;
  lessonType?: ScheduledLessonTypeEnum;
  coursePath?: ICoursePath;
  klass?: Klass;
}

export const makeLessonHeadTitle = ({
  lessonOrder,
  coursePath,
  klass,
  lessonType,
}: MakeLessonHeadTitleParams) => {
  const lessonAbbreviation = formatLessonNamePrefix({
    lessonOrder,
    scheduledLessonType: lessonType,
  });
  const courseAbbreviation = coursePath?.course.abbreviation ?? '';
  const klassName = translateKlassName(klass?.name);
  return `${lessonAbbreviation} - ${klassName || courseAbbreviation}`;
};
