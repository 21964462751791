import { ChallengeEditing } from '@/components/admin/lessons/ChallengeEditing';
import ListActivities from '@/components/admin/lessons/ListActivities';
import MaterialContainer from '@/components/admin/lessons/material/MaterialContainer';
import ComponentGuard from '@/components/common/ComponentGuard';
import MainButton from '@/components/common/buttons/MainButton';
import {
  ColorTypes,
  Rating,
  RatingTypes,
} from '@/components/common/dataDisplay/Rating';

import ConditionalRenderer from '@/components/common/ConditionalRenderer';
import Tabs, {
  Pane,
  serializerTabsContents,
} from '@/components/common/dataDisplay/Tabs/Tabs';
import Text from '@/components/common/dataDisplay/Text';
import { useBankFilters } from '@/data/context/BankFiltersContext';
import useAuth from '@/data/hook/useAuth';
import {
  createTeacherLessonRating,
  updateTeacherLessonRating,
} from '@/data/services/lessonRatingServices';
import { updateLessonInBank } from '@/data/services/lessonServices';
import { isSuperAdmin } from '@/functions/auth';
import { Lesson, LessonTab } from '@/models/Lesson';
import { UserTypeEnum } from '@/models/User';
import {
  BookOpenIcon,
  ClipboardListIcon,
  InformationCircleIcon,
  LightningBoltIcon,
  SaveIcon,
} from '@heroicons/react/outline';
import { compact } from 'lodash';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useItems } from '../ListBankCards.hooks';
import { ResumeField } from '../ResumeField';
import { ExpansibleCardProps } from './ExpansibleCardProps';
import useInfiniteService from '@/data/hook/useInfiniteService';
import { teacherLessonRatingssQuerykeys } from '@/data/services/querykeys';

export const ExpansibleLessonCard = ({
  item: lesson,
  used,
  onUse,
}: ExpansibleCardProps<Lesson>) => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'modalBank.useButton',
  });
  const [activeTab, setActiveTab] = useState<LessonTab>('details');

  const panes = useMountPanes(lesson);

  return (
    <div className="mt-4 flex flex-col gap-4">
      <Tabs
        active={activeTab}
        contents={serializerTabsContents(panes)}
        onChange={setActiveTab}
      >
        <Tabs.Container>
          <Tabs.List>
            {panes.map(pane => (
              <Tabs.Trigger id={pane.key} key={pane.key}>
                <pane.icon className="w-6 h-6 mr-2" />
                {pane.text}
              </Tabs.Trigger>
            ))}
          </Tabs.List>
          <Tabs.ShowContent />
        </Tabs.Container>
      </Tabs>
      <div className="flex justify-start gap-2 items-center">
        <ConditionalRenderer condition={!!onUse && !used}>
          <MainButton
            text={t('lessons')}
            onClick={() => onUse?.(lesson)}
            icon={<SaveIcon className="w-4" />}
          />
        </ConditionalRenderer>
        <ConditionalRenderer condition={used}>
          <TeacherRating lesson={lesson} />
        </ConditionalRenderer>
        <LessonPublicChange lesson={lesson} />
      </div>
    </div>
  );
};

type TeacherRatingProps = {
  lesson: Lesson;
};

export const TeacherRating = ({
  lesson: { id: lessonId },
}: TeacherRatingProps) => {
  const { user } = useAuth();
  const { filters } = useBankFilters();
  const { invalidate: lessonQueryInvalidate } = useItems('lessons', filters, {
    enabled: false,
  });

  const { results: lessonRating, invalidate: teacherRatingInvalidate } =
    useInfiniteService({
      ...teacherLessonRatingssQuerykeys.list({ lessonId, userId: user?.id })
        ._ctx.infinity,
    });

  const teacherRating = lessonRating?.[0];

  const onRating = async (rating: number) => {
    if (!teacherRating) {
      await createTeacherLessonRating({ lessonId, rating });
    } else {
      await updateTeacherLessonRating(teacherRating?.id, { rating });
    }
    const teacherInvalidate = teacherRatingInvalidate();
    const lessonInvalidate = lessonQueryInvalidate();
    await Promise.all([teacherInvalidate, lessonInvalidate]);
  };
  return (
    <ComponentGuard roles={[UserTypeEnum.TEACHER]}>
      <Rating
        type={RatingTypes.STAR}
        length={5}
        onClickRating={onRating}
        value={teacherRating?.rating || 0}
        color={ColorTypes.YELLOW}
      />
    </ComponentGuard>
  );
};

type PublicChangeProps = {
  lesson: Lesson;
};
const LessonPublicChange = ({ lesson }: PublicChangeProps) => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'lessonForm',
  });
  const { user } = useAuth();
  const { filters } = useBankFilters();
  const { invalidate: lessonQueryInvalidate } = useItems('lessons', filters, {
    enabled: false,
  });

  const isSuper = isSuperAdmin(user?.userType);

  const onSetPublic = async (isPublicInBank: boolean) => {
    await updateLessonInBank({
      lessonId: lesson.id,
      bank: { isPublicInBank, inBank: lesson.inBank },
    });
    await lessonQueryInvalidate();
  };

  return (
    <ConditionalRenderer condition={isSuper && lesson.inBank}>
      <div className="flex gap-2 items-center justify-start">
        <input
          type="checkbox"
          className="toggle toggle-sm toggle-primary"
          checked={lesson.isPublicInBank}
          onChange={e => onSetPublic(e.target.checked)}
        />
        <Text text={t('isPublicInBank')} className="flex" />
      </div>
    </ConditionalRenderer>
  );
};

const useMountPanes = (lesson: Lesson) => {
  let panes: Pane<LessonTab>[] = [];
  const { t } = useTranslation('translation', { keyPrefix: 'klass.lessons' });

  panes.push({
    key: 'details',
    text: t('details'),
    icon: InformationCircleIcon,
    content: <ResumeLesson lesson={lesson} />,
  });
  if (lesson.book)
    panes.push({
      key: 's-material',
      text: t('studentBook'),
      icon: BookOpenIcon,
      content: (
        <MaterialContainer lesson={lesson} bookType="STUDENT" readOnly />
      ),
    });

  if (lesson.teacherBook) {
    panes.push({
      key: 't-material',
      text: t('teacherBook'),
      icon: BookOpenIcon,
      content: (
        <MaterialContainer lesson={lesson} bookType="TEACHER" readOnly />
      ),
    });
  }
  if (lesson.activities.length) {
    panes.push({
      key: 'activities',
      text: t('activity'),
      icon: ClipboardListIcon,
      content: <ListActivities lesson={lesson} readOnly />,
    });
  }

  if (lesson.challenge) {
    panes.push({
      key: 'challenge',
      text: t('challenge'),
      icon: LightningBoltIcon,
      content: <ChallengeEditing lesson={lesson} readOnly />,
    });
  }
  return panes;
};

function ResumeLesson({ lesson }: { lesson: Lesson }) {
  const { t } = useTranslation('translation', {
    keyPrefix: 'modalBank.expansibleContent.lesson',
  });
  const links = [lesson.toolUrl, lesson.instructionsUrl];
  const linksNotNullable = compact(links);
  return (
    <div className="flex flex-col gap-4">
      <ResumeField label={t('description')} value={lesson.description} />
      <ConditionalRenderer condition={linksNotNullable.length}>
        <ResumeField label={t('links')} value={linksNotNullable} />
      </ConditionalRenderer>
    </div>
  );
}
