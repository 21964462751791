import { CheckCircleIcon } from '@heroicons/react/solid';
import { useTranslation } from 'react-i18next';
import Text from '../common/dataDisplay/Text';
import Modal from '../common/modals/Modal';

export default function ResetPasswordModal({ onClose }: { onClose(): void }) {
  const { t } = useTranslation('translation', {
    keyPrefix: 'login.resetPassword.modal',
  });
  return (
    <Modal closeButtonTestId="closeButton" onClose={onClose} visible>
      <div
        data-testid="successModal"
        className="flex flex-col items-center gap-4"
      >
        <Text
          format="poppins-600"
          size="text-20"
          className="text-primary"
          text={t('success')}
        />
        <CheckCircleIcon className="w-20 h-w-20 text-success" />
        <Text text={t('canLogin')} className="text-center" />
      </div>
    </Modal>
  );
}
