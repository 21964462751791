import { ReactNode } from 'react';
import { XIcon } from '@heroicons/react/outline';
import ConditionalRenderer from '@/components/common/ConditionalRenderer';
import IconButton from '@/components/common/buttons/IconButton';

type LessonViewContainerProps = {
  children: ReactNode;
  close?(): void;
  className?: string;
};

function LessonViewContainer({
  close,
  children,
  className,
}: LessonViewContainerProps) {
  return (
    <div className="flex flex-col gap-8 relative">
      <ConditionalRenderer condition={close}>
        <IconButton
          className="absolute -right-2.5 -top-2.5 z-20"
          testId="closeButton"
          id="close-button"
          onClick={close}
          icon={<XIcon className="text-secondary w-7" />}
        />
      </ConditionalRenderer>
      <div className={className}>{children}</div>
    </div>
  );
}

export default LessonViewContainer;
