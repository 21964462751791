import { useTabsPanelContext } from './Tabs';

type TabsVerticalProps = {
  children: React.ReactNode;
};
const TabsContainer = ({ children }: TabsVerticalProps) => {
  const { orientation } = useTabsPanelContext();

  return (
    <div
      className={`relative flex h-fit w-full ${
        orientation === 'vertical' ? 'flex-row mb-14' : 'flex-col'
      }`}
    >
      {children}
    </div>
  );
};

export default TabsContainer;
