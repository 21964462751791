import { Tooltip } from '@/components/common/dataDisplay/Tooltip';
import { KlassStatusEnum } from '@/models/Klass';
import {
  CheckCircleIcon,
  DotsCircleHorizontalIcon,
  XCircleIcon,
} from '@heroicons/react/solid';
import { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';

export default function KlassStatusIcon({
  status,
}: {
  status: KlassStatusEnum;
}) {
  const { t: tStatus } = useTranslation('translation', {
    keyPrefix: 'manageKlasses.status',
  });

  const icons: Record<KlassStatusEnum, ReactElement> = {
    [KlassStatusEnum.OPEN]: (
      <DotsCircleHorizontalIcon className="w-5 h-5 text-purple-500" />
    ),
    [KlassStatusEnum.IN_PROGRESS]: (
      <CheckCircleIcon className="w-5 h-5 text-primary" />
    ),
    [KlassStatusEnum.CONCLUDED]: (
      <CheckCircleIcon className="w-5 h-5 text-success" />
    ),
    [KlassStatusEnum.CANCELED]: (
      <XCircleIcon className="w-5 h-5 text-red-500" />
    ),
  };

  return <Tooltip text={tStatus(status)}>{icons[status]}</Tooltip>;
}
