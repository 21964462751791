import React, { useEffect, useState } from 'react';

interface ButtonTimeProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  isSelected: boolean;
  timeStart: string;
  timeEnd: string;
  delayAnimation: number;
}

export default function ButtonTime({
  isSelected,
  timeEnd,
  timeStart,
  delayAnimation,
  ...rest
}: ButtonTimeProps) {
  const [activedAnimation, setActivedAnimation] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => {
      setActivedAnimation(true);
    }, delayAnimation * 270);

    return () => {
      clearTimeout(timer);
      setActivedAnimation(false);
    };
  }, [delayAnimation]);

  return (
    <button
      {...rest}
      className={`px-6 py-2 rounded-md leading-none transition ${
        activedAnimation ? 'animate-[zoom_.6s_ease-in-out]' : 'hidden'
      } ${
        isSelected ? 'bg-accent' : 'bg-accent/40'
      } border-none font-poppins font-600 text-14 text-base-100 w-[47%] sm:w-[144px]`}
    >
      {`${timeStart} - ${timeEnd}`}
    </button>
  );
}
