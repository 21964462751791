import { useTranslation } from 'react-i18next';
import IconButton from '../buttons/IconButton';
import Text from '../dataDisplay/Text';
import { PlusIcon, MinusIcon } from '@heroicons/react/outline';
import { useState } from 'react';
import { UseFormRegisterReturn } from 'react-hook-form';
import { AnimatePresence, motion } from 'framer-motion';
import AreaInput from './TextAreaInput';
import { accordion } from '../../../utils/animations/commom';

interface AccordionTextareaProps {
  testId: string;
  isLoading?: boolean;
  register: UseFormRegisterReturn;
  studentName?: string;
  disabled?: boolean;
}

export const AccordionTextarea = ({
  testId,
  isLoading,
  register,
  studentName,
  disabled,
}: AccordionTextareaProps) => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'manageScheduledLessonReport',
  });

  const [isOpen, setIsOpen] = useState<boolean>(true);

  return (
    <div className="w-full relative">
      <div className="flex w-full justify-between my-2">
        <Text testId="addCommentsText" text={t('addComments')} />
        <IconButton
          disabled={isLoading}
          testId="addCommentsButton"
          icon={
            isOpen ? (
              <MinusIcon className="h-5 text-primary" />
            ) : (
              <PlusIcon className="h-5 text-primary" />
            )
          }
          onClick={() => {
            setIsOpen(old => !old);
          }}
          type="button"
        />
      </div>
      <AnimatePresence>
        {isOpen && (
          <motion.div {...accordion}>
            <AreaInput
              disabled={disabled}
              register={register}
              testId={testId}
              placeholder={t('placeholderLessonComment', {
                student: studentName,
              })}
            />
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
};
