import { useState } from 'react';
import Layout from '@/components/template/Layout';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { useNavigate, useParams } from 'react-router-dom';
import {
  deleteProject,
  deleteProjectUpvote,
  upvoteProject,
} from '@/data/services/projectServices';
import LoadingView from '../courses/LoadingView';
import {
  AstroBreadcrumb,
  breadcrumbArray,
} from '@/components/common/AstroBreadcrumb';
import EmbedPreview from '@/components/common/dataDisplay/EmbedPreview';
import { useTranslation } from 'react-i18next';
import { HtmlPreview } from '@/components/common/dataDisplay/HtmlPreview';
import Text from '@/components/common/dataDisplay/Text';
import InteractionsContainer from '@/components/projects/InteractionsContainer';
import {
  EyeIcon,
  HeartIcon,
  PencilIcon,
  TrashIcon,
} from '@heroicons/react/outline';
import { HeartIcon as SolidHeartIcon } from '@heroicons/react/solid';
import PageTitle from '@/components/common/PageTitle';
import MainButton from '@/components/common/buttons/MainButton';
import useAuth from '@/data/hook/useAuth';
import AvatarName from '@/components/common/dataDisplay/AvatarName';
import ConditionalRenderer from '@/components/common/ConditionalRenderer';
import ModalCreateProject from '@/components/projects/ModalCreateProject';
import IconButton from '@/components/common/buttons/IconButton';
import HeadTitle from '@/components/common/HeadTitle';
import ErrorComponent from '@/components/common/ErrorComponent';
import { UserTypeEnum } from '@/models/User';
import { isStaff } from '@/functions/auth';
import ModalDisable from '@/components/common/modals/ModalDisable';
import { ApiError } from '@/models/Errors';
import alert from '@/utils/UseAlert';
import { projectsQuerykeys } from '@/data/services/querykeys';

export default function ProjectView() {
  const { id: idParam } = useParams();
  const id = Number(idParam);
  const navigate = useNavigate();

  const { queryFn: projectQueryFn, queryKey: projectQueryKey } =
    projectsQuerykeys.get(id);
  const {
    data: project,
    isInitialLoading: isLoading,
    isError,
  } = useQuery({
    enabled: !isNaN(id),
    queryFn: projectQueryFn,
    queryKey: projectQueryKey,
  });
  const { t } = useTranslation('translation', {
    keyPrefix: 'projectView',
  });
  const { user } = useAuth();
  type OpenedModal = 'none' | 'edit' | 'delete';
  const [openedModal, setOpenedModal] = useState<OpenedModal>('none');
  const isEditModalOpen = openedModal === 'edit';
  const onUpvote = async () => {
    if (project?.upVoted) {
      await deleteProjectUpvote(project.id);
    } else if (project) {
      await upvoteProject(project.id);
    }
  };

  const projectsKey = projectsQuerykeys.list._def;

  const invalidade = async () =>
    await queryClient.invalidateQueries(projectsKey);

  const { mutate: addUpvote, isLoading: isUpvoting } = useMutation(onUpvote, {
    async onSuccess() {
      await queryClient.invalidateQueries(projectQueryKey);
      await invalidade();
    },
  });

  const { mutate: confirmDeleteProject, isLoading: isDeleting } = useMutation(
    deleteProject,
    {
      async onSuccess() {
        await invalidade();
        navigate('/projects');
      },
      onError(error: any) {
        navigate('/projects');
        const apiError = new ApiError(error as any);
        alert.error(apiError.getErrorMessage());
      },
    },
  );

  const isStudentProject = project?.user.userType === UserTypeEnum.STUDENT;
  const isUserOwnerOfProject = project?.user.id === user?.id;
  const shouldDisplayActions =
    isUserOwnerOfProject || (isStudentProject && isStaff(user?.userType));

  const closeModal = () => setOpenedModal('none');

  const queryClient = useQueryClient();

  if (isLoading) {
    return (
      <Layout>
        <LoadingView />
      </Layout>
    );
  } else if (isError || !project) {
    return (
      <Layout>
        <ErrorComponent
          errorTextTitle={t('projectNotFound')}
          errorTextSubTitle={t('checkInfo')}
        />
      </Layout>
    );
  }

  const breadcrumbItems: breadcrumbArray[] = [
    {
      name: 'Home',
      routeType: 'home',
      url: '/',
    },
    { name: t('projects'), routeType: 'projects', url: '/projects' },
    {
      name: project.title,
      routeType: 'projects',
      url: '',
    },
  ];

  return (
    <Layout>
      <HeadTitle routeInfo={`${project.title} - ${t('projects')}`} />
      <header className="flex flex-col gap-3">
        <AstroBreadcrumb breadcrumbList={breadcrumbItems} />
        <PageTitle position="mb-2" headingText={project.title} backButton />
      </header>
      <main className="grid grid-cols-1 w-full gap-5 md:grid-cols-4">
        <div className="flex col-start-1 col-end-4 flex-col gap-2 shrink-0 w-full">
          <EmbedPreview
            className="w-full aspect-video"
            src={project.projectUrl}
          />
          <div className="flex gap-3 justify-start items-center">
            <InteractionsContainer
              count={project.views}
              size="text-18"
              icon={({ className }) => <EyeIcon className={className} />}
            />

            <InteractionsContainer
              count={project.upVotes}
              size="text-18"
              upvoted={project.upVoted}
              icon={({ className }) => (
                <IconButton
                  onClick={() => {
                    addUpvote();
                  }}
                  disabled={isUpvoting}
                  testId="upvoteButton"
                  icon={
                    project.upVoted ? (
                      <SolidHeartIcon className={className} />
                    ) : (
                      <HeartIcon className={className} />
                    )
                  }
                />
              )}
            />
          </div>
        </div>
        <div className="flex flex-col w-full shrink gap-3">
          <div className="flex flex-col w-full shrink gap-1">
            <Text
              format="rubik-500"
              text={project.title}
              className="text-primary text-18"
            />
            <AvatarName user={project.user} />
          </div>
          <div className="flex flex-col w-full shrink gap-0">
            <Text
              format="rubik-500"
              className="my-0 text-primary text-18"
              text={t('description')}
            />
            <div
              className="h-full w-full text-wrap break-words flex shrink max-h-[280px] md:max-h-[350px] overflow-y-auto scrollbar-thumb-primary/40 scrollbar-track-primary-content scrollbar-thumb-rounded-full 
    scrollbar-track-rounded-full scrollbar-thin"
            >
              <HtmlPreview
                className="w-full text-wrap break-words"
                html={project.description}
              />
            </div>
          </div>
          <div className="flex w-full justify-end py-1">
            <ConditionalRenderer condition={isEditModalOpen}>
              <ModalCreateProject
                project={project}
                isVisible={isEditModalOpen}
                onClose={closeModal}
              />
            </ConditionalRenderer>
            <ModalDisable
              onClickCancel={closeModal}
              onClickConfirm={() => confirmDeleteProject(project.id)}
              translationString="deleteProject"
              modalType="delete"
              visible={openedModal === 'delete'}
              selectedObjectName={project.title}
              isRequesting={isDeleting}
            />

            <ConditionalRenderer condition={shouldDisplayActions}>
              <div className="flex flex-col gap-2 items-end">
                <ConditionalRenderer condition={isUserOwnerOfProject}>
                  <MainButton
                    onClick={() => setOpenedModal('edit')}
                    icon={<PencilIcon className="w-4 h-4" />}
                    color="primary"
                    text={t('editProject')}
                  />
                </ConditionalRenderer>
                <MainButton
                  onClick={() => setOpenedModal('delete')}
                  icon={<TrashIcon className="w-4 h-4" />}
                  color="warning"
                  text={t('deleteProject')}
                />
              </div>
            </ConditionalRenderer>
          </div>
        </div>
      </main>
    </Layout>
  );
}
