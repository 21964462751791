import { QueryCtx } from '@/components/common/dataInput/InfiniteSearchInput';
import { TIME_REFRESH_NOTIFICATIONS } from '@/constants';
import useInfiniteService from '@/data/hook/useInfiniteService';
import { ListAlertsFilters } from '@/data/services/alertServices';
import { alertQueryKeys } from '@/data/services/querykeys';
import { Alert, AlertTypeEnum } from '@/models/Alert';
import { Meta } from '@/models/JsonApiResponse';
import { differenceWith, isEqual } from 'lodash';
import { useEffect, useRef } from 'react';

type UseAlertServiceArgs = {
  onHasDifference: () => void;
  filters: ListAlertsFilters;
};
export const useAlertsService = ({
  onHasDifference,
  filters,
}: UseAlertServiceArgs) => {
  const fetchCount = useRef(0);

  const { queryFn, queryKey } = alertQueryKeys.list(filters)._ctx.infinity;

  const queryFnWrapper: QueryCtx<Alert>['infinity']['queryFn'] = e => {
    if (!e.pageParam) ++fetchCount.current;
    return queryFn(e);
  };

  const {
    results: alerts,
    meta,
    ...queryProps
  } = useInfiniteService({
    queryFn: queryFnWrapper,
    queryKey,
    refetchInterval: TIME_REFRESH_NOTIFICATIONS,
    refetchIntervalInBackground: true,
  });

  const alertRef = useRef<Alert[]>([]);
  const metaRef = useRef<Meta>();
  const filtersRef = useRef<ListAlertsFilters>();

  const firstPage = alerts.slice(0, 10);
  useEffect(() => {
    if (fetchCount.current === 1) {
      alertRef.current = firstPage;
      metaRef.current = meta;
      filtersRef.current = filters;
      return;
    }

    if (!firstPage.length) return;
    const alertsDiff = differenceWith(firstPage, alertRef.current, isEqual);

    const latelessDiffCount = alertsDiff.reduce(
      (acc, b) => (b.type === AlertTypeEnum.LATENESS ? acc + 1 : acc),
      0,
    );

    if (
      latelessDiffCount &&
      meta?.pagination.count !== metaRef.current?.pagination.count &&
      isEqual(filters, filtersRef.current)
    )
      onHasDifference();

    alertRef.current = firstPage;
    metaRef.current = meta;
    filtersRef.current = filters;
  }, [firstPage, meta, filters, onHasDifference]);

  return { alerts, ...queryProps };
};
