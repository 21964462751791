import { useTranslation } from 'react-i18next';
import ScheduledLesson from '@/models/ScheduledLesson';
import Text from '../common/dataDisplay/Text';
import Klass from '@/models/Klass';
import { formatScheduledLessonText } from '@/utils/formatScheduledLessonText';
import LateReportItem from './LateReportItem';
import CardListItem from '../common/dataDisplay/CardListItem';
import { Fragment } from 'react/jsx-runtime';

interface ReportsListProps {
  scheduledLessons: ScheduledLesson[];
  klasses: Klass[];
}

export default function ReportsList({
  scheduledLessons,
  klasses,
}: ReportsListProps) {
  const { t } = useTranslation('translation', {
    keyPrefix: 'teacherDashboard.teacherReports',
  });

  if (!scheduledLessons.length) {
    return (
      <div className="flex justify-center items-center h-full flex-col gap-y-2 text-accent font-500">
        <Text text={t('noReportsPhrase')} />
        <Text text="\ (•◡•) /" />
      </div>
    );
  }

  return (
    <Fragment>
      {scheduledLessons.map((scheduledLesson, i) => (
        <CardListItem
          key={scheduledLesson.id}
          color={i % 2 === 0 ? 'accent' : 'blue'}
        >
          <LateReportItem
            klassName={FormatText(scheduledLesson, klasses)}
            scheduledLesson={scheduledLesson}
          />
        </CardListItem>
      ))}
    </Fragment>
  );
}

const FormatText = (
  scheduledLesson: ScheduledLesson,
  klasses: Klass[],
): string => {
  const findKlass = klasses.find(klass => klass.id === scheduledLesson.klass);
  let string = '';

  if (findKlass) {
    string = formatScheduledLessonText(scheduledLesson, findKlass);
  }

  return string;
};
