import { useCallback, useEffect, useState } from 'react';
import { ICoursePath } from '@/models/Course';
import { Controller, useForm } from 'react-hook-form';
import MyCkeditor from '../../editor/MyCkeditor';
import { ImageFolderEnum } from '@/models/CkEditor';
import { buildChangedObject } from '@/utils/buildChangedObject';
import SaveCancelGroup from '../../common/buttons/SaveCancelGroup';
import { isEqual, isEmpty } from 'lodash';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { ApiError } from '@/models/Errors';
import alert from '@/utils/UseAlert';
import ConditionalRenderer from '../../common/ConditionalRenderer';
import { HtmlPreview } from '../../common/dataDisplay/HtmlPreview';
import useCourseEditing from '@/data/hook/useCourseEditing';
import Text from '../../common/dataDisplay/Text';
import { useTranslation } from 'react-i18next';
import {
  UpdateCoursePath,
  updateCoursePath,
} from '@/data/services/coursePathServices';
import { coursePathsQueryKeys } from '@/data/services/querykeys';

interface CoursePathFormProps {
  course: ICoursePath;
  readOnly?: boolean;
  onReset?(): void;
}

export default function CoursePathForm({
  course,
  onReset,
  readOnly,
}: CoursePathFormProps) {
  const [hasChanges, setHasChanges] = useState(false);
  const { allowBigChanges } = useCourseEditing();
  const {
    control,
    handleSubmit,
    watch,
    reset,
    formState: { defaultValues, errors },
  } = useForm<Omit<UpdateCoursePath, 'status'>>({
    defaultValues: {
      isbnCode: course.isbnCode ?? '',
    },
  });
  const queryClient = useQueryClient();
  const { t } = useTranslation('translation', {
    keyPrefix: 'coursePathForm',
  });
  const hasErrors = !isEmpty(errors);
  const isSaveButtonDisabled = !hasChanges || hasErrors;

  const updateCurrentCourse = async (data: UpdateCoursePath) => {
    return await updateCoursePath(course.slug, data);
  };

  const onClickReset = () => {
    reset(defaultValues);
    onReset?.();
  };

  const { mutate: onUpdate, isLoading: isUpdating } = useMutation(
    updateCurrentCourse,
    {
      async onSuccess({ slug, isbnCode }) {
        await queryClient.invalidateQueries(coursePathsQueryKeys.get(slug));
        alert.success(t('saveSuccessfuly'));
        reset({ isbnCode });
        setHasChanges(false);
        onReset?.();
      },
      onError(error: any) {
        const apiError = new ApiError(error);
        alert.error(apiError.getErrorMessage());
      },
    },
  );

  const onSubmit = (data: UpdateCoursePath) => {
    const body = buildChangedObject(defaultValues ?? {}, data);
    if (isEmpty(body)) {
      throw new Error('This form has no changes');
    }
    onUpdate(body);
  };

  const handleFormEntriesChanges = useCallback(
    (fields: UpdateCoursePath) => {
      const hasChanges = !isEqual(defaultValues ?? {}, fields);
      setHasChanges(hasChanges);
    },
    [defaultValues],
  );

  useEffect(() => {
    const subscribe = watch(handleFormEntriesChanges);
    return () => subscribe.unsubscribe();
  }, [handleFormEntriesChanges, watch]);

  return (
    <div className="flex flex-col gap-3">
      <ConditionalRenderer condition={!readOnly && !allowBigChanges}>
        <Text size="text-14" text={t('codeWillOverride')} />
      </ConditionalRenderer>
      <form
        className="flex flex-col gap-3 mt-2"
        onSubmit={handleSubmit(onSubmit)}
      >
        <ConditionalRenderer
          condition={!readOnly}
          fallback={<HtmlPreview html={course.isbnCode ?? ''} />}
        >
          <div className="flex flex-col gap-1">
            <label htmlFor="isbnCode">
              <Text text={t('isbnCode')} />
            </label>
            <Controller
              control={control}
              name="isbnCode"
              render={({ field: { onChange, value } }) => (
                <MyCkeditor
                  testId="isbnCode"
                  content={value ?? ''}
                  folder={ImageFolderEnum.COURSE}
                  handleContentChange={onChange}
                />
              )}
            />
          </div>
        </ConditionalRenderer>
        <ConditionalRenderer condition={!readOnly}>
          <SaveCancelGroup
            save={{ disabled: isSaveButtonDisabled }}
            disable={!hasChanges}
            loading={isUpdating}
            cancel={{ onClick: onClickReset }}
          />
        </ConditionalRenderer>
      </form>
    </div>
  );
}
