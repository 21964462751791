import ModalBank, {
  ModalBankButton,
} from '@/components/common/modals/ModalBank/ModalBank';
import useCourseEditing from '@/data/hook/useCourseEditing';
import useToggle from '@/data/hook/useToggle';
import {
  createActivity,
  pushActivityLesson,
} from '@/data/services/activityServices';
import { updateLesson } from '@/data/services/lessonServices';
import { isPulished } from '@/functions/handleCourseStatusIcon';
import Activity from '@/models/Activity';
import ActivityConfig from '@/models/ActivityConfig';
import CoursePath from '@/models/Course';
import { ApiError } from '@/models/Errors';
import { Lesson } from '@/models/Lesson';
import { ScheduledLessonTypeEnum } from '@/models/ScheduledLesson';
import Observable from '@/utils/observers/ObserverPattern';
import alert from '@/utils/UseAlert';
import { PlusIcon } from '@heroicons/react/outline';
import { useMutation } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import ConditionalRenderer from '@/components/common/ConditionalRenderer';
import TooltipHandler from '@/components/common/TooltipHandler';
import MainButton from '@/components/common/buttons/MainButton';
import ListActivityItem from './ListActivityItem';
import { LoadingIcon } from '@/components/icons';
import { VersioningStatusEnum } from '@/enums/VersioningStatus';
import useInfiniteService from '@/data/hook/useInfiniteService';
import { activitiesQueryKeys } from '@/data/services/querykeys';

interface ListActivitiesProps {
  lesson: Lesson;
  type?: ScheduledLessonTypeEnum;
  coursePath?: CoursePath;
  updateLessons?(loading?: boolean): Promise<void>;
  klassId?: number;
  readOnly?: boolean;
}

export default function ListActivities({
  lesson,
  coursePath,
  updateLessons,
  type,
  klassId,
  readOnly: readOnlyProp,
}: ListActivitiesProps) {
  const { t } = useTranslation('translation', {
    keyPrefix: 'activity',
  });

  const { isOpen, open, close } = useToggle();
  const readOnly = readOnlyProp || (!klassId && !coursePath);
  const { t: editRulesT } = useTranslation('translation', {
    keyPrefix: 'adminPage.editCourseRules',
  });

  const { slugCourseName = '' } = useParams();

  const observer = new Observable<number>();

  const requestKey = klassId || coursePath?.slug;
  const { allowBigChanges } = useCourseEditing();
  const navigate = useNavigate();

  const {
    results: activities,
    invalidate: updateActivities,
    isInitialLoading,
  } = useInfiniteService({
    enabled: !!lesson.id,
    ...activitiesQueryKeys.list({
      lessonId: lesson.id,
    })._ctx.infinity,
  });

  const updateLessonStatus = async (lesson: Lesson) => {
    const isExtraLesson = type !== ScheduledLessonTypeEnum.NORMAL && !!type;

    if (isPulished(lesson.status)) {
      const newLesson = await updateLesson(
        { lessonId: lesson.id, slugCourseName },
        { status: isExtraLesson ? VersioningStatusEnum.VERSIONING : undefined },
      );
      return newLesson.id;
    }
    return lesson.id;
  };

  const onCreate = async ({ lessonId }: { lessonId: number }) => {
    if (!requestKey) {
      throw new Error('Route not found');
    }

    lessonId = await updateLessonStatus(lesson);

    const activity = await createActivity(lessonId, {
      name: 'Nova Atividade',
      content: [],
      config: {} as ActivityConfig,
    });

    return { ...activity, lesson: lessonId };
  };

  const { mutate: pushActivity, isLoading: pushingActivity } = useMutation(
    pushActivityLesson,
    {
      async onSuccess() {
        close();
        await updateLessons?.();
        await updateActivities();
      },
      onError(error: any) {
        const apiError = new ApiError(error);
        alert.error(apiError.getErrorMessage());
      },
    },
  );

  const onUseActivity = async (activity: Activity) => {
    const lessonId = await updateLessonStatus(lesson);

    if (lessonId) {
      const activityId = activity.id;
      pushActivity({ lessonId, activityId });
    }
  };

  const { mutate: create, isLoading: isCreating } = useMutation(onCreate, {
    onSuccess: createdActivity => {
      const isCourse = typeof requestKey === 'string';
      const adminUrl = `/admin/courses/${requestKey}/lessons/${createdActivity.lesson}/activities/${createdActivity.id}`;
      const extraLessonUrl = `/class/${requestKey}/lessons/${createdActivity.lesson}/activities/${createdActivity.id}`;
      const route = isCourse ? adminUrl : extraLessonUrl;
      navigate(route);
    },
    onError: (err: any) => {
      const apiError = new ApiError(err);
      alert.error(apiError.getErrorMessage());
    },
  });

  return (
    <div className="grid gap-4">
      <ConditionalRenderer condition={isInitialLoading}>
        <LoadingIcon className="w-20 justify-self-center text-secondary/40" />
      </ConditionalRenderer>
      <ConditionalRenderer condition={activities?.length}>
        <div className="grid gap-8">
          {activities?.map((activity, index) => {
            return (
              <ListActivityItem
                key={index}
                activity={activity}
                allowBigChanges={allowBigChanges}
                coursePath={coursePath}
                klassId={klassId}
                lesson={lesson}
                observer={observer}
                readOnly={readOnly}
                updateActivities={updateActivities}
              />
            );
          })}
        </div>
      </ConditionalRenderer>
      <ConditionalRenderer condition={!readOnly}>
        <TooltipHandler
          renderTooltip={!allowBigChanges}
          className="w-fit"
          tooltipMessage={editRulesT('cantModify')}
        >
          <div className="flex flex-wrap gap-2">
            <MainButton
              dataTestId="newActivityButton"
              disabled={!allowBigChanges}
              loading={isCreating}
              onClick={() =>
                create({
                  lessonId: lesson.id,
                })
              }
              icon={<PlusIcon />}
              text={t('newActivity')}
            />
            <ModalBankButton
              onClick={open}
              mode="activities"
              disabled={!allowBigChanges}
            />
          </div>
        </TooltipHandler>
      </ConditionalRenderer>
      <ModalBank
        visible={isOpen}
        onClose={close}
        initial="activities"
        onUseActivity={onUseActivity}
        loading={pushingActivity}
      />
    </div>
  );
}
