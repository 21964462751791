import Answer from './Answer';
import { ElementPreviewFactoryProps } from '../ElementPreviewFactory';
import { QuizCorrectAnswer } from '../../../../../models/ActivityProgress';

function QuizFeedback({ answer }: ElementPreviewFactoryProps) {
  const { correctAnswer, correctAnswerIndex, answerType } =
    answer as QuizCorrectAnswer;

  return (
    <div className="flex w-full md:w-[70%] justify-center">
      <Answer
        answerType={answerType}
        value={correctAnswer!}
        index={correctAnswerIndex}
        disabled
        selectedAnswer={{
          id: correctAnswer!.id,
          answerVerifier: correctAnswer!.answerVerifier,
          answer: correctAnswer!.answer,
        }}
      />
    </div>
  );
}

export default QuizFeedback;
