import { Outlet } from 'react-router';
import { CtrlPlayFullLogoColorIcon } from '../../components/icons';

export default function LoginPage() {
  return (
    <div className="flex w-full min-h-screen max-w-full">
      <div
        id="gradientComponent"
        className="hidden md:flex w-full relative bg-gradient-to-t from-primary to-secondary duration-500"
      >
        <CtrlPlayFullLogoColorIcon className="w-44 absolute top-8 left-8" />
      </div>

      <div className="flex flex-col items-center gap-4 w-full md:w-[80%] lg:w-[45%] p-10 justify-center">
        <CtrlPlayFullLogoColorIcon className="w-[50%] sm:w-[25%] md:hidden" />
        <Outlet />
      </div>
    </div>
  );
}
