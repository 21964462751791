import { useTranslation } from 'react-i18next';
import { LinkIcon } from '@heroicons/react/outline';
import { Link } from 'react-router-dom';
import { twMerge } from 'tailwind-merge';

import { ModalState } from '../ModalHandler';
import ConditionalRenderer from '@/components/common/ConditionalRenderer';
import { Tooltip } from '@/components/common/dataDisplay/Tooltip';
import ConditionalWrapper from '@/components/common/ConditionalWrapper';
import Text from '@/components/common/dataDisplay/Text';

type AlterLinkButtonProps = {
  render?: boolean;
  editing?: boolean;
  url?: string;
  onChangeModal: (state: ModalState) => void;
};

export function AlterLinkButton({
  render,
  editing,
  url,
  onChangeModal,
}: AlterLinkButtonProps) {
  const { t } = useTranslation('translation', {
    keyPrefix: 'calendar.admin.manageKlass',
  });

  return (
    <ConditionalRenderer condition={render}>
      <Tooltip
        text={
          editing ? t('alterLink') : url ? t('klassLink') : t('noKlassLink')
        }
        className="flex items-center font-400"
      >
        <ConditionalWrapper
          condition={!!url && !editing}
          wrapper={<Link data-testid="classRoomLink" to={url ?? ''} />}
        >
          <button
            data-testid="alterLinkButton"
            className={twMerge(
              'flex min-w-min gap-0.5 hover:text-primary/50 transition ease-in-out duration-250 items-center disabled:cursor-default disabled:text-default text-primary',
              url && !editing ? 'disabled' : '',
            )}
            onClick={
              editing ? () => onChangeModal(ModalState.KLASS_LINK) : undefined
            }
          >
            <span className="w-4 h-4">
              <LinkIcon className="w-4 h-4" />
            </span>

            <Text
              text={editing ? t('alterLink') : t('accessKlass')}
              className="font-500 text-[0.875rem] lg:text-[1rem] hidden xl:flex"
            />
          </button>
        </ConditionalWrapper>
      </Tooltip>
    </ConditionalRenderer>
  );
}
