import useCountDown from '@/data/hook/useCountDown';
import useListService from '@/data/hook/useListService';
import { createAlert, updateAlert } from '@/data/services/alertServices';
import { alertQueryKeys } from '@/data/services/querykeys';
import { Alert, AlertTypeEnum } from '@/models/Alert';
import Klass from '@/models/Klass';
import ScheduledLesson from '@/models/ScheduledLesson';
import ScheduledLessonReport from '@/models/ScheduledLessonReport';
import { getErrorMessage } from '@/utils/getErrorMessage';
import alertToast from '@/utils/UseAlert';
import { ChatIcon, ClockIcon } from '@heroicons/react/outline';
import { useMutation } from '@tanstack/react-query';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { Fragment } from 'react/jsx-runtime';
import MainButton from '../common/buttons/MainButton';
import ConditionalRenderer from '../common/ConditionalRenderer';
import { Tooltip } from '../common/dataDisplay/Tooltip';
import Checkbox from '../common/dataInput/Checkbox';

export type NotifyLatelessButtonProps = {
  studentId: number;
  klass: Klass;
  scheduledLesson: ScheduledLesson;
  scheduledLessonReport?: ScheduledLessonReport;
  setRef?: (ref: HTMLSpanElement) => void;
};

const NotifyLatelessButton = ({
  studentId,
  scheduledLesson,
  scheduledLessonReport,
  klass,
  setRef,
}: NotifyLatelessButtonProps) => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'manageScheduledLessonReport',
  });
  const {
    results: alerts,
    isInitialLoading,
    invalidate,
  } = useListService(
    alertQueryKeys.list({
      actorId: studentId,
      targetId: scheduledLesson.id,
      type: [AlertTypeEnum.LATENESS],
    }),
  );

  const startNotifyRange = moment(scheduledLesson.datetime).diff(
    moment(),
    'seconds',
  );

  const endLessonDatetime = moment(scheduledLesson.datetime).add({
    minutes: klass.duration / 2,
  });

  const endNotifyRange = endLessonDatetime.diff(moment(), 'seconds');

  const { timeLeft: timerStart } = useCountDown(startNotifyRange);
  const { timeLeft: timerEnd } = useCountDown(endNotifyRange);

  const notifyLateless = async () => {
    await createAlert({
      type: AlertTypeEnum.LATENESS,
      actorId: studentId,
      targetId: scheduledLesson.id,
      alertAt: moment().toISOString(),
    });
    alertToast.success(t('notifySucess'));
    await invalidate();
  };

  const { mutate: onNotifyLateless, isLoading } = useMutation(notifyLateless, {
    onSuccess: invalidate,
    onError: error => {
      const errorMsg = getErrorMessage(error);
      alertToast.error(errorMsg);
    },
  });

  const notifyRangeTime = timerStart <= 0 && timerEnd > 0;

  const emptyReport = !scheduledLessonReport?.presence;

  const showButton = notifyRangeTime && emptyReport;

  const alert = alerts.at(0);

  return (
    <Fragment>
      <ConditionalRenderer condition={showButton && !alert}>
        <span ref={setRef}>
          {!alert && (
            <MainButton
              text={t('notifyLateless')}
              icon={<ClockIcon />}
              onClick={() => onNotifyLateless()}
              className="whitespace-nowrap"
              loading={isInitialLoading || isLoading}
            />
          )}
        </span>
      </ConditionalRenderer>
      {alert && (
        <NotifyEntered
          alert={alert}
          duration={klass.duration}
          scheduledLesson={scheduledLesson}
          invalidate={invalidate}
        />
      )}
    </Fragment>
  );
};

export default NotifyLatelessButton;

type NotifyInputProps = {
  alert: Alert;
  scheduledLesson: ScheduledLesson;
  duration: number;
  invalidate: () => Promise<void>;
};

const NotifyEntered = ({
  alert,
  scheduledLesson,
  duration,
  invalidate,
}: NotifyInputProps) => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'manageScheduledLessonReport',
  });
  const notifyInput = async () => {
    if (!alert) return;
    await updateAlert(alert.id, {
      isRead: true,
      comment: t('commentAlert', {
        hours: moment().format('HH:mm'),
      }),
    });
    await invalidate();
  };

  const { mutate: onNotifyLateless, isLoading } = useMutation(notifyInput, {
    onSuccess: invalidate,
    onError: error => {
      const errorMsg = getErrorMessage(error);
      alertToast.error(errorMsg);
    },
  });

  const endLesson = moment(scheduledLesson.datetime).add(duration, 'minutes');

  const endLessonTimer = endLesson.diff(moment(), 'seconds');

  const { timeLeft } = useCountDown(endLessonTimer);

  return (
    <ConditionalRenderer condition={timeLeft > 0}>
      <ConditionalRenderer condition={!alert.isRead}>
        <Checkbox
          label={t('studentEntered')}
          checked={alert.isRead}
          id={'alert' + alert.id}
          loading={isLoading}
          onChange={() => onNotifyLateless()}
        />
      </ConditionalRenderer>
      <ConditionalRenderer condition={alert.comment && alert.isRead}>
        <Tooltip text={alert.comment} placement="top" className="w-[200px]">
          <MainButton
            text={t('latelessUpdated')}
            className="items-center whitespace-nowrap gap-1"
            color="custom"
            icon={<ChatIcon />}
          />
        </Tooltip>
      </ConditionalRenderer>
    </ConditionalRenderer>
  );
};
