import {
  ChangeEventHandler,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import moment from 'moment';
import 'moment-timezone';
import { getTimezoneOffset } from 'date-fns-tz';
import { debounce } from 'lodash';

import { SelectInputProps } from './SelectInput';
import SearchInput, { SearchInputProps, SearchOption } from './SearchInput';

export type SelectTimeZoneProps = Pick<SearchInputProps<string>, 'input'> &
  Omit<SelectInputProps, 'children' | 'value' | 'onChange'> & {
    value?: string;
    onChange?: (value: string) => void;
  };

export default function SelectTimeZone({
  value = '',
  onChange,
  input,
  ...rest
}: SelectTimeZoneProps) {
  const [search, setSearch] = useState(value);

  const allOptions = moment.tz.names().map<SearchOption<string>>(createOption);

  const [options, setOptions] = useState(
    moment.tz.names().map<SearchOption<string>>(createOption),
  );

  const formatSearch = (value: string) =>
    value
      .replace('_', ' ')
      .toLocaleLowerCase()
      .normalize('NFD')
      .replace(/[\u0300-\u036f]/g, '');

  const memoedDebounce = useMemo(
    () =>
      debounce(search => {
        const value = formatSearch(search);
        const filter = allOptions.filter(option =>
          formatSearch(option.value).includes(value),
        );
        setOptions(filter);
      }, 500),
    [allOptions],
  );

  const debouncedSearch = useCallback(
    (search?: string) => {
      memoedDebounce(search);
    },
    [memoedDebounce],
  );

  const onSelect = (option: string) => {
    setSearch(option);
    onChange?.(option);
    debouncedSearch(option);
  };

  const onSearch: ChangeEventHandler<HTMLInputElement> = e => {
    const value = e.target.value;

    setSearch(value ?? undefined);
    debouncedSearch(value ?? undefined);
  };

  const onDeselect = () => {
    setSearch('');
    setOptions(moment.tz.names().map<SearchOption<string>>(createOption));
    onChange?.('');
  };

  const displayOption = useCallback((option: string) => {
    const tzOffSet = getTimezoneOffset(option, new Date()) / 3600000;
    return `${option.replace('_', ' ')} (GMT ${tzOffSet}:00)`;
  }, []);

  useEffect(() => {
    if (!!value) {
      setSearch(displayOption(value));
    }
  }, [displayOption, value]);

  return (
    <SearchInput
      {...rest}
      input={input}
      options={options}
      onSelect={onSelect}
      displayName={option => displayOption(option)}
      value={search}
      onSearch={onSearch}
      onDeselect={onDeselect}
      hasItemSelected={!!search}
    />
  );
}

const createOption = (item: string, index: number): SearchOption<string> => ({
  value: item,
  key: index,
});
