import { useTranslation } from 'react-i18next';
import Modal from './Modal';
import RoundedButton from '../buttons/RoundedButton';
import Text from '../dataDisplay/Text';
import { EmptyActivity, EmptyActivityWithLesson } from '@/models/Activity';
import { formatLessonNamePrefix } from '@/functions/lessonsName';

export type ModalEmptyActivityProps = {
  isVisible: boolean;
  isRequesting?: boolean;
  emptyActivities: EmptyActivityWithLesson[];
  onClickConfirm: (emptyActivities?: EmptyActivity[]) => void;
  onClickCancel: (e?: any) => void;
};

export default function ModalEmptyActivity(props: ModalEmptyActivityProps) {
  const {
    isVisible,
    onClickCancel,
    onClickConfirm,
    isRequesting,
    emptyActivities,
  } = props;
  const { t } = useTranslation('translation', {
    keyPrefix: 'modalEmptyActivity',
  });

  const defineLessonName = ({ name, lessonInfo }: EmptyActivityWithLesson) => {
    const lessonPrefix = formatLessonNamePrefix({
      lessonOrder: lessonInfo.order,
      scheduledLessonType: lessonInfo.type,
    });
    return `${lessonPrefix} - ${name}`;
  };

  return (
    <Modal
      testId="modalEmptyActivity"
      visible={isVisible}
      onClose={onClickCancel}
    >
      <div className="flex flex-col gap-6 items-center w-full">
        <Text
          text={t('attentionPhrase')}
          format="rubik-500"
          size="text-20"
          className="text-primary"
        />

        <Text
          text={t('deletePhrase')}
          format="rubik-400"
          size="text-16"
          className="text-base-content"
        />

        <div className="flex flex-col gap-4">
          {emptyActivities.map((emptyActivity, i) => (
            <Text
              testId={'emptyActivity' + emptyActivity.id}
              key={emptyActivity.id}
              text={defineLessonName(emptyActivity)}
              format="rubik-500"
              size="text-20"
              className="text-base-content"
            />
          ))}
        </div>

        <div className="flex w-full justify-around gap-6">
          <RoundedButton
            testId="modalCancelDeleteButton"
            color="neutral"
            className="w-full"
            text={t('cancelButton')}
            onClick={onClickCancel}
            id="cancel"
          />
          <RoundedButton
            text={t('confirmButton')}
            loading={isRequesting}
            testId="modalConfirmDeleteButton"
            color="warning"
            className="w-full truncate"
            onClick={() => onClickConfirm(emptyActivities)}
            id="confirm"
          />
        </div>
      </div>
    </Modal>
  );
}
