import ConditionalRenderer from '@/components/common/ConditionalRenderer';
import { HtmlPreview } from '@/components/common/dataDisplay/HtmlPreview';
import Text from '@/components/common/dataDisplay/Text';

interface ActivityHeadingProps {
  heading: string;
  subheading: string;
  content?: string;
}

function ActivityHeading({
  heading,
  subheading,
  content,
}: ActivityHeadingProps) {
  return (
    <div className="flex flex-col w-full items-center text-center gap-8">
      <Text
        testId="heading"
        text={heading.replace(/_________/g, '___')}
        format="poppins-600"
        size="text-24 md:text-28"
        className="text-primary break-words"
        id="text-title"
      />

      <ConditionalRenderer condition={content && content?.length > 0}>
        <HtmlPreview
          testId="preview"
          className="flex flex-col w-fit"
          html={content}
          format="book"
        />
      </ConditionalRenderer>

      <Text testId="subheading" text={subheading} />
    </div>
  );
}

export default ActivityHeading;
