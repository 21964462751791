interface HasDoneToolStuffParams {
  toolUrl?: string;
  instructionsUrl?: string;
  hasWatchedInstruction?: boolean;
  hasAccessedTool?: boolean;
}

export const booleanToNotification = (hasDone: boolean) => {
  return hasDone ? 0 : 1;
};

export const handleToolNotification = (params: HasDoneToolStuffParams) => {
  const { hasAccessedTool, hasWatchedInstruction, instructionsUrl, toolUrl } =
    params;

  const toolUrlNotification = booleanToNotification(!!hasAccessedTool);
  const instructionsUrlNotification = booleanToNotification(
    !!hasWatchedInstruction,
  );

  if (instructionsUrl && toolUrl) {
    return toolUrlNotification + instructionsUrlNotification;
  } else if (!instructionsUrl && !toolUrl) {
    return 0;
  } else {
    return toolUrl ? toolUrlNotification : instructionsUrlNotification;
  }
};
