import MainButton from '@/components/common/buttons/MainButton';
import useKlassOptions from '@/data/hook/useKlassOptions';
import { KlassPopupProps } from './KlassPopup';

export default function KlassPopupActions({ klass }: KlassPopupProps) {
  const { options } = useKlassOptions({
    klass,
    classNameIcons: 'text-base-100 w-4',
  });

  const { members, ...items } = options;

  return (
    <ul className="flex flex-wrap gap-2">
      {Object.values(items).map(item => (
        <li key={item.id}>
          <MainButton
            dataTestId={item.id}
            className="text-14 font-500"
            text={item.text}
            icon={item.icon}
            href={item.to}
          />
        </li>
      ))}
    </ul>
  );
}
