import React, { useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import SortToggle from '../../common/dataInput/SortToggle';
import ToggleButtonGroup from '../../common/dataInput/ToggleGroup';
import { NotificationFilterFields } from './NotificationTypes';
import NotificationTypeFilter from './NotificationTypeFilter';
import Checkbox from '../../common/dataInput/Checkbox';

type FiltersProps = {
  filters: NotificationFilterFields;
  onFilter: (data: NotificationFilterFields) => void;
};

const NotificationFilters: React.FC<FiltersProps> = ({ filters, onFilter }) => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'alerts.filters',
  });
  const { control, watch, register } = useForm<NotificationFilterFields>({
    defaultValues: filters,
  });

  const statusOptions = [
    { text: t('status.all'), value: 'all' },
    { text: t('status.today'), value: 'today' },
    { text: t('status.yesterday'), value: 'yesterday' },
  ];

  useEffect(() => {
    const subscription = watch(data => {
      onFilter(data as NotificationFilterFields);
    });
    return () => subscription.unsubscribe();
  }, [watch, onFilter]);

  return (
    <div className="flex flex-wrap items-center justify-between gap-2">
      <Controller
        name="status"
        control={control}
        render={({ field }) => (
          <ToggleButtonGroup
            exclusive
            size="small"
            buttons={statusOptions}
            value={field.value}
            onChange={field.onChange}
          />
        )}
      />
      <Checkbox
        label={t('onlyPendings')}
        {...register('onlyPendings')}
        className={{ div: 'flex-row-reverse' }}
      />
      <NotificationTypeFilter {...register('types')} control={control} />
      <Controller
        name="sortBy"
        control={control}
        render={({ field }) => (
          <SortToggle
            id="notificationsSort"
            hideLabel
            text={{ ascending: t('oldest'), descending: t('newest') }}
            isDescending={field.value === 'desc'}
            onToggleSort={checked => field.onChange(checked ? 'desc' : 'asc')}
          />
        )}
      />
    </div>
  );
};

export default NotificationFilters;
