import Text from '../common/dataDisplay/Text';
import { ClipboardListIcon } from '@heroicons/react/outline';
import Badge from '../common/dataDisplay/Badge';
import { Link } from 'react-router-dom';
import ScheduledLesson from '@/models/ScheduledLesson';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { Tooltip } from '../common/dataDisplay/Tooltip';
import { formatScheduledLessonText } from '@/utils/formatScheduledLessonText';
import { REQUEST_STALE_TIME_IN_MS } from '@/constants';
import { useQuery } from '@tanstack/react-query';
import { klassesQueryKeys } from '@/data/services/querykeys';

interface LateReportItemProps {
  scheduledLesson: ScheduledLesson;
  klassName: string;
}

interface LateReportItemProps {
  scheduledLesson: ScheduledLesson;
  klassName: string;
}

function LateReportItem({ scheduledLesson, klassName }: LateReportItemProps) {
  const { t } = useTranslation('translation', {
    keyPrefix: 'teacherDashboard.teacherReports',
  });

  const { data: klass } = useQuery({
    ...klassesQueryKeys.get(scheduledLesson.klass),
    enabled: !klassName,
    staleTime: REQUEST_STALE_TIME_IN_MS,
  });

  return (
    <Badge
      count={moment(scheduledLesson.datetime).fromNow()}
      className="transform -translate-y-1/2 bg-error p-2"
      position="-top-2.5 -right-2.5"
    >
      <Link
        to={`/class/${scheduledLesson.klass}/lesson-report/${scheduledLesson.id}`}
        className="text-inherit"
      >
        <div className="relative flex justify-between items-center">
          <Text
            format="rubik-500"
            size="text-16"
            text={
              klassName || formatScheduledLessonText(scheduledLesson, klass)
            }
          />
          <Tooltip text={t('pendingReports')}>
            <ClipboardListIcon className="w-6" />
          </Tooltip>
        </div>
      </Link>
    </Badge>
  );
}

export default LateReportItem;
