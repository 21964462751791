export interface SimpleTag {
  id: number;
  name: string;
  color?: string;
}
export enum TagDifficultyEnum {
  BEGINNER = 'BEGINNER',
  INTERMEDIATE = 'INTERMEDIATE',
  ADVANCED = 'ADVANCED',
}
export type LevelUnion = 1 | 2 | 3 | 4 | 5;
export default interface Tag {
  id: number;
  description: string;
  areaId: number;
  difficulty: TagDifficultyEnum;
  level: LevelUnion;
}

export const implementsOfTag = (obj: any): obj is Tag => {
  if (!obj) return false;
  return (
    'id' in obj &&
    'name' in obj &&
    'description' in obj &&
    'areaId' in obj &&
    'difficulty' in obj &&
    'level' in obj
  );
};
