import ConditionalRenderer from '@/components/common/ConditionalRenderer';
import InfinityList from '@/components/common/InfinityList';
import { LessonFormType } from '@/components/forms/LessonForm';
import useInfiniteService from '@/data/hook/useInfiniteService';
import { ListAreaFilter } from '@/data/services/areaServices';
import { areasQueryKeys } from '@/data/services/querykeys';
import Area from '@/models/Area';
import { Control } from 'react-hook-form';
import { ListItemFactory } from './ListItemFactory';
import useStack from '@/data/hook/useStack';
import Topbar from './TopBar';
import { LoadingIcon } from '@/components/icons';

type ListTopicsProps = {
  area?: Area;
  stack: ReturnType<typeof useStack<Area>>;
  control?: Control<LessonFormType>;
};

const mountFilters = (area?: Area): ListAreaFilter => {
  if (!!area)
    return {
      parentAreaId: [area.id],
    };

  return {
    isRoot: true,
  };
};
const ListTopics = ({ area, stack, control }: ListTopicsProps) => {
  const {
    results: areas,
    isInitialLoading,
    fetchNextPage,
    ...infinityProps
  } = useInfiniteService({
    ...areasQueryKeys.list(mountFilters(area))._ctx.infinity,
  });

  return (
    <div className="w-full pt-2 flex flex-col gap-2.5 overflow-hidden">
      <ConditionalRenderer condition={stack.value.length}>
        <Topbar stack={stack} />
      </ConditionalRenderer>
      <ConditionalRenderer condition={isInitialLoading}>
        <div className="flex justify-center w-full text-primary">
          <LoadingIcon className="w-5 h-5" />
        </div>
      </ConditionalRenderer>
      <InfinityList
        className="flex flex-col gap-1.5 h-36 pr-2"
        scroll
        {...infinityProps}
        onReachEnd={fetchNextPage}
      >
        {areas.map(area => (
          <ListItemFactory
            key={area.id}
            area={area}
            stack={stack}
            control={control}
          />
        ))}
      </InfinityList>
    </div>
  );
};

export default ListTopics;
