import {
  ArrowUpIcon,
  ChevronDownIcon,
  ReplyIcon,
  ChevronUpIcon,
  TrashIcon,
} from '@heroicons/react/outline';
import moment from 'moment';
import { LessonComment } from '../../models/Comment';
import IconButton from '../common/buttons/IconButton';
import MainButton from '../common/buttons/MainButton';
import { Avatar } from '../common/dataDisplay/Avatar';
import { HtmlPreview } from '../common/dataDisplay/HtmlPreview';
import Text from '../common/dataDisplay/Text';
import useAuth from '../../data/hook/useAuth';
import { useState } from 'react';
import CommentBox from './CommentBox';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { handleUserFullName } from '../../functions/handleUserFullName';
import ConditionalRenderer from '../common/ConditionalRenderer';
import {
  createLessonComment,
  deleteLessonComment,
  upvoteLessonComment,
} from '@/data/services/lessonCommentServices';
import alert from '@/utils/UseAlert';
import { useQueryClient } from '@tanstack/react-query';
import { lessonCommentsQueryKeys } from '@/data/services/querykeys';

export default function TeacherComment({
  comment,
  isUpdatingList,
  lessonId,
}: {
  comment: LessonComment;
  isUpdatingList: boolean;
  lessonId: number;
}) {
  const { user } = useAuth();
  const { t } = useTranslation();

  const [isUpdating, setIsUpdating] = useState(false);
  const [openResponses, setOpenResponses] = useState(false);

  const queryClient = useQueryClient();

  const CommentItem = ({
    comment,
    isResponse,
  }: {
    comment: LessonComment;
    isResponse: boolean;
  }) => {
    const [openReply, setOpenReply] = useState(false);
    const [isUpvoting, setIsUpvoting] = useState(false);

    const listQueryKey = lessonCommentsQueryKeys.list(lessonId).queryKey;

    const userHasUpvoted = comment.upVotes?.find(
      vote => vote === Number(user?.id),
    );

    const handleSubmit = async (content: string) => {
      setIsUpdating(true);
      try {
        await createLessonComment(content, lessonId, comment.id);
        alert.success(t('teacherComments.successAdd'), { duration: 2000 });
        queryClient.invalidateQueries(listQueryKey);

        setOpenResponses(true);
      } catch (error) {
        alert.error(t('teacherComments.errorAdd'), { duration: 2000 });
      } finally {
        setIsUpdating(false);
      }
    };

    const handleDelete = async () => {
      setIsUpdating(true);
      try {
        await deleteLessonComment({ commentId: comment.id, lessonId });
        alert.success(t('teacherComments.successDelete'), { duration: 2000 });
        queryClient.invalidateQueries(listQueryKey);
      } catch (error) {
        alert.error(t('teacherComments.successError'), { duration: 2000 });
      } finally {
        setIsUpdating(false);
      }
    };

    const handleUpvote = async () => {
      if (user && !userHasUpvoted) {
        try {
          setIsUpvoting(true);
          await upvoteLessonComment({ commentId: comment.id, lessonId });
          queryClient.invalidateQueries(listQueryKey);
        } catch (error) {
          alert.error(t('teacherComments.errorUpvote'));
        } finally {
          setIsUpvoting(false);
        }
      }
    };

    return (
      <div className="flex justify-between">
        <div className="flex gap-2.5">
          {comment.user ? (
            <Avatar size="6" userId={comment.user.id} />
          ) : (
            <React.Fragment />
          )}
          <div className="flex gap-2.5 flex-col">
            <div className="flex gap-2.5 items-center">
              <Text
                className="text-primary"
                format="rubik-500"
                text={handleUserFullName(comment.user)}
              />
              <Text
                className="text-neutral text-14"
                text={`${moment(comment.createdAt).format('DD/MM/YYYY')} ${t(
                  'teacherComments.at',
                )} ${moment(comment.createdAt).format('HH:mm')}`}
              />
            </div>
            <HtmlPreview html={comment.comment} />
            {!isResponse && (
              <React.Fragment>
                <MainButton
                  color="custom"
                  dataTestId="replyComment"
                  text={t('teacherComments.reply')}
                  onClick={() => {
                    setOpenReply(!openReply);
                  }}
                  icon={<ReplyIcon strokeWidth={2.5} />}
                  className="uppercase text-primary font-500 gap-2.5"
                />
                {openReply && (
                  <CommentBox testId="Reply" onSubmit={handleSubmit} />
                )}
              </React.Fragment>
            )}
          </div>
        </div>
        <div className="flex items-center gap-1 flex-col">
          <IconButton
            onClick={handleUpvote}
            testId="upvoteComment"
            loading={isUpvoting || isUpdatingList}
            loadingSize="w-5 h-5"
            icon={<ArrowUpIcon className="w-5 h-5" />}
            className={
              userHasUpvoted
                ? 'text-primary cursor-default pointer-events-none'
                : 'text-neutral'
            }
          />
          <Text
            testId="upvoteValue"
            format="rubik-500"
            className="text-18 mb-2"
            text={comment.upVotes?.length ?? 0}
          />
          <ConditionalRenderer condition={user?.id === comment.user.id}>
            <IconButton
              onClick={handleDelete}
              loading={isUpdating || isUpdatingList}
              testId="deleteComment"
              loadingSize="w-4 h-4"
              icon={<TrashIcon className="text-error w-4 h-4" />}
            />
          </ConditionalRenderer>
        </div>
      </div>
    );
  };

  return (
    <div className="flex flex-col gap-2.5">
      <div key={comment.id} className="flex flex-col gap-2.5">
        <CommentItem isResponse={false} comment={comment} />
        <div
          onClick={() => setOpenResponses(!openResponses)}
          data-testid="replyDropdown"
          className={`ml-8 flex gap-4 text-14 items-center text-neutral ${
            comment.replies?.length ? 'cursor-pointer' : 'cursor-default'
          } `}
        >
          <Text
            text={`${comment.replies?.length || 0} ${
              comment.replies?.length === 1
                ? t('teacherComments.response')
                : t('teacherComments.responses')
            }`}
          />
          {openResponses ? (
            <ChevronUpIcon
              className={`w-3 h-3 shrink-0 ${
                comment.replies?.length ? '' : 'hidden'
              }`}
            />
          ) : (
            <ChevronDownIcon
              className={`w-3 h-3 shrink-0 ${
                comment.replies?.length ? '' : 'hidden'
              }`}
            />
          )}
        </div>
        {comment.replies && openResponses ? (
          <div className="flex flex-col ml-8 mt-2 gap-2">
            {openResponses &&
              comment.replies.map(response => (
                <div key={response.id} className="flex flex-col gap-2.5">
                  <CommentItem isResponse={true} comment={response} />
                </div>
              ))}
          </div>
        ) : (
          <React.Fragment />
        )}
      </div>
    </div>
  );
}
