import { PropsWithChildren } from 'react';
import { twMerge } from 'tailwind-merge';

type TopicsContainerProps = {
  className?: string;
} & PropsWithChildren;
export default function TopicsContainer({
  children,
  className,
}: TopicsContainerProps) {
  return (
    <div
      className={twMerge(
        'p-3 border border-base-content/20 rounded-xl bg-base-100',
        className,
      )}
    >
      {children}
    </div>
  );
}
