import ConditionalRenderer from '@/components/common/ConditionalRenderer';
import Text from '@/components/common/dataDisplay/Text';
import InfinityList from '@/components/common/InfinityList';
import useInfiniteService from '@/data/hook/useInfiniteService';
import useTreeArea from '@/data/hook/useTreeArea';
import { areasQueryKeys } from '@/data/services/querykeys';
import useFilterParams from '@/utils/UseFilterParams';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import TopicsContainer from '../TopicsContainer';
import { TopicsFiltersParams } from '../TopicsPageContent';
import Actions from './actions/Actions';
import MoveTo from './actions/MoveTo';
import WorkspaceItemsFactory from './items/WorkspaceItemsFactory';
import TopicsBreadcrumb from './TopicsBreadcrumb';
import { twMerge } from 'tailwind-merge';
import { scrollBarClassName } from '@/utils/scrollBarClassName';

export default function TopicsWorkspace() {
  const { t } = useTranslation('translation', {
    keyPrefix: 'topics.workspace',
  });

  const { tree, pathParams, finalMountTreeObserver } = useTreeArea();
  const [mountTreeSignal, setMountTreeSignal] = useState(1);
  const [isMoving, setIsMoving] = useState(false);

  const { filterParams } = useFilterParams<TopicsFiltersParams>();

  const isRenamingParam = Boolean(filterParams.get('isRenaming'));
  const [isRenaming, setIsRenaming] = useState(isRenamingParam);
  const areaId = Number(filterParams.get('area') ?? NaN);

  const path = tree.current.findPath(pathParams.at(-1) || 0);

  const currentArea = path?.at(-1);

  const {
    results: areas,
    fetchNextPage,
    ...infiniteProps
  } = useInfiniteService({
    ...areasQueryKeys.list({
      parentAreaId: [currentArea?.id || 0],
    })._ctx.infinity,
    enabled: !!currentArea?.id,
  });

  useEffect(() => {
    setIsRenaming(isRenamingParam);
  }, [isRenamingParam]);

  useEffect(
    () =>
      finalMountTreeObserver.current.subscribe({
        id: 1,
        action: () => {
          setMountTreeSignal(prev => ++prev);
        },
      }),
    [finalMountTreeObserver],
  );

  if (!mountTreeSignal) return null;

  return (
    <TopicsContainer
      className={twMerge(
        'relative flex-grow flex flex-col gap-2.5 ',
        scrollBarClassName,
      )}
    >
      <ConditionalRenderer
        condition={path?.length && currentArea?.id}
        fallback={
          <div className="w-full h-full flex justify-center items-center">
            <Text text={t('selectArea')} />
          </div>
        }
      >
        <header className="flex justify-between">
          <TopicsBreadcrumb
            key={'breadcrum' + filterParams.toString()}
            path={path?.slice(1) || []}
            isRenaming={isRenaming}
            setIsRenaming={setIsRenaming}
          />
          <ConditionalRenderer condition={currentArea}>
            {currentArea && (
              <Actions
                key={filterParams.toString()}
                area={currentArea}
                setIsRenaming={setIsRenaming}
                setIsMoving={setIsMoving}
              />
            )}
          </ConditionalRenderer>
        </header>
        <ConditionalRenderer condition={isMoving && currentArea}>
          {currentArea && (
            <MoveTo area={currentArea} setIsMoving={setIsMoving} />
          )}
        </ConditionalRenderer>

        <InfinityList
          {...infiniteProps}
          onReachEnd={fetchNextPage}
          className={twMerge(
            'gap-2.5 max-h-[600px] min-h-[200px] relative overflow-x-hidden pr-1',
          )}
          scroll
        >
          {areas?.map(area => (
            <WorkspaceItemsFactory
              key={`${area.id}/${areaId}`}
              area={area}
              active={areaId === area.id}
            />
          ))}
        </InfinityList>
      </ConditionalRenderer>
    </TopicsContainer>
  );
}
