import LessonCard, { LessonCardProps } from '../lessons/LessonCard/LessonCard';
import ConditionalRenderer from './ConditionalRenderer';

type ChallengeCardProps = LessonCardProps & {
  active: boolean;
  notification?: number;
  challengeId?: number | null;
};

export default function ChallengeCard({
  active,
  notification,
  type,
  challengeId,
}: ChallengeCardProps) {
  return (
    <ConditionalRenderer condition={challengeId}>
      <LessonCard
        type={type}
        link="challenge"
        active={active}
        notification={notification}
      />
    </ConditionalRenderer>
  );
}
