import { AnimatePresence, motion } from 'framer-motion';
import CourseForm from './CourseForm';
import { courseGenerator } from '../../../functions/generators';
import { CourseTypeEnum } from '../../../models/Course';
import { accordion } from '../../../utils/animations/commom';

interface AddCourseProps {
  showAddCourse: boolean;
  updateCourseList(): void;
  setShowAddCourse: React.Dispatch<React.SetStateAction<boolean>>;
}

export default function AddCourse({
  showAddCourse,
  updateCourseList,
  setShowAddCourse,
}: AddCourseProps) {
  const course = courseGenerator({
    id: 0,
    name: '',
    abbreviation: '',
    courseType: CourseTypeEnum.CTRL_QUICK,
    bannerImg: null,
  });

  return (
    <AnimatePresence>
      {showAddCourse && (
        <motion.div
          className="w-full flex overflow-hidden justify-end"
          {...accordion}
        >
          <div className="flex w-[70%] self-end">
            <CourseForm
              course={course}
              updateCourseList={updateCourseList}
              isEditMode={true}
              setIsEditMode={setShowAddCourse}
            />
          </div>
        </motion.div>
      )}
    </AnimatePresence>
  );
}
