import React from 'react';
import Text from '../common/dataDisplay/Text';
import {
  AcademicCapIcon,
  DesktopComputerIcon,
  QuestionMarkCircleIcon,
  UserGroupIcon,
} from '@heroicons/react/outline';

const columnOptions = [
  {
    icon: <DesktopComputerIcon strokeWidth={1.3} />,
    text: 'Encontros ao vivo uma vez por semana com 1h30min de duração',
  },
  {
    icon: <AcademicCapIcon strokeWidth={1.3} />,
    text: 'Acesso ao Portal do Aluno, com Material Didático seguindo as competências da BNCC do MEC',
  },
  {
    icon: <UserGroupIcon strokeWidth={1.5} />,
    text: 'Turmas reduzidas que garantem atenção a cada aluno, mas mantendo socialização',
  },
  {
    icon: <QuestionMarkCircleIcon strokeWidth={1.7} />,
    text: 'Plantões de dúvidas',
  },
];

const OnlineRequirements = () => {
  return (
    <div className="flex w-full flex-col gap-2">
      <Text
        format="poppins-600"
        className="text-primary text-20"
        text="Nossas escolas"
      />
      <img
        src="https://s3.amazonaws.com/guiafranquiasdesucesso/wp-content/uploads/2020/01/21165035/franquia-ctrl-play-logo.png"
        alt="Aulas online"
        className="h-28 aspect-auto w-fit"
      />
      <p className="leading-none">
        Computadores de última geração disponíveis para cada aluno poder fazer
        as atividades.
      </p>
      <p className="leading-none">
        <strong>Espaço Play:</strong> enquanto os alunos esperam as aulas
        começarem, eles podem interagir com os colegas, jogarem e se divertir.
      </p>
      <p className="leading-none">
        <strong>Espaço Plat:</strong> enquanto os alunos esperam as aulas
        começarem, eles podem interagir com os colegas, jogarem e se divertir.
      </p>
    </div>
  );
};
const F2fRequirements = () => {
  return (
    <div className="flex w-full flex-col gap-2">
      <Text
        format="poppins-600"
        className="text-primary text-20"
        text="Requisitos"
      />
      <img
        src="https://s3.amazonaws.com/guiafranquiasdesucesso/wp-content/uploads/2020/01/21165035/franquia-ctrl-play-logo.png"
        alt="Aulas online"
        className="h-28 aspect-auto w-fit"
      />
      <p className="leading-none">
        Aulas online são feitas pelo <strong>computador ou notebook</strong>,
        não é possível fazer as aulas por celular ou tablet pois o aluno precisa
        baixar e utilizar os programas de computador
      </p>
      <p className="leading-none">
        <strong>Requisitos de Hardware Mínimo:</strong> Quad-Core Intel i3 - 6a
        geração ou i5 - 5a geração com 4 GB de RAM
      </p>
      <p className="leading-none">
        <strong>Requisitos de Hardware Recomendável:</strong> Processador i5 -
        6a geração com 8 GB de RAM
      </p>
    </div>
  );
};

export default function HowCourseWorks({ type }: { type?: 'online' | 'f2f' }) {
  return (
    <div className="flex flex-col sm:flex-row gap-8">
      <div className="gap-2 w-full flex flex-col h-auto md:justify-between">
        <Text
          format="poppins-600"
          className="text-primary text-20 mb-3"
          text="Como Funciona"
        />
        {columnOptions.map(option => {
          return (
            <div className="flex gap-5 items-center ">
              <div className="flex items-center w-8 h-8 sm:w-12 sm:h-12 shrink-0 grow-0 p-2 sm:p-3 justify-center rounded-full bg-accent/40 text-accent">
                {option.icon}
              </div>
              <Text
                format="rubik-500"
                className="text-accent"
                text={option.text}
              />
            </div>
          );
        })}
      </div>
      {type === 'f2f' ? <F2fRequirements /> : <OnlineRequirements />}
    </div>
  );
}
