import {
  ComponentProps,
  forwardRef,
  Fragment,
  useEffect,
  useImperativeHandle,
  useRef,
} from 'react';
import ConditionalRenderer from '../ConditionalRenderer';
import TextInput from './TextInput';
import { BaseInputProps } from './BaseInput';

type SpanInputProps = Omit<ComponentProps<'input'>, 'children'> &
  BaseInputProps & {
    isEditing: boolean;
    children?: (value?: string) => React.ReactNode;
  };
const SpanInput = forwardRef<HTMLInputElement, SpanInputProps>(
  ({ value, isEditing, children, className, ...props }, ref) => {
    const inputRef = useRef<HTMLInputElement>(null);

    useImperativeHandle(ref, () => inputRef.current as HTMLInputElement);
    useEffect(() => {
      if (isEditing) inputRef.current?.focus();
    }, [isEditing, value]);
    return (
      <Fragment>
        <ConditionalRenderer condition={!isEditing}>
          {children ? children(value as string) : <span>{value}</span>}
        </ConditionalRenderer>
        <ConditionalRenderer condition={isEditing}>
          <TextInput
            ref={inputRef}
            value={value}
            onFocus={e => e.target.select()}
            {...props}
            type="text"
            color="primary"
          />
        </ConditionalRenderer>
      </Fragment>
    );
  },
);

export default SpanInput;
