import { ApiError } from '@/models/Errors';
import { AxiosError } from 'axios';
import alert from './UseAlert';

export const handleMultipleRejectRequests = (
  responses: PromiseSettledResult<unknown>[],
) => {
  const rejectedResponses = responses.filter(
    ({ status }) => status === 'rejected',
  );
  rejectedResponses.forEach(response => {
    if ('reason' in response && response.reason instanceof AxiosError) {
      const apiError = new ApiError(response.reason);
      alert.error(apiError.getErrorMessage());
    }
  });
};
