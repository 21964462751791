import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import ChapterRender from '@/components/common/ChapterRender';
import ChapterContainer from './ChapterContainer';
import { useQuery } from '@tanstack/react-query';
import HeadTitle from '@/components/common/HeadTitle';
import { makeLessonHeadTitle } from '@/utils/lessonHeadTitle';
import ConditionalRenderer from '@/components/common/ConditionalRenderer';
import AdminMaterialControl from './AdminMaterialControl';
import useLessonContext from '@/data/hook/lesson';
import getKlassViewBaseUrl from '@/utils/getKlassViewBaseUrl';
import {
  booksQueryKeys,
  coursePathsQueryKeys,
} from '@/data/services/querykeys';

function TeacherMaterialView() {
  const { t } = useTranslation('translation', {
    keyPrefix: 'klassView',
  });

  const { chapterOrder, lessonId, slugCourseName = '', klassId } = useParams();

  const { lesson, scheduledLesson } = useLessonContext();
  const { order, teacherBook: bookId } = lesson || {};

  const { data: book, isInitialLoading: isLoading } = useQuery({
    ...booksQueryKeys.get(Number(bookId)),
    enabled: !!bookId,
  });

  const chapters = book?.chapters ?? [];
  const chapter = chapters?.at(Number(chapterOrder) - 1);

  const { data: coursePath } = useQuery({
    enabled: !!slugCourseName,
    ...coursePathsQueryKeys.get(slugCourseName ?? ''),
  });
  const lessonAndCourseTitle = makeLessonHeadTitle({
    lessonOrder: order || 0,
    coursePath,
    lessonType: scheduledLesson?.type,
  });
  const titleWithouChapter = `${t(
    'teacherMaterial',
  )} - ${lessonAndCourseTitle}`;
  const title = chapter
    ? `${chapter.title} - ${titleWithouChapter}`
    : titleWithouChapter;

  return (
    <ChapterContainer isLoading={isLoading}>
      <HeadTitle routeInfo={title} />
      <ChapterRender chapter={chapter} />
      <ConditionalRenderer condition={!!chapter}>
        <AdminMaterialControl
          isTeacherBook
          baseUrl={getKlassViewBaseUrl({
            slugCourseName,
            lessonId: Number(lessonId),
            klassId: Number(klassId),
          })}
          chapters={chapters}
        />
      </ConditionalRenderer>
    </ChapterContainer>
  );
}

export default TeacherMaterialView;
