import { INTERVAL_BETWEEN_SOUND_ALERTS } from '@/constants';
import { debounce } from 'lodash';
import { useRef, useMemo, useCallback } from 'react';
import useConfigContext from './config';

export const useSoundAlert = () => {
  const { userSettings } = useConfigContext();
  const activeSound = userSettings?.alertSound ?? true;
  const ref = useRef<HTMLAudioElement>(null);

  const playSound = useMemo(
    () =>
      debounce(
        () => activeSound && ref.current?.play(),
        INTERVAL_BETWEEN_SOUND_ALERTS,
        {
          leading: true,
          trailing: false,
        },
      ),
    [activeSound],
  );

  const setVolume = useCallback((volume: number) => {
    if (ref.current) {
      ref.current.volume = volume;
    }
  }, []);
  return { ref, playSound, setVolume };
};
