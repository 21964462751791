import requester from '@/axios';
import ChapterProgress from '@/models/ChapterProgress';

export const getChapterProgress = async (
  chapterId?: number,
  chapterProgressId?: number,
) => {
  const { data } = await requester().get<ChapterProgress>(
    `chapters/${chapterId}/chapter-progress/${chapterProgressId}/`,
  );
  return data;
};

type UpdateChapterProgress = {
  chapterId: number;
  chapterProgressId: number;
  chapterProgress: Partial<ChapterProgress>;
};

export const updateChapterProgress = async ({
  chapterId,
  chapterProgressId,
  chapterProgress,
}: UpdateChapterProgress) => {
  const { data } = await requester().patch<ChapterProgress>(
    `chapters/${chapterId}/chapter-progress/${chapterProgressId}/`,
    chapterProgress,
  );
  return data;
};
