import { BaseItem } from '../../models/Avatar';
import ConditionalRenderer from '../common/ConditionalRenderer';
import { HexColorPicker } from 'react-colorful';
import { usePopperTooltip } from 'react-popper-tooltip';

export default function AvatarColorPicker({
  item,
  color,
  onChange,
}: {
  item: BaseItem;
  color: string;
  onChange: (newValue: string) => void;
}) {
  const { getTooltipProps, setTooltipRef, setTriggerRef, visible } =
    usePopperTooltip({
      placement: 'right-end',
      trigger: 'click',
      interactive: true,
    });
  return (
    <ConditionalRenderer condition={item.allowUserColor}>
      <label
        ref={setTriggerRef}
        style={{ backgroundColor: color }}
        data-testid={`${item.name}InventoryColorSelectLabel`}
        className="relative flex cursor-pointer z-[60] rounded-full border border-base-100 bottom-5 left-[3.75rem] w-4 h-4"
      ></label>
      <ConditionalRenderer condition={visible}>
        <div
          {...getTooltipProps({
            className: `w-fit h-fit text-14 texts shadow-default   z-50`,
          })}
          ref={setTooltipRef}
          className="fixed smallColorPicker z-[70] -right-36 w-fit"
        >
          <HexColorPicker
            data-testid={`${item.name}InventoryColorSelect`}
            color={color}
            onChange={onChange}
          />
        </div>
      </ConditionalRenderer>
    </ConditionalRenderer>
  );
}
