import { Fragment } from 'react';
import { twMerge } from 'tailwind-merge';
import { useTranslation } from 'react-i18next';
import { useQuery } from '@tanstack/react-query';
import { Link } from 'react-router-dom';

import Klass from '@/models/Klass';
import useListService from '@/data/hook/useListService';
import {
  avatarsQueryKeys,
  studentsQuerykeys,
  usersQueryKeys,
} from '@/data/services/querykeys';
import { REQUEST_STALE_TIME_IN_MS } from '@/constants';
import User, { UserStatusEnum, UserTypeEnum } from '@/models/User';
import ConditionalRenderer from '@/components/common/ConditionalRenderer';
import Text from '@/components/common/dataDisplay/Text';
import { LoadingIcon } from '@/components/icons';
import { ClassNameComponent } from '@/utils/ClassNameComponent';
import UserAvatar from '@/components/store/UserAvatar';
import Tag from '@/components/common/dataDisplay/Tag';
import { ROUTES } from '@/utils/routes';

type AvatarClassroomProps = {
  klass: Pick<Klass, 'id' | 'teacherId'>;
  className?: ClassNameComponent;
};

export default function AvatarClassroom({
  klass,
  className,
}: AvatarClassroomProps) {
  const { id: klassId, teacherId } = klass;

  const { t: tDashboardClass } = useTranslation('translation', {
    keyPrefix: 'dashboard.dashboardClass',
  });

  const [t] = useTranslation('translation', {
    keyPrefix: 'common',
  });

  const { results: userAvatars, isInitialLoading: loadingUserAvatars } =
    useListService({
      ...avatarsQueryKeys.list({
        klass: klassId,
      }),
      enabled: !!klassId,
      staleTime: REQUEST_STALE_TIME_IN_MS,
    });

  const { results: students } = useListService({
    ...studentsQuerykeys.list({
      klassId,
      status: [UserStatusEnum.ACTIVE_ENROLLMENT],
    }),
    staleTime: REQUEST_STALE_TIME_IN_MS,
    enabled: !!klass?.id,
  });

  const { data: teacher } = useQuery({
    ...usersQueryKeys.get(teacherId),
    enabled: !!teacherId,
    staleTime: REQUEST_STALE_TIME_IN_MS,
  });

  return (
    <div
      className={twMerge(
        'flex relative bg-accent/40 aspect-video rounded-b-2xl shadow-default',
        className?.base,
      )}
    >
      <img
        src="/images/cenario.png"
        alt={t('accessibility.yourClassShip')}
        className={twMerge('w-full rounded-b-2xl', className?.img)}
      />

      <ConditionalRenderer
        condition={klass}
        fallback={
          <Text
            format="rubik-500"
            className="text-20 rounded-b-2xl text-primary-content p-5 text-center w-full h-auto aspect-video absolute flex place-self-end self-end bg-primary/40 justify-self-end"
            text={tDashboardClass('teacherKlassMembers')}
          />
        }
      >
        <Fragment>
          <ConditionalRenderer condition={loadingUserAvatars}>
            <div className="flex w-full h-full items-center justify-center absolute">
              <LoadingIcon className="w-20 h-20 text-accent/40" />
            </div>
          </ConditionalRenderer>

          <AvatarTag
            className={{ base: 'self-center top-2 absolute w-full' }}
            user={teacher}
          />

          <ConditionalRenderer condition={userAvatars.length}>
            <div className="flex flex-wrap-reverse absolute bottom-3 justify-between w-full">
              {students.map(student => (
                <AvatarTag
                  key={student.id}
                  className={{ base: 'odd:pb-2 h-fit aspect-square' }}
                  user={student}
                />
              ))}
            </div>
          </ConditionalRenderer>
        </Fragment>
      </ConditionalRenderer>
    </div>
  );
}

type AvatarTagProps = {
  user?: User;
  className?: ClassNameComponent;
};

function AvatarTag({ user, className }: AvatarTagProps) {
  if (!user) return null;

  return (
    <Link
      to={ROUTES.PROFILE.INFO(user.username)}
      className={twMerge(
        'flex flex-col items-center gap-0.5 h-fit z-10 relative',
        className?.base,
      )}
    >
      <Tag
        text={user.firstName}
        color={user.userType === UserTypeEnum.STUDENT ? 'accent' : 'primary'}
        className="text-[0.5rem] rounded-full px-1 py-0.5 absolute z-10 -top-0.5"
      />
      <UserAvatar userId={user.id} />
    </Link>
  );
}
