import ActivityConfig from './ActivityConfig';
import ActivityElement from './ActivityElement';
import { Lesson } from './Lesson';
import Tool from './Tool';
import { ScheduledLessonTypeEnum } from './ScheduledLesson';
import { VersioningStatusEnum } from '@/enums/VersioningStatus';

export default interface Activity {
  id: number;
  name: string;
  questions: ActivityElement[];
  content?: ActivityElement[];
  hasGrade?: boolean;
  author: number;
  inBank: boolean;
  isPublicInBank: boolean;
  grade?: number;
  isDone: boolean;
  isActive?: boolean;
  config?: ActivityConfig;
  lesson?: number[];
  status: VersioningStatusEnum;
  tools: Tool[];
}

export interface ActivityMeta {
  id: number;
  originCourse: string;
  lessonsCount: number;
}

export interface EmptyActivity {
  id: number;
  lesson: number[];
  name: string;
}

export interface EmptyActivityWithLesson extends EmptyActivity {
  lessonInfo: Lesson & { type?: ScheduledLessonTypeEnum; order?: number };
}

export enum ActivityTypeEnum {
  QUIZ = 'QUIZ',
  TEXT = 'TEXT',
  VIDEO = 'VIDEO',
  CODE = 'CODE',
  TRUE_OR_FALSE = 'TRUE_OR_FALSE',
  DND_ORDENATION = 'DND_ORDENATION',
  DND_POSITIONAL = 'DND_POSITIONAL',
  CODE_EDITOR = 'CODE_EDITOR',
}

export function implementsOfActivity(obj: any): obj is Activity {
  if (!obj) return false;
  return 'id' in obj && 'name' in obj && 'questions' in obj && 'status' in obj;
}
