import {
  inventoryCategories,
  inventoryCategoriesKeys,
} from '../../utils/userAvatarUtils';
import { AvatarItemsEnum } from '../../models/Avatar';
import { useTranslation } from 'react-i18next';

export default function InventoryShopTabs({
  selectedCategory,
  selectedSubcategory,
  setCategory,
  setSubcategory,
  isInventory,
}: {
  selectedCategory: inventoryCategoriesKeys;
  selectedSubcategory: AvatarItemsEnum;
  setCategory: (newValue: inventoryCategoriesKeys) => void;
  setSubcategory: (newValue: AvatarItemsEnum) => void;
  isInventory: boolean;
}) {
  const { t } = useTranslation('translation', {
    keyPrefix: 'inventory',
  });
  const itemCategory = Object.keys(inventoryCategories);
  const getClassNameCategory = (isActive: boolean) => {
    if (isInventory) {
      if (isActive) {
        return 'border-accent text-accent';
      } else return 'border-accent-content text-accent';
    } else if (isActive) {
      return 'border-warning text-warning';
    } else return 'border-warning-content text-warning';
  };
  const getClassNameSubcategory = (isActive: boolean) => {
    if (isInventory) {
      if (isActive) {
        return 'text-base-100 bg-accent';
      } else return 'bg-accent-content text-accent';
    } else if (isActive) {
      return 'text-base-100 bg-warning';
    } else return 'bg-warning-content text-warning';
  };

  return (
    <div className="flex flex-col pb-4 gap-3">
      <ul
        className="flex px-2 py-0 m-0 list-none w-full justify-between scrollbar-h-[2px] scrollbar-thin scrollbar-thumb-primary/40
            scrollbar-track-primary-content scrollbar-thumb-rounded-full scrollbar-track-rounded-full overflow-x-scroll"
      >
        {itemCategory.map((category, index) => (
          <li
            className={`cursor-pointer ${getClassNameCategory(
              selectedCategory === category,
            )} py-1 px-2 w-full border-b-2  transition-all`}
            key={index}
            data-testid={`${category}Category`}
            onClick={() => {
              setCategory(category as inventoryCategoriesKeys);
              setSubcategory(
                inventoryCategories[category as inventoryCategoriesKeys][0],
              );
            }}
          >
            {t(`itemCategory.${category}`)}
          </li>
        ))}
      </ul>
      <ul className="flex px-2 gap-2 items-center list-none">
        {inventoryCategories[selectedCategory as inventoryCategoriesKeys].map(
          (subcategory, index) => {
            return (
              <li
                data-testid={`${subcategory}Subecategory`}
                key={index}
                className={`cursor-pointer ${getClassNameSubcategory(
                  selectedSubcategory === subcategory,
                )}
               py-0 px-4 text-14 rounded-full transition-all`}
                onClick={() => {
                  setSubcategory(subcategory);
                }}
              >
                {t(`inventoryItems.${subcategory}`)}
              </li>
            );
          },
        )}
      </ul>
    </div>
  );
}
