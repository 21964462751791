import { Wellcome } from '@/components/common/Wellcome';
import User from '@/models/User';

type GuestPageProps = {
  user: User;
};

export function GuestPage({ user }: GuestPageProps) {
  return (
    <main className="flex flex-col gap-4">
      <Wellcome userType={user.userType} firstName={user.firstName} />
      <div className="flex flex-wrap items-center gap-6 justify-center 2xl:justify-start">
        <img src="/images/DashboardAlternative1.svg" alt="" />
        <img src="/images/DashboardAlternative2.svg" alt="" />
        <img src="/images/DashboardAlternative3.svg" alt="" />
      </div>
    </main>
  );
}
