import { AxiosResponse } from 'axios';
import requester from '@/axios';
import Book, { bookType } from '@/models/Book';
import { bookEndpoints } from '@/utils/setBookType';

export async function getBook(bookId: number) {
  const { data } = await requester().get<Book>(`books/${bookId}/`);

  return data;
}

export interface BookParams {
  lessonId: number;
  bookType: bookType;
}
export interface GetBookParams extends BookParams {
  bookId: number;
}

export const handleBookEndpoint = (bookType: bookType) => {
  const endpoint = bookEndpoints[bookType];

  if (!endpoint) {
    throw new Error('Invalid endpoint');
  }
  return endpoint;
};

export async function deleteBook(params: GetBookParams) {
  const { lessonId, bookType, bookId } = params;

  const endpoint = handleBookEndpoint(bookType);

  const url = `/lessons/${lessonId}/${endpoint}/${bookId}/`;

  await requester().delete(url);
}

interface CreateBook {
  filename: string;
  rawZip: File;
}

export async function createBook(params: BookParams, body?: CreateBook) {
  const { lessonId, bookType } = params;
  const bookRoute = bookType === 'STUDENT' ? 'books' : 'teacher-books';
  const formData = new FormData();
  formData.append('lesson', `${lessonId}`);
  formData.append('bookType', bookType);

  if (body) {
    Object.entries(body).forEach(([key, value]) => {
      if (value) {
        formData.append(key, value);
      }
    });
  }

  const { data: book } = await requester().post<Book>(
    `lessons/${lessonId}/${bookRoute}/`,
    formData,
    {
      headers: {
        'Content-Type': 'multipart/form-data',
        accept: 'application/json',
      },
    },
  );

  return book;
}

export type UpdateBookBody = Partial<Pick<Book, 'status'>>;

export async function updateBook(params: GetBookParams, body: UpdateBookBody) {
  const { lessonId, bookType, bookId } = params;
  type Response = AxiosResponse<Book>;
  const endpoint = handleBookEndpoint(bookType);

  const formData = new FormData();

  Object.entries(body).forEach(([key, value]) => {
    if (value) {
      formData.append(key, value);
    }
  });

  const { data: book } = await requester().patch<unknown, Response, FormData>(
    `lessons/${lessonId}/${endpoint}/${bookId}/`,
    formData,
    {
      headers: {
        'Content-Type': 'multipart/form-data',
        accept: 'application/json',
      },
    },
  );

  return book;
}
