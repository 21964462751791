import { AvatarItemsEnum } from '../../models/Avatar';
import ConditionalRenderer from '../common/ConditionalRenderer';

interface AvatarItemProps {
  category: AvatarItemsEnum;
  img: string;
  color?: string;
  relative?: boolean;
}

export default function AvatarItem({
  category,
  img,
  relative,
  color = 'white',
}: AvatarItemProps) {
  const getItemIndex = () => {
    switch (category) {
      case AvatarItemsEnum.base:
        return '';
      case AvatarItemsEnum.face:
      case AvatarItemsEnum.shirt:
        return 'z-10';
      case AvatarItemsEnum.pants:
      case AvatarItemsEnum.hair:
        return 'z-20';
      case AvatarItemsEnum.coat:
      case AvatarItemsEnum.hat:
      case AvatarItemsEnum.shoe:
      case AvatarItemsEnum.bottomFaceAcessory:
        return 'z-30';
      case AvatarItemsEnum.topFaceAcessory:
      case AvatarItemsEnum.neckAcessory:
        return 'z-40';
      default:
        return 'z-50';
    }
  };

  const itemIndex = getItemIndex();
  const shouldBeRelative = category === AvatarItemsEnum.base || relative;

  return (
    <div
      data-testid={`${color}AvatarItem`}
      className={`pointer-events-none ${
        shouldBeRelative ? 'relative' : 'absolute top-0'
      } ${
        category === AvatarItemsEnum.leftHandAcessory ? '-scale-x-[1]' : ''
      } w-full h-auto aspect-square flex self-center ${itemIndex}`}
    >
      <ConditionalRenderer condition={color !== '#fff'}>
        <div
          style={{
            WebkitMaskImage: `url(${img})`,
            maskImage: `url(${img})`,
            backgroundColor: color,
            maskRepeat: 'no-repeat',
            WebkitMaskRepeat: 'no-repeat',
            maskSize: 'contain',
            WebkitMaskSize: 'contain',
            mixBlendMode: 'color',
          }}
          className={`aspect-square absolute w-full h-auto ${itemIndex}`}
        />
      </ConditionalRenderer>
      <img className="w-full h-auto aspect-square" src={img} alt="" />
    </div>
  );
}
