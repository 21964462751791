import Subheading2 from '../common/Subheading2';
import { SolidGradientCheckCircleIcon, DashedCircleIcon } from '../icons';
import { useTranslation } from 'react-i18next';
import ConditionalRenderer from '../common/ConditionalRenderer';
import UserProfile from '../../models/UserProfile';

export default function CheckLits({
  shouldRender,
  profile,
}: {
  shouldRender: boolean;
  profile?: UserProfile;
}) {
  const { t } = useTranslation('translation', { keyPrefix: 'profile' });

  const items = [
    {
      name: t('checklist.banner'),
      done: !!profile?.bannerUrl,
      slug: 'banner_url',
    },
    {
      name: t('checklist.bio'),
      done: !!profile?.bio && profile?.bio !== '',
      slug: 'bio',
    },
    {
      name: t('checklist.links'),
      done: profile?.socialMedia?.length !== 0,
      slug: 'social_media',
    },
  ];

  return (
    <ConditionalRenderer
      condition={items.filter(done => done.done).length < 3 && shouldRender}
    >
      <div
        data-testid="checklist"
        className="grow-0 flex flex-col justify-start items-start gap-5 p-6 rounded-xl shadow-default   md:w-64 w-full bg-base-100"
      >
        <Subheading2 text={t('create')} className="text-accent" />
        <ul
          data-testid="ulChecklist"
          className="grow-0 pl-0 indent-0 flex flex-col justify-start items-stretch gap-3.5 w-full"
        >
          {items.map((item, index) => (
            <li
              className="self-stretch grow-0 flex items-center flex-row justify-between"
              key={index}
              data-testid={item.done ? 'doneItem' : 'notDoneItem'}
            >
              <span
                className={`text-base-content font-rubik font-400 text-16 flex w-full justify-between ${
                  item.done ? 'line-through  opacity-40' : ''
                }`}
              >
                {item.name}
              </span>

              {item.done ? (
                <SolidGradientCheckCircleIcon className="w-4 h-4" />
              ) : (
                <DashedCircleIcon className="w-4 h-4 text-neutral/50" />
              )}
            </li>
          ))}
        </ul>
      </div>
    </ConditionalRenderer>
  );
}
