import { DateRange } from 'react-day-picker';
import { format } from 'date-fns';

export const formatDateRange = (range?: DateRange) => {
  const pattern = 'yyyy-MM-dd';

  let from;
  let to;

  if (range?.from && range?.to) {
    from = format(range.from, pattern);
    to = format(range.to, pattern);
  }

  return { from, to };
};
