import React from 'react';
import Layout from '../template/Layout';
import FrequentQuestions from './FrequentQuestions';
import AboutCurriculum from './AboutCurriculum';
import CourseTrack from './CourseTrack';
import HowCourseWorks from './HowCourseWorks';
import PageTitle from '../common/PageTitle';
import { optionsHowItWorksView } from '../../utils/optionsFrequentQuestions';

export default function HowItWorksView() {
  return (
    <Layout>
      <PageTitle
        headingText="Como funciona"
        subheadingText="Aprenda sobre como os cursos da Ctrl+Play beneficiam nossos alunos"
      />
      <div className="flex flex-col gap-8 items-center">
        <AboutCurriculum />
        <CourseTrack />
        <HowCourseWorks />
        <FrequentQuestions
          className="w-full"
          options={optionsHowItWorksView}
          title="Perguntas Frequentes"
          subtitle="Algumas das principais dúvidas que podem surgir"
        />
      </div>
    </Layout>
  );
}
