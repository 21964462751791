import { Link, useNavigate } from 'react-router-dom';
import Text from './dataDisplay/Text';
import {
  AcademicCapIcon,
  BookOpenIcon,
  CalendarIcon,
  IdentificationIcon,
  ViewGridIcon,
} from '@heroicons/react/solid';
import React from 'react';
import { CollectionIcon } from '@heroicons/react/outline';
import useCourseEditing from '../../data/hook/useCourseEditing';
import { twMerge } from 'tailwind-merge';
import truncateString from '@/utils/truncateString';

export interface breadcrumbArray {
  url: string;
  name: string;
  routeType: routeTypes;
}

type routeTypes =
  | 'home'
  | 'courses'
  | 'lessons'
  | 'admin'
  | 'teacher'
  | 'calendar'
  | 'projects';

interface AstroBreadcrumbProps {
  breadcrumbList: breadcrumbArray[];
  className?: string;
}

function RouteIcon({ routeType }: { routeType: routeTypes }) {
  const defaultSize = 'w-4 h-4';
  switch (routeType) {
    case 'home':
      return <ViewGridIcon className={defaultSize} />;
    case 'courses':
      return <AcademicCapIcon className={defaultSize} />;
    case 'lessons':
      return <BookOpenIcon className={defaultSize} />;
    case 'calendar':
      return <CalendarIcon className={defaultSize} />;
    case 'admin':
      return <IdentificationIcon className={defaultSize} />;
    case 'teacher':
      return <IdentificationIcon className={defaultSize} />;
    case 'projects':
      return <CollectionIcon className={defaultSize} />;
    default:
      return <React.Fragment />;
  }
}

export function AstroBreadcrumb({
  breadcrumbList,
  className,
}: AstroBreadcrumbProps) {
  const navigate = useNavigate();
  const { reorderScheduledLessons, preventExitWithChanges } =
    useCourseEditing();
  const BreadcrumbItems = breadcrumbList.map((element, i) => {
    const isLast = i === breadcrumbList.length - 1;
    const textColor = isLast ? 'text-base-content' : 'text-base-300';
    return (
      <li key={i}>
        <Link
          to={reorderScheduledLessons.length ? '' : element.url}
          onClick={() => preventExitWithChanges(() => navigate(element.url))}
          className={`flex gap-1.5 items-center no-underline  ${textColor} hover:text-base-content transition duration-100`}
        >
          <RouteIcon routeType={element.routeType} />
          <Text text={truncateString(element.name, 50)} format="rubik-400" />
        </Link>
      </li>
    );
  });

  return (
    <div
      className={twMerge(
        'text-14 p-0 font-rubik hidden lg:block breadcrumbs overflow-y-hidden',
        className,
      )}
    >
      <ul>{BreadcrumbItems}</ul>
    </div>
  );
}
