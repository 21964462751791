import { useState } from 'react';
import Editor, { BeforeMount, OnMount } from '@monaco-editor/react';
import { useTranslation } from 'react-i18next';
import { RefreshIcon } from '@heroicons/react/outline';
import { twMerge } from 'tailwind-merge';

import { LanguageCodeEditorEnum } from '@/models/CodeEditorActivity';
import useConfigContext from '@/data/hook/config';
import { setEditorTheme, removeAutocomplete } from './CodeEditorConfig';
import { ASTRO_CODE_EDITOR_THEME } from '@/constants';
import MainButton from '../common/buttons/MainButton';
import { useJSRuntime, usePythonRuntime } from './CodeEditor.hooks';
import { StartIcon } from '../icons';
import { scrollBarClassName } from '@/utils/scrollBarClassName';
import Skeleton from '../common/Skeleton';

export type CodeEditorProps = {
  testId?: string;
  value?: string;
  language: LanguageCodeEditorEnum;
  autocomplete?: boolean;
  onCompleteRun?(result: string): void;
  onFailRun?(error: string): void;
  onChangeCode(value?: string): void;
};

export default function CodeEditor({
  testId,
  value = '',
  language,
  autocomplete = false,
  onCompleteRun,
  onFailRun,
  onChangeCode,
}: CodeEditorProps) {
  const { t } = useTranslation('translation', {
    keyPrefix: 'codeEditor',
  });

  const [code, setCode] = useState(value);
  const [output, setOutput] = useState('');
  const { userSettings } = useConfigContext();

  const { run: runPython, loading: loadingPython } = usePythonRuntime({
    enabled: language === LanguageCodeEditorEnum.PYTHON,
    onCompleteRun,
    onFailRun,
  });

  const { run: runJavascript, loading: loadingJavascript } = useJSRuntime({
    onCompleteRun,
    onFailRun,
  });

  const runCode = async () => {
    switch (language) {
      case LanguageCodeEditorEnum.PYTHON:
        const pythonExecuted = await runPython(code);
        setOutput(pythonExecuted);
        break;
      case LanguageCodeEditorEnum.JAVASCRIPT:
        const javaScriptExecuted = await runJavascript(code);
        setOutput(javaScriptExecuted);
        break;
    }
  };

  const reset = () => {
    setOutput('');
  };

  const onBeforeMount: BeforeMount = async monaco => {
    const theme = userSettings?.theme ?? 'light';
    setEditorTheme(monaco, theme);
  };

  const onMount: OnMount = (editor, monaco) => {
    if (!autocomplete) removeAutocomplete(monaco, editor);
  };

  const loading = loadingPython || loadingJavascript;

  const onChange = (value?: string) => {
    setCode(value || '');
    onChangeCode(value || '');
  };

  return (
    <div className="flex flex-col gap-2 bg-base-100 rounded-lg p-2 border border-primary/50 w-full">
      <Editor
        loading={
          <Skeleton className="flex h-72 bg-primary-content rounded-lg" />
        }
        data-testid={testId ?? 'codeEditor'}
        onMount={onMount}
        className="h-72 border-b border-neutral-content"
        language={language.toLowerCase()}
        theme={ASTRO_CODE_EDITOR_THEME}
        value={code}
        onChange={value => onChange(value)}
        beforeMount={onBeforeMount}
        options={{
          minimap: { enabled: false },
          suggestOnTriggerCharacters: autocomplete,
          quickSuggestions: autocomplete,
          wordBasedSuggestions: autocomplete ? 'allDocuments' : 'off',
          inlineSuggest: { enabled: autocomplete },
          parameterHints: { enabled: autocomplete },
        }}
      />

      <div className="flex flex-col-reverse sm:flex-row w-full gap-2">
        <div className="flex sm:flex-col gap-2 shrink-0">
          <MainButton
            className="flex-row-reverse sm:w-full justify-between"
            icon={<StartIcon className="w-3" />}
            disabled={!code || loading}
            loading={loading}
            onClick={runCode}
            text={t('run')}
          />
          <MainButton
            className="flex-row-reverse sm:w-full justify-between"
            icon={<RefreshIcon />}
            text={t('reset')}
            onClick={reset}
            disabled={!code || loading}
          />
        </div>
        <pre
          className={twMerge(
            'grow max-h-40 border border-neutral-content rounded w-full flex p-4 text-[0.875rem] bg-base-100',
            scrollBarClassName,
          )}
        >
          {output}
        </pre>
      </div>
    </div>
  );
}
