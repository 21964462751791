import BankFiltersProvider, {
  useBankFilters,
} from '@/data/context/BankFiltersContext';
import useAuth from '@/data/hook/useAuth';
import { isSuperAdmin } from '@/functions/auth';
import Activity from '@/models/Activity';
import ActivityElement from '@/models/ActivityElement';
import { Lesson } from '@/models/Lesson';
import { Views, bankTypes } from '@/utils/modalBankUtils';
import { CogIcon, CollectionIcon } from '@heroicons/react/outline';
import { motion } from 'framer-motion';
import React, { ButtonHTMLAttributes, useState } from 'react';
import { useTranslation } from 'react-i18next';
import ConditionalRenderer from '../../ConditionalRenderer';
import MainButton from '../../buttons/MainButton';
import Modal, { ModalProps } from '../Modal';
import BackArrow from './BackArrow';
import ControlView from './ControlView';
import { FilterBank } from './FilterBank';
import { ListBankCards } from './ListBankCards';
import { SelectType } from './SelectType';

type UseFunction<T> = (item: T) => Promise<void> | void;

export type UseFunctions = {
  onUseActivity?: UseFunction<Activity>;
  onUseLesson?: UseFunction<Lesson>;
  onUseQuestion?: UseFunction<ActivityElement>;
};
type ModalBankContentProps = {
  initial: bankTypes;
  loading?: boolean;
} & UseFunctions;

export type ModalBankProps = ModalBankContentProps &
  Omit<ModalProps, 'children'>;

export default function ModalBank({
  initial,
  onUseActivity,
  onUseLesson,
  onUseQuestion,
  loading,
  ...modalProps
}: ModalBankProps) {
  const modalBankProps = {
    initial,
    onUseActivity,
    onUseLesson,
    onUseQuestion,
    loading,
  };

  return (
    <BankFiltersProvider>
      <Modal
        className="overflow-y-hidden h-[90vh] max-w-[80vw]"
        {...modalProps}
      >
        <ConditionalRenderer condition={modalProps.visible}>
          <ModalBankContent {...modalBankProps} />
        </ConditionalRenderer>
      </Modal>
    </BankFiltersProvider>
  );
}

function ModalBankContent({
  initial,
  onUseActivity,
  onUseLesson,
  onUseQuestion,
  loading,
}: ModalBankContentProps) {
  const { reset } = useBankFilters();
  const [view, setView] = useState<Views>('list');
  const { t } = useTranslation('translation', {
    keyPrefix: 'modalBank',
  });

  const { user } = useAuth();

  const [activeContext, setActiveContext] = useState<bankTypes>(initial);
  const leftWidthAnimated = view === 'add' ? '50px' : '160px';

  const onClickManage = () => {
    setView('add');
    if (!isSuperAdmin(user?.userType)) {
      reset({ author: ['my'], inBank: undefined }, 'search');
    } else {
      reset({ inBank: undefined }, 'search');
    }
  };

  const onBack = () => {
    reset({ inBank: true }, 'search', 'author');
    setView('list');
  };

  return (
    <div className="relative flex w-full gap-6">
      <motion.aside
        className="border-r-2 border-base-200 pr-6 overflow-hidden sticky top-0 h-full"
        animate={{
          width: leftWidthAnimated,
        }}
      >
        <ControlView
          view={view}
          renders={{
            list: (
              <React.Fragment>
                <SelectType
                  activeContext={activeContext}
                  onSelected={setActiveContext}
                />
                <FilterBank />
                <MainButton
                  text={t('manage')}
                  icon={<CogIcon />}
                  className="w-full whitespace-nowrap"
                  onClick={onClickManage}
                />
              </React.Fragment>
            ),
            add: <BackArrow onClick={onBack} />,
          }}
        />
      </motion.aside>

      <motion.section
        animate={{
          width: `calc(100% - ${leftWidthAnimated})`,
        }}
      >
        <ListBankCards
          mode={activeContext}
          checkable={view === 'add'}
          onUseActivity={onUseActivity}
          onUseLesson={onUseLesson}
          onUseQuestion={onUseQuestion}
          loading={loading}
        />
      </motion.section>
    </div>
  );
}

type ModalBankButtonProps = {
  mode: bankTypes;
} & Omit<ButtonHTMLAttributes<HTMLButtonElement>, 'color'>;

export const ModalBankButton = ({
  mode,
  ...buttonProps
}: ModalBankButtonProps) => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'modalBank.openModal',
  });
  return (
    <MainButton
      text={t(mode)}
      icon={<CollectionIcon className="w-4" />}
      {...buttonProps}
    />
  );
};
