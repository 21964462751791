import { ChevronUpIcon } from '@heroicons/react/outline';
import moment from 'moment';
import { ReactNode, useEffect, useRef, useState } from 'react';
import { formatLessonName } from '@/functions/lessonsName';
import { Lesson } from '@/models/Lesson';
import ScheduledLesson from '@/models/ScheduledLesson';
import Text from '@/components/common/dataDisplay/Text';
import ConditionalRenderer from '@/components/common/ConditionalRenderer';
import { motion } from 'framer-motion';
import Tag, { TagProps } from '@/components/common/dataDisplay/Tag';
import LessonIcon from '@/components/lessons/LessonIcon';

interface CollapsibleLessonProps {
  id?: string;
  open: boolean;
  lesson: Lesson;
  scheduledLesson?: ScheduledLesson;
  disable?: 'ENABLE' | 'DEACTIVE' | 'PAST';
  actionElement?: React.ReactNode;
  renderActionElement?: boolean;
  children?: ReactNode;
  tags?: TagProps[];
}

export default function CollapsibleLesson(props: CollapsibleLessonProps) {
  const {
    id,
    open: openLesson,
    lesson,
    scheduledLesson,
    children,
    tags,
  } = props;
  const { actionElement, disable, renderActionElement } = props;

  const { bannerImg, tools } = lesson;

  const mainTool = tools.find(tool => tool.isMain);

  const lessonBanner = mainTool?.image ?? bannerImg;

  const [height, setHeight] = useState<number | undefined>(
    openLesson ? undefined : 0,
  );

  const [isOpen, setIsOpen] = useState(openLesson);

  const ref = useRef<HTMLDivElement>(null);

  function handleDisableType() {
    switch (disable) {
      case 'DEACTIVE':
        return 'opacity-50';
      case 'PAST':
        return 'pointer-events-none opacity-20';
      default:
        return '';
    }
  }

  useEffect(() => {
    setIsOpen(openLesson);

    return () => {
      setIsOpen(false);
    };
  }, [openLesson]);

  useEffect(() => {
    if (!height || !isOpen || !ref.current) return undefined;
    const resizeObserver = new ResizeObserver(el => {
      setHeight(el[0].target.getBoundingClientRect().height);
    });
    resizeObserver.observe(ref.current);
    return () => {
      resizeObserver.disconnect();
    };
  }, [height, isOpen]);

  return (
    <div
      className={`lesson-bar rounded-2xl px-6 ${handleDisableType()} ${
        isOpen ? 'bg-secondary-content' : 'bg-base-100'
      } shadow-default w-full`}
      id={id}
    >
      <div
        className={`flex ${
          children ? 'cursor-pointer' : 'cursor-default'
        } items-center justify-between py-3 font-500`}
        onClick={() => (children ? setIsOpen(prev => !prev) : null)}
      >
        <div className="flex relative w-full justify-between items-center gap-x-7 gap-y-4">
          <div className="relative overflow-x-hidden flex flex-wrap items-center gap-x-7">
            <div className="flex overflow-x-hidden gap-x-7 items-center">
              <LessonIcon lessonBanner={lessonBanner} showProgress={false} />

              <Text
                className={`truncate ${
                  isOpen ? 'text-secondary' : 'text-primary'
                }`}
                text={formatLessonName(lesson, scheduledLesson)}
              />
            </div>
            <ConditionalRenderer condition={tags?.length}>
              <div className="flex flex-wrap gap-2">
                {tags?.map(tag => (
                  <Tag key={tag.text} {...tag} />
                ))}
              </div>
            </ConditionalRenderer>
          </div>

          <div className="flex gap-4 items-center">
            <ConditionalRenderer
              condition={scheduledLesson && disable !== 'DEACTIVE'}
            >
              <Text
                className={isOpen ? 'text-secondary' : 'text-primary'}
                text={moment(scheduledLesson?.datetime).format('DD/MM')}
              />
            </ConditionalRenderer>

            {children && !renderActionElement && (
              <button type="button" className="cursor-pointer">
                <ChevronUpIcon
                  className={`h-8 w-8 transition-all ease-in-out duration-150 ${
                    isOpen ? 'text-secondary' : '-rotate-180 text-primary'
                  }`}
                />
              </button>
            )}
            {renderActionElement && actionElement}
          </div>
        </div>
      </div>

      <motion.div
        className={`overflow-hidden transition-all ease-in-out duration-200 ${
          isOpen && 'pb-6'
        }`}
        animate={{ minHeight: height }}
      >
        <div ref={ref} onClick={e => e.stopPropagation()}>
          <motion.div
            initial={{
              height: 0,
            }}
            animate={{
              height: isOpen ? 'auto' : 0,
            }}
          >
            <ConditionalRenderer condition={isOpen}>
              {children}
            </ConditionalRenderer>
          </motion.div>
        </div>
      </motion.div>
    </div>
  );
}
