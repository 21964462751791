import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Control, Controller, SubmitHandler, useForm } from 'react-hook-form';
import { useMutation } from '@tanstack/react-query';
import { isEqual } from 'lodash';

import Klass from '@/models/Klass';
import { CommentAssessment, KlassAssessment } from '@/models/KlassAssessment';
import { SheetTab } from '@/models/SheetTab';
import {
  KlassAssessmentBody,
  createKlassAssessment,
  updateKlassAssessment,
} from '@/data/services/klassAssessmentServices';
import alert from '@/utils/UseAlert';
import { ApiError } from '@/models/Errors';
import { buildChangedObject } from '@/utils/buildChangedObject';
import { motion } from 'framer-motion';
import { fadeIn } from '@/utils/animations/commom';
import ConditionalWrapper from '@/components/common/ConditionalWrapper';
import SaveCancelGroup from '@/components/common/buttons/SaveCancelGroup';
import ConditionalRenderer from '@/components/common/ConditionalRenderer';
import TextAreaInput from '@/components/common/dataInput/TextAreaInput';

type KlassCommentAssessmentFormProps = {
  klass?: Klass;
  updateAssessments?(): Promise<void>;
  klassAssessment?: KlassAssessment;
  changeTab?(changed: SheetTab): void;
  assessmentControl?: Control<KlassAssessment>;
};

export default function CommentAssessmentForm({
  klass,
  updateAssessments,
  klassAssessment: defaultValues,
  changeTab,
  assessmentControl,
}: KlassCommentAssessmentFormProps) {
  const createMode = !defaultValues;

  const { t } = useTranslation('translation', {
    keyPrefix: 'commentAssessmentForm',
  });

  const {
    handleSubmit,
    reset,
    watch,
    control: commentControl,
  } = useForm<KlassAssessment>({ defaultValues });

  const control = assessmentControl ?? commentControl;

  const [hasChanges, setHasChanges] = useState(false);

  const onCancel = () => {
    setHasChanges(false);
    reset();
    changeTab?.('view');
  };

  const onSuccessAction = async () => {
    if (updateAssessments) await updateAssessments();
    changeTab?.('view');
    setHasChanges(false);
  };

  const { mutate: create, isLoading: loadingCreate } = useMutation(
    createKlassAssessment,
    {
      async onSuccess() {
        alert.success(t('successCreatedMessage'));
        await onSuccessAction();
      },
      onError(error: any) {
        const apiError = new ApiError(error);
        alert.error(apiError.getErrorMessage());
      },
    },
  );

  const { mutate: update, isLoading: loadingUpdate } = useMutation(
    updateKlassAssessment,
    {
      async onSuccess() {
        alert.success(t('successUpdatedMessage'));
        await onSuccessAction();
      },
      onError(error: any) {
        const apiError = new ApiError(error);
        alert.error(apiError.getErrorMessage());
      },
    },
  );

  const onSubmitUpdate: SubmitHandler<KlassAssessment> = async data => {
    if (!defaultValues?.id) throw new Error('assessment not found');

    if (defaultValues) {
      const assessmentId = defaultValues.id;

      const assessmentChanges = buildChangedObject<KlassAssessment>(
        defaultValues,
        data,
      );

      const commentChanges = buildChangedObject<CommentAssessment>(
        defaultValues.commentAssessment,
        data.commentAssessment,
      );

      const body = { ...assessmentChanges, commentAssessment: commentChanges };
      update({ assessmentId, body });
    }
  };

  const onSubmitCreate: SubmitHandler<KlassAssessment> = async ({
    commentAssessment,
  }: KlassAssessment) => {
    if (!klass) throw new Error('class not found');

    const assessment: KlassAssessmentBody = {
      commentAssessment,
      klassId: klass.id,
    };

    create(assessment);
  };

  const onSubmit = createMode ? onSubmitCreate : onSubmitUpdate;

  const className = 'flex flex-col w-full gap-3';

  useEffect(() => {
    const subscribe = watch(value =>
      setHasChanges(!isEqual(value, defaultValues)),
    );
    return () => {
      subscribe.unsubscribe();
    };
  }, [defaultValues, watch]);

  const motionForm = (
    <motion.form
      className={className}
      {...fadeIn}
      onSubmit={handleSubmit(onSubmit)}
    />
  );

  const wrapperDiv = <div className={className} />;

  const areaPlaceholder = t('commentInput.placeholder.klassCommentAbout', {
    name: klass?.name,
  });

  return (
    <ConditionalWrapper
      condition={!assessmentControl}
      wrapper={motionForm}
      fallback={wrapperDiv}
    >
      <Controller
        name="commentAssessment.comment"
        control={control}
        render={({ field: { onChange, value }, fieldState: { error } }) => (
          <TextAreaInput
            fontSize="text-14"
            label={
              assessmentControl ? t('commentInput.label.klassLabel') : undefined
            }
            testId="commentTextAreaInput"
            onChange={value => onChange(value)}
            placeholder={areaPlaceholder}
            value={value ?? ''}
            errorLabelText={error?.message}
          />
        )}
      />

      <ConditionalRenderer condition={!assessmentControl}>
        <SaveCancelGroup
          loading={loadingCreate || loadingUpdate}
          className="self-start"
          save={{
            disabled: !hasChanges,
            testId: 'saveButton',
          }}
          cancel={{
            onClick: onCancel,
            testId: 'cancelButton',
          }}
        />
      </ConditionalRenderer>
    </ConditionalWrapper>
  );
}
