import { Fragment } from 'react';

import useAuth from '@/data/hook/useAuth';
import Layout from '@/components/template/Layout';
import AdminDashboardView from './AdminDashboardView';
import TeacherDashboardView from './TeacherDashboardView';
import { UserTypeEnum } from '@/models/User';
import StudentDashboardView from './student/StudentDashboardView';
import ParentDashboardView from './ParentDashbordView';
import HeadTitle from '@/components/common/HeadTitle';

export default function DashboardPage() {
  const { user } = useAuth();

  return (
    <Layout>
      <HeadTitle />
      <RenderViewByRole role={user?.userType} />
    </Layout>
  );
}

interface RenderViewByRoleProps {
  role?: UserTypeEnum;
}

const RenderViewByRole = ({ role }: RenderViewByRoleProps) => {
  switch (role) {
    case UserTypeEnum.STUDENT:
      return <StudentDashboardView />;
    case UserTypeEnum.SUPER_ADMIN:
      return <AdminDashboardView />;
    case UserTypeEnum.UNIT_ADMIN:
      return <AdminDashboardView />;
    case UserTypeEnum.TEACHER:
      return <TeacherDashboardView />;
    case UserTypeEnum.PARENT:
      return <ParentDashboardView />;
    default:
      return <Fragment />;
  }
};
