import Skeleton from '@/components/common/Skeleton';
import { useTranslation } from 'react-i18next';
import Text from '@/components/common/dataDisplay/Text';
import { DotsVerticalIcon, EyeIcon } from '@heroicons/react/outline';
import { handleUserFullName } from '@/functions/handleUserFullName';
import IconButton from '@/components/common/buttons/IconButton';
import Popper from '@/components/common/dataDisplay/Popper';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import MainButton from '@/components/common/buttons/MainButton';
import { updateNotification } from '@/data/services/notificationServices';
import { ApiError } from '@/models/Errors';
import alert from '@/utils/UseAlert';
import { AbsenceNotification } from '@/models/AbsenceNotification';
import ScheduledLessonReport, {
  PresenceEnum,
  ScheduledLessonReportEnum,
} from '@/models/ScheduledLessonReport';
import moment from 'moment';
import LessonIconContainer from '@/components/common/LessonIconContainer';
import { presenceNotification } from '@/utils/lessonProgressNotifications';
import { Tooltip } from '@/components/common/dataDisplay/Tooltip';
import { formatLessonName } from '@/functions/lessonsName';
import ConditionalRenderer from '@/components/common/ConditionalRenderer';
import { scrollBarClassName } from '@/utils/scrollBarClassName';
import {
  absenceNotificationsQueryKeys,
  klassesQueryKeys,
  scheduledLessonReportsQueryKeys,
  scheduledLessonsQueryKeys,
  usersQueryKeys,
} from '@/data/services/querykeys';
import useListService from '@/data/hook/useListService';
import { ScheduledLessonReportFiltersParams } from '@/data/services/scheduledLessonReportServices';

interface AbsenceNotificationItemProps {
  absenceNotification: AbsenceNotification;
}

const LAST_REPORTS_PAGE_SIZE = 5;

export default function AbsenceNotificationItem({
  absenceNotification: { scheduledLessonReport, notification },
}: AbsenceNotificationItemProps) {
  const scheduledLessonId = scheduledLessonReport.scheduledLesson;
  const queryClient = useQueryClient();
  const {
    data: scheduledLesson,
    isError: isScheduledLessonError,
    isLoading: isLoadingScheduledLesson,
  } = useQuery({
    ...scheduledLessonsQueryKeys.get(scheduledLessonId),
    enabled: !!scheduledLessonId,
  });

  const studentId = scheduledLessonReport.student;
  const {
    data: student,
    isError: isStudentError,
    isLoading: isLoadingStudent,
  } = useQuery({
    ...usersQueryKeys.get(studentId),
    enabled: !!studentId,
  });

  const klassId = Number(scheduledLesson?.klass);
  const {
    data: klass,
    isError: isKlassError,
    isLoading: loadingKlass,
  } = useQuery({
    ...klassesQueryKeys.get(klassId),
    enabled: !isNaN(klassId),
  });

  const scheduledLessonReportsFilters: ScheduledLessonReportFiltersParams = {
    klass: klassId,
    pageSize: LAST_REPORTS_PAGE_SIZE,
    student: studentId,
    dateRangeBefore: moment().format('YYYY-MM-DD'),
    ordering: '-datetime',
    hasDone: true,
    status: [ScheduledLessonReportEnum.ACTIVE],
  };

  const {
    results: lastScheduledLessonReports,
    isLoading: isLoadingLastLessonReports,
  } = useListService({
    ...scheduledLessonReportsQueryKeys.list(scheduledLessonReportsFilters),
    refetchOnMount: false,
    enabled: !isNaN(klassId) && !isNaN(studentId),
  });

  const handleHasReadNotification = async () => {
    await updateNotification(notification.id, {
      hasRead: true,
    });
  };

  const { mutate: setNotificationAsRead, isLoading } = useMutation(
    handleHasReadNotification,
    {
      async onSuccess() {
        const absenceNotificationQueryKey =
          absenceNotificationsQueryKeys.list._def;
        return await queryClient.invalidateQueries(absenceNotificationQueryKey);
      },
      onError(error: any) {
        const apiError = new ApiError(error);
        alert.error(apiError.getErrorMessage());
      },
    },
  );

  const hasErrors = isStudentError || isScheduledLessonError || isKlassError;
  const isMissingInfo = !scheduledLesson || !student || !klass;
  const isLoadingInfo =
    isLoadingScheduledLesson || isLoadingStudent || loadingKlass;

  const [t] = useTranslation('translation', {
    keyPrefix: 'teacherDashboard.absenceReports',
  });

  if (!isLoadingInfo && (hasErrors || isMissingInfo)) {
    return <Text text={t('klassNotFound')} />;
  }

  return (
    <div className="flex justify-between items-center">
      <div className="flex flex-col max-w-4/5 md:max-w-[40%] lg:max-w-none">
        {isLoadingStudent ? (
          <Skeleton className="bg-slate-50 opacity-25 w-1/2 xs:w-36 rounded-md h-4 mb-1" />
        ) : (
          <Text
            format="rubik-500"
            size="text-16"
            className="line-clamp-2 lg:line-clamp-none"
            text={handleUserFullName(student)}
          />
        )}
        {loadingKlass ? (
          <Skeleton
            testId="loader"
            className="bg-slate-50 opacity-25 w-24 xs:w-44 rounded-md h-2"
          />
        ) : (
          <Text format="rubik-500" size="text-12" text={klass?.name} />
        )}
      </div>

      <LastLessonsPresence
        isLoading={isLoadingLastLessonReports}
        scheduledLessonReports={lastScheduledLessonReports}
      />

      <Popper
        referenceContent={
          <IconButton
            testId="optionsTrigger"
            icon={<DotsVerticalIcon className="h-5" />}
          />
        }
        placement="bottom-start"
        popperClassName="transform -translate-x-44 translate-y-5"
        popperContent={
          <ul
            className="min-w-[200px] rounded-lg shadow-default shadow-primary-content flex flex-col gap-5 bg-base-100 px-3 py-2 cursor-pointer"
            onClick={() => setNotificationAsRead()}
          >
            <MainButton
              icon={<EyeIcon className="stroke-3" />}
              loading={isLoading}
              text={t('markAsRead')}
              className="text-accent"
              color="custom"
            />
          </ul>
        }
        hover
      />
    </div>
  );
}

type LastLessonsPresenceProps = {
  scheduledLessonReports: ScheduledLessonReport[];
  isLoading?: boolean;
};

function LastLessonsPresence({
  scheduledLessonReports,
  isLoading = false,
}: LastLessonsPresenceProps) {
  const scheduledLessonReportsList = scheduledLessonReports.map(
    scheduledLessonReport => (
      <LessonPresence
        key={scheduledLessonReport.id}
        scheduledLessonReport={scheduledLessonReport}
      />
    ),
  );

  const loading = Array.from({ length: LAST_REPORTS_PAGE_SIZE }).map((_, i) => (
    <ScheduledLessonReportsLoadings key={i} />
  ));

  return (
    <ul
      className={`grid grid-cols-3 xs:flex gap-1 shrink-0 items-center ${scrollBarClassName}`}
    >
      {isLoading ? loading : scheduledLessonReportsList}
    </ul>
  );
}

type LessonsPresenceProps = {
  scheduledLessonReport: ScheduledLessonReport;
};

function LessonPresence({ scheduledLessonReport }: LessonsPresenceProps) {
  const [t] = useTranslation('translation', {
    keyPrefix: 'teacherDashboard.absenceReports',
  });
  const { data: scheduledLesson, isLoading } = useQuery({
    ...scheduledLessonsQueryKeys.get(scheduledLessonReport.scheduledLesson),
    enabled: !!scheduledLessonReport.scheduledLesson,
    refetchOnMount: false,
  });

  const reportHref =
    scheduledLesson &&
    `/class/${scheduledLesson?.klass}/lesson-report/${scheduledLessonReport.scheduledLesson}`;

  const lessonName = scheduledLesson
    ? formatLessonName(scheduledLesson.lesson, scheduledLesson)
    : '';

  const getTooltipMessage = () => {
    const BASE_KEY = 'lastLessonsHelpMessage';

    const translateKey =
      scheduledLessonReport.presence === PresenceEnum.MISSED
        ? PresenceEnum.MISSED
        : 'default';

    return t(`${BASE_KEY}.${translateKey}`, { lessonName });
  };

  return (
    <li className="overflow-hidden">
      <Tooltip text={getTooltipMessage()}>
        <ConditionalRenderer
          condition={!isLoading}
          fallback={<ScheduledLessonReportsLoadings />}
        >
          <LessonIconContainer
            type={'attendance'}
            size="w-5"
            href={reportHref}
            notification={presenceNotification(scheduledLessonReport.presence)}
          />
        </ConditionalRenderer>
      </Tooltip>
    </li>
  );
}

const ScheduledLessonReportsLoadings = () => (
  <Skeleton className="w-6 h-6 rounded-full bg-primary/50" />
);
