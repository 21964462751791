import { useEffect, useState } from 'react';
import { isObject, isArray } from 'lodash';
import Interpreter from 'js-interpreter';
import { useTranslation } from 'react-i18next';
import { transform } from '@babel/standalone';

import { LoadPyodide } from '@/types/global';
import alert from '@/utils/UseAlert';

type UseRuntime = {
  enabled?: boolean;
  onCompleteRun?(result: string): void;
  onFailRun?(error: string): void;
};

export function usePythonRuntime({
  enabled,
  onCompleteRun,
  onFailRun,
}: UseRuntime = {}) {
  const { t } = useTranslation('translation', {
    keyPrefix: 'codeEditor',
  });

  const [pyodide, setPyodide] = useState<LoadPyodide | null>(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    async function loadPyodideInstance() {
      try {
        setLoading(true);
        const pyodideInstance = await window.loadPyodide();
        setPyodide(pyodideInstance);
      } catch (error: any) {
        alert.warning(t('notStarted'));
      } finally {
        setLoading(false);
      }
    }

    if (enabled) loadPyodideInstance();
  }, [enabled, t]);

  async function run(code: string): Promise<string> {
    let capturedOutput = '';
    let capturedError = '';

    const originalConsoleLog = console.log;
    console.log = (...args) => {
      capturedOutput += args.join(' ') + '\n';
    };

    if (pyodide) {
      try {
        setLoading(true);
        await pyodide.runPythonAsync(code);
        onCompleteRun?.(capturedOutput.trim());
      } catch (error: any) {
        capturedError += `Error: ${error.message}`;
        onFailRun?.(capturedError);
      } finally {
        console.log = originalConsoleLog;
        const output = capturedError
          ? `Error: ${capturedError.trim()}`
          : capturedOutput.trim();

        setLoading(false);
        return output;
      }
    } else {
      return t('notStarted');
    }
  }

  return { loading, run };
}

export function useJSRuntime({ onCompleteRun, onFailRun }: UseRuntime = {}) {
  const [loading, setLoading] = useState(false);

  async function run(code: string): Promise<string> {
    let capturedOutput = '';
    let capturedError = '';

    const originalConsoleLog = console.log;
    console.log = (...args) => {
      capturedOutput += args.join(' ') + '\n';
    };

    try {
      setLoading(true);
      const initFunc = (interpreter: Interpreter, globalObject: any) => {
        const nativeToPseudo =
          interpreter.nativeToPseudo || Interpreter.nativeToPseudo;
        const pseudoToNative =
          interpreter.pseudoToNative || Interpreter.pseudoToNative;

        const consoleObj = nativeToPseudo.call(interpreter, {
          log: (...args: any[]) => {
            const nativeArgs = args.map(arg => {
              if (isArray(arg))
                return pseudoToNative.call(interpreter, JSON.stringify(arg));

              if (isObject(arg))
                return pseudoToNative.call(
                  interpreter,
                  JSON.stringify(arg, null, 2),
                );

              return pseudoToNative.call(interpreter, arg);
            });
            capturedOutput += nativeArgs.join(' ') + '\n';
          },
        });
        interpreter.setProperty(globalObject, 'console', consoleObj);
      };
      code =
        transform(code, {
          presets: ['es2015'],
        })?.code || '';
      const wrappedCode = `(function() { ${code} })()`;
      const interpreter = new Interpreter(wrappedCode, initFunc);

      while (interpreter.step()) {}
      onCompleteRun?.(capturedOutput.trim());
    } catch (error: any) {
      capturedError += 'Error: ' + error.message + '\n';
      onFailRun?.(capturedError.trim());
    } finally {
      console.log = originalConsoleLog;
      const output = capturedError
        ? `Error: ${capturedError.trim()}`
        : capturedOutput.trim();

      setLoading(false);
      return output;
    }
  }

  return {
    loading,
    run,
  };
}
