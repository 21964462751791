import { CheckIcon, MinusIcon } from '@heroicons/react/outline';
import React, { MouseEvent } from 'react';
import Badge from '@/components/common/dataDisplay/Badge';
import {
  ActivityColorIcon,
  CalendarColorIcon,
  CursorColorIcon,
  StudentBookColorIcon,
  TeacherBookColorIcon,
  ChallengeColorIcon,
  FeedbackColorIcon,
} from '../icons';
import { Tooltip } from './dataDisplay/Tooltip';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import ConditionalWrapper from './ConditionalWrapper';
import { isDoneNotification } from '@/utils/lessonProgressNotifications';
import ConditionalRenderer from './ConditionalRenderer';

export type IconType =
  | 'studentBook'
  | 'teacherBook'
  | 'activity'
  | 'challenge'
  | 'access'
  | 'attendance'
  | 'teacherFeedback'
  | 'studentFeedback';

interface LessonIconContainersProps extends LessonContentProps {
  hasTooltip?: boolean;
}

const IconTypeImport: Record<IconType, JSX.Element> = {
  studentBook: <StudentBookColorIcon />,
  teacherBook: <TeacherBookColorIcon />,
  activity: <ActivityColorIcon />,
  access: <CursorColorIcon />,
  challenge: <ChallengeColorIcon />,
  attendance: <CalendarColorIcon />,
  teacherFeedback: <FeedbackColorIcon />,
  studentFeedback: <FeedbackColorIcon />,
};

export default function LessonIconContainer(props: LessonIconContainersProps) {
  const { testId, href, type, hasTooltip } = props;
  const { t } = useTranslation('translation');
  const textsTooltip: Record<IconType, string> = {
    access: t('klassView.iconType.access'),
    activity: t('klassView.iconType.activity'),
    challenge: t('klassView.iconType.challenge'),
    studentBook: t('klassView.iconType.studentBook'),
    teacherBook: t('klassView.iconType.teacherBook'),
    attendance: t('klassView.iconType.attendance'),
    teacherFeedback: t('klassView.iconType.feedback'),
    studentFeedback: t('klassView.iconType.feedback'),
  };

  return (
    <ConditionalWrapper
      condition={!!href}
      wrapper={<Link to={href as string} data-testid={testId} />}
    >
      <ConditionalWrapper
        condition={!!hasTooltip}
        wrapper={<Tooltip text={textsTooltip[type]} />}
      >
        <LessonContent
          {...props}
          hover={!!props.onClick || !!href || props.hover}
        />
      </ConditionalWrapper>
    </ConditionalWrapper>
  );
}

interface LessonContentProps {
  className?: string;
  notification?: number;
  circleColor?: string;
  testId?: string;
  size?: string;
  hover?: boolean;
  type: IconType;
  onClick?: (e?: MouseEvent<HTMLDivElement>) => void | Promise<void>;
  href?: string;
  hidePercent?: boolean;
}

const LessonContent: React.FC<LessonContentProps> = ({
  type,
  onClick,
  notification,
  href,
  testId,
  hover,
  circleColor,
  className,
  size,
  hidePercent,
}) => {
  const percentageTypes: IconType[] = ['studentBook'];

  const isPercentage = percentageTypes.includes(type);
  const handleNotification = () => {
    if (type === 'attendance') {
      return 0;
    }

    if (isPercentage && typeof notification !== 'undefined' && !hidePercent)
      return notification === 100 ? 0 : `${notification}%`;

    return notification;
  };
  return (
    <div
      data-testid={!href ? testId : ''}
      onClick={onClick}
      className="relative"
    >
      <Badge position="-top-1 -right-1" count={handleNotification()}>
        <div
          className={`aspect-square group relative p-0.5 h-fit shrink-0 rounded-full flex items-center justify-center ${
            circleColor || 'bg-primary/30'
          } ${className}`}
        >
          <Overlay
            isPercentage={isPercentage}
            type={type}
            notification={notification}
          />

          <div
            className={`ease-out duration-300 ${
              hover ? 'group-hover:scale-125' : ''
            } ${size || 'w-9 h-9'}`}
          >
            {IconTypeImport[type]}
          </div>
        </div>
      </Badge>
    </div>
  );
};

interface OverlayProps {
  notification?: number;
  type: IconType;
  isPercentage?: boolean;
}

const Overlay = ({ notification, type, isPercentage }: OverlayProps) => {
  const isDone = isDoneNotification(notification ?? 0, isPercentage);
  const hasNotification = typeof notification !== 'undefined';
  const shouldRenderMinusIcon =
    hasNotification && type === 'attendance' && notification > 0;
  const renderClassname = () => {
    if (hasNotification) {
      if (isDone) {
        return 'bg-success/80';
      } else if (notification > 0 && type === 'attendance') {
        return 'bg-error-content/80';
      }
    }
    return 'hidden';
  };
  return (
    <div
      data-testid="overlay"
      className={`w-full h-full duration-500 rounded-full absolute flex items-center justify-center ${renderClassname()}`}
    >
      <ConditionalRenderer condition={shouldRenderMinusIcon}>
        <MinusIcon
          strokeWidth={2.5}
          className="w-[80%] h-[80%] aspect-square text-base-100"
        />
      </ConditionalRenderer>
      <ConditionalRenderer condition={isDone}>
        <CheckIcon
          strokeWidth={2.5}
          className="w-[80%] h-[80%] aspect-square text-base-100"
        />
      </ConditionalRenderer>
    </div>
  );
};
