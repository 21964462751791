import {
  createCodeActivity,
  updateCodeActivity,
} from '@/data/services/activityElement/codeServices';
import Activity from '@/models/Activity';
import {
  CodeActivityEditElement,
  CodeActivityGoal,
} from '@/models/ActivityElement';
import { ApiError } from '@/models/Errors';
import alert from '@/utils/UseAlert';
import { buildChangedObject } from '@/utils/buildChangedObject';
import { useMutation } from '@tanstack/react-query';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import CodeElementEditForm, {
  CodeFields,
  CodeFieldsKeys,
} from './CodeElementEditForm';

type onEditFn = (
  question: Partial<Omit<CodeActivityEditElement, 'goal'>> & {
    goal?: Partial<CodeActivityGoal>;
  },
) => void;

export type CodeElementEditProps = {
  question: CodeActivityEditElement;
  activity: Activity;
  onEdit: onEditFn;
  onSave: (question: CodeActivityEditElement) => void;
};

export default function CodeElementEdit({
  onSave,
  activity,
  onEdit,
  question,
}: CodeElementEditProps) {
  const { t } = useTranslation('translation', {
    keyPrefix: 'activity.manageActivity.activityCode',
  });
  const [apiError, setApiError] = useState<ApiError<CodeFieldsKeys>>();

  const onCreate = async (codeQuestion: CodeActivityEditElement) => {
    return await createCodeActivity(codeQuestion);
  };

  const onUpdate = async (data: Partial<CodeFields>) => {
    const oldQuestion = activity.questions.find(
      q => q.id === question.id,
    ) as CodeActivityEditElement;
    const changed = buildChangedObject(oldQuestion, data);
    return await updateCodeActivity(question.id, changed);
  };

  const onSubmit = async (data: CodeFields) => {
    return question.changeStatus === 'new'
      ? await onCreate({
          ...question,
          ...data,
          activity: activity.id,
        })
      : await onUpdate(data);
  };

  const { mutate: onSaveMutate, isLoading: isSaving } = useMutation(onSubmit, {
    onError: e => {
      const apiError = new ApiError<CodeFieldsKeys>(e as any);
      alert.error(
        `${t(`${apiError.fields[0]}.label`)}: ${apiError.getErrorMessage()}`,
      );
      setApiError(apiError);
    },
    onSuccess: data => {
      alert.success(t('success'));
      onSave(data);
    },
  });
  return (
    <CodeElementEditForm
      question={question}
      onSave={onSaveMutate}
      onEdit={onEdit}
      error={apiError}
      isSaving={isSaving}
    />
  );
}
