import { StarIcon as SolidStar } from '@heroicons/react/solid';
import { StarIcon as OutlineStar } from '@heroicons/react/outline';
import { BookOpenIcon as SolidBook } from '@heroicons/react/solid';
import { BookOpenIcon as OutlineBook } from '@heroicons/react/outline';
import { HeartIcon as SolidHeart } from '@heroicons/react/solid';
import { HeartIcon as OutlineHeart } from '@heroicons/react/outline';
import { EmojiHappyIcon as SolidHappy } from '@heroicons/react/solid';
import { EmojiHappyIcon as OutlineHappy } from '@heroicons/react/outline';
import { LightningBoltIcon as SolidLightning } from '@heroicons/react/solid';
import { LightningBoltIcon as OutlineLightning } from '@heroicons/react/outline';
import { ThumbUpIcon as SolidLike } from '@heroicons/react/solid';
import { ThumbUpIcon as OutlineLike } from '@heroicons/react/outline';
import React, { useState, useEffect, useRef } from 'react';

interface RatingProps {
  length: number;
  value: number;
  type?: RatingTypes;
  color?: string;
  onClickRating?: (value: number) => void;
  testId?: string;
  className?: string;
  isDisabled?: boolean | false;
}

export enum RatingTypes {
  STAR = 'Star',
  HEART = 'Heart',
  BOOK = 'Book',
  HAPPY = 'Happy',
  LIGHTNING = 'Lightning',
  LIKE = 'Like',
}

export enum ColorTypes {
  BLUE = 'text-primary',
  GREEN = 'text-success',
  YELLOW = 'text-yellow-400',
  RED = 'text-error',
  PURPLE = 'text-secondary',
  ACCENT = 'text-accent',
}

export const Rating = ({
  length,
  value,
  type,
  color,
  onClickRating,
  testId = '',
  className,
  isDisabled,
}: RatingProps) => {
  const defaultConfigs = isDisabled
    ? 'h-5 cursor-auto'
    : 'transition ease-in-out duration-200 hover:-translate-y-1 active:translate-y-1 h-5 cursor-pointer';

  const [rating, setRating] = useState<number>(0);

  const clicks = useRef(0);

  useEffect(() => {
    if (value > 0) setRating(value);
    else if (!value) setRating(0);

    if (value === 1) clicks.current = clicks.current + 1;
    return;
  }, [value]);

  return (
    <span data-testid={testId} className={`flex gap-x-1 ${className || ''}`}>
      {Array.from({ length: length }, (_, index) => index).map((_, index) => {
        index += 1;
        return (
          <span
            className="flex"
            data-testid={`${testId}RatingValue${index}`}
            key={index}
            onClick={() => {
              if (!isDisabled) {
                setRating(index);
                if (onClickRating) {
                  onClickRating(index);
                }

                if (index === 1) {
                  clicks.current = clicks.current + 1;

                  if (clicks.current >= 2) {
                    setRating(0);
                    clicks.current = 0;
                    if (onClickRating) {
                      onClickRating(0);
                    }
                  }
                }
              }
            }}
          >
            {rating >= index ? (
              <SolidIconType
                color={color || ColorTypes.YELLOW}
                defaultConfigs={defaultConfigs}
                type={type || RatingTypes.STAR}
              />
            ) : (
              <OutlineIconType
                defaultConfigs={defaultConfigs}
                type={type || RatingTypes.STAR}
              />
            )}
          </span>
        );
      })}
    </span>
  );
};

interface IconProps {
  defaultConfigs: string;
  type: RatingTypes;
}

const OutlineIconType: React.FC<IconProps> = ({ defaultConfigs, type }) => {
  const iconsDefaultProps = {
    'data-testid': 'outlineIcon',
    id: 'outlineIcon',
    className: `${defaultConfigs} text-neutral/50`,
  };
  const outlinedIcons = {
    [RatingTypes.BOOK]: <OutlineBook {...iconsDefaultProps} />,
    [RatingTypes.STAR]: <OutlineStar {...iconsDefaultProps} />,
    [RatingTypes.HEART]: <OutlineHeart {...iconsDefaultProps} />,
    [RatingTypes.HAPPY]: <OutlineHappy {...iconsDefaultProps} />,
    [RatingTypes.LIGHTNING]: <OutlineLightning {...iconsDefaultProps} />,
    [RatingTypes.LIKE]: <OutlineLike {...iconsDefaultProps} />,
  };

  return outlinedIcons[type];
};

interface SolidIconTypeProps extends IconProps {
  color: string;
}
const SolidIconType: React.FC<SolidIconTypeProps> = ({
  color,
  defaultConfigs,
  type,
}) => {
  const iconsDefaultProps = {
    'data-testid': 'solidIcon',
    id: 'solidIcon',
    className: `${defaultConfigs} ${color}`,
  };
  const solidIcons = {
    [RatingTypes.BOOK]: <SolidBook {...iconsDefaultProps} />,
    [RatingTypes.STAR]: <SolidStar {...iconsDefaultProps} />,
    [RatingTypes.HEART]: <SolidHeart {...iconsDefaultProps} />,
    [RatingTypes.HAPPY]: <SolidHappy {...iconsDefaultProps} />,
    [RatingTypes.LIGHTNING]: <SolidLightning {...iconsDefaultProps} />,
    [RatingTypes.LIKE]: <SolidLike {...iconsDefaultProps} />,
  };
  return solidIcons[type];
};
