import User from '@/models/User';

export type StudentAssessment = {
  id: number;
  pedagogicalSheetId: number;
  behaviorAssessment: BehaviorAssessment;
  commentAssessment: CommentAssessment;
  author: User;
  klassId: number;
  createdAt: Date;
};

export type BehaviorAssessment = {
  id: number;
  attention: BehaviorRatingEnum;
  organization: BehaviorRatingEnum;
  initiative: BehaviorRatingEnum;
  socialization: BehaviorRatingEnum;
  apprenticeship: BehaviorRatingEnum;
  createdAt: Date;
  updatedAt: Date;
  studentAssessmentId: number;
};

export type CommentAssessment = {
  id: number;
  comment: string;
  studentAssessmentId: number;
  createdAt: Date;
  updatedAt: Date;
};

export enum BehaviorRatingEnum {
  ONE = 1,
  TWO,
  THREE,
  FOUR,
  FIVE,
}
