import ToggleButtonGroup from '@/components/common/dataInput/ToggleGroup';
import { BookOpenIcon } from '@heroicons/react/outline';
import { DateRange } from 'react-day-picker';
import { Control, Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { ScheduledLessonOrdering } from '../../../data/services/scheduledLessonsServices';
import { ScheduledLessonFilterEnum } from '../../../enums/ScheduledLessonFilter';
import ConditionalRenderer from '../../common/ConditionalRenderer';
import Tag from '../../common/dataDisplay/Tag';
import DateRangeInput from '../../common/dataInput/DateRangeInput';
import SortToggle from '../../common/dataInput/SortToggle';

export interface ScheduledLessonHeaderDefaultValues {
  isDescending: boolean;
  dateRange: DateRange;
  filter: ScheduledLessonFilterEnum;
}

interface ScheduledLessonProgressHeaderProps {
  onSelectFilter: (mode: ScheduledLessonFilterEnum) => void;
  onToggleSort?(order: ScheduledLessonOrdering): void;
  onChangeDateRange?(dateRange: DateRange): void;
  control: Control<ScheduledLessonHeaderDefaultValues, any>;
}

export const ScheduledLessonProgressViewHeader = ({
  onSelectFilter,
  onToggleSort,
  onChangeDateRange,
  control,
}: ScheduledLessonProgressHeaderProps) => {
  const onChangeSort = (checked: boolean) => {
    onToggleSort?.(checked ? '-datetime' : 'datetime');
  };

  const { t } = useTranslation('translation', {
    keyPrefix: 'manageKlasses.dataKlassTable.scheduledLessonProgressView',
  });

  interface FilterButtons {
    text: string;
    value: ScheduledLessonFilterEnum;
  }

  const filters: FilterButtons[] = [
    {
      text: t('everyKlass'),
      value: ScheduledLessonFilterEnum.ALL_SCHEDULED_LESSONS,
    },
    {
      text: t('completedKlasses'),
      value: ScheduledLessonFilterEnum.COMPLETED_SCHEDULED_LESSONS,
    },
    {
      text: t('scheduledKlasses'),
      value: ScheduledLessonFilterEnum.ONLY_SCHEDULED_LESSONS,
    },
    {
      text: t('pendingKlasses'),
      value: ScheduledLessonFilterEnum.PENDING_SCHEDULED_LESSONS,
    },
  ];

  return (
    <div className="flex flex-col gap-2 pr-10">
      <div className="flex justify-between w-full ">
        <Tag text={t('klasses')} icon={<BookOpenIcon className="w-6 h-6" />} />
        <div className="flex gap-x-2.5">
          <Controller
            name="filter"
            control={control}
            render={({ field: { onChange, value } }) => (
              <ToggleButtonGroup
                className="h-8"
                value={value}
                buttons={filters}
                onChange={mode => {
                  onChange(mode);
                  onSelectFilter(mode);
                }}
                exclusive
              />
            )}
          />
        </div>
      </div>
      <div
        className={`flex gap-x-2.5 ${
          !!onChangeDateRange && !!onToggleSort ? 'justify-between' : ''
        }`}
      >
        <ConditionalRenderer condition={!!onChangeDateRange}>
          <Controller
            name="dateRange"
            control={control}
            render={({ field: { value, onChange } }) => (
              <DateRangeInput
                range={value}
                onChange={range => {
                  onChange(range);
                  if (range) {
                    onChangeDateRange?.(range);
                  }
                }}
              />
            )}
          />
        </ConditionalRenderer>
        <ConditionalRenderer condition={!!onToggleSort}>
          <div
            className={`flex items-center gap-3 ${
              !!onChangeDateRange ? '' : 'ml-auto'
            }`}
          >
            <Controller
              name="isDescending"
              control={control}
              render={({ field: { onChange, value } }) => (
                <SortToggle
                  id="lessonsSort"
                  isDescending={value}
                  onToggleSort={checked => {
                    onChange(checked);
                    onChangeSort(checked);
                  }}
                />
              )}
            />
          </div>
        </ConditionalRenderer>
      </div>
    </div>
  );
};
