import { useState } from 'react';
import {
  ScheduledLessonHeaderDefaultValues,
  ScheduledLessonProgressViewHeader,
} from '../klass/ScheduledLessonProgressViewHeader';
import { ScheduledLessonFilterEnum } from '@/enums/ScheduledLessonFilter';
import ManageScheduledLesson from '../calendar/ManageScheduledLesson/ManageScheduledLesson';
import ConditionalRenderer from '@/components/common/ConditionalRenderer';
import { useTranslation } from 'react-i18next';
import Text from '@/components/common/dataDisplay/Text';
import { LoadingIcon } from '@/components/icons';
import {
  ScheduledLessonFilters,
  ScheduledLessonOrdering,
} from '@/data/services/scheduledLessonsServices';
import InfinityList from '@/components/common/InfinityList';
import {
  createDateRange,
  handleScheduledLessonFilters,
} from '@/utils/handleScheduledLessonFilters';
import moment from 'moment';
import { DateRange } from 'react-day-picker';
import { useForm } from 'react-hook-form';
import User from '@/models/User';
import useInfiniteService from '@/data/hook/useInfiniteService';
import { scheduledLessonsQueryKeys } from '@/data/services/querykeys';

interface ScheduledLessonsTeacherProps {
  user: User;
}

export const ScheduledLessonsTeacher = ({
  user,
}: ScheduledLessonsTeacherProps) => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'manageKlasses.dataKlassTable.scheduledLessonProgressView',
  });

  const { dateRangeAfter, dateRangeBefore } = createDateRange({
    from: new Date(),
    to: moment().add(3, 'months').toDate(),
  });
  const initialFilters: ScheduledLessonFilters = {
    teacher: user.id,
    ordering: 'datetime',
    dateRangeAfter,
    dateRangeBefore,
  };
  const defaultValues = {
    isDescending: false,
    filter: ScheduledLessonFilterEnum.ALL_SCHEDULED_LESSONS,
    dateRange: {
      from: new Date(),
      to: moment().add(3, 'months').toDate(),
    },
  };
  const { control, setValue } = useForm<ScheduledLessonHeaderDefaultValues>({
    defaultValues,
  });
  const [filters, setFilters] = useState(initialFilters);

  const handleFilters = (filters: Omit<ScheduledLessonFilters, 'teacher'>) => {
    setFilters(({ teacher, ordering }) => ({
      ...filters,
      ordering,
      teacher,
    }));
  };
  const onChangeDateRange = (dateRange: DateRange) => {
    const { dateRangeAfter, dateRangeBefore } = createDateRange(dateRange);
    setFilters(prev => ({
      ...prev,
      dateRangeAfter,
      dateRangeBefore,
    }));
  };

  const {
    results: scheduledLessons,
    isPreviousData,
    isInitialLoading: isLoadingScheduledLessons,
    isFetching: isFetchingScheduledLessons,
    hasNextPage,
    fetchNextPage,
    isFetchingNextPage,
    invalidate: updateLessons,
  } = useInfiniteService({
    ...scheduledLessonsQueryKeys.list(filters)._ctx.infinity,
    enabled: !!user,
    keepPreviousData: true,
  });

  const onChangeOrdering = (ordering: ScheduledLessonOrdering) => {
    setFilters(prev => ({
      ...prev,
      ordering,
    }));
  };
  const onFilterScheduledLessons = (mode: ScheduledLessonFilterEnum) => {
    const newFilters = handleScheduledLessonFilters(mode, { initialFilters });
    if (mode !== ScheduledLessonFilterEnum.ALL_SCHEDULED_LESSONS) {
      setValue('dateRange', {
        from: new Date(),
      });
    } else {
      setValue('dateRange', defaultValues.dateRange);
    }
    handleFilters(newFilters);
  };

  if (isLoadingScheduledLessons) {
    return (
      <div className="flex w-full justify-center p-3.5">
        <LoadingIcon className="text-primary/40 w-10" />
      </div>
    );
  }

  return (
    <div className="flex flex-col w-full font-rubik py-5 gap-y-2.5">
      <ScheduledLessonProgressViewHeader
        onSelectFilter={onFilterScheduledLessons}
        onToggleSort={onChangeOrdering}
        onChangeDateRange={onChangeDateRange}
        control={control}
      />
      <ConditionalRenderer condition={!scheduledLessons?.length}>
        <div className="flex justify-center p-5 w-full">
          <ConditionalRenderer
            condition={isPreviousData}
            fallback={<Text text={t('noScheduledLessons')} />}
          >
            <LoadingIcon className="text-primary/40 w-10" />
          </ConditionalRenderer>
        </div>
      </ConditionalRenderer>
      <InfinityList
        className="h-96 w-full pr-2 gap-4"
        onReachEnd={fetchNextPage}
        hasNextPage={hasNextPage ?? isFetchingScheduledLessons}
        isFetchingNextPage={isFetchingNextPage}
        scroll
      >
        {scheduledLessons.map(scheduledLesson => (
          <ManageScheduledLesson
            key={scheduledLesson.id}
            updateLessons={updateLessons}
            scheduledLesson={scheduledLesson}
          />
        ))}
      </InfinityList>
    </div>
  );
};
