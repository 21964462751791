import { ChevronLeftIcon } from '@heroicons/react/outline';
import Text from './dataDisplay/Text';
import { useNavigate } from 'react-router-dom';
import BackButton from './BackButton';
import ConditionalRenderer from './ConditionalRenderer';

interface PageTitleProps {
  testId?: string;
  headingText: string;
  subheadingText?: string;
  backButton?: boolean;
  className?: string;
  position?: string;
}

export default function PageTitle(props: PageTitleProps) {
  const {
    testId,
    headingText,
    subheadingText,
    backButton,
    className,
    position,
  } = props;
  const navigate = useNavigate();
  return (
    <div
      data-testid={testId}
      className={`flex flex-col gap-2 ${position || 'mb-9'} ${className}`}
    >
      <div className="flex justify-start lg:justify-between items-center">
        <ChevronLeftIcon
          className="text-primary w-8 h-8 lg:hidden cursor-pointer"
          onClick={() => navigate(-1)}
        />
        <Text
          testId={testId + 'Title'}
          text={headingText}
          className="pl-10 lg:pl-0 text-primary"
          format="poppins-600"
          size="text-24 sm:text-28"
        />
        <ConditionalRenderer condition={backButton}>
          <span className="hidden lg:flex">
            <BackButton />
          </span>
        </ConditionalRenderer>
      </div>

      <ConditionalRenderer condition={!!subheadingText}>
        <Text text={subheadingText} />
      </ConditionalRenderer>
    </div>
  );
}
