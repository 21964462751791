import requester from '../../axios';
import { DNDColumn } from '../../models/DNDColumn';

export async function patchDndPositionalColumn(
  dndPositionalColumn: DNDColumn,
  dndPositionalColumnId?: number,
): Promise<DNDColumn> {
  const { data } = await requester().patch<DNDColumn>(
    `dnd-positional-column/${dndPositionalColumnId || dndPositionalColumn.id}/`,
    {
      name: dndPositionalColumn.name,
      flow: dndPositionalColumn.flow,
      dndOrdenationActivity: dndPositionalColumn.dragAndDropPositionalActivity,
    },
  );

  return data;
}

export async function patchDndPositionalColumnOrder(
  dndPositionalColumnId: number,
  orderList: { id: number | undefined }[],
) {
  await requester().patch(
    `dnd-positional-column/${dndPositionalColumnId}/dnd-positional-item/order/`,
    orderList,
  );
}

export async function getDndPositionalColumn(
  dndColumnId: number,
): Promise<DNDColumn> {
  const { data } = await requester().get<DNDColumn>(
    `dnd-positional-column/${dndColumnId}/`,
  );

  return data;
}
