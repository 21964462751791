import { InformationCircleIcon } from '@heroicons/react/outline';
import { PencilIcon } from '@heroicons/react/outline';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import Unit from '../../../models/Unit';
import { UserTypeEnum } from '../../../models/User';
import MainButton from '../../common/buttons/MainButton';
import ComponentGuard from '../../common/ComponentGuard';
import Tag from '../../common/dataDisplay/Tag';

import React from 'react';
import UnitForm from './UnitForm';

interface EditUnitProps {
  unit: Unit;
}

export default function Edit({ unit }: EditUnitProps) {
  const [isEdit, setIsEdit] = useState(false);
  const { t } = useTranslation('translation', {
    keyPrefix: 'adminPage',
  });
  const generateUnitWithoutRecess = ({
    recess,
    ...unit
  }: Unit): Omit<Unit, 'recess'> => {
    return unit;
  };

  return (
    <div className="flex flex-col w-full font-rubik py-5 px-8 gap-y-2.5">
      <div className="flex w-auto items-center gap-8 mb-3 ">
        <Tag
          icon={<InformationCircleIcon className="w-6 h-6" />}
          text={t('listUnits.infoTooltip')}
        />
        <ComponentGuard
          roles={[UserTypeEnum.SUPER_ADMIN, UserTypeEnum.UNIT_ADMIN]}
        >
          <MainButton
            id="editButton"
            dataTestId="editButton"
            disabled={isEdit}
            onClick={() => setIsEdit(!isEdit)}
            className="text-primary"
            icon={<PencilIcon />}
            text={t('listUnits.editTooltip')}
            color="custom"
          />
        </ComponentGuard>
      </div>
      <UnitForm
        formMode="edit"
        onCancel={() => setIsEdit(false)}
        unit={generateUnitWithoutRecess(unit)}
        className="w-[60%] mb-5"
        disabled={!isEdit}
      />
    </div>
  );
}
