import { motion } from 'framer-motion';
import QuickFilter, {
  QuickFilterProps as QuickFilterItem,
} from './QuickFilter';

type QuickFiltersProps = {
  quickFilters: QuickFilterItem[];
};

export default function QuickFilters({ quickFilters }: QuickFiltersProps) {
  return (
    <motion.ul className="flex flex-wrap gap-2 justify-end w-[50%]">
      {quickFilters.map(quickFilter => (
        <QuickFilter
          key={quickFilter.type}
          type={quickFilter.type}
          filters={quickFilter.filters}
        />
      ))}
    </motion.ul>
  );
}
