import ConditionalRenderer from '@/components/common/ConditionalRenderer';
import { CheckIcon, XIcon } from '@heroicons/react/outline';
import { useTranslation } from 'react-i18next';
import { ButtonHTMLAttributes } from 'react';

type TrueOrFalseButtonProps = ButtonHTMLAttributes<HTMLButtonElement> & {
  id?: string;
  variant: boolean;
  className?: string;
  disabled?: boolean;
  selectedValue?: boolean;
};

const selectedButtonText: string = 'text-base-100';
const notSelectedButtonBg: string = 'bg-base-100';

const notSelectedClasses: object = {
  true: `border-success text-success ${notSelectedButtonBg} enabled:hover:bg-success-content focus-visible:ring-success-content`,
  false: `border-error text-error ${notSelectedButtonBg} enabled:hover:bg-error-content focus-visible:ring-error-content`,
};

const selectedClasses: object = {
  true: `border-success bg-success ${selectedButtonText} focus-visible:ring-success-content`,
  false: `border-error bg-error text-base-100 focus-visible:ring-error-content`,
};

export default function TrueOrFalseButton({
  variant,
  selectedValue,
  ...props
}: TrueOrFalseButtonProps) {
  const { t } = useTranslation('translation', {
    keyPrefix: 'activity.manageActivity.activityTrueFalse',
  });

  const index = String(variant);
  return (
    <button
      {...props}
      id={props.id}
      disabled={props.disabled ?? false}
      className={`leading-none border flex rounded-full w-full sm:w-72 text-16  px-7 py-2.5 md:py-3 gap-6 items-center font-rubik duration-150 transition disabled:cursor-default focus-visible:ring focus:outline-none ${
        selectedValue === variant
          ? selectedClasses[index as keyof typeof selectedClasses]
          : notSelectedClasses[index as keyof typeof notSelectedClasses]
      } ${props.className} ${props.disabled ? 'disabled' : ''}`}
    >
      <div className="w-6 h-6 md:w-8 md:h-8">
        <ConditionalRenderer
          condition={variant}
          fallback={<XIcon strokeWidth={1.5} />}
        >
          <CheckIcon strokeWidth={1.5} />
        </ConditionalRenderer>
      </div>
      {variant ? t('true') : t('false')}
    </button>
  );
}
