import React, { useState } from 'react';
import { BackpackIcon } from '../icons';
import Skeleton from '../common/Skeleton';
import IconButton from '../common/buttons/IconButton';
import InventoryModal from './InventoryModal';
import ConditionalRenderer from '../common/ConditionalRenderer';
import { useAvatar } from '../../data/context/AvatarContext';
import UserAvatar from './UserAvatar';
interface ViewAvatarProps extends React.HtmlHTMLAttributes<HTMLDivElement> {
  userId: number;
  isLoading?: boolean;
  visiting?: boolean;
  testId?: string;
}

export default function ViewAvatar({
  className,
  userId,
  isLoading,
  visiting,
  testId,
}: ViewAvatarProps) {
  const [openModal, setOpenModal] = useState(false);
  const { userAvatar } = useAvatar();

  if (isLoading)
    return (
      <Skeleton className="bg-primary-content h-full w-full rounded-2xl relative" />
    );

  return (
    <div
      style={{
        backgroundImage: `url('/images/cenario.png')`,
      }}
      className={`bg-cover bg-center bg-no-repeat rounded-2xl items-center justify-center flex relative ${
        className || 'h-full w-full'
      }`}
      data-testid={testId}
    >
      <ConditionalRenderer condition={!visiting && !!userAvatar}>
        <IconButton
          type="button"
          testId="inventoryButton"
          className="flex justify-centers border-2 border-base-100 items-center cursor-pointer absolute z-40 right-1 bottom-1 bg-secondary rounded-full p-0.5 lg:p-1"
          icon={
            <BackpackIcon className="w-5 h-5 lg:w-6 text-base-100 lg:h-6" />
          }
          onClick={() => setOpenModal(!openModal)}
        />
        {userAvatar && (
          <InventoryModal
            visible={openModal}
            onClose={() => setOpenModal(false)}
            userAvatar={userAvatar}
          />
        )}
      </ConditionalRenderer>
      <UserAvatar className="scale-150 xs:scale-[200%]" userId={userId} />
    </div>
  );
}
