import React from 'react';
import Text from '../common/dataDisplay/Text';

interface FaqCollapseProps {
  header: string;
  content: string;
}

export default function FaqCollapse({ header, content }: FaqCollapseProps) {
  return (
    <div className="collapse collapse-arrow bg-secondary-content text-secondary items-center rounded-lg w-full md:max-w-lg">
      <input type="checkbox" className="min-h-6" />
      <div className="collapse-title min-h-6 py-2 flex items-center">
        <Text format="rubik-500" text={header} />
      </div>
      <div className="collapse-content">
        <Text
          className="bg-base-100/80 p-2.5 rounded-md text-[#3e247b]"
          text={content}
        />
      </div>
    </div>
  );
}
