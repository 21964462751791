import { Link } from 'react-router-dom';

import { formatLessonName } from '@/functions/lessonsName';
import { Lesson } from '@/models/Lesson';
import ScheduledLesson from '@/models/ScheduledLesson';
import { PresenceEnum } from '@/models/ScheduledLessonReport';
import { Tooltip } from '@/components/common/dataDisplay/Tooltip';

type LessonItemProps = {
  lesson: Lesson;
  url: string;
  scheduledLesson?: ScheduledLesson;
  presence?: PresenceEnum | null;
};

export function LessonView({
  lesson,
  scheduledLesson,
  url,
  presence,
}: LessonItemProps) {
  const lessonName = formatLessonName(lesson, scheduledLesson);

  const presenceStatus: {
    [key in PresenceEnum]: string;
  } = {
    ATTENDED: 'bg-accent',
    MISSED: 'bg-error',
    RESCHEDULED: 'bg-warning',
    NONE: 'bg-accent/40',
  };

  return (
    <li>
      <Tooltip text={lessonName} color="accent">
        <Link to={url} data-testid="lessonItem">
          <span
            className={`flex w-3 h-3 sm:w-5 sm:h-5 hover:opacity-25 transition duration-200 ease-in-out ${
              presenceStatus[presence || PresenceEnum.NONE]
            }`}
          />
        </Link>
      </Tooltip>
    </li>
  );
}
