import { useEffect, useMemo, useState } from 'react';
import { debounce } from 'lodash';

import { useTabsPanelContext } from '../common/dataDisplay/Tabs/Tabs';
import CodeEditor, { CodeEditorProps } from './CodeEditor';

export default function TriggerSizeCodeEditor(props: CodeEditorProps) {
  const { triggerListSize } = useTabsPanelContext();
  const [resizeKey, setResizeKey] = useState(0);

  const memoedDebounce = useMemo(
    () => debounce((size: number) => setResizeKey(size), 100),
    [],
  );

  useEffect(() => {
    memoedDebounce(triggerListSize.width);

    return () => memoedDebounce.cancel();
  }, [memoedDebounce, triggerListSize.width]);

  return <CodeEditor {...props} key={`editor${resizeKey}`} />;
}
