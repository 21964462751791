import requester from '@/axios';
import JsonApiResponse from '@/models/JsonApiResponse';
import { BaseFilters } from '@/models/Service';
import Tag from '@/models/Tag';

export type ListTagFilter = {
  areaId?: number[];
  ordering?: string;
  id?: number[];
} & BaseFilters;

export const listTags = async (params: ListTagFilter = {}) => {
  const { data } = await requester().get<JsonApiResponse<Tag>>('tags/', {
    params,
  });

  return data;
};

export const createTag = async (tag: Omit<Tag, 'id'>) => {
  const { data } = await requester().post<Tag>('tags/', tag);
  return data;
};

export const updateTag = async (id: number, tag: Partial<Omit<Tag, 'id'>>) => {
  const { data } = await requester().patch<Tag>(`tags/${id}/`, tag);
  return data;
};

export const deleteTag = async (id: number) => {
  await requester().delete(`tags/${id}/`);
};

export const getTag = async (id: number) => {
  const { data } = await requester().get<Tag>(`tags/${id}/`);
  return data;
};
