import MainButton from '@/components/common/buttons/MainButton';
import { tagsQueryKeys } from '@/data/services/querykeys';
import Area from '@/models/Area';
import { PlusIcon } from '@heroicons/react/outline';
import { useQuery } from '@tanstack/react-query';
import { useState } from 'react';
import ModalNewTag from './ModalNewTag';
import TagComponent from './Tag';
import { useTranslation } from 'react-i18next';

type TagsListProps = {
  area: Area;
};
export function TagsList({ area }: TagsListProps) {
  const [isVisibleModal, setIsVisibleModal] = useState(false);
  const { t } = useTranslation('translation', {
    keyPrefix: 'topics.tags',
  });

  const { data: tags } = useQuery({
    ...tagsQueryKeys.list({ areaId: [area.id] }),
    enabled: true,
  });

  return (
    <div className="flex flex-col gap-2.5">
      <ul className="flex flex-col gap-2.5">
        {tags?.results.map(tag => (
          <TagComponent key={tag.id} tag={tag} />
        ))}
      </ul>
      <MainButton
        text={t('addDifficulty')}
        icon={<PlusIcon />}
        className="bg-secondary"
        onClick={() => setIsVisibleModal(true)}
      />
      <ModalNewTag
        area={area}
        visible={isVisibleModal}
        onClose={() => setIsVisibleModal(false)}
      />
    </div>
  );
}
