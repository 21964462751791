import useInfiniteService from '@/data/hook/useInfiniteService';
import { areasQueryKeys } from '@/data/services/querykeys';
import TopicsContainer from '../TopicsContainer';
import TopicsTreeList from './TopicsTreeList';
import ConditionalRenderer from '@/components/common/ConditionalRenderer';
import { useTranslation } from 'react-i18next';
import { LoadingIcon } from '@/components/icons';

export default function TopicsTreeRoot() {
  const { t } = useTranslation('translation', {
    keyPrefix: 'topics.tree',
  });

  const {
    results: areas,
    isInitialLoading,
    ...infinityProps
  } = useInfiniteService(
    areasQueryKeys.list({
      isRoot: true,
    })._ctx.infinity,
  );

  return (
    <TopicsContainer className="relative w-1/5 min-w-[250px] max-w-[500px] min-h-24">
      <ConditionalRenderer condition={!areas.length && !isInitialLoading}>
        <div className="w-full h-full flex justify-center items-center">
          {t('createNew')}
        </div>
      </ConditionalRenderer>
      <ConditionalRenderer condition={isInitialLoading}>
        <div className="w-full h-full flex justify-center items-center">
          <LoadingIcon className="w-5 h-5 text-primary" />
        </div>
      </ConditionalRenderer>
      <TopicsTreeList items={areas} isRoot {...infinityProps} />
    </TopicsContainer>
  );
}
