import { UserTypeEnum } from '@/models/User';
import { PropsWithChildren, createContext, useContext, useState } from 'react';

export const AuthorFieldsArray = [
  UserTypeEnum.TEACHER,
  UserTypeEnum.SUPER_ADMIN,
  'my',
];

export type AuthorFields = (typeof AuthorFieldsArray)[number];

export type FilterFields = {
  unit: string[];
  author: AuthorFields[];
  tool: string[];
  search: string;
  courseAbbreviation: boolean;
  inBank: boolean;
};
type BankFiltersContextType = {
  filters: FilterFields;
  setFilters: (filters: FilterFields) => void;
  setFilter: <K extends keyof FilterFields>(
    key: K,
    value: FilterFields[K],
  ) => void;
  reset: (
    filters: Partial<FilterFields>,
    ...ignoreKeys: (keyof FilterFields)[]
  ) => void;
};

const BankFiltersContext = createContext<BankFiltersContextType>(
  {} as BankFiltersContextType,
);

const BankFiltersProvider = ({ children }: PropsWithChildren<{}>) => {
  const initialFilters = {
    unit: [],
    author: [],
    tool: [],
    search: '',
    courseAbbreviation: false,
    inBank: true,
  };
  const [filters, setFilters] = useState<FilterFields>(initialFilters);

  const setFilter: BankFiltersContextType['setFilter'] = (key, value) =>
    setFilters({ ...filters, [key]: value });

  const reset = (
    filters: Partial<FilterFields>,
    ...ignoreKeys: (keyof FilterFields)[]
  ) => {
    const newInitialIgnored = initialFilters;
    ignoreKeys.forEach(key => {
      delete newInitialIgnored[key];
    });
    setFilters(oldFilters => ({
      ...oldFilters,
      ...newInitialIgnored,
      ...filters,
    }));
  };

  return (
    <BankFiltersContext.Provider
      value={{
        filters,
        setFilters,
        setFilter,
        reset,
      }}
    >
      {children}
    </BankFiltersContext.Provider>
  );
};

export default BankFiltersProvider;

export const useBankFilters = () => {
  return useContext(BankFiltersContext);
};
