import { twMerge } from 'tailwind-merge';

import Text from './Text';
import { ClassNameComponent } from '@/utils/ClassNameComponent';

type IconWithTextProps = {
  icon: JSX.Element;
  text: string;
  className?: ClassNameComponent;
};

export function IconWithText({ icon, text, className }: IconWithTextProps) {
  return (
    <div
      className={twMerge(
        'flex flex-row self-start items-center gap-2.5',
        className?.base,
      )}
    >
      {icon}
      <Text
        text={text}
        format="rubik-500"
        className={twMerge('text-16', className?.text)}
      />
    </div>
  );
}
