import { AxiosError } from 'axios';
import React, { useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import requester from '../../axios';
import RoundedButton from '../../components/common/buttons/RoundedButton';
import Text from '../../components/common/dataDisplay/Text';
import PasswordInput from '../../components/common/dataInput/PasswordInput';
import LinkText from '../../components/common/LinkText';
import ResetPasswordModal from '../../components/login/ResetPasswordModal';
import HeadTitle from '../../components/common/HeadTitle';

interface Inputs {
  newPassword: string;
  reNewPassword: string;
}

interface IRequestBody extends Inputs {
  uid: string;
  token: string;
}

function ResetPassword() {
  const { t } = useTranslation('translation', {
    keyPrefix: 'login.resetPassword',
  });
  const { t: tErrors } = useTranslation('translation', {
    keyPrefix: 'errors',
  });
  const { token, uid } = useParams();
  const [isRequesting, setIsRequesting] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const { register, handleSubmit, reset } = useForm<Inputs>();
  const navigate = useNavigate();

  const onSubmit: SubmitHandler<Inputs> = async ({
    newPassword,
    reNewPassword,
  }) => {
    try {
      setErrorMessage(null);
      setIsRequesting(true);
      if (!token || !uid) {
        throw new Error('Token ou uid não encontrados');
      }
      if (newPassword !== reNewPassword) {
        return setErrorMessage(t('differentPasswordError'));
      }
      await requester().post<void, null, IRequestBody>(
        'users/reset_password_confirm/',
        {
          newPassword,
          reNewPassword,
          token,
          uid,
        },
      );
      reset();
      setOpenModal(true);
    } catch (err) {
      const error = err as AxiosError<{ message: [] }>;
      let errorPasswordPrefix = '';
      error.response?.data.message?.map((errorMessage: Object) => {
        return Object.entries(errorMessage).forEach(([key]) => {
          errorPasswordPrefix += tErrors(key);
          errorPasswordPrefix += '; ';
        });
      });
      setErrorMessage(errorPasswordPrefix);
    } finally {
      setIsRequesting(false);
    }
  };

  return (
    <div className="flex flex-col">
      <HeadTitle routeInfo={t('redefinePassword')} />
      <Text
        className="text-primary text-center pb-1"
        text={t('redefinePassword')}
        format="poppins-600"
        size="text-28"
      />
      <span className="mb-10">
        {errorMessage !== null && (
          <Text
            size="text-16"
            format="rubik-400"
            text={errorMessage}
            className="text-error bg-error-content px-3 py-1 text-center self-center rounded-md"
          />
        )}
      </span>
      <form
        className="flex flex-col gap-6"
        data-testid="resetPasswordForm"
        onSubmit={handleSubmit(onSubmit)}
      >
        <PasswordInput
          placeholder={t('typeNewPassword')}
          register={register('newPassword')}
          aria-label="new-password"
          name="newPassword"
          label={t('newPassword')}
          typeInput="login"
          className={{ input: 'mx-auto' }}
          fontSize="text-14"
        />
        <PasswordInput
          placeholder={t('typeNewPassword')}
          register={register('reNewPassword')}
          aria-label="confirm-new-password"
          name="reNewPassword"
          typeInput="login"
          className={{ input: 'mx-auto' }}
          label={t('confirmNewPassword')}
          fontSize="text-14"
        />
        <RoundedButton
          loading={isRequesting}
          className="mx-auto whitespace-nowrap"
          text={t('confirmRedefine')}
          color="gradient"
          type="submit"
        />
      </form>
      <hr className="my-5 h-px border-neutral/50" />
      <div className="flex gap-2 items-center">
        <Text text={t('alreadyRegister')} format="rubik-400" />
        <LinkText text={t('login')} href="/login" />
      </div>
      {openModal && <ResetPasswordModal onClose={() => navigate('/login')} />}
    </div>
  );
}

export default ResetPassword;
