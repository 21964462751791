import requester from "../../axios";
import DNDItemProgress from "../../models/DNDItemProgress";

export async function updateDndPositionalColumnProgress(
  dndPositionalColumnProgressId: number,
  itemProgressList: DNDItemProgress[],
) {
  await requester().patch(
    `/dnd-positional-column-progress/${dndPositionalColumnProgressId}/dnd-positional-item-progress/order/`,
    itemProgressList,
  );
}
