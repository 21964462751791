import IconButton from '@/components/common/buttons/IconButton';
import { MoveAreaIcon } from '@/components/icons';
import useToggle from '@/data/hook/useToggle';
import Area from '@/models/Area';
import { accordion } from '@/utils/animations/commom';
import { PencilIcon, TagIcon } from '@heroicons/react/outline';
import { AnimatePresence, motion } from 'framer-motion';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { twMerge } from 'tailwind-merge';
import AreaRename from '../../actions/AreaRename';
import DeleteArea from '../../actions/DeleteArea';
import MoveTo from '../../actions/MoveTo';
import { TagsList } from '../tag/TagsList';

export type LeafAreaProps = {
  area: Area;
  active?: boolean;
};
export default function LeafArea({ area, active }: LeafAreaProps) {
  const { t } = useTranslation('translation', {
    keyPrefix: 'topics.tooltip',
  });

  const { isOpen, toggle } = useToggle(active);
  const [isRenaming, setIsRenaming] = useState(false);
  const [isMoving, setIsMoving] = useState(false);

  return (
    <li
      className={twMerge(
        'flex flex-col gap-2.5 p-2.5 border border-primary rounded-lg',
        isOpen ? 'bg-primary-content' : '',
      )}
    >
      <header className="flex justify-between items-center">
        <div
          className="flex items-center gap-2.5 flex-grow cursor-pointer"
          role="button"
          onClick={toggle}
        >
          <TagIcon className="w-6 h-6 text-primary" />
          <AreaRename
            area={area}
            key={area.name}
            isRenaming={isRenaming}
            setIsRenaming={setIsRenaming}
            wrapper={<p className="font-500 text-primary" />}
          />
        </div>
        <div className="flex gap-2.5 items-center">
          <IconButton
            tooltip={t('renaming')}
            icon={<PencilIcon className="w-5 h-5 text-primary" />}
            onClick={() => setIsRenaming(prev => !prev)}
          />
          <IconButton
            tooltip={t('move')}
            icon={<MoveAreaIcon className="w-5 h-5 text-primary" />}
            onClick={() => setIsMoving(prev => !prev)}
          />
          <DeleteArea area={area} />
        </div>
      </header>
      {isMoving && <MoveTo area={area} setIsMoving={setIsMoving} />}
      <AnimatePresence>
        {isOpen && (
          <motion.div
            {...accordion}
            transition={{ type: 'spring', stiffness: 900, damping: 40 }}
            className="overflow-hidden"
          >
            <TagsList area={area} />
          </motion.div>
        )}
      </AnimatePresence>
    </li>
  );
}
