import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import EditActivity from '@/components/admin/activities/EditActivity';
import ManageActivityEditContainer from '@/components/admin/activities/ManageActivityEditContainer';
import useCourseEditing from '@/data/hook/useCourseEditing';
import { useQuery } from '@tanstack/react-query';
import HeadTitle from '@/components/common/HeadTitle';
import { useTranslation } from 'react-i18next';
import { makeLessonHeadTitle } from '@/utils/lessonHeadTitle';
import { ScheduledLessonTypeEnum } from '@/models/ScheduledLesson';
import {
  activitiesQueryKeys,
  coursePathsQueryKeys,
  lessonsQueryKeys,
} from '@/data/services/querykeys';

export default function ManageActivityEdit() {
  const { t } = useTranslation('translation', {
    keyPrefix: 'activity.manageActivity',
  });
  const {
    slugCourseName,
    lessonId: lessonIdParam,
    activityId: activityIdParam,
  } = useParams();
  const { setCourseStatus } = useCourseEditing();
  const { data: course } = useQuery({
    ...coursePathsQueryKeys.get(slugCourseName ?? ''),
    enabled: !!slugCourseName,
  });

  const lessonId = Number(lessonIdParam);

  const { data: lesson } = useQuery({
    ...lessonsQueryKeys.get(lessonId),
    enabled: !isNaN(lessonId) && !!slugCourseName,
  });

  const activityId = Number(activityIdParam);

  const { data: activity, isInitialLoading: isLoading } = useQuery({
    ...activitiesQueryKeys.get(activityId),
    enabled: !!course && !isNaN(activityId),
  });

  const hasCourseAndLessonAndActivity = !!course && !!lesson && !!activity;
  const titlePrefix = makeLessonHeadTitle({
    lessonOrder: lesson?.order ?? 0,
    coursePath: course,
    lessonType: ScheduledLessonTypeEnum.NORMAL,
  });
  const title = hasCourseAndLessonAndActivity
    ? `${t('editActivity')} - ${activity.name} - ${titlePrefix}`
    : `${t('editActivity')} - ${titlePrefix}`;

  useEffect(() => {
    let mounted = true;
    if (mounted && course) {
      setCourseStatus(course.status);
    }
    return () => {
      mounted = false;
    };
  }, [setCourseStatus, course]);

  return (
    <ManageActivityEditContainer
      isLoading={isLoading}
      courseName={course?.course.name}
    >
      <HeadTitle routeInfo={title} />
      {course && lesson && activity ? (
        <EditActivity course={course} lesson={lesson} activity={activity} />
      ) : (
        <React.Fragment />
      )}
    </ManageActivityEditContainer>
  );
}
